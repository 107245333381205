<template>
  <div class="dialog_mask" v-show="createGroupParams.show">
    <div class="dialog">
      <div class="dialog_header">
        <div class="dialog_title">
          <span>选择群成员</span>
        </div>
        <span
          class="iconfont icon-error icon_close"
          @click="closeDialog"
        ></span>
      </div>
      <div class="dialog_content">
        <div class="c_left">
          <div class="sub_title">群成员列表</div>
          <div class="list select_list">
            <div class="c_item" v-for="item in selectUserList" :key="item.id">
              <img
                class="user_photo"
                :src="item.photo ? item.photo : userAvatar"
                alt=""
              />
              <span class="user_name">{{ item.name }}</span>
              <span class="user_opt">
                <span
                  class="btn_remove"
                  v-show="selectChatIdList.includes(item.chatId)"
                  @click="removeUserClick(item)"
                  >移除</span
                >
              </span>
            </div>
            <div class="no_data" v-show="selectUserList.length == 0">
              暂无群成员
            </div>
          </div>
        </div>
        <div class="c_right">
          <div>
            <div class="c_label" @click="openStuParList = !openStuParList">
              <span>我的学生/家长</span>
              <span class="list_opt">
                <span
                  class="iconfont icon-youjiantou"
                  v-show="!openStuParList"
                ></span>
                <span
                  class="iconfont icon-xiajiantou-copy"
                  v-show="openStuParList"
                ></span>
              </span>
            </div>
            <div class="list" v-show="openStuParList">
              <div class="c_item" v-for="item in stuParList" :key="item.id">
                <img
                  class="user_photo"
                  :src="item.photo ? item.photo : userAvatar"
                  alt=""
                />
                <span class="user_name">{{ item.name }}</span>
                <span class="user_opt">
                  <span
                    class="btn_remove"
                    v-show="selectChatIdList.includes(item.chatId)"
                    @click="removeUserClick(item)"
                    >移除</span
                  >
                  <span
                    class="btn_add"
                    v-show="!selectChatIdList.includes(item.chatId)"
                    @click="addUserClick(item)"
                    >选择</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="c_label" @click="openTeacherList = !openTeacherList">
              <span>我的老师</span>
              <span class="list_opt">
                <span
                  class="iconfont icon-youjiantou"
                  v-show="!openTeacherList"
                ></span>
                <span
                  class="iconfont icon-xiajiantou-copy"
                  v-show="openTeacherList"
                ></span>
              </span>
            </div>
            <div class="list" v-show="openTeacherList">
              <div class="c_item" v-for="item in teacherList" :key="item.id">
                <img
                  class="user_photo"
                  :src="item.photo ? item.photo : userAvatar"
                  alt=""
                />
                <span class="user_name">{{ item.name }}</span>
                <span class="user_opt">
                  <span
                    class="btn_remove"
                    v-show="selectChatIdList.includes(item.chatId)"
                    @click="removeUserClick(item)"
                    >移除</span
                  >
                  <span
                    class="btn_add"
                    v-show="!selectChatIdList.includes(item.chatId)"
                    @click="addUserClick(item)"
                    >选择</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div>
            <div
              class="c_label"
              @click="openColleagueList = !openColleagueList"
            >
              <span>我的同事</span>
              <span class="list_opt">
                <span
                  class="iconfont icon-youjiantou"
                  v-show="!openColleagueList"
                ></span>
                <span
                  class="iconfont icon-xiajiantou-copy"
                  v-show="openColleagueList"
                ></span>
              </span>
            </div>
            <div class="list" v-show="openColleagueList">
              <div class="c_item" v-for="item in colleagueList" :key="item.id">
                <img
                  class="user_photo"
                  :src="item.photo ? item.photo : userAvatar"
                  alt=""
                />
                <span class="user_name">{{ item.name }}</span>
                <span class="user_opt">
                  <span
                    class="btn_remove"
                    v-show="selectChatIdList.includes(item.chatId)"
                    @click="removeUserClick(item)"
                    >移除</span
                  >
                  <span
                    class="btn_add"
                    v-show="!selectChatIdList.includes(item.chatId)"
                    @click="addUserClick(item)"
                    >选择</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog_bottom">
        <span class="btn btn_save" @click="saveClick">创建</span>
        <span class="btn btn_cancel" @click="closeDialog">取消</span>
      </div>
    </div>
  </div>
</template>
<script>
import TIM from "tim-js-sdk";
import { myStuAndPar, concernRelationship, myColleagues } from "@/api/chat";
import userAvatar from "@/assets/image/chat/default_avatar.png";
import { getBytesLength } from "@/utils/tool";
export default {
  data() {
    return {
      teacherList: [],
      stuParList: [],
      colleagueList: [],
      selectUserList: [],
      selectChatIdList: [],
      openTeacherList: true,
      openStuParList: true,
      openColleagueList: true,
      userAvatar,
    };
  },
  inject: ["timChat"],
  props: ["createGroupParams"],
  watch: {
    "createGroupParams.show"() {
      console.log(111);
      this.colleagueList = [];
      this.teacherList = [];
      this.stuParList = [];
      this.selectUserList = [];
      this.selectChatIdList = [];
      this.getColleagues();
      this.getMyStuAndPar();
      this.getTeacherList();
    },
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    getColleagues() {
      myColleagues().then((res) => {
        let list = [];
        res.rows.forEach((item) => {
          list.push({
            photo: item.photo,
            name: item.username,
            chatId: item.sysChatId,
          });
        });
        this.colleagueList = list;
      });
    },
    getMyStuAndPar() {
      myStuAndPar().then((res) => {
        let list = [];
        res.rows.forEach((item) => {
          list.push({
            roleType: 1,
            name: item.parentName ? item.parentName : item.stuName + "（家长）",
            photo: item.stuPhoto,
            chatId: item.parChatId,
          });
          list.push({
            roleType: 2,
            name: item.stuName,
            photo: item.parentPhoto,
            chatId: item.stuChatId,
          });
        });
        this.stuParList = list;
      });
    },
    getTeacherList() {
      concernRelationship({
        concernType: null,
      }).then((res) => {
        let list = [];
        res.rows.forEach((item) => {
          list.push({
            name: item.name,
            chatId: item.teaChatId,
            photo: item.photo,
          });
        });
        this.teacherList = list;
      });
    },
    saveClick() {
      if (!this.createGroupExec) {
        this.createGroupExec = true;
        // 群名称不能超过30个字节(规则：昵称拼起来最后...)
        let arr = [],
          groupName = "",
          memberList = [];
        if (this.selectUserList.length == 0) {
          this.msgWarn("群成员不能为空！");
          return;
        }
        this.selectUserList.forEach((item) => {
          arr.push(item.name);
          memberList.push({
            userID: item.chatId,
          });
        });
        groupName = arr.join(",");
        if (getBytesLength(groupName) > 30) {
          for (let i = 1, len = groupName.length; i < len; i++) {
            if (getBytesLength(groupName.substring(0, i)) > 27) {
              groupName = groupName.substring(0, i - 1);
              break;
            }
          }
          groupName = groupName.replace(/,$/, "") + "...";
        }
        console.log('groupName: ', groupName)
        this.timChat.tim
          .createGroup({
            type: TIM.TYPES.GRP_WORK,
            name: groupName,
            memberList,
          })
          .then(({ data }) => {
            this.createGroupExec = false;
            this.msgSuccess("群聊创建成功！");
            this.$emit("closeCreateGroupDialog");
          })
          .catch((imError) => {
            this.createGroupExec = false;
            console.warn("createGroup error:", imError);
          });
      }
    },
    closeDialog() {
      this.$emit("closeCreateGroupDialog");
    },
    addUserClick(item) {
      if (!this.selectChatIdList.includes(item.chatId)) {
        this.selectUserList.push(item);
        this.selectChatIdList.push(item.chatId);
      }
    },
    removeUserClick(item) {
      if (this.selectChatIdList.includes(item.chatId)) {
        let index = this.selectChatIdList.indexOf(item.chatId);
        this.selectUserList.splice(index, 1);
        this.selectChatIdList.splice(index, 1);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .dialog {
    background-color: #ffffff;
    width: 760px;
    height: 600px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
    .dialog_header {
      position: relative;
      .dialog_title {
        margin-top: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        .dialog_title_sub {
          color: #444444;
          font-size: 12px;
        }
      }
      .icon_close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
    .dialog_content {
      flex: 1;
      overflow: hidden;
      padding: 20px 20px;
      display: flex;
      height: 100%;
      .sub_title {
        line-height: 50px;
      }
      .c_label {
        line-height: 50px;
        cursor: pointer;
        &:hover {
          color: #024df8;
        }
        .list_opt {
          margin-left: 10px;
        }
      }
      .c_left {
        flex: 1;
        height: 100%;
        border-right: 1px solid #eeeeee;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        .select_list {
          flex: 1;
          flex-direction: column;
          display: flex;
          overflow-y: auto;
          .no_data {
            line-height: 60px;
            text-align: center;
          }
        }
      }
      .c_right {
        flex: 1;
        overflow-y: auto;
        padding: 6px 10px;
      }
      .c_item {
        display: flex;
        align-items: center;
        padding: 6px 0;
        border-bottom: 1px solid #eeeeee;
        .user_photo {
          width: 30px;
          height: 30px;
          margin: 0 10px;
          border-radius: 4px;
        }
        .user_name {
          flex: 1;
          @include ellipsis;
        }
        .user_opt {
          width: 60px;
          .btn_remove {
            cursor: pointer;
            color: #f56c6c;
          }
          .btn_add {
            cursor: pointer;
            color: #024df8;
          }
        }
      }
    }
    .dialog_bottom {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 16px;
      }
      .btn_save {
        background-color: #024df8;
        color: #ffffff;
      }
      .btn_cancel {
        margin-left: 40px;
        color: #bfbfbf;
        border: 1px solid #bfbfbf;
      }
    }
  }
}
</style>