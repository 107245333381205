<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">全部讲师</el-menu-item>
          <el-menu-item index="2">关注讲师</el-menu-item>
          <el-menu-item index="7">常用讲师</el-menu-item>
          <el-menu-item index="3">评价讲师</el-menu-item>
        </el-menu>
      </div>
    </div>
      <div class="btn-area select-condition" >
      <div class="left">
        <div class="select-condition-item">
          <div class="select-condition-item">
            <span class="item-lable">关键字：</span>
            <el-input
              v-model="listParams.name"
              placeholder="姓名/联系方式"
              size="small"
              style="min-width:120px"
               @blur="()=>(listParams.pageNumber=1,listTeacherManager())"
            ></el-input>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">年级：</span>
            <el-select
              v-model="listParams.sectionId"
              placeholder="请选择"
              size="small"
              @change="()=>(listParams.pageNumber=1,listTeacherManager())"
            >
              <el-option
                v-for="item in sectionIdList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">学科：</span>
            <el-select
              v-model="listParams.subjectId"
              placeholder="请选择"
              size="small"
              @change="()=>(listParams.pageNumber=1,listTeacherManager())"
            >
              <el-option
                v-for="item in subjectIdList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">讲师身份：</span>
            <el-select
              v-model="listParams.identityType"
              placeholder="请选择"
              size="small"
              @change="()=>(listParams.pageNumber=1,listTeacherManager())"
            >
              <el-option
                v-for="item in identityTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">讲师职称：</span>
            <el-select
              v-model="listParams.teachTitleId"
              placeholder="请选择"
              size="small"
              @change="()=>(listParams.pageNumber=1,listTeacherManager())"
            >
              <el-option
                v-for="item in teachTitleIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">学段：</span>
            <el-select
              v-model="listParams.periodId"
              placeholder="请选择"
              size="small"
              @change="()=>(listParams.pageNumber=1,listTeacherManager())"
            >
              <el-option
                v-for="item in periodIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="select-condition-item">
          <el-button type="primary" size="small" icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,listTeacherManager())"
            >搜索</el-button
          >
          <el-button type="primary" size="small" icon="el-icon-edit" v-show="handleKey==2"
            @click="editGrouping()"
            >新增/编辑分组</el-button
          >
          <el-button type="primary" size="small" icon="el-icon-user" v-show="handleKey==3"
          @click="myEvaluate()"
            >我的评价</el-button
          >
        </div>
      </div>
    </div>
    <div style="margin:10px 0;">
        <el-checkbox v-model="registerTime" @change="registerTimeChange()">只看最新注册</el-checkbox>
        <el-checkbox v-model="suggestChecked" @change="suggestChange()">系统推荐</el-checkbox>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="teacherList"
        style="width: 100%; margin-bottom: 20px"
        border
        height="100%"
        tooltip-effect="dark"
        ref="teacherListTable"
        @selection-change="handleSelectionChange"
        @row-click="teacherItemClick"
      >
        <el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div class="teachContent">
              <div class="infoDetails">
                <dl class="left">
                  <dt>
                    <el-image :src="scope.row.photo" :preview-src-list="scope.row.imageArray" alt="" style="width:100px;height:100px;background-size:cover;"></el-image> 
                    <el-tag v-show="scope.row.teacherEvaluatedFlag==0&&scope.row.usedAllHour==0" type="warning" effect="dark" class="span" size="mini">待评价</el-tag>
                  </dt>
                  <dd style="margin-left:35px;">
                    <el-tooltip
                    class="item"
                    effect="dark"
                    :content="aa + getNum + bb"
                    placement="top"
                  >
                    <p
                    @click.stop="dealEvaluate(scope.row)"
                      @mouseenter="seeMany(scope.row)"
                      style="font-size:16px;cursor:pointer;color:#333;"
                    >
                      {{ scope.row.realname?scope.row.realname:'暂无姓名' }}
                      <span class="onlinespan">{{onLineFlagFn(scope.row.onLineFlag)}}</span>
                      <span class="bq" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&(scope.row.onlineStatus=='Online'||scope.row.onlineStatus=='PushOnline')">
                        <img :src="require('@/assets/image/手机.png')" alt="" >
                        <span class="bqfont">教师APP在线</span>
                      </span>
                      <span class="bqlx" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&scope.row.onlineStatus=='Offline'">
                        <img :src="require('@/assets/image/手机-未开.png')" alt="" >
                        <span>教师APP离线</span>
                      </span>
                      <span v-show="scope.row.suggest==1" style="border-radius:3px;padding:0 8px;height:11px;font-size:12px;margin-left:10px;color:#3EC279;border:1px solid #3EC279;">{{suggestFlagFn(scope.row.suggest)}}</span>
                    </p>
                  </el-tooltip>
                  <p class="info_p">
                    <span>{{scope.row.sexId==1?'男':scope.row.sexId==2?'女':''}}</span>
                    <!-- <span>28岁</span> -->
                    <span>{{scope.row.isEquipment==1?'有设备':'无设备'}}</span>
                    <span>{{getIdentityType(scope.row.identityType)}}</span>
                  </p>
                  <!-- <p style="margin-top:15px;">
                   <el-tag size='small'>{{ identityFn(scope.row.identityType) }}</el-tag>
                  </p> -->
                  <p style="margin-top:10px;">
                    <el-tag size='small'>{{ subjectFn(scope.row.subjectId) }}</el-tag>
                    <el-tag size='small' style="min-width:50px ;">{{ dealPeriodId(scope.row.periodId) }}</el-tag>
                  </p>
                  <!-- <p style="margin-top:10px;" >
                      分组：
                      <span v-show="scope.row.concernType==0">未关注</span>
                      <span v-show="(scope.row.concernType==1||scope.row.concernType==3)&&scope.row.groupName==null">未分组</span>
                      <span v-show="scope.row.groupName">{{ scope.row.groupName}}</span>
                  </p> -->
                  </dd>
                </dl>
                <div class="content">
                    <ul>
                      <!-- <li><span>授课方式：</span><span>{{classFormFn(scope.row.classForm)}}</span></li> -->
                      <li><span>擅教学生：</span><span  >{{judgeStyleNeedFn(scope.row.skilfulTeach)}}</span></li>
                      <li><span>讲课风格：</span><span  >{{skilfulFn(scope.row.skilful)}}</span></li>
                      <li><span>分组：</span><span>{{scope.row.groupName}}</span></li>
                    </ul>
                     <p style="margin-top:10px;">
                      <el-tag size='small' type="warning" v-for="(item,key) in scope.row.arr" :key='key'>{{item.name}}</el-tag>
                    </p>
                </div>
                <div class="right">
                  <el-button style="margin:4px 0;" size="mini" type="primary" v-show="scope.row.concernType==0" :disabled="isDisable" @click="followTeacher(scope.row.id)">去关注</el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      style="margin:4px 0;"
                      v-show="handleKey==3"
                      @click.stop="addEvaluate(scope.row)"
                      >添加评价</el-button
                    >
                 <el-popover
                    placement="right"
                    width="100"
                   :ref="`popover-${scope.$index}`"
                    trigger="click">
                    <div class="groupingDiv">
                      <p @click.stop="setGrouping(scope.row.id,scope)">设置分组</p>
                      <p @click.stop="passTeacher(scope.row.id,scope)">取消关注</p>
                    </div>
                    <el-button size="mini" style="margin:4px 0;" slot="reference" v-show="scope.row.concernType==1" type="success">已关注</el-button>
                  </el-popover>
                  <el-button size="mini" style="margin:4px 0;" type="primary" v-show="scope.row.concernType==2" @click="huiguanTeacher(scope.row.id)">回关</el-button>
                  <el-popover
                    placement="right"
                    width="100"
                   :ref="`popover-${scope.$index}`"
                    trigger="click">
                    <div class="groupingDiv">
                      <p @click.stop="setGrouping(scope.row.id,scope)">设置分组</p>
                      <p @click.stop="passTeacher(scope.row.id,scope,2)">取消关注</p>
                    </div>
                    <el-button size="mini" style="margin:4px 0;" type="warning" slot="reference" v-show="scope.row.concernType==3"  >互关</el-button>
                    
                  </el-popover>
                  <!-- <el-badge
                    :value="scope.row.unreadCount"
                    :max="99"
                    class="online_chat"
                    :hidden="scope.row.unreadCount == 0"
                  >
                    <el-button
                      type="primary"
                      plain
                      size="mini"
                      @click="onlineChat(scope.row)"
                      >在线沟通</el-button
                    >
                    
                  </el-badge> -->
                </div>
              </div>
              <div class="freeTime">
                <span></span><span style="cursor:pointer" @click="dealTime(scope.row)">上课时间>></span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title=""
      :visible.sync="teacherDetailPopup"
      width="800px"
      :close-on-click-modal="false"
      center
      @close="teacherReturn()"
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="teacherInfo">
          <dl>
            <dt><el-image :src="teacherDetail.photo" :preview-src-list="teacherDetail.imageArray" alt="" style="width:110px;height:110px;border-radius:50%;"></el-image></dt>
            <dd>
              <p>   
                {{ teacherDetail.realname
                }}<span
                  style="margin-left:10px;font-size:15px;"
                  v-show="teacherDetail.sexId == 1"
                  >男</span
                ><span
                  style="margin-left:10px;font-size:15px;"
                  v-show="teacherDetail.sexId == 2"
                  >女</span
                >
                <span style="margin-left:10px;font-size:15px;" v-show="teacherDetail.contact">{{teacherDetail.contact}}</span>
                <span style="margin-left:10px;font-size:15px;">{{ageFn(teacherDetail.birthday)}}</span>
              </p>
              <p style="margin-top:16px;font-size:16px;">
                {{ getSectionId(teacherDetail) }}
              </p>
              <p style="margin-top:16px;fotn-size:16px;">
                共有{{ getNum }}位教务关注
              </p>
            </dd>
          </dl>
          <el-button
            type="primary"
            size="small"
            v-show="teacherStatus==true"
            @click="dealFollow()"
            >取消常用</el-button
          >
          <el-button  
            type="success"
            size="small"
            v-show="teacherStatus==false"
            @click="dealCancelFollow()"
            >设为常用</el-button
          >
        </div>
        <el-tabs
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
          style="margin-top:40px;"
        >
          <el-tab-pane label="讲师信息" name="first">
            <p class="title_p">基本信息</p>
            <ul class="teacher_ul">
              <li>
                <p><span>昵称：</span><span>{{ teacherDetail.name }}</span></p>
                <p><span>毕业院校：</span><span>{{ teacherDetail.graduationSchool?teacherDetail.graduationSchool:'暂无数据' }}</span></p>
                <p><span>学历：</span><span>{{ educationFn(teacherDetail) }}</span></p>
              </li>
              <li>
                <p><span>出生日期：</span><span>{{ teacherDetail.birthday?teacherDetail.birthday.substring(0,11):'暂无数据' }}</span></p>
                <p><span>教龄：</span><span>{{ teachingAgeFn(teacherDetail) }}</span></p>
                <p><span>任职学校：</span><span>{{ teacherDetail.schoolName ? teacherDetail.schoolName:'暂无数据' }}</span></p>
              </li>
              <li>
                <p><span>讲师职称：</span><span>{{ teachTitleFn(teacherDetail) }}</span></p>
                <p><span>籍贯：</span><span>{{ teacherDetail.provinceName}}{{ teacherDetail.cityName}}{{ teacherDetail.countyName}}</span></p>
                <p style="line-height:1.5;"><span>面授地点：</span><span>{{teacherDetail.currentProvincesName}}{{teacherDetail.currentCitysName}}{{getCurrentCountysName}}</span></p>
              </li>
              <li>
                <p><span>讲师身份：</span><span>{{ identityTypeFn(teacherDetail) }}</span></p>
                <p><span>讲课风格：</span><span>{{ skilfulFn(teacherDetail.skilful) }}</span> </p>
              </li>
              <li>
                <p><span>授课方式：</span><span>{{ classFormFn(teacherDetail.classForm) }}</span></p>
                <p><span>是否接收试听：</span><span>{{ teacherDetail.ifAcceptAudition==1?'可接受45分钟~1小时试听':'不接受' }}</span> </p>
              </li>
              <li>
                <p><span>擅教学生：</span><span>{{judgeStyleNeedFn(teacherDetail.skilfulTeach)}}</span></p>
                <p><span>期望课酬：</span><span>{{teacherDetail.hopeMinFee?teacherDetail.hopeMinFee:'暂无数据'}}——{{teacherDetail.hopeMaxFee?teacherDetail.hopeMaxFee:'暂无数据'}}元/课时</span></p>
              </li>
              <li>
                <p style="line-height:1.5;"><span>教学特点：</span><span>{{ teacherDetail.teachTrait ?teacherDetail.teachTrait:'暂无数据' }}</span></p>
              </li>
            </ul>
             <p class="title_p">附件管理</p>
             <a target="view_window" v-show="teacherDetail.resume" :href="teacherDetail.resume" style="display:block;padding-left:20px;margin-top:10px;text-decoration:none;">{{teacherDetail.realname}}简历</a>
                  <span v-show="!teacherDetail.resume"
                    >还未上传附件简历</span
                  >
            <p class="title_p">成功案例</p>
            <div class="caseDiv" v-for="(item, index) in caseList" :key="index">
                    <div class="caseLeft">
                        <p>
                          <span>{{item.studentName}}</span>
                          <span>{{studentSectionIdFn(item.studentSectionId)}}/{{studentSubjectIdFn(item.studentSubjectId)}}</span>
                          <span>{{item.classTimeBegin?item.classTimeBegin.substring(0,11):"暂无数据"}} 至 {{item.classTimeEnd?item.classTimeEnd.substring(0,11):'暂无数据'}}</span>
                          <span>{{studentAchievementFn(item.studentAchievement)}}</span>
                        </p>
                        <p style="margin-top:20px;">{{item.detailContent}}</p>
                        <p>
                          <el-image   style="width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in item.photoList'  :preview-src-list="item.photoList" :src="items"  :key='indexs' alt=""></el-image>
                        </p>
                    </div>
                    <div class="caseRight">
                    </div>
                  </div>
            <p class="title_p">功底考试</p>
            <ul class="basiceUl">
              <li v-for="(item,index) in paperTable" :key="index">
                <p><span>试卷：</span><span>{{item.title}}</span></p>
                <p><span>成绩：</span><span>{{item.finishScore}}分</span></p>
                <p><span @click="seepaperInfo(item.id)">查看详情</span></p>
              </li>
            </ul>
            <p class="title_p">试讲视频</p>
            <p v-html="getResult" style="margin-top:15px;"></p>
           <div class="video_area">
            <video id="video" :src="videoForm.videoUrl" controls v-show="videoForm.videoUrl"></video>
            <div v-show="!videoForm.videoUrl">暂无</div>
          </div>
           <p>
            <el-image   style="cursor:pointer;width:100px;height:100px;margin-right:10px;margin-top:15px;" v-for='(items,indexs) in fileList'   :src="items.url"  :key='indexs' :preview-src-list="fileLists" alt=""></el-image>
          </p>
            <p  class="title_p">面试答题</p>
            <ul class="answer_ul">
              <li v-for="(item, index) in answerList" :key="index">
                <p>问题{{ index + 1 }}：{{ item.questionTitle }}？</p>
                <p>答：{{ item.answer }}</p>
              </li>
            </ul>
            <p  class="title_p">面试评分</p>
            <el-tag effect="plain" style="margin-top:30px;">录入员评价</el-tag>
            <ul class="teacherFace_ul">
              <li><p>整体形象：<span> {{overallScoreFn(teacherFaceMark1)}}</span></p><p>形象气质：<span>{{appearance(teacherFaceMark1)}}</span></p><p>沟通表达：<span>{{connectCapacity(teacherFaceMark1)}}</span></p><p>教学特色：<span>{{teachingCharacteristics(teacherFaceMark1)}}</span></p></li>
               <li><p>在京代课经验：<span>{{lectureSpeed(teacherFaceMark1)}}</span></p><p>教学经验：<span>{{lectureStyle(teacherFaceMark1)}}</span></p><p>主要代课区域：<span>{{teacherFaceMark1.provinceOneName?teacherFaceMark1.provinceOneName:''}}{{teacherFaceMark1.provinceTwoName?teacherFaceMark1.provinceTwoName:''}}</span></p><p>合作求职态度：<span>{{jobHuntingAttitude(teacherFaceMark1)}}</span></p></li>
               <li><p>录入员意见：<span>{{teacherFaceMark1.overallEvaluation}}</span></p></li>
               <li><p>其他合作机构：<span>{{teacherFaceMark1.otherPartnersOne}}</span><span style="margin-left:20px;">{{teacherFaceMark1.otherPartnersTwo}}</span><span style="margin-left:20px;">{{teacherFaceMark1.otherPartnersThree}}</span></p></li>
            </ul>
             <el-tag effect="plain" style="margin-top:30px;">审核员评价</el-tag>
            <ul class="teacherFace_ul">
              <li><p>整体形象：<span> {{overallScoreFn(teacherFaceMark2)}}</span></p><p>形象气质：<span>{{appearance(teacherFaceMark2)}}</span></p><p>沟通表达：<span>{{connectCapacity(teacherFaceMark2)}}</span></p><p>教学特色：<span>{{teachingCharacteristics(teacherFaceMark2)}}</span></p></li>
              <li><p>在京代课经验：<span>{{lectureSpeed(teacherFaceMark2)}}</span></p><p>教学经验：<span>{{lectureStyle(teacherFaceMark2)}}</span></p><p>主要代课区域：<span>{{teacherFaceMark2.provinceOneName?teacherFaceMark2.provinceOneName:''}}{{teacherFaceMark2.provinceTwoName?teacherFaceMark2.provinceTwoName:""}}</span></p><p>合作求职态度：<span>{{jobHuntingAttitude(teacherFaceMark2)}}</span></p></li>
              <li><p>审核员意见：<span>{{teacherFaceMark2.overallEvaluation}}</span></p></li>
               <li><p>其他合作机构：<span>{{teacherFaceMark2.otherPartnersOne}}</span><span style="margin-left:20px;">{{teacherFaceMark2.otherPartnersTwo}}</span><span style="margin-left:20px;">{{teacherFaceMark2.otherPartnersThree}}</span></p></li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="评价详情" name="second">
            <p>老师的优点:</p>
            <p style="margin-top:5px;">
              <el-tag  style="margin-top:10px;" size='small' type="warning" v-for="(value,key) in goodMapData" :key='key'>{{key}}（{{value}}）</el-tag>
            </p>
            <p style="margin-top:15px;">希望老师改进的地方:</p>
            <p style="margin-top:5px;">
              <el-tag  style="margin-top:10px;" size='small' type="warning" v-for="(value,key) in badMapData" :key='key'>{{key}}（{{value}}）</el-tag>
            </p>
            <el-table
                    size="small"
                    :data="evaluateTable"
                    style="width: 100%; margin-bottom: 20px;margin-top:20px;"
                    row-key="userId"
                    border
                    tooltip-effect="dark"
                  >
                      <el-table-column prop="name" label="">
                          <template slot-scope="scope">
                              <div>
                                <div style="display:flex;justify-content: space-between;align-items: center;">
                                  <p  v-show="scope.row.ifAnonymous!=1" style="height:80px;display:flex;align-items: center;width:80%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;-webkit-box-orient:vertical;-webkit-line-clamp:2;"><span style="font-weight:600;">{{scope.row.teachManagerName?scope.row.teachManagerName:scope.row.studentName}}</span> <span style="flex:1;"><el-tag style="margin-left:15px;" type="warning" v-for="(item,index) in scope.row.evaluationTags" :key="index">{{item}}</el-tag></span></p>
                                  <p  v-show="scope.row.ifAnonymous==1" style="display:flex;align-items: center;width:85%;"><span style="font-weight:600;">{{scope.row.teachManagerName?scope.row.teachManagerName.substr(0,1)+"*****"+scope.row.teachManagerName.substr(-1,1):scope.row.studentName}}</span> <span style="flex:1;"><el-tag style="margin-left:15px;" type="warning" v-for="(item,index) in scope.row.evaluationTags" :key="index">{{item}}</el-tag></span></p>
                                  <p style="flex:1;">{{scope.row.createTime}}</p>
                                </div>
                                <p>{{scope.row.evaluationContent}}</p>
                              </div>
                          </template>
                      </el-table-column>
                  </el-table>
                   <div class="page-area">
                      <el-pagination
                        @size-change="evaluatedSizeChange"
                        @current-change="evaluatedCurrentChange"
                        :current-page="evaluatedParams.pageNumber"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="evaluatedParams.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="evaluateTotal"
                        background
                      ></el-pagination>
                    </div>
          </el-tab-pane>
          <el-tab-pane label="上课详情" name="2">
              <ul class="classInfoUl">
               <li v-for="(item, index) in classInfoData" :key="index">
                  <p>{{item.type}}</p>
                  <p><span>总课时数：{{item.classCount}}课时</span><span>总学生数：{{item.studentCount}}人</span><span>首次课作废课时数：{{item.cancelClassCount}}课时</span> </p>
                </li>
              </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="teacherReturn()" size="small" type="primary"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="添加评价"
      :visible.sync="evaluateDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="evaluateRuleFormOrder"
          status-icon
          :rules="evaluateRuleOrder"
          ref="evaluateRuleFormOrder"
          label-width="160px"
          size="small"
        >
          <el-form-item label="老师的优点" >
            <el-checkbox-group v-model="evaluationGood">
                  <el-checkbox-button label="课堂有激情" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="形象气质佳" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="沟通表达强" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="风趣幽默" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="细致负责有耐心" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="声音洪亮" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="干脆利索" name="type" ></el-checkbox-button>
                </el-checkbox-group>
          </el-form-item>
          <el-form-item label="希望老师改进的地方" >
                <el-checkbox-group v-model="evaluationTagNotGood">
                  <el-checkbox-button label="有口音" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="上课迟到" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="课上互动少" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="亲和力差" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="没有耐心" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="讲课古板" name="type" ></el-checkbox-button>
                  <el-checkbox-button label="不太负责" name="type" ></el-checkbox-button>
                </el-checkbox-group>
          </el-form-item>
          <el-form-item label="详细描述" prop="evaluationContent">
              <el-input  type="textarea" :rows="2" v-model="evaluateRuleFormOrder.evaluationContent"></el-input>
          </el-form-item>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="saveEvaluate('evaluateRuleFormOrder')" size="small"
          >确 定</el-button
        >
        <el-button @click="evaluateDialogVisible = false" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
      title="我的评价"
      :visible.sync="myEvaluateDialog"
      :close-on-click-modal="false"
      width="700px"
      center
    > 
      <div class="dialog-content">
        <el-table
          size="small"
          :data="myEvaluateTable"
          style="width: 100%; margin-bottom: 20px;margin-top:20px;"
          row-key="userId"
          border
          tooltip-effect="dark"
        >
            <el-table-column prop="name" label="">
                <template slot-scope="scope">
                    <div>
                      <div style="display:flex;justify-content: space-between;align-items: center;">
                        <span v-html="scope.row.teachManagerName">{{scope.row.teachManagerName}}</span>
                        <span>评价于{{scope.row.createTime}}</span>
                      </div>
                      <div class="myEvaluateDiv">
                        <div>
                          <p>{{scope.row.evaluationContent}}</p>
                        <ul>
                          <li v-show="scope.row.evaluationImage">
                            <span>{{scope.row.evaluationImage.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationImage.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationImage.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationTeaching">
                            <span>{{scope.row.evaluationTeaching.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationTeaching.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationTeaching.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationTeachingStyle">
                            <span>{{scope.row.evaluationTeachingStyle.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationTeachingStyle.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationTeachingStyle.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationOther">
                            <span>{{scope.row.evaluationOther.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationOther.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationOther.content}}</span>
                          </li>
                        </ul>
                        </div>
                        <div>
                            <el-button class="btn" type="primary" size="mini" v-show="scope.row.ifAnonymous==0" @click="anonymousFn(scope.row,0)">我要匿名</el-button>
                            <el-button class="btn" type="primary" size="mini" v-show="scope.row.ifAnonymous==1" @click="anonymousFn(scope.row,1)">取消匿名</el-button>
                            <el-button class="btn" type="primary" size="mini" @click="removeAnonymous(scope.row)">删除评价</el-button>
                            <el-button class="btn" type="primary" size="mini" @click="editEvaluate(scope.row)">修改评价</el-button>
                        </div>
                      </div>
                      <p class="tP">
                        <span>{{scope.row.teacherName?scope.row.teacherName:"暂无数据"}}</span>
                        <span>{{identityRowFn(scope.row.identityType)}}</span>
                        <span>{{scope.row.sectionName?scope.row.sectionName:"暂无数据"}}</span>
                      </p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-area">
          <el-pagination
            @size-change="myEvaluateSizeChange"
            @current-change="myEvaluateCurrentChange"
            :current-page="myEvaluateParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="myEvaluateParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="myEvaluateTotal"
            background
          ></el-pagination>
      </div>
      </div>
     </el-dialog>
     <el-dialog
      :title="groupTitle.title"
      :visible.sync="groupingPopup"
      width="700px"
      center
    >
      <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small"  @click.stop="daalAdd()">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
      <div class="save-dialog-content">
        <el-table
        size="small"
        :data="groupingList"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="groupingListTable"
        @selection-change="groupingSelectionChange"
        @row-click="groupingItemClick"
      >
       
        <el-table-column
          type="index"
          label="编号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="groupName"
          label="分组"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="daalDelete(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="daalEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="选择分组"
      :visible.sync="setGroupingPopup"
      width="400px"
      center
    >
      <div class="save-dialog-content">
        <el-table
        size="small"
        :data="setGroupingList"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="setGroupingTable"
        @selection-change="setGroupingChange"
        @row-click="setGroupingClick"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          prop="groupName"
          label="分组"
          align="center"
        ></el-table-column>
      </el-table>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="setGroupingPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="setTeacherGrouping()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="groupingDialog.title"
      :visible.sync="groupingDialogPopup"
      width="500px"
      center
    >
      <div class="save-dialog-content">
          <el-form
          :model="groupingRuleForm"
          status-icon
          :rules="groupingRules"
          ref="groupingRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-form-item label="分组名称" prop="groupName" >
                <el-input v-model="groupingRuleForm.groupName" placeholder="请输入分组名称"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="groupingDialogPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="saveGrouping('groupingRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="seePaperPopup"
      width="1000px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
      >
     <div class="dialog-content">
          <!-- <p style="float:right;font-size:16px;">总得分：{{testScore}}分</p> -->
          <h1 style="text-align:center;">{{ getFinishExamTitle }}</h1>
          <h3 style="text-align:center;margin-top:15px;">
            {{ getFinishExamDesc }}
          </h3>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div
              v-for="(item, index) in getFinishExam"
              :key="index"
              style="margin-top:30px;"
            >
              <p
                style="cursor: pointer;background:#ccc;height:40px;line-height:40px;padding-left:10px;font-size:16px;"
              >
                {{ item.qno }}、{{ item.title }}:本题共{{
                  item.finishPaperSmalls.length
                }}小题，每小题{{ item.score }}分，共{{
                  item.finishPaperSmalls.length * item.score
                }}分。{{ item.desc }}
              </p>
              <div
                v-for="(items, indexs) in item.finishPaperSmalls"
                :key="indexs"
                style="margin-top:25px;"
              >
                <p style="display:flex;align-items:center;">
                  <span>{{ items.qid }}</span
                  >.<span v-html="items.questions.title"></span>
                </p>
                 <div style="margin-top:10px;" class="aaa">
                  <template v-if="items.smallTitle == '单项选择题'">
                    <el-form-item label="" prop="radio">
                      <span v-if="items.questions.optionA"
                        >A.<span v-html="items.questions.optionA"></span
                      ></span>
                      <span v-if="items.questions.optionB"
                        >B.<span v-html="items.questions.optionB"></span
                      ></span>
                      <span v-if="items.questions.optionC"
                        >C.<span v-html="items.questions.optionC"></span
                      ></span>
                      <span v-if="items.questions.optionD"
                        >D.<span v-html="items.questions.optionD"></span
                      ></span>
                      <span v-if="items.questions.optionE"
                        >E.<span v-html="items.questions.optionE"></span
                      ></span>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '多项选择题'">
                    <el-form-item label="" prop="checkbox">
                      <span v-if="items.questions.optionA"
                        >A.<span v-html="items.questions.optionA"></span
                      ></span>
                      <span v-if="items.questions.optionB"
                        >B.<span v-html="items.questions.optionB"></span
                      ></span>
                      <span v-if="items.questions.optionC"
                        >C.<span v-html="items.questions.optionC"></span
                      ></span>
                      <span v-if="items.questions.optionD"
                        >D.<span v-html="items.questions.optionD"></span
                      ></span>
                      <span v-if="items.questions.optionE"
                        >E.<span v-html="items.questions.optionE"></span
                      ></span>
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '判断题'">
                    <el-form-item label="" prop="judge">
                      <!-- <el-radio-group
                        v-model="items.judge"
                        @change="chang1(items.judge, items)"
                      >
                        <el-radio label="1">对</el-radio>
                        <el-radio label="0">错</el-radio>
                      </el-radio-group> -->
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '主观题'">
                    <el-form-item label="" prop="subjective">
                      <!-- <span>{{items.answer}}</span> -->
                    </el-form-item>
                  </template>
                  <template v-if="items.smallTitle == '填空题'">
                    <el-form-item label="" prop="blanks">
                      <!-- <span>{{items.answer}}</span> -->
                      <!-- <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        @blur="chang1(items.blanks, items)"
                        v-model="items.blanks"
                      >
                      </el-input> -->
                    </el-form-item>
                  </template>
                </div>
                <p
                  style="color:red;margin-top:10px;"
                  v-if="
                    items.answer &&
                      (item.title == '单项选择题' || item.title == '多项选择题')
                  "
                >
                  【老师答案】:{{ items.answer
                  }}<span style="padding-left:20px;"
                    >得分:{{ items.score }}</span
                  >
                </p>
                <p
                  style="color:red;margin-top:10px;"
                  v-else-if="
                    items.answer &&
                      (item.title == '主观题' || item.title == '填空题')
                  "
                >
                  【老师答案】:{{ items.answer
                  }}<span style="padding-left:20px;"
                    >得分:<el-input
                      style="width:80px;magin-left:10px;"
                      v-model="items.score"
                      :maxlength="4"
                      @input="scoreChange(items.score,item.score,index,indexs)"
                      placeholder="请输入内容"
                    ></el-input
                  ></span>
                </p>
                <p
                  style="color:red;margin-top:10px;"
                  v-else-if="items.answer && item.title == '判断题'"
                >
                  【老师答案】:<template v-if="items.answer == 1">对</template
                  ><template v-if="items.answer == 0">错</template
                  ><span style="padding-left:20px;"
                    >得分:{{ items.score }}</span
                  >
                </p>
                <p style="color:red;margin-top:10px;" v-else>
                  【老师答案】:未作答<span style="padding-left:20px;"
                    >得分:{{ items.score }}</span
                  >
                </p>
                <p style="margin-top:10px;color:green;">
                  【参考答案】:
                  <span
                    style="color:green;margin-top:10px;display:inline-block;"
                    v-html="items.rightAnswer"
                    >{{ items.rightAnswer }}</span
                  >
                </p>
                <p>
                  <span style="color:green;" v-if="item.title == '判断题'"
                    >【参考答案】:<template v-if="items.rightAnswer == 1"
                      >对</template
                    ><template v-else-if="items.rightAnswer == 0"
                      >错</template
                    ></span
                  >
                </p>
                <div></div>
              </div>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="seePaperPopup = false" size="small"
            >返 回</el-button
          >
        </span>
    </el-dialog>
     <el-dialog
      title="上课时间"
      :visible.sync="timeDialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
        <div class="teacher_info">
          <span>姓名：{{teaRealname}}</span>
          <span>学段：{{dealSectionId(teaSectionId)}}</span>
          <span>学科：{{subjectFn(teaSubjectId)}}</span>
          <span>联系方式：{{teaContact}}</span>
        </div>
          <div class="weekDiv" style="display:flex;align-items:center;justify-content:center;margin-top:50px;">
            <p id="last-week" @click="lastWeek()"><<</p>
            <p>{{weekTitle}}</p>
            <p id="next-week" @click="nextWeek()">>></p>
          </div>
          <div style="display:flex;margin-top:30px;">
                  <div class="right_div" style="flex:1;">
                      <table id="monitor" > 
                          <thead id="monitor_thead">
                            <tr>
                              <td style="line-height:39px;"></td>
                              <td v-for="(item, index) in weekList" :key="index" style="line-height:39px;">
                                {{ item.value }}
                              </td>
                            </tr>
                          </thead>
                          <tbody class="monitor_tbody">
                            <tr v-for="(item,index) in tableList" :key="index" style="height:39px;">
                              <td style="width: 80px;line-height:39px;height: 39px;"> {{ dataTimeArray[index].time }}</td>
                              <td
                            class="course_td"
                            style="height:39px !important;"
                            v-for="(items, indexs) in item"
                            :key="indexs"
                          >
                             <div
                              class="course_can_use"
                              v-if="items.teaStuId"
                            >
                              <span>可排课</span>
                            </div>
                            <div v-if="!items.teaStuId"   :style="timeClass(items)" >
                              <span
                                >{{ items.subjectName }} {{ items.name }}</span
                              >
                              <span>{{ items.time }}</span>
                            </div>
                          </td>
                            </tr>
                          </tbody>
                      </table>
                      <table>
                      </table>
                  </div>
                </div>
      </div>
    </el-dialog>
    <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
    <teacherInfoPage ref="teacherInfo" :tInfoStatus="tInfoStatus" :tInfoRow="tInfoRow" @CB_tInfoStatus="CB_tInfoStatus"></teacherInfoPage>
    <teacherEvaluate ref="teacherEvaluate" :tEvaluateParams="tEvaluateParams" :editRow="editRow" :tEvaluateStatus="tEvaluateStatus" @CB_tEvaluateStatus="CB_tEvaluateStatus"></teacherEvaluate>
    <chat-to-user></chat-to-user>
  </div>
</template>
<script>
import chatToUser from "./chat/dialog/chat-to-user.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  teachStyle,
  achievement
} from "@/api/public";
import {
  listTeacherManager,
  concernTeacher,
  removeConcern,
  countConcern,
  currentTeachManagerId,
  teacherInfo,
  getByTeacherId,
  isConcernTeacher,
  teacherEvaluatedSave,
  teacherEvaluatedList,
  teacherEvaluatedUpdate,
  teacherEvaluatedContent,//评价详情
  teacherEvaluatedRemove,
  teacherUpdateEquipment,
  getTeacherClassDetailsCount,
  setOftenTeacher,
  selectOftenUsed,
  concernTeacherGroupList,
  concernTeacherGroupSave,
  concernTeacherGroupUpdate,
  removeByGroupId,
  getFreetimeList,
  setTeacherGroup,
  updateByTeacherId,
  getListByTeacherManagerlist,
  teacherEvaluatedDelete
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";

import { teacherFaceMarkDetail,finishPaperList,findPaperQuestions,teacherFindAuthFile,getFinishExamDetail } from "@/api/teacher/teacherManage";
import dayjs from "dayjs";
import teacherInfoPage from './dialog/teacherManageFile/teacherInfo.vue'
import teacherEvaluate from './dialog/teacherManageFile/teacherEvaluate.vue'
var addDate;
var setDate;
var currentFirstDate;
var getYear;
const cNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
const getYearWeek = function (a, b, c) {
  //a = d = 当前日期
  //b = 6 - w = 当前周的还有几天过完(不算今天)
  //a + b 的和在除以7 就是当天是当前月份的第几周
  var date = new Date(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate();
  return Math.ceil((d + 6 - w) / 7);
};
export default {
  
  data() {
    return {
      editRow:{},
      tInfoStatus:false,
      tEvaluateStatus:false,
      tEvaluateParams:{},
      timeStyle:{
        'font-size':'16px',
        'color':'red'
      },
      imgPreview: {
        img: "",
        show: false,
      },
      groupTitle:{
        title:'新增分组',
        type:"add"
      },
      groupingDialog:{
        title:'新增分组',
        type:"add"
      },
      srcList:[],
      groupingsId:[],
      groupingList:[],
      groupingTotal:0,
      groupingPopup:false,
      groupingDialogPopup:false,
      systemTeacherData:[],
      timeDialogVisible:false,
      weekTitle:"",
      tableList:[],
      dataArray2:[],
      dataWeek:[],
      rowTeacherId:"",




       dataTimeArray:[
        {
          time:"6:00-7:00",
          min:6,
          max:7
        },
        {
          time:"7:00-8:00",
          min:7,
          max:8
        },
        {
          time:"8:00-9:00",
          min:8,
          max:9
        },
        {
          time:"9:00-10:00",
          min:9,
          max:10
        },
        {
          time:"10:00-11:00",
           min:10,
          max:11
        },
        {
          time:"11:00-12:00",
          min:11,
          max:12
        },
        {
          time:"12:00-13:00",
          min:12,
          max:13
        },
        {
          time:"13:00-14:00",
          min:13,
          max:14
        },
{
          time:"14:00-15:00",
          min:14,
          max:15
        },
{
          time:"15:00-16:00",
          min:15,
          max:16
        },
{
          time:"16:00-17:00",
          min:16,
          max:17
        },
{
          time:"17:00-18:00",
          min:17,
          max:18
        },
{
          time:"18:00-19:00",
          min:18,
          max:19
        },
{
          time:"19:00-20:00",
          min:19,
          max:20
        },
{
          time:"20:00-21:00",
          min:20,
          max:21
        },
{
          time:"21:00-22:00",
          min:21,
          max:22
        },
{
          time:"22:00-23:00",
          min:22,
          max:23
        },
{
          time: "23:00-24:00",
          min: 23,
          max: 24,
        },
      ],
      groupingtatus:0,
      freetimeParams:{
        userType:1,
        teaStuId:"",
        searchBeginDate:"",
        searchEndDate:"",

      },
      groupingParams:{
        pageNumber:1,
        pageSize:10
      },
      groupingRuleForm:{
        groupName:"",

      },
      groupingRules:{
        groupName: [
          { required: true, message: "分组名称不能为空", trigger: "blur" },
        ],
      },
      evaluationGood:[],
      evaluationTagNotGood:[],
      getCurrentCountysName:"",
      followMyParams: {
        selectType:'4',
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      urlObj: {},
      urlArr:[],
      followMyTotal:0,
      followMyList:[],
      commonlyParams: {
        selectType:'7',
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      teacherDetailPhoto:[],
      groupNavIndex:'0',
      groupNavList:[],
      commonlyList:[],
       systemParams: {
        selectType:'6',
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      setGroupingList:[],
      groupIdList:[],
      systemTotal:0,
      caseList:[],
      commonlyParams:{
        selectType:'7',
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      commonlyTotal:0,
      commonlyData:[],
      newestTeacherData:[],
      newestTotal:0,
      evaluatedParams:{
          teacherId:"",
          pageNumber:1,
          pageSize:10
      },
      newestParams: {
        selectType:'5',
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      getFinishExam:[],
      ruleForm: {
        a: []
      },
      teaRealname:"",
      teaContact:"",
      teaSubjectId:"",
      teaSectionId:"",
      getFinishExamDesc:"",
      getFinishExamTitle:"",
      classInfoData:[],
      evaluateActive2:0,
      evaluateTable2:[],
      evaluateTotal2:0,
      getConcernId:"",
      curWeekFirstDay: dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD"),
      weekItemArr: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      evaluateUlData2:{
        allCount:0,
        praiseCount:0,
        chinaReviewCount:0,
        negativeCommentCount:0,
      },
      selectGroupingId:'',
      menuData:[

      ],
      registerTime:false,
      suggestChecked:false,
      teachStyleArray:[],
      weekList:[],
      setGroupingPopup:false,
      styleNeedArray:[],
      newPageSize:10,
      newPageNumber:1,
      handleKey:1,
       newPageSize2:10,
      newPageNumber2:1,
      getTeachManagerId:"",
      teacherFaceMark1:{},
      teacherFaceMark2:{},
      answerList: [],
      activeName: "first",
      teacherDetailPopup: false, //讲师详情弹窗
      activeIndex: "1",
      goodMapData:{},
      badMapData:{},
      listParams: {
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        periodId:"0",
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      xmtTeacherEvaluateLabelList:[
        {name:'课堂有激情',value:290},
        {name:'形象气质佳',value:284},
        {name:'沟通表达强',value:285},
        {name:'风趣幽默',value:286},
        {name:'细致负责有耐心',value:287},
        {name:'声音洪亮',value:288},
        {name:'干脆利索',value:289},
        {name:'有口音',value:291},
        {name:'上课迟到',value:292},
        {name:'课上互动少',value:293},
        {name:'亲和力差',value:294},
        {name:'没有耐心',value:295},
        {name:'讲课古板',value:296},
        {name:'不太负责',value:297},
      ],
      evaluatedContentParams:{
        pageNumber: 1,
        pageSize: 10,
        evaluationSource:0,
      },
       evaluatedContentParams2:{
        pageNumber: 1,
        pageSize: 10,
        evaluationSource:1,
      },
      paperTable:[],
      isDisable:false,
      isDisable2:false,
      evaluationTagBad:[],
      evaluationTagGood:[],
      evaluateDialogVisible:false,
      evaluateTotal:0,
      myEvaluateDialog:false,
      evaluateRuleFormOrder:{
        ifAnonymous:'1',
        evaluationSource:'1',
        xmtTeacherEvaluateLabelList:[],
      },
      myEvaluateParams:{
        pageNumber:1,
        pageSize:10,
        teacherId:"",
        evaluationSource:0,
        evaluationGrade:0,
        teachManagerId:"",
      },
      evaluateName:'1',
      myEvaluateTable:[],
      getCurrentUserId:"",
      myEvaluateTotal:0,
      evaluateUlData:{
        allCount:0,
        praiseCount:0,
        chinaReviewCount:0,
        negativeCommentCount:0,
      },
      evaluateRuleOrder:{
       
      },
     
      videoformArray:[],
      fileList:[],
      fileLists:[],
      videoForm: {
        videoUrl: ""
      },
      tInfoRow:{},
      getResult:"",
      evaluateParams: {
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10,
      },
      sectionIdList: [
        {
          macroId: "0",
          name: "全部"
        }
      ],
      checkRowId: [],
      teacherList: [],
      evaluateActive:0,
      total: 0,
      evaluateTable:[],
      evaluateTotal:0,
      currentUserId: "",
      getNum: "",
      aa: "已有",
      bb: "名教务关注了该讲师",
      seePaperPopup:false,
      subjectIdList: [
        {
          macroId: "0",
          name: "全部"
        }
      ],
      identityTypeList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "学科类-老师"
        },
        {
          value: "2",
          label: "学科类-在校大学生"
        },
        {
          value: "3",
          label: "素质教育类-老师"
        },
        {
          value: "4",
          label: "素质教育类-在校大学生"
        },
      ],
       identityTypeData:[
          {
              value: 1,
              label: "学科类-老师",
          },
          {
              value: 2,
              label: "学科类-在校大学生",
          },
          {
              value: 3,
              label: "素质教育类-老师",
          },
          {
              value: 4,
              label: "素质教育类-在校大学生",
          }
      ],
      teachTitleIdList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: 59,
          label: "初级教师"
        },
        {
          value: 60,
          label: "中级教师"
        },
        {
          value: 61,
          label: "高级教师"
        },
        {
          value: 62,
          label: "特级教师"
        }
      ],
      periodIdList:[
        {value:"0",label:'全部'},
        {value:107,label:'高中'},
        {value:106,label:'初中'},
        {value:105,label:'小学'},
        {value:108,label:'艺术培训'},
        {value:109,label:'小语种'}
      ],
      teacherDetail: {},
      teacherStatus: false
    };
  },
  computed: {
    ...mapState("chatInfo", ["conversationList", "currentConversation", 'chatToUserParams']),
    ...mapGetters("chatInfo", ["totalUnreadCount"]),
  },
  watch: {
    totalUnreadCount: {
      handler(totalUnreadCount) {
        this.dealUnreadCountChat();
      },
      deep: true,
    },
  },
  components: {
    chatToUser,
    teacherInfoPage,
    teacherEvaluate
  },
  created() {
    this.listTeacherManager();
  },
  mounted() {
    this.currentTeachManagerId();
    this.getSectionIdList();
    this.getSubjectList();
    
  },
  methods: {
    ...mapMutations("chatInfo", [
      "chatBoxShowChange",
      'updateCurTeacherList',
    ]),
    dealUnreadCountChat() {
      this.conversationList.forEach((conItem) => {
        if (conItem.type == "C2C") {
          let chatId = conItem.conversationID.split("C2C")[1];
          //unreadCount  未读消息数
          this.teacherList.forEach((teaItem) => {
            if (teaItem.teaChatId == chatId) {
              teaItem.unreadCount = conItem.unreadCount;
            }
          });
        }
      });
    },
    selectOftenUsedList(){
      selectOftenUsed({teacherId:this.teacherId}).then(res => {
        if(res.code==0){
          if(res.rows==true){//常用
              this.teacherStatus=true
          }else if(res.rows==false){//不常用
              this.teacherStatus=false
          }
        }
      });
    },
    currentTeachManagerId(){
       currentTeachManagerId().then(res => {
       this.getTeachManagerId=res.rows;
       this.myEvaluateParams.teachManagerId=res.rows;
      });
    },
    myEvaluate(){
      this.myEvaluateDialog=true;
      this.myEvaluateFn();
    },
    myEvaluateSizeChange(pageSize){
      this.myEvaluateParams.pageSize = pageSize;
      this.myEvaluateFn();
    },
    myEvaluateCurrentChange(currentPage){
      this.myEvaluateParams.pageNumber = currentPage;
      this.myEvaluateFn();
    },
    CB_tInfoStatus(){
      this.tInfoStatus=false;
    },
    CB_tEvaluateStatus(){
      this.tEvaluateStatus=false;
      this.myEvaluateFn();
      this.listTeacherManager();
    },
    myEvaluateFn(){
      if(this.myEvaluateDialog==false){
        this.myEvaluateParams.teacherId=this.teacherId;
      }else{
        delete this.myEvaluateParams.teacherId
      }
      getListByTeacherManagerlist(this.myEvaluateParams).then(res => {  
         this.myEvaluateTable=res.rows;
         for(var i=0;i<this.myEvaluateTable.length;i++){
            this.myEvaluateTable[i].evaluationImage=this.myEvaluateTable[i].evaluationImage?JSON.parse(this.myEvaluateTable[i].evaluationImage):'';
            this.myEvaluateTable[i].evaluationOther=this.myEvaluateTable[i].evaluationOther?eval('('+this.myEvaluateTable[i].evaluationOther+')'):'';
            this.myEvaluateTable[i].evaluationTeaching=this.myEvaluateTable[i].evaluationTeaching?eval('('+this.myEvaluateTable[i].evaluationTeaching+')'):'';
            this.myEvaluateTable[i].evaluationTeachingStyle=this.myEvaluateTable[i].evaluationTeachingStyle?eval('('+this.myEvaluateTable[i].evaluationTeachingStyle+')'):''
          
        }
        this.myEvaluateTotal=res.total;
      });
    },
    lastWeek(){
      this.dataArray2=[];
      this.dataWeek=[];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    nextWeek(){
      this.dataArray2=[];
      this.dataWeek=[];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    classFormFn(type){
      if(type=='1'){
        return '线上'
      }else if(type=='2'){
        return '线下'
      }else if(type=='0'){
        return '均可'
      }
    },
    sexFn(sexid){
      if(sexid=='1'){
        return '男'
      }else if(sexid=='2'){
        return '女'
      }else{
        return '暂无数据'
      }
    },
    anonymousFn(row,num){
      var data={
        id:row.id,
        ifAnonymous:1
      }
      if(num==0){
        data.ifAnonymous=1
      }else if(num==1){
        data.ifAnonymous=0
      }
      teacherEvaluatedUpdate(data).then(res => {
        if(res.code==0){
          this.msgSuccess("操作成功！");
          this.myEvaluateFn();
        }
      });
    },
    removeAnonymous(row){
      teacherEvaluatedRemove([row.id]).then(res => {
        if(res.code==0){
          this.msgSuccess("操作成功！");
          this.myEvaluateFn();
        }
      });
    },
    editEvaluate(row){
      this.editRow=row;
      this.tEvaluateParams=row;
      this.tEvaluateStatus=true;
    },
    identityRowFn(row) {
        for(var i=0;i<this.identityTypeData.length;i++){
            if(this.identityTypeData[i].value==Number(row)){
                return this.identityTypeData[i].label;
            }
        }
    },
    dealPeriodId(row){
      var str = "";
      var arr2 = new Array();
      if (row) {
        arr2 = row.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 107) {
          str += "高中,";
        } else if (arr2[i] == 106) {
          str += "初中,";
        } else if (arr2[i] == 105) {
          
          str += "小学,";
        } else if (arr2[i] == 108) {
          str += "艺术培训,";
        } else if (arr2[i] == 109) {
          str += "小语种,";
        } else if (arr2[i] == 235) {
          str += "全学段,";
        }
      }
      return str.substring(0, str.length - 1);
    },
    judgeStyleNeedFn(item) {
      let arr = new Array();
      let str = "";
      if(item){
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
          for (var k = 0; k < arr.length; k++) {
            if(this.styleNeedArray[i].macroId==arr[k]){
                str+=this.styleNeedArray[i].name.split(":")[0]+"，"
            }
          }
      }
       return str.substring(str.length-1,0);
    },
    timeClass(item){
      if(item.time){
        var minuteTop=Number(item.time.substring(3,5)),
            spacing=39.9/60,
            start=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[0]),
            end=new Date(item.cellTime.split(' ')[0]+" "+item.time.split('-')[1]),
            m='',
            h=''
            if (start && end) {
              var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
              var cal=Math.floor(subTime/60);//分钟
              }
        if(minuteTop!=0){
          return {
            'top':spacing*minuteTop+'px',
            'position':'absolute',
            'z-index':'99999',
            'background':'#1890FF',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }else{
          return {
            'top':'0px',
            'position':'absolute',
            'z-index':'99999',
            'background':'#1890FF',
            'width':'100%',
            'font-size':'12px',
            'color':'#fff',
            'height':Number(cal)*spacing+'px'
          }
        }
      }
    },
    listTeacherManager() {
      var type="";
      if(this.handleKey==1){
        type=""
      }else if(this.handleKey==2){
        type="2"
      }else if(this.handleKey==3){
        type="1"
      }else if(this.handleKey==7){
        type="7"
      }
      let data = {
        selectType:type,
        name: this.listParams.name,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        subjectId:
          this.listParams.subjectId == "0" ? "" : this.listParams.subjectId, //学科
        identityType:
          this.listParams.identityType == "0"
            ? null
            : this.listParams.identityType, //身份
        teachTitleId:
          this.listParams.teachTitleId == "0"
            ? null
            : String(this.listParams.teachTitleId), //职称
        periodId: this.listParams.periodId == "0"
            ? null
            : String(this.listParams.periodId), //职称,
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        suggest:this.suggestChecked?1:0,
        createTime:this.registerTime?'1':null
      };
      listTeacherManager(data).then(res => {
        this.total = res.total;
        this.currentUserId = res.currentUserId;
        for (var i = 0; i < res.rows.length; i++) {
          if (res.rows[i].xmtConcernTeacherEntityList.length) {
            for (
              var j = 0;
              j < res.rows[i].xmtConcernTeacherEntityList.length;
              j++
            ) {
              if (
                res.rows[i].xmtConcernTeacherEntityList[j].teachManagerId ==
                  this.currentUserId &&
                res.rows[i].xmtConcernTeacherEntityList[j].teacherId ==
                  res.rows[i].id
              ) {
                res.rows[i].newTeachManagerIdd = true;
              } else {
                res.rows[i].newTeachManagerIdd = false;
              }
            }
          } else {
            res.rows[i].newTeachManagerIdd = false;
          }
        }
        this.getCurrentUserId=res.currentUserId;
        let curTeacherList = []
        this.teacherList = res.rows.map((item) => {
          item.arr=new Array();
          item.imageArray=new Array();
          item.unreadCount = 0;
          curTeacherList.push({
            teaChatId: item.teaChatId
          })
          item.imageArray.push(item.photo)
          if(item.teacherEvaluatedTag){
            item.teacherEvaluatedTag.forEach(items => {
            for(var k in items) {
              item.arr.push({name: k, value: items[k]});
              if(item.arr.length>0){
                item.arr = item.arr.slice(0,4);
              }else{
                item.arr=[];
              }
            }
          })
          }
          
          return item;
        });
        this.updateCurTeacherList(curTeacherList)
        this.dealUnreadCountChat();
      });
    },
    
    mouseOver(){
      this.groupingtatus=1;
    },
    
   
    showImg(e) {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
    },
   
    registerTimeChange(){
      this.listParams.pageNumber=1;
      this.listTeacherManager();
    },
    suggestChange(){
      this.listParams.pageNumber=1;
      this.listTeacherManager();
    },
   
    setTeacherGrouping(){
      if(!this.selectGroupingId){
        this.msgWarn('请先选择组！')
        return
      }
      var isConcernParams={
        teacherId:this.teacherId,
        teachManagerId:this.getTeachManagerId
      }
      isConcernTeacher(isConcernParams).then(res => {
        if (res.code == 0) {
          if (res.rows == "无数据") {
          } else {
            var data={
              id:res.rows.id,
              groupId:this.selectGroupingId
            }
            setTeacherGroup(data).then(res => {
                if (res.code == 0&&res.msg=="操作成功！") {
                  this.msgSuccess("操作成功！");
                  this.setGroupingPopup=false;
                  this.listTeacherManager();
                }else{
                  this.msgWarn(res.msg);
                  return false;
                }
              });
          }
        }
      });
    },
    groupClick(id){
      console.log(id)
    },
    setGrouping(id,row){
      let groupId = row.row.groupId;
      this.setGroupingPopup = true;
      this.teacherId = id;
      concernTeacherGroupList({ pageNumber: 1, pageSize: 1000 }).then((res) => {
        this.setGroupingList = res.rows;
        if (groupId) {
          res.rows.forEach((item) => {
            if (item.id == groupId) {
              console.log('执行了！')
              this.$nextTick(() => {
                this.$refs.setGroupingTable.toggleRowSelection(item);
              })
            }
          });
        }
      });


    },
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.teacherInfo();
      }else if(tab.index==1){
        this.evaluateName='1';
        this.teacherEvaluatedContent();
      }else if(tab.index==2){
        this.getTeacherClassDetailsCountFn();
      }
    },
    setGroupingChange(val){
      if (val.length == 0) {
        this.selectGroupingId = "";
      } else if (val.length == 1) {
        this.selectGroupingId = val[0].id;
      } else if (val.length > 1) {
        this.selectGroupingId = val[0].id;
        this.$refs.setGroupingTable.clearSelection();
        this.$refs.setGroupingTable.toggleRowSelection(val.pop());
      }
    },
    setGroupingClick(row){
      this.$refs.setGroupingTable.toggleRowSelection(row);
    },
    //上课详情接口
    getTeacherClassDetailsCountFn(){
      getTeacherClassDetailsCount({teacherId:this.teacherId}).then(res => {
        if (res.code == 0) {
          this.classInfoData=res.rows;
        }
      });
    },
    teacherInfo() {
      var data1={//录入员
          peopleType:1,
          teacherId:this.teacherId
      }
       var data2={//审核员
          peopleType:2,
          teacherId:this.teacherId
      }
       teacherFaceMarkDetail(data1).then(res => {
        if (res.code == 0) {
            this.teacherFaceMark1=res.rows;
        }
      });
      teacherFaceMarkDetail(data2).then(res => {
        if (res.code == 0) {
          this.teacherFaceMark2=res.rows;
        }
      });
      var isConcernParams={
        teacherId:this.teacherId,
        teachManagerId:this.getTeachManagerId
      }
      isConcernTeacher(isConcernParams).then(res => {
        if (res.code == 0) {
          if (res.rows == "无数据") {
          } else {
            this.getConcernId=res.rows.id;
          }
        }
      });
      this.videoformArray=[];
      teacherFindAuthFile(this.teacherId).then(res => {
        var getData = res.rows.authFiles;
        if (res.code == 0) {
          for (var i = 0; i < getData.length; i++) {
            if (getData[i].fileType == 6) {
              //视频
              this.videoForm.videoUrl = getData[i].fileUrl;
            }
            if (getData[i].fileType == 5) {
              this.videoformArray.push(getData[i]);
              this.urlObj.url = getData[i].fileUrl;
              this.urlArr.push(getData[i].fileUrl);
              this.fileList.push(this.urlObj);
              this.getResult=getData[i].title;
              this.urlObj = {};
            }
          }
           for(var i=0;i<this.fileList.length;i++){
            this.fileLists.push(this.fileList[i].url);
          }
        }
      });
      teacherInfo(this.teacherId).then(res => {
        if (res.code == 0) {
          this.teacherDetail = res.rows;
          this.teacherDetail.imageArray = new Array();
          this.teacherDetail.imageArray.push(res.rows.photo)
          this.teacherDetailPhoto.push(res.rows.photo)
          var CountysName=""
          if(res.rows.currentCountysName.length){
            for(let i=0;i<res.rows.currentCountysName.length;i++){
              CountysName+=res.rows.currentCountysName[i].NAME+"，"
            }
          }
          this.getCurrentCountysName=CountysName.substring(0,CountysName.length-1);
          this.caseList = res.rows.xmtTeacherSuccessCaseEntityList;
        }
      });
      getByTeacherId(this.teacherId).then(res => {
        if (res.code == 0) {
          this.answerList = res.rows;
        }
      });
     
      countConcern({teacherId:this.teacherId}).then(res => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
      var data = {
        examStatus: 1,
        pageNumber: 1,
        pageSize: 1000,
        sortOrder: "asc",
        examUserId: this.teacherId
      };
      finishPaperList(data).then(res => {
        if (res.rows.length) {
          this.paperId=res.rows[0].id;
          this.paperTable = res.rows;

          if (res.rows[0].title) {
            this.getFinishExamTitle = res.rows[0].title;
          }
        }else{
          this.finishPaperId = ''
          this.getFinishExam = []
        }
      });
    },
    getIdentityType(row){
        if (row==1) {
            return "学科类--老师";
        } else if (row==2) {    
            return "学科类--大学生";
        } else if (row==3) {
            return "素质教育类--老师";
        } else if (row == 4) {
            return "素质教育类--大学生";
        } else {
            return "";
        }
    },
    seepaperInfo(id){
      this.seePaperPopup=true;
       
      findPaperQuestions(id).then(res => {
        if(res.rows.length){
          this.finishPaperId=res.rows[0].finishPaperId;
          var data = {
          finishPaperId: this.finishPaperId,
          teacherId: this.teacherId
        };
          getFinishExamDetail(data).then(res => {
          if (res.flag == "success") {
            if (res.msg.title) {
              this.getFinishExamTitle = res.msg.title;
            }
            if (res.msg.desc) {
              this.getFinishExamDesc = res.msg.desc;
            }
          }
        });
        }

        for (var i = 0; i < res.rows.length; i++) {
          for (var j = 0; j < res.rows[i].finishPaperSmalls.length; j++) {
            res.rows[i].finishPaperSmalls[j].smallTitle = res.rows[i].title;
            res.rows[i].finishPaperSmalls[j].paperBigType =
              res.rows[i].paperBigType;
            if (res.rows[i].finishPaperSmalls[j].smallTitle == "单项选择题") {
              res.rows[i].finishPaperSmalls[j].radio =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "多项选择题"
            ) {
              res.rows[i].finishPaperSmalls[j].checkbox = res.rows[i]
                .finishPaperSmalls[j].answer
                ? res.rows[i].finishPaperSmalls[j].answer.split("|")
                : [];
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "判断题"
            ) {
              res.rows[i].finishPaperSmalls[j].judge =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "填空题"
            ) {
              res.rows[i].finishPaperSmalls[j].blanks =
                res.rows[i].finishPaperSmalls[j].answer;
            } else if (
              res.rows[i].finishPaperSmalls[j].smallTitle == "主观题"
            ) {
              res.rows[i].finishPaperSmalls[j].subjective =
                res.rows[i].finishPaperSmalls[j].answer;
            }
          }
        }
        this.getFinishExam = res.rows;
      });
    },
    onLineFlagFn(num){
        switch(num){
        case 1:
          return '在线'
        case 2:
          return '刚刚活跃'
        case 3:
          return '一天前活跃'
        case 4:
          return '七天前活跃'
        default:
          return ''
      }
      },
      suggestFlagFn(num){
        switch(num){
          case 1: 
            return '推荐'
          case 0: 
            return '非推荐'
          default:
            return ''
        }
      },
    commonlySelectionChange(){

    },
    commonlyItemClick(){

    },
    overallScoreFn(row){
      var str="";
      if(row.overallScore==1){
          str='A+'
      }else if(row.overallScore==2){
          str='A'
      }else if(row.overallScore==3){
          str='B+'
      }else if(row.overallScore==4){
          str='B'
      }else if(row.overallScore==5){
          str='C'
      }else if(row.overallScore==6){
          str='D'
      }
      else{
          str="暂无数据"
      }
      return str;
    },
    appearance(row){
        var str=''
        if(row.imageTemperament==1){
            str='负分'
        }else if(row.imageTemperament==2){
            str='正常'
        }else if(row.imageTemperament==3){
            str='加分'
        }else{
            str="暂无数据"
        }
        return str;
    },
    connectCapacity(row){
      var str=''
        if(row.communicationExpression==1){
            str='负分'
        }else if(row.communicationExpression==2){
            str='正常'
        }else if(row.communicationExpression==3){
            str='加分'
        }else{
            str="暂无数据"
        }
        return str;
    },
    lectureSpeed(row){
       var str=''
        if(row.substituteLessonsBeijing==1){
            str='5年以下'
        }else if(row.substituteLessonsBeijing==2){
            str='5-10年'
        }else if(row.substituteLessonsBeijing==3){
            str='10年以上'
        }else{
            str="暂无数据"
        }
        return str;
    },
    specialtySkil(row){
       var str=''
        if(row.specialtySkil==1){
            str='极佳'
        }else if(row.specialtySkil==2){
            str='佳'
        }else if(row.specialtySkil==3){
            str='平实'
        }else if(row.specialtySkil==4){
            str='略差'
        }else if(row.specialtySkil==5){
            str='极差'
        }
        return str;
    },
    lectureStyle(row){
       var str=''
        if(row.teachingExperience==1){
             str='5年以下'
        }else if(row.teachingExperience==2){
            str='5-10年'
        }else if(row.teachingExperience==3){
            str='10-15年'
        }else if(row.teachingExperience==4){
            str='15-20以上'
        }
        else{
            str="暂无数据"
        }
        return str;
    },
    studentAchievementFn(num){
      if(num==268){
        return '提高型'
      }else if(num==269){
        return '中上等成绩'
      }else if(num==270){
        return '中等类待提高'
      }else if(num==271){
        return '基础型'
      }
      else{
        return ''
      }
    },
    teachingCharacteristics(row){
       var str=''
        if(row.teachingCharacteristics==1){
             str='负分'
        }else if(row.teachingCharacteristics==2){
            str='正常'
        }else if(row.teachingCharacteristics==3){
            str='加分'
        }else{
            str="暂无数据"
        }
        return str;
    },
    jobHuntingAttitude(row){
       var str=''
        if(row.jobHuntingAttitude==1){
             str='负分'
        }else if(row.jobHuntingAttitude==2){
            str='正常'
        }else if(row.jobHuntingAttitude==3){
            str='加分'
        }else{
            str="暂无数据"
        }
        return str;
    },
    dealSectionId(row) {
      let type 
      switch (row) {
        case '107':
          type = '高中'
          break;
        case '106':
          type = '初中'
          break;
        case '105':
          type = '小学'
          break;
        case '108':
          type = '艺术培训'
          break;
        case '109':
          type = '小语种'
          break;
        case '235':
          type = '全学段'
          break;
        default:
          type = ''
          break;
      }
      return type
    },
    educationFn(row) {
      var educationstr = "";
      if (row.educationId == 70) {
        educationstr = "专科";
      } else if (row.educationId == 69) {
        educationstr = "本科";
      } else if (row.educationId == 68) {
        educationstr = "硕士";
      } else if (row.educationId == 67) {
        educationstr = "博士";
      }else{
        educationstr ='暂无数据'
      }
      return educationstr;
    },
    ageFn(birthday){
      if(birthday){
        var birthday_time=birthday.split("-");
        var birthYear = birthday_time[0];
        var d= new Date();
        var nowYear=d.getFullYear();
        var age=nowYear-birthYear;
        return age+'岁';
      }else{
        return ''
      }

    },
    teachingAgeFn(row) {
      var teachingAgestr = "";
      if (row.teachingAgeId == 27) {
        teachingAgestr = "5年以下";
      } else if (row.teachingAgeId == 28) {
        teachingAgestr = "5-10";
      } else if (row.teachingAgeId == 29) {
        teachingAgestr = "10-15";
      } else if (row.teachingAgeId == 227) {
        teachingAgestr = "15-20以上";
      }
      return teachingAgestr;
    },
    teachTitleFn(row) {
      var teachTitlestr = "";
      if (row.teachTitleId == 59) {
        teachTitlestr = "初级教师";
      } else if (row.teachTitleId == 60) {
        teachTitlestr = "中级教师";
      } else if (row.teachTitleId == 61) {
        teachTitlestr = "高级教师";
      } else if (row.teachTitleId == 62) {
        teachTitlestr = "特级教师";
      }
      return teachTitlestr;
    },
    identityTypeFn(row) {
      var identityTypestr = "";
      if (row.identityType == 1) {
        identityTypestr = "学科类--老师";
      } else if (row.identityType == 2) {
        identityTypestr = "学科类--大学生";
      } else if (row.identityType == 3) {
        identityTypestr = "素质教育类--老师";
      } else if (row.identityType == 4) {
        identityTypestr = "素质教育类--大学生";
      } 
      return identityTypestr;
    },
    skilfulFn(item) {
      let arr = new Array();
      let str = "";
      if(item){
        arr = item.split(",");
      }
      for (var i = 0; i < this.teachStyleArray.length; i++) {
          for (var k = 0; k < arr.length; k++) {
            if(this.teachStyleArray[i].macroId==arr[k]){
                str+=this.teachStyleArray[i].name.split("：")[0]+"，"
            }
          }
      }
       return str.substring(str.length-1,0);
    },
    getSectionId(row) {
      var str = "";
      var arr2 = new Array();
      var subjectlist = "";
      if (row.sectionId) {
        arr2 = row.sectionId.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三，";
        } else if (arr2[i] == 97) {
          str += "高二，";
        } else if (arr2[i] == 96) {
          str += "高一，";
        } else if (arr2[i] == 20) {
          str += "初三，";
        } else if (arr2[i] == 19) {
          str += "初二，";
        } else if (arr2[i] == 18) {
          str += "初一，";
        } else if (arr2[i] == 95) {
          str += "六年级，";
        } else if (arr2[i] == 94) {
          str += "五年级，";
        } else if (arr2[i] == 93) {
          str += "四年级，";
        } else if (arr2[i] == 92) {
          str += "三年级，";
        } else if (arr2[i] == 110) {
          str += "艺术培训，";
        } else if (arr2[i] == 111) {
          str += "小语种，";
        }
      }
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return str.substring(0, str.length - 1) + " | " + subjectlist;
    },
    //设为常用
    dealFollow() {
      var data={
        teacherId:this.teacherId,
        oftenUsedType:0
      }
      setOftenTeacher(data).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.teacherInfo();
          this.selectOftenUsedList();
          // this.teacherDetailPopup = false;
          this.listTeacherManager();
        }
      });
    },
    //列表关注
    followTeacher(id){
      this.isDisable = true;
      var data={
        teacherId:id,
        teachManagerId:this.getCurrentUserId,
        concernType:1
      }
      concernTeacher(data).then(res => {
        if (res.code == 0) {
          setTimeout(() => {
            this.isDisable = false;
          }, 1000)
          this.msgSuccess("操作成功！");
           this.listTeacherManager();
        }
      });
    },
    //设为不常用
    dealCancelFollow() {
      var data={
        teacherId:this.teacherId,
        oftenUsedType:1
      }
      setOftenTeacher(data).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.teacherInfo();
          this.selectOftenUsedList();
          // this.teacherDetailPopup = false;
          this.listTeacherManager();
        }
      });
    },
    huiguanTeacher(id){
      console.log(this.handleKey,'handleKey...')
      var data={
        teacherId:id,
        concernType:3
      }
      updateByTeacherId(data).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
           this.listTeacherManager();
        }
      });
    },
   
    //列表取消关注
    passTeacher(teacherId,scope,type){
       scope._self.$refs[`popover-${scope.$index}`].doClose()
      var isConcernParams={
        teacherId:teacherId,
        teachManagerId:this.getCurrentUserId
      }
      isConcernTeacher(isConcernParams).then(res => {
        if (res.code == 0) {
          if (res.rows == "无数据") {
          } else {
            var data={
              id:res.rows.id,
              concernType:type?2:0
            }
            removeConcern(data).then(res => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.teacherInfo();
                  this.listTeacherManager();
                  
                }
              });
          }
        }
      });
    },
    //查看多少教务关注了该讲师
    seeMany(row) {
      countConcern({teacherId:row.id}).then(res => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
    },
    //上课时间
    dealTime(row) {
      this.rowTeacherId=row.id;
      this.timeDialogVisible=true;
      this.teaRealname=row.realname?row.realname:'';
      this.teaContact=row.contact?row.contact:'';
      this.teaSubjectId=row.subjectId?row.subjectId:'';
      this.teaSectionId=row.periodId?row.periodId:"";
      this.subjectFn();
      this.dealSectionId();

      this.$nextTick(()=>{
        this.dataArray2=[];
        this.dataWeek=[];
        this.getWeekFn();
        this.findCourseItemFnn();
      })
    },
    getWeekFn(){
       this.weekList = [];
      for (let i = 0; i < 7; i++) {
        this.weekList.push({
          value: `${dayjs(this.curWeekFirstDay)
            .add(i, "day")
            .format("MM-DD")}(${this.weekItemArr[i]})`,
        });
      }
    },
    findCourseItemFnn(type){
        let year = dayjs(this.curWeekFirstDay).year(),
        month = dayjs(this.curWeekFirstDay).month(),
        date = dayjs(this.curWeekFirstDay).date();
        this.weekTitle =
        year +
        "年" +
        (month + 1) +
        "月第" +
        cNum[getYearWeek(year, month, date)] +
        "周";
        let list = [];
      for (var k = 6; k < 24; k++) {
        list.push([]);
        for (var q = 0; q < 7; q++) {
          // 单元格时间段的开始时间
          let cellTime = dayjs(this.curWeekFirstDay)
            .add(q, "day")
            .add(k, "hour")
            .format("YYYY-MM-DD HH:mm");
          // 时间是否是当前时间之前，是的话不能点击选择， 小时加1按照每格1小时算的
          let isPastTime = dayjs().isAfter(
            dayjs(this.curWeekFirstDay)
              .add(q, "day")
              .add(k + 1, "hour")
          );
          list[k - 6][q] = {
            check: false,
            name: "",
            pastTime: isPastTime,
            canUse: true,
            cellTime,
          };
        }
      }
      this.tableList = list;
      let searchBeginDate =
        dayjs(this.curWeekFirstDay).add(0, "day").format("YYYY-MM-DD") +
        " 00:00:00";
      let searchEndDate =
        dayjs(this.curWeekFirstDay).add(6, "day").format("YYYY-MM-DD") +
        " 23:59:59";
      let data={
          searchBeginDate:searchBeginDate,
          searchEndDate:searchEndDate,
          teacherId:this.rowTeacherId
        }
      getFreetimeList(data).then(res => {
        if(res.code==0){
          let listProductItemOrgan =res.rows.listProductItemOrgan;
          let listProductItemTeachManager =res.rows.listProductItemTeachManager;
          let xmtTeacherFreetime=res.rows.xmtTeacherFreetime;
          let haveClass=listProductItemOrgan.concat(xmtTeacherFreetime)
          let listProductItemTeachManagers =
            haveClass.concat(listProductItemTeachManager)
            console.log(listProductItemTeachManagers,'listProductItemTeachManagers.....')
          //    let temp = [];
          // let newArr = listProductItemTeachManagers.reduce((acc, cur) => {
          //   if (temp.indexOf(cur['beginTime']) === -1) {
          //     temp.push(cur['beginTime']);
          //     acc.push(cur);
          //     return acc;
          //   } else {
          //     console.log(acc,'acc...');
          //     return acc;
          //   }
          // }, []);
          // console.log(newArr,'newArr...')
          //   return;
          listProductItemTeachManagers.forEach((item) => {
            // day取值 0 1 2 3 4 5 6 对应周一至周日
            let day =
              (dayjs(item.beginTime).day() == 0
                ? 7
                : dayjs(item.beginTime).day()) - 1;
            // hour代表y坐标因为小时轴从6开始-6便于计算
            let hour = dayjs(item.beginTime).hour() - 6;
            this.tableList[hour][day].canUse = false;
            this.tableList[hour][day].check = true;

            this.tableList[hour][day].name = item.studentName;
            this.tableList[hour][day].id = item.id;

            this.tableList[hour][day].teaStuId = item.teaStuId;
            
            this.tableList[hour][day].time = `${dayjs(item.beginTime).format(
              "HH:mm"
            )}-${dayjs(item.beginTime)
              .add(item.usedClassHour, "hour")
              .format("HH:mm")}`;
            this.tableList[hour][day].usedClassHour = item.usedClassHour;
            // 如果课时大于1，则设置下边的几个时间段也不能被点击处理，不足1个小时的课程按照一个小时处理
            let len = item.usedClassHour;
            if (dayjs(item.beginTime).minute() != "00") {
              len++;
            }
            for (let i = 1; i < len; i++) {
              try {
                this.tableList[hour + i][day].canUse = false;
              } catch (err) {
                console.log(err);
              }
            }
            this.tableList[hour][day].subjectName = item.subjectName;
          });
       }
      });
    },
    //查看评论
    dealEvaluate(row) {
      this.tInfoStatus=true;
      this.tInfoRow=row;
      // this.activeName="first";
      // this.teacherId = row.id;
      // this.evaluatedParams.teacherId = row.id;
      // this.teacherDetailPopup = true;
      // this.fileLists=[];
      // this.fileList=[];
      // this.videoForm.videoUrl='';
      // this.getResult='';
      // this.selectOftenUsedList();
      // this.teacherInfo();
    },
    //添加评论
    addEvaluate(row){
         this.tEvaluateStatus=true;
         this.tEvaluateParams=row;
         this.tEvaluateParams.teachManagerId=this.getTeachManagerId;
         this.editRow={};
      // this.evaluateDialogVisible=true;
      // this.evaluateRuleFormOrder.teacherId=row.id;
      // this.evaluateRuleFormOrder.teachManagerId=this.getTeachManagerId;
    },  

    updateEquipment(row){
      if(row.isEquipment==0){
        teacherUpdateEquipment({id:row.id,isEquipment:1}).then(res => {
          if (res.code == 0) {
            this.listTeacherManager();
          }
        });
      }else{
        teacherUpdateEquipment({id:row.id,isEquipment:0}).then(res => {
          if (res.code == 0) {
            this.listTeacherManager();
          }
        });
      }
    },
    updateEquipment3(row){
      if(row.isEquipment==0){
        teacherUpdateEquipment({id:row.id,isEquipment:1}).then(res => {
          if (res.code == 0) {
            this.evaluateManager();
          }
        });
      }else{
        teacherUpdateEquipment({id:row.id,isEquipment:0}).then(res => {
          if (res.code == 0) {
            this.evaluateManager();
          }
        });
      }
    },
   
    saveEvaluate(formName){
       this.evaluateRuleFormOrder.xmtTeacherEvaluateLabelList=[]
      this.$refs[formName].validate(valid => {
          if (valid) {
            let arr=this.evaluationGood.concat(this.evaluationTagNotGood);
            for(var i=0;i<this.xmtTeacherEvaluateLabelList.length;i++){
              for(var k=0;k<arr.length;k++){
                if(this.xmtTeacherEvaluateLabelList[i].name==arr[k]){
                  this.evaluateRuleFormOrder.xmtTeacherEvaluateLabelList.push({evaluateLabel:this.xmtTeacherEvaluateLabelList[i].value})
                }
              }
            }
            let data={
              teacherId:this.evaluateRuleFormOrder.teacherId,
              teachManagerId:this.evaluateRuleFormOrder.teachManagerId,
              evaluationSource:0,
              ifAnonymous:0,
              evaluationTag:this.evaluationGood.concat(this.evaluationTagNotGood).join(),
              evaluationContent:this.evaluateRuleFormOrder.evaluationContent,
              xmtTeacherEvaluateLabelList:this.evaluateRuleFormOrder.xmtTeacherEvaluateLabelList
            }
            
            teacherEvaluatedSave(data).then(res => {
                if(res.code==0){
                  this.evaluateDialogVisible=false;
                   this.evaluateRuleFormOrder={
                      teacherId:"",
                      evaluationContent:""
                    };
                    this.evaluationGood=[];
                    this.evaluationTagNotGood=[];
                }
            });
          }else {
            this.msgWarn("必填字段不能为空！");
            return false;
          }
      })
    },
    teacherEvaluatedContent(num){
       teacherEvaluatedContent(this.evaluatedParams).then(res => {
            for(var i=0;i<res.page.rows.length;i++){
              res.page.rows[i].evaluationTags=res.page.rows[i].evaluationTag.split(',');
            }
            this.evaluateTable=res.page.rows;
            this.evaluateTotal=res.page.total;
            this.goodMapData=res.goodMap;
            this.badMapData=res.badMap;
        });
    },
    teacherReturn(){
      this.teacherDetailPopup=false;
      var video = document.getElementById('video');
      video.pause(); //暂停控制
    },
    evaluatedSizeChange(pageSize){
      this.evaluatedParams.pageSize = pageSize;
      this.teacherEvaluatedContent();
    },
    evaluatedCurrentChange(currentPage){
      this.evaluatedParams.pageNumber = currentPage;
      this.teacherEvaluatedContent();
    },
    
    handleSelectionChange(val) {
      this.checkRowId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.checkRowId.push(val[i].id);
        }
      }
    },
    concernTypeFn(type){
      if(type==0){
        return '加关注'
      }else if(type==1){
        return '教务关注讲师'
      }else if(type==2){
        return '讲师关注教务'
      }else if(type==3){
        return '互关'
      }
    },
    searchFn(){
      this.listTeacherManager();
    },
     studentSectionIdFn(row){
      let result = []
      var subjectArray=String(row).split();
      for(let  i=0;i<this.sectionIdList.length;i++){
        let tempArr1=this.sectionIdList[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    studentSubjectIdFn(row){
      var subjectlist = "";
      if (row == 185) {
        subjectlist = "全学科";
      } else if (row == 23) {
        subjectlist = "语文";
      } else if (row == 24) {
        subjectlist = "数学";
      } else if (row == 25) {
        subjectlist = "英语";
      } else if (row == 101) {
        subjectlist = "物理";
      } else if (row == 102) {
        subjectlist = "化学";
      } else if (row == 99) {
        subjectlist = "政治";
      } else if (row == 100) {
        subjectlist = "生物";
      } else if (row == 87) {
        subjectlist = "地理";
      } else if (row == 88) {
        subjectlist = "历史";
      } else if (row == 103) {
        subjectlist = "科学";
      } else if (row == 112) {
        subjectlist = "国学";
      } else if (row == 113) {
        subjectlist = "书法";
      } else if (row == 114) {
        subjectlist = "吉他";
      } else if (row == 115) {
        subjectlist = "绘画";
      } else if (row == 116) {
        subjectlist = "德语";
      } else if (row == 117) {
        subjectlist = "法语";
      } else if (row == 118) {
        subjectlist = "俄语";
      } else if (row == 119) {
        subjectlist = "西班牙语";
      } else if (row == 183) {
        subjectlist = "其他小语种";
      } else if (row == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    subjectFn(row) {
      let result = []
      let list=this.subjectIdList.slice(1);
      var subjectArray=row?row.split(','):[];
      for(let  i=0;i<list.length;i++){
        let tempArr1=list[i];
        for(let j=0;j<subjectArray.length;j++){
          let tempArr2=subjectArray[j]
          if(tempArr2==tempArr1.macroId){
             result.push(tempArr1.name)
             break;
          }
        }
      }
      return result.join();
    },
    onlineChat(item) {
      this.chatBoxShowChange({
        show: true,
        chatId: item.teaChatId,
        type: 'teacher',
        info: {
          sexName: item.sexId == 1 ? "男" : item.sexId == 2 ? "女" : "性别未知",
          teacherTitle: this.titleFn(item.teachTitleId)
            ? this.titleFn(item.teachTitleId)
            : "未知职级",
          identity: this.identityFn(item.identityType)
            ? this.identityFn(item.identityType)
            : "暂无认证信息",
          subjectName: this.subjectFn(item.subjectId)
            ? this.subjectFn(item.subjectId)
            : "学科未知",
          sectionName: this.dealPeriodId(item.periodId)
            ? this.dealPeriodId(item.periodId)
            : "学段未知",
          deviceStatus:
            item.isEquipment == 1
              ? "有设备" : "无设备",
          photo: item.photo,
          name: item.realname ? item.realname : '暂无姓名',
          age: this.teachingAgeFn(item) ? this.teachingAgeFn(item) : '教龄未知' ,
          onLineFlag: this.onLineFlagFn(item.onLineFlag) ? this.onLineFlagFn(item.onLineFlag) : '活跃状态未知',
          account: item.account,
          classFlag: this.classFormFn(item.classForm) ? this.classFormFn(item.classForm) : '上课方式未知',
        },
      });
    },
    titleFn(row) {
      switch (row) {
        case 59:
          return "初级教师";
        case 60:
          return "中级教师";
        case 61:
          return "高级教师";
        case 62:
          return "特级教师";
        default:
          return "";
      }
    },
    identityFn(row) {
      if (row==1) {
            return "学科类--老师";
        } else if (row==2) {    
            return "学科类--大学生";
        } else if (row==3) {
            return "素质教育类--老师";
        } else if (row == 4) {
            return "素质教育类--大学生";
        } else {
            return "";
        }
    },
    teacherItemClick(row) {
      this.$refs.teacherListTable.toggleRowSelection(row);
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.listTeacherManager();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.listTeacherManager();
    },
    
    newestSelectionChange(){

    },
    newestItemClick(row){
      this.$refs.newestListTable.toggleRowSelection(row);
    },
    //编辑分组
    editGrouping(){
      this.groupingPopup=true;
      this.groupTitle={
        title:'新增/编辑分组',
        type:"edit"
      },
      this.concernTeacherGroupListFn();
    },
    concernTeacherGroupListFn(){
        concernTeacherGroupList(this.groupingParams).then(res => {
            this.groupingList=res.rows;
            this.groupingTotal=res.total;
        });
    },
    groupingItemClick(row){
      this.$refs.groupingListTable.toggleRowSelection(row);
    },
    groupingSelectionChange(val){
      this.groupingsId = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.groupingsId.push(val[i].id);
        }
      }
    },
    daalDelete(item){
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          removeByGroupId(item.id).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.concernTeacherGroupListFn();
            }
          });
        })
        .catch(() => {});
    },
    daalEdit(item){
      this.groupingDialog ={
        title:"编辑分组",
        type:"edit"
      }
      this.groupingDialogPopup=true;
      this.groupingRuleForm.groupName=item.groupName;
      this.groupingRuleForm.id=item.id;
      
    },
    daalAdd(){
      this.groupingDialog ={
        title:"新增分组",
        type:"add"
      }
      this.groupingDialogPopup=true;
      this.groupingRuleForm.groupName='';
      this.groupingRuleForm.id='';
    },
    saveGrouping(formName){
      this.$refs[formName].validate(valid => {
        if (valid) {  
            if (this.groupingDialog.type == "add") {
              concernTeacherGroupSave(this.groupingRuleForm).then(res => {
                if (res.code == 0) {
                  this.groupingDialogPopup=false;
                  this.msgSuccess("操作成功！");
                  this.concernTeacherGroupListFn();
                }
              });
            }else if (this.groupingDialog.type == "edit") {
              concernTeacherGroupUpdate(this.groupingRuleForm).then(res => {
                if (res.code == 0) {
                  this.groupingDialogPopup=false;
                  this.msgSuccess("操作成功！");
                  this.concernTeacherGroupListFn();
                }
              });
              }
        }else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      })
    },
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionIdList = res.rows;
          this.sectionIdList.unshift({
            macroId: "0",
            name: "全部"
          });
        }
      });
       getSelectList({
        //风格偏好
        parentCode: achievement,
      }).then((res) => {
        if (res.rows) {
          this.styleNeedArray = res.rows;
        }
      });
      getSelectList({
        //讲课风格
        parentCode: teachStyle,
      }).then((res) => {
        if (res.rows) {
          this.teachStyleArray = res.rows;
        }
      });
    },
    //获取学科
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "0",
            name: "全部"
          });
        }
      });
    },
    handleSelect(key, keyPath) {
      this.handleKey=key;
      this.registerTime=false;
      this.suggestChecked=false;
      this.listParams={
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        periodId:"0",
        pageNumber: 1,
        pageSize: 10
      }
      this.listTeacherManager(); 
    }
  }
};
</script>
<style scoped lang="scss">
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 80px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
   
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .save_apply_tip {
    margin-bottom: 20px;
  }
  .save_apply {
    .save_apply_input {
      width: 80px;
    }
  }
  .scopeDiv div {
    margin-top: 10px;
  }
  .scopeDiv h2 {
    margin-left: 10px;
  }
  .scopeDiv p > span {
    padding: 0 10px;
    font-size: 15px;
  }
  .scopeDiv3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    padding-left:30px;
    // background: yellow;
  }
  .infoDetails{
    height:150px;
    padding-top:25px;
    display: flex;
  }
  .infoDetails .left{
    display: flex;
    width:35%;
    min-width:500px;
  }
  .infoDetails .content{
      width:350px;
  }
  .infoDetails .content ul{
    // margin-top:30px;
  }
  .infoDetails .content ul li{
    margin-top:5px;
  }
  .infoDetails .content ul span:nth-child(1){
    font-size:14px;
    color:#909399;
  }
  .infoDetails .content ul span:nth-child(2){
    font-size:14px;
    color:#333;
  }
  .infoDetails .left dt{
    position: relative;
  }
  .infoDetails .left dt .span{
    position: absolute;
    left:-10px;
    top:-10px;
  }
  .infoDetails .right{
    display: flex;
    width:20%;
    align-items: center;
    flex-direction: column;
  }
  .freeTime{
    color:#61687c;
    height:25px;
    line-height: 25px;
    display: flex;
    justify-content: space-between;

  }
  .teacherInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    dl{
      display:flex;
      align-items: center;
      dd{
        margin-left:30px;
        p:nth-child(1){
          font-weight: 600;
          font-size:18px;
        }
      }
    }
  }
  .title_p{
      font-size:16px;
      font-weight: 700;
      color:#303133;
      margin-top:55px;
    }

  .teacher_ul  li{
    width:100%;
    display:flex;
  }
  .teacher_ul li>p {
    font-size: 14px;
    margin-top: 20px;
    display: inline-block;
    font-weight:400;
    flex:1;
    span:nth-child(1){
      color:#909399;
    }
    span:nth-child(2){
      color:#000;
    }
  }
  .answer_ul li {
    margin-top: 30px;
  }
  .answer_ul p {
    margin-top: 15px;
    font-size:14px;
    color:#303133;
    font-weight:400;
  }
  .teacherFace_ul{
    margin-top:20px;
  }
  .teacherFace_ul li{
    display: flex;
    font-size:14px;
    margin-top:15px;
    color:#303133;
    font-weight:400;
  }
  .teacherFace_ul li p{
    // width:50%;
    flex:1;
  }
  .teacherFace_ul li span{
    margin-left:5px;
  }
  .evaluateUl{
    display: flex;
    margin-top:30px;
    li{
      width:60px;
      height:30px;
      border-radius: 15px;
      line-height: 30px;
      text-align: center;
      color:orange;
      border:1px solid orange;
      margin-right:20px;
      cursor:pointer;
    }
  }
  .evaluateActive{
    background:orange;
    color:#fff !important;
  }
  .tP{
      width:80%;
      display: flex;
      span{
        flex:1;
        color:#909399;
      }
    }
  .myEvaluateDiv{
    display: flex;
    justify-content: space-between;
    // align-items: center;
    ul{
      li{
        margin:6px 0;
      }
    }
    div:nth-child(1){
      padding-right:10px;
    }
    div{
      display: flex;
      flex-direction: column;
      .btn{
        margin:4px 0;
      }
    }
    
  }
  .classInfoUl{
    padding-bottom:40px;
    li{
      margin-top:25px;
      p:nth-child(1){
        font-weight: 600;
      }
      p:nth-child(2){
        margin-top:10px;
        span{
          margin-right:15px;
        }
      }
    }
  }
  
.show_table {
  position: relative;
  width: 100%;
  overflow: auto;
}
.weekDiv {
    margin-top: 20px;
    p {
      font-size: 22px;
      // font-weight: 600;
      cursor: pointer;
    }
    p:nth-child(2) {
      font-size: 22px;
      padding: 0 10px;
    }
  }
  
}

.course_td {
    width: 80px;
    height: 39px !important;
    line-height:20px;
    position: relative;
    table-layout: fixed;
    // background:yellow;
    // overflow: hidden;
    vertical-align: middle;
    .course_can_use {
      color: #666666;
      margin-top: -8px;
    }
    .free_course_can_use{
      color: #666666;
      margin-top: -8px;
    }
  }
  .course_td > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .course_td p {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .time_table td {
  border-right-style: none !important;
}
#monitor{
  table-layout: fixed;
}
#monitor td {
  border-bottom-style: none !important;
}
 table tr td {
    border: 1px solid #DCDFE6;
  }
  table {
    width: 900px;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    border-collapse: collapse;
  }
  .groupingDiv{
    width:100px;
    height:80px;
    text-align: center;
    p{
      font-size:16px;
      height:40px;
      line-height:40px;
      color:#606266;
      cursor:pointer;
    }
  }
  .groupUl{
    display: flex;
    li{
      width:100px;
      height:50px;
      line-height:50px;
      border-right:1px solid #ccc;
      text-align: center;
      cursor:pointer;
    }
  }
 .caseDiv{
    display: flex;
    margin-top:20px;
    .caseLeft{
      width: 80%;
      span{
        margin-right:30px;
      }
    }

    .caseRight{
      flex:1;
      text-align: center;
    }
  }
  .li2 span:nth-child(2){
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    width:100%;
  }
  .online_chat {
  // margin-left: 10px;

}
.imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 40%;
    display: block;
  }
}
.basiceUl{
  margin-top:15px;
}
.basiceUl li{
  display: flex;
  margin-top:15px;
  p{
    padding-right:15px;
  }
  p>span:nth-child(1){
    color:#000;
    font-weight: bold;
  }
  p:nth-child(3){
    cursor: pointer;
    color:#1890ff;
  }
}
 .video_area {
    width: 200px;
    height: 140px;
    video {
      width: 200px;
      height: 140px;
      object-fit: fill;
    }
    div {
      width: 200px;
      height: 140px;
      border: 1px solid #dcdfe6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .teacher_info{
    display:flex;
    align-items: center;
    margin-top:20px;
    span{
      flex:1;
      font-size:15px;
      font-weight:600;
    }
  }
  .timediv{
    background:red;
    height:50px;
    position: relative;
    // top:20px;
  }
  .info_p{
    color:#333;
    margin-top:13px;
    span{
      margin-right:10px;
    }
  }
  .bq{
      padding:3px 8px;
      border-radius: 3px;
      font-size:12px;
      color:#EE783D;
      border:1px solid #EE7031;
      background: #FFF4EE;
      img{
        vertical-align: middle;
        display: inline-block;
        margin-top:-3px;
      }
      span{
        display: inline-block;
      }
  }
  .bqlx{
      padding:3px 8px;
      border-radius: 3px;
      font-size:12px;
      color:#B2B8C0;
      border:1px solid #D9DDE3;
      background: #F5F7FA;
      img{
        vertical-align: middle;
        display: inline-block;
        margin-top:-3px;
      }
      span{
        display: inline-block;
      }
  }
  .onlinespan{
    border-radius:3px;
    padding:3px 8px;
    height:18px;
    line-height: 18px;
    font-size:12px;
    margin-left:10px;
    color:#3EC279;
    border:1px solid #3EC279;
  }
</style>
