<template>
    <el-dialog
      title="新增学员"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="800px"
      center

      @close="closeDialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <div class="info-title">学生信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生姓名" prop="name">
                <el-input v-model="saveRuleForm.name" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contact">
                <el-input
                  v-model="saveRuleForm.contact"
                  oninput="if(value.length>11)value=value.slice(0,11)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="saveRuleForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年级" prop="sectionId">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所在学校" prop="schoolName">
                <el-input v-model="saveRuleForm.schoolName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学校所在区域" prop="schoolReach">
                <el-input v-model="saveRuleForm.schoolReach"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="来源渠道" prop="sourceType">
                <el-select
                  v-model="saveRuleForm.sourceType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sourceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="孩子性格" prop="childNature">
                <el-input v-model="saveRuleForm.childNature"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveRuleForm.sourceType == '5'">
            <el-col :span="12">
              <el-form-item label="转介绍人姓名" prop="referralstudent">
                <el-input v-model="saveRuleForm.referralstudent"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转介绍人电话" prop="referralphone">
                <el-input
                  v-model="saveRuleForm.referralphone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  v-model="saveRuleForm.birthday"
                  type="date"
                  @change="birthdayChange"
                  :default-value="new Date('2003-01-01')"
                  :picker-options="expireTimeOption"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"

                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="星座" prop="constellationId">
                <el-select
                  filterable 
                  :disabled="true"
                  v-model="saveRuleForm.constellationId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in constellationList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="家庭住址">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChangeList"
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChange"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="学生其他需求">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="500"
              v-model="saveRuleForm.studentsOtherNeeds">
            </el-input>
          </el-form-item>
          <div class="info-title">家长信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="母亲姓名" prop="childMotherName">
                <el-input v-model="saveRuleForm.childMotherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="母亲电话" prop="childMotherPhone">
                <el-input
                  v-model="saveRuleForm.childMotherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="父亲姓名" prop="childFatherName">
                <el-input v-model="saveRuleForm.childFatherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父亲电话" prop="childFatherPhone">
                <el-input
                  v-model="saveRuleForm.childFatherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="微信/QQ" prop="parentWechat">
            <el-input v-model="saveRuleForm.parentWechat"></el-input>
          </el-form-item>
          <div class="info-title">空闲时间</div>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="firstClassTimeRemark">
              <el-input v-model="saveRuleForm.firstClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="formalClassTimeRemark">
              <el-input v-model="saveRuleForm.formalClassTimeRemark"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";
import {
  studentSave,
} from "@/api/sales/saleManage";
import { systemAreaList,setStudentlevel } from "@/api/system/systemManage";
export default {
    props:["addStudentStatus"],
    watch:{
      addStudentStatus(newValue,oldValue){
        this.showDialog=newValue;
        this.saveRuleForm = {
          teachManagerId: "1",
          name: "",
          contact: "",
          sex: "1",
          birthday: "",
          constellationId: "",
          sectionId: "",
          schoolName: "",
          childNature: "",
          provinceId: "",
          cityId: "",
          countyId: "",
          childMotherName: "",
          childMotherPhone: "",
          childFatherName: "",
          childFatherPhone: "",
          parentWechat: "",
          firstClassTimeRemark: "",
          formalClassTimeRemark: "",
        };
        this.firstClassTimeList = [
          [
            {
              name: "上午",
              key: "a",
              status: false,
            },
            {
              name: "上午",
              key: "b",
              status: false,
            },
            {
              name: "上午",
              key: "c",
              status: false,
            },
            {
              name: "上午",
              key: "d",
              status: false,
            },
            { 
              name: "上午",
              key: "e",
              status: false,
            },
            {
              name: "上午",
              key: "f",
              status: false,
            },
            {
              name: "上午",
              key: "g",
              status: false,
            },
          ],
          [
            {
              name: "下午",
              key: "h",
              status: false,
            },
            {
              name: "下午",
              key: "i",
              status: false,
            },
            {
              name: "下午",
              key: "j",
              status: false,
            },
            {
              name: "下午",
              key: "k",
              status: false,
            },
            {
              name: "下午",
              key: "l",
              status: false,
            },
            {
              name: "下午",
              key: "m",
              status: false,
            },
            {
              name: "下午",
              key: "n",
              status: false,
            },
          ],
          [
            {
              name: "晚上",
              key: "o",
              status: false,
            },
            {
              name: "晚上",
              key: "p",
              status: false,
            },
            {
              name: "晚上",
              key: "q",
              status: false,
            },
            {
              name: "晚上",
              key: "r",
              status: false,
            },
            {
              name: "晚上",
              key: "s",
              status: false,
            },
            {
              name: "晚上",
              key: "t",
              status: false,
            },
            {
              name: "晚上",
              key: "u",
              status: false,
            },
          ],
        ];
        this.formalClassTimeList = [
          [
            {
              name: "上午",
              key: "a",
              status: false,
            },
            {
              name: "上午",
              key: "b",
              status: false,
            },
            {
              name: "上午",
              key: "c",
              status: false,
            },
            {
              name: "上午",
              key: "d",
              status: false,
            },
            {
              name: "上午",
              key: "e",
              status: false,
            },
            {
              name: "上午",
              key: "f",
              status: false,
            },
            {
              name: "上午",
              key: "g",
              status: false,
            },
          ],
          [
            {
              name: "下午",
              key: "h",
              status: false,
            },
            {
              name: "下午",
              key: "i",
              status: false,
            },
            {
              name: "下午",
              key: "j",
              status: false,
            },
            {
              name: "下午",
              key: "k",
              status: false,
            },
            {
              name: "下午",
              key: "l",
              status: false,
            },
            {
              name: "下午",
              key: "m",
              status: false,
            },
            {
              name: "下午",
              key: "n",
              status: false,
            },
          ],
          [
            {
              name: "晚上",
              key: "o",
              status: false,
            },
            {
              name: "晚上",
              key: "p",
              status: false,
            },
            {
              name: "晚上",
              key: "q",
              status: false,
            },
            {
              name: "晚上",
              key: "r",
              status: false,
            },
            {
              name: "晚上",
              key: "s",
              status: false,
            },
            {
              name: "晚上",
              key: "t",
              status: false,
            },
            {
              name: "晚上",
              key: "u",
              status: false,
            },
          ],
        ];
        if (this.$refs['saveRuleForm'] !== undefined) {
          this.$refs["saveRuleForm"].resetFields();
        }
        if(newValue==true){
          this.getProvinceList();
        }
      }
    },
    data(){
        let check_referralphone = (rule, value, callback) => {
          if (this.saveRuleForm.sourceType == "5") {
            if (!value) {
              return callback();
            }
            const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
            setTimeout(() => {
              if (phoneReg.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正确的手机号码"));
              }
            }, 100);
          } else {
            callback();
          }
        };
        let checkPhone = (rule, value, callback) => {
          // 当数据为空时，不进行校验
          if (!value) {
            return callback();
          }
          const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
          setTimeout(() => {
            if (phoneReg.test(value)) {
              callback();
            } else {
              callback(new Error("请输入正确的手机号码"));
            }
          }, 100);
        };
        return{
            showDialog:false,
            sectionList:[],//年级
            constellationList:[],
            provinceList:[],
            cityList:[],
            countyList:[],
            parentId: "0",
            saveRuleForm:{
              teachManagerId: "1",
              name: "",
              contact: "",
              sex: "1",
              birthday: "",
              constellationId: "",
              sectionId: "",
              schoolName: "",
              childNature: "",
              provinceId: "",
              cityId: "",
              countyId: "",
              childMotherName: "",
              childMotherPhone: "",
              childFatherName: "",
              childFatherPhone: "",
              parentWechat: "",
              firstClassTimeRemark: "",
              formalClassTimeRemark: "",
              studentsOtherNeeds:""
            },
            saveRules:{
              referralphone: [
                {
                  validator: check_referralphone,
                  trigger: "blur",
                },
              ],
              sectionId: [
                { required: true, message: "年级不能为空", trigger: "change" },
              ],
              name: [
                { required: true, message: "学生姓名不能为空", trigger: "blur" },
              ],
              contact: [
                {
                  validator: (rule, value, callback) => {
                    let mobile = /^1[2|3|4|5|6|7|8|9]\d{9}$/;
                    let phone = /^0\d{2,3}-?\d{7,8}$/;
                    if (value === "") {
                      callback();
                    } else if (!mobile.test(value) && !phone.test(value)) {
                      callback(new Error("请输入正确手机号"));
                    } else {
                      callback();
                    }
                  },
                  trigger: "blur",
                },
              ],
              childMotherPhone: [
                {
                  validator: checkPhone,
                  message: "请输入正确的手机号码",
                },
              ],
              childFatherPhone: [
                {
                  validator: checkPhone,
                  message: "请输入正确的手机号码",
                },
              ],
            },
            sourceTypeList: [
              {
                value: "1",
                label: "地推活动",
              },
              {
                value: "2",
                label: "网络活动",
              },
              {
                value: "3",
                label: "美团活动",
              },
              {
                value: "4",
                label: "A级客户",
              },
              {
                value: "5",
                label: "家长转介绍",
              },
            ],
            firstClassTimeList: [
              [
                {
                  name: "上午",
                  key: "a",
                  status: false,
                },
                {
                  name: "上午",
                  key: "b",
                  status: false,
                },
                {
                  name: "上午",
                  key: "c",
                  status: false,
                },
                {
                  name: "上午",
                  key: "d",
                  status: false,
                },
                {
                  name: "上午",
                  key: "e",
                  status: false,
                },
                {
                  name: "上午",
                  key: "f",
                  status: false,
                },
                {
                  name: "上午",
                  key: "g",
                  status: false,
                },
              ],
              [
                {
                  name: "下午",
                  key: "h",
                  status: false,
                },
                {
                  name: "下午",
                  key: "i",
                  status: false,
                },
                {
                  name: "下午",
                  key: "j",
                  status: false,
                },
                {
                  name: "下午",
                  key: "k",
                  status: false,
                },
                {
                  name: "下午",
                  key: "l",
                  status: false,
                },
                {
                  name: "下午",
                  key: "m",
                  status: false,
                },
                {
                  name: "下午",
                  key: "n",
                  status: false,
                },
              ],
              [
                {
                  name: "晚上",
                  key: "o",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "p",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "q",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "r",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "s",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "t",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "u",
                  status: false,
                },
              ],
            ],
            formalClassTimeList: [
              [
                {
                  name: "上午",
                  key: "a",
                  status: false,
                },
                {
                  name: "上午",
                  key: "b",
                  status: false,
                },
                {
                  name: "上午",
                  key: "c",
                  status: false,
                },
                {
                  name: "上午",
                  key: "d",
                  status: false,
                },
                {
                  name: "上午",
                  key: "e",
                  status: false,
                },
                {
                  name: "上午",
                  key: "f",
                  status: false,
                },
                {
                  name: "上午",
                  key: "g",
                  status: false,
                },
              ],
              [
                {
                  name: "下午",
                  key: "h",
                  status: false,
                },
                {
                  name: "下午",
                  key: "i",
                  status: false,
                },
                {
                  name: "下午",
                  key: "j",
                  status: false,
                },
                {
                  name: "下午",
                  key: "k",
                  status: false,
                },
                {
                  name: "下午",
                  key: "l",
                  status: false,
                },
                {
                  name: "下午",
                  key: "m",
                  status: false,
                },
                {
                  name: "下午",
                  key: "n",
                  status: false,
                },
              ],
              [
                {
                  name: "晚上",
                  key: "o",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "p",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "q",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "r",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "s",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "t",
                  status: false,
                },
                {
                  name: "晚上",
                  key: "u",
                  status: false,
                },
              ],
            ],
            expireTimeOption: {
              disabledDate(date) {
                return date.getTime() > Date.now();
              },
            },
        }
    },
    created(){
      this.getSectionList();
      this.getConstellationList();//星座
      
    },
    methods:{
      confirmSave(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this._saveFlag){
              return
            }
            this._saveFlag = true
            let arr = [],
            firstClassTimeListArr = [],
            formalClassTimeListArr = [];
            this.saveRuleForm.qitaxueke = 0;
            this.saveRuleForm.yuwen = 1;
            this.saveRuleForm.shuxue = 1;
            this.saveRuleForm.yingyu = 1;
            this.saveRuleForm.lishi = 0;
            this.saveRuleForm.zhengzhi = 0;
            this.saveRuleForm.dili = 0;
            this.saveRuleForm.wuli = 0;
            this.saveRuleForm.huaxue = 0;
            this.saveRuleForm.shengwu = 0;
            for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
              for (
                let j = 0, lenJ = this.firstClassTimeList[i].length;
                j < lenJ;
                j++
              ) {
                if (this.firstClassTimeList[i][j].status) {
                  firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
                }
              }
            }
            for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
              for (
                let j = 0, lenJ = this.formalClassTimeList[i].length;
                j < lenJ;
                j++
              ) {
                if (this.formalClassTimeList[i][j].status) {
                  formalClassTimeListArr.push(this.formalClassTimeList[i][j].key);
                }
              }
            }
            this.saveRuleForm.formalClassTime = formalClassTimeListArr.join(",");
            this.saveRuleForm.firstClassTime = firstClassTimeListArr.join(",");
              studentSave(this.saveRuleForm).then((res) => {
                this._saveFlag = false
                if (res.code == 0 && res.msg == "操作成功！") {
                  this.msgSuccess("操作成功！");
                  this.$emit("CB_addStudentStatus")
                } else {
                  this.msgWarn(res.rows);
                }
              }).catch(err => {
                this._saveFlag = false
              });
          }else{
            this.msgWarn("必填字段不能为空！");
            return false;
          }
        })
      },
      getSectionList(){
        getSelectList({
          parentCode: sectionCode,
        }).then((res) => {
          if (res.code == 0) {
            this.sectionList = res.rows;
          }
        });
      },
      getConstellationList() {
        getSelectList({
          parentCode: constellation,
        }).then((res) => {
          if (res.code == 0) {
            this.constellationList = res.rows;
          }
        });
      },
      getProvinceList() {
        return systemAreaList({
          parentId: this.parentId,
        }).then((res) => {
          if (res.code == 0) {
            this.provinceList = res.rows;
          }
        });
      },
      async cityChange() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
      async provinceChangeList() {
        await this.getCityLists();
        this.saveRuleForm.cityId = this.cityList[0].areaId;
        this.cityChangeList();
      },
      getCityLists() {
        return systemAreaList({
          parentId: this.saveRuleForm.provinceId,
        }).then((res) => {
          if (res.code == 0) {
            this.cityList = res.rows;
          }
        });
      },
      async cityChangeList() {
        await this.getCountyList();
        this.saveRuleForm.countyId = this.countyList[0].areaId;
      },
      getCountyList() {
        return systemAreaList({
          parentId: this.saveRuleForm.cityId,
        }).then((res) => {
          if (res.code == 0) {
            this.countyList = res.rows;
          }
        });
      },
      birthdayChange() {
        let birthdayArray = this.saveRuleForm.birthday.split("-");
        let month = Number(birthdayArray[1]); //月
        let date = Number(birthdayArray[2]); //日
        if ((month == 1 && date >= 20) || (month == 2 && date <= 18)) {
          this.saveRuleForm.constellationId = 256;
        }
        if ((month == 2 && date >= 19) || (month == 3 && date <= 20)) {
          this.saveRuleForm.constellationId = 257;
        }
        if ((month == 3 && date >= 21) || (month == 4 && date <= 19)) {
          this.saveRuleForm.constellationId = 246;
        }
        if ((month == 4 && date >= 20) || (month == 5 && date <= 20)) {
          this.saveRuleForm.constellationId = 247;
        }
        if ((month == 5 && date >= 21) || (month == 6 && date <= 21)) {
          this.saveRuleForm.constellationId = 248;
        }
        if ((month == 6 && date >= 22) || (month == 7 && date <= 22)) {
          this.saveRuleForm.constellationId = 249;
        }
        if ((month == 7 && date >= 23) || (month == 8 && date <= 22)) {
          this.saveRuleForm.constellationId = 250;
        }
        if ((month == 8 && date >= 23) || (month == 9 && date <= 22)) {
          this.saveRuleForm.constellationId = 251;
        }
        if ((month == 9 && date >= 23) || (month == 10 && date <= 22)) {
          this.saveRuleForm.constellationId = 252;
        }
        if ((month == 10 && date >= 23) || (month == 11 && date <= 21)) {
          this.saveRuleForm.constellationId = 253;
        }
        if ((month == 11 && date >= 22) || (month == 12 && date <= 21)) {
          this.saveRuleForm.constellationId = 254;
        }
        if ((month == 12 && date >= 22) || (month == 1 && date <= 19)) {
          this.saveRuleForm.constellationId = 255;
        }
      },
      closeDialog(){
        this.$emit("CB_addStudentStatus")
      }
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    
  }
   .infoDetails{
    height:150px;
    padding-top:20px;
    display: flex;
  }
  .infoDetails .left{
    display: flex;
    width:35%;
    min-width:500px;
  }
  .infoDetails .content{
      width:350px;
  }
  .infoDetails .content ul{
    margin-top:30px;
  }
  .infoDetails .content ul li{
    margin-top:5px;
  }
  .infoDetails .content ul span:nth-child(1){
    font-size:15px;
    color:#414a60;
  }
  .infoDetails .content ul span:nth-child(2){
    font-size:15px;
    color:#61687c;
  }
  .infoDetails .right{
    display: flex;
    width:20%;
    justify-content: center;
    align-items: center;
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
</style>