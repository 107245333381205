<template>
    <el-dialog
            title="历史配置"
            center
            width="1200px"
            :visible.sync="dialogvisible"
            :close-on-click-modal="false"
            @close="closeDialog"
        >
            <div class="dialog-content">
                <div class="table-area" >
                    <el-table
                        size="small"
                        :data="data"
                        style="width: 100%; margin-bottom: 20px"
                        row-key="id"
                        border
                        tooltip-effect="dark"
                        ref="historyListTable"
                    >
                    <el-table-column
                        prop="label"
                        label="标签"
                        align="center"
                    >
                        <template slot-scope="scope">
                        {{scope.row.label==1?'预配置讲师':scope.row.label==2?'配置讲师':'无'}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="subjectId"
                        label="学科"
                        align="center"
                        :formatter="dealSubjectId"
                    ></el-table-column>
                    <el-table-column
                        prop="usedClassHour"
                        label="已排课时数"
                        align="center"
                        :formatter="usedClassHourFormatter"
                    ></el-table-column>
                    <el-table-column
                        prop="courseLevel"
                        label="课程级别"
                        align="center"
                        :formatter="dealjibie"
                    ></el-table-column>
                    <el-table-column
                        prop="teacherRealname"
                        label="讲师"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="配置时间"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="source"
                        label="首次课作废情况"
                        align="center"
                    ></el-table-column>
                    </el-table>
                </div>
                <div class="page-area" >
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="params.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="params.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    background
                ></el-pagination>
                </div>
            </div>
    </el-dialog>
</template>
<script>
import {configteacherPlanList} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";
export default {
    props:["historyStatus","historyId"],
    watch:{
        historyStatus(newValue,oldValue){
            this.dialogvisible=newValue;
            this.params.studentId=this.historyId;
            if(newValue==true){
                this.configteacherPlanListFn();
            }
        }
    },
    data(){
        return{
            dialogvisible:false,
            data:[],
            total:0,
            subjectidList:[],
            params:{
                pageNumber: 1,
                pageSize: 10,
                studentId: "",
            }
        }
    },
    created(){
        this.getSubjectList();
    },
    methods:{
        configteacherPlanListFn(){
            configteacherPlanList(this.params).then((res) => {
                this.data = res.rows;
                this.total = res.total;
            });
        },
        handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.configteacherPlanListFn();
        },
        handleCurrentChange(currentPage) {
            this.params.pageNumber = currentPage;
            this.configteacherPlanListFn();
        },
        usedClassHourFormatter(row) {
            if (row.usedClassHour != null) {
                return row.usedClassHour;
            } else {
                return "0";
            }
        },
        dealSubjectId(row) {
            for(var i=0;i<this.subjectidList.length;i++){
                if(this.subjectidList[i].macroId==row.subjectId){
                return this.subjectidList[i].name;
                }
            }
        },
        dealjibie(row) {
            var jibie = "";
            if (row.courseLevel == 1) {
                jibie = "初级课程";
            } else if (row.courseLevel == 2) {
                jibie = "中级课程";
            } else if (row.courseLevel == 3) {
                jibie = "高级课程";
            } else if (row.courseLevel == 4) {
                jibie = "特级课程";
            } else {
                jibie = "正常课程";
            }
            return jibie;
        },
        getSubjectList() {
            getSelectList({
                parentCode: subjectCode,
            }).then((res) => {
                if (res.code == 0) {
                this.subjectidList = res.rows;
                }
            });
        },
        closeDialog(){
            this.$emit('CB_historyStatus')
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    
  }
   .infoDetails{
    height:150px;
    padding-top:20px;
    display: flex;
  }
  .infoDetails .left{
    display: flex;
    width:35%;
    min-width:500px;
  }
  .infoDetails .content{
      width:350px;
  }
  .infoDetails .content ul{
    margin-top:30px;
  }
  .infoDetails .content ul li{
    margin-top:5px;
  }
  .infoDetails .content ul span:nth-child(1){
    font-size:15px;
    color:#414a60;
  }
  .infoDetails .content ul span:nth-child(2){
    font-size:15px;
    color:#61687c;
  }
  .infoDetails .right{
    display: flex;
    width:20%;
    justify-content: center;
    align-items: center;
  }
</style>
