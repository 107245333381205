<template>
  <div>
    <video
      controls
      style="width: 100%; height: 100%"
      :class="[`video_flv_${this.id}`]"
    ></video>
  </div>
</template>
<script>

import flvjs from 'flv.js'

export default {
  data() {
    return {
      flvPlayer: null,
    };
  },
  props: ["pullUrlFlv", "id"],
  components: {},
  computed: {},
  mounted() {
    this.init();
  },
  destroyed() {
    if (this.flvPlayer) {
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
      this.flvPlayer = null; 
    }
  },
  methods: {
    init() {
      if (this.pullUrlFlv && flvjs.isSupported()) {
        try {
          var videoElement = document.querySelector(`.video_flv_${this.id}`);
          this.flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.pullUrlFlv,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          // this.flvPlayer.play();
         
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            if (err == "NetworkError") {
              console.log("暂无直播！");
            }
          });
        } catch (err) {
          console.log("catch err: ", err);
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
</style>