<template>
    <el-dialog
            title="上课记录"
            center
            width="900px"
            :visible.sync="dialogvisible"
            :close-on-click-modal="false"
            @close="closeDialog"
        >
            <div class="dialog-content">
                <div class="table-area" >
                    <el-table
                        size="small"
                        :data="tableData"
                        style="width: 100%; margin-bottom: 20px"
                        row-key="userId"
                        border
                        tooltip-effect="dark"
                    >
                        <el-table-column
                        prop="studentName"
                        label="学科"
                        :formatter="dealSubjectId"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        prop="title"
                        label="课程标题"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        prop="beginTime"
                        label="上课时间"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        prop="usedClassHour"
                        label="课时数"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        prop="teacherName"
                        label="讲师"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        label="课程级别"
                        align="center"
                        :formatter="formatterPlanCourseLevel"
                        ></el-table-column>
                        <el-table-column
                        prop="learnFee"
                        label="课时单价"
                        align="center"
                        :formatter="formatterLearnFee"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="planTeachFee"
                        label="讲师课酬"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                        prop="createTime"
                        label="上课状态"
                        :formatter="formatterAttendClassStatus"
                        align="center"
                        ></el-table-column>
                        <el-table-column
                              prop=""
                              label="课程状态"
                              align="center"
                            >
                              <template slot-scope="scope">
                                  <span v-if="scope.row.abnormalStatus==0">正常</span>
                                  <span v-else style="color:red;">异常</span>
                              </template>
                            </el-table-column>
                    </el-table>
                    <p class="table_info">
                        <span>已排{{countMap.yipaike}}课时</span>
                        <span>已上{{countMap.yishangke}}课时</span>
                        <span>上课中{{countMap.shangkezhong}}课时</span>
                        <span>未上{{countMap.weishangke}}课时</span>
                    </p>
                </div>
                <div class="page-area" >
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="params.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="params.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    background
                ></el-pagination>
                </div>
            </div>
    </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";
import {
  studentClassRecord,
} from "@/api/sales/myCustomer";
    export default {
        data(){
            return{
                params:{
                    pageNumber: 1,
                    pageSize: 10,
                    studentId:"",
                    orderId:"",
                },
                dialogvisible:false,
                tableData:[],
                total:0,
                subjectidList:[],
                countMap:{}
            }
        },
        props:['classRecordStatus','classRecordParams'],
        watch:{
            classRecordStatus(newValue,oldValue){
                this.dialogvisible=newValue;
                this.params.studentId=this.classRecordParams.studentId;
                this.params.orderId=this.classRecordParams.orderId;
                if(newValue==true){
                    this.getData();
                }
            }
        },
        created(){
            this.getSubjectList();
        },
        methods:{
            getData(){
                studentClassRecord(this.params).then((res) => {
                    this.tableData = res.rows;
                    this.countMap = res.countMap;
                    this.total = res.total;
                });
            },
            handleSizeChange(pageSize){
                this.params.pageSize = pageSize;
                this.getData();
            },
            handleCurrentChange(currentPage){
                this.params.pageNumber = currentPage;
                this.getData();
            },
            dealSubjectId(row) {
                for(var i=0;i<this.subjectidList.length;i++){
                    if(this.subjectidList[i].macroId==row.subjectId){
                    return this.subjectidList[i].name;
                    }
                }
                },
            //获取学科
            getSubjectList() {
                getSelectList({
                    parentCode: subjectCode,
                }).then((res) => {
                    if (res.code == 0) {
                    for (var i = 0; i < res.rows.length; i++) {
                        res.rows[i].flag = false;
                    }
                    this.subjectidList = res.rows;
                    }
                });
            },
            formatterPlanCourseLevel(row) {
                var jibie = "";
                if (row.planCourseLevel == 1) {
                    jibie = "初级课程";
                } else if (row.planCourseLevel == 2) {
                    jibie = "中级课程";
                } else if (row.planCourseLevel == 3) {
                    jibie = "高级课程";
                } else if (row.planCourseLevel == 4) {
                    jibie = "特级课程";
                } else {
                    jibie = "正常课程";
                }
                return jibie;
            },
            formatterAttendClassStatus(row) {
                switch (row.attendClassStatus) {
                    case 0:
                    return "未上课";
                    case 1:
                    return "已上课";
                    case 2:
                    return "上课中";
                    default:
                    return "";
                }
            },
            formatterLearnFee(row) {
                return Number(row.usePrice) / Number(row.usedClassHour);
            },

            closeDialog(){
                this.$emit('CB_classRecordShow')
            }
        }
    }
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    
  }
 .table_info {
  float: right;
  span {
    margin-right: 15px;
  }
}
</style>

