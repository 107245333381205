<template>
    <el-dialog
      title="详情"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="800px"
      center
      @close="closeDialog"
    >
      <div class="dialog-content">
        <p class="titP">学生信息</p>
        <ul class="infoUl">
            <li>
                <span>学生姓名：</span>
                <span>{{studentListDetail.name?studentListDetail.name:'暂无数据'}}</span>
            </li>
            <li>
                <span>联系电话：</span>
                <span>{{studentListDetail.account?studentListDetail.account:'暂无数据'}}</span>
            </li>
            <li>
                <span>年级：</span>
                <span>{{studentListDetail.sectionName?studentListDetail.sectionName:'暂无数据'}}</span>
            </li>
            <li>
                <span>性别：</span>
                <span>{{studentListDetail.sex==1?'男':studentListDetail.sex==2?'女':'暂无数据'}}</span>
            </li>
            <li>
                <span>地址：</span>
                <span>
                    {{studentListDetail.provinceName?studentListDetail.provinceName:'暂无数据'}}
                    {{studentListDetail.cityName?studentListDetail.cityName:'暂无数据'}}
                    {{studentListDetail.countyName?studentListDetail.countyName:'暂无数据'}}
                </span>
            </li>
            <li>
                <span>学校：</span>
                <span>{{studentListDetail.schoolName?studentListDetail.schoolName:'暂无数据'}}</span>
            </li>
        </ul>
        <p class="titP" style="margin-top:30px;">跟进记录</p>
        <el-table
            size="small"
            :data="followUpList"
            style="width: 100%; margin-bottom: 20px;margin-top:20px;flex:1;overflow: hidden;"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              type="index"
              prop="studentName"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column prop="" label="跟进记录" align="center">
              <template slot-scope="scope">
               <p>{{scope.row.followContent}}</p>
               <el-image
                class="td-img"
                v-for="(item,index) in scope.row.photoList" :key="index"
                style="width: 100px; height: 100px;margin-left:5px;"
                :src="item.url"
                :preview-src-list="scope.row.imageArray"
              >
              </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="saleName" label="跟进详情" width="200">
              <template slot-scope="scope">
                <p>跟进人：{{ scope.row.teaName }}</p>
                <p>跟进时间：{{ scope.row.createTime }}</p>
                <p>下次跟进时间：{{ scope.row.nextFollowupTime }}</p>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-area">
            <el-pagination
              @size-change="followUpSizeChange"
              @current-change="followUpCurrentChange"
              :current-page="followUpParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="followUpParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              background
            ></el-pagination>
          </div>
      </div>
    </el-dialog>
</template>
<script>
import {
  teacherFollowupRecordList
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import {
  studentInfo
} from "@/api/sales/saleManage";
export default {
    props:['sTimeStatus','sTimeParams'],
    watch:{
        sTimeStatus(newValue,oldValue){
            this.showDialog=newValue;
            this.followUpListFn();
            this.studentInfoFn();
        }
    },
    data(){
        return{
            showDialog:false,
            followUpList:[],
            total:0,
            studentListDetail:{},
            followUpParams: {
                pageNumber: 1,
                pageSize: 10,
                studentId:""
            },
        }
    },
    methods:{
        studentInfoFn(){
            studentInfo(this.sTimeParams.studentId).then((res) => {
                if (res.rows) {
                this.studentListDetail = res.rows;
                }
            });
        },
        followUpListFn() {
            this.followUpParams.studentId = this.sTimeParams.studentId;
            teacherFollowupRecordList(this.followUpParams).then((res) => {
                for (var i = 0; i < res.rows.length; i++) {
                    res.rows[i].imageArray = new Array();
                    for (var k = 0; k < res.rows[i].photoList.length; k++) {
                        res.rows[i].imageArray.push(res.rows[i].photoList[k].url);
                    }
                }
                this.followUpList = res.rows;
                for (var i = 0; i < this.followUpList.length; i++) {
                if (this.followUpList.length) {
                    this.followUpList[i].followContent = this.followUpList[
                    i
                    ].followContent.replace(
                    /<img/g,
                    "<img style='max-width:200px;height:auto;'"
                    );
                }
                }
                this.total = res.total;
            });
        },
        followUpSizeChange(pageSize) {
            this.followUpParams.pageSize = pageSize;
            this.followUpListFn();
        },
        followUpCurrentChange(currentPage) {
            this.followUpParams.pageNumber = currentPage;
            this.followUpListFn();
        },
        closeDialog(){
            this.$emit('CB_sTimeStatus')
        }
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
 
}

  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .titP{
    color:#000;
    font-weight: bold;
  }
  .infoUl{
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    li{
        width:33%;
        line-height: 25px;
        span:nth-child(1){
            color:#909399;
        }
        span:nth-child(2){
            color:#333;
        }
    }
  }
</style>