<template>
  <div>
    <el-dialog
      title="评价老师"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="650px"
      center
      @close="closeDialog"
    >
      <div class="dialog-content">
        <ul class="tUl">
            <li><span>教师姓名：</span><span>{{tEvaluateParams.realname}}</span></li>
            <li><span>教学方向：</span><span>{{identityRowFn(tEvaluateParams.identityType)}}</span></li>
            <li><span>科目：</span><span>{{subjectFn(tEvaluateParams.subjectId)}}</span></li>
        </ul>
        <p class="tTips"><span>客观真实的评价能帮助其他教务快速了解老师</span></p>
        <div class="optDiv">
            <p>描述维度</p>
            <div>
                <span @click="getGoodorBad(item)" :class="item.type==lastShow?'active':''" v-for="(item,index) in checkList" :key="index">{{item.name}}</span>
            </div>
        </div>
        <div class="optDiv" v-show="goodData.length">
            <p style="width:40px;">正面</p>
            <div class="optBtn">
                <span @click="goodClick(item,index)" :class="item.show?'active':''" v-for="(item,index) in goodData" :key="index">{{item.name}}</span>
            </div>
        </div>
        <div class="optDiv" v-show="badData.length">
            <p style="width:40px;">反面</p>
            <div class="optBtn">
                <span @click="badClick(item,index)" :class="item.show?'active':''" v-for="(item,index) in badData" :key="index">{{item.name}}</span>
            </div>
        </div>
        <div class="optContent" ref="optContent">
          <el-input resize="none"  v-model="saveParams.evaluationContent" :rows="3" type="textarea"  placeholder="我还有其他想说的" class="contText"></el-input>
           <!-- <div class="container" @input="areaInput($event)" contenteditable data-text="请输入内容">{{evaluationContent}}</div> -->
          <div v-for="(item,index) in contentData" :key="index" class="contDiv" >
                <p>{{item.parentType}}:</p>
                <div v-for="(items,indexs) in item.selectgoodData" :key="indexs">
                  <span >{{items.name+'#'}}</span>
                </div>
                <el-input resize="none" v-model="item.content" type="textarea" :rows="3" @input="textInput()" placeholder="我还有其他想说的" class="contText"></el-input>
                <!-- <span :ref="'cont'+index" class="cont"  @input="inputChange($event,index)" contenteditable  data-text="我还有其他想说的"   >{{item.content}}</span> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button   size="small" @click="seeHistory()"
            >历史评价</el-button
          >
          <el-button  @click="closeDialog()" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="releaseClick()" size="small"
            >发 布</el-button
          >
        </span>
    </el-dialog>
    <el-dialog
      title="历史评价"
      :visible.sync="hEvaluateDialog"
      :close-on-click-modal="false"
      width="700px"
      center
    > 
      <div class="dialog-content">
        <el-table
          size="small"
          :data="hEvaluateTable"
          style="width: 100%; margin-bottom: 20px;margin-top:20px;"
          row-key="userId"
          border
          tooltip-effect="dark"
        >
            <el-table-column prop="name" label="">
                <template slot-scope="scope">
                    <div>
                      <div style="display:flex;justify-content: space-between;align-items: center;">
                        <span>{{scope.row.teachManagerName}}</span>
                        <span>评价于{{scope.row.createTime}}</span>
                      </div>
                      <div class="myEvaluateDiv">
                        <div>
                          <p>{{scope.row.evaluationContent}}</p>
                          <ul>
                          <li v-show="scope.row.evaluationImage">
                            <span>{{scope.row.evaluationImage.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationImage.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationImage.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationTeaching">
                            <span>{{scope.row.evaluationTeaching.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationTeaching.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationTeaching.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationTeachingStyle">
                            <span>{{scope.row.evaluationTeachingStyle.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationTeachingStyle.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationTeachingStyle.content}}</span>
                          </li>
                          <li v-show="scope.row.evaluationOther">
                            <span>{{scope.row.evaluationOther.parentType }}：</span>
                            <span style="color:#FFBA00;" v-for="(item,index) in scope.row.evaluationOther.selectgoodData" :key="index">{{item.name+'#'}}</span>
                            <span>{{scope.row.evaluationOther.content}}</span>
                          </li>
                        </ul>
                        </div>
                        
                        <div>
                        </div>
                      </div>
                      <p class="tP">
                        <span>{{scope.row.teacherName?scope.row.teacherName:"暂无数据"}}</span>
                        <span>{{identityRowFn(scope.row.identityType)}}</span>
                        <span>{{scope.row.sectionName?scope.row.sectionName:"暂无数据"}}</span>
                      </p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-area">
          <el-pagination
            @size-change="hEvaluateSizeChange"
            @current-change="hEvaluateCurrentChange"
            :current-page="hEvaluateParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="hEvaluateParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="hEvaluateTotal"
            background
          ></el-pagination>
      </div>
      </div>
     </el-dialog>
    </div>
</template>
<script>
import {
 imageGood,
 imageBad,
 teachingGood,
 teachingBad,
 styleGood,
 styleBad,
 otherGood,
 otherBad,
 getSelectList,
 subjectCode
} from "@/api/public";
import calcTextareaHeight from "@/assets/js/calcTextareaHeight.js";
import {
 saveByTeacherManager,
 teacherEvaluatedInfo,
 teacherEvaluatedUpdate,
 getListByTeacherManagerlist,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    props:["tEvaluateStatus","tEvaluateParams","editRow"],
    watch:{
        tEvaluateStatus(newValue,oldValue){
            this.showDialog=newValue;
            if(newValue==true){
              this.checkList=[
                {name:"形象",name2:"形象",id:0,type:"image",show:false,goodData:[],badData:[],selectgoodData:[]},
                {name:"教学",name2:"教学",id:1,type:"teaching",show:false,goodData:[],badData:[],selectgoodData:[]},
                {name:"授课风格",name2:"授课风格",id:2,type:"style",show:false,goodData:[],badData:[],selectgoodData:[]},
                {name:"其他方面",name2:"其他方面",id:3,type:"other",show:false,goodData:[],badData:[],selectgoodData:[]}
              ]
              this.goodData=[];
              this.badData=[];
              this.activeId="";
              this.addContent=[];
              this.contentData=[];
              this.lastShow="";
              this.saveParams={
                evaluationSource:0,
                teacherId:"",
                ifAnonymous:0,
                evaluationImage:"",
                evaluationTeaching:"",
                evaluationTeachingStyle:"",
                evaluationOther:"",
                xmtTeacherEvaluateLabelList:[],
                evaluationContent:""
              };
              this.xmtTeacherEvaluateLabelList=[];
              this.concatArray=[];
              if(this.editRow.id!=undefined){//编辑
                this.teacherEvaluatedInfoFn();
              }
            }
        }
    },
    created(){
     this.getSubjectList();
    },
    data(){
        return{
            height: '30px',
            activeFlag:0,
            content:"",
            showDialog:false,
            goodData:[],
            badData:[],
            activeId:"",
            addContent:[],
            textarea1:"",
            idList:[],
            lastShow:"",
            checkList:[
              {name:"形象",name2:"形象",id:0,type:"image",show:false,goodData:[],badData:[],selectgoodData:[]},
              {name:"教学",name2:"教学",id:1,type:"teaching",show:false,goodData:[],badData:[],selectgoodData:[]},
              {name:"授课风格",name2:"授课风格",id:2,type:"style",show:false,goodData:[],badData:[],selectgoodData:[]},
              {name:"其他方面",name2:"其他方面",id:3,type:"other",show:false,goodData:[],badData:[],selectgoodData:[]}
            ],
            saveParams:{
              evaluationSource:0,
              teacherId:"",
              ifAnonymous:0,
              evaluationImage:"",
              evaluationTeaching:"",
              evaluationTeachingStyle:"",
              evaluationOther:"",
              xmtTeacherEvaluateLabelList:[],
              evaluationContent:""
            },
            concatArray:[],
            hEvaluateDialog:false,
            hEvaluateTable:[],
            hEvaluateTotal:0,
            hEvaluateParams:{
              pageNumber:1,
              pageSize:10,
              teacherId:"",
              evaluationSource:0,
              evaluationGrade:0,
              teachManagerId:""
            },
            subjectIdList:[],
            xmtTeacherEvaluateLabelList:[],
            identityTypeData:[
              {
                  value: 1,
                  label: "学科类-老师",
              },
              {
                  value: 2,
                  label: "学科类-在校大学生",
              },
              {
                  value: 3,
                  label: "素质教育类-老师",
              },
              {
                  value: 4,
                  label: "素质教育类-在校大学生",
              }
          ],
          contentData:[]
        }
    },
    methods:{
          identityRowFn(row) {
            for(var i=0;i<this.identityTypeData.length;i++){
                if(this.identityTypeData[i].value==Number(row)){
                    return this.identityTypeData[i].label;
                }
            }
        },
        goodClick(item){
          item.show = !item.show;
          if(this.concatArray.indexOf(item.macroId)>-1){
            this.concatArray.splice(this.concatArray.indexOf(item.macroId), 1)
          }else{
            this.concatArray.push(item.macroId)
          }
          var selectgoodData=[];
          var flag=false;
          var index=0;
          for(var i=0;i<this.contentData.length;i++){
            if(this.contentData[i].parentType==item.parentType){//一级标签已添加
              index=i;
              flag=true;
              break;
            }
          }
          if(flag){
            if(item.show){
              this.contentData[index].selectgoodData.push(item)
            }else{
              for(var i=0;i<this.contentData[index].selectgoodData.length;i++){
                if(this.contentData[index].selectgoodData[i].macroId==item.macroId){
                  this.contentData[index].selectgoodData.splice(i,1);
                  break;
                }
              }
            }
          }else{
            if(item.show){
              this.contentData.push({parentType:item.parentType,selectgoodData:[item]})
            }
          }
        },
        badClick(item){
          item.show = !item.show;
          if(this.concatArray.indexOf(item.macroId)>-1){
            this.concatArray.splice(this.concatArray.indexOf(item.macroId), 1)
          }else{
            this.concatArray.push(item.macroId)
          }
          var selectgoodData=[];
          var flag=false;
          var index=0;
          for(var i=0;i<this.contentData.length;i++){
            if(this.contentData[i].parentType==item.parentType){//一级标签已添加
              index=i;
              flag=true;
              break;
            }
          }
          if(flag){
            if(item.show){
              this.contentData[index].selectgoodData.push(item)
            }else{
              for(var i=0;i<this.contentData[index].selectgoodData.length;i++){
                if(this.contentData[index].selectgoodData[i].macroId==item.macroId){
                  this.contentData[index].selectgoodData.splice(i,1);
                  break;
                }
              }
            }
          }else{
            if(item.show){
              this.contentData.push({parentType:item.parentType,selectgoodData:[item]})
            }
          }
        },
        textInput(){
        },
        getGoodorBad(item){
          this.activeId=item.id;
          var checkArray=[];
          if(this.lastShow==item.type){
              item.show = !item.show
              if(!item.show){
                for(var i=0;i<this.contentData.length;i++){
                  this.contentData.splice(i,1)
                  break
                }
              }
              for(var k=0;k<this.goodData.length;k++){
                for(var q=0;q<this.concatArray.length;q++){
                  if(this.goodData[k].macroId==this.concatArray[q]){
                    this.concatArray.splice(q,1)
                  }
                }
              }
              console.log(this.concatArray,'contentData..')

            }else{
              item.show=true
            }
            this.lastShow=item.type;
            for(var i=0;i<this.contentData.length;i++){
              for(var j=0;j<this.contentData[i].selectgoodData.length;j++){
                if(this.contentData[i].selectgoodData[j].show==true){
                  checkArray.push(this.contentData[i].selectgoodData[j])
                }
              }
           }
            this.getSelectListFn(item.type);//获取正面反面
        },
        //查看历史评价
        seeHistory(){
          this.hEvaluateDialog=true;
          this.getListByTeacherManagerlistFn();
        },
        hEvaluateSizeChange(pageSize){
          this.hEvaluateParams.pageSize = pageSize;
          this.getListByTeacherManagerlistFn();
        },
        hEvaluateCurrentChange(currentPage){
          this.hEvaluateParams.pageNumber = currentPage;
          this.getListByTeacherManagerlistFn();
        },
        getListByTeacherManagerlistFn(){
          this.hEvaluateParams.teacherId=this.tEvaluateParams.id;
          this.hEvaluateParams.teachManagerId=this.tEvaluateParams.teachManagerId;
          getListByTeacherManagerlist(this.hEvaluateParams).then(res => {  
            this.hEvaluateTable=res.rows;
            for(var i=0;i<this.hEvaluateTable.length;i++){
              this.hEvaluateTable[i].evaluationImage=this.hEvaluateTable[i].evaluationImage?JSON.parse(this.hEvaluateTable[i].evaluationImage):'';
              this.hEvaluateTable[i].evaluationOther=this.hEvaluateTable[i].evaluationOther?eval('('+this.hEvaluateTable[i].evaluationOther+')'):'';
              this.hEvaluateTable[i].evaluationTeaching=this.hEvaluateTable[i].evaluationTeaching?eval('('+this.hEvaluateTable[i].evaluationTeaching+')'):'';
              this.hEvaluateTable[i].evaluationTeachingStyle=this.hEvaluateTable[i].evaluationTeachingStyle?eval('('+this.hEvaluateTable[i].evaluationTeachingStyle+')'):''
            }
            this.hEvaluateTotal=res.total;
          });
        },
        //获取正面反面
      async  getSelectListFn(val){
          var goodStr="",badStr="",parentType="";
          switch (val) {
            case "image"://形象
              goodStr=imageGood;
              badStr=imageBad;
              parentType="形象"
              break;
            case "teaching"://教学
              goodStr=teachingGood;
              badStr=teachingBad;
              parentType="教学"
              break;
            case "style"://授课风格
              goodStr=styleGood;
              badStr=styleBad;
              parentType="授课风格"
              break;
            case "other"://其他方面
              goodStr=otherGood;
              badStr=otherBad;
              parentType="其他方面"
              break;
            default:
              break;
          }
        await  getSelectList({
            parentCode: goodStr
          }).then(res => {
            if (res.code == 0) {
              res.rows.forEach((item,index) => {
                Object.assign(item,{show:false,parentType:parentType})
              });
              this.goodData=res.rows;
            }
          });
        await  getSelectList({
            parentCode: badStr
          }).then(res => {
            if (res.code == 0) {
              res.rows.forEach((item,index) => {
                Object.assign(item,{show:false,parentType:parentType})
              });
              this.badData=res.rows;
            }
          });
          if(this.editRow.id!=undefined){
              for(let i=0;i<this.xmtTeacherEvaluateLabelList.length;i++){
                for(let k=0;k<this.goodData.length;k++){
                  if(this.xmtTeacherEvaluateLabelList[i].evaluateLabel==this.goodData[k].macroId){
                    this.goodData[k].show=true;
                  }
                }
                for(let k=0;k<this.badData.length;k++){
                  if(this.xmtTeacherEvaluateLabelList[i].evaluateLabel==this.badData[k].macroId){
                    this.badData[k].show=true;
                  }
                }
              }
          }else{
            for(let i=0;i<this.concatArray.length;i++){
                for(let k=0;k<this.goodData.length;k++){
                  if(this.concatArray[i]==this.goodData[k].macroId){
                    this.goodData[k].show=true;
                  }
                }
                for(let k=0;k<this.badData.length;k++){
                  if(this.concatArray[i]==this.badData[k].macroId){
                    this.badData[k].show=true;
                  }
                }
              }
          }
        },
        releaseClick(){
          console.log(this.tEvaluateParams,'tEvaluateParams...')
              this.saveParams.teacherId=this.tEvaluateParams.id;
              for(var i=0;i<this.contentData.length;i++){
                for(var j=0;j<this.contentData[i].selectgoodData.length;j++){
                  this.saveParams.xmtTeacherEvaluateLabelList.push({
                    evaluateLabel:this.contentData[i].selectgoodData[j].macroId
                  })
                }
                if(this.contentData[i].parentType=='形象'){
                  this.saveParams.evaluationImage=this.contentData[i]
                }else if(this.contentData[i].parentType=='教学'){
                  this.saveParams.evaluationTeaching=this.contentData[i]
                }else if(this.contentData[i].parentType=='其他方面'){
                  this.saveParams.evaluationOther=this.contentData[i]
                }else if(this.contentData[i].parentType=='授课风格'){
                  this.saveParams.evaluationTeachingStyle=this.contentData[i]
                }
              }
            if(!this.editRow.id){
              saveByTeacherManager(this.saveParams).then(res => {
                if(res.code==0){
                  this.msgSuccess("操作成功！");
                  this.closeDialog()
                }
              });
            }else{
              this.saveParams.id=this.editRow.id;
              this.saveParams.teacherId=this.editRow.teacherId;
              teacherEvaluatedUpdate(this.saveParams).then(res => {
                if(res.code==0){
                  this.msgSuccess("操作成功！");
                  this.closeDialog();
                }
              });
            }
        },
        teacherEvaluatedInfoFn(){
          teacherEvaluatedInfo(this.tEvaluateParams.id).then(res => {
            if(res.code==0){
              var showFlag=false;
              this.editId=res.rows.id;
              this.saveParams.evaluationContent=res.rows.evaluationContent;
              this.xmtTeacherEvaluateLabelList= res.rows.xmtTeacherEvaluateLabelList;
              res.rows.evaluationTeaching=res.rows.evaluationTeaching?JSON.parse(res.rows.evaluationTeaching):'';
              res.rows.evaluationTeachingStyle=res.rows.evaluationTeachingStyle?JSON.parse(res.rows.evaluationTeachingStyle):'';
              res.rows.evaluationImage=res.rows.evaluationImage?JSON.parse(res.rows.evaluationImage):"";
              res.rows.evaluationOther=res.rows.evaluationOther?JSON.parse(res.rows.evaluationOther):"";
                if(res.rows.evaluationImage){
                  this.lastShow='image';
                }
                else if(res.rows.evaluationTeaching){
                  this.lastShow='teaching';
                }
                else if(res.rows.evaluationTeachingStyle){
                  this.lastShow='style';
                }
                else if(res.rows.evaluationOther){
                  this.lastShow='other';
                }
               if(res.rows.evaluationImage){
                this.contentData.push(res.rows.evaluationImage)
               }
               if(res.rows.evaluationTeaching){
                this.contentData.push(res.rows.evaluationTeaching)
               }
               if(res.rows.evaluationTeachingStyle){
                this.contentData.push(res.rows.evaluationTeachingStyle)
               }
               if(res.rows.evaluationOther){
                this.contentData.push(res.rows.evaluationOther)
               }
                this.getSelectListFn(this.lastShow);//获取正面反面
                // for(var i=0;i<res.rows.xmtTeacherEvaluateLabelList.length;i++){
                //   for(var j=0;j<this.goodData.length;j++){
                //     if(res.rows.xmtTeacherEvaluateLabelList[i].evaluateLabel==this.goodData[j].macroId){
                //       this.goodData[j].show=true;
                //     }
                //   }
                // }
            }
          });
        },
        subjectFn(row) {
          let result = []
          let list=this.subjectIdList;
          var subjectArray=row?row.split(','):[];
          for(let  i=0;i<list.length;i++){
            let tempArr1=list[i];
            for(let j=0;j<subjectArray.length;j++){
              let tempArr2=subjectArray[j]
              if(tempArr2==tempArr1.macroId){
                result.push(tempArr1.name)
                break;
              }
            }
          }
          return result.join();
        },
        inputChange(event,index){
          this.contentData[index].content=event.target.innerHTML;
        },
        
        getSubjectList() {
          return getSelectList({
            parentCode: subjectCode
          }).then(res => {
            if (res.code == 0) {
              this.subjectIdList = res.rows;
            }
          });
        },
        closeDialog(){
            this.$emit("CB_tEvaluateStatus")
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .row_detail {
      padding: 10px 0;
    }
    .tUl{
        display: flex;
        margin-top:30px;
        li{
            flex:1;
            span:nth-child(1){
                color:#909399;
            }
            span:nth-child(2){
                color:#333;
            }
        }
    }
    .tTips{
        border:1px  dashed #ccc;
        position: relative;
        margin-top:40px;
        span{
            position: absolute;
            background: #fff;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            padding:4px;
            width:290px;
        }
    }
    .optDiv{
        display: flex;
        align-items: center;
        margin-top:30px;
        p{
            font-weight: bold;
            width:75px;
        }
        div{
            margin-left:20px;
            flex:1;
            span{
                color:#606266;
                display: inline-block;
                padding:7px 13px;
                border-radius: 4px;
                border:1px solid #DCDFE6;
                margin-right:10px;
                cursor: pointer;
            }
        }
    }
    .optBtn span{
      margin:6px 0;
    }
    .active{
      background: #E8F4FF !important;
      border:1px solid #A3D3FF !important;
      color:#1890FF !important;
    }
    .optContent{
      border:1px solid #ccc;
      margin-top:40px;
      width:100%;
      min-height: 100px;
      padding:10px;
      height:auto;
    }
    .contDiv{
      margin:7px 0;
      line-height: 26px;
      >p{
        color:#333;
        display: inline-block;
      }
      div span{
        color:#FFBA00;
      }
      div{
        color:#606266;
        display: inline-block;
      }
    }
    .optInput{
      border:0 !important;
      display: inline-block !important;
    }
    .cont{
      min-width: 100px;
      padding:6px;
      &:focus{ outline: none; }
      &:empty:before{
        content:attr(data-text);
        // color:rgb(191,191,191);
      }
    }
    .myEvaluateDiv{
      display: flex;
      justify-content: space-between;
      // align-items: center;
      ul{
        li{
          margin:6px 0;
        }
      }
      div{
        display: flex;
        flex-direction: column;
        .btn{
          margin:4px 0;
        }
      }
      
    }
    .tP{
      width:80%;
      display: flex;
      span{
        flex:1;
        color:#909399;
      }
    }
}
.container {
      width: 100%;
      font-size: 13px;
      line-height: 16px;
       &:focus{ outline: none; }
      &:empty:before{
        content:attr(data-text);
        color:rgb(191,191,191);
        font-size:12px;
      }
    }
  >>>.el-textarea__inner{
        resize: none !important;
        border:none;
        min-width:40px !important;
        font-size:13px;
        font-family: '微软雅黑';
        &::placeholder {
          color:rgb(191,191,191);
          font-size:12px;
        }
      }
</style>