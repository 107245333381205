<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">课表查询</el-menu-item>
          <el-menu-item index="2">老师课表</el-menu-item>
          <el-menu-item index="3">学生课表</el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- <div class="btn-area" v-show="handleKey == 1">
            <div class="select-condition-item">
                    <el-switch
                      v-model="switchValue"
                      @change="switchChange()"
                      active-color="#1890ff"
                      inactive-color="#1890ff"
                      active-text="一对一课表"
                      inactive-text="一对多课表">
                    </el-switch>
                </div>
        </div> -->
    <div
      class="btn-area select-condition"
      v-show="handleKey == 1 && switchValue == true"
    >
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="课程标题/班级名称/教师姓名/学生姓名"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="findProductItemPageFn()"
          >搜索</el-button
        >
      </div>
    </div>
    <div
      class="btn-area select-condition"
      v-show="handleKey == 1 && switchValue == true"
    >
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">首次课：</span>
          <el-select
            v-model="listParams.isFirstClass"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in isFirstClassList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课状态：</span>
          <el-select
            v-model="listParams.attendClassStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in attendClassStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课模式：</span>
          <el-select
            v-model="listParams.classMode"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in classModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      class="btn-area select-condition"
      v-show="handleKey == 1 && switchValue == false"
    >
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listMoreParams.name"
            placeholder="课程标题/班级名称/教师姓名/学生姓名"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学段：</span>
          <el-select
            v-model="listMoreParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in moreSectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listMoreParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课状态：</span>
          <el-select
            v-model="listMoreParams.attendClassStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in attendClassStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="findMoreProductItemPage()"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area" v-show="handleKey == 1 && switchValue == true">
      <el-table
        size="small"
        :data="oneTooneData"
        style="width: 100%; margin-bottom: 20px"
        height="100%"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="上课形式"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.classMode == 1">线上</span>
            <span v-show="scope.row.classMode == 2">线下</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="首次课" align="center" width="100">
          <template slot-scope="scope">
            <span v-show="scope.row.isFirstClass == 1" style="color: red"
              >是</span
            >
            <span v-show="scope.row.isFirstClass == 0">否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="课程标题"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="teacherRealName"
          label="教师"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.teacherOnlineStatus == 1"
              >{{ scope.row.teacherRealName }}<span>（已上线）</span>
            </span>
            <span
              v-show="scope.row.teacherOnlineStatus == 0"
              :class="{ statusActive: scope.row.attendClassStatus == 1 }"
              >{{ scope.row.teacherRealName
              }}<span style="color: red">（未上线）</span></span
            >
            <span v-show="scope.row.attendClassStatus == 1">{{
              scope.row.teacherRealName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="studentName"
          label="学生"
          align="center"
          width="140"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.studentOnlineStatus == 1"
              >{{ scope.row.studentName }}<span>（已上线）</span>
            </span>
            <span
              v-show="scope.row.studentOnlineStatus == 0"
              :class="{ statusActive: scope.row.attendClassStatus == 1 }"
              >{{ scope.row.studentName
              }}<span style="color: red">（未上线）</span></span
            >
            <span v-show="scope.row.attendClassStatus == 1">{{
              scope.row.studentName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上课状态"
          prop=""
          width="90"
          :formatter="formatterAttendClassStatus"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
          width="70"
        ></el-table-column>
        <el-table-column prop="" label="旁听二维码" align="center" width="90">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="seeQrcode(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="" label="补课时" align="center" width="100">
          <template slot-scope="scope">
            <span
              v-if="scope.row.classHourType == 7"
              @click="yesHover(scope.row)"
              style="color: red; cursor: pointer"
              >是</span
            >
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="classNoticeClick(scope.row)"
              :disabled="noticeDisabled(scope.row)"
              >通知</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="
                scope.row.attendClassStatus == 1 ||
                scope.row.attendClassStatus == 2
                  ? true
                  : false
              "
              @click="editOneCourse(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="
                scope.row.attendClassStatus == 2 ||
                scope.row.attendClassStatus == 0 ||
                recoveryType(scope.row)
                  ? true
                  : false
              "
              @click="recoveryCourse(scope.row)"
              >恢复课程</el-button
            >
            <el-button
              @click="removeClass(scope.row)"
              slot="reference"
              type="text"
              size="small"
              :disabled="
                scope.row.attendClassStatus == 1 ||
                scope.row.attendClassStatus == 2
                  ? true
                  : false
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="handleKey == 1 && switchValue == true">
      <el-pagination
        @size-change="onTooneSizeChange"
        @current-change="onTooneCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="oneTooneTotal"
        background
      ></el-pagination>
    </div>
    <div class="table-area" v-show="handleKey == 1 && switchValue == false">
      <el-table
        size="small"
        :data="oneToMoreData"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        height="100%"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          prop="studentName"
          label="111序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="title" label="首次课" align="center" width="100">
          <template slot-scope="scope">
            <span v-show="scope.row.isFirstClass == 1">是</span>
            <span v-show="scope.row.isFirstClass == 0">否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sectionName"
          label="学段"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="roomName"
          label="班级名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="课程标题"
          align="center"
        ></el-table-column>
        <el-table-column prop="teacherRealName" label="教师" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.teacherOnlineStatus == 1"
              >{{ scope.row.teacherRealName }}<span>（已上线）</span>
            </span>
            <span
              v-show="scope.row.teacherOnlineStatus == 0"
              :class="{ statusActive: scope.row.attendClassStatus == 1 }"
              >{{ scope.row.teacherRealName
              }}<span style="color: red">（未上线）</span></span
            >
            <span v-show="scope.row.attendClassStatus == 1">{{
              scope.row.teacherRealName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="peopleUsedNum"
          label="学生人数"
          align="center"
          width="95"
        >
          <template slot-scope="scope">
            <div
              style="text-decoration: underline; cursor: pointer"
              @click="daalPeopleNum(scope.row)"
            >
              {{ scope.row.peopleNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="上课状态"
          prop="teachManagerName"
          :formatter="formatterAttendClassStatus"
          align="center"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
          width="70"
        ></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              :disabled="
                scope.row.attendClassStatus == 1 ||
                scope.row.attendClassStatus == 2
                  ? true
                  : false
              "
              @click="moreEdit(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="
                scope.row.attendClassStatus == 2 ||
                scope.row.attendClassStatus == 0 ||
                recoveryType(scope.row)
                  ? true
                  : false
              "
              @click="recoveryCourseMore(scope.row)"
              >恢复课程</el-button
            >
            <el-button
              :disabled="
                scope.row.attendClassStatus == 1 ||
                scope.row.attendClassStatus == 2
                  ? true
                  : false
              "
              slot="reference"
              type="text"
              size="small"
              @click="cancelFnMore(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="handleKey == 1 && switchValue == false">
      <el-pagination
        @size-change="oneToMoreSizeChange"
        @current-change="oneToMoreCurrentChange"
        :current-page="listMoreParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listMoreParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="oneToMoreTotal"
        background
      ></el-pagination>
    </div>
    <div class="btn-area select-condition" v-show="handleKey == 2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="getProductParams.name"
            placeholder="课程标题/班级名称/教师姓名/学生姓名"
            size="small"
            style="min-width: 120px"
            @blur="getSyllabusPageDeptOneFn()"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            v-model="timePickerValueTeacher"
            type="date"
            @change="teacherPickerChange"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课状态：</span>
          <el-select
            v-model="getProductParams.attendClassStatus"
            placeholder="请选择"
            size="small"
            @change="getSyllabusPageDeptOneFn()"
          >
            <el-option
              v-for="item in attendClassStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课模式：</span>
          <el-select
            v-model="getProductParams.classMode"
            placeholder="请选择"
            size="small"
            @change="getSyllabusPageDeptOneFn()"
          >
            <el-option
              v-for="item in classModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="getSyllabusPageDeptOneFn()"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="exportExcel('teacher')"
          >导出Excel表</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-bell"
          @click="teaOneClick()"
          >一键通知</el-button
        >
      </div>
    </div>
    <div class="searchTime" v-show="handleKey == 2">
      <span @click="timeTab(-1)"><<<</span>
      <span
        >{{ tSearchTime }}
        <span style="margin-left: 10px">星期{{ tSearchWeek }}</span> </span
      ><span @click="timeTab(1)">>>></span>
    </div>
    <ul v-show="handleKey == 2" class="census_ul">
      <li>总节数</li>
      <li>{{ total1 }}</li>
      <li>{{ total2 }}</li>
      <li>{{ total3 }}</li>
      <li>{{ total4 }}</li>
      <li>{{ total5 }}</li>
      <li>{{ total6 }}</li>
      <li>{{ total7 }}</li>
      <li>{{ total8 }}</li>
      <li>
        合计：{{
          Number(total1) +
          Number(total2) +
          Number(total3) +
          Number(total4) +
          Number(total5) +
          Number(total6) +
          Number(total7) +
          Number(total8)
        }}
      </li>
    </ul>
    <p v-show="handleKey == 2">共计{{ teaIndex }}名老师</p>
    <div class="table-area" v-show="handleKey == 2" style="margin-top: 10px">
      <el-table
        size="small"
        :row-class-name="rowClassName"
        :data="teacherList"
        style="width: 100%; margin-bottom: 20px"
        height="100%"
        row-key="id"
        border
        id="teacherRef"
        tooltip-effect="dark"
        ref="teacherRef"
      >
        <el-table-column
          prop="teacherRealName"
          label="讲师姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="canShow" label="6:00-8:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter1(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="canShow" label="8:00-10:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter2(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="" label="10:00-12:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter3(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="13:00-15:00" align="center" prop="">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter4(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="15:00-17:00" align="center" prop="">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter5(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="17:00-19:00" align="center" prop="">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter6(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="19:00-21:00" align="center" prop="">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter7(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="21:00-24:00" align="center" prop="">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourFormatter8(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="teacherTZ(scope.row)"
              :disabled="scope.row.lskbTz == 1 ? true : false"
              @click="noticeClick(scope.row, 'teacher')"
              >通知</el-button
            >
            <el-button type="text" size="small" @click="copy1(scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="btn-area select-condition" v-show="handleKey == 3">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="getProductParamsStudent.name"
            placeholder="学员姓名/联系方式/教师姓名/产品名称"
            size="small"
            style="min-width: 120px"
            @blur="getSyllabusPageStudentFn()"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            v-model="studentTimePicker"
            type="date"
            @change="studentPickerChange"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课状态：</span>
          <el-select
            v-model="getProductParamsStudent.attendClassStatus"
            placeholder="请选择"
            size="small"
            @change="getSyllabusPageStudentFn()"
          >
            <el-option
              v-for="item in attendClassStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课模式：</span>
          <el-select
            v-model="getProductParamsStudent.classMode"
            placeholder="请选择"
            size="small"
            @change="getSyllabusPageStudentFn()"
          >
            <el-option
              v-for="item in classModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="getSyllabusPageStudentFn()"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="exportExcel('student')"
          >导出Excel表</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="stuOneClick()"
          icon="el-icon-bell"
          >一键通知</el-button
        >
      </div>
    </div>
    <div class="searchTime" v-show="handleKey == 3">
      <span @click="timeStudentTab(-1)"><<<</span>
      <span
        >{{ tStudentTime }}
        <span style="margin-left: 10px">星期{{ tStudentWeek }}</span> </span
      ><span @click="timeStudentTab(1)">>>></span>
    </div>
    <ul v-show="handleKey == 3" class="census_ul">
      <li>总节数</li>
      <li>{{ stuTotal1 }}</li>
      <li>{{ stuTotal2 }}</li>
      <li>{{ stuTotal3 }}</li>
      <li>{{ stuTotal4 }}</li>
      <li>{{ stuTotal5 }}</li>
      <li>{{ stuTotal6 }}</li>
      <li>{{ stuTotal7 }}</li>
      <li>{{ stuTotal8 }}</li>
      <li>
        合计：{{
          Number(stuTotal1) +
          Number(stuTotal2) +
          Number(stuTotal3) +
          Number(stuTotal4) +
          Number(stuTotal5) +
          Number(stuTotal6) +
          Number(stuTotal7) +
          Number(stuTotal8)
        }}
      </li>
    </ul>
    <p v-show="handleKey == 3">共计{{ stuIndex }}名学生</p>
    <div class="table-area" v-show="handleKey == 3" style="margin-top: 10px">
      <el-table
        size="small"
        :row-class-name="rowStuClassName"
        :data="studentList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        height="100%"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名/班级名称"
          align="center"
        >
          <template slot-scope="scope">
            <span
              @click.stop="seeStudentInfo(scope.row)"
              style="cursor: pointer"
              >{{
                scope.row.studentName
                  ? scope.row.studentName
                  : scope.row.roomName
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="6:00-8:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent1(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="videoType" label="8:00-10:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent2(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="10:00-12:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent3(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="13:00-15:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent4(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="15:00-17:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent5(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="17:00-19:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent6(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="19:00-21:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent7(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="21:00-24:00" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="异常原因"
              width="200"
              trigger="click"
              :disabled="reasonValue ? false : true"
              :content="reasonValue"
            >
              <div
                slot="reference"
                @click="seeReasonClass(scope.row, $event)"
                v-html="dayHourStudent8(scope.row)"
              ></div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="studentTZ(scope.row)"
              :disabled="scope.row.xskbTz == 1 ? true : false"
              @click="noticeClick(scope.row, 'student')"
              >通知</el-button
            >
            <el-button type="text" size="small" @click="copy2(scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="编辑课程"
      :visible.sync="singlePopup"
      :close-on-click-modal="false"
      width="460px"
      center
      custom-class="add_class"
    >
      <div class="dialog-content">
        <el-form
          :model="singleRuleForm"
          status-icon
          :rules="singlePopupRules"
          ref="singleRuleForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="singleRuleForm.subjectId"
                placeholder="请选择"
                size="small"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in singSubjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType != 7">
            <el-form-item label="产品类型" prop="classHourStatus">
              <el-radio-group
                v-model="classHourStatus"
                @change="classHourStatusChange()"
              >
                <el-radio label="1">一对一充值</el-radio>
                <el-radio label="2">一对一</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType != 7">
            <el-form-item label="收费类型" prop="classHourType">
              <el-select
                @change="classHourTypeChange"
                v-model="singleRuleForm.classHourType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in classHourTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-show="classHourStatus == item.show"
                >
                </el-option>
              </el-select>
              <p v-show="singleRuleForm.classHourType == 3">
                剩余金额：{{ accountBalance }}
              </p>
              <p v-show="singleRuleForm.classHourType == 5">
                剩余课时：{{ remainingClassHours }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="classHourStatus == 2">
            <el-form-item label="产品名称" prop="productId">
              <el-select
                v-model="singleRuleForm.productId"
                placeholder="请选择"
                size="small"
                value-key="id"
                @change="surplusNumFn"
              >
                <el-option
                  v-for="item in buyProductList"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <p v-show="singleRuleForm.classHourType == 1">
                剩余金额：{{ surplusNum }}
              </p>
              <p v-show="singleRuleForm.classHourType == 2">
                剩余课时：{{ surplusNum }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课模式" prop="classMode">
              <el-radio-group
                v-model="singleRuleForm.classMode"
                @change="classModeChange()"
              >
                <el-radio label="1">线上</el-radio>
                <el-radio label="2">线下</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="singleRuleForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
              <el-input-number
                v-model="singleRuleForm.usedClassHour"
                type="number"
                placeholder="请输入内容"
                :max="Number(4)"
                :min="Number(1)"
                @change="classHourChange"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课时间" prop="beginTime">
              <el-date-picker
                v-model="singleRuleForm.beginTime"
                type="datetime"
                placeholder="选择日期"
                format="yyyy-MM-dd HH:mm"
                :picker-options="beforePickerOptions"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
              <el-input
                @click.native="getTeacherId()"
                readonly
                v-model="newteacherName"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classMode == 2">
            <el-form-item label="上课地址" prop="detailAddress">
              <el-input
                @click.native="getDetailAddress()"
                readonly
                v-model="singleRuleForm.classAddress"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classMode == 1">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="singleRuleForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-switch
                v-model="singleRuleForm.isFirstClass"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-switch
                :disabled="!hasCdnMenu"
                @click.native="handleAuthTip('hasCdnMenu', hasCdnMenu)"
                :title="!hasCdnMenu ? '请联系管理员开同此功能' : ''"
                v-model="singleRuleForm.isPrisonClass"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-switch
                :disabled="!hasRecordMenu"
                @click.native="handleAuthTip('hasRecordMenu', hasRecordMenu)"
                :title="!hasRecordMenu ? '请联系管理员开同此功能' : ''"
                v-model="singleRuleForm.isPlayback"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType == 7">
            <el-form-item label="讲师课酬" prop="ifClassRemuneration">
              <el-radio-group v-model="singleRuleForm.ifClassRemuneration">
                <el-radio label="0">计算</el-radio>
                <el-radio label="1">不计算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="price_tip">
        <div class="class_fee">
          本堂课流量费用：<span>{{ singleRuleForm.expense }}</span
          >元
        </div>
        <div class="account_fee">账户余额：{{ organTotalBalance }}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="singlePopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="sureOneSave('singleRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="编辑课程"
      :visible.sync="morePopup"
      :close-on-click-modal="false"
      width="400px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="moreRuleForm"
          status-icon
          :rules="morePopupRules"
          ref="moreRuleForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="moreRuleForm.subjectId"
                placeholder="请选择"
                size="small"
                @change="selectMoreChange"
              >
                <el-option
                  v-for="item in singSubjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="moreRuleForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课时间" prop="beginTime">
              <el-date-picker
                v-model="moreRuleForm.beginTime"
                type="datetime"
                :picker-options="expireTimeOptionBefore"
                placeholder="选择日期"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
              <el-input-number
                v-model="moreRuleForm.usedClassHour"
                type="number"
                placeholder="请输入内容"
                :max="Number(4)"
                :min="Number(1)"
                @change="classHourChange"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
              <el-input
                @click.native="getMoreTeacher()"
                readonly
                v-model="moreTeacherName"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="moreRuleForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-radio-group v-model="moreRuleForm.isFirstClass">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="price_tip">
        <div class="class_fee">
          本堂课流量费用：<span>{{ moreRuleForm.expense }}</span
          >元
        </div>
        <div class="account_fee">账户余额：{{ organTotalBalance }}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="morePopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="sureMoreSave('moreRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="moreTeacherPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="matchingTeacherList"
            style="width: 100%; margin-bottom: 20px"
            border
            height="400px"
            tooltip-effect="dark"
            :row-key="getRowKeys"
            ref="resTeacherListTable"
            @selection-change="handleTeacherSelectionChange"
            @row-click="rowTeacherItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="学科"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="teacherRealname"
              label="讲师"
              align="center"
            ></el-table-column>
            <el-table-column prop="createTime" label="期望薪资" align="center">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="teachFee"
              label="讲师课酬"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="classSinglePrice"
              label="课时单价"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <!-- <div class="page-area">
            <el-pagination
              @size-change="handleTeacherNewSizeChange"
              @current-change="handleTeacherNewCurrentChange"
              :current-page="teacherParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="teacherParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="teacherTotalnew"
              background
            ></el-pagination>
          </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moreTeacherPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <p v-show="centerType == 'student'" style="padding: 7px 0">
        该时间段"{{ centerStudentName }}"学生已有课程安排
      </p>
      <p v-show="centerType == 'teacher'" style="padding: 7px 0">
        该时间段"{{ centerTeacherName }}"老师已有课程安排
      </p>
      <p style="padding: 7px 0">上课时间：{{ centerBeginTime }}</p>
      <p style="padding: 7px 0">上课时长：{{ centerUsedClassHour }}小时</p>
      <p style="padding: 7px 0">辅导老师：{{ centerTeacherName }}老师</p>
      <p style="padding: 7px 0">上课学生：{{ centerStudentName }}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="studentListVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="save-dialog-content" style="margin-top: 20px">
          <div class="table-area">
            <el-table
              size="small"
              :data="studentListData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
              highlight-current-row
              ref="studentRef"
              tooltip-effect="dark"
            >
              <el-table-column
                prop="name"
                label="学生姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sex"
                label="老师姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="account"
                label="上课时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="课程标题"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="birthday"
                label="联系方式"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <!-- <div class="page-area">
            <el-pagination
              @size-change="studentHandleSizeCourseChange"
              @current-change="studentHandleCourseChange"
              :current-page="studentDataList.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="studentDataList.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studentDataTotal"
              background
            ></el-pagination>
          </div> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="学生状态"
      :visible.sync="studentStateSync"
      width="500px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="peopleNumDiv">
          <p style="display: flex">
            <span style="width: 50%; text-align: center">学生姓名</span
            ><span style="width: 50%; text-align: center">在线状态</span>
          </p>
          <ul>
            <li
              style="display: flex; margin-top: 10px"
              v-for="(item, index) in peopleNumList"
              :key="index"
            >
              <span style="width: 50%; text-align: center">{{ item.name }}</span
              ><span style="width: 50%; text-align: center">{{
                item.studentOnlineStatus == 1 ? "在线" : "未在线"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="请选择删除原因"
      :visible.sync="removeClassVisible"
      :close-on-click-modal="false"
      width="400px"
      center
    >
      <div>
        <div class="item_select" style="padding: 30px 0">
          <el-radio v-model="removeClassRadio" label="1">学生原因</el-radio>
          <el-radio v-model="removeClassRadio" label="2">老师原因</el-radio>
          <el-radio v-model="removeClassRadio" label="3">其他原因</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeClassVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="cancelFn()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="qrcodePopup"
      :close-on-click-modal="false"
      width="470px"
      center
    >
      <div class="dialog-content" style="padding-bottom: 15px">
        <p style="text-align: center; font-size: 16px">
          电脑旁听地址：<a
            target="_blank"
            :href="`${$myStore.webPc}` + `${roomId}`"
            style="color: #1890ff; text-decoration: none"
            >{{ $myStore.webPc }}{{ roomId }}</a
          >
        </p>
        <p style="margin: 10px auto" class="qrcodeDiv">
          <vue-qr ref="Qrcode" :text="dataObj.text" qid="testQrId"></vue-qr>
          <a
            :href="exportLink"
            @click="downloadImg"
            :download="downloadFilename"
            style="color: #1890ff; text-decoration: none"
            >下载二维码</a
          >
        </p>
        <p style="font-size: 16px; text-align: center; font-weight: bold">
          科目：{{ qrcodeSubject }}
        </p>
        <p
          style="
            font-size: 16px;
            margin-top: 15px;
            text-align: center;
            font-weight: bold;
          "
        >
          时间：{{ qrcodeTime }}
        </p>
      </div>
    </el-dialog>
    <el-dialog
      title="上课地址"
      :visible.sync="detailAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="btn-area">
          <div class="right">
            <el-button-group>
              <el-button type="default" size="small" @click="addAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                <span>新增</span>
              </el-button>
              <el-button type="default" size="small" @click="editAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-edit" />
                </svg>
                <span>编辑</span>
              </el-button>
              <el-button type="default" size="small" @click="removeAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-trash" />
                </svg>
                <span>删除</span>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="addressListData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            tooltip-effect="dark"
            ref="addressListData"
            @selection-change="addressListDataChange"
            @row-click="addressListDataItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="地址" align="center">
              <template slot-scope="scope">
                <span
                  v-show="scope.row.isDefault == 1"
                  style="
                    padding: 5px 8px;
                    background: red;
                    color: #fff;
                    border-radius: 4px;
                  "
                  >默认</span
                >
                <span
                  >{{ scope.row.provinceName }}{{ scope.row.cityName
                  }}{{ scope.row.countyName
                  }}{{ scope.row.detailAddress }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChangeAddress"
            @current-change="handleCurrentChangeAddress"
            :current-page="classAddressData.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="classAddressData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalData"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="clickAddressSure()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="addAddressParams.title"
      :visible.sync="addAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveAddressForm"
          status-icon
          ref="saveAddressForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="所在地区">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveAddressForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="addressProvinceChange"
                >
                  <el-option
                    v-for="item in addressProvinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="addressCityChange"
                >
                  <el-option
                    v-for="item in addressCityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in addressCountyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-col :span="12">
              <el-input v-model="saveAddressForm.detailAddress"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="默认地址" prop="isDefault">
            <el-radio-group v-model="saveAddressForm.isDefault">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmAddressSave('saveAddressForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <sTimeTableInfo
      ref="sTimeTableInfo"
      :sTimeStatus="sTimeStatus"
      :sTimeParams="sTimeParams"
      @CB_sTimeStatus="CB_sTimeStatus"
    ></sTimeTableInfo>
    <el-dialog
      title="问题课程"
      center
      width="350px"
      :close-on-click-modal="false"
      :visible="infoShow"
    >
      <div style="margin-bottom: 20px">
        <p style="text-align: center">
          <span>{{ infoParams.beginTime }}-{{ infoParams.endTime }}</span
          ><span style="margin: 0 10px">{{ infoParams.subjectName }}</span
          ><span>{{ infoParams.teacherRealName }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="infoShow = false"
          >返回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VueQr from "vue-qr";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import { saveOneToMore } from "@/api/administration/tubeTeachBusiness/studentManage.js";
import {
  getSyllabusPage,
  findProductItemPage,
  findMoreProductItemPage,
  xmtProductItemUpdate,
  getProductByStudentId, //该学生所购买过的产品
  getSyllabusPageDeptOne,
  xmtProductItemInfo,
  configteacherPlan,
  checkTeacherClassTime, //老师是否被占用
  checkStudentClassTime, //学生是否被占用
  getStudentInfoByRoomId,
  getResiduePrice,
  xmtProductItemDelete,
  xmtProductItemSave,
  checkTeacherOrganClassTime,
  studentClassAddressList,
  studentClassAddressSave, //上课地址列表新增
  studentClassAddressUpdate, //上课地址列表修改
  studentClassAddressRemove, //上课地址列表删除
  studentClassAddressInfo, //上课地址列表详情
  checkDefault,
  resumeClass,
  saveOneToOne,
  deleteMakeUpTimeForClass,
  makeUpTimeForClass,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { getFlowFeeByMode, getOrganBalance } from "@/api/system/systemManage";
import FileSaver from "file-saver";
import { studentInfo } from "@/api/sales/saleManage";
import {
  systemAreaList,
  sendCourseRemind,
  batchSendCourseRemind,
  oneClickNotification,
} from "@/api/system/systemManage";
import sTimeTableInfo from "./dialog/studentManageFile/sTimeTableInfo.vue";
import XLSX from "xlsx";
import { mapState } from "vuex";
export default {
  components: { VueQr, sTimeTableInfo },
  data() {
    let check_productIdOne = (rule, value, callback) => {
      if (this.classHourStatus == 2) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      sTimeStatus: false,
      sTimeParams: {},
      accountBalance: 0,
      remainingClassHours: 0,
      addressProvinceList: [],
      parentId: "0",
      addressCityList: [],
      addressCountyList: [],
      addAddressPopup: false,
      detNamesListData: [],
      detNamesTotal: 0,
      classAddressData: {
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      addAddressParams: {
        title: "添加地址",
        type: "add",
      },
      saveAddressForm: {
        provinceId: "",
        cityId: "",
        countyId: "",
        isDefault: "0",
        detailAddress: "",
      },
      detailAddressPopup: false,
      addressType: 1,
      addressListData: [],
      selectData: [],
      newdetailAddress: [],

      totalData: 0,
      classAddressData: {
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      dataObj: {
        text: "",
      },
      teaIndex: 0,
      stuIndex: 0,
      total1: 0,
      total2: 0,
      total3: 0,
      total4: 0,
      total5: 0,
      total6: 0,
      total7: 0,
      total8: 0,
      stuTotal1: 0,
      stuTotal2: 0,
      stuTotal3: 0,
      stuTotal4: 0,
      stuTotal5: 0,
      stuTotal6: 0,
      stuTotal7: 0,
      stuTotal8: 0,
      roomId: "",
      downloadFilename: "",
      exportLink: "",
      qrcodePopup: false,
      tSearchTime: "",
      tSearchWeek: "",
      tStudentTime: "",
      tStudentWeek: "",
      editTime: "",
      getRemoveId: "",
      removeClassRadio: "",
      removeClassVisible: false,
      rowStudentId: "",
      surplusNum: 0,
      studentStateSync: false,
      outData: {},
      peopleNumShow: false,
      studentListVisible: false,
      studentListData: [],
      getSubjectId: "",
      getTeacher: "",
      isStatus: 0,
      studentList: [],
      getClassroomId: "",
      matchingTeacherList: [],
      moreTeacherPopup: false,
      morePopup: false,
      moreRuleForm: {},
      centerType: "",
      centerStudentName: "",
      centerTeacherName: "",
      centerBeginTime: "",
      centerTitle: "",
      centerUsedClassHour: "",
      centerDialogVisible: false,
      switchValue: true,
      morePopupRules: {
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
        usedClassHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" },
        ],
      },
      classHourStatus: "1",
      teacherSelectionHandle: [],
      moreTeacherName: false,
      singlePopup: false,
      teacherParams: {
        status: 1,
        label: 2,
        classroomId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      teacherOneParams: {
        status: 1,
        label: 2,
        studentId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      rowId: "",
      newteacherName: "",
      teacherList: [],
      orderStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待财务审核",
        },
        {
          value: 2,
          label: "财务审核拒绝",
        },
        {
          value: 3,
          label: "教务主管分配中",
        },
        {
          value: 4,
          label: "待排课",
        },
        {
          value: 5,
          label: "排课中",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 7,
          label: "订单已完成",
        },
        {
          value: 8,
          label: "订单作废",
        },
        {
          value: 9,
          label: "退款中",
        },
      ],
      videoModeList: [
        {
          name: "普通模式",
          type: 1,
        },
        {
          name: "高级模式",
          type: 2,
        },
      ],
      oneToOneSingleClassPrice: 0, // 一对一单价
      oneToMoreClassNum: 1,
      oneToMoeSingleClassPrice: 0, // 一对多单价
      organTotalBalance: "--", // 机构剩余金额
      singleRuleForm: {
        subjectId: "", //学科
        classMode: "1", //上课模式
        title: "", //课程标题
        classHourType: this.classHourStatus == 2 ? "1" : "3", //收费类型
        usedClassHour: "", //课时数
        beginTime: "", //上课时间
        isFirstClass: "0", //首次课设置
        isPrisonClass: "0",
        isPlayback: "0",
        teacherId: "", //讲师
        productId: "",
        classAddress: "",
        totalPrice: "", //总金额
        usePrice: "", //这节课使用的金额
        videoMode: 1, // 1 普通模式  2 高级模式
        expense: "--", // 消费金额
      },
      reasonValue: "",
      getProductParams: {
        dataAuthority: "user",
        name: "",
        attendClassStatus: "",
        pageNumber: 1,
        pageSize: 10,
        searchTime: "",
        classMode: "",
        flag: "课表查询",
        searchBeginDate: null,
        searchEndDate: null,
      },
      getProductParamsStudent: {
        dataAuthority: "user",
        name: "",
        attendClassStatus: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
        classMode: "",
        flag: "课表查询",
      },
      studentTimePicker: "",
      singlePopupRules: {
        productId: [{ validator: check_productIdOne, trigger: "blur" }],
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        usedClassHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
      },
      singSubjectIdList: [],
      buyProductList: [],
      surplusNum: 0,
      classHourTypeList: [
        {
          value: "1",
          label: "正常课时",
          show: "2",
        },
        {
          value: "2",
          label: "赠送课时",
          show: "2",
        },
        {
          value: "3",
          label: "收费课程",
          show: "1",
        },
        {
          value: "5",
          label: "赠送课时",
          show: "1",
        },
      ],
      expireTimeOptionBefore: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      beforePickerOptions: {
        // 限制时间
        selectableRange: ["06:00:00 -23:59:59"],
        // 限制日期
        disabledDate: this.disabledDateTime,
      },
      timePickerValue2: "",
      smallHandNum: 0,
      oneTooneTotal: 0,
      oneToMoreTotal: 0,
      activeIndex: "1",
      smallActiveName: "first",
      handleKey: "1",
      timePickerValue: [],
      timePickerValueTeacher: "",
      listParams: {
        dataAuthority: "user",
        name: "",
        sectionId: "", //年级
        subjectId: "", //科目
        attendClassStatus: "", //上课状态
        classMode: "", //上课形式
        isFirstClass: "", //首次课
        teacherStatus: "", //教师状态
        studentStatus: "", //学生状态
        pageNumber: 1,
        pageSize: 10,
        orderType: 1,
      },

      listMoreParams: {
        dataAuthority: "user",
        name: "",
        sectionId: "", //年级
        subjectId: "", //科目
        attendClassStatus: "", //上课状态
        // classMode:"",//上课形式
        isFirstClass: "", //首次课
        teacherStatus: "", //教师状态
        studentStatus: "", //学生状态
        pageNumber: 1,
        pageSize: 10,
        orderType: 2,
      },
      tlistParams: {
        dataAuthority: "user",
        name: "",
        sectionId: "", //年级
        subjectId: "", //科目
        attendClassStatus: "", //上课状态
        classMode: "", //上课形式
        isFirstClass: "", //首次课
        teacherStatus: "", //教师状态
        studentStatus: "", //学生状态
        pageNumber: 1,
        pageSize: 10,
        searchTime: "",
      },
      slistParams: {
        dataAuthority: "user",
        name: "",
        sectionId: "", //年级
        subjectId: "", //科目
        attendClassStatus: "", //上课状态
        classMode: "", //上课形式
        isFirstClass: "", //首次课
        teacherStatus: "", //教师状态
        studentStatus: "", //学生状态
      },
      qrcodeSubject: "",
      qrcodeTime: "",
      sectionList: [],
      subjectIdList: [],
      oneTooneData: [],
      oneToMoreData: [],
      peopleNumList: [],
      moreSectionList: [
        {
          macroId: "",
          name: "全部",
        },
        {
          macroId: 107,
          name: "高中",
        },
        {
          macroId: 106,
          name: "初中",
        },
        {
          macroId: 105,
          name: "小学",
        },
        {
          macroId: 108,
          name: "艺术培训",
        },
        {
          macroId: 109,
          name: "小语种",
        },
        {
          macroId: 235,
          name: "全学段",
        },
      ],
      attendClassStatusList: [
        { value: "", label: "全部" },
        { value: 2, label: "上课中" },
        { value: 0, label: "未上课" },
        { value: 1, label: "已上课" },
      ],
      isFirstClassList: [
        { value: "", label: "全部" },
        { value: 1, label: "是" },
        { value: 0, label: "否" },
      ],
      classModeList: [
        { value: "", label: "全部" },
        { value: 1, label: "线上" },
        { value: 2, label: "线下" },
      ],
      infoShow: false,
      infoParams: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("mainData", ["hasCdnMenu", "hasRecordMenu"]),
  },
  methods: {
    // 监课展示和回放录制权限提示
    handleAuthTip(_, flag) {
      if (!flag) {
        this.$message.warning("请联系管理员开同此功能！");
      }
    },
    switchChange() {
      if (this.switchValue == true) {
        this.findProductItemPageFn();
      } else {
        this.findMoreProductItemPage();
      }
    },
    seeStudentInfo(row) {
      this.sTimeStatus = true;
      this.sTimeParams = row;
    },
    getDetailAddress() {
      this.detailAddressPopup = true;
      this.addressType = 1;
      this.studentClassAddressList();
    },
    studentClassAddressList() {
      studentClassAddressList(this.classAddressData).then((res) => {
        this.addressListData = res.rows;
        this.totalData = res.total;
      });
    },
    yesHover(row) {
      xmtProductItemInfo(row.supplementItemId).then((res) => {
        if (res.code == 0) {
          this.infoShow = true;
          this.infoParams = {
            beginTime: res.rows.beginTime,
            subjectName: res.rows.subjectName,
            teacherRealName: res.rows.teacherRealName,
            endTime:
              Number(res.rows.beginTime.split(" ")[1].split(":")[0]) +
              Number(res.rows.usedClassHour) +
              ":" +
              res.rows.beginTime.split(" ")[1].split(":")[1],
          };
        }
      });
    },
    addressListDataChange(val) {
      this.selectData = val;
      this.newdetailAddress = val[0];
      if (val.length > 1) {
        this.$refs.addressListData.clearSelection();
        this.$refs.addressListData.toggleRowSelection(val.pop());
      }
    },
    stuOneClick() {
      let list = [];
      if (this.studentList.length) {
        for (var i = 0; i < this.studentList.length; i++) {
          list.push([
            {
              beginTime: "",
              classMode: "",
              id: "",
              sectionName: "",
              studentName: "",
              subjectName: "",
              teacherName: "",
              teacherRealName: "",
              toAccount: "",
              classAddress: "",
              stopTime: "",
              usedClassHour: "",
            },
          ]);
          for (var k = 0; k < this.studentList[i].beginTimeList.length; k++) {
            list[i][k] = {
              beginTime: this.studentList[i].beginTimeList[k].beginTime,
              classMode: this.studentList[i].beginTimeList[k].classMode,
              id: this.studentList[i].beginTimeList[k].id,
              sectionName: this.studentList[i].beginTimeList[k].sectionName,
              studentName: this.studentList[i].beginTimeList[k].studentName,
              subjectName: this.studentList[i].beginTimeList[k].subjectName,
              teacherName:
                this.studentList[i].beginTimeList[k].teacherNameNotice,
              teacherRealName: this.studentList[i].beginTimeList[k].teacherName,
              toAccount: this.studentList[i].beginTimeList[k].stuChatId,
              xskbTz: this.studentList[i].beginTimeList[k].xskbTz,
              classAddress: this.studentList[i].beginTimeList[k].classAddress,
              stopTime: this.studentList[i].beginTimeList[k].stopTime,
              usedClassHour: this.studentList[i].beginTimeList[k].usedClassHour,
            };
          }
        }
        oneClickNotification(list).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("通知成功！");
          } else {
            this.msgWarn(res.rows);
            return;
          }
        });
      } else {
        this.msgWarn("当前暂无课程需要通知");
        return;
      }
    },
    teaOneClick() {
      let list = [];
      if (this.teacherList.length) {
        for (var i = 0; i < this.teacherList.length; i++) {
          list.push([
            {
              beginTime: "",
              classMode: "",
              id: "",
              sectionName: "",
              studentName: "",
              subjectName: "",
              teacherName: "",
              teacherRealName: "",
              toAccount: "",
              classAddress: "",
              stopTime: "",
              usedClassHour: "",
            },
          ]);
          for (var k = 0; k < this.teacherList[i].beginTimeList.length; k++) {
            list[i][k] = {
              beginTime: this.teacherList[i].beginTimeList[k].beginTime,
              classMode: this.teacherList[i].beginTimeList[k].classMode,
              id: this.teacherList[i].beginTimeList[k].id,
              sectionName: this.teacherList[i].beginTimeList[k].sectionName,
              studentName: this.teacherList[i].beginTimeList[k].studentName,
              subjectName: this.teacherList[i].beginTimeList[k].subjectName,
              teacherName:
                this.teacherList[i].beginTimeList[k].teacherNameNotice,
              teacherRealName: this.teacherList[i].beginTimeList[k].teacherName,
              toAccount: this.teacherList[i].beginTimeList[k].teaChatId,
              lskbTz: this.teacherList[i].beginTimeList[k].lskbTz,
              classAddress: this.teacherList[i].beginTimeList[k].classAddress,
              isFirstClass: this.teacherList[i].beginTimeList[k].isFirstClass,
              stopTime: this.teacherList[i].beginTimeList[k].stopTime,
              usedClassHour: this.teacherList[i].beginTimeList[k].usedClassHour,
            };
          }
        }
        oneClickNotification(list).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("通知成功！");
          } else {
            this.msgWarn(res.rows);
            return;
          }
        });
      } else {
        this.msgWarn("当前暂无课程需要通知");
        return;
      }
    },
    seeReasonClass(row, e) {
      var id = "";
      if (e.target.nodeName.toLowerCase() === "p") {
        id = e.target.getAttribute("get-id");
      }
      for (let i = 0; i < row.beginTimeList.length; i++) {
        if (id == row.beginTimeList[i].id) {
          this.reasonValue = row.beginTimeList[i].abnormalCause;
        }
      }
    },
    rowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      this.teaIndex = row.index + 1;
    },
    rowStuClassName({ row, rowIndex }) {
      row.index = rowIndex;
      this.stuIndex = row.index + 1;
    },
    addressListDataItemClick(row) {
      this.$refs.addressListData.toggleRowSelection(row);
    },
    handleSizeChangeAddress(pageSize) {
      this.classAddressData.pageSize = pageSize;
      this.studentClassAddressList();
    },
    handleCurrentChangeAddress(currentPage) {
      this.classAddressData.pageNumber = currentPage;
      this.studentClassAddressList();
    },
    //选择地址点确定
    clickAddressSure() {
      if (this.selectData.length <= 0) {
        this.msgWarn("请选择上课地址！");
        return;
      }
      this.detailAddressPopup = false;
      if (this.addressType == 1) {
        this.singleRuleForm.classAddress =
          this.newdetailAddress.provinceName +
          this.newdetailAddress.cityName +
          this.newdetailAddress.countyName +
          this.newdetailAddress.detailAddress;
      } else {
        this.selectClassAddress =
          this.newdetailAddress.provinceName +
          this.newdetailAddress.cityName +
          this.newdetailAddress.countyName +
          this.newdetailAddress.detailAddress;
        for (var i = 0; i < this.courseDate.length; i++) {
          this.courseDate[i].classAddress =
            this.newdetailAddress.provinceName +
            this.newdetailAddress.cityName +
            this.newdetailAddress.countyName +
            this.newdetailAddress.detailAddress;
        }
      }
    },
    async addressProvinceChange() {
      await this.addressCityListFn();
      this.saveAddressForm.cityId = this.addressCityList[0].areaId;
      this.addressCityChange();
    },
    async addressCityChange() {
      await this.addressCountyListFn();
      this.saveAddressForm.countyId = this.addressCountyList[0].areaId;
    },
    addressCityListFn() {
      return systemAreaList({
        parentId: this.saveAddressForm.provinceId,
      }).then((res) => {
        this.addressCityList = res.rows;
      });
    },
    addressCountyListFn() {
      return systemAreaList({
        parentId: this.saveAddressForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCountyList = res.rows;
        }
      });
    },
    classHourStatusChange() {
      if (this.classHourStatus == 1) {
        this.singleRuleForm.classHourType = "3";
      } else {
        this.singleRuleForm.classHourType = "1";
      }
    },
    noticeDisabled(row) {
      let beginTime = new Date(row.beginTime); //开始上课时
      beginTime.setHours(beginTime.getHours() + Number(row.usedClassHour));
      if (new Date().getTime() > new Date(beginTime).getTime()) {
        return true;
      } else {
        if (row.kbcxStuTz == 1 && row.kbcxTeaTz == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    classModeChange() {
      this.computeClassExpense();
      if (this.singleRuleForm.classMode == 1) {
        this.singleRuleForm.classAddress == "";
      }
      this.classAddressData.pageSize = 1000;
      studentClassAddressList(this.classAddressData).then((res) => {
        if (res.rows) {
          this.addressList = res.rows;
          for (var i = 0; i < res.rows.length; i++) {
            if (res.rows[i].isDefault == 1) {
              this.defaultId = res.rows[i].id;
              this.singleRuleForm.classAddress =
                res.rows[i].provinceName +
                res.rows[i].cityName +
                res.rows[i].countyName +
                res.rows[i].detailAddress;
            }
          }
        }
      });
    },
    //添加新地址
    addAddress() {
      this.addAddressPopup = true;
      this.addAddressParams = {
        title: "添加地址",
        type: "add",
      };
      this.saveAddressForm.provinceId = "";
      this.saveAddressForm.cityId = "";
      this.saveAddressForm.countyId = "";
      this.saveAddressForm.detailAddress = "";
      this.address1();
    },
    address1() {
      return systemAreaList({
        parentId: this.parentId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressProvinceList = res.rows;
        }
      });
    },
    //删除新地址
    removeAddress() {
      if (this.selectData.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentClassAddressRemove(this.selectData).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentClassAddressList();
            }
          });
        })
        .catch(() => {});
    },
    teacherTZ(row) {
      var date1 = new Date(this.tSearchTime); //要对比的时间
      var date2 = new Date(); //获取当前时间对象
      var num = 24 * 60 * 60 * 1000; //一天的毫秒数
      var cha = date2.getTime() - date1.getTime(); //两个时间的毫秒差
      if (cha > 0) {
        if (cha > num) {
          //不能通知
          return false;
        } else {
          return true;
        }
      } else if (cha < 0) {
        return true;
      }
    },
    studentTZ(row) {
      var date1 = new Date(this.tStudentTime); //要对比的时间
      var date2 = new Date(); //获取当前时间对象
      var num = 24 * 60 * 60 * 1000; //一天的毫秒数
      var cha = date2.getTime() - date1.getTime(); //两个时间的毫秒差
      if (cha > 0) {
        if (cha > num) {
          //不能通知
          return false;
        } else {
          return true;
        }
      } else if (cha < 0) {
        return true;
      }
    },
    //编辑新地址
    async editAddress() {
      if (this.selectData.length > 1) {
        this.msgWarn("请选择单个地址进行编辑！");
        return;
      }
      if (this.selectData.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.addAddressParams = {
        title: "编辑地址",
        type: "edit",
      };
      this.addAddressPopup = true;
      await this.address1();
      await studentClassAddressInfo(this.selectData[0].id).then((res) => {
        if (res.code == 0) {
          this.getAddressId = res.rows.id;
          this.saveAddressForm = {
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            isDefault: String(res.rows.isDefault),
            detailAddress: res.rows.detailAddress,
          };
          if (res.rows.provinceId) {
            this.getEditCityLists();
          }
          if (res.rows.cityId) {
            this.getEditCountyLists();
          }
        }
      });
    },
    getEditCityLists() {
      return systemAreaList({
        parentId: this.saveAddressForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCityList = res.rows;
        }
      });
    },
    getEditCountyLists() {
      return systemAreaList({
        parentId: this.saveAddressForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCountyList = res.rows;
        }
      });
    },
    detNamesList() {
      studentClassAddressList(this.classAddressData).then((res) => {
        this.detNamesListData = res.rows;
        this.detNamesTotal = res.total;
      });
    },
    confirmAddressSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveAddressForm.studentId = this.rowStudentId;
          checkDefault(this.rowStudentId).then((res) => {
            if (res.code == 0) {
              if (this.addAddressParams.type == "add") {
                if (res.msg == "无默认") {
                  studentClassAddressSave(this.saveAddressForm).then((res) => {
                    if (res.code == 0) {
                      this.addAddressPopup = false;
                      this.studentClassAddressList();
                      this.detNamesList();
                    }
                  });
                } else {
                  if (this.saveAddressForm.isDefault == 1) {
                    this.msgWarn("已有默认地址！");
                    return false;
                  }
                  studentClassAddressSave(this.saveAddressForm).then((res) => {
                    if (res.code == 0) {
                      this.addAddressPopup = false;
                      this.studentClassAddressList();
                      this.detNamesList();
                    }
                  });
                }
              } else if (this.addAddressParams.type == "edit") {
                this.saveAddressForm.id = this.selectData[0].id;
                if (res.msg == "无默认") {
                  studentClassAddressUpdate(this.saveAddressForm).then(
                    (res) => {
                      if (res.code == 0) {
                        this.addAddressPopup = false;
                        this.studentClassAddressList();
                        this.detNamesList();
                      }
                    }
                  );
                } else {
                  if (this.saveAddressForm.id == this.defaultId) {
                    studentClassAddressUpdate(this.saveAddressForm).then(
                      (res) => {
                        if (res.code == 0) {
                          this.addAddressPopup = false;
                          this.studentClassAddressList();
                          this.detNamesList();
                        }
                      }
                    );
                  } else {
                    if (this.saveAddressForm.isDefault == 1) {
                      this.msgWarn("已有默认地址！");
                      return false;
                    } else {
                      studentClassAddressUpdate(this.saveAddressForm).then(
                        (res) => {
                          if (res.code == 0) {
                            this.addAddressPopup = false;
                            this.studentClassAddressList();
                            this.detNamesList();
                          }
                        }
                      );
                    }
                  }
                }
              }
            }
          });
        }
      });
    },
    downloadImg() {
      let Qrcode = this.$refs.Qrcode;
      this.exportLink = Qrcode.$el.currentSrc;
      this.downloadFilename = "二维码";
    },

    removeClass(row) {
      this.removeClassRadio = "";
      this.removeClassVisible = true;
      this.getRemoveId = row.id;
    },
    teacherPickerChange() {
      if (!this.timePickerValueTeacher) {
        this.getProductParams.searchBeginDate = null;
        this.getProductParams.searchEndDate = null;
        return;
      }
      this.tSearchTime = this.timePickerValueTeacher;
      var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      this.tSearchWeek = weekArray[new Date(this.tSearchTime).getDay()];
      this.getProductParams.searchBeginDate =
        this.timePickerValueTeacher + " " + "00:00:00";
      this.getProductParams.searchEndDate =
        this.timePickerValueTeacher + " " + "23:59:59";
      this.getSyllabusPageDeptOneFn();
    },
    daalPeopleNum(row) {
      this.studentStateSync = true;
      getStudentInfoByRoomId({ classroomId: row.classroomId }).then((res) => {
        this.peopleNumList = res.rows;
      });
    },
    exportExcel(num) {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#teacherRef"),
        xlsxParam
      ); //outTable为列表id
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream;charset=utf-8" }),
          num == "teacher" ? "讲师课表.xlsx" : "学生课表.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    handleSelect(key, keyPath) {
      this.handleKey = key;
      if (key == 2) {
        //老师课表
        this.getSyllabusPageDeptOneFn();
      } else if (key == 3) {
        //学生课表
        this.getSyllabusPageStudentFn();
      }
    },
    smallHandleClick(key) {
      this.smallHandNum = key.index;
      if (key.index == 0) {
        this.findProductItemPageFn();
      } else {
        this.findMoreProductItemPage();
      }
    },
    selectMoreChange(value) {
      this.teacherParams.subjectId = value;
      if (value != this.moreSubjectId) {
        this.moreTeacherName = "";
        this.moreRuleForm.teacherId = "";
      }
    },
    CB_sTimeStatus() {
      this.sTimeStatus = false;
    },
    selectChanged(value) {
      this.teacherOneParams.subjectId = value;
      if (value != this.getSubjectId) {
        this.newteacherName = "";
        this.singleRuleForm.teacherId = "";
      }
      for (var i = 0; i < this.singSubjectIdList.length; i++) {
        if (this.singSubjectIdList[i].macroId == value) {
          this.singleRuleForm.title =
            this.getSectionName + this.singSubjectIdList[i].name;
        }
      }
    },
    classHourTypeChange() {
      this.$set(this.singleRuleForm, "productId", "");
      this.surplusNum = 0;
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    datePickerChange2() {
      if (!this.timePickerValue2) {
        this.listMoreParams.searchBeginDate = null;
        this.listMoreParams.searchEndDate = null;
        return;
      }
      this.listMoreParams.searchBeginDate = this.timePickerValue2[0];
      this.listMoreParams.searchEndDate = this.timePickerValue2[1];
    },
    surplusNumFn(val) {
      var obj = this.buyProductList;
      var hostValue = {};
      obj.forEach((item, index) => {
        const deviceId = item.id;
        if (!hostValue[deviceId]) {
          hostValue[deviceId] = {
            item,
          };
        }
      });
      this.outData = hostValue[val].item;
      this.singleRuleForm.productId = this.outData.id;
      if (this.outData.singlePrice) {
        //订单金额
        this.getSinglePrice = Number(this.outData.singlePrice);
      } else {
        this.getSinglePrice = Number(this.outData.zdySinglePrice);
      }
      getResiduePrice({
        orderId: this.outData.orderId,
        studentId: this.rowStudentId,
        classHourType: this.singleRuleForm.classHourType,
      }).then((res) => {
        if (res.code == 0) {
          this.surplusNum = res.rows;
          if (this.editClassHourType == 1) {
            //产品单价*原来的课时+现在的剩余金额
            this.beforeDate =
              Number(this.productSinglePrice) * Number(this.editUsedClassHour) +
              Number(res.rows);
          } else {
            //赠送课时   原来的扣得课时+现在剩的课时
            this.beforeDate = Number(this.editUsedClassHour) + Number(res.rows);
          }
          this.singleRuleForm.totalPrice = res.rows;
        }
      });
    },
    getTeacherId() {
      if (!this.singleRuleForm.subjectId) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.moreTeacherPopup = true;
      this.configOneTeacherPlan();
    },

    getSyllabusPageDeptOneFn() {
      this.total1 = 0;
      this.total2 = 0;
      this.total3 = 0;
      this.total4 = 0;
      this.total4 = 0;
      this.total5 = 0;
      this.total6 = 0;
      this.total7 = 0;
      this.total8 = 0;
      getSyllabusPageDeptOne(this.getProductParams).then((res) => {
        if (res.code == 0) {
          var beforedata = new Array();
          var tempArr = new Array();
          var afterData = new Array();
          beforedata = res.rows;
          for (let i = 0; i < beforedata.length; i++) {
            if (tempArr.length > 0) {
              let flag = true;
              for (let j = 0; j < tempArr.length; j++) {
                if (tempArr[j].teacherId == beforedata[i].teacherId) {
                  flag = false;
                  tempArr[j].beginTimeList.push({
                    abnormalCause: beforedata[i].abnormalCause,
                    abnormalStatus: beforedata[i].abnormalStatus,
                    stopTime: beforedata[i].stopTime,
                    usedClassHour: beforedata[i].usedClassHour,
                    isFirstClass: beforedata[i].isFirstClass,
                    classAddress: beforedata[i].classAddress,
                    parChatId: beforedata[i].parChatId,
                    lskbTz: beforedata[i].lskbTz,
                    xskbTz: beforedata[i].xskbTz,
                    classMode: beforedata[i].classMode,
                    id: beforedata[i].id,
                    sectionName: beforedata[i].sectionName
                      ? beforedata[i].sectionName
                      : "",
                    subjectNameNotice: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    teaChatId: beforedata[i].teaChatId
                      ? beforedata[i].teaChatId
                      : "",
                    stuChatId: beforedata[i].stuChatId
                      ? beforedata[i].stuChatId
                      : "",
                    teacherNameNotice: beforedata[i].teacherName,
                    studentNameNotice: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : "",
                    subjectName: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : beforedata[i].sectionName,
                    time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                    teacherid: beforedata[i].teacherId,
                    beginTime: beforedata[i].beginTime,
                    sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                    sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                    studentName: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : beforedata[i].roomName
                      ? beforedata[i].roomName
                      : "",
                    teacherName: beforedata[i].teacherRealName,
                    endTime:
                      Number(
                        beforedata[i].beginTime.split(" ")[1].split(":")[0]
                      ) +
                      Number(beforedata[i].usedClassHour) +
                      ":" +
                      beforedata[i].beginTime.split(" ")[1].split(":")[1],
                  });
                }
              }
              if (flag) {
                beforedata[i].beginTimeList = [
                  {
                    abnormalCause: beforedata[i].abnormalCause,
                    abnormalStatus: beforedata[i].abnormalStatus,
                    stopTime: beforedata[i].stopTime,
                    usedClassHour: beforedata[i].usedClassHour,
                    isFirstClass: beforedata[i].isFirstClass,
                    classAddress: beforedata[i].classAddress,
                    parChatId: beforedata[i].parChatId,
                    lskbTz: beforedata[i].lskbTz,
                    xskbTz: beforedata[i].xskbTz,
                    classMode: beforedata[i].classMode,
                    id: beforedata[i].id,
                    sectionName: beforedata[i].sectionName
                      ? beforedata[i].sectionName
                      : "",
                    subjectNameNotice: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    teaChatId: beforedata[i].teaChatId
                      ? beforedata[i].teaChatId
                      : "",
                    stuChatId: beforedata[i].stuChatId
                      ? beforedata[i].stuChatId
                      : "",
                    teacherNameNotice: beforedata[i].teacherName,
                    studentNameNotice: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : "",
                    subjectName: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : beforedata[i].sectionName,
                    time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                    // endTime:beforedata[i].beginTime.split(' ')[1].split(':')[1],
                    teacherid: beforedata[i].teacherId,
                    beginTime: beforedata[i].beginTime,
                    sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                    sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                    studentName: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : beforedata[i].roomName
                      ? beforedata[i].roomName
                      : "",
                    teacherName: beforedata[i].teacherRealName,
                    endTime:
                      Number(
                        beforedata[i].beginTime.split(" ")[1].split(":")[0]
                      ) +
                      Number(beforedata[i].usedClassHour) +
                      ":" +
                      beforedata[i].beginTime.split(" ")[1].split(":")[1],
                  },
                ];
                tempArr.push(beforedata[i]);
              }
            } else {
              beforedata[i].beginTimeList = [
                {
                  abnormalCause: beforedata[i].abnormalCause,
                  abnormalStatus: beforedata[i].abnormalStatus,
                  stopTime: beforedata[i].stopTime,
                  usedClassHour: beforedata[i].usedClassHour,
                  isFirstClass: beforedata[i].isFirstClass,
                  classAddress: beforedata[i].classAddress,
                  parChatId: beforedata[i].parChatId,
                  lskbTz: beforedata[i].lskbTz,
                  xskbTz: beforedata[i].xskbTz,
                  classMode: beforedata[i].classMode,
                  id: beforedata[i].id,
                  sectionName: beforedata[i].sectionName
                    ? beforedata[i].sectionName
                    : "",
                  subjectNameNotice: beforedata[i].subjectName
                    ? beforedata[i].subjectName
                    : "",
                  teaChatId: beforedata[i].teaChatId
                    ? beforedata[i].teaChatId
                    : "",
                  stuChatId: beforedata[i].stuChatId
                    ? beforedata[i].stuChatId
                    : "",
                  teacherNameNotice: beforedata[i].teacherName,
                  studentNameNotice: beforedata[i].studentName
                    ? beforedata[i].studentName
                    : "",
                  subjectName: beforedata[i].subjectName
                    ? beforedata[i].subjectName
                    : beforedata[i].sectionName,
                  time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                  // endTime:beforedata[i].beginTime.split(' ')[1].split(':')[1],
                  teacherid: beforedata[i].teacherId,
                  beginTime: beforedata[i].beginTime,
                  sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                  sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                  studentName: beforedata[i].studentName
                    ? beforedata[i].studentName
                    : beforedata[i].roomName
                    ? beforedata[i].roomName
                    : "",
                  teacherName: beforedata[i].teacherRealName,
                  endTime:
                    Number(
                      beforedata[i].beginTime.split(" ")[1].split(":")[0]
                    ) +
                    Number(beforedata[i].usedClassHour) +
                    ":" +
                    beforedata[i].beginTime.split(" ")[1].split(":")[1],
                },
              ];
              tempArr.push(beforedata[i]);
            }
          }
          for (var i = 0; i < tempArr.length; i++) {
            for (var k = 0; k < tempArr[i].beginTimeList.length; k++) {
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 6 &&
                Number(tempArr[i].beginTimeList[k].time) < 8
              ) {
                this.total1 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 8 &&
                Number(tempArr[i].beginTimeList[k].time) < 10
              ) {
                this.total2 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 10 &&
                Number(tempArr[i].beginTimeList[k].time) <= 12
              ) {
                this.total3 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 13 &&
                Number(tempArr[i].beginTimeList[k].time) < 15
              ) {
                this.total4 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 15 &&
                Number(tempArr[i].beginTimeList[k].time) < 17
              ) {
                this.total5 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 17 &&
                Number(tempArr[i].beginTimeList[k].time) < 19
              ) {
                this.total6 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 19 &&
                Number(tempArr[i].beginTimeList[k].time) < 21
              ) {
                this.total7 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 21 &&
                Number(tempArr[i].beginTimeList[k].time) < 23
              ) {
                this.total8 += 1;
              }
            }
          }
          this.teacherList = tempArr;
        }
      });
    },
    disabledDateTime(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    studentPickerChange() {
      if (!this.studentTimePicker) {
        this.getProductParamsStudent.searchBeginDate = null;
        this.getProductParamsStudent.searchEndDate = null;
        return;
      }
      this.tStudentTime = this.studentTimePicker;
      var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      this.tStudentWeek = weekArray[new Date(this.tStudentTime).getDay()];
      this.getProductParamsStudent.searchBeginDate =
        this.studentTimePicker + " " + "00:00:00";
      this.getProductParamsStudent.searchEndDate =
        this.studentTimePicker + " " + "23:59:59";
      this.getSyllabusPageStudentFn();
    },
    /* 视频模式改变 */
    videoModeChanged() {
      debugger;
      this.getSingleClassFee();
    },
    /* 视频模式改变 */
    videoModeMoreChanged() {
      this.getSingleClassFee();
    },
    /* 课时数变化 */
    classHourChange() {
      this.computeClassExpense();
    },
    /* 获取当前机构余额 */
    getOrganBalance() {
      this.organTotalBalance = "--";
      getOrganBalance().then((res) => {
        console.log("getOrganBalance: ", res);
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.organTotalBalance = res.rows.accountBalance
          ? res.rows.accountBalance
          : 0;
      });
    },
    /* 获取特定上课模式下单节课的费用 */
    getSingleClassFee() {
      this.oneToOneSingleClassPrice = 0;
      this.oneToMoeSingleClassPrice = 0;
      this.singleRuleForm.expense = "--";
      let classMode = 1;
      let videoMode;
      if (this.singlePopup) {
        // 一对一排课
        classMode = 1;
        videoMode = this.singleRuleForm.videoMode;
      } else if (this.morePopup) {
        // 一对多排课
        classMode = 2;
        videoMode = this.moreRuleForm.videoMode;
      }
      if (videoMode) {
        getFlowFeeByMode({
          classMode,
          videoMode,
        }).then((res) => {
          console.log("getOrganBalance: ", res);
          if (res.flag == "error") {
            return this.$message.error(res.msg);
          }
          if (classMode == 1) {
            this.oneToOneSingleClassPrice = res.rows.flowFee;
          } else if (classMode == 2) {
            if (res.rows.sysFlowFeeMoreList) {
              res.rows.sysFlowFeeMoreList.forEach((item) => {
                if (
                  item.startPeople <= this.oneToMoreClassNum &&
                  this.oneToMoreClassNum <= item.endPeople
                ) {
                  this.oneToMoeSingleClassPrice = item.flowFeeMore;
                }
              });
              if(!this.oneToMoeSingleClassPrice){
              this.$message.warning('未找到对应的一对多费用，请先进行费用设置！')
            }
            }
          }
          console.log(
            this.oneToOneSingleClassPrice,
            this.oneToMoeSingleClassPrice
          );
          this.computeClassExpense();
        });
      }
    },
    /* 计算本次排课消耗金额 */
    computeClassExpense() {
      let classPirce = -1;
      this.singleRuleForm.expense = "--";
      // 一对一处理
      if (this.singlePopup) {
        // this.singleRuleForm.classMode  1 线上
        if (this.singleRuleForm.classMode == 2) {
          this.singleRuleForm.expense = 0;
          return;
        }
        const { usedClassHour } = this.singleRuleForm;
        if (this.oneToOneSingleClassPrice != 0) {
          classPirce = this.oneToOneSingleClassPrice * usedClassHour;
        }
        if (classPirce == -1) {
          this.singleRuleForm.expense = "--";
        } else {
          this.singleRuleForm.expense = classPirce;
        }
        // 一对多处理
      } else if (this.morePopup) {
        const { usedClassHour } = this.moreRuleForm;
        if (this.oneToMoeSingleClassPrice) {
          classPirce = this.oneToMoeSingleClassPrice * usedClassHour;
        }
        if (classPirce == -1) {
          this.moreRuleForm.expense = "--";
        } else {
          this.moreRuleForm.expense = classPirce;
        }
      }
    },
    sureOneSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.singleRuleForm.expense == "--") {
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            return this.$message.warning("流量费用不能大于账户余额！");
          }
          if (this.getSinglePrice2) {
            //正常课程除外
            this.singleRuleForm.usePrice =
              Number(this.getSinglePrice2) *
              Number(this.singleRuleForm.usedClassHour);
          } else {
            this.singleRuleForm.usePrice =
              Number(this.getSinglePrice) *
              Number(this.singleRuleForm.usedClassHour);
          }
          // this.singleRuleForm.id=this.editId;
          if (this.singleRuleForm.classHourType == 7) {
            this.singleRuleForm.id = this.editId;
            this.singleRuleForm.supplementItemId = this.editId;
          } else {
            this.singleRuleForm.id = this.editId;
          }
          this.singleRuleForm.sectionId = this.getSectionId;
          this.singleRuleForm.studentId = this.rowStudentId;
          if (this.singleRuleForm.classHourType == 1) {
            //正常课时
            if (this.singleRuleForm.usePrice > this.beforeDate) {
              this.msgWarn("该费用已不足！");
              return false;
            }
          } else {
            if (this.singleRuleForm.usedClassHour > this.beforeDate) {
              this.msgWarn("该赠送课时已不足！");
              return false;
            }
          }
          if (
            dayjs(this.singleRuleForm.beginTime)
              .add(1, "minute")
              .isBefore(dayjs())
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return;
          }
          if (
            this.editTime == this.singleRuleForm.beginTime &&
            this.editUsedClassHour == this.singleRuleForm.usedClassHour
          ) {
            //没修改时间  只删除保存
            //没修改时间  只删除保存
            //一对一充值编辑去掉delete
            if (this.classHourStatus == 2) {
              //一对一
              xmtProductItemDelete(this.editId).then((res) => {
                delete this.singleRuleForm.id;
                xmtProductItemSave(this.singleRuleForm).then((res) => {
                  if (res.code == 0 && res.msg == "操作成功！") {
                    this.msgSuccess("添加成功！");
                    this.singlePopup = false;
                    this.findProductItemPageFn();
                  } else {
                    this.msgWarn(res.rows);
                    return false;
                  }
                });
              });
            } else if (this.singleRuleForm.classHourType == 7) {
              deleteMakeUpTimeForClass({ id: this.editId }).then((res) => {
                delete this.singleRuleForm.id;
                makeUpTimeForClass(this.singleRuleForm).then((res) => {
                  if (res.code == 0 && res.msg == "操作成功！") {
                    this.msgSuccess("添加成功！");
                    this.singlePopup = false;
                    this.findProductItemPageFn();
                  } else {
                    this.msgWarn(res.rows);
                    return false;
                  }
                });
              });
            } else {
              xmtProductItemDelete(this.editId).then((res) => {
                delete this.singleRuleForm.id;
                saveOneToOne(this.singleRuleForm).then((res) => {
                  if (res.code == 0 && res.msg == "操作成功！") {
                    this.msgSuccess("添加成功！");
                    this.singlePopup = false;
                    this.findProductItemPageFn();
                  } else {
                    this.msgWarn(res.rows);
                    return false;
                  }
                });
              });
            }
          } else {
            checkTeacherClassTime(this.singleRuleForm).then((res) => {
              if (res.msg == "操作成功！") {
                checkStudentClassTime(this.singleRuleForm).then((res) => {
                  if (res.msg == "操作成功！") {
                    checkTeacherOrganClassTime(this.singleRuleForm).then(
                      (res) => {
                        if (res.msg == "操作成功！") {
                          if (this.singleRuleForm.classHourType == 7) {
                            deleteMakeUpTimeForClass({ id: this.editId }).then(
                              (res) => {
                                delete this.singleRuleForm.id;
                                makeUpTimeForClass(this.singleRuleForm).then(
                                  (res) => {
                                    if (
                                      res.code == 0 &&
                                      res.msg == "操作成功！"
                                    ) {
                                      this.msgSuccess("添加成功！");
                                      this.singlePopup = false;
                                      this.findProductItemPageFn();
                                    } else {
                                      this.msgWarn(res.rows);
                                      return false;
                                    }
                                  }
                                );
                              }
                            );
                          } else {
                            xmtProductItemDelete(this.editId).then((res) => {
                              delete this.singleRuleForm.id;
                              if (this.classHourStatus == 2) {
                                xmtProductItemSave(this.singleRuleForm).then(
                                  (res) => {
                                    if (
                                      res.code == 0 &&
                                      res.msg == "操作成功！"
                                    ) {
                                      this.msgSuccess("添加成功！");
                                      this.singlePopup = false;
                                      this.findProductItemPageFn();
                                    } else {
                                      this.msgWarn(res.rows);
                                      return false;
                                    }
                                  }
                                );
                              } else {
                                saveOneToOne(this.singleRuleForm).then(
                                  (res) => {
                                    if (
                                      res.code == 0 &&
                                      res.msg == "操作成功！"
                                    ) {
                                      this.msgSuccess("添加成功！");
                                      this.singlePopup = false;
                                      this.findProductItemPageFn();
                                    } else {
                                      if (
                                        res.rows ==
                                          "当前学生剩余金额不足，请核实后重新排课" ||
                                        res.rows ==
                                          "该学生赠送课时不足，请核对后重新排课"
                                      ) {
                                        this.msgWarn(res.rows);
                                        this.singlePopup = false;
                                        this.findProductItemPageFn();
                                        return false;
                                      } else {
                                        this.msgWarn(res.rows);
                                        return false;
                                      }
                                    }
                                  }
                                );
                              }
                            });
                          }
                        } else {
                          this.centerType = "teacher";
                          this.centerStudentName = res.rows.studentName;
                          this.centerTeacherName = res.rows.teacherRealName;
                          this.centerBeginTime = res.rows.beginTime;
                          this.centerTitle = res.rows.title;
                          this.centerUsedClassHour = res.rows.usedClassHour;
                          this.centerTrue = false;
                          this.centerDialogVisible = true;
                        }
                      }
                    );
                  } else {
                    //学生被占用
                    this.centerType = "student";
                    this.centerStudentName = res.rows.studentName;
                    this.centerTeacherName = res.rows.teacherName;
                    this.centerBeginTime = res.rows.beginTime;
                    this.centerTitle = res.rows.title;
                    this.centerUsedClassHour = res.rows.usedClassHour;
                    this.centerDialogVisible = true;
                  }
                });
              } else {
                //老师被占用
                this.centerType = "teacher";
                this.centerStudentName = res.rows.studentName;
                this.centerTeacherName = res.rows.teacherName;
                this.centerBeginTime = res.rows.beginTime;
                this.centerTitle = res.rows.title;
                this.centerUsedClassHour = res.rows.usedClassHour;
                this.centerDialogVisible = true;
              }
            });
          }
        }
      });
    },
    seeQrcode(row) {
      this.roomId = row.roomid;
      this.qrcodePopup = true;
      this.qrcodeSubject = row.subjectName;
      this.dataObj.text = this.$myStore.webMobile + row.roomid;
      this.qrcodeTime =
        row.beginTime +
        "-" +
        Number(
          Number(row.beginTime.split(" ")[1].split(":")[0]) +
            Number(row.usedClassHour)
        ) +
        ":" +
        row.beginTime.split(" ")[1].split(":")[1];
    },
    getSyllabusPageStudentFn() {
      this.stuTotal1 = 0;
      this.stuTotal2 = 0;
      this.stuTotal3 = 0;
      this.stuTotal4 = 0;
      this.stuTotal4 = 0;
      this.stuTotal5 = 0;
      this.stuTotal6 = 0;
      this.stuTotal7 = 0;
      this.stuTotal8 = 0;
      getSyllabusPageDeptOne(this.getProductParamsStudent).then((res) => {
        if (res.code == 0) {
          var beforedata = new Array();
          var tempArr = new Array();
          var afterData = new Array();
          beforedata = res.rows;
          for (let i = 0; i < beforedata.length; i++) {
            if (tempArr.length > 0) {
              let flag = true;
              for (let j = 0; j < tempArr.length; j++) {
                if (tempArr[j].studentId == beforedata[i].studentId) {
                  flag = false;
                  tempArr[j].beginTimeList.push({
                    abnormalCause: beforedata[i].abnormalCause,
                    abnormalStatus: beforedata[i].abnormalStatus,
                    stopTime: beforedata[i].stopTime,
                    usedClassHour: beforedata[i].usedClassHour,
                    classAddress: beforedata[i].classAddress,
                    lskbTz: beforedata[i].lskbTz,
                    xskbTz: beforedata[i].xskbTz,
                    parChatId: beforedata[i].parChatId,
                    classMode: beforedata[i].classMode,
                    id: beforedata[i].id,
                    sectionName: beforedata[i].sectionName
                      ? beforedata[i].sectionName
                      : "",
                    subjectNameNotice: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    teaChatId: beforedata[i].teaChatId
                      ? beforedata[i].teaChatId
                      : "",
                    stuChatId: beforedata[i].stuChatId
                      ? beforedata[i].stuChatId
                      : "",
                    teacherNameNotice: beforedata[i].teacherName,
                    studentNameNotice: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : "",
                    subjectName: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                    teacherid: beforedata[i].teacherId,
                    beginTime: beforedata[i].beginTime,
                    sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                    sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                    studentName: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : beforedata[i].roomName,
                    teacherName: beforedata[i].teacherRealName
                      ? beforedata[i].teacherRealName
                      : "",
                    endTime:
                      Number(
                        beforedata[i].beginTime.split(" ")[1].split(":")[0]
                      ) +
                      Number(beforedata[i].usedClassHour) +
                      ":" +
                      beforedata[i].beginTime.split(" ")[1].split(":")[1],
                  });
                }
              }
              if (flag) {
                beforedata[i].beginTimeList = [
                  {
                    abnormalCause: beforedata[i].abnormalCause,
                    abnormalStatus: beforedata[i].abnormalStatus,
                    stopTime: beforedata[i].stopTime,
                    usedClassHour: beforedata[i].usedClassHour,
                    classAddress: beforedata[i].classAddress,
                    lskbTz: beforedata[i].lskbTz,
                    xskbTz: beforedata[i].xskbTz,
                    parChatId: beforedata[i].parChatId,
                    classMode: beforedata[i].classMode,
                    id: beforedata[i].id,
                    sectionName: beforedata[i].sectionName
                      ? beforedata[i].sectionName
                      : "",
                    subjectNameNotice: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    teaChatId: beforedata[i].teaChatId
                      ? beforedata[i].teaChatId
                      : "",
                    stuChatId: beforedata[i].stuChatId
                      ? beforedata[i].stuChatId
                      : "",
                    teacherNameNotice: beforedata[i].teacherName,
                    studentNameNotice: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : "",
                    subjectName: beforedata[i].subjectName
                      ? beforedata[i].subjectName
                      : "",
                    time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                    teacherid: beforedata[i].teacherId,
                    beginTime: beforedata[i].beginTime,
                    sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                    sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                    studentName: beforedata[i].studentName
                      ? beforedata[i].studentName
                      : beforedata[i].roomName,
                    teacherName: beforedata[i].teacherRealName
                      ? beforedata[i].teacherRealName
                      : "",
                    endTime:
                      Number(
                        beforedata[i].beginTime.split(" ")[1].split(":")[0]
                      ) +
                      Number(beforedata[i].usedClassHour) +
                      ":" +
                      beforedata[i].beginTime.split(" ")[1].split(":")[1],
                  },
                ];
                tempArr.push(beforedata[i]);
              }
            } else {
              beforedata[i].beginTimeList = [
                {
                  abnormalCause: beforedata[i].abnormalCause,
                  abnormalStatus: beforedata[i].abnormalStatus,
                  stopTime: beforedata[i].stopTime,
                  usedClassHour: beforedata[i].usedClassHour,
                  classAddress: beforedata[i].classAddress,
                  lskbTz: beforedata[i].lskbTz,
                  xskbTz: beforedata[i].xskbTz,
                  parChatId: beforedata[i].parChatId,
                  classMode: beforedata[i].classMode,
                  id: beforedata[i].id,
                  sectionName: beforedata[i].sectionName
                    ? beforedata[i].sectionName
                    : "",
                  subjectNameNotice: beforedata[i].subjectName
                    ? beforedata[i].subjectName
                    : "",
                  teaChatId: beforedata[i].teaChatId
                    ? beforedata[i].teaChatId
                    : "",
                  stuChatId: beforedata[i].stuChatId
                    ? beforedata[i].stuChatId
                    : "",
                  teacherNameNotice: beforedata[i].teacherName,
                  studentNameNotice: beforedata[i].studentName
                    ? beforedata[i].studentName
                    : "",
                  subjectName: beforedata[i].subjectName
                    ? beforedata[i].subjectName
                    : "",
                  time: beforedata[i].beginTime.split(" ")[1].split(":")[0],
                  teacherid: beforedata[i].teacherId,
                  beginTime: beforedata[i].beginTime,
                  sendtime: beforedata[i].beginTime.split(" ")[1], //上课时间
                  sendusedClassHour: beforedata[i].usedClassHour, //上课时长
                  studentName: beforedata[i].studentName
                    ? beforedata[i].studentName
                    : beforedata[i].roomName,
                  teacherName: beforedata[i].teacherRealName
                    ? beforedata[i].teacherRealName
                    : "",
                  endTime:
                    Number(
                      beforedata[i].beginTime.split(" ")[1].split(":")[0]
                    ) +
                    Number(beforedata[i].usedClassHour) +
                    ":" +
                    beforedata[i].beginTime.split(" ")[1].split(":")[1],
                },
              ];
              tempArr.push(beforedata[i]);
            }
          }
          for (var i = 0; i < tempArr.length; i++) {
            for (var k = 0; k < tempArr[i].beginTimeList.length; k++) {
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 6 &&
                Number(tempArr[i].beginTimeList[k].time) < 8
              ) {
                this.stuTotal1 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 8 &&
                Number(tempArr[i].beginTimeList[k].time) < 10
              ) {
                this.stuTotal2 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 10 &&
                Number(tempArr[i].beginTimeList[k].time) <= 12
              ) {
                this.stuTotal3 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 13 &&
                Number(tempArr[i].beginTimeList[k].time) < 15
              ) {
                this.stuTotal4 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 15 &&
                Number(tempArr[i].beginTimeList[k].time) < 17
              ) {
                this.stuTotal5 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 17 &&
                Number(tempArr[i].beginTimeList[k].time) < 19
              ) {
                this.stuTotal6 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 19 &&
                Number(tempArr[i].beginTimeList[k].time) < 21
              ) {
                this.stuTotal7 += 1;
              }
              if (
                Number(tempArr[i].beginTimeList[k].time) >= 21 &&
                Number(tempArr[i].beginTimeList[k].time) < 23
              ) {
                this.stuTotal8 += 1;
              }
            }
          }
          this.studentList = tempArr;
          console.log(this.studentList, "studentList....");
        }
      });
    },
    timeTab(day) {
      var dd = new Date(this.tSearchTime);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      var time = y + "-" + m + "-" + d;
      this.tSearchTime = time;
      this.timePickerValueTeacher = time;
      this.getProductParams.searchBeginDate = time + " " + "00:00:00";
      this.getProductParams.searchEndDate = time + " " + "23:59:59";
      var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      this.tSearchWeek = weekArray[new Date(time).getDay()]; //注意此处必须是先new一个Date
      this.getSyllabusPageDeptOneFn();
    },
    timeStudentTab(day) {
      var dd = new Date(this.tStudentTime);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      var time = y + "-" + m + "-" + d;
      this.tStudentTime = time;
      this.studentTimePicker = time;
      this.getProductParamsStudent.searchBeginDate = time + " " + "00:00:00";
      this.getProductParamsStudent.searchEndDate = time + " " + "23:59:59";
      var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      this.tStudentWeek = weekArray[new Date(time).getDay()]; //注意此处必须是先new一个Date
      this.getSyllabusPageStudentFn();
    },
    classNoticeClick(row) {
      var beginTime = new Date(row.beginTime);
      var endTime = beginTime.setHours(
        beginTime.getHours + Number(row.usedClassHour)
      );
      console.log(endTime, "endTime");

      const noticeList = [
        {
          beginTime: row.beginTime,
          classMode: row.classMode,
          id: row.id,
          sectionName: row.sectionName,
          studentName: row.studentName,
          subjectName: row.subjectName,
          teacherName: row.teacherName,
          teacherRealName: row.teacherRealName,
          toAccount: [row.stuChatId, row.teaChatId, row.parChatId],
          classAddress: row.classAddress ? row.classAddress : "",
          stopTime: row.stopTime,
          usedClassHour: row.usedClassHour,
        },
      ];
      if (row.kbcxStuTz == 0) {
        //可以通知
        batchSendCourseRemind(noticeList).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("通知成功！");
            this.findProductItemPageFn();
          }
        });
      } else {
        this.msgWarn("已通知！");
        return;
      }
    },
    noticeClick(row, type) {
      const noticeList = [];
      for (let i = 0; i < row.beginTimeList.length; i++) {
        noticeList.push({
          classAddress: row.beginTimeList[i].classAddress,
          beginTime: row.beginTimeList[i].beginTime,
          classMode: row.beginTimeList[i].classMode,
          id: row.beginTimeList[i].id,
          sectionName: row.beginTimeList[i].sectionName,
          studentName: row.beginTimeList[i].studentNameNotice,
          subjectName: row.beginTimeList[i].subjectNameNotice,
          teacherName: row.beginTimeList[i].teacherNameNotice,
          teacherRealName: row.beginTimeList[i].teacherName,
          parChatId: row.beginTimeList[i].parChatId,
          toAccount:
            type == "teacher"
              ? row.beginTimeList[i].teaChatId
              : row.beginTimeList[i].stuChatId,
          stopTime: row.beginTimeList[i].stopTime,
          usedClassHour: row.beginTimeList[i].usedClassHour,
        });
      }
      if (type == "teacher") {
        if (row.beginTimeList.some((item) => item.lskbTz == 0)) {
          //可以通知
          sendCourseRemind(noticeList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("通知成功！");
              this.getSyllabusPageDeptOneFn();
            }
          });
        } else {
          this.msgWarn("已通知！");
          return;
        }
      } else {
        if (row.beginTimeList.some((item) => item.xskbTz == 0)) {
          //学生可以通知
          sendCourseRemind(noticeList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("通知成功！");
              this.getSyllabusPageStudentFn();
              this.findProductItemPageFn();
            }
          });
        } else {
          this.msgWarn("已通知！");
          return;
        }
      }
    },
    recoveryCourse(row) {
      let startTime = new Date(row.startTime); //开始上课时
      startTime.setHours(startTime.getHours() + Number(row.usedClassHour));
      let endTime = new Date(row.endTime).getTime(); //结束上课时间
      resumeClass({
        id: row.id,
        studentId: row.studentId,
      }).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("恢复课程成功！");
          this.findProductItemPageFn();
        }
      });
    },
    recoveryCourseMore(row) {
      let startTime = new Date(row.startTime); //开始上课时
      startTime.setHours(startTime.getHours() + Number(row.usedClassHour));
      let endTime = new Date(row.endTime).getTime(); //结束上课时间
      resumeClass({
        id: row.id,
      }).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("恢复课程成功！");
          this.findMoreProductItemPage();
        }
      });
    },
    recoveryType(row) {
      let startTime = new Date(row.startTime); //开始上课时
      startTime.setHours(startTime.getHours() + Number(row.usedClassHour));
      if (new Date().getTime() > new Date(startTime).getTime()) {
        return true;
      } else {
        return false;
      }
    },
    editOneCourse(row) {
      this.isStatus = 1;
      this.rowId = row.id;
      this.rowStudentId = row.studentId;
      this.singlePopup = true;
      this.classAddressData.studentId = row.studentId;
      this.editTime = row.beginTime;
      this.editUsedClassHour = row.usedClassHour;
      this.teacherOneParams.studentId = row.studentId;
      this.teacherOneParams.subjectId = row.subjectId;
      this.getSectionName = row.sectionName ? row.sectionName : "";
      this.getSectionId = row.sectionId;
      getProductByStudentId(row.studentId).then((res) => {
        this.buyProductList = res.rows;
      });
      studentInfo(this.rowStudentId).then((res) => {
        this.accountBalance = res.rows.accountBalance;
        this.remainingClassHours = res.rows.remainingClassHours;
      });
      this.getOrganBalance();
      xmtProductItemInfo(this.rowId).then((res) => {
        this.editId = res.rows.id;
        this.getSubjectId = res.rows.subjectId;
        this.getTeacher = res.rows.teacherId;
        this.teacherParams.subjectId = res.rows.subjectId;
        this.editUsePrice = res.rows.usePrice;
        this.editClassHourType = res.rows.classHourType;
        this.singleRuleForm = {
          id: res.rows.id,
          subjectId: res.rows.subjectId,
          title: res.rows.title,
          beginTime: res.rows.beginTime,
          usedClassHour: res.rows.usedClassHour,
          isFirstClass: String(res.rows.isFirstClass),
          teacherId: res.rows.teacherId,
          classroomId: res.rows.classroomId,
          classMode: String(res.rows.classMode),
          classHourType: String(res.rows.classHourType),
          classAddress: res.rows.classAddress,
          productId: res.rows.productId,
          configteacherPlanId: res.rows.configteacherPlanId,
          isPrisonClass: String(res.rows.isPrisonClass),
          isPlayback: String(res.rows.isPlayback),
          ifClassRemuneration: String(res.rows.ifClassRemuneration),
          videoMode: res.rows.videoMode,
          expense: "--",
        };
        this.getSingleClassFee();
        if (res.rows.classHourType == 1 || res.rows.classHourType == 2) {
          this.classHourStatus = "2";
        } else {
          this.classHourStatus = "1";
        }
        this.newteacherName = res.rows.teacherRealName;
        if (this.classHourStatus == 2) {
          this.surplusNumFn(res.rows.productId);
        }

        for (var i = 0; i < this.buyProductList.length; i++) {
          if (this.buyProductList[i].orderId == res.rows.orderId) {
            this.productSinglePrice = this.buyProductList[i].singlePrice
              ? Number(this.buyProductList[i].singlePrice)
              : Number(this.buyProductList[i].zdySinglePrice);
          }
        }
      });
    },
    findProductItemPageFn() {
      findProductItemPage(this.listParams).then((res) => {
        this.oneTooneData = res.rows;
        this.oneTooneTotal = res.total;
      });
    },
    findMoreProductItemPage() {
      findMoreProductItemPage(this.listMoreParams).then((res) => {
        this.oneToMoreData = res.rows;
        this.oneToMoreTotal = res.total;
      });
    },
    cancelFn() {
      if (this.removeClassRadio == "") {
        this.msgWarn("请选择删除原因！");
        return;
      }
      let data = {
        id: this.getRemoveId,
        delFlag: 0,
        delCause: this.removeClassRadio,
      };
      xmtProductItemUpdate(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.findProductItemPageFn();
          this.removeClassVisible = false;
          this.removeClassRadio = "";
        }
      });
    },
    cancelFnMore(row) {
      let data = {
        id: row.id,
        delFlag: 0,
      };
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          xmtProductItemUpdate(data).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.findMoreProductItemPage();
            }
          });
        })
        .catch(() => {});
    },
    sureMoreSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.singleRuleForm.expense == "--") {
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            return this.$message.warning("流量费用不能大于账户余额！");
          }
          if (
            dayjs(this.moreRuleForm.beginTime)
              .add(1, "minute")
              .isBefore(dayjs())
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return;
          }
          xmtProductItemDelete(this.editId).then((res) => {
            checkTeacherClassTime(this.moreRuleForm).then((res) => {
              if (res.msg == "操作成功！") {
                checkStudentClassTime(this.moreRuleForm).then((res) => {
                  if (res.msg == "操作成功！") {
                    this.moreRuleForm.classroomId = this.getClassroomId;
                    saveOneToMore(this.moreRuleForm).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("操作成功！");
                        this.morePopup = false;
                        this.findMoreProductItemPage();
                      } else {
                        this.msgWarn(res.msg);
                        return;
                      }
                    });
                  } else {
                    //学生被占用
                    this.centerType = "student";
                    this.centerStudentName = res.rows.studentName;
                    this.centerTeacherName = res.rows.teacherName;
                    this.centerBeginTime = res.rows.beginTime;
                    this.centerTitle = res.rows.title;
                    this.centerUsedClassHour = res.rows.usedClassHour;
                    this.centerDialogVisible = true;
                  }
                });
              } else {
                //老师被占用
                this.centerType = "teacher";
                this.centerStudentName = res.rows.studentName;
                this.centerTeacherName = res.rows.teacherName;
                this.centerBeginTime = res.rows.beginTime;
                this.centerTitle = res.rows.title;
                this.centerUsedClassHour = res.rows.usedClassHour;
                this.centerDialogVisible = true;
              }
            });
          });
        }
      });
    },
    dayHourFormatter1(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 6 && row.beginTimeList[i].time < 8) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 1)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 6 &&
            endHour == 8 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                            <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                            <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter2(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 8 && row.beginTimeList[i].time < 10) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 8 &&
            endHour == 10 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter3(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 10 && row.beginTimeList[i].time < 13) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 10 &&
            endHour == 12 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter4(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 13 && row.beginTimeList[i].time < 15) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 13 &&
            endHour == 15 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter5(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 15 && row.beginTimeList[i].time < 17) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 15 &&
            endHour == 17 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter6(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 17 && row.beginTimeList[i].time < 19) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 17 &&
            endHour == 19 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter7(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 19 && row.beginTimeList[i].time < 21) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 19 &&
            endHour == 21 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourFormatter8(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (
          row.beginTimeList[i].time >= 21 &&
          row.beginTimeList[i].time <= 24
        ) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 21 &&
            endHour == 24 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].studentName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].studentName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourStudent1(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 6 && row.beginTimeList[i].time < 8) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 1)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 6 &&
            endHour == 8 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourStudent2(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 8 && row.beginTimeList[i].time < 10) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 8 &&
            endHour == 10 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      return str;
    },
    dayHourStudent3(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 10 && row.beginTimeList[i].time < 13) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 10 &&
            endHour == 12 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    dayHourStudent4(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 13 && row.beginTimeList[i].time < 15) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 13 &&
            endHour == 15 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    dayHourStudent5(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 15 && row.beginTimeList[i].time < 17) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 15 &&
            endHour == 17 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    dayHourStudent6(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 17 && row.beginTimeList[i].time < 19) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 17 &&
            endHour == 19 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    dayHourStudent7(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (row.beginTimeList[i].time >= 19 && row.beginTimeList[i].time < 21) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 19 &&
            endHour == 21 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    dayHourStudent8(row) {
      var str = "";
      for (var i = 0; i < row.beginTimeList.length; i++) {
        if (
          row.beginTimeList[i].time >= 21 &&
          row.beginTimeList[i].time <= 24
        ) {
          var startHour = Number(row.beginTimeList[i].sendtime.substring(0, 2)),
            startHourEnd = Number(
              row.beginTimeList[i].sendtime.substring(3, 5)
            ),
            endHour = Number(row.beginTimeList[i].endTime.substring(0, 2)),
            endHourEnd = Number(row.beginTimeList[i].endTime.substring(3, 5));
          if (
            startHour == 21 &&
            endHour == 24 &&
            startHourEnd == 0 &&
            endHourEnd == 0
          ) {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              //异常
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p>
                      <p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p>`;
            } else {
              str += `<p>${row.beginTimeList[i].teacherName}</p>
                      <p>${row.beginTimeList[i].subjectName}</p>`;
            }
          } else {
            if (row.beginTimeList[i].abnormalStatus == 1) {
              str += `<p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p style="color:red;" get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            } else {
              str += `<p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].teacherName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].subjectName}</p><p get-id=${row.beginTimeList[i].id}>${row.beginTimeList[i].sendtime}-${row.beginTimeList[i].endTime}</p>`;
            }
          }
        }
      }
      if (str == "undefined") {
        return "";
      } else {
        return str;
      }
    },
    copy1(row) {
      if (row.beginTimeList.length) {
        var Url1 = [];
        var oInput = document.createElement("textarea");
        for (var i = 0; i < row.beginTimeList.length; i++) {
          var weekDay = [
            "周日",
            "周一",
            "周二",
            "周三",
            "周四",
            "周五",
            "周六",
          ];
          var myDate = new Date(Date.parse(row.beginTimeList[i].beginTime));
          var endtime =
            Number(row.beginTimeList[i].sendtime.split(":")[0]) +
            Number(row.beginTimeList[i].sendusedClassHour);
          Url1.unshift(
            row.beginTimeList[0].beginTime.split(" ")[0] +
              " " +
              "(" +
              weekDay[myDate.getDay()] +
              ")" +
              "   上课提醒:  " +
              row.beginTimeList[i].sendtime +
              "-" +
              endtime +
              ":" +
              row.beginTimeList[i].sendtime.split(":")[1] +
              "      学生：" +
              row.beginTimeList[i].studentName +
              "(" +
              row.beginTimeList[i].teacherName +
              ")"
          );
        }
        oInput.value = Url1.join("\n"); //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
      }
    },
    copy2(row) {
      if (row.beginTimeList.length) {
        var Url1 = [];
        var oInput = document.createElement("textarea");
        for (var i = 0; i < row.beginTimeList.length; i++) {
          var weekDay = [
            "周日",
            "周一",
            "周二",
            "周三",
            "周四",
            "周五",
            "周六",
          ];
          var myDate = new Date(Date.parse(row.beginTimeList[i].beginTime));
          var endtime =
            Number(row.beginTimeList[i].sendtime.split(":")[0]) +
            Number(row.beginTimeList[i].sendusedClassHour);
          Url1.unshift(
            row.beginTimeList[0].beginTime.split(" ")[0] +
              " " +
              "(" +
              weekDay[myDate.getDay()] +
              ")" +
              "   上课提醒:  " +
              row.beginTimeList[i].sendtime +
              "-" +
              endtime +
              ":" +
              row.beginTimeList[i].sendtime.split(":")[1] +
              "      " +
              row.beginTimeList[i].subjectName +
              "(" +
              row.beginTimeList[i].studentName +
              ")"
          );
        }
        oInput.value = Url1.join("\n"); //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
      }
    },
    moreEdit(row) {
      this.isStatus = 2;
      this.morePopup = true;
      this.getClassroomId = row.classroomId;
      this.teacherParams.classroomId = row.classroomId;
      this.teacherParams.subjectId = row.subjectId;
      this.editId = row.id;
      this.oneToMoreClassNum = row.peopleNum;
      xmtProductItemInfo(row.id).then((res) => {
        this.moreRuleForm = {
          id: res.rows.id,
          subjectId: res.rows.subjectId,
          title: res.rows.title,
          beginTime: res.rows.beginTime,
          usedClassHour: res.rows.usedClassHour,
          isFirstClass: String(res.rows.isFirstClass),
          teacherId: res.rows.teacherId,
          classroomId: res.rows.classroomId,
          sectionId: res.rows.sectionId,
          videoMode: res.rows.videoMode,
          expense: "",
        };
        this.moreTeacherName = res.rows.teacherRealName;
        this.moreSubjectId = res.rows.subjectId;
      });
    },
    importExcel() {
      let jsonData = [],
        beforeData = [
          { time: "", content: "" },
          { time: "", content: "" },
          { time: "", content: "" },
        ],
        str = `讲师姓名,6:00-8:00,8:00-10:00,10:00-12:00,13:00-15:00,15:00-17:00,17:00-19:00,19:00-21:00,21:00-23:00\n`;
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < beforeData.length; i++) {
        for (const key in beforeData[i]) {
          str += `${beforeData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      // encodeURIComponent解决中文乱码?
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);

      const link = document.createElement("a");
      link.href = uri;
      link.download = "讲师课表.xls";
      link.click();
    },
    importExcel2() {
      let jsonData = [],
        str = `学生姓名,6:00-8:00,8:00-10:00,10:00-12:00,13:00-15:00,15:00-17:00,17:00-19:00,19:00-21:00,21:00-23:00\n`;
      for (let i = 0, len = this.studentList.length; i < len; i++) {
        jsonData.push({
          studentName: this.studentList[i].studentName,
          studentSectionName:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          orderNo:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          productName:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          productType:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          courseTypeId:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          createTime:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          orderStatus:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          saleName:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
          moneyType:
            this.studentList[i].teacherName + this.studentList[i].subjectName,
        });
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      console.log(str);
      // encodeURIComponent解决中文乱码
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "学生课表.xls";
      link.click();
    },
    formatterMoneyType(item) {
      switch (item.moneyType) {
        case 1:
          return "全款";
        case 2:
          return "定金";
        case 3:
          return "尾款";
        default:
          return "";
      }
    },
    formatterOrderStatus(item) {
      if (item.orderStatus) {
        for (let i = 0, len = this.orderStatusList.length; i < len; i++) {
          if (this.orderStatusList[i].value == item.orderStatus) {
            return this.orderStatusList[i].label;
          }
        }
      }
    },
    formatterCourseType(item) {
      switch (item.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    formatterProductType(item) {
      switch (item.productType) {
        case 1:
        case 3:
          return item.isSubscribe == 1 ? "试听课" : "一对一";
        case 2:
          return "一对多";
        case 4:
          return "试听课";
        case 5:
          return "一对多";
      }
    },
    sureTeacher() {
      this.moreTeacherPopup = false;
      if (this.isStatus == 1) {
        //一对一
        this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
        this.newteacherName = this.teacherSelectionHandle[0].teacherRealname;
        this.singleRuleForm.teacherId =
          this.teacherSelectionHandle[0].teacherId;
        this.singleRuleForm.configteacherPlanId =
          this.teacherSelectionHandle[0].id;
        this.singleRuleForm.teachFee = this.teacherSelectionHandle[0].teachFee;
      } else if (this.isStatus == 2) {
        //一对多
        this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
        this.moreTeacherName = this.teacherSelectionHandle[0].teacherRealname;
        this.moreRuleForm.teacherId = this.teacherSelectionHandle[0].teacherId;
        this.moreRuleForm.configteacherPlanId =
          this.teacherSelectionHandle[0].id;
        this.moreRuleForm.teachFee = this.teacherSelectionHandle[0].teachFee;
      }
    },
    handleTeacherSelectionChange(val) {
      this.teacherSelectionHandle = val;
    },
    rowTeacherItemClick(row) {
      this.$refs.resTeacherListTable.toggleRowSelection(row);
    },
    getMoreTeacher() {
      // this.teacherParams.subjectId=this.moreRuleForm.subjectId;
      if (!this.moreRuleForm.subjectId) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.moreTeacherPopup = true;
      this.configMoreTeacherPlan();
    },
    getRowKeys(row) {
      return row.id;
    },
    configMoreTeacherPlan() {
      configteacherPlan(this.teacherParams).then((res) => {
        this.matchingTeacherList = res.rows;
        // this.teacherTotalnew=res.total;
      });
    },
    configOneTeacherPlan() {
      configteacherPlan(this.teacherOneParams).then((res) => {
        this.matchingTeacherList = res.rows;
        // this.teacherTotalnew=res.total;
      });
    },

    onTooneSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.findProductItemPageFn();
    },
    onTooneCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.findProductItemPageFn();
    },
    oneToMoreSizeChange(pageSize) {
      this.listMoreParams.pageSize = pageSize;
      this.findMoreProductItemPage();
    },
    oneToMoreCurrentChange(currentPage) {
      this.listMoreParams.pageNumber = currentPage;
      this.findMoreProductItemPage();
    },
    formatterAttendClassStatus(row) {
      switch (row.attendClassStatus) {
        case 0:
          return "未上课";
        case 1:
          return "已上课";
        case 2:
          return "上课中";
        default:
          return "";
      }
    },
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    //获取学科
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
          this.singSubjectIdList = res.rows;
          this.singSubjectIdList.splice(0, 2);
        }
      });
    },
    getSubjectListSearch() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
  },
  mounted() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
    this.timePickerValueTeacher = year + "-" + month + "-" + day;
    this.tSearchTime = year + "-" + month + "-" + day;
    this.tSearchWeek =
      weekArray[new Date(this.timePickerValueTeacher).getDay()]; //注意此处必须是先new一个Date
    this.studentTimePicker = year + "-" + month + "-" + day;
    this.tStudentTime = year + "-" + month + "-" + day;
    this.tStudentWeek = weekArray[new Date(this.studentTimePicker).getDay()]; //注意此处必须是先new一个Date
    this.timePickerValue = [
      year + "-" + month + "-" + day + " 00:00:00",
      year + "-" + month + "-" + day + " 23:59:59",
    ];
    this.timePickerValue2 = [
      year + "-" + month + "-" + day + " 00:00:00",
      year + "-" + month + "-" + day + " 23:59:59",
    ];
    this.listParams.searchBeginDate = this.timePickerValue[0];
    this.listParams.searchEndDate = this.timePickerValue[1];
    this.listMoreParams.searchBeginDate = this.timePickerValue2[0];
    this.listMoreParams.searchEndDate = this.timePickerValue2[1];
    this.getSectionIdList(); //获取搜索年级
    this.getSubjectList(); //获取搜索学科
    this.getSubjectListSearch();
    this.findProductItemPageFn();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 300px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .subject_ul li {
    text-align: center;
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 10px;
  }
  .classTimeUl {
    display: flex;
    margin-top: 20px;
    li {
      margin-right: 30px;
    }
    span {
      display: inline-block;
      width: 15px;
      height: 15px;
    }
    .span1 {
      background: #bbff00;
    }
    .span2 {
      background: #33ccff;
    }
    .span3 {
      background: #eeee00;
    }
    .span4 {
      background: #ccc;
    }
  }
}
.searchTime {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 18px;
  cursor: pointer;
  span:nth-child(2) {
    padding: 0 15px;
  }
}
.statusActive {
  display: none;
}
.qrcodeDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qrcodeDiv img {
  margin: 0 auto;
}
.census_ul {
  height: 50px;
  line-height: 50px;
  display: flex;
  margin-top: 30px;
  li {
    flex: 1;
    text-align: center;
  }
}
/deep/ .add_class .el-dialog__body {
  padding-bottom: 10px;
}
.price_tip {
  margin-top: 10px;
  display: flex;
  line-height: 24px;
  padding-left: 28px;
  .account_fee {
    margin-left: 20px;
  }
}
</style>
