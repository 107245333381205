<template>
  <el-dialog
    title=""
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    width="800px"
    center
    :append-to-body="true"
    @close="closeDialog"
  >
    <div class="dialog-content">
      <div class="navContent">
        <dl>
          <dt>
            <el-image
              class="teacherPhoto"
              :src="teacherDetail.photo"
              :preview-src-list="teacherDetail.imageArray"
              alt=""
            ></el-image>
          </dt>
          <dd>
            <p>
              <span>{{
                teacherDetail.realname ? teacherDetail.realname : "暂无数据"
              }}</span>
              <span>{{
                teacherDetail.sexId == 1
                  ? "男"
                  : teacherDetail.sexId == 2
                  ? "女"
                  : ""
              }}</span>
              <span v-show="teacherDetail.contact">{{
                teacherDetail.contact
              }}</span>
              <span>{{ ageFn(teacherDetail.birthday) }}</span>
            </p>
            <p>
              <span>{{ getSectionId(teacherDetail.sectionId) }}</span>
              <span>{{ getSubjectId(teacherDetail.subjectId) }}</span>
              <span class="endSpan">{{
                getIdentityType(teacherDetail.identityType)
              }}</span>
            </p>
            <p>
              <span>账号：{{teacherDetail.account}}</span>
            </p>
            <p>
              <span>共有{{ personNumber }}位教务关注</span>
            </p>
          </dd>
        </dl>
        <div>
          <p>注册时间：{{ teacherDetail.createTime ? teacherDetail.createTime.substring(0, 11) : '暂无数据'}}</p>
          <el-button
            @click="dealFollow()"
            class="btn"
            type="success"
            size="small"
            >{{ commonStatus ? "取消常用" : "设为常用" }}</el-button
          >
        </div>
      </div>
      <el-tabs
        v-model="tabsName"
        type="card"
        @tab-click="tabClick"
        style="margin-top: 30px"
      >
        <el-tab-pane label="讲师信息" name="first">
          <p class="titleP" style="margin-top: 40px !important">基本信息</p>
          <el-descriptions
            class="margin-top"
            title=""
            :column="3"
            border
            style="margin-top: 24px"
          >
            <el-descriptions-item>
              <template slot="label"> 昵称 </template>
              {{ teacherDetail.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 出生日期 </template>
              {{
                teacherDetail.birthday
                  ? teacherDetail.birthday.substring(0, 11)
                  : "暂无数据"
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 籍贯 </template>
              {{ teacherDetail.provinceName }}{{ teacherDetail.cityName
              }}{{ teacherDetail.countyName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                {{
                  teacherDetail.identityType == 1 ||
                  teacherDetail.identityType == 3
                    ? "学历"
                    : "在读阶段"
                }}
              </template>
              {{
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 3
                  ? educationFn(teacherDetail.educationId)
                  : stageFn(teacherDetail.readingStage)
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                {{
                  teacherDetail.identityType == 1 ||
                  teacherDetail.identityType == 3
                    ? "毕业院校"
                    : "在读院校"
                }}
              </template>
              {{
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 3
                  ? teacherDetail.graduationSchool
                  : teacherDetail.schoolName
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 期望课酬 </template>
              {{
                teacherDetail.hopeMinFee
                  ? teacherDetail.hopeMinFee
                  : "暂无数据"
              }}—{{
                teacherDetail.hopeMaxFee
                  ? teacherDetail.hopeMaxFee
                  : "暂无数据"
              }}元/课时
            </el-descriptions-item>
            <template
              v-if="
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 3
              "
            >
              <el-descriptions-item>
                <template slot="label"> 教龄 </template>
                {{ teachingAgeFn(teacherDetail) }}
              </el-descriptions-item>
              <el-descriptions-item v-if="teacherDetail.identityType == 1">
                <template slot="label"> 任职学校 </template>
                {{
                  teacherDetail.schoolName
                    ? teacherDetail.schoolName
                    : "暂无数据"
                }}
              </el-descriptions-item>
              <el-descriptions-item v-if="teacherDetail.identityType == 1">
                <template slot="label"> 教师职称 </template>
                {{ teachTitleFn(teacherDetail) }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item>
              <template slot="label"> 授课方式 </template>
              {{ classFormFn(teacherDetail.classForm) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 是否接受试听 </template>
              {{
                teacherDetail.ifAcceptAudition == 1
                  ? "可接受45分钟~1小时试听"
                  : "不接受"
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 可面授地点 </template>
              {{ teacherDetail.currentProvincesName
              }}{{ teacherDetail.currentCitysName }}{{ getCurrentCountysName }}
            </el-descriptions-item>
          </el-descriptions>
          <ul class="navInfo">
            <li
              v-show="
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 2
              "
            >
              <span>善教学生：</span
              ><span>{{ judgeStyleNeedFn(teacherDetail.skilfulTeach) }}</span>
            </li>
            <li
              v-show="
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 2
              "
            >
              <span>讲课风格：</span
              ><span>{{ skilfulFn(teacherDetail.skilful) }}</span>
            </li>
            <li
              v-show="
                teacherDetail.identityType == 1 ||
                teacherDetail.identityType == 2
              "
            >
              <span>教学特点：</span
              ><span>{{
                teacherDetail.teachTrait ? teacherDetail.teachTrait : "暂无数据"
              }}</span>
            </li>
            <li>
              <span>附件管理：</span>
              <span>
                <a
                  target="view_window"
                  v-show="teacherDetail.resume"
                  :href="teacherDetail.resume"
                  style="display: block; text-decoration: none"
                  >{{ teacherDetail.realname }}简历</a
                >
                <span v-show="!teacherDetail.resume">还未上传附件简历</span>
              </span>
            </li>
          </ul>
          <p
            class="titleP"
            v-show="
              teacherDetail.identityType == 1 || teacherDetail.identityType == 2
            "
          >
            成功案例
          </p>
          <div
            class="caseDiv"
            v-for="(item, index) in caseList"
            :key="index"
            v-show="
              teacherDetail.identityType == 1 || teacherDetail.identityType == 2
            "
          >
            <div>
              <p>
                <span>{{ item.studentName }}</span>
                <span>{{ getSectionId(String(item.studentSectionId)) }}</span>
                <span
                  >{{
                    item.classTimeBegin
                      ? item.classTimeBegin.substring(0, 11)
                      : "暂无数据"
                  }}
                  至
                  {{
                    item.classTimeEnd
                      ? item.classTimeEnd.substring(0, 11)
                      : "暂无数据"
                  }}</span
                >
                <span>{{ studentAchievementFn(item.studentAchievement) }}</span>
              </p>
              <p style="margin-top: 15px">{{ item.detailContent }}</p>
              <p>
                <el-image
                  style="
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                    margin-top: 15px;
                  "
                  v-for="(items, indexs) in item.photoList"
                  :preview-src-list="item.photoList"
                  :src="items"
                  :key="indexs"
                  alt=""
                ></el-image>
              </p>
            </div>
          </div>
          <p
            class="titleP"
            v-show="
              teacherDetail.identityType == 3 || teacherDetail.identityType == 4
            "
          >
            代课经历
          </p>
          <div
            class="caseDiv"
            v-for="(item, index) in caseList"
            :key="index"
            v-show="
              teacherDetail.identityType == 3 || teacherDetail.identityType == 4
            "
          >
            <div>
              <p>
                <span>{{ item.studentName }}</span>
                <span>{{ getSectionId(String(item.studentSectionId)) }}</span>
                <span
                  >{{
                    item.classTimeBegin
                      ? item.classTimeBegin.substring(0, 11)
                      : "暂无数据"
                  }}
                  至
                  {{
                    item.classTimeEnd
                      ? item.classTimeEnd.substring(0, 11)
                      : "暂无数据"
                  }}</span
                >
                <span>{{ studentAchievementFn(item.studentAchievement) }}</span>
              </p>
              <p style="margin-top: 15px">{{ item.detailContent }}</p>
              <p>
                <el-image
                  style="
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                    margin-top: 15px;
                  "
                  v-for="(items, indexs) in item.photoList"
                  :preview-src-list="item.photoList"
                  :src="items"
                  :key="indexs"
                  alt=""
                ></el-image>
              </p>
            </div>
          </div>
          <p
            class="titleP"
            v-show="
              teacherDetail.identityType == 3 || teacherDetail.identityType == 4
            "
          >
            作品展示
          </p>
          <p
            v-show="
              teacherDetail.identityType == 3 || teacherDetail.identityType == 4
            "
          >
            <el-image
              style="
                width: 100px;
                height: 100px;
                margin-right: 10px;
                margin-top: 15px;
              "
              v-for="(items, indexs) in daikeList"
              :preview-src-list="daikeLists"
              :src="items.url"
              :key="indexs"
              alt=""
            ></el-image>
          </p>
          <p class="titleP">试讲视频</p>
          <div>
            <p v-html="getResult" style="margin-top: 15px"></p>
            <div class="video_area">
              <video
                id="video"
                :src="videoForm.videoUrl"
                controls
                v-show="videoForm.videoUrl"
              ></video>
              <div v-show="!videoForm.videoUrl">暂无</div>
            </div>
            <p>
              <el-image
                style="
                  cursor: pointer;
                  width: 100px;
                  height: 100px;
                  margin-right: 10px;
                  margin-top: 15px;
                "
                v-for="(items, indexs) in fileList"
                :src="items.url"
                :key="indexs"
                :preview-src-list="fileLists"
                alt=""
              ></el-image>
            </p>
          </div>
          <p class="titleP">面试答题</p>
          <ul class="answer_ul">
            <li v-for="(item, index) in answerList" :key="index">
              <p>问题{{ index + 1 }}：{{ item.questionTitle }}？</p>
              <p class="twoP">答：{{ item.answer }}</p>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="评价详情" name="two">
          <p class="titleP" style="margin-top: 40px !important">面试评级</p>
          <el-descriptions
            class="margin-top"
            title="录入员"
            :column="3"
            border
            style="margin-top: 20px"
          >
            <el-descriptions-item>
              <template slot="label"> 形象气质 </template>
              {{ appearance(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 教学经验 </template>
              {{ lectureStyle(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 沟通表达 </template>
              {{ connectCapacity(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 在京代课经验 </template>
              {{ lectureSpeed(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 其他合作机构 </template>
              {{ teacherFaceMark1.otherPartnersOne }}
              {{ teacherFaceMark1.otherPartnersTwo }}
              {{ teacherFaceMark1.otherPartnersThree }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 主要代课区域 </template>
              {{
                teacherFaceMark1.provinceOneName
                  ? teacherFaceMark1.provinceOneName
                  : ""
              }}
              {{
                teacherFaceMark1.provinceTwoName
                  ? teacherFaceMark1.provinceTwoName
                  : ""
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 教学特色 </template>
              {{ teachingCharacteristics(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 合作求职态度 </template>
              {{ jobHuntingAttitude(teacherFaceMark1) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 整体评分 </template>
              {{ overallScoreFn(teacherFaceMark1) }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            class="margin-top"
            title="审核员"
            :column="3"
            border
            style="margin-top: 20px"
          >
            <el-descriptions-item>
              <template slot="label"> 形象气质 </template>
              {{ appearance(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 教学经验 </template>
              {{ lectureStyle(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 沟通表达 </template>
              {{ connectCapacity(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 在京代课经验 </template>
              {{ lectureSpeed(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 其他合作机构 </template>
              {{ teacherFaceMark2.otherPartnersOne }}
              {{ teacherFaceMark2.otherPartnersTwo }}
              {{ teacherFaceMark2.otherPartnersThree }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 主要代课区域 </template>
              {{
                teacherFaceMark2.provinceOneName
                  ? teacherFaceMark2.provinceOneName
                  : ""
              }}
              {{
                teacherFaceMark2.provinceTwoName
                  ? teacherFaceMark2.provinceTwoName
                  : ""
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 教学特色 </template>
              {{ teachingCharacteristics(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 合作求职态度 </template>
              {{ jobHuntingAttitude(teacherFaceMark2) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 整体评分 </template>
              {{ overallScoreFn(teacherFaceMark2) }}
            </el-descriptions-item>
          </el-descriptions>
          <p class="titleP">整体评价</p>
          <ul class="navInfo">
            <li>
              <span>录入员：</span
              ><span>{{ teacherFaceMark1.overallEvaluation }}</span>
            </li>
            <li>
              <span>审核员：</span
              ><span>{{ teacherFaceMark2.overallEvaluation }}</span>
            </li>
          </ul>
          <p class="titleP">其他人评价</p>
          <el-tabs
            v-model="evaluateName"
            @tab-click="evaluateClick"
            type="card"
            style="margin-top: 20px"
          >
            <el-tab-pane label="学生评价" name="first">
              <div class="evaluateDiv">
                <p>老师的优点：</p>
                <div>
                  <el-tag
                    style="margin-top: 10px"
                    size="small"
                    type="warning"
                    v-for="(value, key) in goodMapData"
                    :key="key"
                    >{{ key }}（{{ value }}）</el-tag
                  >
                </div>
              </div>
              <div class="evaluateDiv">
                <p>希望老师改进的地方：</p>
                <div>
                  <el-tag
                    style="margin-top: 10px"
                    size="small"
                    type="warning"
                    v-for="(value, key) in badMapData"
                    :key="key"
                    >{{ key }}（{{ value }}）</el-tag
                  >
                </div>
              </div>
              <div class="studentContent">
                <dl v-for="(item, index) in studentEvaluateList" :key="index">
                  <dt><img :src="item.studentPhoto ? item.studentPhoto : 'https://rcmtoss.oss-cn-beijing.aliyuncs.com/default/studentDefault.png'" alt="" /></dt>
                  <dd>
                    <div class="contT">
                      <p>
                        <span>{{ item.studentName }}</span
                        ><img :src="xueshengPng" alt="" />
                      </p>
                      <p>评价于{{ item.createTime }}</p>
                    </div>
                    <div class="contTag">
                      <el-tag
                        style="margin-right: 10px; margin-bottom:10px"
                        type="warning"
                        v-for="(items, indexs) in item.evaluationTags"
                        :key="indexs"
                        >{{ items }}</el-tag
                      >
                    </div>
                    <p>{{ item.evaluationContent }}</p>
                  </dd>
                </dl>
              </div>
            </el-tab-pane>
            <el-tab-pane label="教务评价" name="two">
              <div>
                <el-tag
                  @click="labelClick(items.labelId)"
                  style="margin-right: 10px; margin-top: 8px; cursor: pointer"
                  type="warning"
                  v-for="(items, indexs) in labelData"
                  :key="indexs"
                  >{{ items.name }}（{{ items.num }}）</el-tag
                >
              </div>
              <div class="teachContent">
                <dl
                  v-for="(item, index) in teacherEvaluateList"
                  :key="index"
                  style="padding: 26px 0; border-bottom: 1px solid #dcdfe6"
                >
                  <dt><img :src="item.teachManagerPhoto ? item.teachManagerPhoto : 'https://rcmtoss.oss-cn-beijing.aliyuncs.com/default/teacherManagerDefault.png'" alt="" /></dt>
                  <dd>
                    <div class="contT">
                      <p>
                        <span>{{ item.teachManagerName }}</span
                        ><img :src="jiaowuPng" alt="" />
                      </p>
                      <p>评价于{{ item.createTime }}</p>
                    </div>
                    <div class="myEvaluateDiv">
                      <p>{{ item.evaluationContent }}</p>
                      <ul>
                        <li v-show="item.evaluationImage">
                          <span>{{ item.evaluationImage.parentType }}：</span>
                          <span
                            style="color: #ffba00"
                            v-for="(item, index) in item.evaluationImage
                              .selectgoodData"
                            :key="index"
                            >{{ item.name + "#" }}</span
                          >
                          <span>{{ item.evaluationImage.content }}</span>
                        </li>
                        <li v-show="item.evaluationTeaching">
                          <span
                            >{{ item.evaluationTeaching.parentType }}：</span
                          >
                          <span
                            style="color: #ffba00"
                            v-for="(item, index) in item.evaluationTeaching
                              .selectgoodData"
                            :key="index"
                            >{{ item.name + "#" }}</span
                          >
                          <span>{{ item.evaluationTeaching.content }}</span>
                        </li>
                        <li v-show="item.evaluationTeachingStyle">
                          <span
                            >{{
                              item.evaluationTeachingStyle.parentType
                            }}：</span
                          >
                          <span
                            style="color: #ffba00"
                            v-for="(item, index) in item.evaluationTeachingStyle
                              .selectgoodData"
                            :key="index"
                            >{{ item.name + "#" }}</span
                          >
                          <span>{{
                            item.evaluationTeachingStyle.content
                          }}</span>
                        </li>
                        <li v-show="item.evaluationOther">
                          <span>{{ item.evaluationOther.parentType }}：</span>
                          <span
                            style="color: #ffba00"
                            v-for="(item, index) in item.evaluationOther
                              .selectgoodData"
                            :key="index"
                            >{{ item.name + "#" }}</span
                          >
                          <span>{{ item.evaluationOther.content }}</span>
                        </li>
                      </ul>
                    </div>
                  </dd>
                </dl>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="上课详情" name="three">
          <p class="countTab">
            <span
              @click="timeClick(1)"
              :class="activeIndex == 1 ? 'countActive' : ''"
              >近一年</span
            >
            <span
              @click="timeClick(2)"
              :class="activeIndex == 2 ? 'countActive' : ''"
              >近一个月</span
            >
            <span
              @click="timeClick(3)"
              :class="activeIndex == 3 ? 'countActive' : ''"
              >近一周</span
            >
          </p>
          <el-table
            size="small"
            :data="countData.hourCount"
            style="width: 100%; margin-top: 20px"
            border
            tooltip-effect="dark"
            ref="teacherListTable"
          >
            <el-table-column
              prop="title"
              label=""
              align="center"
            ></el-table-column>
            <el-table-column prop="studentNum" label="学员总数" align="center">
              <template slot-scope="scope">
                {{ scope.row.studentNum }}人
              </template>
            </el-table-column>
            <el-table-column
              prop="allClassHour"
              label="计划课时数"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.allClassHour }}课时
              </template>
            </el-table-column>
            <el-table-column
              prop="usedClassHour"
              label="已上课时数"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.usedClassHour }}课时
              </template>
            </el-table-column>
            <el-table-column
              prop="abnormalClassHour"
              label="异常课时数"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.abnormalClassHour }}课时
              </template>
            </el-table-column>
          </el-table>
          <p class="titleP">学员年级分布</p>
          <div
            ref="sectionCount"
            style="width: 410px; height: 410px; margin: 0 auto"
          ></div>
          <p class="titleP">首次课评价</p>
          <div
            ref="firstClassCount"
            style="width: 410px; height: 410px; margin: 0 auto"
          ></div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" size="small" type="primary"
        >返 回</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  teachStyle,
  achievement,
  readingStage,
} from "@/api/public";
import {
  selectOftenUsed, //是否常用
  setOftenTeacher, //设置是否常用
  countConcern, //教务关注人数
  teacherInfo, //teacher详情
  getByTeacherId,
  parentCodes,
  teacherEvaluatedContent,
  getListEvaluatedCount, //统计
  getListByTeacherManagerlist,
  getDetailCountByTeacherId,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import {
  teacherFaceMarkDetail,
  finishPaperList,
  findPaperQuestions,
  teacherFindAuthFile,
  getFinishExamDetail,
} from "@/api/teacher/teacherManage";
import xueshengPng from "@/assets/image/xuesheng.png";
import jiaowuPng from "@/assets/image/jiaowu.png";
export default {
  data() {
    return {
      xueshengPng,
      jiaowuPng,
      showDialog: false,
      commonStatus: false, //是否常用
      personNumber: 0,
      teacherDetail: {},
      teacherDetailPhoto: [],
      getCurrentCountysName: "",
      caseList: [],
      daikeList: [],
      daikeLists: [],
      subjectIdList: [],
      sectionList: [],
      tabsName: "first",
      styleNeedArray: [],
      teachStyleArray: [],
      videoForm: {
        videoUrl: "",
      },
      videoformArray: [],
      fileList: [],
      getResult: "",
      urlObj: {},
      fileLists: [],
      answerList: [],
      stageList: [],
      teacherFaceMark1: {},
      teacherFaceMark2: {},
      goodMapData: {},
      badMapData: {},
      urlArr: [],
      timeName: "first",
      evaluateName: "first",
      evaluateStuParams: {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: "",
        evaluationSource: 1, //1学生 0教务
      },
      studentEvaluateList: [],
      evaluateTeaParams: {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: "",
        evaluationSource: 0, //1学生 0教务
        labelId: "",
      },
      teacherEvaluateList: [],
      labelData: [],
      identityTypeData: [
        {
          value: 1,
          label: "学科类-老师",
        },
        {
          value: 2,
          label: "学科类-在校大学生",
        },
        {
          value: 3,
          label: "素质教育类-老师",
        },
        {
          value: 4,
          label: "素质教育类-在校大学生",
        },
      ],
      countParams: {
        teacherId: "",
        searchBeginDate: null,
        searchEndDate: null,
      },
      countData: {},
      activeIndex: "",
    };
  },
  props: ["tInfoRow", "tInfoStatus"],
  watch: {
    tInfoStatus(newValue, oldValue) {
      this.showDialog = newValue;
      this.videoForm.videoUrl = "";
      this.videoformArray = [];
      this.urlObj.url = "";
      this.fileList = [];
      this.getResult = "";
      this.daikeList = [];
      this.tabsName = "first";
      this.evaluateName = "first";
      this.activeIndex = "";
      this.evaluateTeaParams = {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: "",
        evaluationSource: 0, //1学生 0教务
        labelId: "",
      };
      if (newValue == true) {
        this.teacherInfoFn();
        this.selectOftenUsedList();
        this.countConcernFn();
        this.teacherFindAuthFileFn();
      }
    },
  },
  created() {
    this.getSubjectList(); //获取学科
    this.getSectionList(); //获取年级
  },
  mounted() {},
  methods: {
    timeClick(num) {
      this.activeIndex = num;
      if (num == 1) {
        this.getYear();
      } else if (num == 2) {
        this.getAMonth();
      } else if (num == 3) {
        this.getAWeek();
      }
      this.teacherCountFn();
    },
    teacherCountFn() {
      this.countParams.teacherId = this.tInfoRow.id;
      getDetailCountByTeacherId(this.countParams).then((res) => {
        if (res.code == 0) {
          this.countData = res.rows;
          var sectionArray = [],
            firstClassArray = [];
          if (res.rows.sectionCount) {
            for (var i = 0; i < res.rows.sectionCount.length; i++) {
              sectionArray.push({
                value: res.rows.sectionCount[i].num,
                name: res.rows.sectionCount[i].sectionName,
              });
            }
          }
          if (res.rows.firstClassCount) {
            for (var i = 0; i < res.rows.firstClassCount.length; i++) {
              if (res.rows.firstClassCount[i].evaluation_id == 1) {
                res.rows.firstClassCount[i].name = "非常满意";
              } else if (res.rows.firstClassCount[i].evaluation_id == 2) {
                res.rows.firstClassCount[i].name = "满意";
              } else if (res.rows.firstClassCount[i].evaluation_id == 3) {
                res.rows.firstClassCount[i].name = "一般";
              } else if (res.rows.firstClassCount[i].evaluation_id == 4) {
                res.rows.firstClassCount[i].name = "不满意";
              } else if (res.rows.firstClassCount[i].evaluation_id == 5) {
                res.rows.firstClassCount[i].name = "非常不满意";
              }
              firstClassArray.push({
                value: res.rows.firstClassCount[i].num,
                name: res.rows.firstClassCount[i].name,
              });
            }
          }
          let sectionCountCharts = this.$echarts.init(this.$refs.sectionCount);
          let firstClassCharts = this.$echarts.init(this.$refs.firstClassCount);
          let sectionCountOptions = {
            title: {
              text: "",
              subtext: "",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: "60%",
                data: sectionArray,
                itemStyle: {
                  normal: {
                    color: function (params) {
                      //自定义颜色
                      var colorList = [
                        "#224B87",
                        "#FED755",
                        "#F45853",
                        "#FFFF00",
                        "#99FF00",
                        "#993300",
                        "#44A1DF",
                      ];
                      return colorList[params.dataIndex];
                    },
                  },
                },
              },
            ],
          };
          let firstClassOptions = {
            title: {
              text: "",
              subtext: "",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: ["30%", "70%"],
                data: firstClassArray,
                itemStyle: {
                  normal: {
                    color: function (params) {
                      //自定义颜色
                      var colorList = [
                        "#58C486",
                        "#F45853",
                        "#9C6FCE",
                        "#FFFF00",
                        "#646FA3",
                        "#50CDE8",
                      ];
                      return colorList[params.dataIndex];
                    },
                  },
                },
              },
            ],
          };
          sectionCountCharts.setOption(sectionCountOptions);
          firstClassCharts.setOption(firstClassOptions);
        }
      });
    },
    getAWeek() {
      let end = new Date();
      let year = end.getFullYear();
      let month = end.getMonth() + 1; //0-11表示1-12月
      let day = end.getDate();
      let dateObj = {};
      dateObj.end =
        year +
        "-" +
        (month >= 1 && month <= 9 ? "0" : "") +
        month +
        "-" +
        (day >= 0 && day <= 9 ? "0" : "") +
        day;
      if (day - 7 <= 0) {
        //如果在当月7日之前
        let startMonthDay = new Date(year, parseInt(month) - 1, 0).getDate(); //1周前所在月的总天数
        if (month - 1 <= 0) {
          //如果在当年的1月份
          dateObj.start = year - 1 + "-" + 12 + "-" + (31 - (7 - day));
        } else {
          dateObj.start =
            year +
            "-" +
            (month - 1 >= 1 && month - 1 <= 9 ? "0" : "") +
            (month - 1) +
            "-" +
            (startMonthDay - (7 - day) >= 0 && startMonthDay - (7 - day) <= 9
              ? "0"
              : "") +
            (startMonthDay - (7 - day));
        }
      } else {
        dateObj.start =
          year +
          "-" +
          (month >= 1 && month <= 9 ? "0" : "") +
          month +
          "-" +
          (day - 7 >= 0 && day - 7 <= 9 ? "0" : "") +
          (day - 7);
      }
      this.countParams.searchBeginDate = dateObj.start + " 00:00:00";
      this.countParams.searchEndDate = dateObj.end + " 23:59:59";
    },
    getAMonth() {
      let end = new Date();
      let year = end.getFullYear();
      let month = end.getMonth() + 1; //0-11表示1-12月
      let day = end.getDate();
      let dateObj = {};
      // dateObj.end = year + '-' + month + '-' + day
      dateObj.end =
        year +
        "-" +
        (month >= 1 && month <= 9 ? "0" : "") +
        month +
        "-" +
        (day >= 0 && day <= 9 ? "0" : "") +
        day;
      let endMonthDay = new Date(year, month, 0).getDate(); //当前月的总天数
      if (month - 1 <= 0) {
        //如果是1月，年数往前推一年
        dateObj.start =
          year - 1 + "-" + 12 + "-" + (day >= 0 && day <= 9 ? "0" : "") + day;
      } else {
        let startMonthDay = new Date(year, parseInt(month) - 1, 0).getDate();
        if (startMonthDay < day) {
          //1个月前所在月的总天数小于现在的天日期
          if (day < endMonthDay) {
            //当前天日期小于当前月总天数
            //
            dateObj.start =
              year +
              "-" +
              (month - 1 >= 1 && month - 1 <= 9 ? "0" : "") +
              (month - 1) +
              "-" +
              (startMonthDay - (endMonthDay - day) >= 1 &&
              startMonthDay - (endMonthDay - day) <= 9
                ? "0"
                : "") +
              (startMonthDay - (endMonthDay - day));
          } else {
            dateObj.start =
              year +
              "-" +
              (month - 1 >= 1 && month - 1 <= 9 ? "0" : "") +
              (month - 1) +
              "-" +
              (startMonthDay >= 0 && startMonthDay <= 9 ? "0" : "") +
              startMonthDay;
          }
        } else {
          dateObj.start =
            year +
            "-" +
            (month - 1 >= 1 && month - 1 <= 9 ? "0" : "") +
            (month - 1) +
            "-" +
            (day >= 0 && day <= 9 ? "0" : "") +
            day;
        }
      }
      this.countParams.searchBeginDate = dateObj.start + " 00:00:00";
      this.countParams.searchEndDate = dateObj.end + " 23:59:59";
    },
    getYear() {
      let result = "";
      const datenow = new Date();
      const dateend =
        datenow.getFullYear().toString() +
        "-" +
        (datenow.getMonth() + 1).toString() +
        "-" +
        datenow.getDate().toString();
      datenow.setMonth(datenow.getMonth() - 12);
      let dyear = datenow.getFullYear();
      let dmonth = datenow.getMonth() + 1;
      dmonth = dmonth < 10 ? 0 + dmonth : dmonth;
      let dday = datenow.getDate();
      const datestart =
        dyear.toString() + "-" + dmonth.toString() + "-" + dday.toString();

      this.countParams.searchBeginDate = datestart + " 00:00:00";
      this.countParams.searchEndDate = dateend + " 23:59:59";
    },
    handleTimeClick(tab, event) {},
    labelClick(id) {
      this.evaluateTeaParams.labelId = id;
      this.getListByTeacherManagerlistFn();
    },
    evaluateClick(tab, event) {
      if (tab.index == 0) {
        this.teacherEvaluatedContentFn();
      } else if (tab.index == 1) {
        this.evaluateTeaParams.labelId = "";
        this.getListByTeacherManagerlistFn();
        this.getListEvaluatedCountFn();
      }
    },
    getListByTeacherManagerlistFn() {
      this.evaluateTeaParams.teacherId = this.tInfoRow.id;
      getListByTeacherManagerlist(this.evaluateTeaParams).then((res) => {
        this.teacherEvaluateList = res.rows;
        for (var i = 0; i < this.teacherEvaluateList.length; i++) {
          this.teacherEvaluateList[i].evaluationImage = this
            .teacherEvaluateList[i].evaluationImage
            ? JSON.parse(this.teacherEvaluateList[i].evaluationImage)
            : "";
          this.teacherEvaluateList[i].evaluationOther = this
            .teacherEvaluateList[i].evaluationOther
            ? eval("(" + this.teacherEvaluateList[i].evaluationOther + ")")
            : "";
          this.teacherEvaluateList[i].evaluationTeaching = this
            .teacherEvaluateList[i].evaluationTeaching
            ? eval("(" + this.teacherEvaluateList[i].evaluationTeaching + ")")
            : "";
          this.teacherEvaluateList[i].evaluationTeachingStyle = this
            .teacherEvaluateList[i].evaluationTeachingStyle
            ? eval(
                "(" + this.teacherEvaluateList[i].evaluationTeachingStyle + ")"
              )
            : "";
        }
      });
    },
    getListEvaluatedCountFn() {
      //统计
      getListEvaluatedCount({ teacherId: this.tInfoRow.id }).then((res) => {
        if (res.code == 0) {
          this.labelData = res.rows;
        }
      });
    },
    teacherInfoFn() {
      teacherInfo(this.tInfoRow.id).then((res) => {
        if (res.code == 0 && typeof res.rows == 'object') {
          this.teacherDetail = res.rows;
          this.teacherDetail.imageArray = new Array();
          this.teacherDetail.imageArray.push(res.rows.photo);
          this.teacherDetailPhoto.push(res.rows.photo);
          var CountysName = "";
          if (res.rows.currentCountysName.length) {
            for (let i = 0; i < res.rows.currentCountysName.length; i++) {
              CountysName += res.rows.currentCountysName[i].NAME + "，";
            }
          }
          this.getCurrentCountysName = CountysName.substring(
            0,
            CountysName.length - 1
          );
          this.caseList = res.rows.xmtTeacherSuccessCaseEntityList;
          for (var i = 0; i < res.rows.exhibitionPhotoList.length; i++) {
            this.daikeList.push({ url: res.rows.exhibitionPhotoList[i] });
          }
          for (var i = 0; i < this.daikeList.length; i++) {
            this.daikeLists.push(this.daikeList[i].url);
          }
        }
      });
      //获取录入员审核员评价
      var data1 = {
        //录入员
        peopleType: 1,
        teacherId: this.tInfoRow.id,
      };
      var data2 = {
        //审核员
        peopleType: 2,
        teacherId: this.tInfoRow.id,
      };
      teacherFaceMarkDetail(data1).then((res) => {
        if (res.code == 0) {
          this.teacherFaceMark1 = res.rows;
        }
      });
      teacherFaceMarkDetail(data2).then((res) => {
        if (res.code == 0) {
          this.teacherFaceMark2 = res.rows;
        }
      });
    },
    teacherFindAuthFileFn() {
      teacherFindAuthFile(this.tInfoRow.id).then((res) => {
        var getData = res.rows.authFiles;
        if (res.code == 0) {
          for (var i = 0; i < getData.length; i++) {
            if (getData[i].fileType == 6) {
              //视频
              this.videoForm.videoUrl = getData[i].fileUrl;
            }
            if (getData[i].fileType == 5) {
              this.videoformArray.push(getData[i]);
              this.urlObj.url = getData[i].fileUrl;
              this.urlArr.push(getData[i].fileUrl);
              this.fileList.push(this.urlObj);
              this.getResult = getData[i].title;
              this.urlObj = {};
            }
          }
          for (var i = 0; i < this.fileList.length; i++) {
            this.fileLists.push(this.fileList[i].url);
          }
        }
      });
      getByTeacherId(this.tInfoRow.id).then((res) => {
        if (res.code == 0) {
          this.answerList = res.rows;
        }
      });
    },
    //教务关注人数
    countConcernFn() {
      countConcern({ teacherId: this.tInfoRow.id }).then((res) => {
        if (res.code == 0) {
          this.personNumber = res.msg;
        }
      });
    },
    //是否常用讲师
    selectOftenUsedList() {
      selectOftenUsed({ teacherId: this.tInfoRow.id }).then((res) => {
        if (res.code == 0) {
          res.rows ? (this.commonStatus = true) : (this.commonStatus = false);
        }
      });
    },
    //设置常用、取消常用
    dealFollow() {
      var data = {
        teacherId: this.tInfoRow.id,
        oftenUsedType: this.commonStatus ? 0 : 1,
      };
      setOftenTeacher(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          // this.teacherInfo();
          // this.selectOftenUsedList();
          // this.listTeacherManager();
        }
      });
    },
    tabClick(tab, event) {
      if (tab.index == 0) {
        this.teacherInfoFn();
        this.selectOftenUsedList();
        this.countConcernFn();
        this.teacherFindAuthFileFn();
      } else if (tab.index == 1) {
        this.teacherEvaluatedContentFn();
      } else if (tab.index == 2) {
        this.teacherCountFn();
      }
    },
    teacherEvaluatedContentFn() {
      var data = {
        pageNumber: 1,
        pageSize: 1000,
        teacherId: this.tInfoRow.id,
        evaluationSource: 1,
      };
      teacherEvaluatedContent(data).then((res) => {
        for (var i = 0; i < res.page.rows.length; i++) {
          res.page.rows[i].evaluationTags =
            res.page.rows[i].evaluationTag.split(",");
        }
        this.studentEvaluateList = res.page.rows;
        this.goodMapData = res.goodMap;
        this.badMapData = res.badMap;
      });
    },
    identityRowFn(row) {
      for (var i = 0; i < this.identityTypeData.length; i++) {
        if (this.identityTypeData[i].value == Number(row)) {
          return this.identityTypeData[i].label;
        }
      }
    },
    jobHuntingAttitude(row) {
      var str = "";
      if (row.jobHuntingAttitude == 1) {
        str = "负分";
      } else if (row.jobHuntingAttitude == 2) {
        str = "正常";
      } else if (row.jobHuntingAttitude == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    lectureSpeed(row) {
      var str = "";
      if (row.substituteLessonsBeijing == 1) {
        str = "5年以下";
      } else if (row.substituteLessonsBeijing == 2) {
        str = "5-10年";
      } else if (row.substituteLessonsBeijing == 3) {
        str = "10年以上";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    appearance(row) {
      var str = "";
      if (row.imageTemperament == 1) {
        str = "负分";
      } else if (row.imageTemperament == 2) {
        str = "正常";
      } else if (row.imageTemperament == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    lectureStyle(row) {
      var str = "";
      if (row.teachingExperience == 1) {
        str = "5年以下";
      } else if (row.teachingExperience == 2) {
        str = "5-10年";
      } else if (row.teachingExperience == 3) {
        str = "10-15年";
      } else if (row.teachingExperience == 4) {
        str = "15-20以上";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    teachingCharacteristics(row) {
      var str = "";
      if (row.teachingCharacteristics == 1) {
        str = "负分";
      } else if (row.teachingCharacteristics == 2) {
        str = "正常";
      } else if (row.teachingCharacteristics == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    overallScoreFn(row) {
      var str = "";
      if (row.overallScore == 1) {
        str = "A+";
      } else if (row.overallScore == 2) {
        str = "A";
      } else if (row.overallScore == 3) {
        str = "B+";
      } else if (row.overallScore == 4) {
        str = "B";
      } else if (row.overallScore == 5) {
        str = "C";
      } else if (row.overallScore == 6) {
        str = "D";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    connectCapacity(row) {
      var str = "";
      if (row.communicationExpression == 1) {
        str = "负分";
      } else if (row.communicationExpression == 2) {
        str = "正常";
      } else if (row.communicationExpression == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    skilfulFn(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.teachStyleArray.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.teachStyleArray[i].macroId == arr[k]) {
            str += this.teachStyleArray[i].name.split("：")[0] + "，";
          }
        }
      }
      return str ? str.substring(str.length - 1, 0) : "";
    },
    judgeStyleNeedFn(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.styleNeedArray[i].macroId == arr[k]) {
            str += this.styleNeedArray[i].name.split(":")[0] + "，";
          }
        }
      }
      console.log(str, "str...");
      return str ? str.substring(str.length - 1, 0) : "";
    },
    getSectionId(row) {
      let result = [];
      let list = this.sectionList;
      var subjectArray = row ? row.split(",") : [];
      for (let i = 0; i < list.length; i++) {
        let tempArr1 = list[i];
        for (let j = 0; j < subjectArray.length; j++) {
          let tempArr2 = subjectArray[j];
          if (tempArr2 == tempArr1.macroId) {
            result.push(tempArr1.name);
            break;
          }
        }
      }
      return result.join();
    },
    studentAchievementFn(num) {
      if (num == 268) {
        return "提高型";
      } else if (num == 269) {
        return "中上等成绩";
      } else if (num == 270) {
        return "中等类待提高";
      } else if (num == 271) {
        return "基础型";
      } else {
        return "";
      }
    },
    getSubjectId(row) {
      let result = [];
      let list = this.subjectIdList;
      var subjectArray = row ? row.split(",") : [];
      for (let i = 0; i < list.length; i++) {
        let tempArr1 = list[i];
        for (let j = 0; j < subjectArray.length; j++) {
          let tempArr2 = subjectArray[j];
          if (tempArr2 == tempArr1.macroId) {
            result.push(tempArr1.name);
            break;
          }
        }
      }
      return result.join();
    },
    studentSectionIdFn(row) {
      let result = [];
      var subjectArray = String(row).split();
      for (let i = 0; i < this.sectionList.length; i++) {
        let tempArr1 = this.sectionList[i];
        for (let j = 0; j < subjectArray.length; j++) {
          let tempArr2 = subjectArray[j];
          if (tempArr2 == tempArr1.macroId) {
            result.push(tempArr1.name);
            break;
          }
        }
      }
      return result.join();
    },
    classFormFn(type) {
      if (type == "1") {
        return "线上";
      } else if (type == "2") {
        return "线下";
      } else if (type == "0") {
        return "均可";
      }
    },
    teachingAgeFn(row) {
      var teachingAgestr = "";
      if (row.teachingAgeId == 27) {
        teachingAgestr = "5年以下";
      } else if (row.teachingAgeId == 28) {
        teachingAgestr = "5-10";
      } else if (row.teachingAgeId == 29) {
        teachingAgestr = "10-15";
      } else if (row.teachingAgeId == 227) {
        teachingAgestr = "15-20以上";
      }
      return teachingAgestr;
    },
    teachTitleFn(row) {
      var teachTitlestr = "";
      if (row.teachTitleId == 59) {
        teachTitlestr = "初级教师";
      } else if (row.teachTitleId == 60) {
        teachTitlestr = "中级教师";
      } else if (row.teachTitleId == 61) {
        teachTitlestr = "高级教师";
      } else if (row.teachTitleId == 62) {
        teachTitlestr = "特级教师";
      }
      return teachTitlestr;
    },
    educationFn(row) {
      var educationstr = "";
      if (row == 70) {
        educationstr = "专科";
      } else if (row == 69) {
        educationstr = "本科";
      } else if (row == 68) {
        educationstr = "硕士";
      } else if (row == 67) {
        educationstr = "博士";
      } else {
        educationstr = "暂无数据";
      }
      return educationstr;
    },
    stageFn(row) {
      for (var i = 0; i < this.stageList.length; i++) {
        if (this.stageList[i].macroId == row) {
          return this.stageList[i].name;
        }
      }
    },
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
        }
      });
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
        }
      });
      getSelectList({
        //风格偏好
        parentCode: achievement,
      }).then((res) => {
        if (res.rows) {
          this.styleNeedArray = res.rows;
        }
      });
      getSelectList({
        //讲课风格
        parentCode: teachStyle,
      }).then((res) => {
        if (res.rows) {
          this.teachStyleArray = res.rows;
        }
      });
      parentCodes({
        parentCode: readingStage,
      }).then((res) => {
        if (res.code == 0) {
          this.stageList = res.rows;
        }
      });
    },
    getIdentityType(row) {
      if (row == 1) {
        return "学科类--老师";
      } else if (row == 2) {
        return "学科类--大学生";
      } else if (row == 3) {
        return "素质教育类--老师";
      } else if (row == 4) {
        return "素质教育类--大学生";
      } else {
        return "";
      }
    },
    ageFn(birthday) {
      if (birthday) {
        var birthday_time = birthday.split("-");
        var birthYear = birthday_time[0];
        var d = new Date();
        var nowYear = d.getFullYear();
        var age = nowYear - birthYear;
        return age + "岁";
      } else {
        return "";
      }
    },
    closeDialog() {
      this.$emit("CB_tInfoStatus");
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
.subjectActive {
  overflow: hidden;
}
.teachContent {
  display: flex;
  flex-direction: column;
}
.infoDetails {
  height: 150px;
  padding-top: 20px;
  display: flex;
}
.infoDetails .left {
  display: flex;
  width: 35%;
  min-width: 500px;
}
.infoDetails .content {
  width: 350px;
}
.infoDetails .content ul {
  margin-top: 30px;
}
.infoDetails .content ul li {
  margin-top: 5px;
}
.infoDetails .content ul span:nth-child(1) {
  font-size: 15px;
  color: #414a60;
}
.infoDetails .content ul span:nth-child(2) {
  font-size: 15px;
  color: #61687c;
}
.infoDetails .right {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.class_title {
  height: 30px;
  line-height: 30px;
  text-indent: 12px;
}
.table_area {
  margin-left: 20px;
  padding: 10px 4px;
  .class_tip {
    height: 40px;
    line-height: 40px;
    text-indent: 4px;
    span {
      margin-right: 20px;
    }
  }
}
.self_table {
  width: 100%;
  border: 1px solid #ebeef5;
  border-collapse: collapse;
  border-spacing: 0px;
  tr {
    border: 1px solid #ebeef5;
    td {
      border: 1px solid #ebeef5;
      text-align: center;
      vertical-align: middle;
      height: 40px;
    }
  }
}
.navContent {
  display: flex;
  justify-content: space-between;
  dl {
    display: flex;
    align-items: center;
    width: 80%;
    dt {
      width: 109px;
      height: 109px;
      border-radius: 50%;
      overflow: hidden;
      .teacherPhoto {
        width: 100%;
        height: 100%;
      }
    }
    dd {
      margin-left: 32px;
      p:nth-child(1) {
        color: #333;
        font-size: 14px;
        span {
          margin-right: 13px;
        }
      }
      p:nth-child(2) {
        margin-top: 18px;
        color: #606266;
        span {
          padding: 0 7px;
          border-right: 1px solid #dcdfe6;
        }
        span:nth-child(1) {
          padding-left: 0;
        }
        .endSpan {
          border: 0;
        }
      }
      p:nth-child(3), p:nth-child(4) {
        margin-top: 18px;
        color: #606266;
      }
    }
  }
  div {
    flex: 1;
    text-align: right;
    p {
      color: #909399;
    }
    .btn {
      margin-top: 19px;
    }
  }
}
.titleP {
  margin-top: 60px;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}
.navInfo {
  li {
    color: #333333;
    margin-top: 30px;
    display: flex;
    span:nth-child(1) {
      font-weight: bold;
      width: 11%;
    }
    span:nth-child(2) {
      flex: 1;
      // margin-left:12px;
      // line-height: 1.5;
    }
  }
}
.caseDiv {
  margin-top: 23px;
}
.caseDiv div {
  color: #333;
  p:nth-child(1) {
    span {
      margin-right: 20px;
    }
  }
}
.video_area {
  width: 200px;
  height: 140px;
  margin-top: 10px;
  video {
    width: 200px;
    height: 140px;
    object-fit: fill;
  }
  div {
    width: 200px;
    height: 140px;
    border: 1px solid #dcdfe6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.answer_ul {
  margin-top: 7px;
  padding-bottom: 50px;
}
.answer_ul li {
  margin-top: 31px;
}
.answer_ul p {
  font-size: 14px;
  color: #606266;
  font-weight: 400;
}
.twoP {
  margin-top: 7px;
  line-height: 1.5;
}
.evaluateDiv {
  margin-top: 15px;
  p {
    color: #333333;
    font-weight: bold;
  }
}
.plP {
  margin: 56px 10px 25px 10px;
  background: #f5f7fa;
  height: 46px;
  line-height: 46px;
  padding-left: 16px;
  color: #333333;
  font-size: 16px;
}
.studentContent,
.teachContent {
  margin-top: 50px;
  dl {
    display: flex;
    padding: 26px 0;
    border-bottom: 1px solid #dcdfe6;
    dt {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    dd {
      margin-left: 20px;
      flex: 1;
      .contT {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p:nth-child(1) {
          display: flex;
          align-items: center;
          span {
            color: #909399;
          }
          img {
            width: 34px;
            height: 16px;
            margin-left: 7px;
          }
        }
      }
      .contTag {
        margin-top: 15px;
      }
      > p {
        color: #333333;
        margin-top: 20px;
        line-height: 24px;
      }
    }
  }
}
.myEvaluateDiv {
  margin-top: 20px;
  ul {
    li {
      margin: 6px 0;
      line-height: 1.5;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    .btn {
      margin: 4px 0;
    }
  }
}
.tP {
  width: 80%;
  display: flex;
  span {
    flex: 1;
    color: #909399;
  }
}
.countTab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  line-height: 35px;
  margin-top: 30px;
  span {
    color: #333333;
    // margin:0 20px;
    cursor: pointer;
    margin-right: 25px;
  }
  span:hover {
    color: #1890ff;
  }
}
.countActive {
  font-weight: bold;
  color: #1890ff !important;
  border-bottom: 2px solid #1890ff;
  // padding-bottom:8px;
}
</style>