<template>
  <div>
      <el-dialog
        title="跟进记录"
        center
        width="800px"
        :close-on-click-modal="false"
        :visible="followDialogSon"
        @close="closeDialog"
    >
        <div class="save-dialog-content">
        <div class="table-area">
           <el-table
            size="small"
            :data="seeFollowData"
            style="width: 100%; margin-bottom: 20px；margin-top:20px;flex:1;overflow: hidden;"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              type="index"
              prop="studentName"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column prop="" label="跟进记录" align="center">
              <template slot-scope="scope">
               <p>{{scope.row.followContent}}</p>
               <el-image
                class="td-img"
                v-for="(item,index) in scope.row.photoList" :key="index"
                style="width: 100px; height: 100px;margin-left:5px;"
                :src="item.url"
                :preview-src-list="scope.row.imageArray"
              >
              </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="saleName" label="跟进详情" width="200">
              <template slot-scope="scope">
                <p>跟进人：{{ scope.row.teaName }}</p>
                <p>跟进时间：{{ scope.row.createTime }}</p>
                <p>下次跟进时间：{{ scope.row.nextFollowupTime }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
       <div class="page-area">
          <el-pagination
            @size-change="followSizeChange"
            @current-change="followCurrentChange"
            :current-page="followUpContent.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="followUpContent.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="followUpTotal"
            background
          ></el-pagination>
        </div> 
      </div>
      </el-dialog>
  </div>
</template>
<script>
import {teacherFollowupRecordList} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    props:["followDialog",'followId'],
    data(){
        return{
            followDialogSon:false,
            followUpTotal:0,
            seeFollowData:[],
            followUpContent:{
                studentId:"",
                pageNumber: 1,
                pageSize: 10,
            }
        }
    },
    watch:{
      followDialog(newValue,oldValue){
        this.followDialogSon=newValue;
        if(newValue==true){
            this.followUpContent={
                studentId:"",
                pageNumber: 1,
                pageSize: 10,
            }
          this.teacherFollowupRecordListFn();
        }
      },
    },
    mounted(){

    },
    methods:{
        closeDialog(){
          this.$emit('change_followStatus')
        },
        teacherFollowupRecordListFn() {
            this.followUpContent.studentId=this.followId
            teacherFollowupRecordList(this.followUpContent).then((res) => {
                for (var i = 0; i < res.rows.length; i++) {
                res.rows[i].imageArray = new Array();
                for (var k = 0; k < res.rows[i].photoList.length; k++) {
                    res.rows[i].imageArray.push(res.rows[i].photoList[k].url);
                }
                }
                this.seeFollowData = res.rows;
                for (var i = 0; i < this.seeFollowData.length; i++) {
                if (this.seeFollowData.length) {
                    this.seeFollowData[i].followContent = this.seeFollowData[
                    i
                    ].followContent.replace(
                    /<img/g,
                    "<img style='max-width:200px;height:auto;'"
                    );
                }
                }
                this.followUpTotal = res.total;
            });
        },
        followSizeChange(pageSize) {
            this.followUpContent.pageSize = pageSize;
            this.teacherFollowupRecordListFn();
        },
        followCurrentChange(currentPage) {
            this.followUpContent.pageNumber = currentPage;
            this.teacherFollowupRecordListFn();
        },
    }
}
</script>
<style lang="scss" scoped>
       .page-area {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
</style>
