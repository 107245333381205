<template>
  <el-dialog
    :title="downParams.type == 'edit' ? '修改订单' : '新增订单'"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    width="650px"
    center
    @close="closeDialog"
  >
    <div class="dialog-content">
      <el-form
        :model="saveRuleFormOrder"
        status-icon
        :rules="saveRuleOrder"
        ref="saveRuleFormOrder"
        label-width="90px"
        size="small"
      >
        <el-form-item label="产品类型" prop="orderType">
          <el-row>
            <el-col :span="7">
              <el-select
                v-model="saveRuleFormOrder.orderType"
                placeholder="请选择"
                @change="handleOrderTypeChange"
                size="small"
                :disabled="
                  orderDialog.type == 'see' || orderDialog.type == 'edit'
                "
              >
                <el-option value="5" label="一对一充值"></el-option>
                <el-option value="2" label="一对多"></el-option>
                <el-option value="3" label="试听课"></el-option>
                <el-option value="4" label="公开课"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-form-item
            label="课程类型"
            prop="courseTypeId"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.courseTypeId"
              :disabled="orderDialog.type == 'see'"
              @change="orderClick"
            >
              <el-radio label="1">大班课</el-radio>
              <el-radio label="2">小班课</el-radio>
              <el-radio label="3">答疑课</el-radio>
            </el-radio-group>
          </el-form-item> -->
        <el-form-item
          label="选择产品"
          prop="productId"
          v-show="saveRuleFormOrder.orderType != 5"
          :disabled="orderDialog.type == 'see'"
        >
          <el-row>
            <el-col :span="7">
              <el-select
                v-model="saveRuleFormOrder.productId"
                placeholder="请选择"
                @change="productChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="产品规格"
          v-show="saveRuleFormOrder.orderType != 5"
        >
          <el-row type="flex" v-show="saveRuleFormOrder.orderType == 3">
            <el-col :span="5">
              <el-select
                v-model="saveRuleFormOrder.sectionId"
                placeholder="暂未设置学段"
                @change="orderSectionChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.sectionList"
                  :key="item.sectionId"
                  :label="item.sectionName"
                  :value="item.sectionId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="5" class="mL20">
              <el-select
                v-model="saveRuleFormOrder.teacherTitleId"
                placeholder="暂未设置讲师头衔"
                @change="orderTeacherTitleChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.teacherTitleList"
                  :key="item.teacherTitleId"
                  :label="item.teacherTitleName"
                  :value="item.teacherTitleId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6" class="mL20">
              <el-select
                @change="orderClassHourChange"
                v-model="saveRuleFormOrder.classHourId"
                placeholder="暂未设置课时"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.classHourList"
                  :key="item.id"
                  :label="item.classHourName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            v-show="
              saveRuleFormOrder.orderType == 2 ||
              saveRuleFormOrder.orderType == 4
            "
          >
            <el-col :span="12">
              <el-select
                v-model="saveRuleFormOrder.periodId"
                placeholder="暂未设置学段"
                @change="orderSectionChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.periodList"
                  :key="item.periodId"
                  :label="item.periodName"
                  :value="item.periodId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="12" class="mL20">
              <el-select
                v-model="saveRuleFormOrder.subjectId"
                placeholder="暂未设置学科"
                @change="orderSubjectChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.subjectList"
                  :key="item.subjectId"
                  :label="item.subjectName"
                  :value="item.subjectId"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="mT10"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-col :span="12">
              <el-select
                v-model="saveRuleFormOrder.teacherTitleId"
                placeholder="暂未设置讲师头衔"
                @change="orderTeacherTitleChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.teacherTitleList"
                  :key="item.teacherTitleId"
                  :label="item.teacherTitleName"
                  :value="item.teacherTitleId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="12" class="mL20">
              <el-select
                v-model="saveRuleFormOrder.classHourId"
                placeholder="暂未设置课时"
                @change="orderClassHourChange"
                :disabled="orderDialog.type == 'see'"
              >
                <el-option
                  v-for="item in productDetail.classHourList"
                  :key="item.id"
                  :label="item.classHourName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="mT10"
            v-show="
              saveRuleFormOrder.classHourId != -1 &&
              saveRuleFormOrder.orderType != 2 &&
              saveRuleFormOrder.orderType != 4
            "
          >
            <el-col :span="12">
              <div class="course_info">
                <span>课时单价：</span>
                <span>{{ saveRuleFormOrder.singlePrice }}元</span>
              </div>
            </el-col>
            <el-col :span="12" class="mL20">
              <div class="course_info">
                <span>总价：</span>
                <span
                  >{{
                    saveRuleFormOrder.orderType != 2
                      ? saveRuleFormOrder.totalPrice
                      : saveRuleFormOrder.zdyActualPrice
                  }}元</span
                >
              </div>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="mT20"
            v-show="saveRuleFormOrder.classHourId == -1"
          >
            <el-col :span="8">
              <el-form-item label="课时数：" prop="zdyClassHour">
                <input
                  type="text"
                  :disabled="orderDialog.type == 'see'"
                  v-model="saveRuleFormOrder.zdyClassHour"
                  class="self_input"
                  @keyup="filterZdyClassHour"
                  onpaste="return false"
                  @blur="zdyClassHourBlur()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="单价：" prop="zdySinglePrice">
                <input
                  type="text"
                  :disabled="orderDialog.type == 'see'"
                  v-model="saveRuleFormOrder.zdySinglePrice"
                  class="self_input"
                  onpaste="return false"
                  @keyup="fileterZdySinglePrice"
                  @blur="zdySinglePriceBlur()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总金额：" prop="zdyTotalPrice">
                <input
                  :disabled="orderDialog.type == 'see'"
                  type="text"
                  v-model="saveRuleFormOrder.zdyTotalPrice"
                  onpaste="return false"
                  @keyup="filterZdyTotalPrice"
                  @blur="zdyTotalPriceBlur"
                  class="self_input"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-show="saveRuleFormOrder.classHourId == -1">
            <el-col :span="8">
              <el-form-item label="赠送课时：" prop="zdyGiveClassHour">
                <input
                  type="text"
                  :disabled="orderDialog.type == 'see'"
                  onpaste="return false"
                  @keyup="
                    saveRuleFormOrder.zdyGiveClassHour =
                      saveRuleFormOrder.zdyGiveClassHour.replace(/[^\d]/g, '')
                  "
                  v-model="saveRuleFormOrder.zdyGiveClassHour"
                  class="self_input"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="优惠金额：" prop="zdyDiscountAmount">
                <input
                  :disabled="orderDialog.type == 'see'"
                  type="text"
                  v-model="saveRuleFormOrder.zdyDiscountAmount"
                  class="self_input"
                  onpaste="return false"
                  @keyup="filterZdyDiscountAmount"
                  @blur="zdyDiscountAmountBlur()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="实际支付：" prop="zdyActualPrice">
                <input
                  :disabled="orderDialog.type == 'see'"
                  type="text"
                  onpaste="return false"
                  @keyup="filterZdyActualPrice"
                  @blur="zdyActualPriceBlur"
                  v-model="saveRuleFormOrder.zdyActualPrice"
                  class="self_input"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row
          type="flex"
          class="mT10"
          v-show="
            saveRuleFormOrder.classHourId != -1 &&
            (saveRuleFormOrder.orderType == 2 ||
              saveRuleFormOrder.orderType == 4)
          "
        >
          <el-col :span="6" class="mL20">
            <div class="course_info" style="line-height: 40px">
              <span>总价：</span>
              <span
                >{{
                  saveRuleFormOrder.orderType == 2 ||
                  saveRuleFormOrder.orderType == 4
                    ? saveRuleFormOrder.zdyTotalPrice
                    : ""
                }}元</span
              >
            </div>
          </el-col>
          <el-col :span="10" class="mL20">
            <div class="course_info">
              <span>优惠金额：</span>
              <span
                ><el-input
                  type="text"
                  @blur="morenAmount()"
                  v-model="saveRuleFormOrder.zdyDiscountAmount"
                  style="width: 100px !important"
                ></el-input
              ></span>
            </div>
          </el-col>
          <el-col :span="10" class="mL20">
            <div class="course_info">
              <span>实际支付：</span>
              <span
                ><el-input
                  type="text"
                  @blur="moreActualPrice()"
                  v-model="saveRuleFormOrder.zdyActualPrice"
                  style="width: 100px !important"
                ></el-input
              ></span>
            </div>
          </el-col>
        </el-row>
        <el-form-item
          label="缴费金额"
          prop="totalPrice"
          v-show="saveRuleFormOrder.orderType == 5"
        >
          <el-input
            style="width: 140px"
            :maxlength="8"
            @blur="totalPriceBlur()"
            v-model="saveRuleFormOrder.totalPrice"
            placeholder="请输入缴费金额"
          ></el-input>
          <span style="color: #909399; font-size: 12px; margin-left: 10px">{{
            translateTotal
          }}</span>
        </el-form-item>
        <el-form-item
          label="赠送课时"
          prop="zdyGiveClassHour"
          v-show="
            saveRuleFormOrder.orderType == 5 && saveRuleFormOrder.moneyType != 4
          "
        >
          <el-input
            style="width: 140px"
            min="0"
            type="number"
            v-model="saveRuleFormOrder.zdyGiveClassHour"
            placeholder="请输入赠送课时"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="优惠金额" prop="payType" v-show="saveRuleFormOrder.orderType==2||saveRuleFormOrder.orderType==4">
            <el-input style="width:140px;" @blur="morenAmount()" v-model="saveRuleFormOrder.zdyDiscountAmount"></el-input>
          </el-form-item> -->
        <el-form-item label="支付方式" prop="payType">
          <el-radio-group
            v-model="saveRuleFormOrder.payType"
            :disabled="orderDialog.type == 'see'"
          >
            <el-radio label="2">线下支付</el-radio>
            <el-radio label="3">余额支付</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="收款方式"
          prop="payMent"
          v-show="
            (saveRuleFormOrder.orderType == 5 ||
              saveRuleFormOrder.orderType == 2 ||
              saveRuleFormOrder.orderType == 3) &&
            saveRuleFormOrder.payType == 2
          "
        >
          <el-radio-group
            v-model="saveRuleFormOrder.payMent"
            :disabled="orderDialog.type == 'see'"
          >
            <el-radio label="1">支付宝</el-radio>
            <el-radio label="2">微信</el-radio>
            <el-radio label="3">对公账户</el-radio>
            <el-radio label="4">POS机</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="收款类型"
          prop="moneyType"
          v-show="saveRuleFormOrder.orderType != 3"
        >
          <el-radio-group
            v-model="saveRuleFormOrder.moneyType"
            :disabled="orderDialog.type == 'see'"
          >
            <el-radio label="1">全款</el-radio>
            <el-radio label="2">定金</el-radio>
            <el-radio label="3">尾款</el-radio>
            <el-radio label="4">特殊订单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="付款凭证"
          prop="certificate"
          v-show="saveRuleFormOrder.payType == 2"
        >
          <el-row align="middle" type="flex">
            <el-col :span="12">
              <div class="certificate_box">
                <el-image
                  class="td-img"
                  style="width: 100px; height: 100px"
                  :src="saveRuleFormOrder.certificate"
                  v-show="saveRuleFormOrder.certificate"
                  :preview-src-list="srcList"
                >
                </el-image>
              </div>
              <div class="certificate_tip">
                <span v-show="saveRuleFormOrder.certificate"
                  >点击图片查看大图</span
                >
                <span v-show="!saveRuleFormOrder.certificate"
                  >暂未上传凭证</span
                >
              </div>
            </el-col>
            <el-col :span="12" class="mL20">
              <el-upload
                accept="image/jpeg, image/png/, image/gif"
                :action="fileUpload"
                :on-success="certificateSuccess"
                :on-error="certificateError"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :disabled="orderDialog.type == 'see'"
              >
                <el-button
                  size="small"
                  type="primary"
                  :disabled="orderDialog.type == 'see'"
                  >点击上传</el-button
                >
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="辅导协议" prop="coachAgreement">
          <el-row align="middle" type="flex">
            <el-col :span="12">
              <div class="coachAgreement_tip">
                <a
                  target="view_window"
                  :href="saveRuleFormOrder.coachAgreement"
                  >{{ saveRuleFormOrder.coachAgreementName }}</a
                >
                <span v-show="!saveRuleFormOrder.coachAgreement"
                  >还未上传辅导协议</span
                >
              </div>
            </el-col>
            <el-col :span="12" class="mL20">
              <el-upload
                accept="image/jpeg, image/png/, image/gif"
                :action="fileUpload"
                :on-success="coachAgreementSuccess"
                :on-error="coachAgreementError"
                :show-file-list="false"
                :disabled="orderDialog.type == 'see'"
              >
                <el-button
                  size="small"
                  type="primary"
                  :disabled="orderDialog.type == 'see'"
                  >点击上传</el-button
                >
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-row align="middle" type="flex">
            <el-col :span="17">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="saveRuleFormOrder.remark"
              >
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
      v-show="orderDialog.type != 'see'"
    >
      <el-button @click="closeDialog" size="small">取 消</el-button>
      <el-button
        type="primary"
        @click="confirmSaveOrder('saveRuleFormOrder')"
        size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";

import {
  productListNoPage,
  productInfoDetail,
} from "@/api/operates/productManage";
import {
  studentProcessList as studentList,
  getAvliableNum,
  saleApply,
  checkOneToOneOrder,
  addOrder,
  followUpList,
  orderList,
  orderDetail,
  orderRemove,
  studentClassRecord,
} from "@/api/sales/myCustomer";
export default {
  props: ["downOrderStatus", "downParams"],
  watch: {
    downOrderStatus(newValue, oldValue) {
      this.showDialog = newValue;
      this.saveRuleFormOrder = {
        orderType: "5", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        coachAgreementName: "",
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "",
        zdySinglePrice: "",
        zdyGiveClassHour: 0,
        zdyDiscountAmount: "",
        zdyActualPrice: "",
        studentId: this.downParams.id,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1", //1全款   2定金  3尾款
      };
      if (this.$refs["saveRuleFormOrder"] !== undefined) {
        this.$refs["saveRuleFormOrder"].resetFields();
      }
      this.orderDialog.type = this.downParams.type;
      if (newValue == true) {
        if (this.downParams.type == "edit") {
          this.getOrderDetail(this.downParams.classId);
        }
        if (this.downParams.type != "edit") {
          this.orderClick();
        }
      }
    },
  },
  data() {
    let check_zdyClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时数不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdySinglePrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时单价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("自定义课时单价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyGiveClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("赠送课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyDiscountAmount = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("优惠金额应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyActualPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("课时总价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("课时总价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_certificate = (rule, value, callback) => {
      if (this.saveRuleFormOrder.payType == 2) {
        if (!value) {
          callback(new Error("线下支付时付款凭证不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_productId = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType != 5) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_totalPrice = (rule, value, callback) => {
      if (
        this.saveRuleFormOrder.orderType == 5 &&
        this.saveRuleFormOrder.moneyType != 4
      ) {
        var reg = /^\d+$|^\d+[.]?\d+$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确格式金额！"));
        } else {
          callback();
        }
        // if(!value||value==0){
        //     callback(new Error('缴费金额不能为空！'))
        // }else if(!reg.test(value)||(value.indexOf(".") != -1 && value.split('.').length > 2)){
        //     callback(new Error('请输入正确格式的金额')) //防止输入多个小数点
        // }else if(value.indexOf(".") != -1 && value.split('.')[1].length > 2){
        //   callback(new Error('请输入正确的小数位数')) //小数点后两位
        // }else{
        //   callback();
        // }
      } else if (
        this.saveRuleFormOrder.orderType == 5 &&
        this.saveRuleFormOrder.moneyType == 4
      ) {
        //  var regF=/^(^-?\d+$)$/
        // if(!value||value==0){
        //       callback(new Error('缴费金额不能为空！'))
        //   }else if(!reg.test(value)||(value.indexOf(".") != -1 && value.split('.').length > 2)){
        //       callback(new Error('请输入正确格式的金额')) //防止输入多个小数点
        //   }else if(value.indexOf(".") != -1 && value.split('.')[1].length > 2){
        //     callback(new Error('请输入正确的小数位数')) //小数点后两位
        //   }else{
        //     callback();
        //   }
        //特殊订单
        var regF = /^(^-?\d+$)$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        } else if (!regF.test(value)) {
          callback(new Error("请输入正确格式金额！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      orderDialog: {
        type: "",
      },
      curEditOrder: "",
      fileUpload,
      showDialog: false,
      productList: [],
      translateTotal: "",
      srcList: [],
      saveRuleFormOrder: {
        orderType: "5", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        coachAgreementName: "",
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "", // 自定义课时数
        zdySinglePrice: "", // 自定义单价
        zdyGiveClassHour: 0, // 自定义赠送课时
        zdyDiscountAmount: "", // 自定义优惠金额
        zdyActualPrice: "", // 自定义实际支付
        zdyTotalPrice: "", //  自定义总金额
        studentId: this.downParams.id,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1", //1全款   2定金  3尾款
      },
      productDetail: {
        productPackage: [],
        periodList: [],
        periodIdList: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: [],
      },
      saveRuleOrder: {
        zdyClassHour: [{ validator: check_zdyClassHour, trigger: "blur" }],
        zdySinglePrice: [{ validator: check_zdySinglePrice, trigger: "blur" }],
        zdyGiveClassHour: [
          { validator: check_zdyGiveClassHour, trigger: "blur" },
        ],
        zdyDiscountAmount: [
          { validator: check_zdyDiscountAmount, trigger: "blur" },
        ],
        zdyActualPrice: [{ validator: check_zdyActualPrice, trigger: "blur" }],
        // certificate: [{ validator: check_certificate, trigger: "change" }],
        productId: [{ validator: check_productId, trigger: "change" }],
        totalPrice: [{ validator: check_totalPrice, trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    handleOrderTypeChange() {
      this.saveRuleFormOrder.classHourId = "";
      this.saveRuleFormOrder.zdyClassHour = "";
      this.orderClick();
    },
    async orderClick(isInit) {
      if (this.downParams.type != "edit") {
        this.saveRuleFormOrder.zdyDiscountAmount = "";
        this.saveRuleFormOrder.totalPrice = "";
        // this.saveRuleFormOrder.zdyActualPrice="";
        // this.saveRuleFormOrder.zdyTotalPrice="";
      }
      console.log(this.saveRuleFormOrder.zdyTotalPrice, "123...");
      await this.getProductList();
      this.translateTotal = "";
      if (this.saveRuleFormOrder.orderType != 5) {
        //一对一充值除外
        if (this.productList.length > 0) {
          if (this.downParams.type != "edit") {
            this.saveRuleFormOrder.productId = this.productList[0].id;
          } else {
            for (var i = 0; i < this.productList.length; i++) {
              if (this.productList[i].id == this.downParams.productId) {
                this.saveRuleFormOrder.productId = this.productList[i].id;
              }
            }
          }
          this.getProductDetail(isInit);
        } else {
          this.saveRuleFormOrder.sectionId = "";
          this.saveRuleFormOrder.productId = "";
          this.saveRuleFormOrder.periodId = "";
          this.saveRuleFormOrder.subjectId = "";
          this.saveRuleFormOrder.teacherTitleId = "";
          this.saveRuleFormOrder.classHourId = "";
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    getProductList() {
      if (this.saveRuleFormOrder.orderType == 3) {
        //试听课
        return productListNoPage({
          isSubscribe: 1,
          courseTypeId:
            this.saveRuleFormOrder.orderType == 2
              ? this.saveRuleFormOrder.courseTypeId
              : null,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      } else if (this.saveRuleFormOrder.orderType == 2) {
        //一对多
        return productListNoPage({
          productType: this.saveRuleFormOrder.orderType,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      } else if (this.saveRuleFormOrder.orderType == 4) {
        //一对多
        return productListNoPage({
          productType: this.saveRuleFormOrder.orderType,
        }).then((res) => {
          if (res.code == 0) {
            this.productList = res.rows;
          }
        });
      }
    },
    morenAmount() {
      // alert(1)
      if (
        this.saveRuleFormOrder.zdyTotalPrice &&
        this.saveRuleFormOrder.zdyDiscountAmount
      ) {
        this.saveRuleFormOrder.zdyActualPrice =
          Number(this.saveRuleFormOrder.zdyTotalPrice) -
          Number(this.saveRuleFormOrder.zdyDiscountAmount);
      }
    },
    moreActualPrice() {
      this.saveRuleFormOrder.zdyDiscountAmount =
        Number(this.saveRuleFormOrder.zdyTotalPrice) -
        Number(this.saveRuleFormOrder.zdyActualPrice);
    },
    getProductDetail(isInit) {
      return productInfoDetail(this.saveRuleFormOrder.productId).then((res) => {
        if (res.code == 0) {
          if (res.rows.productType == 4) {
            //公开课
            this.productDetail.productPackage = [
              {
                id: res.rows.id,
                periodId: res.rows.gkkPeriod,
                periodName: res.rows.gkkPeriodName,
                productId: res.rows.id,
                subjectId: res.rows.gkkSubject,
                subjectName: res.rows.gkkSubjectName,
                totalPrice: res.rows.preferentialPrice,
              },
            ];
          } else {
            this.productDetail.productPackage =
              res.rows.xmtProductPackageEntityList;
          }
          if (
            this.saveRuleFormOrder.orderType == 2 ||
            this.saveRuleFormOrder.orderType == 4
          ) {
            this.dealPeriod(isInit);
          } else {
            this.dealSection(isInit);
          }
        }
      });
    },
    dealSection(isInit) {
      this.productDetail.sectionList = [];
      this.productDetail.sectionIdList = [];
      if(!isInit){
        this.saveRuleFormOrder.sectionId = "";
      }
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.sectionIdList.includes(
            this.productDetail.productPackage[i].sectionId
          ) &&
          this.productDetail.productPackage[i].sectionId
        ) {
          this.productDetail.sectionList.push({
            sectionName: this.productDetail.productPackage[i].sectionName,
            sectionId: this.productDetail.productPackage[i].sectionId,
          });
          this.productDetail.sectionIdList.push(
            this.productDetail.productPackage[i].sectionId
          );
        }
      }
      if (this.productDetail.sectionList.length > 0 && !isInit) {
        this.saveRuleFormOrder.sectionId =
          this.productDetail.sectionList[0].sectionId;
      }
      this.dealSubject(isInit);
    },
    dealPeriod(isInit) {
      this.productDetail.periodList = [];
      this.productDetail.periodIdList = [];
      if(!isInit){
        this.saveRuleFormOrder.periodId = "";
      }
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.periodIdList.includes(
            this.productDetail.productPackage[i].periodId
          ) &&
          this.productDetail.productPackage[i].periodId
        ) {
          this.productDetail.periodList.push({
            periodName: this.productDetail.productPackage[i].periodName,
            periodId: this.productDetail.productPackage[i].periodId,
          });
          this.productDetail.periodIdList.push(
            this.productDetail.productPackage[i].periodId
          );
        }
      }
      if (this.productDetail.periodList.length > 0 && !isInit) {
        this.saveRuleFormOrder.periodId =
          this.productDetail.periodList[0].periodId;
      }
      this.dealSubject(isInit);
    },
    dealSubject(isInit) {
      if (
        this.saveRuleFormOrder.orderType == 2 ||
        this.saveRuleFormOrder.orderType == 4
      ) {
        this.productDetail.subjectList = [];
        this.productDetail.subjectIdList = [];
        if(!isInit){
          this.saveRuleFormOrder.subjectId = "";
        }
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            !this.productDetail.subjectIdList.includes(
              this.productDetail.productPackage[i].subjectId
            )
          ) {
            this.productDetail.subjectList.push({
              subjectName: this.productDetail.productPackage[i].subjectName,
              subjectId: this.productDetail.productPackage[i].subjectId,
            });
            this.productDetail.subjectIdList.push(
              this.productDetail.productPackage[i].subjectId
            );
          }
        }
        if (this.productDetail.subjectList.length > 0 && !isInit) {
          this.saveRuleFormOrder.subjectId =
            this.productDetail.subjectList[0].subjectId;
        }
      }
      this.dealTeacherTitle(isInit);
    },
    dealTeacherTitle(isInit) {
      this.productDetail.teacherTitleList = [];
      this.productDetail.teacherTitleIdList = [];
      if(!isInit){
        this.saveRuleFormOrder.teacherTitleId = "";
      }
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        if (this.productDetail.teacherTitleList.length > 0 && !isInit) {
          this.saveRuleFormOrder.teacherTitleId =
            this.productDetail.teacherTitleList[0].teacherTitleId;
        }
      } else if (
        this.saveRuleFormOrder.orderType == 2 ||
        this.saveRuleFormOrder.orderType == 4
      ) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName:
                this.productDetail.productPackage[i].teacherTitleName,
              teacherTitleId:
                this.productDetail.productPackage[i].teacherTitleId,
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        if (this.productDetail.teacherTitleList.length > 0 && !isInit) {
          this.saveRuleFormOrder.teacherTitleId =
            this.productDetail.teacherTitleList[0].teacherTitleId;
        }
      }
      this.dealClassHour(isInit);
    },
    dealClassHour(isInit) {
      this.productDetail.classHourList = [];
      this.productDetail.classHourIdList = [];
      if(!isInit){
        this.saveRuleFormOrder.productPackageId = "";
        this.saveRuleFormOrder.classHour = "";
        this.saveRuleFormOrder.classHourId = "";
        this.saveRuleFormOrder.singlePrice = "";
        this.saveRuleFormOrder.totalPrice = "";
        this.saveRuleFormOrder.zdyClassHour = "";
        this.saveRuleFormOrder.zdySinglePrice = "";
        this.saveRuleFormOrder.zdyGiveClassHour = "";
        this.saveRuleFormOrder.zdyDiscountAmount = "";
        this.saveRuleFormOrder.zdyActualPrice = "";
        this.saveRuleFormOrder.zdyTotalPrice = "";
      }
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
        this.productDetail.classHourList.push({
          classHour: "自定义课时",
          classHourName: "自定义课时",
          id: -1,
        });
      } else if (
        this.saveRuleFormOrder.orderType == 2 ||
        this.saveRuleFormOrder.orderType == 4
      ) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice,
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
      }
      if (this.saveRuleFormOrder.zdyClassHour) {
        this.saveRuleFormOrder.classHourId = -1;
      } else if (this.saveRuleFormOrder.classHour) {
        this.productDetail.classHourList.forEach((item) => {
          if (item.classHour == this.saveRuleFormOrder.classHour) {
            this.saveRuleFormOrder.classHourId = item.id;
          }
        });
      } else {
        this.saveRuleFormOrder.classHourId =
          this.productDetail.classHourList[0].id;
          this.saveRuleFormOrder.productPackageId =
        this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.classHour =
        this.productDetail.classHourList[0].classHour;
        this.saveRuleFormOrder.singlePrice =
        this.productDetail.classHourList[0].singlePrice;
      this.saveRuleFormOrder.totalPrice =
        this.productDetail.classHourList[0].totalPrice;
      }
      if (
        this.saveRuleFormOrder.orderType == 3 &&
        !this.saveRuleFormOrder.zdyClassHour
      ) {
        this.saveRuleFormOrder.zdyClassHour = "";
        this.saveRuleFormOrder.zdySinglePrice = "";
        this.saveRuleFormOrder.zdyGiveClassHour = "";
        this.saveRuleFormOrder.zdyDiscountAmount = "";
        this.saveRuleFormOrder.zdyActualPrice = "";
        this.saveRuleFormOrder.zdyTotalPrice = "";
      } else {
        this.saveRuleFormOrder.zdyTotalPrice =
          this.productDetail.classHourList[0].totalPrice;
        this.saveRuleFormOrder.zdyActualPrice =
          this.productDetail.classHourList[0].totalPrice;
      }

      console.log(
        this.productDetail.classHourList[0].zdyTotalPrice,
        "6767...."
      );
      this.morenAmount();
    },
    productChange() {
      this.saveRuleFormOrder.zdyDiscountAmount = "";
      this.getProductDetail();
    },
    orderSectionChange() {
      this.dealSubject();
    },
    orderTeacherTitleChange() {
      this.dealClassHour();
    },
    orderSubjectChange() {
      this.dealTeacherTitle();
    },
    getOrderDetail(id) {
      orderDetail(id).then((res) => {
        if (res.code == 0) {
          this.curEditOrder = id;
          this.srcList.push(res.rows.certificate);
          this.saveRuleFormOrder = {
            orderType: String(res.rows.orderType), //1一对一  2一对多  3试听课
            payType: String(res.rows.payType), //1线上支付   2线下支付
            courseTypeId: res.rows.courseTypeId
              ? String(res.rows.courseTypeId)
              : null, //1大班课  2小班课  3答疑课
            productId: res.rows.productPid,
            certificate: res.rows.certificate, //付款凭证
            coachAgreement: res.rows.coachAgreement, //辅导协议
            sectionId: res.rows.sectionId, //学段
            periodId: res.rows.sectionId, //学段
            subjectId: res.rows.subjectId,
            teacherTitleId: res.rows.teacherTitleId,
            classHour: res.rows.classHour,
            classHourId: res.rows.classHourId, //不用传到后台
            singlePrice: res.rows.singlePrice,
            totalPrice: res.rows.totalPrice,
            zdyClassHour: res.rows.zdyClassHour,
            zdySinglePrice: res.rows.zdySinglePrice,
            zdyGiveClassHour: res.rows.zdyGiveClassHour,
            zdyDiscountAmount: res.rows.zdyDiscountAmount,
            zdyActualPrice: res.rows.zdyActualPrice,
            zdyTotalPrice: res.rows.zdyTotalPrice,
            studentId: this.downParams.id,
            productPackageId: res.rows.productPackageId,
            remark: res.rows.remark,
            payMent: res.rows.payMent ? String(res.rows.payMent) : null,
            moneyType: res.rows.moneyType ? String(res.rows.moneyType) : null,
            coachAgreementName: res.rows.coachAgreementName,
          };
          if (res.rows.productType == 2 || res.rows.productType == 4) {
            this.saveRuleFormOrder.zdyDiscountAmount =
              res.rows.zdyDiscountAmount;
          }
          this.numberToString(res.rows.totalPrice);
          this.orderClick(true);
        }
      });
    },
    orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
          this.saveRuleFormOrder.classHourId
        ) {
          if (this.saveRuleFormOrder.classHourId != -1) {
            this.saveRuleFormOrder.productPackageId =
              this.productDetail.classHourList[i].id;
            this.saveRuleFormOrder.classHour =
              this.productDetail.classHourList[i].classHour;
            this.saveRuleFormOrder.singlePrice =
              this.productDetail.classHourList[i].singlePrice;
            this.saveRuleFormOrder.totalPrice =
              this.productDetail.classHourList[i].totalPrice;
            
          } else {
            this.saveRuleFormOrder.productPackageId = "";
            this.saveRuleFormOrder.classHour = "";
            this.saveRuleFormOrder.singlePrice = "";
            this.saveRuleFormOrder.totalPrice = "";
          }
        }
      }
    },
    filterZdyClassHour() {
      // 课时大于等于1的正整数
      this.saveRuleFormOrder.zdyClassHour =
        this.saveRuleFormOrder.zdyClassHour.replace(/[^\d]/g, "");
      if (this.saveRuleFormOrder.zdyClassHour.length == 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/[^1-9]/gi, "");
      } else if (this.saveRuleFormOrder.zdyClassHour.length > 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/^0/, "");
      }
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    fileterZdySinglePrice() {
      this.saveRuleFormOrder.zdySinglePrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdySinglePrice
      );
    },
    zdySinglePriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdySinglePrice) {
        if (zdySinglePrice === "0") {
          this.msgWarn(
            "单价不合法，必须是必须是大于0的最多保留两位小数的数字！"
          );
          zdySinglePrice = "";
        }
        // 处理课时和总价
        if (zdyClassHour) {
          zdyTotalPrice = zdySinglePrice * zdyClassHour;
        } else if (zdyTotalPrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyTotalPrice() {
      this.saveRuleFormOrder.zdyTotalPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyTotalPrice
      );
    },
    zdyTotalPriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyTotalPrice) {
        // 处理课时和单价
        if (zdyClassHour && zdySinglePrice) {
          if (zdyClassHour * zdySinglePrice !== zdyTotalPrice) {
            zdyClassHour = "";
            zdySinglePrice = "";
          }
        } else if (zdyClassHour && !zdySinglePrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        } else if (!zdyClassHour && zdySinglePrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyDiscountAmount() {
      this.saveRuleFormOrder.zdyDiscountAmount = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyDiscountAmount
      );
    },
    zdyDiscountAmountBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyDiscountAmount && zdyTotalPrice) {
        if (Number(zdyDiscountAmount) > Number(zdyTotalPrice)) {
          this.msgWarn("优惠价格不能大于总价！");
          zdyDiscountAmount = "";
        }
      }
      zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    filterZdyActualPrice() {
      this.saveRuleFormOrder.zdyActualPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyActualPrice
      );
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
    zdyActualPriceBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyActualPrice && zdyTotalPrice) {
        if (zdyActualPrice > zdyTotalPrice) {
          this.msgWarn("实际支付不能大于总价格，请重新输入！");
          zdyActualPrice = zdyTotalPrice;
        }
        zdyDiscountAmount = zdyTotalPrice - zdyActualPrice;
      } else if (zdyActualPrice && zdyDiscountAmount) {
        if (zdyActualPrice < zdyDiscountAmount) {
          zdyDiscountAmount = "";
        }
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      } else {
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      }
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    totalPriceBlur() {
      // if(this.saveRuleFormOrder.totalPrice<0){
      //   this.saveRuleFormOrder.moneyType='4'
      // }
      console.log(
        this.saveRuleFormOrder.totalPrice,
        "saveRuleFormOrder.totalPrice..."
      );
      var totalPriceNum = this.saveRuleFormOrder.totalPrice;
      this.numberToString(totalPriceNum);
    },
    numberToString(n) {
      if (!/^(0|\-*[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
      var fuhao = n.toString().indexOf("-") == 0 ? "负" : "";
      var unit = "千百拾亿千百拾万千百拾元角分",
        str = "";
      n += "00";
      //如果是负数就就截取
      if (fuhao == "负") {
        n = n.substring(1, n.length);
      }
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
      this.translateTotal =
        fuhao +
        str
          .replace(/零(千|百|拾|角)/g, "零")
          .replace(/(零)+/g, "零")
          .replace(/零(万|亿|元)/g, "$1")
          .replace(/(亿)万|壹(拾)/g, "$1$2")
          .replace(/^元零?|零分/g, "")
          .replace(/元$/g, "元整");
    },

    certificateSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.certificate = response[0].url;
        this.srcList = [];
        this.srcList.push(response[0].url);
      }
    },
    certificateError(err, file, fileList) {
      this.msgError("付款凭证上传失败！");
    },
    beforeAvatarUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      } else {
      }
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    confirmSaveOrder(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this._saveFlag) {
            return;
          }
          this._saveFlag = true;
          let obj = {};
          if (this.saveRuleFormOrder.orderType == 3) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              studentId: this.downParams.id,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
            };
            if (this.saveRuleFormOrder.classHourId == -1) {
              obj.zdyClassHour = this.saveRuleFormOrder.zdyClassHour;
              obj.zdySinglePrice = this.saveRuleFormOrder.zdySinglePrice;
              obj.zdyGiveClassHour = this.saveRuleFormOrder.zdyGiveClassHour;
              obj.zdyDiscountAmount = this.saveRuleFormOrder.zdyDiscountAmount;
              obj.zdyActualPrice = this.saveRuleFormOrder.zdyActualPrice;
              obj.zdyTotalPrice = this.saveRuleFormOrder.zdyTotalPrice;
              obj.classHour = "";
              obj.singlePrice = "";
              obj.totalPrice = "";
            } else {
              obj.zdyClassHour = "";
              obj.zdySinglePrice = "";
              obj.zdyGiveClassHour = "";
              obj.zdyDiscountAmount = "";
              obj.zdyActualPrice = "";
              obj.zdyTotalPrice = "";
              obj.productPackageId = this.saveRuleFormOrder.productPackageId;
              obj.classHour = this.saveRuleFormOrder.classHour;
              obj.singlePrice = this.saveRuleFormOrder.singlePrice;
              obj.totalPrice = this.saveRuleFormOrder.totalPrice;
            }
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else if (
            this.saveRuleFormOrder.orderType == 2 ||
            this.saveRuleFormOrder.orderType == 4
          ) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              courseTypeId: this.saveRuleFormOrder.courseTypeId,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              // 一对多 保存时 sectionId 替代 periodId
              sectionId: this.saveRuleFormOrder.periodId,
              studentId: this.downParams.id,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              subjectId: this.saveRuleFormOrder.subjectId,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              coachAgreementName: this.saveRuleFormOrder.coachAgreementName,
              classHour: this.saveRuleFormOrder.classHour,
              singlePrice: this.saveRuleFormOrder.singlePrice,
              // totalPrice: this.saveRuleFormOrder.totalPrice,
              productPackageId: this.saveRuleFormOrder.productPackageId,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              zdyActualPrice: this.saveRuleFormOrder.zdyActualPrice,
              zdyTotalPrice: this.saveRuleFormOrder.totalPrice,
              zdyDiscountAmount: this.saveRuleFormOrder.zdyDiscountAmount,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              sectionId: this.saveRuleFormOrder.sectionId,
              subjectId: this.saveRuleFormOrder.subjectId,
              studentId: this.downParams.id,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              totalPrice: this.saveRuleFormOrder.totalPrice,
              zdyGiveClassHour: this.saveRuleFormOrder.zdyGiveClassHour,
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          }
          if (this.orderDialog.type == "edit") {
            await orderRemove([this.curEditOrder])
              .then((res) => {
                if (res.code == 0 && res.msg == "操作成功！") {
                  this._saveFlag = false;
                } else {
                  this._saveFlag = false;
                  this.msgWarn(res.rows);
                  return false;
                }
              })
              .catch((err) => {
                this._saveFlag = false;
              });
          }
          addOrder(obj)
            .then((res) => {
              if (res.code == 0 && res.msg == "下单成功") {
                this._saveFlag = false;
                this.msgSuccess("操作成功！");
                this.$emit("CB_downOrderStatus");
              } else {
                this._saveFlag = false;
                this.msgError(res.rows);
              }
            })
            .catch((err) => {
              this._saveFlag = false;
            });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    closeDialog() {
      this.$emit("CB_downOrderStatus");
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
.self_input {
  padding-bottom: 4px;
  color: #606266;
  text-align: center;
  width: 90%;
  overflow: hidden;
  outline: none;
  border: none;
  border-bottom: 1px solid #c0c4cc;
}
.self_input:focus {
  border-bottom: 1px solid #1890ff;
}
</style>
