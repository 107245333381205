<template>
  <div class="main-content sys-user-manage">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="btn-area" style="margin-top: 10px">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">新增学员</el-menu-item>
          <el-menu-item index="2">我的学员</el-menu-item>
          <el-menu-item index="3">一对多学员</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="btn-area select-condition" v-show="getKey==1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">性别：</span>
          <el-select v-model="listParams.sex" placeholder="请选择" size="small">
            <el-option
              v-for="item in sexList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>

        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchFn()"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area select-condition"  v-show="getKey==1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">下次跟进时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
        size="small"
        type="primary"
        style="float: right"
        @click="addStudent()"
        >新增学员</el-button
      >
      </div>
    </div>
    <div class="table-area"  v-show="getKey==1">
      <el-table
        size="small"
        :data="studentList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
        ><el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div style="display: flex;padding:4px 0;" >
              <div style="width:50%;">
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  position: relative;
                "
              >
                <p
                  v-show="showSaveDialog && scope.row.otoAllocateIsNew == 1"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-left:-10px;
                    line-height: 20px;
                    background: red;
                    color: #fff;
                    font-size: 14px;
                    position: absolute;
                    left: 20px;
                    top: 0px;
                    border-radius: 50%;
                  "
                >
                  新
                </p>
                <img
                  :src="scope.row.teachManagerPhoto"
                  alt=""
                  style="width: 100px; height: 100px; margin: 0 20px;object-fit: cover;"
                />
              </div>
              <div style="display: inline-block;margin-top:12px;" class="scopeDiv" >
                <p
                  @click="studentPopupFn(scope.row.id, scope.row.status)"
                  style="cursor: pointer;font-size:16px;color:#333;font-weight: bold;"
                >
                  {{ scope.row.name }}
                  <span style="font-size: 12px"
                    >({{ refereeTypeFn(scope.row.refereeType) }})</span
                  >
                  <span><img src="../../../assets/image/dengji.png" v-for="index in scope.row.levelId" :key="index" style="width:15px;height:12px;margin-left:4px;"/></span>
                  <span class="bq" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&(scope.row.onlineStatus=='Online'||scope.row.onlineStatus=='PushOnline')">
                    <img :src="require('@/assets/image/手机.png')" alt="" >
                    <span class="bqfont">家长APP在线</span>
                  </span>
                  <span class="bqlx" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&scope.row.onlineStatus=='Offline'">
                    <img :src="require('@/assets/image/手机-未开.png')" alt="" >
                    <span>家长APP离线</span>
                  </span>
                </p>
                <p style="margin-top:12px;">
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding-right:0px;">{{scope.row.sex==1?'男':scope.row.sex==2?'女':'未填写'}}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.birthday?scope.row.birthday:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.schoolName?scope.row.schoolName:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ getStudentState(scope.row.studentState) }}</span>
                  <span style="color:#333;padding:0 6px;">{{ scope.row.sectionName }}</span>
                </p>
                <p >
                  <span style="color:#909399;">必考科目：</span>
                  <span style="color:#333;">{{ mustTest(scope.row) }}</span>
                </p>
                <p v-show="showSaveDialog">
                  <span style="color:#909399;">分配时间：</span>
                  <span style="color:#333;">{{ scope.row.allocateTime }}</span>
                </p>
                <p v-show="!showSaveDialog">
                  <span style="color:#909399;">添加时间：</span>
                  <span style="color:#333;">{{ scope.row.createTime }}</span>
                </p>
                <div></div>
              </div>
              </div>
              <div style="display: inline-block; margin-top:15px;width:35%;">
                <p>
                  <span style="color:#909399;">下次跟进时间：</span>
                  <span style="color:#333;"> {{ scope.row.nextFollowupTime }}</span>
                </p>
                <p style="margin-top:5px">
                  <span style="color:#909399;">跟进内容：</span>
                  <span
                    style="       
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 3;
                      overflow: hidden;
                      color:#333;
                       cursor:pointer
                    "
                    @click="seeFollow(scope.row)"
                    v-html="scope.row.followContent"
                    >{{ scope.row.followContent }}</span
                  >
                </p>
              </div>
              <div style="width: 10%" class="scopeDiv3">
                <el-button
                  class="scopeDiv3_btn"
                  type="primary"
                  size="small"
                  @click.stop="daalArrange(scope.row)"
                  v-show="showSaveDialog && scope.row.status == 1"
                  >课程安排</el-button
                >
                <el-button type="primary" size="small" v-show="scope.row.status == 0"
                  >已禁用</el-button
                >
                 <el-button type="primary" size="small"  @click.stop="dealRemove(scope.row)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area"  v-show="getKey==1">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <div class="btn-area select-condition" v-show="getKey==2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="alreadyParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="alreadyParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">性别：</span>
          <el-select v-model="alreadyParams.sex" placeholder="请选择" size="small">
            <el-option
              v-for="item in sexList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">分配时间：</span>
          <el-date-picker
            size="small"
            v-model="alreadyPickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="alreadyPickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="alreadySearch()"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area select-condition"  v-show="getKey==2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">下次跟进时间：</span>
          <el-date-picker
            size="small"
            v-model="alreadyPickerValue2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="alreadyPickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">剩余金额：</span>
          <el-input
            v-model="alreadyParams.minPrice"
            placeholder="最小金额数"
            size="small"
            clearable
          ></el-input>
          ~
          <el-input
            v-model="alreadyParams.maxPrice"
            placeholder="最大金额数"
            size="small"
            clearable
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">跟进程度：</span>
          <el-select v-model="alreadyParams.levelId" placeholder="请选择">
            <el-option
              v-for="item in levelIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div style="display:flex;padding:5px 0;" v-show="getKey==2">
        <el-button type="success" @click="levelTab(1)" size="mini" class="levelBtn"  :class="{levelActive:levelNum==1}">在读（{{countParams.studying}}）</el-button>
        <el-button type="success" @click="levelTab(2)" size="mini" class="levelBtn" :class="{levelActive:levelNum==2}">停课（{{countParams.suspend}}）</el-button>
        <el-button type="success" @click="levelTab(3)" size="mini" class="levelBtn" :class="{levelActive:levelNum==3}">退费（{{countParams.refund}}）</el-button>
        <el-button type="success" @click="levelTab(4)" size="mini" class="levelBtn" :class="{levelActive:levelNum==4}">结课（{{countParams.endClass}}）</el-button>
      </div>
    <div class="table-area"  v-show="getKey==2">
      <el-table
        size="small"
        :data="alreadyList"
        style="width: 100%; margin-bottom: 20px;margin-top:10px;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
        ><el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div style="display: flex;padding:4px 0;" >
              <div style="width:50%;">
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  position: relative;
                "
              >
                <p
                  v-show="showSaveDialog && scope.row.otoAllocateIsNew == 1"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-left:-10px;
                    line-height: 20px;
                    background: red;
                    color: #fff;
                    font-size: 14px;
                    position: absolute;
                    left: 20px;
                    top: 0px;
                    border-radius: 50%;
                  "
                >
                  新
                </p>
                <img
                  :src="scope.row.teachManagerPhoto"
                  alt=""
                  style="width: 100px; height: 100px; margin: 0 20px;object-fit: cover;"
                />
              </div>
              <div style="display: inline-block;margin-top:12px;" class="scopeDiv" >
                <p
                  @click="studentPopupFn(scope.row.id, scope.row.status)"
                  style="cursor: pointer;font-size:16px;color:#333;font-weight: bold;"
                >
                  {{ scope.row.name }}
                  <span style="font-size: 12px"
                    >({{ refereeTypeFn(scope.row.refereeType) }})</span
                  >
                  <span><img src="../../../assets/image/dengji.png" v-for="index in scope.row.levelId" :key="index" style="width:15px;height:12px;margin-left:4px;"/></span>
                  <span class="bq" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&(scope.row.onlineStatus=='Online'||scope.row.onlineStatus=='PushOnline')">
                    <img :src="require('@/assets/image/手机.png')" alt="" >
                    <span class="bqfont">家长APP在线</span>
                  </span>
                  <span class="bqlx" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&scope.row.onlineStatus=='Offline'">
                    <img :src="require('@/assets/image/手机-未开.png')" alt="" >
                    <span>家长APP离线</span>
                  </span>
                </p>
                <p style="margin-top:12px;">
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding-right:0px;">{{scope.row.sex==1?'男':scope.row.sex==2?'女':'未填写'}}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.birthday?scope.row.birthday:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.schoolName?scope.row.schoolName:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ getStudentState(scope.row.studentState) }}</span>
                  <span style="color:#333;padding:0 6px;">{{ scope.row.sectionName }}</span>
                </p>
                <p >
                  <span style="color:#909399;">必考科目：</span>
                  <span style="color:#333;">{{ mustTest(scope.row) }}</span>
                </p>
                <p v-show="showSaveDialog">
                  <span style="color:#909399;">分配时间：</span>
                  <span style="color:#333;">{{ scope.row.allocateTime }}</span>
                </p>
                <p v-show="!showSaveDialog">
                  <span style="color:#909399;">添加时间：</span>
                  <span style="color:#333;">{{ scope.row.createTime }}</span>
                </p>
                <div></div>
              </div>
              </div>
              <div style="display: inline-block; margin-top:15px;width:35%;">
                <p>
                  <span style="color:#909399;">下次跟进时间：</span>
                  <span style="color:#333;"> {{ scope.row.nextFollowupTime }}</span>
                </p>
                <p style="margin-top:5px">
                  <span style="color:#909399;">跟进内容：</span>
                  <span
                    style="
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 3;
                      overflow: hidden;
                      color:#333;
                      cursor:pointer
                    "
                    @click="seeFollow(scope.row)"
                    v-html="scope.row.followContent"
                    >{{ scope.row.followContent }}</span
                  >
                </p>
              </div>
              <div style="width: 10%" class="scopeDiv3">
                <el-button
                  class="scopeDiv3_btn"
                  type="primary"
                  size="small"
                  @click.stop="daalArrange(scope.row)"
                  v-show="showSaveDialog && scope.row.status == 1"
                  >课程安排</el-button
                >
                <el-button type="primary" v-show="scope.row.status == 0" size="small"
                  >已禁用</el-button
                >
                <el-button type="primary" @click="setStatus(scope.row)" size="small" style="margin:8px 0;">设置状态</el-button>
                <!-- <el-badge
                    :value="scope.row.unreadCount"
                    :max="99"
                    :hidden="scope.row.unreadCount == 0"
                  >
                    <el-button type="primary"  size="small" plain @click="chatToParent(scope.row)">联系家长</el-button>
                  </el-badge> -->
                
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area"  v-show="getKey==2">
      <el-pagination
        @size-change="alreadySizeChange"
        @current-change="alreadyCurrentChange"
        :current-page="alreadyParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="alreadyParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="alreadyTotal"
        background
      ></el-pagination>
    </div>
    <div class="btn-area select-condition" v-show="getKey==3">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listMoreParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listMoreParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">性别：</span>
          <el-select v-model="listMoreParams.sex" placeholder="请选择" size="small">
            <el-option
              v-for="item in sexList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">分配时间：</span>
          <el-date-picker
            size="small"
            v-model="timeMorePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="dateMorePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="alreadyDistributeMore()"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area select-condition"  v-show="getKey==3">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">下次跟进时间：</span>
          <el-date-picker
            size="small"
            v-model="timeNextPickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="dateNextPickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">剩余金额：</span>
          <el-input
            v-model="listMoreParams.minPrice"
            placeholder="最小金额数"
            size="small"
            clearable
          ></el-input>
          ~
          <el-input
            v-model="listMoreParams.maxPrice"
            placeholder="最大金额数"
            size="small"
            clearable
          ></el-input>
        </div>
      </div>
    </div>
    <div class="table-area"  v-show="getKey==3">
      <el-table
        size="small"
        :data="studentMoreList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
        ><el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div style="display: flex;padding:4px 0;" >
              <div style="width:50%;">
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  position: relative;
                "
              >
                <p
                  v-show="showSaveDialog && scope.row.otoAllocateIsNew == 1"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-left:-10px;
                    line-height: 20px;
                    background: red;
                    color: #fff;
                    font-size: 14px;
                    position: absolute;
                    left: 20px;
                    top: 0px;
                    border-radius: 50%;
                  "
                >
                  新
                </p>
                <img
                  :src="scope.row.teachManagerPhoto"
                  alt=""
                  style="width: 100px; height: 100px; margin: 0 20px;object-fit: cover;"
                />
              </div>
              <div style="display: inline-block;margin-top:12px;" class="scopeDiv" >
                <p
                  @click="studentPopupFn(scope.row.id, scope.row.status)"
                  style="cursor: pointer;font-size:16px;color:#333;font-weight: bold;"
                >
                  {{ scope.row.name }}
                  <span style="font-size: 12px"
                    >({{ refereeTypeFn(scope.row.refereeType) }})</span
                  ><span>
                    <img src="../../../assets/image/dengji.png" v-for="index in scope.row.levelId" :key="index" style="width:15px;height:12px;margin-left:4px;"/></span>
                  <span class="bq" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&(scope.row.onlineStatus=='Online'||scope.row.onlineStatus=='PushOnline')">
                    <img :src="require('@/assets/image/手机.png')" alt="" >
                    <span class="bqfont">家长APP在线</span>
                  </span>
                  <span class="bqlx" v-show="(scope.row.onlineStatus!=''&&scope.row.onlineStatus!=null)&&scope.row.onlineStatus=='Offline'">
                    <img :src="require('@/assets/image/手机-未开.png')" alt="" >
                    <span>家长APP离线</span>
                  </span>
                </p>
                <p style="margin-top:12px;">
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding-right:0px;">{{scope.row.sex==1?'男':scope.row.sex==2?'女':'未填写'}}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.birthday?scope.row.birthday:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ scope.row.schoolName?scope.row.schoolName:"未填写" }}</span>
                  <span style="color:#333;border-right:1px solid #DCDFE6;padding:0 6px;">{{ getStudentState(scope.row.studentState) }}</span>
                  <span style="color:#333;padding:0 6px;">{{ scope.row.sectionName }}</span>
                </p>
                <p >
                  <span style="color:#909399;">必考科目：</span>
                  <span style="color:#333;">{{ mustTest(scope.row) }}</span>
                </p>
                <p v-show="showSaveDialog">
                  <span style="color:#909399;">分配时间：</span>
                  <span style="color:#333;">{{ scope.row.allocateTime }}</span>
                </p>
                <p v-show="!showSaveDialog">
                  <span style="color:#909399;">添加时间：</span>
                  <span style="color:#333;">{{ scope.row.createTime }}</span>
                </p>
                <div></div>
              </div>
              </div>
              <div style="display: inline-block; margin-top:15px;width:35%;">
                <p>
                  <span style="color:#909399;">下次跟进时间：</span>
                  <span style="color:#333;"> {{ scope.row.nextFollowupTime }}</span>
                </p>
                <p >
                  <span style="color:#909399;">跟进内容：</span>
                  <span
                    style="
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 3;
                      overflow: hidden;
                      color:#333;
                       cursor:pointer
                    "
                     @click="seeFollow(scope.row)"
                    v-html="scope.row.followContent"
                    >{{ scope.row.followContent }}</span
                  >
                </p>
              </div>
              <div style="width: 10%" class="scopeDiv3">
                <el-button type="primary" size="small"
                 @click="addedOrderlist(scope.row)"
                  >下单</el-button
                >
              </div>
            </div>
          </template>
        
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area"  v-show="getKey==3">
      <el-pagination
        @size-change="moreSizeChange"
        @current-change="moreCurrentChange"
        :current-page="listMoreParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listMoreParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalMore"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="课程安排"
      :visible.sync="arrangePopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <div class="content_div1">
          <div class="detail_type">学生概况</div>
          <div class="infoTitle">
            <p><span>学生姓名：</span><span>{{ studentDetail.name }}</span></p>
            <p><span>联系方式：</span><span>{{ studentDetail.contact ? studentDetail.contact : '暂无'}}</span></p>
            <p><span>年级：</span><span>{{ studentDetail.sectionName }}</span></p>
            <p><span>性别：</span><span>{{studentDetail.sex==1?'男':studentDetail.sex==2?'女':""}}</span></p>
            <p><span>必考科目：</span><span>{{ mustTest(studentDetail) }}</span></p>
          </div>
        </div>
        <div class="content_div3">
          <p style="font-size:16px;color:#000;font-weight:bold;">课程需求</p>
          <el-table
            size="small"
            :data="demandList"
            style="width: 100%; margin-top: 20px"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="科目"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectScore"
              label="科目成绩"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="讲师职称"
              :formatter="getTeachTitleId"
              align="center"
            ></el-table-column>
            <el-table-column
              label="讲师教龄"
              align="center"
              :formatter="teachingAgeIdFn2"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="demandInfo(scope.row.id)"
                  >查看</el-button
                > </template
              >产品
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="demandSizeChange"
            @current-change="demandCurrentChange"
            :current-page="xmtStudentNeeds.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="xmtStudentNeeds.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="demandTotal"
            background
          ></el-pagination>
        </div>
        <div class="content_div4">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程安排" name="first">
              <div style="display: flex">
                <el-button type="primary" size="small" @click="singleTime('')"
                  >单次排课</el-button
                >
                <el-button type="primary" size="small" @click="batchTime"
                  >批量排课</el-button
                >
                <el-button type="primary" size="small" @click="batchCancel"
                  >批量取消</el-button
                >
              </div>
              <div>
                <div
                  class="weekDiv"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <p id="last-week" @click="lastWeek()"><<</p>
                  <p>{{ weekTitle }}</p>
                  <p id="next-week" @click="nextWeek()">>></p>
                </div>
                <div style="display: flex; margin-top: 30px">
                  <div class="right_div" style="flex: 1">
                    <table id="monitor">
                      <thead id="monitor_thead" >
                        <tr>
                          <td style="line-height:39px;"></td>
                          <td v-for="(item, index) in weekList" :key="index" style="line-height:39px;">
                            {{ item.value }}
                          </td>
                        </tr>
                      </thead>
                      <tbody class="monitor_tbody">
                        <tr v-for="(item, index) in tableList" :key="index"  >
                          <td style="width: 80px;line-height:39px;height: 39px;">
                            {{ dataTimeArray[index].time }}
                          </td>
                          <td
                            class="course_td"
                            style="height:39px !important;"
                            v-for="(items, indexs) in item"
                            :key="indexs"
                            :class="{
                              cursor_pointer: !items.pastTime && items.canUse,
                            }"
                             @click.stop="singleTime(items)"
                             @mouseover="mouseOver($event,items)"
                            @mouseleave="mouseLeave($event,items)"
                          >
                            <p v-show="items.id" class="course_opt"  :style="timeClasss(items)">
                              <i
                                class="el-icon-edit-outline"
                                @click.stop="courseEdit(items)"
                                style="
                                  font-size: 25px;
                                  color: #fff;
                                  margin-right: 10px;
                                "
                              ></i>
                              <i
                                  class="el-icon-delete"
                                  @click.stop="courseEditRemove(items.id)"
                                  style="
                                    font-size: 25px;
                                    color: #fff;
                                    margin-left: 10px;
                                  "
                                ></i>
                            </p>
                            <div v-show="items.name" :style="timeClass(items)"
                            >
                              <span
                                >{{ items.subjectName }} {{ items.name }}</br>{{ items.time }}</span
                              >
                            </div>
                            <div
                              class="course_can_use"
                              v-show="!items.pastTime && items.canUse"
                            >
                              <span>+</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="配置讲师" name="second">
              <ul :class="{ subjectActive: flag }" class="subject_ul">
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(23, '语文')"
                    >语文</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(24, '数学')"
                    >数学</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(25, '英语')"
                    >英语</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(101, '物理')"
                    >物理</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(102, '化学')"
                    >化学</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(99, '政治')"
                    >政治</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(100, '生物')"
                    >生物</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(87, '地理')"
                    >地理</el-button
                  >
                </li>
                <li v-show="flag == true || flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(88, '历史')"
                    >历史</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(113, '书法')"
                    >书法</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(115, '美术')"
                    >美术</el-button
                  >
                </li>
                 <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(116, '德语')"
                    >德语</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(117, '法语')"
                    >法语</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(118, '俄语')"
                    >俄语</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(119, '西班牙语')"
                    >西班牙语</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(183, '其他小语种')"
                    >其他小语种</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(184, '其他艺术课')"
                    >其他艺术课</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(299, '其他学科')"
                    >其他学科</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(300, '汉语')"
                    >汉语</el-button
                  >
                </li>
                <!-- <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(103, '科学')"
                    >科学</el-button
                  >
                </li>
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(112, '国学')"
                    >国学</el-button
                  >
                </li>
                
                <li v-show="flag == false">
                  <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(114, '吉他')"
                    >吉他</el-button
                  >
                </li>
                 -->
                <p @click="showTag">
                  <span style="cursor: pointer">{{
                    flag ? "展开" : "收起"
                  }}</span>
                </p>
              </ul>
              <div style="clear: both"></div>
              <el-button
                size="small"
                type="success"
                style="margin-top: 30px"
                @click="historyFn()"
                >历史配置</el-button
              >
              <el-table
                size="small"
                :data="teacherList"
                style="width: 100%; margin-top: 20px"
                row-key="userId"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  prop="label"
                  label="标签"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{scope.row.label==1?'预配置讲师':scope.row.label==2?'配置讲师':'无'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="studentSectionName"
                  label="学科"
                  :formatter="dealSubjectId"
                  align="center"
                ></el-table-column>
                <el-table-column prop="orderNo" label="课程级别" align="center">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.courseLevel"
                      placeholder="请选择"
                      @change="
                        subjectRow(
                          scope.row.courseLevel,
                          scope.$index,
                          scope.row.subjectId,
                          scope.row
                        )
                      "
                    >
                      <el-option
                        v-for="item in courseLevelList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="teacherRealname"
                  label="讲师"
                  align="center"
                ></el-table-column>
                <el-table-column label="课时单价" align="center" >
                  <template slot-scope="scope" >
                    <el-input
                      maxlength="4"
                      v-model="scope.row.classSinglePrice"
                      @input="classSingBlur(scope.$index, scope.row.classSinglePrice)"
                      placeholder="请输入单价"
                      v-show="scope.row.courseLevel!=0"
                    ></el-input>
                    <span v-show="scope.row.courseLevel==0">--</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="selectTeacher(scope.$index)"
                      >选老师</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      v-show="scope.row.label == 1"
                      @click="setDispose(scope.$index)"
                      >设为配置讲师</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      v-show="scope.row.show == true"
                      @click="sureTeacherData(scope, scope.$index)"
                      >确定</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      @click="removeTeacherData(scope)"
                      >删除</el-button
                    >
                    <!-- <el-button
                      type="text"
                      size="small"
                      v-show="
                        !scope.row.show &&
                        scope.row.id &&
                        !scope.row.groupId &&
                        scope.row.parChatId&&
                        scope.row.label==2
                      "
                      @click="createGroupClick(scope, scope.$index)"
                      >创建群聊</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="already_create_group"
                      v-show="scope.row.groupId"
                      >已创建群聊</el-button
                    > -->
                  </template>
                </el-table-column>
              </el-table>
              <div class="page-area">
                <el-pagination
                  @size-change="teacherListSizeChange"
                  @current-change="teacherListCurrentChange"
                  :current-page="configteacherParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="configteacherParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="teacherListTotal"
                  background
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="课单管理" name="third">
              <div style="display: flex">
                <el-button type="primary" size="small" @click="addLessonList"
                  >新增</el-button
                >
                <el-button type="primary" size="small" @click="editLessonList"
                  >修改</el-button
                >
                <el-button type="primary" size="small" @click="removeLessonList"
                  >删除</el-button
                >
              </div>
              <div style="margin-top: 30px" class="cardLesson">
                <el-card
                  class="box-card"
                  v-for="(item, index) in orderPublishData"
                  :key="index"
                >
                  <div class="text item lessonDiv">
                    <p class="radioLesson">
                      <el-checkbox v-model="item.checked" v-show="(item.orientPublishStatus==0&&item.publishStatus==0)||(item.orientPublishStatus==2&&item.publishStatus==2)"></el-checkbox>
                      <span style="font-size:18px;margin-left:10px;">标题：{{ item.classListTitle }}</span>
                      <span>
                        <el-button
                        type="primary"
                        @click="orientIssuing(item.id,item.publishStatus)"
                        >定向发单</el-button
                      >
                      <el-button
                        type="primary"
                        v-show="item.publishStatus == 0"
                        @click="sendLesson(1, item.id)"
                        >发送课单</el-button
                      >
                      <el-button
                        type="primary"
                        v-show="item.publishStatus == 1"
                        @click="sendLesson(2, item.id)"
                        >立即结束</el-button
                      >
                      <el-button type="primary" v-show="item.publishStatus == 2"
                        >已结束</el-button
                      >
                      </span>
                    </p>
                    <p>
                      <span>年级：{{ item.sectionName }}</span>
                      <span>学科：{{ item.subjectName }}</span>
                      <span v-show="item.ifFaceToFace == 0"
                        >课酬：{{ item.minimumClassPay }}~{{
                          item.maximumClassPay
                        }}元/{{ item.classHour }}课时</span
                      ><span v-show="item.ifFaceToFace == 1">课酬：面议</span>
                    </p>
                    <p>
                      <span
                        >科目成绩：{{ item.score }} /
                        {{ item.totalScore }}</span
                      ><span
                        >上课形式：{{ item.formOfClass=='1'?'线上':item.formOfClass=='2'?'线下':'均可' }}</span
                      ><span
                        >讲师教龄：{{
                          teachingAgeIdFn2(item)
                        }}</span
                      >
                    </p>
                    <p>
                      首次课时间：{{ getFirstTeachTime(item.firstTeachTime) }}
                    </p>
                    <p>正常课时间：{{ item.weekArr }}</p>
                    <p>
                      <span>性别偏好：{{ item.sexNeedId==1?'男老师':item.sexNeedId==2?'女老师':'均可' }}</span>
                      <span>普通话：{{ mandarinIdFn(item.mandarinId) }}</span>
                      <span
                        >学生成绩类型：{{
                          studentAchievementIdFn(item.studentAchievementId)
                        }}</span
                      >
                    </p>
                    <p v-show="item.formOfClasss=='0'||item.formOfClasss=='2'">
                      <span
                        >面授地点：{{ item.addressNames
                        }}{{ item.addressDetNames }}</span
                      >
                    </p>
                    <p>是否面议：{{ item.ifFaceToFace==0?'不面议':item.ifFaceToFace==1?'面议':'暂无数据' }}</p>
                    <p>老师要求：{{ item.studentDesc }}</p>
                    <p>课单结束时间：{{ item.finishTime }}</p>
                    <p>备注：{{ item.remarks }}</p>
                    <p>风格偏好： </p>
                    <p style="width:100%;line-height:1.5;" v-html="judgeStyleNeedFn(item.styleNeedId)"></p>
                    <h1 style="font-size: 16px;margin-top:15px;">
                      接单老师<span
                        >（{{ item.xmtTeacherEntityList.length }}）人：</span
                      >
                    </h1>
                    <div
                      class="orderTeacher"
                      style="
                        display: flex;
                        height: 60px;
                        line-height: 60px;
                        margin-top: 20px;
                      "
                    >
                      <ul style="width: 80%">
                        <li
                          v-for="(items, indexs) in item.xmtTeacherEntityList"
                          :key="indexs"
                          style="
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 15px;
                          "
                        >
                          <img
                            :src="items.photo"
                            alt=""
                            style="
                              width: 50px;
                              height: 50px;
                              border-radius: 50%;
                            "
                          />
                        </li>
                      </ul>
                      <p
                        style="
                          width: 20%;
                          text-align: center;
                          color: #1890ff;
                          cursor: pointer;
                          font-weight: 500;
                        "
                        @click="viewDetails(item)"
                      >
                        <span>查看详情</span>
                      </p>
                    </div>
                  </div>
                </el-card>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="teacherPopup"
      :close-on-click-modal="false"
      width="1200px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
       <div class="btn-area select-condition">
        <el-menu
          :default-active="teacherIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="teacherIndexSelect"
        >
          <el-menu-item index="1">内部讲师</el-menu-item>
          <el-menu-item index="2">关注讲师</el-menu-item>
          <el-menu-item index="3">评价讲师</el-menu-item>
          <el-menu-item index="4">关注我的</el-menu-item>
          <el-menu-item index="5">最新注册</el-menu-item>
          <el-menu-item index="6">系统推荐</el-menu-item>
          <el-menu-item index="7">常用老师</el-menu-item>
        </el-menu>
    </div>
    <div class="btn-area select-condition" >
      <div class="left">
        <div class="select-condition-item">
          <div class="select-condition-item"> 
            <span class="item-lable">关键字：</span>
            <el-input
              v-model="teacherListParams.name"
              placeholder="姓名/联系方式"
              size="small"
            ></el-input>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">年级：</span>
            <el-select
              v-model="teacherListParams.sectionId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in sectionIdList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">学科：</span>
            <el-select
              v-model="teacherListParams.subjectId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in onesubjectIdList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">讲师身份：</span>
            <el-select
              v-model="teacherListParams.identityType"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in identityTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">讲师职称：</span>
            <el-select
              v-model="teacherListParams.teachTitleId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in teachTitleIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="select-condition-item">
          <el-button type="primary" size="small" icon="el-icon-search"
           @click="searchTeacher()"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="table-area" >
      <el-table
        size="small"
        :data="teacherListData"
        style="width: 100%; margin-bottom: 20px"
        border
        row-key="id"
        tooltip-effect="dark"
        ref="teacherListTable"
        @selection-change="handleSelectionChange"
        @row-click="teacherItemClick"
      >
      <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div class="teachContent">
              <div class="infoDetails">
                <dl class="left">
                  <dt><img :src="scope.row.photo" alt="" style="width:90px;height:90px;margin:0 10px"> </dt>
                  <dd >
                    <el-tooltip
                 
                  effect="dark"
                  :content="aa + getNum + bb"
                  placement="top"
                >
                  <p
                   @click.stop="dealEvaluate(scope.row)"
                    @mouseenter="seeMany(scope.row)"
                    style="display:inline-block;font-size:18px;cursor:pointer"
                  >
                    {{ scope.row.name }}<span style="font-size:14px;margin-left:5px;">（{{scope.row.realname}}）</span><span style="font-size:14px;margin-left:10px;">{{onLineFlagFn(scope.row.onLineFlag)}}</span>
                  </p>
                </el-tooltip>
                  <p >
                   <el-tag  size='small' v-if="scope.row.sexId == 1">男</el-tag>
                   <el-tag size='small' v-if="scope.row.sexId == 2">女</el-tag>
                   <el-tag size='small'>{{ titleFn(scope.row.teachTitleId) }}</el-tag>
                   <el-tag size='small'>{{ identityFn(scope.row.identityType) }}</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                    <el-tag size='small'>{{ subjectFn(scope.row.subjectId) }}</el-tag>
                    <el-tag size='small'>{{ dealSectionId(scope.row.sectionId) }}</el-tag>
                     
                    <el-tag size='small' type="success" v-if="scope.row.isEquipment==1">有设备</el-tag>
                    <el-tag size='small'  v-else-if="scope.row.isEquipment!=1">无设备</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                  <el-tag size='small' type="warning" v-for="(item,key) in scope.row.arr" :key='key'>{{item.name}}</el-tag>
                  </p>
                  <p style="margin-top:10px;" >
                      分组：
                      <span v-show="scope.row.concernType==0">未关注</span>
                      <span v-show="(scope.row.concernType==1||scope.row.concernType==3)&&scope.row.groupName==null">未分组</span>
                      <span v-show="scope.row.groupName">{{ scope.row.groupName}}</span>
                  </p>
                  </dd>
                </dl>
                <div class="content">
                    <ul>
                      <li><span>授课方式：</span><span>{{classFormFn(scope.row.classForm)}}</span></li>
                      <li><span>擅教学生：</span><span  >{{judgeStyleNeedFns(scope.row.skilfulTeach)}}</span></li>
                      <li><span>讲课风格：</span><span  >{{skilfulFn(scope.row.skilful)}}</span></li>
                    </ul>
                </div>
                <div class="right">
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
              <el-pagination
                @size-change="teacherSizeChange"
                @current-change="teacherCurrentChange"
                :current-page="teacherListParams.pageNumber"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="teacherListParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="teachertotal"
                background
              ></el-pagination>
            </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTeacherData(1)" size="small"
          >添加配置讲师</el-button
        >
        <el-button type="success" @click="saveTeacherData(2)" size="small"
          >添加预配置讲师</el-button
        >
        <el-button @click="teacherPopup = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="studentDetailPopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
       <div id="posterHtml" :style="backImgStyle" style="display:none;background-repeat: no-repeat;background-size:100% 100%;width: 750px;height: 1334px;">
        <div class="posterInfo" style="position: absolute;margin-top:331px;width:100%;">
          <p style="color:#fff;font-size:60px;text-align:center;">{{studentListDetail.name}}</p>
          <div :style="backImgTime"><p  id="qecodeTime" style="color:#fff;font-size:32px;text-align:center;"></p></div>
        </div>
        <div class="qrcode"><div id="qrcodeImg"></div><p style="text-align:center;color:#fff;font-size:28px;margin-top:27px;">长按识别二维码</p></div>
      </div>
      <div class="dialog-content">
        <el-button type="success" @click="daalEdit" style="float: right;" size="small">查看</el-button>
        <dl class="student_dl">
          <dt>
            <el-upload
              :action="fileUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              accept="image/jpeg, image/png/"
            >
              <img
                v-if="updateImg.teachManagerPhoto"
                :src="updateImg.teachManagerPhoto"
                style="width: 88px; height: 88px; "
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </dt>
          <dd style="margin-top: 16p;margin-left:20px;">
            <span style="font-weight: bold; font-size: 20px;color:#000;">{{
              studentListDetail.name
            }}<span
              style="
                display: inline-block;
                padding: 4px 10px;
                border: 1px solid #3EC279;
                background: #fff;
                color:#3EC279;
                font-size:12px;
                border-radius: 15px;
                margin-left:12px;
              "
              >{{ getStudentState(studentListDetail.studentState) }}</span
            ></span>
            <p style="margin-top:19px; font-size: 14px;color:#010101;"
              >账号：{{ studentListDetail.account }}</p
            >
          </dd>
        </dl>
        <p
              style="padding: 6px 0; color: red"
              v-show="updateImg.photoFlag ==0"
            >
              还未上传头像，点击头像上传头像吧~
            </p>
        <div class="info_div1" style="margin-top: 35px">
          <p>基本信息</p>
          <ul>
            <li>
              <p>联系电话</p>
              <p>{{ studentListDetail.contact?studentListDetail.contact:"~" }}</p>
            </li>
            <li>
              <p>性别</p>
              <p>{{studentListDetail.sex==1?"男":studentListDetail.sex==2?'女':'~'}}</p>
            </li>
            <li>
              <p>年级</p>
              <p>{{ studentListDetail.sectionName?studentListDetail.sectionName:"~" }}</p>
            </li>
            <li>
              <p>出生日期</p>
              <p>{{ studentListDetail.birthday?studentListDetail.birthday:"~" }}</p>
            </li>
            <li>
              <p>所在学校</p>
              <p>{{ studentListDetail.schoolName?studentListDetail.schoolName:"~" }}</p>
            </li>
            <li>
              <p
                >家庭地址</p
              >
              <p>{{ studentListDetail.provinceName?studentListDetail.provinceName:"~"
                }}{{ studentListDetail.cityName?studentListDetail.cityName:"~"
                }}{{ studentListDetail.countyName?studentListDetail.countyName:"~" }}</p>
            </li>
            <li>
              <p>孩子性格</p>
              <p>{{ studentListDetail.childNature?studentListDetail.childNature:"~" }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div1">
          <p>家长信息</p>
          <ul>
            <li>
              <p
                >学生母亲</p>
              <p>{{ studentListDetail.childMotherName?studentListDetail.childMotherName:"~"
                }}{{ studentListDetail.childMotherPhone?studentListDetail.childMotherPhone:"~" }}</p>
            </li>
            <li>
              <p
                >学生父亲</p>
              <p>{{ studentListDetail.childFatherName?studentListDetail.childFatherName:"~"
                }}{{ studentListDetail.childFatherPhone?studentListDetail.childFatherPhone :"~" }}</p>
            </li>
            <li>
              <p>微信/QQ</p>
              <p>{{ studentListDetail.parentWechat?studentListDetail.parentWechat:"~" }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div2">
          <p>必考科目</p>
          <ul>
            <li>
              <p>{{ mustTest(studentListDetail) }}</p>
            </li>
          </ul>
        </div>
        <div class="info_div2">
          <p>所上科目</p>
          <ul>
            <li><p>{{ mustSubjects(studentListDetail) }}</p></li>
          </ul>
        </div>
        <div class="info_div1" v-show="studentListDetail.referralstudent">
          <p>介绍人信息</p>
          <ul>
            <li>
              <p
                >老生姓名</p
              >
              <p>{{
                  studentListDetail.referralstudent
                    ? studentListDetail.referralstudent
                    : "暂无介绍人"
                }}</p>
            </li>
            <li>
              <p
                >联系方式</p
              >
              <p>{{
                  studentListDetail.referralphone
                    ? studentListDetail.referralphone
                    : "暂无联系方式"
                }}</p>
            </li>
          </ul>
        </div>
      </div>
      <el-tabs v-model="tabsActive" @tab-click="tabsHandleClick">
        <el-tab-pane label="工作记录" name="record">
          <div>
            <div
                  class="weekDiv"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <p id="last-week" @click="lastFreeWeek()"><<</p>
                  <p>{{ weekFreeTitle }}</p>
                  <p id="next-week" @click="nextFreeWeek()">>></p>
                </div>
                <div>
                  <el-button @click="cancelFreeTime()" size="small" type="primary">取消批量设置</el-button>
                  <el-button @click="setFreeTime()" size="small" type="primary">批量设置空闲</el-button>
                </div>
                <div style="display: flex; margin-top: 30px">
                  <div class="right_div" style="flex: 1">
                    <table id="monitor">
                      <thead id="monitor_thead">
                        <tr>
                          <td style="line-height:39px;"></td>
                          <td v-for="(item, index) in weekLists" :key="index" style="line-height:39px;">
                            {{ item.value }}
                          </td>
                        </tr>
                      </thead>
                      <tbody class="monitor_tbody">
                        <tr v-for="(item, index) in tableFreeList" :key="index">
                          <td style="width: 80px;line-height:39px;height: 39px;" >
                            {{ dataTimeArray[index].time }}
                          </td>
                          <td
                            @click.stop="seeFree($event,items,indexs)"
                            class="course_td"
                            v-for="(items, indexs) in item"
                            :key="indexs"
                          >
                          <span v-show="items.flag" style="color:red;">已选中</span>
                            <div v-show="!items.teacherRealName">
                              <span >{{ items.name }}</span>
                            </div>
                            <div v-show="items.teacherRealName" :style="timeClassWork(items)">
                              <span
                                >{{ items.subjectName }} {{ items.teacherRealName }}</br>{{ items.time }}</span
                              >
                            </div>
                            <div
                              class="free_course_can_use"
                              v-show="!items.pastTime && items.canUse"
                            >
                              <span></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
          </div>
          <div style="float: right; padding-bottom: 20px">
            <el-button size="small" @click="seeNeed()">查看需求</el-button>
            <el-button size="small" @click="addNeed()">添加需求</el-button>
            <el-button size="small" @click="editNeed()">修改需求</el-button>
            <el-button size="small" @click="deleteNeed()">删除</el-button>
          </div>
          <el-table
            size="small"
            :data="needList"
            style="width: 100%; margin-top: 40px"
            row-key="id"
            border
            ref="userListTable"
            tooltip-effect="dark"
            @selection-change="handleNeedChange"
            @row-click="needItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subjectId"
              label="科目"
              :formatter="dealSubjectIdd"
              align="center"
            ></el-table-column>
            <el-table-column prop="orderNo" label="科目成绩" align="center">
              <template slot-scope="scope">
                {{ scope.row.subjectScore }}~{{ scope.row.subjectAllScore }}
              </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="讲师职称"
              align="center"
              :formatter="dealTeachTitleName"
            ></el-table-column>
            <el-table-column
              prop="teachingAgeId"
              label="讲师教龄"
              align="center"
              :formatter="teachingAgeIdd"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  v-show="showSaveDialog"
                  @click.stop="matching(scope.row)"
                  >匹配老师</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.stop="sendOut(scope.row)"
                  >发送课单</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-area">
            <el-pagination
              @size-change="handleNeedSizeChange"
              @current-change="handleNeedCurrentChange"
              :current-page="needRuleForm.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="needRuleForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="needTotal"
              background
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="followUp">
          <el-button type="primary" @click="addFollow" style="margin-bottom:20px">新增跟进记录</el-button>
          <div style="display:inline-block;margin-left:20px;">
            <span class="item-lable">跟进程度：</span>
            <el-select
              v-model="levelParams.levelId"
              placeholder="请选择"
              size="small"
              style="width:130px;"
              @change="levelChange"
            >
              <el-option
                v-for="item in studentlevellList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-table
            size="small"
            :data="followUpList"
            style="width: 100%; margin-bottom: 20px；margin-top:20px;flex:1;overflow: hidden;"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              type="index"
              prop="studentName"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column prop="" label="跟进记录" align="center">
              <template slot-scope="scope">
               <p>{{scope.row.followContent}}</p>
               <el-image
                class="td-img"
                v-for="(item,index) in scope.row.photoList" :key="index"
                style="width: 100px; height: 100px;margin-left:5px;"
                :src="item.url"
                :preview-src-list="scope.row.imageArray"
              >
              </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="saleName" label="跟进详情" width="200">
              <template slot-scope="scope">
                <p>跟进人：{{ scope.row.teaName }}</p>
                <p>跟进时间：{{ scope.row.createTime }}</p>
                <p>下次跟进时间：{{ scope.row.nextFollowupTime }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="editfollowUp(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="removefollowUp(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-area">
            <el-pagination
              @size-change="followUpSizeChange"
              @current-change="followUpCurrentChange"
              :current-page="followUpParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="followUpParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="followUpParamsTotal"
              background
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="学习报告" name="studyReport">
          <el-button type="primary" size="small" @click="addStudyReport()">新增学习报告</el-button>
          <el-table
            size="small"
            :data="studyReportList"
            style="width: 100%; margin-top: 40px"
            row-key="id"
            border
            ref="userListTable"
            tooltip-effect="dark"
            @selection-change="studyReportChange"
            @row-click="studyReportClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              label="科目"
              align="center">
              <template slot-scope="scope">
                <span>{{reportSubject(scope.row.subjectIds)}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="报告状态"
              align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.reportStatus==1?'已发布':scope.row.reportStatus==0?'未发布':''}}</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="teachTitleName"
              label="家长查看状态"
              align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.parentStatus==1?'已查看':scope.row.parentStatus==0?'未查看':''}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="createLink(scope.row)"
                  >生成海报</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="seeStudyReport(scope.row)"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  size="small"
                   @click="editStudyReport(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="updateStudyReport(scope.row)"
                  v-show="scope.row.reportStatus==0"
                  >发送给家长</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="removeStudyReport(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-area">
            <el-pagination
              @size-change="handlestudySizeChange"
              @current-change="handlestudyCurrentChange"
              :current-page="studyParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="studyParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studyTotal"
              background
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="订单" name="order">
          <div style="display:flex;justify-content: space-between; padding-bottom: 20px;">
            <el-switch
                  v-model="switchValue"
                  @change="switchChange()"
                  active-color="#1890ff"
                  inactive-color="#1890ff"
                  active-text="订单记录"
                  inactive-text="充值记录">
                </el-switch>
              <div>
                  <el-button
                    size="small"
                    @click="addedOrder()"
                    v-show="getstatus == 1"
                    >新增下单</el-button
                  >
                  <el-button size="small" @click="returnStudent()" v-show="getKey!=1"
                    >退回学生</el-button
                  >
                  <el-button size="small" @click="studentRefund()"
                    >学生退费</el-button
                  >
              </div>
          </div>
          <el-table
            v-show="switchValue==true"
            size="small"
            :data="orderList"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            ref="orderListTable"
            tooltip-effect="dark"
            @selection-change="handleOrderChange"
            @row-click="orderItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="orderNo"
              label="订单号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orderType"
              :formatter="formatterOrderType"
              label="产品类型"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="productName"
              label="产品名称"
              align="center"
            ></el-table-column>
            <el-table-column
              label="总价"
              :formatter="formatterTotalPrice"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="surplusPrice"
              label="剩余费用"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="zdyActualPrice"
              label="实际支付"
              align="center"
              :formatter="formatterZdyActualPrice"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="下单时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orderSourceName"
              label="下单人"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.orderSourceName}}（{{scope.row.orderSourceRealName}}）</span>
              </template>
            </el-table-column>
            <el-table-column
              :formatter="formatterOrderStatus"
              label="订单状态"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="150px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="seeInfo(scope.row)"
                  >查看详情</el-button
                >
                 <el-button
                  type="text"
                  size="small"
                  v-show="scope.row.orderStatus!=1&&scope.row.orderStatus!=2"
                  @click.stop="seeClassRecord(scope.row)"
                  >上课记录</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-show="scope.row.orderStatus == 2"
                  @click.stop="editOrderClick(scope.row)"
                  >修改订单</el-button
                >
                <el-button
                  type="text"
                  size="small"
                   v-show="(scope.row.orderStatus == 4||scope.row.orderStatus == 5)&&scope.row.orderType==1"
                  @click.stop="balanceTurn(scope.row)"
                  >余额转充值</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <p v-show="getKey!=3&&switchValue==true" style="float: right; font-weight: 600; font-size: 16px;padding-bottom:20px;" >
            试听课剩余总金额：{{ surplusMoney }}
          </p>
          <el-table
            v-show="switchValue==false"
            size="small"
            :data="rechargeList"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            ref="rechargeListTable"
            tooltip-effect="dark"
            @selection-change="handleOrderChange"
            @row-click="rechargeItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="totalPrice"
              label="缴费金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="zdyGiveClassHour"
              label="赠送课时"
              align="center"
            > 
              <template slot-scope="scope">
                {{scope.row.zdyGiveClassHour?scope.row.zdyGiveClassHour:0}}
              </template>
            </el-table-column>
            <el-table-column
            :formatter="formatterMoneyType"
              prop="surplusPrice"
              label="收款类型"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="充值时间"
              align="center"
            ></el-table-column>
            <el-table-column
             :formatter="formatterOrderStatus"
              prop="createTime"
              label="审核状态"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="150px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="seeInfo(scope.row)"
                  >查看详情</el-button
                >
                
                <el-button
                  type="text"
                  size="small"
                  v-show="scope.row.orderStatus == 2"
                  @click.stop="editOrderClick(scope.row)"
                  >修改订单</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <p v-show="switchValue==false" style="float: right; font-weight: 600; font-size: 16px" >
            总计缴费金额：{{rechargeParams.total}}元，剩余金额{{rechargeParams.surplusMoney}}元，剩余赠送课时{{rechargeParams.classHours}}课时
          </p>
        </el-tab-pane>
        <el-tab-pane label="学生课耗" name="lessonCost">
          <el-tabs v-model="classRecord" @tab-click="classRecordClick">
            <el-tab-pane label="一对一" name="1">
              <div class="btn-area select-condition">
                <div class="left">
                  
                  <div class="select-condition-item">
                    <span class="item-lable">学科：</span>
                    <el-select
                      v-model="findListParams.subjectId"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option
                        v-for="item in onesubjectIdList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">上课状态：</span>
                    <el-select
                      v-model="findListParams.attendClassStatus"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option label="全部" value=""></el-option>
                      <el-option label="未上课" value="0"></el-option>
                      <el-option label="已上课" value="1"></el-option>
                      <el-option label="上课中" value="2"></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">课程状态：</span>
                    <el-select
                      v-model="findListParams.abnormalStatus"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option label="全部" value=""></el-option>
                      <el-option label="正常" value="0"></el-option>
                      <el-option label="异常" value="1"></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">日期范围：</span>
                    <el-date-picker
                      size="small"
                      v-model="timePickerOne"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      @change="onePickerChange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                  </div>
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="()=>(findListParams.pageNumber=1,findProductItemPage())"
                    >搜索</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="importExcelOne"
                    >导出excel</el-button
                  >
                </div>
              </div>
              <el-table
                size="small"
                :data="oneToOneData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  type="index"
                  prop="orderNo"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="beginTime"
                  label="上课时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称"
                  align="center"
                >
                    <template slot-scope="scope">
                      <span v-if="scope.row.productName">{{scope.row.productName}}</span>
                      <span v-else>充值订单</span>
                    </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="课程标题"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="courseLevel"
                  :formatter="courseLevelFormatter"
                  label="课程级别"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="usedClassHour"
                  label="课时"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="teacherRealName"
                  label="授课老师"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop=""
                  label="上课状态"
                  :formatter="formatterAttendClassStatus"
                  align="center"
                ></el-table-column>
                <el-table-column prop="" label="课程状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.abnormalStatus == 0">正常</span>
                    <span v-else style="color: red">异常</span>
                  </template>
                </el-table-column>
                <el-table-column
                prop=""
                label="补课时"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.classHourType == 7" style="color:red;">是</span>
                  <span v-if="scope.row.classHourType != 7" >否</span>
                </template>
              </el-table-column>
              </el-table>
              <p class="countMap_p">
                <span>已排{{countMap.yipaike}}课时</span>
                <span>已上{{countMap.yishangke}}课时</span>
                <span>上课中{{countMap.shangkezhong}}课时</span>
                <span>未上{{countMap.weishangke}}课时</span>
              </p>
              <div class="page-area">
                <el-pagination
                  @size-change="oneToOneSizeChange"
                  @current-change="oneToOneCurrentChange"
                  :current-page="findListParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="findListParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total1"
                  background
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="一对多" name="2">
              <div class="btn-area select-condition">
                <div class="left">
                  <div class="select-condition-item">
                    <span class="item-lable">关键字：</span>
                    <el-input
                      v-model="oneToMoreParams.roomName"
                      placeholder="请输入班级名称"
                      size="small"
                    ></el-input>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">上课状态：</span>
                    <el-select
                      v-model="oneToMoreParams.attendClassStatus"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option label="全部" value=""></el-option>
                      <el-option label="未上课" value="0"></el-option>
                      <el-option label="已上课" value="1"></el-option>
                      <el-option label="上课中" value="2"></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">课程状态：</span>
                    <el-select
                      v-model="oneToMoreParams.abnormalStatus"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option label="全部" value=""></el-option>
                      <el-option label="正常" value="0"></el-option>
                      <el-option label="异常" value="1"></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">日期范围：</span>
                    <el-date-picker
                      size="small"
                      v-model="timePickerMore"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      @change="morePickerChange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                  </div>
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="()=>(oneToMoreParams.pageNumber=1,findMoreProductItemPage())"
                    >搜索</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="importExcelMore"
                    >导出excel</el-button
                  >
                </div>
              </div>
              <el-table
                size="small"
                :data="oneToMoreData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  type="index"
                  prop="orderNo"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="roomName"
                  label="班级名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="teacherRealName"
                  label="授课老师"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="title"
                  label="课程标题"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="beginTime"
                  label="上课时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="orderSourceName"
                  label="上课状态"
                  align="center"
                  :formatter="formatterAttendClassStatus"
                ></el-table-column>
                <el-table-column
                  prop="usedClassHour"
                  label="课时数"
                  align="center"
                ></el-table-column>
                <el-table-column prop="" label="课程状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.abnormalStatus == 0">正常</span>
                    <span v-else style="color: red">异常</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page-area">
                <el-pagination
                  @size-change="oneToMoreSizeChange"
                  @current-change="oneToMoreCurrentChange"
                  :current-page="oneToMoreParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="oneToMoreParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total2"
                  background
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="取消课" name="3">
              <div class="btn-area select-condition">
                <div class="left">
                  <div class="select-condition-item">
                    <span class="item-lable">取消原因：</span>
                    <el-select
                      v-model="delParams.delCause"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option
                        v-for="item in delCauseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="select-condition-item">
                    <span class="item-lable">日期范围：</span>
                    <el-date-picker
                      size="small"
                      v-model="timePickerDel"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      @change="delPickerChange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                  </div>
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="()=>(delParams.pageNumber=1,findDelProductItemPage())"
                    >搜索</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="importExcelDel"
                    >导出excel</el-button
                  >
                </div>
              </div>
              <el-table
                size="small"
                :data="delData"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="beginTime"
                  label="上课时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称"
                  align="center"
                > 
                  <template slot-scope="scope">
                      <span v-if="scope.row.productName">{{scope.row.productName}}</span>
                      <span v-else>充值订单</span>
                    </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="课程标题"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="课程级别"
                  align="center"
                  :formatter="courseLevelFormatter"
                ></el-table-column>
                <el-table-column
                  prop="usedClassHour"
                  label="课时"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="teacherRealName"
                  label="授课老师"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="取消原因"
                  align="center"
                  :formatter="formatterDelCause"
                ></el-table-column>
              </el-table>
              <div class="page-area">
                <el-pagination
                  @size-change="delSizeChange"
                  @current-change="delCurrentChange"
                  :current-page="delParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="delParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total3"
                  background
                ></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      :title="needTitle.title"
      :visible.sync="needPopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="needRuleForm"
          status-icon
          :rules="needRules"
          ref="needRuleForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="普通话" prop="teachTitleId">
                <el-select
                  v-model="needRuleForm.mandarinId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in mandarinIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学科" prop="subjectId">
                <el-select
                  v-model="needRuleForm.subjectId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in subjectidList"
                    :key="option.macroId"
                    :label="option.name"
                    :value="option.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="科目成绩">
                <div class="item_score">
                  <el-input
                    v-model="needRuleForm.subjectScore"
                    :maxlength="3"
                  ></el-input>
                  <span> / </span>
                  <el-input
                    v-model="needRuleForm.subjectAllScore"
                    :maxlength="3"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="性别偏好" prop="sexNeedId">
                <el-select
                  v-model="needRuleForm.sexNeedId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in teachSexList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="讲师教龄">
                <el-select
                  v-model="needRuleForm.teachingAgeId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in teachingAgeIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="上课形式" prop="classForm">
                <el-select
                  v-model="needRuleForm.classForm"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in classFormList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            
            </el-col>
          </el-row>
          <el-row :gutter="18" type="flex">
            <el-col :span="8">
              <el-form-item label="优先排课日期" prop="priorDateOne">
                <el-select
                  v-model="needRuleForm.priorDateOne"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorDatList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="priorDateTwo">
                <el-select
                  v-model="needRuleForm.priorDateTwo"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorDatList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="priorDateThree">
                <el-select
                  v-model="needRuleForm.priorDateThree"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorDatList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="18" type="flex">
            <el-col :span="8">
              <el-form-item label="优先排课时间" prop="priorTimeOne">
                <el-select
                  v-model="needRuleForm.priorTimeOne"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorTimeList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="priorTimeTwo">
                <el-select
                  v-model="needRuleForm.priorTimeTwo"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorTimeList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="priorTimeThree">
                <el-select
                  v-model="needRuleForm.priorTimeThree"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in priorTimeList"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="排课次数" prop="classTimes">
                <el-select
                  v-model="needRuleForm.classTimes"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in classTimesList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学生成绩类型" prop="studentAchievementId">
                <el-select
                  v-model="needRuleForm.studentAchievementId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in studentAchievementList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                  
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="风格偏好">
                <el-checkbox-group v-model="skilfulArray">
                  <el-checkbox
                    v-for="(item, index) in skilfulDate"
                    :key="index"
                    :label="item.macroId"
                    :value="item.macroId"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生情况描述" prop="studentDescribe">
                <el-input
                  type="textarea"
                  :rows="2"
                  :maxlength="200"
                  placeholder="日常成绩/班级排名等"
                  v-model="needRuleForm.studentDescribe"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  :rows="2" 
                   :maxlength="200"
                  placeholder="请输入内容"
                  v-model="needRuleForm.remark"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="needTitle.title != '查看需求'"
      >
        <el-button @click="needPopup = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveNeed('needRuleForm')" size="small"
          >确 定</el-button
        >
      </span>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="needTitle.title == '查看需求'"
      >
        <el-button @click="needPopup = false" type="primary" size="small"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="singleParams.title"
      :visible.sync="singlePopup"
      :close-on-click-modal="false"
      width="460px"
      center
      custom-class="add_class"
    >
      <div class="dialog-content">
        <el-form
          :model="singleRuleForm"
          status-icon
          :rules="singlePopupRules"
          ref="singleRuleForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="singleRuleForm.subjectId"
                placeholder="请选择"
                size="small"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in singSubjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType!=7">
            <el-form-item label="产品类型" prop="classHourStatus">
              <el-radio-group v-model="classHourStatus" @change="classHourStatusChange()">
                <el-radio label="1">一对一充值</el-radio>
                <el-radio label="2">一对一</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType!=7">
            <el-form-item label="收费类型" prop="classHourType">
              <el-select
                @change="classHourTypeChange"
                v-model="singleRuleForm.classHourType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in classHourTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-show="classHourStatus==item.show"
                >
                </el-option>
              </el-select>
              <p v-show="singleRuleForm.classHourType == 3">
                剩余金额：{{ accountBalance }}
              </p>
              <p v-show="singleRuleForm.classHourType == 5">
                剩余课时：{{ remainingClassHours }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="classHourStatus==2">
            <el-form-item label="产品名称" prop="productId">
              <el-select
                v-model="singleRuleForm.productId"
                placeholder="请选择"
                size="small"
                value-key="id"
                @change="surplusNumFn"
              >
                <el-option
                  v-for="item in buyProductList"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <p v-show="singleRuleForm.classHourType == 1">
                剩余金额：{{ surplusNum }}
              </p>
              <p v-show="singleRuleForm.classHourType == 2">
                剩余课时：{{ surplusNum }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课模式" prop="classMode">
              <el-radio-group v-model="singleRuleForm.classMode" @change="classModeChange()">
                <el-radio label="1">线上</el-radio>
                <el-radio label="2">线下</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="singleRuleForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课时间" prop="beginTime">
              <el-date-picker
                v-model="singleRuleForm.beginTime"
                type="datetime"
                format='yyyy-MM-dd HH:mm'
                value-format='yyyy-MM-dd HH:mm'
                placeholder="选择日期"
                @change="changeBeginTime"
                :picker-options="singleRuleForm.classMode=='1'?beforePickerOptions:offlineBeforePickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
              <el-input-number
                v-model="singleRuleForm.usedClassHour"
                @change="classHourChange"
                type="number"
                placeholder="请输入内容"
                :max="Number(4)"
                :min="Number(1)"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
              <el-input
                @click.native="getTeacherId()"
                readonly
                v-model="newteacherName"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classMode == 2">
            <el-form-item label="上课地址" prop="detailAddress">
              <el-input
                @click.native="getDetailAddress()"
                readonly
                v-model="singleRuleForm.classAddress"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classMode == 1">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="singleRuleForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-switch v-model="singleRuleForm.isFirstClass"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-switch :disabled="!hasCdnMenu"
                @click.native="handleAuthTip('hasCdnMenu', hasCdnMenu)"
                :title="!hasCdnMenu ? '请联系管理员开同此功能' : ''" v-model="singleRuleForm.isPrisonClass"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-switch :disabled="!hasRecordMenu"
                @click.native="handleAuthTip('hasRecordMenu', hasRecordMenu)"
                :title="!hasRecordMenu ? '请联系管理员开同此功能' : ''" v-model="singleRuleForm.isPlayback"
                  active-value="1"
                  inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-show="singleRuleForm.classHourType==7">
            <el-form-item label="讲师课酬" prop="ifClassRemuneration">
              <el-radio-group v-model="singleRuleForm.ifClassRemuneration">
                <el-radio label="0">计算</el-radio>
                <el-radio label="1">不计算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="price_tip">
        <div class="class_fee">本堂课流量费用：<span>{{singleRuleForm.expense}}</span>元 </div>
        <div class="account_fee">账户余额：{{organTotalBalance}}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="singlePopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="sureSave('singleRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="selectTeacherPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="matchingTeacherList"
            style="width: 100%; margin-bottom: 20px"
            border
            height="400px"
            tooltip-effect="dark"
            :row-key="getRowKeys"
            ref="resTeacherListTable"
            @selection-change="handleTeacherSelectionChange"
            @row-click="rowTeacherItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="polymerizationKeywords"
              label="课程级别"
              align="center"
              :formatter="courseLevelFormatter"
            ></el-table-column>
            <el-table-column
              prop="subjectName"
              label="学科"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="teacherRealname"
              label="讲师"
              align="center"
            ></el-table-column>
            <!-- <el-table-column prop="createTime" label="期望薪资" align="center">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="teachFee"
              label="讲师课酬"
              align="center"
            ></el-table-column> -->
            <!-- <el-table-column
              prop="classSinglePrice"
              label="课时单价"
              align="center"
            ></el-table-column> -->
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleTeacherNewSizeChange"
            @current-change="handleTeacherNewCurrentChange"
            :current-page="teacherParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="teacherParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="teacherTotalnew"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectTeacherPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="上课地址"
      :visible.sync="detailAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="btn-area">
          <div class="right">
            <el-button-group>
              <el-button type="default" size="small" @click="addAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                <span>新增</span>
              </el-button>
              <el-button type="default" size="small" @click="editAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-edit" />
                </svg>
                <span>编辑</span>
              </el-button>
              <el-button type="default" size="small" @click="removeAddress">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-trash" />
                </svg>
                <span>删除</span>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="addressListData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            tooltip-effect="dark"
            ref="addressListData"
            @selection-change="addressListDataChange"
            @row-click="addressListDataItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="地址" align="center">
              <template slot-scope="scope">
                <span
                  v-show="scope.row.isDefault == 1"
                  style="
                    padding: 5px 8px;
                    background: red;
                    color: #fff;
                    border-radius: 4px;
                  "
                  >默认</span
                >
                <span
                  >{{ scope.row.provinceName }}{{ scope.row.cityName
                  }}{{ scope.row.countyName
                  }}{{ scope.row.detailAddress }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChangeAddress"
            @current-change="handleCurrentChangeAddress"
            :current-page="classAddressData.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="classAddressData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalData"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="clickAddressSure()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="上课地址"
      :visible.sync="detailDetNamesPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="btn-area">
          <div class="right">
            <el-button-group>
              <el-button type="default" size="small" @click="addDetNames">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                <span>新增</span>
              </el-button>
              <el-button type="default" size="small" @click="editDetNames">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-edit" />
                </svg>
                <span>编辑</span>
              </el-button>
              <el-button type="default" size="small" @click="removeDetNames">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-trash" />
                </svg>
                <span>删除</span>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="detNamesListData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            tooltip-effect="dark"
            ref="detNamesListData"
            @selection-change="detNamesListDataChange"
            @row-click="detNamesListDataItemClick"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="地址" align="center">
              <template slot-scope="scope">
                <span
                  v-show="scope.row.isDefault == 1"
                  style="
                    padding: 5px 8px;
                    background: red;
                    color: #fff;
                    border-radius: 4px;
                  "
                  >默认</span
                >
                <span
                  >{{ scope.row.provinceName }}{{ scope.row.cityName
                  }}{{ scope.row.countyName
                  }}{{ scope.row.detailAddress }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChangeDetNames"
            @current-change="handleCurrentChangeDetNames"
            :current-page="detNamesData.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="detNamesData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detNamesTotal"
            background
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDetNamesPopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="clickDetNamesSure()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="addAddressParams.title"
      :visible.sync="addAddressPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveAddressForm"
          status-icon
          :rules="saveAddressRules"
          ref="saveAddressForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="所在地区">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveAddressForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="addressProvinceChange"
                >
                  <el-option
                    v-for="item in addressProvinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="addressCityChange"
                >
                  <el-option
                    v-for="item in addressCityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveAddressForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in addressCountyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-col :span="12">
              <el-input v-model="saveAddressForm.detailAddress"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="默认地址" prop="isDefault">
            <el-radio-group v-model="saveAddressForm.isDefault">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAddressPopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmAddressSave('saveAddressForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新增课程"
      :visible.sync="batchTimePopup"
      :close-on-click-modal="false"
      width="1300px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="batchTimeForm"
          status-icon
          ref="batchTimeForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="排课日期" prop="">
                <el-date-picker
                  size="small"
                  v-model="batchPickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="expireTimeOptionBefore"
                  @change="batchPickerChange"
                  value-format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="星期选择" prop="">
                <el-checkbox-group
                  v-model="checkedWeeks"
                  @change="handleCheckedWeeksChange"
                >
                  <el-checkbox
                    v-for="city in weeks"
                    :label="city.id"
                    :key="city.id"
                    >{{ city.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox
                  :indeterminate="isWeekIndeterminate"
                  v-model="checkWeekAll"
                  @change="weeksCheckAllChange"
                  >全选</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="产品类型" prop="classHourType">
                 <el-radio-group v-model="classHourStatusMore" @change="classMoreChange()">
                <el-radio label="1">一对一充值</el-radio>
                <el-radio label="2">一对一</el-radio>
              </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="收费类型" prop="classHourType">
                <el-select
                  v-model="batchTimeForm.classHourType"
                  placeholder="请选择"
                  @change="batchHourTypeChange"
                >
                  <el-option
                    v-for="item in classHourTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                     v-show="classHourStatusMore==item.show"
                  >
                  </el-option>
                </el-select>
                 <p v-show="batchTimeForm.classHourType == 3">
                    剩余金额：{{ accountBalance }}
                  </p>
                  <p v-show="batchTimeForm.classHourType == 5">
                    剩余课时：{{ remainingClassHours }}
                  </p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="classHourStatusMore==2">
            <el-col :span="10">
              <el-form-item label="产品名称" prop="sex">
                <el-select
                  v-model="newProductId"
                  placeholder="请选择"
                  size="small"
                  value-key="id"
                  @change="surplusNumFn2"
                >
                  <el-option
                    v-for="item in batchProductList"
                    :key="item.id"
                    :label="item.productName"
                    :value="item"
                  ></el-option>
                </el-select>
               <p v-show="batchTimeForm.classHourType == 1">
                剩余金额：{{ surplusNum2 }}
              </p>
              <p v-show="batchTimeForm.classHourType == 2">
                剩余课时：{{ surplusNum2 }}
              </p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="batchTimeForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          </el-row>
          
        </el-form>
        <el-button type="primary" @click="createCourse">生成课程</el-button>
        <el-table
          v-show="courseShow==true"
          size="small"
          :data="courseDate"
          style="width: 100%; margin-bottom: 20px; margin-top: 20px"
          row-key="id"
          border
          tooltip-effect="dark"
          highlight-current-row
        >
          <el-table-column
            prop="date"
            label="日期"
            align="center"
            width="110px"
          ></el-table-column>
          <el-table-column
            prop="orderType"
            label="星期"
            :formatter="dealIsDay"
            align="center"
            width="100px"
          >
          </el-table-column>
          <el-table-column prop="productName" label="科目" align="center">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="tableSubjectId"
                placeholder="可选科目"
                size="small"
                @change="tableChange()"
              >
                <el-option
                  v-for="item in singSubjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </template>
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.subjectId"
                placeholder="可选科目"
                size="small"
                @change="tableItemChange()"
              >
                <el-option
                  v-for="item in singSubjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="beginTime" label="上课时间" align="center">
            <template slot="header" slot-scope="scope">
              <el-time-select
                placeholder="起始时间"
                v-model="pickerData"
                :picker-options="{
                  start: '06:00',
                  step: '00:05',
                  end: '23:59',
                }"
                @input="datetimeChange()"
              >
              </el-time-select>
            </template>
            <template slot-scope="scope">
              <el-time-select
                placeholder="起始时间"
                v-model="scope.row.time"
                :picker-options="{
                  start: '06:00',
                  step: '00:05',
                  end: '23:59',
                }"
                @change="datetimeItemChange()"
              >
              </el-time-select>
            </template>
          </el-table-column>
          <el-table-column
            label="课时"
            align="center"
            visEdit="true"
            width="150px"
          >
            <template slot="header" slot-scope="scope">
              <el-input-number
                v-model="usedClassHour"
                :max="4"
                :min="0"
                @change="usedClassHourChange()"
                @input.native="usedClassHourInput($event)"
                clearable
                size="mini"
                placeholder="输入课时"
              />
            </template>
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.usedClassHour"
                :max="4"
                :min="0"
                clearable
                size="mini"
                placeholder="输入课时"
              />
            </template>
          </el-table-column>
          <el-table-column label="课程标题" align="center" visEdit="true">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="usedTitle"
                @input="usedTitleInput()"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.title"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
          </el-table-column>
          <el-table-column prop="teacherName" label="讲师" align="center">
            <template slot="header">
              <p @click="scopeTeacher">请选择讲师</p>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.teacherName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="classAddress" label="上课地址" align="center">
            <template slot="header">
              <p @click="scopeAddress">请选择上课地址</p>
            </template>
            <template slot-scope="scope">
              <span v-show="scope.row.classMode">
                {{ scope.row.classAddress }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="200px">
            <template slot-scope="scope">
              <div style="display: flex">
                <div style="width: 60%;text-align:left;">
                  <el-switch
                    v-model="scope.row.isFirstClass"
                    active-text="首次课"
                  >
                  </el-switch>
                  <el-switch
                    style="display: block"
                    v-model="scope.row.classMode"
                    @change="moreClassMode"
                    active-text="线下课"
                  >
                  </el-switch>
                  <el-switch v-model="scope.row.isPrisonClass"
                      active-text="是否监课"
                      active-value="1"
                      inactive-value="0">
                  </el-switch>
                  <el-switch v-model="scope.row.isPlayback"
                      active-text="是否回放"
                      active-value="1"
                      inactive-value="0">
                  </el-switch>
                </div>
                <div style="width: 40%;display:flex;align-items: center;justify-content: center;">
                  <i
                    class="el-icon-delete"
                    @click="deleteTableRow(scope)"
                    style="font-size: 30px; color: red"
                  ></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="price_tip">
        <div class="class_fee">本堂课流量费用：<span>{{singleRuleForm.expense}}</span>元 </div>
        <div class="account_fee">账户余额：{{organTotalBalance}}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchTimePopup = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sure()" :loading="batchLoading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="取消课程"
      :visible.sync="cancelCoursePopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="cancelCourseForm"
          status-icon
          ref="cancelCourseForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="学科" prop="sex">
                <el-select
                  v-model="cancelCourseForm.subjectId"
                  placeholder="请选择"
                  size="small"
                  @change="selectChanged"
                >
                  <el-option
                    v-for="item in cancelCourseSubjectList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="选择排课日期" prop="">
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm"
                  v-model="batchPickerValue2"
                  type="datetime"
                  :picker-options="startDatePicker"
                  @change="batchPickerChange2()"
                  style="display: inline-block"
                  placeholder="选择开始日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="">
                <el-date-picker
                  style="display: inline-block"
                  value-format="yyyy-MM-dd HH:mm"
                  v-model="batchPickerValue3"
                  type="datetime"
                  :picker-options="endDatePicker"
                  @change="batchPickerChange3()"
                  placeholder="选择结束日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="星期选择" prop="">
                <el-checkbox-group
                  v-model="checkedCities"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    v-for="city in cities"
                    :label="city.id"
                    :key="city.id"
                    >{{ city.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="weekCheckAllChange"
                  >全选</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button type="text" @click="queryCurse()">查询课程</el-button>
        <el-checkbox v-model="selectAll" @change="selectCheckAllChange"
          >全选</el-checkbox
        >
        <ul class="curseUl">
          <el-checkbox-group v-model="type_of_cost" class="checkGroup">
            <el-checkbox
              v-for="(items, indexs) in findCourseItemList"
              :key="indexs"
              :label="items.id"
              @change="selecteCheckedCitiesChange(items.id)"
            >
              {{ items.beginTime.split(" ")[0] }}
              {{ returnWeek(items.week) }}
              {{ returnTime(items) }}
              {{ items.teacherName }}
            </el-checkbox>
          </el-checkbox-group>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCoursePopup = false" size="small"
          >取 消</el-button
        >
        <el-button slot="reference" type="primary" size="small"
          @click="cancelFn()"
            >取 消 课 程
          </el-button>
       
      </span>
    </el-dialog>
    <el-dialog
      title="查看学员"
      :visible.sync="saveDialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
        <p
          style="color: #000; float: right; font-size: 18px; cursor: pointer"
          @click="editStudentInfo()"
        >
          编辑
        </p>
        <div class="info_div1"  >
          <p style="font-size:16px;color:#000;font-weight: bold;">学生信息</p>
          <ul>
            <li>
              <p>学生姓名</p>
              <p>{{ studentRuleForm.name?studentRuleForm.name:"~" }}</p>
            </li>
            <li>
              <p>联系电话</p>
              <p>{{ studentRuleForm.contact?studentRuleForm.contact:"~" }}</p>
            </li>
            <li>
              <p>性 别</p>
              <p>{{studentRuleForm.sex==1?"男":studentRuleForm.sex==2?'女':'~'}}</p>
            </li>
            <li>
              <p>年 级</p>
              <p>{{studentRuleForm.sectionName?studentRuleForm.sectionName:'~'}}</p>
            </li>
            <li>
              <p>出生日期</p>
              <p>{{studentRuleForm.birthday?studentRuleForm.birthday:'~'}}</p>
            </li>
             <li>
              <p>星 座</p>
              <p>{{seeConstellationId(studentRuleForm.constellationId)}}</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>家庭住址</p>
              <p>{{ studentRuleForm.provinceName?studentRuleForm.provinceName:'~' }}
                {{ studentRuleForm.cityName?studentRuleForm.cityName:'~'
              }}{{ studentRuleForm.countyName?studentRuleForm.countyName:'~' }}</p>
            </li>
             <li>
              <p>所在学校</p>
              <p>{{studentRuleForm.schoolName?studentRuleForm.schoolName:'~'}}</p>
            </li>
            <li>
              <p>所在区域</p>
              <p>{{studentRuleForm.schoolReach?studentRuleForm.schoolReach:'~'}}</p>
            </li>
            <li>
              <p>孩子性格</p>
              <p>{{studentRuleForm.childNature?studentRuleForm.childNature:'~'}}</p>
            </li>
            <li>
              <p>来源渠道</p>
              <p>{{sourceTypeFn(studentRuleForm.sourceType)}}</p>
            </li>
            <li>
              <p></p>
              <p></p>
            </li>
          </ul>
        </div>
        <div class="info_div1">
          <p style="font-size:16px;color:#000;font-weight: bold;margin-top:50px;">家长信息</p>
          <ul>
            <li>
              <p>母亲姓名</p>
              <p>{{ studentListDetail.childMotherName?studentListDetail.childMotherName:"~" }}</p>
            </li>
            <li>
              <p>母亲电话</p>
              <p>{{ studentListDetail.childMotherPhone?studentListDetail.childMotherPhone:"~" }}</p>
            </li>
            <li>
              <p>父亲姓名</p>
              <p>{{ studentListDetail.childFatherName?studentListDetail.childFatherName:"~" }}</p>
            </li>
            <li>
              <p>父亲电话</p>
              <p>{{ studentListDetail.childFatherPhone?studentListDetail.childFatherPhone:"~" }}</p>
            </li>
            <li>
              <p>微信/QQ</p>
              <p>{{ studentListDetail.parentWechat?studentListDetail.parentWechat:"~" }}</p>
            </li>
          </ul>
        </div>
        <p style="font-size:16px;color:#000;font-weight: bold;margin-top:50px;">空闲时间</p>
        <el-row :gutter="20" class="row_detail" align="bottom">
          <div class="class_title">
            <span
              @click="classTimeTyp = 1"
              :class="{ active: classTimeTyp == 1 }"
              style="cursor:pointer;font-weight:550;"
              >首次上课时间</span
            >
            <span
              @click="classTimeTyp = 2"
              :class="{ active: classTimeTyp == 2 }"
              class="mL20"
               style="cursor:pointer;font-weight:550;"
              >正式上课时间</span
            >
          </div>
          <div class="table_area" v-show="classTimeTyp == 1">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status" disabled>{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <div class="label_info">
              <span>上课时间备注：</span>
              <span>{{
                studentRuleForm.firstClassTimeRemark
                  ? studentRuleForm.firstClassTimeRemark
                  : "暂无数据"
              }}</span>
            </div>
          </div>
          <div class="table_area" v-show="classTimeTyp == 2">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status" disabled>{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <div class="label_info">
              <span>上课时间备注：</span>
              <span>{{
                studentRuleForm.formalClassTimeRemark
                  ? studentRuleForm.formalClassTimeRemark
                  : "暂无数据"
              }}</span>
            </div>
          </div>
        </el-row>
        <p style="font-size:16px;color:#000;font-weight: bold;padding-bottom:26px;margin-top:50px;">课程信息</p>
        <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
          <el-col :span="20">
            <span class="studentSmallTitle" >选择必考科目：</span>
            <template v-for="item in subjectList_info">
              <el-checkbox
                v-model="item.select"
                v-show="item.value == 'sys.teach.subject.base'"
                class="mR10"
                :key="item.macroId"
                disabled
                >{{ item.name }}</el-checkbox
              >
            </template>
          </el-col>
        </el-row>
        <div
          class="classInfo"
          v-for="item in subjectList_info"
          :key="item.macroId"
          v-show="item.status"
        >
          <p>
            <span style="font-size:16px;color:#1890FF;font-weight:bold;">{{ item.name }}</span>
            <el-button size="small">发送给老师</el-button>
          </p>
          <div>
            <span class="studentSmallTitle">设置讲师要求</span>
            <p>
              <span>性别偏好：{{ item.sexNeedId==1?'男老师':item.sexNeedId==2?'女老师':'均可'}}</span>
              <span>讲师教龄：{{ judgeAgeNeed(item.ageNeedId) }}</span>
              <span>讲课速度：{{ getTeachSpeed(item) }}</span>
              <span>风格偏好：{{ judgeStyleNeed(item.styleNeedId) }}</span>
            </p>
          </div>
          <div style="min-height:56px;background: #F3F9FF;border-radius: 3px;display:flex;align-items:center;">
            <span style="margin-left:25px;">学生成绩：</span>
            <ul>
              <li
                v-for="(items, indexs) in item.xmtStudentAcademicRecordList"
                :key="indexs"
                style="display:flex;align-items:center"
              >
                <span style="width: 15%">{{ items.examinationTime?items.examinationTime.substring(0,11):'' }}</span>
                <span style="width: 15%">{{
                  examinationTypeFn(items.examinationType)
                }}</span>
                <span style="width: 10%"
                  >{{ items.subjectScore ? items.subjectScore : "" }}/{{
                    items.subjectAllScore ? items.subjectAllScore : ""
                  }}</span
                >
                <span style="flex: 1" class="remarksSpan">{{
                  items.remarks
                }}</span>
                <el-button
                  style="width: 15%"
                  type="text"
                  @click="paperPreview(items)"
                  >试卷预览</el-button
                >
              </li>
            </ul>
          </div>
        </div>
        <p class="studentTitle" v-show="studentRuleForm.referralstudent">
          介绍人信息
        </p>
        <el-row
          :gutter="20"
          class="row_detail"
          type="flex"
          align="bottom"
          v-show="studentRuleForm.referralstudent"
        >
          <el-col :span="8">
            <span>老生姓名：</span>
            <span>{{
              studentRuleForm.referralstudent
                ? studentRuleForm.referralstudent
                : ""
            }}</span>
          </el-col>
          <el-col :span="8">
            <span>联系方式：</span>
            <span>{{
              studentRuleForm.referralphone ? studentRuleForm.referralphone : ""
            }}</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <p v-show="centerType == 'student'" style="padding: 7px 0">
        该时间段"{{ centerStudentName }}"学生已有课程安排
      </p>
      <p v-show="centerType == 'teacher'" style="padding: 7px 0">
        该时间段"{{ centerTeacherName }}"老师已有课程安排
      </p>
      <p style="padding: 7px 0">上课时间：{{ centerBeginTime }}</p>
      <p style="padding: 7px 0" v-show="centerTrue">机构：{{ centerOrganName }}</p>
      <p style="padding: 7px 0">上课时长：{{ centerUsedClassHour }}小时</p>
      <p style="padding: 7px 0">辅导老师：{{ centerTeacherName }}老师</p>
      <p style="padding: 7px 0">上课学生：{{ centerStudentName }}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="退费详情"
      :visible.sync="refundSync"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content studentInfo">
        <h2>学员信息</h2>
        <ul class="studentUl">
          <li>学生姓名：{{ studentDetailList.name }}</li>
          <li>性别：{{ studentDetailList.sex==1?'男':studentDetailList.sex==2?'女':'' }}</li>
          <li>
            年级：{{
              studentDetailList.sectionName
                ? studentDetailList.sectionName
                : "暂无数据"
            }}
          </li>
          <li>
            出生日期：{{
              studentDetailList.birthday
                ? studentDetailList.birthday
                : "暂无数据"
            }}
          </li>
          <li>
            星座：{{
              studentDetailList.constellationName
                ? studentDetailList.constellationName
                : "暂无数据"
            }}
          </li>
        </ul>
        <div class="orderDiv">
          <ul v-for="(item, indexs) in orderdDetail" :key="indexs">
            <li style="font-weight: 700; font-size: 16px">
              订单信息{{ indexs + 1 }}
            </li>
            <li>产品名称：{{ item.productName }}</li>
            <li>总课时：{{item.classHour?item.classHour:item.zdyClassHour}}课时</li>
            <li>总金额：{{item.totalPrice?item.totalPrice:item.zdyActualPrice}}元</li>
            <li>下单时间：{{ item.createTime }}</li>
            <li>订单编号：{{ item.orderNo }}</li>
            <ul
              v-for="(items, indexss) in item.xmtStudentRefundInfoChildList"
              :key="indexss"
            >
              <li>课程级别：{{ courseLevelFn(items.courseLevel) }}</li>
              <li>已上课时数：{{ items.countUsedClassHour }}</li>
              <li>课时单价：{{ items.classSinglePrice }}元</li>
              <li>
                课消金额：{{
                  classMoney(items.countUsedClassHour, items.classSinglePrice)
                }}元
              </li>
            </ul>
          </ul>
          <p style="margin-left: 150px">总计交费金额：{{ orderMoney }}元</p>
          <el-form
            :model="saveOrderForm"
            status-icon
            :rules="saveOrderRules"
            ref="saveOrderForm"
            label-width="100px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="消耗金额" prop="realityUsePrice">
                  <span>{{saveOrderForm.realityUsePrice}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应退款金额" prop="refundAmount">
                  <span>{{saveOrderForm.refundAmount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="实退款金额" prop="realityRefundAmount">
                  <el-input
                    v-model="saveOrderForm.realityRefundAmount"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="备注" prop="backReason">
                  <el-input
                    type="textarea"
                    v-model="saveOrderForm.backReason"
                    placeholder="例如：手续费、设备押金、课时费不正常等"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款姓名" prop="bankUserName">
                  <el-input
                    v-model="saveOrderForm.bankUserName"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款账号" prop="bankAccount">
                  <el-input
                    v-model="saveOrderForm.bankAccount"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="开户支行" prop="bankAddress">
                  <el-input
                    v-model="saveOrderForm.bankAddress"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="联系方式" prop="bankUserPhone">
                  <el-input
                    v-model="saveOrderForm.bankUserPhone"
                    placeholder="请输入内容"
                    :maxlength="11"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitExamine('saveOrderForm')"
          >提交审核</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="followUpDialog.title"
      :visible.sync="followUpDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormFollowUp"
          status-icon
          ref="saveRuleFormFollowUp"
          label-width="100px"
          size="small"
        >
          <el-form-item label="下次跟进时间" prop="nextFollowupTime">
            <el-date-picker
              class="time_selector"
              v-model="saveRuleFormFollowUp.nextFollowupTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :picker-options="expireTimeOptionBefore"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="跟进记录" prop="followContent">
            <el-input
                  type="textarea"
                  :rows="3"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入内容"
                  v-model="saveRuleFormFollowUp.followContent"
                >
                </el-input>
          </el-form-item>
          <el-form-item label="图片记录" prop="photo">
            <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in photoList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span

                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="imgItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="followUpDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSaveFollowUp('saveRuleFormFollowUp')"
          size="small"
          :disabled="isSaveFollow"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="saveLessonDialog.title"
      :visible.sync="saveLessonDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveLessonForm"
          status-icon
          :rules="saveLessonRules"
          ref="saveLessonForm"
          label-width="110px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="课单标题" prop="classListTitle">
                <el-input v-model="saveLessonForm.classListTitle" :maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年级" prop="sectionId">
                <el-select
                  v-model="saveLessonForm.sectionId"
                  placeholder="请选择"
                  size="small"
                  @change="sectionIdChange"
                >
                  <el-option
                    v-for="item in sectionLists"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="是否面议" prop="ifFaceToFace">
                <el-radio-group
                  v-model="saveLessonForm.ifFaceToFace"
                  @change="ifFaceToFaceChange()"
                >
                  <el-radio label="0">不面议</el-radio>
                  <el-radio label="1">面议</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="课酬"
                prop=""
                v-show="saveLessonForm.ifFaceToFace == 0"
              >
                <el-input
                  style="width: 70px !important"
                  v-model="saveLessonForm.minimumClassPay"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input
                >~
                <el-input
                  style="width: 70px !important"
                  v-model="saveLessonForm.maximumClassPay"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input
                >元/
                <el-input
                  style="width: 70px !important"
                  v-model="saveLessonForm.classHour"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input
                >课时
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="科目成绩" prop="">
                <el-input
                  style="width: 110px !important"
                  v-model="saveLessonForm.score"
                  :maxlength="3"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input>
                /
                <el-input
                  style="width: 110px !important"
                  v-model="saveLessonForm.totalScore"
                  :maxlength="3"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学科" prop="subjectId">
                <el-select
                  v-model="saveLessonForm.subjectId"
                  placeholder="请选择"
                  @change="subjectIdChange"
                  size="small"
                >
                  <el-option
                    v-for="item in subjectListEl"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in normalClassHoursList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生成绩类型" prop="studentAchievementId">
                <el-select
                  v-model="saveLessonForm.studentAchievementId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in studentAchievementList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="讲师教龄" prop="teachingAgeId">
                <el-select
                  v-model="saveLessonForm.teachingAgeId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in teachingAgeIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别偏好" prop="sexNeedId">
                <el-select
                  v-model="saveLessonForm.sexNeedId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sexNeedIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="普通话" prop="mandarinId">
                <el-select
                  v-model="saveLessonForm.mandarinId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in mandarinIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="风格偏好" prop="styleNeedId">
                <el-checkbox-group v-model="styleNeedGroup">
                  <el-checkbox
                    v-for="(item, index) in styleNeedArray"
                    :key="index"
                    :label="item.macroId"
                    :value="item.macroId"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="上课形式" prop="classForm">
                <el-select
                  v-model="saveLessonForm.formOfClass"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in classFormList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课单结束时间" prop="finishTime">
                <el-date-picker
                  size="small"
                  v-model="saveLessonForm.finishTime"
                  type="datetime"
                  placeholder="选择日期"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :default-value="new Date()"
                  :picker-options="finishTimeBefore"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="saveLessonForm.formOfClass=='0'||saveLessonForm.formOfClass=='2'">
            <el-col :span="20">
              <el-form-item label="详细地址" prop="addressDetNames">
                <el-input
                  @click.native="getAddressDetNames()"
                  readonly
                  v-model="saveLessonForm.addressDetNames"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="老师要求" prop="studentDesc">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="saveLessonForm.studentDesc"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="saveLessonForm.remarks"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="saveLesson('saveLessonForm', 0)"
          size="small"
          >保 存</el-button
        >
        <el-button @click="saveLessonDialogVisible = false" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="需求详情"
      :visible.sync="demandDialog"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>学科：</span>
              <span>{{ dealSubjectIdd(demandParams) }}</span>
            </el-col>
            <el-col :span="8">
              <span>科目成绩：</span>
              <span
                >{{
                  demandParams.subjectScore
                    ? demandParams.subjectScore
                    : "暂无数据"
                }}/{{
                  demandParams.subjectAllScore
                    ? demandParams.subjectAllScore
                    : "暂无数据"
                }}</span
              >
            </el-col>
            <el-col :span="8">
              <span>讲师职称：</span>
              <span>{{ getTeachTitleId(demandParams) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>讲师教龄：</span>
              <span>{{ getTeachingAgeId(demandParams) }}</span>
            </el-col>
            <el-col :span="8">
              <span>性别偏好：</span>
              <span>{{ getSexNeedId(demandParams) }}</span>
            </el-col>
            <el-col :span="8">
              <span>上课形式：</span>
              <span>{{ getClassForm(demandParams) }}</span>
            </el-col>
            
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>成绩类型：</span>
               <span>{{ getAchievement(demandParams) }}</span>
            </el-col>
            <el-col :span="8">
              <span>普通话：</span>
              <span>{{ getMandarinId(demandParams) }}</span>
            </el-col>
            <el-col :span="8">
              <span>排课次数：</span>
              <span>{{ getClassTimes(demandParams) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>优先排课时间：</span>
              <span
                >{{ getPriorTimeOne(demandParams) }}
                {{ getPriorTimeTwo(demandParams) }}
                {{ getPriorTimeThree(demandParams) }}</span
              >
            </el-col>
            <el-col :span="8">
              <span>优先排课日期：</span>
              <span
                >{{ getPriorDateOne(demandParams) }}
                {{ getPriorDateTwo(demandParams) }}
                {{ getPriorDateThree(demandParams) }}</span
              >
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
            <el-col :span="8">
              <span>老师要求：</span>
              <span>{{
                demandParams.studentDescribe
                  ? demandParams.studentDescribe
                  : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>备注：</span>
              <span>{{
                demandParams.remark ? demandParams.remark : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_details" type="flex" align="bottom">
             <el-col :span="20">
              <span>风格偏好：</span>
              <span style="width:100%;line-height:1.5;" v-html="getStyleNeedId(demandParams.styleNeedId)"></span>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="demandDialog = false" size="small"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="编辑学生信息"
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="editRuleForm"
          status-icon
          :rules="editRules"
          ref="editRuleForm"
          label-width="100px"
          size="small"
        >
          <div class="info-title">学生信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生姓名" prop="name">
                <el-input v-model="editRuleForm.name" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contact">
                <el-input
                  v-model="editRuleForm.contact"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="editRuleForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年级" prop="sex">
                <el-select
                  v-model="editRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所在学校" prop="schoolName">
                <el-input v-model="editRuleForm.schoolName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学校所在区域" prop="schoolReach">
                <el-input v-model="editRuleForm.schoolReach"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="来源渠道" prop="sourceType">
                <el-select
                  v-model="editRuleForm.sourceType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sourceTypeList2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="孩子性格" prop="childNature">
                <el-input v-model="editRuleForm.childNature"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="editRuleForm.sourceType == '5'">
            <el-col :span="12">
              <el-form-item label="转介绍人姓名" prop="referralstudent">
                <el-input
                  v-model="editRuleForm.referralstudent"
                  :maxlength="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转介绍人电话" prop="referralphone">
                <el-input
                  v-model="editRuleForm.referralphone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  :picker-options="expireTimeOption"
                  v-model="editRuleForm.birthday"
                  type="date"
                  @change="editBirthdayChange"
                  :default-value="new Date('2003-01-01')"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="星座" prop="constellationId">
                <el-select
                  :disabled="true"
                  v-model="editRuleForm.constellationId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in constellationList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="家庭住址">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="editRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="editProvinceChange"
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="editRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="editCityChange"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="editRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="学生其他需求">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  maxlength="500"
                  v-model="editRuleForm.studentsOtherNeeds">
                </el-input>
          </el-form-item>
          <div class="info-title">家长信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="母亲姓名" prop="childMotherName">
                <el-input v-model="editRuleForm.childMotherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="母亲电话" prop="childMotherPhone">
                <el-input
                  v-model="editRuleForm.childMotherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="父亲姓名" prop="childFatherName">
                <el-input
                  v-model="editRuleForm.childFatherName"
                  maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父亲电话" prop="childFatherPhone">
                <el-input
                  v-model="editRuleForm.childFatherPhone"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="微信/QQ" prop="parentWechat">
            <el-input v-model="editRuleForm.parentWechat"></el-input>
          </el-form-item>
          <div class="info-title">空闲时间</div>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="firstClassTimeRemark">
              <el-input v-model="editRuleForm.firstClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="formalClassTimeRemark">
              <el-input v-model="editRuleForm.formalClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div>
            <div class="info-title">课程信息</div>
            <el-tabs stretch>
              <el-tab-pane label="文化课">
                <div class="required_subject left">
                  <span>选择必考科目：</span>
                  <template v-for="item in subjectList">
                    <el-checkbox
                      v-model="item.select"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      >{{ item.name }}</el-checkbox
                    >
                  </template>
                </div>
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="小语种">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.language'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="艺术类">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.art'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="select_subject">
              <div
                class="select_subject_item"
                v-for="(item, index) in subjectList"
                :key="index"
                v-show="item.status"
              >
                <div class="item_subject_title left" style="margin-top: 30px;display:flex;align-items: center;justify-content: space-between;">
                  <span>{{ item.name }}</span>
                  <el-button type="danger" size="small" @click="removeSubject(index,item.id)">删除</el-button>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="讲课速度">
                      <el-select
                        v-model="item.speedNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSpeedList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别偏好" prop="sexNeedId">
                      <el-select
                        v-model="item.sexNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSexList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="年龄偏好" prop="ageNeedId">
                      <el-select
                        v-model="item.ageNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachYearList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="风格偏好" prop="styleNeedId">
                      <el-select
                        v-model="item.styleNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachStyleList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <p style="font-weight: 600; font-size: 16px; margin-left: 30px">
                  学生成绩<el-button
                    size="mini"
                    type="primary"
                    @click="addStudentResult(item, index)"
                    style="margin-left: 10px"
                    >添加</el-button
                  >
                </p>
                <ul class="paperUl">
                  <li
                    v-for="(item, indexs) in item.xmtStudentAcademicRecordList"
                    :key="indexs"
                  >
                    <span>{{ examinationTimeFn(item.examinationTime) }}</span>
                    <span>{{ examinationTypeFn(item.examinationType) }}</span>
                    <span
                      >{{ item.subjectScore }}/{{ item.subjectAllScore }}</span
                    >
                    <span>{{ item.remarks }}</span>
                    <span>
                      <span
                        style="color: #1890ff; cursor: pointer"
                        @click="paperPreview(item)"
                        >预览</span
                      >
                      <span
                        style="color: #1890ff; cursor: pointer; padding: 0 5px"
                        @click="editStudent(item)"
                        >编辑</span
                      >
                      <span
                        style="color: #1890ff; cursor: pointer"
                        @click="paperRemove(item,index,indexs)"
                        >删除</span
                      >
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmEdit('editRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="接单老师"
      :visible.sync="orderReceivingPopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <el-table
          size="small"
          :data="orderReceivingData"
          style="width: 100%; margin-bottom: 20px"
          border
           :row-key="getRowKeys"
          tooltip-effect="dark"
          ref="teacherListTable"
          @selection-change="orderSelectionChange"
          @row-click="orderReceivingClick"
        >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
         <el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div class="teachContent">
              <div class="infoDetails">
                <dl class="left">
                  <dt><img :src="scope.row.photo" alt="" style="width:90px;height:90px;margin:0 10px"> </dt>
                  <dd >
                    <el-tooltip
                 
                  effect="dark"
                  :content="aa + getNum + bb"
                  placement="top"
                >
                  <p
                   @click.stop="dealEvaluate(scope.row)"
                    @mouseenter="seeMany(scope.row)"
                    style="display:inline-block;font-size:18px;cursor:pointer"
                  >
                    {{ scope.row.realname }}<span style="font-size:14px;margin-left:10px;">{{onLineFlagFn(scope.row.onLineFlag)}}</span>
                  </p>
                </el-tooltip>
                  <p >
                   <el-tag  size='small' v-if="scope.row.sexId == 1">男</el-tag>
                   <el-tag size='small' v-if="scope.row.sexId == 2">女</el-tag>
                   <el-tag size='small'>{{ titleFn(scope.row.teachTitleId) }}</el-tag>
                   <el-tag size='small'>{{ identityFn(scope.row.identityType) }}</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                    <el-tag size='small'>{{ subjectFn(scope.row.subjectId) }}</el-tag>
                    <el-tag size='small'>{{ dealSectionId(scope.row.sectionId) }}</el-tag>
                     
                    <el-tag size='small' type="success" v-if="scope.row.isEquipment==1">有设备</el-tag>
                    <el-tag size='small'  v-else-if="scope.row.isEquipment!=1">无设备</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                  <el-tag size='small' type="warning" v-for="(item,key) in scope.row.arr" :key='key'>{{item.name}}</el-tag>
                  </p>
                  <p style="margin-top:10px;" >
                      分组：
                      <span v-show="scope.row.concernType==0">未关注</span>
                      <span v-show="(scope.row.concernType==1||scope.row.concernType==3)&&scope.row.groupName==null">未分组</span>
                      <span v-show="scope.row.groupName">{{ scope.row.groupName}}</span>
                  </p>
                  </dd>
                </dl>
                <div class="content">
                    <ul>
                      <li><span>授课方式：</span><span>{{classFormFn(scope.row.classForm)}}</span></li>
                      <li><span>擅教学生：</span><span  >{{judgeStyleNeedFns(scope.row.skilfulTeach)}}</span></li>
                      <li><span>讲课风格：</span><span  >{{skilfulFn(scope.row.skilful)}}</span></li>
                    </ul>
                </div>
                <div class="right">
                </div>
              </div>  
            </div>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="page-area">
        <el-pagination
          @size-change="orderSizeChange"
          @current-change="orderCurrentChange"
          :current-page="orderReceivingParams.pageNumber"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="orderReceivingParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="orderReceivingTotal"
          background
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="addReceivingNow(1)">添加配置讲师</el-button>
        <el-button size="small" @click="addReceivingNow(2)">添加预配置讲师</el-button>
        <el-button size="small" @click="orderReceivingPopup=false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="teacherDetailPopup"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="teacherInfo">
          <dl>
            <dt>
              <img
                :src="teacherDetail.photo"
                alt=""
                style="width: 110px; height: 110px; border-radius: 50%"
              />
            </dt>
            <dd>
              <p>
                {{ teacherDetail.name
                }}<span
                  style="margin-left: 10px; font-size: 15px"
                  v-show="teacherDetail.sexId == 1"
                  >男</span
                ><span
                  style="margin-left: 10px; font-size: 15px"
                  v-show="teacherDetail.sexId == 2"
                  >女</span
                >
              </p>
              <p style="margin-top: 16px; font-size: 16px">
                {{ getSectionIdDetail(teacherDetail) }}
              </p>
              <p style="margin-top: 16px; fotn-size: 16px">
                共有{{ getNum }}位教务关注
              </p>
            </dd>
          </dl>
        </div>
        <el-tabs
          v-model="modelName"
          type="card"
          @tab-click="modelHandleClick"
          style="margin-top: 40px"
        >
          <el-tab-pane label="讲师信息" name="first">
            <p class="title_p">基本信息</p>
            <ul class="teacher_ul">
              <li>
                <span>姓名：{{ teacherDetail.realname }}</span>
                <span
                  >毕业院校：{{
                    teacherDetail.graduationSchool
                      ? teacherDetail.graduationSchool
                      : "暂无数据"
                  }}</span
                >
                <span>学历：{{ educationFn(teacherDetail) }}</span>
              </li>
              <li>
                <span
                  >出生日期：{{
                    teacherDetail.birthday ? teacherDetail.birthday.substring(0,11) : "暂无数据"
                  }}</span
                >
                <span>教龄：{{ teachingAgeFn(teacherDetail) }}</span>
                <span
                  >任职学校：{{
                    teacherDetail.schoolName
                      ? teacherDetail.schoolName
                      : "暂无数据"
                  }}</span
                >
              </li>
              <li>
                <span>讲师职称：{{ titleFn(teacherDetail.teachTitleId) }}</span>
                <span
                  >籍贯：{{  teacherDetail.provinceName? teacherDetail.provinceName+teacherDetail.cityName+teacherDetail.countyName: "暂无数据"}}</span>
                <span>面试地点：{{  teacherDetail.currentProvinceName? teacherDetail.currentProvinceName+teacherDetail.currentCityName+teacherDetail.currentCountyName: "暂无数据"}}</span>
              </li>
              <li>
                <span>讲师身份：{{ identityTypeFn(teacherDetail) }}</span>
                <span> 擅教学生：{{ skilfulFn(teacherDetail.skilful) }} </span>
              </li>
              <li>
                <span
                  >教学特点：{{
                    teacherDetail.teachTrait
                      ? teacherDetail.teachTrait
                      : "暂无数据"
                  }}</span
                >
              </li>
            </ul>
            <p class="title_p">面试答题</p>
            <ul class="answer_ul">
              <li v-for="(item, index) in answerList" :key="index">
                <p>问题{{ index + 1 }}：{{ item.questionTitle }}？</p>
                <p>答：{{ item.answer }}</p>
              </li>
            </ul>
            <p class="title_p">面试评分</p>
            <el-tag effect="plain" style="margin-top: 30px">录入员评价</el-tag>
            <ul class="teacherFace_ul">
              <li>
                <p>
                  整体形象：<span> {{ overallScoreFn(teacherFaceMark1) }}</span>
                </p>
                <p>
                  形象气质：<span>{{ appearance(teacherFaceMark1) }}</span>
                </p>
                <p>
                  沟通表达：<span>{{ connectCapacity(teacherFaceMark1) }}</span>
                </p>
                <p>
                  教学特色：<span>{{
                    teachingCharacteristics(teacherFaceMark1)
                  }}</span>
                </p>
              </li>
              <li>
                <p>
                  在京代课经验：<span>{{
                    lectureSpeed(teacherFaceMark1)
                  }}</span>
                </p>
                <p>
                  教学经验：<span>{{ lectureStyle(teacherFaceMark1) }}</span>
                </p>
                <p>
                  主要代课区域：<span>{{
                    specialtySkil(teacherFaceMark1)
                  }}</span>
                </p>
                <p>
                  合作求职态度：<span>{{
                    jobHuntingAttitude(teacherFaceMark1)
                  }}</span>
                </p>
              </li>
              <li>
                <p>
                  录入员意见：<span>{{ teacherFaceMark1.overallEvaluation }}</span>
                </p>
              </li>
              <li>
                <p>
                  其他合作机构：<span>{{
                    teacherFaceMark1.otherPartnersOne
                  }}</span
                  ><span style="margin-left: 20px">{{
                    teacherFaceMark1.otherPartnersTwo
                  }}</span
                  ><span style="margin-left: 20px">{{
                    teacherFaceMark1.otherPartnersThree
                  }}</span>
                </p>
              </li>
            </ul>
            <el-tag effect="plain" style="margin-top: 30px">审核员评价</el-tag>
            <ul class="teacherFace_ul">
              <li>
                <p>
                  整体形象：<span> {{ overallScoreFn(teacherFaceMark2) }}</span>
                </p>
                <p>
                  形象气质：<span>{{ appearance(teacherFaceMark2) }}</span>
                </p>
                <p>
                  沟通表达：<span>{{ connectCapacity(teacherFaceMark2) }}</span>
                </p>
                <p>
                  教学特色：<span>{{
                    teachingCharacteristics(teacherFaceMark2)
                  }}</span>
                </p>
              </li>
              <li>
                <p>
                  在京代课经验：<span>{{
                    lectureSpeed(teacherFaceMark2)
                  }}</span>
                </p>
                <p>
                  教学经验：<span>{{ lectureStyle(teacherFaceMark2) }}</span>
                </p>
                <p>
                  主要代课区域：<span>{{
                    specialtySkil(teacherFaceMark2)
                  }}</span>
                </p>
                <p>
                  合作求职态度：<span>{{
                    jobHuntingAttitude(teacherFaceMark2)
                  }}</span>
                </p>
              </li>
              <li>
                <p>
                  录入员意见：<span>{{ teacherFaceMark2.overallEvaluation }}</span>
                </p>
              </li>
              <li>
                <p>
                  其他合作机构：<span>{{
                    teacherFaceMark1.otherPartnersOne
                  }}</span
                  ><span style="margin-left: 20px">{{
                    teacherFaceMark1.otherPartnersTwo
                  }}</span
                  ><span style="margin-left: 20px">{{
                    teacherFaceMark1.otherPartnersThree
                  }}</span>
                </p>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="评价详情" name="second">
            <el-tabs v-model="evaluateName" @tab-click="evaluateHandleClick">
              <el-tab-pane label="教务评价" name="1">
                <ul class="evaluateUl">
                  <li
                    :class="{ evaluateActive: evaluateActive == 0 }"
                    @click="evaluateActiveClick(0)"
                  >
                    全部{{ evaluateUlData.allCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive == 3 }"
                    @click="evaluateActiveClick(3)"
                  >
                    好评{{ evaluateUlData.praiseCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive == 2 }"
                    @click="evaluateActiveClick(2)"
                  >
                    中评{{ evaluateUlData.chinaReviewCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive == 1 }"
                    @click="evaluateActiveClick(1)"
                  >
                    差评{{ evaluateUlData.negativeCommentCount }}
                  </li>
                </ul>
                <el-table
                  size="small"
                  :data="evaluateTable"
                  style="width: 100%; margin-bottom: 20px; margin-top: 20px"
                  row-key="userId"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column prop="name" label="">
                    <template slot-scope="scope">
                      <div>
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <p style="display: flex; align-items: center">
                            <span style="font-size: 16px">{{
                              scope.row.teachManagerName
                            }}</span>
                            <el-rate
                              v-model="scope.row.evaluationStar"
                              style="margin-left: 15px"
                            ></el-rate
                            ><el-tag style="margin-left: 15px" type="warning">{{
                              scope.row.evaluationTag
                            }}</el-tag>
                          </p>
                          <p>{{ scope.row.createTime }}</p>
                        </div>
                        <p>{{ scope.row.evaluationContent }}</p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="page-area">
                  <el-pagination
                    @size-change="evaluatedSizeChange"
                    @current-change="evaluatedCurrentChange"
                    :current-page="newPageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="newPageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="evaluateTotal"
                    background
                  ></el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="学员评价" name="2">
                <ul class="evaluateUl">
                  <li
                    :class="{ evaluateActive: evaluateActive2 == 0 }"
                    @click="evaluateActiveClick2(0)"
                  >
                    全部{{ evaluateUlData2.allCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive2 == 3 }"
                    @click="evaluateActiveClick2(3)"
                  >
                    好评{{ evaluateUlData2.praiseCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive2 == 2 }"
                    @click="evaluateActiveClick2(2)"
                  >
                    中评{{ evaluateUlData2.chinaReviewCount }}
                  </li>
                  <li
                    :class="{ evaluateActive: evaluateActive2 == 1 }"
                    @click="evaluateActiveClick2(1)"
                  >
                    差评{{ evaluateUlData2.negativeCommentCount }}
                  </li>
                </ul>
                <el-table
                  size="small"
                  :data="evaluateTable2"
                  style="width: 100%; margin-bottom: 20px; margin-top: 20px"
                  row-key="userId"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column prop="name" label="">
                    <template slot-scope="scope">
                      <div>
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <p style="display: flex; align-items: center">
                            <span style="font-size: 16px">{{
                              scope.row.teachManagerName
                            }}</span>
                            <el-rate
                              v-model="scope.row.evaluationStar"
                              disabled
                              show-score
                              style="margin-left: 15px"
                            ></el-rate
                            ><el-tag style="margin-left: 15px" type="warning">{{
                              scope.row.evaluationTag
                            }}</el-tag>
                          </p>
                          <p>{{ scope.row.createTime }}</p>
                        </div>
                        <p>{{ scope.row.evaluationContent }}</p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="page-area">
                  <el-pagination
                    @size-change="evaluatedSizeChange2"
                    @current-change="evaluatedCurrentChange2"
                    :current-page="newPageNumber2"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="newPageSize2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="evaluateTotal2"
                    background
                  ></el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="上课详情" name="2">
            <ul class="classInfoUl">
              <li v-for="(item, index) in classInfoData" :key="index">
                <p>{{ item.type }}</p>
                <p>
                  <span>总课时数：{{ item.classCount }}课时</span
                  ><span>总学生数：{{ item.studentCount }}人</span
                  ><span
                    >首次课作废课时数：{{ item.cancelClassCount }}课时</span
                  >
                </p>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="teacherDetailPopup = false"
          size="small"
          type="primary"
          >返 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="studentResultDialog.title"
      :visible.sync="studentResultSync"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="studentRuleForm"
          status-icon
          :rules="studentResultRules"
          ref="studentRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间" prop="examinationTime">
                <el-date-picker
                  class="time_selector"
                  v-model="studentRuleForm.examinationTime"
                  type="date"
                  :picker-options="expireTimeOption"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型" prop="examinationType">
                <el-select
                  v-model="studentRuleForm.examinationType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in examinationTypeList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="成绩" prop="subjectScore">
                <el-input v-model="studentRuleForm.subjectScore"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分" prop="subjectAllScore">
                <el-input v-model="studentRuleForm.subjectAllScore"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="备注说明" prop="remarks">
                <el-input v-model="studentRuleForm.remarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="试卷上传" prop="testPapers">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :file-list="testPapers"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  multiple
                  :on-remove="handlePapersRemove"
                  :limit="8"
                  :on-success="handlePapersSuccess"
                  ><i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentResultSync = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmStudent('studentRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      title="试卷预览"
      :visible.sync="previewDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content" style="margin-top: 30px">
        <el-form
          :model="previewRuleForm"
          status-icon
          :rules="previewResultRules"
          ref="previewRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间：" prop="examinationTime">
                {{ previewRuleForm.examinationTime }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型：" prop="examinationType">
                {{ examinationTypeFn(previewRuleForm.examinationType) }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="成绩：" prop="subjectScore">
                {{ previewRuleForm.subjectScore }}分
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分：" prop="subjectAllScore">
                {{ previewRuleForm.subjectAllScore }}分
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="备注说明：" prop="remarks">
                {{ previewRuleForm.remarks }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="试卷上传：" prop="testPapers">
                <el-image
                  v-for="(ele, index) in previewImage"
                  :key="index"
                  style="width: 100px; height: 100px; margin-right: 10px"
                  :src="ele"
                  :preview-src-list="getPreviewImgList(index)"
                >
                </el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="请选择删除原因"
      :visible.sync="removeClassVisible"
      :close-on-click-modal="false"
      width="400px"
      center
    > 
      <div >
        <div class="item_select" style="padding:30px 0;">
            <el-radio v-model="removeClassRadio" label="1">学生原因</el-radio>
            <el-radio v-model="removeClassRadio" label="2">老师原因</el-radio>
            <el-radio v-model="removeClassRadio" label="3">其他原因</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeClassVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small" @click="sureRemoveClass()"
          >确 定</el-button
        >
      </span>

    </el-dialog>
    
    <el-dialog
      title="请选择删除原因"
      :visible.sync="removeBatchClassVisible"
      :close-on-click-modal="false"
      width="400px"
      center
    > 
      <div >
        <div class="item_select" style="padding:30px 0;">
            <el-radio v-model="removeBatchClassRadio" label="1">学生原因</el-radio>
            <el-radio v-model="removeBatchClassRadio" label="2">老师原因</el-radio>
            <el-radio v-model="removeBatchClassRadio" label="3">其他原因</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeBatchClassVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small" @click="sureBatchRemoveClass()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="定向选择讲师"
      :visible.sync="orientPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-button type="primary" @click="orientSure">确定发送</el-button>
        <ul>
        <li v-for="(item,index) in orientList" :key="index" @click="handleTit(index)">
          <div class="dp_title">
            <input
              type="checkbox"
              v-model="item.flag"
               @change.stop="handleTitAll(item,index)"
            /><span style="margin-left:10px;font-size:16px;">{{item.name}}</span>
          </div>
          <div  v-show="entitiesState[index]" class="dp_item" v-for="(items,indexs) in item.data" :key="indexs">
            <input type="checkbox" v-model="items.flag" @change="dp_item(item)"  @click.stop=""/>
            <div class="infoDetails" >
                    <dl>
                      <dt><img :src="items.photo" alt="" style="width:80px;height:80px;margin:0 10px"></dt>
                      <dd>
                        <p style="font-size:18px;">{{items.name}}</p>
                        <p style="margin-top:15px;">
                          <span >{{ageFn(items.birthday)}}</span>
                          <span v-if="items.sexId == 1">男</span>
                          <span v-if="items.sexId == 2">女</span>
                          <span>{{ educationIdFn(items.educationId) }}</span>
                          <span>{{ identityTypeFn(items) }}</span>
                          
                        </p>
                        <p style="margin-top:10px;">
                          <span>学科：{{ subjectFn(items.subjectId) }}</span>
                          <span>年级：{{ dealSectionId(items.sectionId) }}</span>
                        </p>
                      </dd>
                    </dl>
                  </div>  
          </div>
        </li>
      </ul>
      </div>
    </el-dialog>
    <el-dialog
      title="设置空闲时间"
      :visible.sync="freePopup"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="freeRuleFormFollowUp"
          status-icon
          ref="freeRuleFormFollowUp"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择日期" prop="">
                <el-date-picker
                  size="small"
                  v-model="freePickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="expireTimeOptionBefore"
                  @change="freePickerChange"
                  value-format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="星期选择" prop="">
                <el-checkbox-group
                  v-model="freeWeeks"
                  @change="freeCheckedWeeksChange"
                >
                  <el-checkbox
                    v-for="city in weeks"
                    :label="city.id"
                    :key="city.id"
                    >{{ city.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox
                  :indeterminate="isFreeIndeterminate"
                  v-model="freeWeekAll"
                  @change="freeCheckAllChange"
                  >全选</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择时间" prop="">
                <el-select v-model="value1" multiple placeholder="请选择">
                  <el-option
                    v-for="item in classHoursList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="freePopup = false" size="small"
          >取 消</el-button
        >
        <el-button slot="refereaddStudyPopupnce" type="primary" size="small"
          @click="sureSaveFree()"
            >确 定
          </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="addStudyParams.title"
      :visible.sync="addStudyPopup"
      width="700px"
      @close='closeDialog'
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="studyRuleForm"
          status-icon
          :rules="studyRules"
          ref="studyRuleForm"
          label-width="110px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="开始上课时间" prop="name">
                <el-date-picker
                  v-model="startTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                   @change="startTimeChange()"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="结束上课时间" prop="name">
                <el-date-picker
                  v-model="endTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                   @change="endTimeChange()"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择报告的科目" prop="name">
                <el-select v-model="reportValue" multiple  @change="reportChangeSubject()" @blur="reportBlur()" placeholder="请选择">
                  <el-option
                    v-for="item in subjectidList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="info-title2"> <el-checkbox v-model="comparisonClassHours"></el-checkbox><span style="margin-left:10px;">上课时长对比</span><span style="color:red;">（必填项）</span></div>
          <div style="margin-top:10px">
            <div class="infoDiv" style="margin-left:25px;">
              <span  v-for="(item,index) in subjectData" :class="{reportActive:reportNum==index}" v-show="item.flag" @click="comparisonClassClick(item.macroId,index,item,1)" :id="'nav' + index" :ref="'nav' + index" v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" :key="index" type="success" plain size="small" >{{item.name}}</span>
            </div>
              <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum==index" style="margin-top:30px;">
                  <!-- <span style="display:inline-block;margin-right:-10px;color:red;">*</span> -->
                  <el-form-item label="平均上课时长" prop="name" style="display:inline-block;">
                    
                    <input  class="el-input__inner" style="width:80px;" @blur="averageClassHoursInput(index)" @input="averageClassChange(item.classHours,index)"  v-model="subjectData[index].classHours"></input>     课时
                  </el-form-item>
              </div>
              <div id="comparisonClassHoursChart" ref="comparisonClassHoursChart" style="width: 600px;height:400px;"></div>
            </div>
            <div class="info-title2"><el-checkbox v-model="classroomFeedback"></el-checkbox><span style="margin-left:10px;">课堂反馈</span></div>
            <div class="infoDiv" style="margin-left:25px;margin-top:10px;">
              <span @click="comparisonClassClick(item.macroId,index,item,2)"  v-for="(item,index) in subjectData"  :class="{reportActive:reportNum3==index}" v-show="item.flag"  :id="'feedback' + index" :key="index"  v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" type="success" plain size="small" >{{item.name}}</span>
            </div>
             <p style="font-size:15px;color:#666;font-weight:600;margin-left:25px;margin-top:30px;">课堂评价</p>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生在课堂听课的认真程度</p>
             <div id="feedbackChart" ref="feedbackChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">上次课作业的完成程度</p>
             <div id="teaFinishChart" ref="teaFinishChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生与老师的互动效果</p>
             <div id="teaInteractionChart" ref="teaInteractionChart" style="width: 600px;height:420px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生对本堂课内容的掌握程度</p>
             <div id="teaUnderstandChart" ref="teaUnderstandChart" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">作业情况</p>
             <div id="homeWorkDownloadChart" ref="homeWorkDownloadChart" style="width: 600px;height:400px;"></div>
           <div class="info-title2"><el-checkbox v-model="cancellationOfClass"></el-checkbox><span style="margin-left:10px;">取消课情况</span></div>
            <div class="infoDiv" style="margin-left:25px;margin-top:10px;">
              <span  v-for="(item,index) in subjectData" @click="comparisonClassClick(item.macroId,index,item,3)" :class="{reportActive:reportNum4==index}" v-show="item.flag"  :id="'cancelClass' + index"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <div id="cancellationOfClassChart" ref="cancellationOfClassChart" style="width: 600px;height:400px;"></div>
            <div class="info-title2"><el-checkbox v-model="followUpClassPlan"></el-checkbox><span style="margin-left:10px;">后续上课计划</span><span style="color:red;">（必填项）</span></div>
            <div class="infoDiv" style="margin-left:25px;margin-top:10px;">
              <span  v-for="(item,index) in subjectData" @click="stuComparisonClick(item.macroId,index)" :class="{reportActive:reportNum2==index}" v-show="item.flag"  v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum2==index"  style="margin-top:30px;">
                  <!-- <span style="display:inline-block;color:red;margin-right:-10px;">*</span> -->
                  <el-form-item label="课时数" prop="name" >
                    <input class="el-input__inner" style="width:90px;" maxlength="3" v-model="subjectData[index].stuComparisonClassHours"></input>   课时
                  </el-form-item>
              <el-row :gutter="20" >
                <el-col :span="20">
                  <el-form-item label="计划内容" prop="name">
                    <input
                    :rows="3"
                    class="el-input__inner"
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="subjectData[index].plan">
                </input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="info-title2"><el-checkbox v-model="adviceToParents"></el-checkbox><span style="margin-left:10px;">给家长的建议</span></div>
                  <el-input
                    type="textarea"
                    show-word-limit
                    :rows="3"
                    maxlength="500"
                    placeholder="请输入内容"
                    v-model="parentProposal">
                </el-input>
          <div class="info-title2"><el-checkbox v-model="suggestionsForParticipants"></el-checkbox><span style="margin-left:10px;">给学员的建议</span></div>
                  <el-input
                    type="textarea"
                    show-word-limit
                    :rows="3"
                    maxlength="500"
                    placeholder="请输入内容"
                    v-model="stuProposal">
                </el-input>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStudyPopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="saveReport('studyRuleForm')"
          size="small"
          :disabled="isSaveDisable"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     
    <div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
      <i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
     <el-dialog
      title="学习报告预览"
      :visible.sync="seeStudyPopup"
      width="700px"
      :close-on-click-modal="false"
      center
      @close='closeDialog'
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="student_info">
          <dl>
            <dt><img  style="width: 88px; height: 88px;border-radius:50%; " :src="updateImg.teachManagerPhoto"> </dt>
            <dd>
              <p>{{studentListDetail.name}}</p>
              <p>{{ studentListDetail.sectionName?studentListDetail.sectionName:"~" }}</p>
              <p style="margin-top:10px;">{{startTime?startTime.substring(0,11):''}}~{{endTime?endTime.substring(0,11):''}}</p>
            </dd>
          </dl>
          <p> <el-button type="primary">学习报告</el-button></p>
        </div>
       <div class="census_div">
            <p>上课时长对比</p>
            <div style="margin-top:20px;">
            <div class="infoDiv" style="margin-left:25px;">
              <span  v-for="(item,index) in subjectData" :class="{reportActive:reportNum==index}" v-show="item.flag" @click="comparisonClassClick(item.macroId,index,item,1)" :id="'nav' + index" v-hClick="{index:index,macroId:item.macroId,flag:item.flag}" :key="index" type="success" plain size="small" >{{item.name}}</span>
            </div>
              <div id="comparisonClassHoursCharts" ref="comparisonClassHoursCharts" style="width: 600px;height:400px;"></div>
            </div>
        </div>
        <div class="census_div">
            <p>课堂反馈</p>
            <div class="infoDiv" style="margin-left:25px;margin-top:30px;">
              <span @click="comparisonClassClick(item.macroId,index,item,2)"  v-for="(item,index) in subjectData"  :class="{reportActive:reportNum3==index}" v-show="item.flag"  :id="'feedback' + index" :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
            <p style="font-size:15px;color:#666;font-weight:600;margin-left:25px;margin-top:30px;">课堂评价</p>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生在课堂听课的认真程度</p>
            
            <div id="feedbackCharts" ref="feedbackCharts" style="width: 600px;height:400px;"></div>
            <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">上次课作业的完成程度</p>
             <div id="teaFinishCharts" ref="teaFinishCharts" style="width: 600px;height:400px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生与老师的互动效果</p>
             <div id="teaInteractionCharts" ref="teaInteractionCharts" style="width: 600px;height:420px;"></div>
             <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">学生对本堂课内容的掌握程度</p>
             <div id="teaUnderstandCharts" ref="teaUnderstandCharts" style="width: 600px;height:400px;"></div>
              <p style="font-size:14px;color:#909399;margin-top:20px;margin-left:50px;font-weight:600;">作业情况</p>
             <div id="homeWorkDownloadCharts" ref="homeWorkDownloadCharts" style="width: 600px;height:400px;"></div>
        </div>
        <div class="census_div">
            <p>上课情况</p>
           <div class="infoDiv" style="margin-left:25px;margin-top:30px;">
              <span  v-for="(item,index) in subjectData" @click="comparisonClassClick(item.macroId,index,item,3)" :class="{reportActive:reportNum4==index}" v-show="item.flag"  :id="'cancelClass' + index"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
          <div id="cancellationOfClassCharts" ref="cancellationOfClassCharts" style="width: 600px;height:400px;"></div>
        </div>
        <div class="census_div">
            <p>后续上课计划</p>
           <div class="infoDiv" style="margin-top:20px;">
              <span  v-for="(item,index) in subjectData" @click="stuComparisonClick(item.macroId,index)" :class="{reportActive:reportNum2==index}" v-show="item.flag"  :key="index"  type="success" plain size="small" >{{item.name}}</span>
            </div>
        </div>
        <div v-for="(item,index) in subjectData" :key="index" v-show="reportNum2==index"  style="margin-top:30px;">
            <p style="font-size:16px;">课时数：{{subjectData[index].stuComparisonClassHours}}</p>   
            <p style="font-size:16px;margin-top:10px;">计划内容：{{subjectData[index].plan}}</p>   
        </div>
         <div class="census_div">
             <p>给家长的建议</p>
             <p style="margin-top:10px;min-height:100px;">{{parentProposal}}</p>
         </div>
         <div class="census_div">
             <p>给学生的建议</p>
             <p style="margin-top:10px;min-height:100px;">{{stuProposal}}</p>
         </div>
      </div>
    </el-dialog>
    <chat-to-user></chat-to-user>
    <followCont ref="followCont" :followDialog="followDialog" :followId="followId" @change_followStatus="change_followStatus" ></followCont>
    <refundDialog ref="refundDialog"  @close="Visible=false" :visibleStudentId="visibleStudentId" v-if="Visible" :visible="Visible" :page="1"></refundDialog>
    <setStudentStatu ref="setStudentStatu" :studentStatuDialog="studentStatuDialog" :studentStatuId="studentStatuId" :studentStateType="studentStateType" @change_studentStatu="change_studentStatu"></setStudentStatu>
    <historyList ref="historyList" :historyStatus="historyStatus" :historyId="historyId" @CB_historyStatus="CB_historyStatus"></historyList>
    <addStudent ref="addStudent" :addStudentStatus="addStudentStatus" @CB_addStudentStatus="CB_addStudentStatus"></addStudent>
    <downOrder ref="downOrder" :downOrderStatus="downOrderStatus" :downParams="downParams" @CB_downOrderStatus="CB_downOrderStatus"></downOrder>
    <orderInfo ref="orderInfo" :orderInfoStatus="orderInfoStatus"  :orderInfoParams="orderInfoParams" @CB_orderInfoShow="CB_orderInfoShow"></orderInfo>
    <classRecord ref="classRecord" :classRecordStatus="classRecordStatus" :classRecordParams="classRecordParams" @CB_classRecordShow="CB_classRecordShow"></classRecord>
  </div>
</template>
<script>
import orderInfo from "../../finance/dialog/orderInfo.vue";
import chatToUser from "./chat/dialog/chat-to-user.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import tim from "@/tim";
import TIM from "tim-js-sdk";
import { saveGroup, updateGroup, saveGroupServe } from "@/api/chat/group";
import $ from "jquery";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import followCont from "./studentFile/followCont.vue"; //跟进内容
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";

import {
  productListNoPage,
  productInfoDetail,
} from "@/api/operates/productManage";
import {
  studentProcessList as studentList,
  getAvliableNum,
  saleApply,
  checkOneToOneOrder,
  followUpList,
  orderList,
  orderDetail,
  orderRemove,
  studentClassRecord,
} from "@/api/sales/myCustomer";
import dayjs from "dayjs";
import {
  studentSave,
  studentInfo,
  studentUpdate,
  infoByParentCodeAndValue,
  saleBack,
  academicrecordSave, //学生添加试卷信息接口
  getObjectById, //学生添加试卷信息接口
  academicrecordRemove, //学生删除试卷接口
  academicrecordUpdate, //学生编辑试卷接口
} from "@/api/sales/saleManage";
import { systemAreaList, setStudentlevel } from "@/api/system/systemManage";
import {
  alreadyDistribute,
  teacherManagerSave,
  returnStudentByStudentId,
  listTeacherManager,
  configteacherPlan,
  configteacherPlanList,
  getConfigureCourseById,
  saveChooseTeacher,
  saveOneChooseTeacher,
  deleteOneChooseTeacher,
  xmtStudentNeedsList,
  xmtStudentNeedsSave,
  xmtStudentNeedsUpdate,
  getDetailByStudentId,
  xmtStudentNeedsRemove,
  studentClassAddressList, //上课地址列表
  studentClassAddressSave, //上课地址列表新增
  studentClassAddressUpdate, //上课地址列表修改
  studentClassAddressRemove, //上课地址列表删除
  studentClassAddressInfo, //上课地址列表详情
  getProductByStudentId, //该学生所购买过的产品
  checkDefault, //判断是否有默认地址
  checkClassTime, //排课前看一下这个老师和学生有么有被占用
  xmtProductItemSave, //新增排课接口
  getResiduePrice, //查询剩余金额接口
  xmtProductItembatchSave,
  xmtProductItemInfo,
  findCourseItem,
  xmtProductItemRemove,
  xmtProductItemUpdate,
  checkTeacherClassTime, //老师是否被占用
  checkStudentClassTime, //学生是否被占用
  xmtProductItemDelete,
  findProductItemPage,
  findMoreProductItemPage,
  findDelProductItemPage,
  batchCancel,
  teacherFollowupRecordSave,
  teacherFollowupRecordList,
  teacherFollowupRecordUpdate,
  orderPublishList, //课单管理列表
  orderPublishSave, //课单管理新增
  orderPublishInfo, //课单管理详情
  orderPublishUpdate, //课单管理修改接口
  orderPublishRemove, //课单管理删除接口
  batchCheckTeacherClassTime, //批量排课钱判断老师是否被占用
  batchCheckStudentClassTime,
  countConcern,
  checkRefundOrders,
  getOtoPriceByStudentId,
  checkHistoryChooseTeacher,
  isConcernTeacher,
  teacherInfo,
  getByTeacherId,
  teacherEvaluatedContent,
  getTeacherClassDetailsCount,
  teacherEvaluatedList,
  teacherFollowupRecordRemove,
  checkTeacherOrganClassTime,
  batchCheckTeacherOrganClassTime,
  listTeacherTeble, //定向发单列表
  updateOrientOrderPublish,
  fileUploads,
  listStudentCourse, //学生空闲时间列表
  addFreetime, //学生空闲时间添加
  batchRemoveFreetime, //学生空闲时间删除
  removeByStuId,
  saveOneToOne,
  batchSaveOneToOne,
  deleteMakeUpTimeForClass,
  makeUpTimeForClass,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import {
  studentRefundList,
  studentRefundInfo,
  studentRefundUpdate,
  studentRefundSave,
  selectStudentState,
  refundOrderInfos,
  studentDelete,
  alreadyDistributeMore,
  studentStudyReportList,
  studentStudyReportSave,
  studentStudyReportRemove,
  studentStudyReportUpdate,
  studentStudyReportInfo,
  getChartData,
  sendStudyReport,
  checkRefundOneToOne,
  alreadyDistributeCount,
  getProductByStudentIdForBatch,
  balanceRecharge,
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import { getFlowFeeByMode, getOrganBalance } from "@/api/system/systemManage";
import { teacherFaceMarkDetail } from "@/api/teacher/teacherManage";
import { getBytesLength } from "@/utils/tool";
import dialogFileViewPrivate from "../expertTeachBusiness/chat/dialog-file-view-private.vue";
import refundDialog from "../../finance/dialog/refund-dialog.vue";
import setStudentStatu from "../publicFile/setStudentStatu.vue";
// @/expertTeachBusiness/chat/dialog-file-view-private.vue
import historyList from "./dialog/studentManageFile/historyList.vue";
import addStudent from "./dialog/studentManageFile/addStudent.vue";
import downOrder from "./dialog/studentManageFile/downOrder.vue";
import classRecord from "./dialog/studentManageFile/classRecord.vue";
function formatNumber(n) {
  return n.toString().length > 1 ? n : "0" + n;
}

const rich = {
  b: {
    fontSize: 14,
    fontWeight: "bold",
  },
  n: {
    fontSize: 14,
    // lineHeight: 20
  },
};

const makeMultiLine = (str, charQty) => {
  const strs = str.split("");
  const len = strs.length;
  if (len < charQty + 1) {
    return str;
  }
  let result = "";
  strs.forEach((_, index) => {
    result += _;
    if ((index + 1) % charQty === 0 && index < len - 1) {
      result += "\n";
    }
  });
  return result;
};
const weekOptions = [
  { name: "星期一", id: "1" },
  { name: "星期二", id: "2" },
  { name: "星期三", id: "3" },
  { name: "星期四", id: "4" },
  { name: "星期五", id: "5" },
  { name: "星期六", id: "6" },
  { name: "星期日", id: "0" },
];
const cityOptions = [
  { name: "星期一", id: "1" },
  { name: "星期二", id: "2" },
  { name: "星期三", id: "3" },
  { name: "星期四", id: "4" },
  { name: "星期五", id: "5" },
  { name: "星期六", id: "6" },
  { name: "星期日", id: "0" },
];
const getWeek = {
  run: function (year) {
    var days = getWeek.getDate(year || new Date().getFullYear());
    var weeks = {};

    for (var i = 0; i < days.length; i++) {
      var weeksKeyLen = Object.keys(weeks).length;
      var daySplit = days[i].split("_");

      if (weeks[weeksKeyLen] == undefined) {
        weeks[weeksKeyLen + 1] = [daySplit[0]];
      } else {
        if (daySplit[1] == "1") {
          weeks[weeksKeyLen + 1] = [daySplit[0]];
        } else {
          weeks[weeksKeyLen].push(daySplit[0]);
        }
      }
    }

    return weeks;
  },
  getDate: function (year) {
    var dates = [];
    for (var i = 1; i <= 12; i++) {
      for (var j = 1; j <= new Date(year, i, 0).getDate(); j++) {
        dates.push(
          year +
            "-" +
            formatNumber(i) +
            "-" +
            formatNumber(j) +
            "_" +
            new Date([year, i, j].join("-")).getDay()
        );
      }
    }

    return dates;
  },
};

const cNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
const getYearWeek = function (a, b, c) {
  //a = d = 当前日期
  //b = 6 - w = 当前周的还有几天过完(不算今天)
  //a + b 的和在除以7 就是当天是当前月份的第几周
  var date = new Date(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate();
  return Math.ceil((d + 6 - w) / 7);
};
let addDate = (date, n) => {
  date.setDate(date.getDate() + n);
  return date;
};
let setDate = (date) => {
  var week = date.getDay() - 1;
  date = addDate(date, week * -1);
  currentFirstDate = new Date(date);
  for (var i = 1; i < clen; i++) {
    cells[i].innerHTML = formatDate(i == 1 ? date : addDate(date, 1));
  }
};
let formatDate = (date) => {
  var month = 0;
  var day = 0;
  var year = date.getFullYear();
  month = date.getMonth() + 1;
  if (month >= 10) {
    month = month;
  } else {
    month = "0" + month;
  }
  day = date.getDate();
  if (day >= 10) {
    day = day;
  } else {
    day = "0" + day;
  }
  var week =
    "(" +
    ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][date.getDay()] +
    ")";
  getYear = year;
  this.dataArray2.push(year + "-" + month + "-" + day);
  this.dataWeek.push(year + "-" + month + "-" + day);
  return month + "-" + day + " " + week;
};
var currentFirstDate;
var getYear;

export default {
  components: {
    dialogFileViewPrivate,
    refundDialog,
    followCont,
    setStudentStatu,
    chatToUser,
    historyList,
    addStudent,
    downOrder,
    orderInfo,
    classRecord, //上课记录
  },
  directives: {
    hClick: {
      inserted(el, binding, vnode) {
        el.id == "nav0" ? el.click() : null;
        el.id == "cancelClass0" ? el.click() : null;
      },
    },
  },
  data() {
    let check_productIdOne = (rule, value, callback) => {
      if (this.classHourStatus == 2) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback();
      }
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      setTimeout(() => {
        if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号码"));
        }
      }, 100);
    };
    return {
      addStudentStatus: false,
      downOrderStatus: false,
      studentlevellList: [
        { value: 1, label: "一级" },
        { value: 2, label: "二级" },
        { value: 3, label: "三级" },
        { value: 4, label: "四级" },
      ],
      levelParams: {
        levelId: "",
        id: "",
      },
      historyStatus: false,
      historyId: "",
      levelNum: 1,
      followDialog: false, //跟进记录显示
      followId: "",
      Visible: false,
      reportValue: "",
      comparisonClassHoursList: [],
      addStudyPopup: false,
      studyReportList: [],
      stuComparisonList: [],
      studyTimes: {
        searchBeginTime: "",
        searchEndTime: "",
      },
      chart: null,
      studyParams: {
        pageNumber: 1,
        pageSize: 10,
        studentId: "",
      },
      comparisonData: [],
      rechargeParams: {
        total: 0,
        surplusMoney: 0,
        classHours: 0,
      },
      studyData: {},
      reportNum: 0,
      reportNum2: 0,
      reportNum3: 0,
      reportNum4: 0,
      parentProposal: "",
      stuProposal: "",
      averageClassHours: "",
      comparisonOne: [],
      comparisonTwo: [],
      startTime: "",
      endTime: "",
      studyRuleForm: {
        XmtStudentFollowUpClassPlanList: [], //后续上课计划
        XmtStudentComparisonClassHoursList: [], //上课时长对比
        studentId: "",
      },
      studyRules: {},
      teacherIndex: "1",
      photoList: [],
      removeBatchClassVisible: false,
      removeBatchClassRadio: "",
      checkedWeeks: [],
      freeWeeks: [],
      checkWeekAll: false,
      freeWeekAll: false,
      isWeekIndeterminate: true,
      isFreeIndeterminate: true,
      courseLevelList: [
        {
          id: 0,
          label: "正常课程",
        },
        {
          id: 2,
          label: "中级课程",
        },
        {
          id: 3,
          label: "高级课程",
        },
        {
          id: 4,
          label: "特级课程",
        },
      ],
      orderReceivingPopup: false,
      orderReceivingParams: {
        selectType: "4",
        orderPublishId: "",
        name: "",
        sectionId: "",
        subjectId: "",
        identityType: null,
        teachTitleId: null,
        pageNumber: 1,
        pageSize: 10,
      },
      orderReceivingTotal: 0,
      orderReceivingData: [],
      editDialogVisible: false,
      getNum: 0,
      aa: "已有",
      bb: "名教务关注了该讲师",
      sureTeacherArray: {
        id: "",
        teacherName: "",
        teachFee: "",
        classSinglePrice: "",
        studentId: "",
        label: "",
        subjectId: "",
        courseLevel: "",
        hopeMinFee: "",
        hopeMaxFee: "",
        teacherId: "",
        flag: 1,
      },
      editRules: {
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" },
        ],
        childMotherPhone: [
          {
            validator: checkPhone,
            message: "请输入正确的手机号码",
          },
        ],
        childFatherPhone: [
          {
            validator: checkPhone,
            message: "请输入正确的手机号码",
          },
        ],
      },
      addStudyParams: {
        type: "add",
        title: "新增学习报告",
      },
      orderStatusList: [
        { name: "待财务审核", id: 1 },
        { name: "财务审核拒绝", id: 2 },
        { name: "教务主管分配中", id: 3 },
        { name: "待排课", id: 4 },
        { name: "排课中", id: 5 },
      ],
      studentStatuDialog: false,
      studentStatuId: "",
      studentStateType: "",
      accountBalance: 0,
      remainingClassHours: 0,
      classHourStatus: "1",
      comparisonClassHours: false, //上课时长对比状态
      classroomFeedback: false, //课堂反馈状态
      cancellationOfClass: false, //取消课状态  上课情况
      followUpClassPlan: false, //后续上课状态
      adviceToParents: false, //家长的建议
      suggestionsForParticipants: false, //学员的建议
      saveDisabled: false,
      orderInfoStatus: false,
      classRecordStatus: false,
      classRecordParams: {},
      orderInfoParams: {
        id: "",
        studentId: "",
      },
      editRuleForm: {
        name: "",
        contact: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        qitaxueke: 0,
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        xmtStudentSubjectRelationEntityList: [],
      },
      classTimeTyp: 1,
      srcUrl: "",
      imgPreview: {
        img: "",
        show: false,
      },
      seeImageShow: false,
      subjectList_info: [],
      newSingleRuleForm: {},
      detailDetNamesPopup: false,
      beforePickerOptions: {
        selectableRange: ["06:00:00 -23:59:59"],
        disabledDate: this.disabledDateTime,
        format: "HH:mm",
      },
      offlineBeforePickerOptions: {
        selectableRange: ["06:00:00 -23:59:59"],
        disabledDate: false,
        format: "HH:mm",
      },
      orderDetail: {},
      orderChangeId: "",
      studentIdInfo: "",
      updateImg: {
        photo: "",
        id: "",
      },
      moreclassAddress: "",
      orderReceivingHandle: [],
      checkedGh: [],
      checkedGh2: [],
      checkedGhCheck: [],
      idData: [],
      editId: "",
      isExamine: true,
      allRealityUsePrice: "",
      getKey: "1",
      surplusMoney: 0,
      orderMoney: 0,
      getSinglePrice2: 0,
      selectOrderList2: "",
      studentState: "",
      selectOrderList: [],
      srcList: [],
      centerType: "",
      centerDialogVisible: false,
      centerStudentName: "",
      centerTeacherName: "",
      centerBeginTime: "",
      centerTitle: "",
      centerUsedClassHour: "",
      centerOrganName: "",
      centerTrue: false,
      getval: [],
      activeName2: "info1",
      startDatePicker: null,
      endDatePicker: null,
      total: 0,
      teachertotal: 0,
      followUpDialogVisible: false,
      content_height: "160",
      orderPublishParams: {
        studentId: "",
      },
      cancellationOfClassArray: [],
      teachSpeedList2: [
        {
          macroId: 0,
          name: "均可",
        },
        {
          macroId: 13,
          name: "正常",
        },
        {
          macroId: 12,
          name: "快",
        },
        {
          macroId: 14,
          name: "慢",
        },
      ],
      countMap: {},
      saveLessonDialog: {
        title: "新增课单",
        type: "add",
      },
      batchLoading: false,
      saveLessonDialogVisible: false,
      saveLessonForm: {
        studentAchievementId: "",
        weekArr: "",
        addressNames: "",
        classListTitle: "",
        sectionId: "",
        ifFaceToFace: "0",
        provinceId: "",
        cityId: "",
        countyId: "",
        teachingAgeId: 0,
        teachTitleId: 0,
        sexNeedId: 0,
        formOfClass: 0,
        styleNeedId: "",
        speedNeedId: 0,
        ageNeedId: 0,
        mandarinId: 0,
        minimumClassPay: '',
        maximumClassPay: '',
        classHour: '',
      },
      dateArr: [],
      outData: {},
      provinceLessonList: [],
      cityLessonList: [],
      countyLessonList: [],
      cancelCourseSubjectList: [],
      saveLessonRules: {
        classListTitle: [
          { required: true, message: "课单标题不能为空", trigger: "blur" },
        ],
        sectionId: [
          { required: true, message: "年级不能为空", trigger: "change" },
        ],
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "change" },
        ],
        finishTime: [
          { required: true, message: "课单结束时间不能为空", trigger: "blur" },
        ],
      },
      saveRuleFormFollowUp: {
        studentId: "",
        nextFollowupTime: "",
        followContent: "",
      },
      downParams: {
        id: "",
      },
      listParams: {
        dataAuthority: "user",
        keyword: "",
        sectionId: "0",
        sex: "0",
        accountBalanceBegin: null, //一对一剩余费用起
        accountBalanceEnd: null, //一对一剩余费用止
        searchBeginDate: null, //分配时间起
        searchEndDate: null, //分配时间止
        beginTime: null, // 下次更进时间起
        endTime: null, //下次更进时间止
        pageNumber: 1,
        pageSize: 10,
      },
      countMap: {},
      listMoreParams: {
        dataAuthority: "user",
        keyword: "",
        sectionId: "0",
        sex: "0",
        accountBalanceBegin: null, //一对一剩余费用起
        accountBalanceEnd: null, //一对一剩余费用止
        searchBeginDate: null, //分配时间起
        searchEndDate: null, //分配时间止
        beginTime: null, // 下次更进时间起
        endTime: null, //下次更进时间止
        pageNumber: 1,
        pageSize: 10,
      },
      studentMoreList: [],
      timeMorePickerValue: "",
      timeNextPickerValue: "",
      totalMore: 0,
      alreadyPickerValue: "",
      alreadyTotal: 0,
      alreadyList: [],
      alreadyPickerValue2: "",
      alreadyParams: {
        dataAuthority: "user",
        keyword: "",
        sectionId: "0",
        sex: "0",
        accountBalanceBegin: null, //一对一剩余费用起
        accountBalanceEnd: null, //一对一剩余费用止
        searchBeginDate: null, //分配时间起
        searchEndDate: null, //分配时间止
        beginTime: null, // 下次更进时间起
        endTime: null, //下次更进时间止
        pageNumber: 1,
        pageSize: 10,
        levelId: "",
      },
      entitiesState: [],
      childState: [],
      allState: [],
      listLen: 0,
      orderPublishData: [],
      studentList: [],
      studentData: [],
      timePickerMore: "",
      studentDetailList: {},
      followUpParamsTotal: 0,
      followUpParams: {
        pageNumber: 1,
        pageSize: 10,
      },
      followUpContent: {
        pageNumber: 1,
        pageSize: 10,
      },
      subjectData: [],
      saveOrderForm: {
        realityUsePrice: "",
        refundAmount: "",
        realityRefundAmount: "",
        backReason: "",
        bankUserName: "",
        bankAccount: "",
        bankAddress: "",
        bankUserPhone: "",
      },
      classHourStatusMore: "1",
      saveOrderRules: {
        bankUserName: [
          { required: true, message: "收款姓名不能为空", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "收款账号不能为空", trigger: "blur" },
        ],
        bankAddress: [
          { required: true, message: "开户支行不能为空", trigger: "blur" },
        ],
        bankUserPhone: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
        ],
        realityRefundAmount: [
          { required: true, message: "实退款金额不能为空", trigger: "blur" },
        ],
        realityUsePrice: [
          { required: true, message: "实际消耗金额不能为空", trigger: "blur" },
        ],
        refundAmount: [
          { required: true, message: "应退款金额不能为空", trigger: "blur" },
        ],
      },
      interactionName: [],
      interactionData: [],
      sectionLists: [],
      teaUnderstand: [],
      isSaveDisable: false,
      historyDate: {
        pageNumber: 1,
        pageSize: 1000,
        studentId: "",
      },
      historyDateList: [],
      sectionList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      sexList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      defaultId: "",
      studyTotal: 0,
      seeStudyPopup: false,
      normalClassHoursList: [
        [
          {
            name: "上午",
            key: "a",
            status: false,
            week: "周一",
          },
          {
            name: "上午",
            key: "b",
            status: false,
            week: "周二",
          },
          {
            name: "上午",
            key: "c",
            status: false,
            week: "周三",
          },
          {
            name: "上午",
            key: "d",
            status: false,
            week: "周四",
          },
          {
            name: "上午",
            key: "e",
            status: false,
            week: "周五",
          },
          {
            name: "上午",
            key: "f",
            status: false,
            week: "周六",
          },
          {
            name: "上午",
            key: "g",
            status: false,
            week: "周日",
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
            week: "周一",
          },
          {
            name: "下午",
            key: "i",
            status: false,
            week: "周二",
          },
          {
            name: "下午",
            key: "j",
            status: false,
            week: "周三",
          },
          {
            name: "下午",
            key: "k",
            status: false,
            week: "周四",
          },
          {
            name: "下午",
            key: "l",
            status: false,
            week: "周五",
          },
          {
            name: "下午",
            key: "m",
            status: false,
            week: "周六",
          },
          {
            name: "下午",
            key: "n",
            status: false,
            week: "周日",
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
            week: "周一",
          },
          {
            name: "晚上",
            key: "p",
            status: false,
            week: "周二",
          },
          {
            name: "晚上",
            key: "q",
            status: false,
            week: "周三",
          },
          {
            name: "晚上",
            key: "r",
            status: false,
            week: "周四",
          },
          {
            name: "晚上",
            key: "s",
            status: false,
            week: "周五",
          },
          {
            name: "晚上",
            key: "t",
            status: false,
            week: "周六",
          },
          {
            name: "晚上",
            key: "u",
            status: false,
            week: "周日",
          },
        ],
      ],
      expireTimeOptionBefore: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      wordTimeObj: {
        a: "周一上午",
        b: "周二上午",
        c: "周三上午",
        d: "周四上午",
        e: "周五上午",
        f: "周六上午",
        g: "周日上午",
        h: "周一下午",
        i: "周二下午",
        j: "周三下午",
        k: "周四下午",
        l: "周五下午",
        m: "周六下午",
        n: "周日下午",
        o: "周一晚上",
        p: "周二晚上",
        q: "周三晚上",
        r: "周四晚上",
        s: "周五晚上",
        t: "周六晚上",
        u: "周日晚上",
      },
      firstClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ],
      teaEarnest: [0, 0, 0, 0, 0],
      teacherDetailPopup: false,
      teacherId: "",
      teacherFaceMark1: {},
      teacherFaceMark2: {},
      getConcernId: "",
      teacherDetail: {},
      modelName: "first",
      evaluateName: "1",
      evaluateActive: 0,
      newPageSize2: 10,
      newPageNumber2: 1,
      evaluateActive2: 0,
      newPageSize: 10,
      newPageNumber: 1,
      lastTime: 0,
      evaluateUlData: {
        allCount: 0,
        praiseCount: 0,
        chinaReviewCount: 0,
        negativeCommentCount: 0,
      },
      evaluateUlData2: {
        allCount: 0,
        praiseCount: 0,
        chinaReviewCount: 0,
        negativeCommentCount: 0,
      },

      evaluatedContentParams: {
        pageNumber: 1,
        pageSize: 10,
        evaluationSource: 0,
      },
      evaluatedContentParams2: {
        pageNumber: 1,
        pageSize: 10,
        evaluationSource: 1,
      },
      countParams: {
        suspend: 0, //暂停课
        studying: 0, //在读
        refund: 0, //退费
        endClass: 0, //节课
      },
      levelUrl: require("../../../assets/image/dengji.png"),
      weekFreeTitle: "",
      evaluateTable: [],
      evaluateTotal: 0,
      evaluateTable2: [],
      evaluateTotal2: 0,
      answerList: [],
      timePickerValue: "",
      timePickerValue2: "",
      classInfoData: [],

      arr: [],
      activeIndex: "1",
      orientList: [],
      orientIdArray: [],
      orientLists: [],
      orientParams: {
        id: "",
        listTeacherId: [],
        orientPublishStatus: 1,
      },
      saveRuleForm: {
        teachManagerId: "11",
        name: "",
        contact: "",
        sex: "1",
        birthday: "",
        constellationId: "",
        sectionId: "",
        schoolName: "",
        childNature: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTimeRemark: "",
        formalClassTimeRemark: "",
      },

      studentStateTotal: [1, 5],
      handleKey: "1",
      reverse: true,
      addressShow: false,
      qrClassTime: "",
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
      lessonTeachTitleId: [
        {
          value: 0,
          label: "均可",
        },
        {
          value: 59,
          label: "初级讲师",
        },
        {
          value: 60,
          label: "中级讲师",
        },
        {
          value: 61,
          label: "高级讲师",
        },
        {
          value: 62,
          label: "特级讲师",
        },
      ],
      sexNeedIdList: [
        {
          value: 0,
          label: "均可",
        },
        {
          value: 1,
          label: "男老师",
        },
        {
          value: 2,
          label: "女老师",
        },
      ],
      speedNeedIdList: [
        {
          value: "",
          label: "均可",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "快",
        },
        {
          value: "3",
          label: "慢",
        },
      ],
      levelIdList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "一级",
        },
        {
          value: "2",
          label: "二级",
        },
        {
          value: "3",
          label: "三级",
        },
        {
          value: "4",
          label: "四级",
        },
      ],
      lessonTeachingAgeId: [],
      formOfClassModel: [],
      lessonTimeValue: "",
      getRemoveId: "",
      removeClassRadio: "",
      removeClassVisible: false,
      lessonStyleList: [],
      orderdDetail: [],
      refundSync: false,
      constellationList: [],
      provinceList: [],
      parentId: "0",
      cityList: [],
      countyList: [],
      id: "",
      courseShow: false,
      //当选择自定义课时时，销售人员可以自己设置课时、单价、赠送课时、优惠金额等；注：课时X单价-优惠金额=总价
      saveRuleFormOrder: {
        orderType: "5", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        coachAgreementName: "",
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "",
        zdySinglePrice: "",
        zdyGiveClassHour: 0,
        zdyDiscountAmount: "",
        zdyActualPrice: "",
        studentId: this.idd,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1", //1全款   2定金  3尾款
      },
      productList: [],
      backImgStyle: {
        backgroundImage:
          "url(" + require("../../../assets/image/xuexibaogao_bg.png") + ")",
      },
      backImgTime: {
        backgroundImage:
          "url(" + require("../../../assets/image/classTime.png") + ")",
        width: "673px",
        height: "64px",
        lineHeight: "64px",
        position: "absolute",
        left: "50%",
        marginLeft: "-337px",
        marginTop: "28px",
      },
      workHome: [],
      getOrderStatus: [],
      valLength: 0,
      productDetail: {
        productPackage: [],
        periodList: [],
        periodIdList: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: [],
      },
      fileUpload,
      subjectList: [],
      showSaveDialog: false,
      //课程安排
      arrangePopup: false,
      studentDetail: {},
      demandList: [],
      activeName: "first",

      teacherList: [],
      isIndeterminate: true,

      checkedCities: [],
      cities: cityOptions,
      weeks: weekOptions,
      options: [
        {
          value: 0,
          label: "正常课程",
        },
        {
          value: 1,
          label: "初级课程",
        },
        {
          value: 2,
          label: "中级课程",
        },
        {
          value: 3,
          label: "高级课程",
        },
        {
          value: 4,
          label: "特级课程",
        },
      ],
      teacherPopup: false,
      sectionIdList: [
        {
          macroId: "",
          name: "全部",
        },
      ],
      subjectIdList: [],
      finishTimeBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      teacherListParams: {
        hopeFee: "",
        name: "", //关键字
        sectionId: "", //年级
        subjectId: "", //科目
        identityType: "", //讲师身份
        teachTitleId: "", //讲师职称
        pageNumber: 1,
        pageSize: 10,
      },
      identityTypeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "学科类--老师",
        },
        {
          value: "2",
          label: "学科类--大学生",
        },
        {
          value: "3",
          label: "素质教育类--老师",
        },
        {
          value: "4",
          label: "素质教育类--大学生",
        },
      ],
      teachTitleIdList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 59,
          label: "初级讲师",
        },
        {
          value: 60,
          label: "中级讲师",
        },
        {
          value: 61,
          label: "高级讲师",
        },
        {
          value: 62,
          label: "特级讲师",
        },
      ],
      aa: "已有",
      bb: "名教务关注了该讲师",
      getNum: "",
      teacherListData: [],
      tableRadio: "",
      getIndex: "",
      idd: "",
      configteacherParams: {
        status: 1,
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      teacherListTotal: 0,
      studentDetailPopup: false,
      studentListDetail: false,
      tabsActive: "record",
      demandList: [],
      flag: true,
      needList: [],
      needPopup: false,
      subjectidList: [],
      teachTitleList: [],
      needTotal: 0,
      needRuleForm: {
        studentAchievementId: "",
        mandarinId: 0,
        speedNeedId: 0,
        ageNeedId: 0,
        sectionId: "",
        teachTitleId: 0, //讲师职称
        teachingAgeId: 0, //教龄
        sexNeedId: 0, //性别偏好
        priorDateOne: "",
        priorDateTwo: "",
        priorDateThree: "",
        priorTimeOne: "",
        priorTimeTwo: "",
        priorTimeThree: "",
        classTimes: "",
        classForm: 0,
        faceProvinceId: "",
        faceCityId: "",
        faceCountyId: "",
        studentDescribe: "",
        studentDescribe: "",
        studentId: "",
        subjectId: "",
        styleNeedId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      followUpTotal: 0,
      idArr: [],
      freeId: [],
      needRules: {},
      skilfulArray: [],
      skilfulDate: [],
      editOrderId: "",
      freeRuleFormFollowUp: {
        userType: "2",
        teaStuId: "",
        beginTime: null,
        endTime: null,
        usedClassHour: 1,
      },
      teachingAgeIdList: [],
      previewResultRules: {},
      previewRuleForm: {},
      teachSexList: [
        {
          value: 1,
          label: "男老师",
        },
        {
          value: 2,
          label: "女老师",
        },
        {
          value: 0,
          label: "均可",
        },
      ],
      lessonAgeNeedId: [],
      teachStyleList: [],
      teachSpeedList: [],
      teachYearList: [],
      priorDatList: [
        { value: 1, label: "周一" },
        { value: 2, label: "周二" },
        { value: 3, label: "周三" },
        { value: 4, label: "周四" },
        { value: 5, label: "周五" },
        { value: 6, label: "周六" },
        { value: 7, label: "周日" },
      ],
      priorTimeList: [
        {
          value: 0,
          label: "均可",
        },

        {
          value: 2,
          label: "上午",
        },
        {
          value: 3,
          label: "下午",
        },
        {
          value: 4,
          label: "晚上",
        },
      ],
      classHoursList: [
        { value: 6, label: "06:00" },
        { value: 7, label: "07:00" },
        { value: 8, label: "08:00" },
        { value: 9, label: "09:00" },
        { value: 10, label: "10:00" },
        { value: 11, label: "11:00" },
        { value: 12, label: "12:00" },
        { value: 13, label: "13:00" },
        { value: 14, label: "14:00" },
        { value: 15, label: "15:00" },
        { value: 16, label: "16:00" },
        { value: 17, label: "17:00" },
        { value: 18, label: "18:00" },
        { value: 19, label: "19:00" },
        { value: 20, label: "20:00" },
        { value: 21, label: "21:00" },
        { value: 22, label: "22:00" },
        { value: 23, label: "23:00" },
      ],
      classTimesList: [
        {
          value: 0,
          label: "每周一次",
        },
        {
          value: 1,
          label: "每周两次",
        },
        {
          value: 2,
          label: "每周三次",
        },
        {
          value: 3,
          label: "每周四次",
        },
        {
          value: 4,
          label: "每周五次",
        },
        {
          value: 5,
          label: "每周六次",
        },
        {
          value: 6,
          label: "每天一次",
        },
      ],
      classFormList: [
        {
          value: 0,
          label: "均可",
        },
        {
          value: 1,
          label: "线上",
        },
        {
          value: 2,
          label: "线下",
        },
      ],
      demandTotal: 0,
      xmtStudentNeeds: {
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      getstudentid: "",
      selectUserList: [],
      needTitle: {
        type: "add",
        title: "新增需求",
      },
      classRecord: "1",
      tableKey: "",
      oneToOneData: [],
      oneToMoreData: [],
      delData: [],
      cancelClassData: [],
      followUpList: [],
      orderList: [],
      total1: 0,
      total2: 0,
      total3: 0,
      dataList: [],
      radio: "",
      timePickerOne: "",
      onesubjectIdList: [],
      findListParams: {
        orderType: "1",
        dataAuthority: "user",
        name: "",
        subjectId: "",
        attendClassStatus: "1",
        abnormalStatus: "",
        classMode: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
      },
      oneToMoreParams: {
        dataAuthority: "user",
        roomName: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
        studentId: "",
        attendClassStatus: "1",
        abnormalStatus: "",
      },
      visibleStudentId: "",
      delParams: {
        dataAuthority: "user",
        delCause: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
        studentId: "",
      },
      delCauseList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "学生原因",
        },
        {
          value: "2",
          label: "老师原因",
        },
        {
          value: "3",
          label: "其他原因",
        },
      ],
      singlePopup: false,
      timePickerDel: "",
      singleParams: {
        title: "新增课程",
        type: "add",
      },
      singSubjectIdList: [],
      orientPopup: false,
      singSubjectIdList2: [],
      singSubjectIdListTitle: [],
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      classHourTypeList: [
        {
          value: "1",
          label: "正常课时",
          show: "2",
        },
        {
          value: "2",
          label: "赠送课时",
          show: "2",
        },
        {
          value: "3",
          label: "收费课程",
          show: "1",
        },
        {
          value: "5",
          label: "赠送课时",
          show: "1",
        },
      ],
      isDisable: false,
      videoModeList: [
        {
          name: "普通模式",
          type: 1,
        },
        {
          name: "高级模式",
          type: 2,
        },
      ],
      oneToOneSingleClassPrice: 0, // 一对一单价
      organTotalBalance: "--", // 机构剩余金额
      singleRuleForm: {
        subjectId: "", //学科
        classMode: "1", //上课模式
        title: "", //课程标题
        classHourType: this.classHourStatus == 2 ? "1" : "3", //收费类型
        usedClassHour: "2", //课时数
        beginTime: "", //上课时间
        isFirstClass: "0", //首次课设置
        isPrisonClass: "0",
        isPlayback: "0",
        teacherId: "", //讲师
        productId: "",
        classAddress: "",
        totalPrice: "", //总金额
        usePrice: "", //这节课使用的金额
        videoMode: 1, // 1 普通模式  2 高级模式
        expense: "--", // 消费金额
      },
      singlePopupRules: {
        productId: [{ validator: check_productIdOne, trigger: "blur" }],
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        usedClassHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
      },
      selectTeacherPopup: false,
      matchingTeacherList: [],
      getSubjectId: "",
      freePopup: false,
      teacherParams: {
        status: 1,
        label: 2,
        studentId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      teacherSelectionHandle: [],
      teacherTotalnew: 0,
      newteacherName: "",
      detailAddress: "",
      detailAddressPopup: false,
      addAddressPopup: false,
      addAddressParams: {
        title: "添加地址",
        type: "add",
      },
      demandDialog: false,
      demandParams: {},
      saveAddressForm: {
        provinceId: "",
        cityId: "",
        countyId: "",
        isDefault: "0",
        detailAddress: "",
      },

      saveAddressRules: {},
      addressProvinceList: [],
      addressCityList: [],
      addressCountyList: [],
      addressListData: [],
      selectData: [],
      classAddressData: {
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      detNamesData: {
        studentId: "",
        pageNumber: 1,
        pageSize: 10,
      },
      studentResultDialog: {
        title: "添加试卷信息",
        type: "add",
      },
      studentResultRules: {
        examinationTime: [
          { required: true, message: "考试时间不能为空", trigger: "blur" },
        ],
        subjectScore: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/;
              if (value === "") {
                callback(new Error("成绩不能为空不能为空"));
              } else if (!regNum.test(value)) {
                callback(new Error("仅限输入数字格式"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subjectAllScore: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/;
              if (value === "") {
                callback(new Error("总分不能为空不能为空"));
              } else if (!regNum.test(value)) {
                callback(new Error("仅限输入数字格式"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      examinationTypeList: [
        {
          macroId: "1",
          name: "模拟考试",
        },
        {
          macroId: "2",
          name: "期中",
        },
        {
          macroId: "3",
          name: "期末",
        },
        {
          macroId: "4",
          name: "月考",
        },
        {
          macroId: "5",
          name: "随堂练",
        },
      ],
      tableFreeList: [],
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      previewImage: [],
      dialogVisible: false,
      previewDialogVisible: false,
      dialogImageUrl: "",
      studentResultSync: false,
      testPapers: [],
      subjectListEl: [],
      detNamesListData: [],
      detNamesInfo: [],
      detNamesTotal: 0,
      totalData: 0,
      buyProductList: [], //购买过的产品数据
      batchProductList: [],
      newdetailAddress: [],
      surplusNum: 0,
      getSinglePrice: 0,
      newProductId: "",
      batchTimePopup: false,
      batchTimeForm: {
        beginTime: "",
        stopTime: "",
        classHourType: "",
        videoMode: 1,
      },
      batchPickerValue: "", //排课日期
      week: [],
      value1: false,
      value2: false,
      courseDate: [],
      usedClassHour: "",
      pickerData: "",
      tableSubjectId: null,
      teacherType: 1,
      batchTotalPrice: "",
      newsinglePrice: "",
      batchProductId: "",
      surplusNum2: 0,
      addressType: 1,
      cancelCoursePopup: false,
      cancelCourseForm: {
        subjectId: "",
        searchBeginDate: "",
        searchEndDate: "",
      },
      batchPickerValue2: "",
      batchPickerValue3: "",
      week2: [],
      findCourseItemList: [],
      checkList: [],
      getYear: "",
      getWeekDate: "",
      aaa: {},
      bbb: [],
      currentFirstDate: "",
      tableData: [
        {
          id: 1,
          date: "06:00-07:00",
          sales: 23.34,
          sale: 137597.76,
          const: 102203.71,
          profit: 35394.05,
        },
      ],
      tableLabel: [],
      getSectionName: "",
      followTagList: [],
      dataArray2: [],
      followUpDialog: {
        title: "新增跟进记录",
        type: "save",
      },
      dataTimeArray: [
        {
          time: "6:00-7:00",
          min: 6,
          max: 7,
        },
        {
          time: "7:00-8:00",
          min: 7,
          max: 8,
        },
        {
          time: "8:00-9:00",
          min: 8,
          max: 9,
        },
        {
          time: "9:00-10:00",
          min: 9,
          max: 10,
        },
        {
          time: "10:00-11:00",
          min: 10,
          max: 11,
        },
        {
          time: "11:00-12:00",
          min: 11,
          max: 12,
        },
        {
          time: "12:00-13:00",
          min: 12,
          max: 13,
        },
        {
          time: "13:00-14:00",
          min: 13,
          max: 14,
        },
        {
          time: "14:00-15:00",
          min: 14,
          max: 15,
        },
        {
          time: "15:00-16:00",
          min: 15,
          max: 16,
        },
        {
          time: "16:00-17:00",
          min: 16,
          max: 17,
        },
        {
          time: "17:00-18:00",
          min: 17,
          max: 18,
        },
        {
          time: "18:00-19:00",
          min: 18,
          max: 19,
        },
        {
          time: "19:00-20:00",
          min: 19,
          max: 20,
        },
        {
          time: "20:00-21:00",
          min: 20,
          max: 21,
        },
        {
          time: "21:00-22:00",
          min: 21,
          max: 22,
        },
        {
          time: "22:00-23:00",
          min: 22,
          max: 23,
        },
        {
          time: "23:00-24:00",
          min: 23,
          max: 24,
        },
      ],
      tableList: [],
      newCourseData: [],
      tableTime: [],
      usedTitle: "",
      saveDialogVisible: false,
      getstatus: "",
      getAddressId: "",
      sourceTypeList2: [
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "网络活动",
        },
        {
          value: "3",
          label: "美团活动",
        },
        {
          value: "4",
          label: "A级客户",
        },
        {
          value: "5",
          label: "家长转介绍",
        },
      ],
      sourceTypeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "地推活动",
        },
        {
          value: "2",
          label: "网络活动",
        },
        {
          value: "3",
          label: "美团活动",
        },
        {
          value: "4",
          label: "A级客户",
        },
        {
          value: "5",
          label: "家长转介绍",
        },
      ],
      switchValue: true,
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },
      studentRules: {
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" },
        ],
      },
      checkAll: false,
      selectAll: false,
      type_of_cost: [],
      studentAchievementList: [],
      mandarinIdList: [],
      styleNeedIdList: [],
      styleNeedArray: [],
      styleNeedGroup: [],
      isSaveFollow: false,
      tableTotal: 0,
      freePickerValue: "",
      studentRuleForm: {
        name: "",
        contact: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        qitaxueke: 0,
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        xmtStudentSubjectRelationEntityList: [],
      },
      nowIndex: "",
      rechargeList: [],
      formalClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ],
      dataWeek: [],
      weekTitle: "",
      curWeekFirstDay: dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD"),
      freeWeekFirstDay: dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD"),
      weekList: [],
      weekLists: [],
      weekItemArr: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      // 批量排课选择讲师保存时，记录的数据
      selectTeacherName: null,
      selectTeacherId: null,
      selectConfigteacherPlanId: null,
      // 批量排课选择地址时记录的数据
      selectClassAddress: null,
    };
  },
  computed: {
    ...mapState("chatInfo", [
      "conversationList",
      "currentConversation",
      "chatToUserParams",
    ]),
    ...mapGetters("chatInfo", ["totalUnreadCount"]),
    ...mapState('mainData', ['hasCdnMenu', 'hasRecordMenu']),
  },
  watch: {
    totalUnreadCount: {
      handler() {
        this.dealUnreadCountChat();
      },
      deep: true,
    },
    singlePopup: function (val, oldVla) {
      if (this.$refs["singleRuleForm"] !== undefined) {
        this.$refs["singleRuleForm"].resetFields();
      }
    },

    saveLessonDialogVisible: function (val, oldVla) {
      if (this.$refs["saveLessonForm"] !== undefined) {
        this.$refs["saveLessonForm"].resetFields();
      }
    },
    refundSync: function (val, oldVla) {
      if (this.$refs["saveOrderForm"] !== undefined) {
        this.$refs["saveOrderForm"].resetFields();
      }
    },
    courseDate: {
      handler() {
        clearTimeout(this._computeOneToMoreFeeTimer);
        this._computeOneToMoreFeeTimer = setTimeout(() => {
          this.computeClassExpense();
        }, 200);
      },
      deep: true,
    },
  },

  created() {
    this.teacherManagerSave();
  },
  mounted() {
    this.resultList();
    this.getFollowTagList();
    this.getSubjectList();
    this.getSubjectList2();
    this.getSubjectCancel();
    this.getSectionList();
    this.getSectionLists();
    this.getConstellationList();
    this.getSectionIdList();
    this.getTeachTitle();
    this.getTeachSpeedList();
    this.getTeacYearList();
    this.findProductItemPage();
    this.classSubjectList();

    this.startDatePicker = this.beginDate();
    this.endDatePicker = this.processDate();
  },
  methods: {
    ...mapMutations("chatInfo", ["chatBoxShowChange", "updateCurStudentList"]),
    // 监课展示和回放录制权限提示
    handleAuthTip(_, flag){
      if(!flag){
        this.$message.warning('请联系管理员开同此功能！')
      }
    },
    dealUnreadCountChat() {
      this.conversationList.forEach((conItem) => {
        if (conItem.type == "C2C") {
          let chatId = conItem.conversationID.split("C2C")[1];
          //unreadCount  未读消息数
          this.alreadyList.forEach((stuItem) => {
            if (stuItem.parChatId == chatId) {
              stuItem.unreadCount = conItem.unreadCount;
            }
          });
        }
      });
    },
    teacherIndexSelect(key, keyPath) {
      this.teacherListParams = {
        hopeFee: "",
        name: "", //关键字
        sectionId: "", //年级
        subjectId: "", //科目
        identityType: "", //讲师身份
        teachTitleId: "", //讲师职称
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleKey = key;
      if (key == 1) {
        this.listTeacherManager();
      } else if (key == 2) {
        this.listTeacherManager(2);
      } else if (key == 3) {
        this.listTeacherManager(1);
      } else if (key == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (key == 5) {
        this.listTeacherManager(5);
      } else if (key == 6) {
        this.listTeacherManager(6);
      } else if (key == 7) {
        this.listTeacherManager(7);
      }
    },
    createGroupClick(item, index) {
      if (!this.createGroupExec && !item.row.groupId) {
        this.$confirm("是否确定创建群聊?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.createGroupExec = true;
            const {
              studentId,
              studentName,
              subjectName,
              stuChatId,
              teaChatId,
              teacherId,
              id: configteacherPlanId,
              createUserId: teachManagerId,
              subjectId,
              teacherRealname,
              parChatId,
            } = item.row;
            if (
              !studentName ||
              !teacherRealname ||
              !studentName ||
              !studentId ||
              !teacherId ||
              !subjectId ||
              !configteacherPlanId
            ) {
              this.msgError("缺少必要数据创建失败！");
              return;
            }
            saveGroupServe({
              subjectName,
              teacherName: teacherRealname,
              studentName,
              studentId,
              teacherId,
              subjectId,
              configteacherPlanId,
            })
              .then((res) => {
                this.createGroupExec = false;
                if (res.code == 0) {
                  this.msgSuccess("群聊创建成功！");
                } else {
                  this.msgError("创建群聊失败！");
                }
                this.configteacherPlanFnList();
              })
              .catch((err) => {
                this.createGroupExec = false;
              });
          })
          .catch(() => {});
      }
    },
    imgItemSeeClick(item, index) {
      this.dialogFileViewPrivateInfo = {
        show: true,
        type: "img",
        src: item.fileUrl ? item.fileUrl : item.url,
        curIndex: index ? index : 0,
        imgList:
          index != undefined
            ? this.photoList.map((item) => {
                return item.fileUrl ? item.fileUrl : item.url;
              })
            : [item.fileUrl ? item.fileUrl : item.url],
      };
    },
    imgItemDelClick(index) {
      this.photoList.splice(index, 1);
    },
    imgSelectClick() {
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange() {
      if (this.photoList.length == 8) {
        this.msgWarn("最多只能上传八张图片！");
        return;
      }
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUploads(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.photoList.push({
            // name: uploadRes[0].originalName,
            url: uploadRes[0].url,
          });
        }
      });
    },
    modelHandleClick(tab, event) {
      if (tab.index == 0) {
        this.teacherInfo();
      } else if (tab.index == 1) {
        this.evaluateName = "1";
        this.teacherEvaluatedList(0);
        this.teacherEvaluatedContent();
      } else if (tab.index == 2) {
        this.getTeacherClassDetailsCountFn();
      }
    },
    //上课详情接口
    getTeacherClassDetailsCountFn() {
      getTeacherClassDetailsCount({ teacherId: this.teacherId }).then((res) => {
        if (res.code == 0) {
          this.classInfoData = res.rows;
        }
      });
    },
    teacherEvaluatedList(num) {
      var data = {
        teacherId: this.teacherId,
        evaluationSource: num,
      };
      teacherEvaluatedList(data).then((res) => {
        if (res.code == 0) {
          if (num == 0) {
            //教务评价
            this.evaluateUlData = res.rows;
          } else {
            //学生评价
            this.evaluateUlData2 = res.rows;
          }
        }
      });
    },
    addStudyReport() {
      this.addStudyPopup = true;
      this.comparisonClassHours = false;
      this.classroomFeedback = false;
      this.cancellationOfClass = false;
      this.followUpClassPlan = false;
      this.adviceToParents = false;
      this.suggestionsForParticipants = false;
      this.startTime = null;
      this.endTime = null;
      this.reportValue = "";
      this.reportNum = 0;
      this.reportNum2 = 0;
      this.reportNum3 = 0;
      this.reportNum4 = 0;
      this.studyRuleForm.id = "";
      this.teaEarnest = [];
      this.teaFinish = [];
      this.interactionName = [];
      this.interactionData = [];
      this.teaUnderstand = [];
      this.workHome = [];
      for (var i = 0; i < this.subjectidList.length; i++) {
        this.subjectidList[i].flag = false;
        this.subjectidList[i].plan = "";
        this.subjectidList[i].classHours = "";
        this.subjectidList[i].comparisonOne = "";
        this.subjectidList[i].stuComparisonClassHours = "";
      }
      for (var i = 0; i < this.subjectData.length; i++) {
        this.subjectData[i].flag = false;
        this.subjectData[i].plan = "";
        this.subjectData[i].classHours = "";
        this.subjectData[i].comparisonOne = "";
        this.subjectData[i].stuComparisonClassHours = "";
      }
      this.parentProposal = "";
      this.stuProposal = "";
      this.$nextTick(() => {
        this.drawChart();
        this.drawChart2();
      });
      this.addStudyParams = {
        type: "add",
        title: "新增学习报告",
      };
    },
    confirmStudent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.studentRuleForm.testPapers = "";
          let papersList = this.testPapers.map((obj) => {
            return obj.url;
          });
          this.studentRuleForm.testPapers = papersList.join(",");
          this.studentRuleForm.studentId = this.getstudentid;
          // this.studentRuleForm.subjectId=this.getMacroId;
          if (this.studentResultDialog.type == "add") {
            for (var i = 0; i < this.subjectList.length; i++) {
              if (this.subjectList[i].macroId == this.getSubjectId) {
                this.subjectList[i].xmtStudentAcademicRecordList.push(
                  this.studentRuleForm
                );
              }
            }
          } else if (this.studentResultDialog.type == "edit") {
            for (var i = 0; i < this.subjectList.length; i++) {
              if (this.subjectList[i].id) {
                for (
                  var j = 0;
                  j < this.subjectList[i].xmtStudentAcademicRecordList.length;
                  j++
                ) {
                  if (
                    this.subjectList[i].xmtStudentAcademicRecordList[j].id ==
                    this.editId
                  ) {
                    this.subjectList[i].xmtStudentAcademicRecordList[j] =
                      this.studentRuleForm;
                  } else {
                    //  this.subjectList[i].xmtStudentAcademicRecordList[j] =[]
                  }
                }
              }
            }
          }
          this.studentResultSync = false;
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    //试卷预览
    paperPreview(item) {
      this.previewDialogVisible = true;
      this.paperId = item.id;
      if (item.id) {
        getObjectById(item.id)
          .then((res) => {
            if (res.code == 0) {
              this.previewRuleForm = res.rows;
              if (res.rows.testPapers) {
                this.previewImage = res.rows.testPapers.split(",");
              } else {
                this.previewImage = [];
              }
              this.previewRuleForm.examinationTime =
                res.rows.examinationTime.split(" ")[0];
            }
          })
          .catch(() => {});
      } else {
        this.previewRuleForm.examinationType = item.examinationType;
        this.previewRuleForm.subjectScore = item.subjectScore;
        this.previewRuleForm.subjectAllScore = item.subjectAllScore;
        this.previewRuleForm.remarks = item.remarks;
        this.previewImage = item.testPapers.split(",");
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        this.previewRuleForm.examinationTime = y + "-" + m + "-" + d;
      }
    },
    //试卷编辑
    editStudent(item) {
      this.editId = item.id;
      this.testPapers = [];
      this.studentResultSync = true;
      this.studentResultDialog = {
        title: "编辑试卷信息",
        type: "edit",
      };
      if (item.id) {
        getObjectById(item.id)
          .then((res) => {
            if (res.code == 0) {
              this.studentRuleForm = res.rows;
              let getTestPapers = res.rows.testPapers.split(",");
              if (res.rows.testPapers != "undefined") {
                getTestPapers.forEach((item) => {
                  //处理图片需要转格式
                  this.testPapers.push({
                    url: item,
                  });
                });
              } else {
                this.testPapers = [];
              }
            }
          })
          .catch(() => {});
      } else {
        this.studentRuleForm.examinationType = item.examinationType;
        this.studentRuleForm.subjectScore = item.subjectScore;
        this.studentRuleForm.subjectAllScore = item.subjectAllScore;
        this.studentRuleForm.remarks = item.remarks;
        let getTestPapers = item.testPapers.split(",");
        if (item.testPapers != "undefined") {
          getTestPapers.forEach((item) => {
            //处理图片需要转格式
            this.testPapers.push({
              url: item,
            });
          });
        } else {
          this.testPapers = [];
        }
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        this.studentRuleForm.examinationTime = y + "-" + m + "-" + d;
      }
    },
    CB_addStudentStatus() {
      this.addStudentStatus = false;
      this.teacherManagerSave();
    },
    CB_downOrderStatus() {
      this.downOrderStatus = false;
      this.orderListFn();
      this.rechargeOrderList();
    },
    //试卷删除
    paperRemove(item, index, indexs) {
      if (item.id == "" || item.id == undefined) {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.subjectList[index].xmtStudentAcademicRecordList.splice(
            indexs,
            1
          );
        });
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        academicrecordRemove(item.id)
          .then((res) => {
            if (res.code == 0) {
              this.msgSuccess("删除成功！");
              this.daalEdit();
              this.subjectList[index].xmtStudentAcademicRecordList.splice(
                indexs,
                1
              );
            }
          })
          .catch(() => {});
      });
    },
    seeClassRecord(row) {
      this.classRecordStatus = true;
      this.classRecordParams = {
        studentId: row.studentId,
        orderId: row.id,
      };
    },
    addReceivingNow(num) {
      if (this.orderReceivingHandle.length == 0) {
        this.msgWarn("请选择讲师!");
        return;
      }
      getConfigureCourseById({
        studentId: this.idd,
        subjectId: this.orderReceivingHandle[0].subjectId,
      }).then((res) => {
        if (res.code == 0) {
          var data = {
            label: 0,
            subjectId: this.teacherListId,
            xmtConfigureCourseList: [],
            classSinglePrice: 0,
            courseLevel: 0,
          };
          var rowsData = [];
          data.xmtConfigureCourseList = res.rows;
          this.teacherList.unshift(data);
          if (num == 1) {
            this.teacherList[0].label = 2;
            var data = {
              studentId: this.idd,
              teacherId: this.orderReceivingHandle[0].id,
            };
            checkHistoryChooseTeacher(data).then((res) => {
              if (res.code == 0) {
                if (res.rows == true) {
                  //配置过
                  this.historyDate.studentId = this.idd;
                  this.$confirm(
                    "该讲师历史配置里配置过，是否继续选择?",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.orderReceivingPopup = false;
                      this.activeName = "second";
                    })
                    .catch(() => {});
                } else {
                  this.orderReceivingPopup = false;
                  this.activeName = "second";
                }
                this.teacherList[0].teacherRealname =
                  this.orderReceivingHandle[0].realname;
                this.teacherList[0].newTeaChatId =
                  this.orderReceivingHandle[0].teaChatId;
                this.teacherList[0].teacherId = this.orderReceivingHandle[0].id;
                this.teacherList[0].hopeMinFee =
                  this.orderReceivingHandle[0].hopeMinFee;
                this.teacherList[0].hopeMaxFee =
                  this.orderReceivingHandle[0].hopeMaxFee;
                this.$set(this.teacherList, 0, this.teacherList[0]);
                this.teacherList[0].show = true;
              }
            });
          } else {
            var data = {
              studentId: this.idd,
              teacherId: this.orderReceivingHandle[0].id,
            };
            checkHistoryChooseTeacher(data).then((res) => {
              if (res.code == 0) {
                if (res.rows == true) {
                  this.$confirm(
                    "该讲师历史配置里配置过，是否继续选择?",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.orderReceivingPopup = false;
                      this.activeName = "second";
                    })
                    .catch(() => {});
                } else {
                  this.orderReceivingPopup = false;
                  this.activeName = "second";
                }
                this.teacherList[0].teacherRealname =
                  this.orderReceivingHandle[0].realname;
                this.teacherList[0].newTeaChatId =
                  this.orderReceivingHandle[0].teaChatId;
                this.teacherList[0].teacherId = this.orderReceivingHandle[0].id;
                this.teacherList[0].hopeMinFee =
                  this.orderReceivingHandle[0].hopeMinFee;
                this.teacherList[0].hopeMaxFee =
                  this.orderReceivingHandle[0].hopeMaxFee;
                this.$set(this.teacherList, 0, this.teacherList[0]);
                this.teacherList[0].show = true;
              }
            });
            this.teacherList[0].label = 1;
          }
        }
      });
    },
    examinationTimeFn(row) {
      let date = new Date(row);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    examinationTypeFn(row) {
      if (row == 1) {
        return "模拟考试";
      } else if (row == 2) {
        return "期中";
      } else if (row == 3) {
        return "期末";
      } else if (row == 4) {
        return "月考";
      } else if (row == 5) {
        return "随堂练";
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      for (var i = 0; i < this.testPapers.length; i++) {
        if (this.testPapers[i].url === file.url) {
          this.testPapers.splice(i, 1);
        }
      }
    },
    handleAvatarSuccess(res, file) {
      studentUpdate({
        id: this.getstudentid,
        teachManagerPhoto: res[0].url,
        photoFlag: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.studentInfo(this.getstudentid);
        }
      });
      this.updateImg.teachManagerPhoto = res[0].url;
    },

    evaluatedSizeChange(pageSize) {
      this.newPageSize = pageSize;
      this.teacherEvaluatedContent();
    },
    evaluatedCurrentChange(currentPage) {
      this.newPageNumber = currentPage;
      this.teacherEvaluatedContent();
    },
    evaluatedSizeChange2(pageSize) {
      this.newPageSize2 = pageSize;
      this.teacherEvaluatedContent2();
    },
    evaluatedCurrentChange2(currentPage) {
      this.newPageNumber2 = currentPage;
      this.teacherEvaluatedContent2();
    },
    evaluateHandleClick(tab) {
      if (tab.index == 0) {
        this.teacherEvaluatedList(0);
        this.teacherEvaluatedContent();
      }
      if (tab.index == 1) {
        this.teacherEvaluatedList(1);
        this.teacherEvaluatedContent2();
      }
    },
    evaluateActiveClick(num) {
      this.evaluateActive = num;
      this.newPageSize = 10;
      this.newPageNumber = 1;
      this.teacherEvaluatedContent();
    },
    evaluateActiveClick2(num) {
      this.evaluateActive2 = num;
      this.newPageSize2 = 10;
      this.newPageNumber2 = 1;
      this.teacherEvaluatedContent2();
    },
    showImg(e) {
      if (e.target.tagName == "IMG") {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
      }
    },
    teacherEvaluatedContent() {
      if (this.evaluateActive == 0 && this.evaluateName == 1) {
        //全部教务评价老师
        this.evaluatedContentParams = {
          pageNumber: this.newPageNumber,
          pageSize: this.newPageSize,
          evaluationSource: 0,
          teacherId: this.teacherId,
        };
      } else if (this.evaluateActive == 0 && this.evaluateName == 2) {
        //全部学生评价老师
        this.evaluatedContentParams = {
          pageNumber: this.newPageNumber,
          pageSize: this.newPageSize,
          evaluationSource: 1,
          teacherId: this.teacherId,
        };
      } else if (this.evaluateName == 1) {
        //教务评价老师
        this.evaluatedContentParams = {
          pageNumber: this.newPageNumber,
          pageSize: this.newPageSize,
          evaluationSource: 0,
          evaluationGrade: this.evaluateActive,
          teacherId: this.teacherId,
        };
      } else if (this.evaluateName == 2) {
        //学生评价老师
        this.evaluatedContentParams = {
          pageNumber: this.newPageNumber,
          pageSize: this.newPageSize,
          evaluationSource: 1,
          evaluationGrade: this.evaluateActive,
          teacherId: this.teacherId,
        };
      }
      teacherEvaluatedContent(this.evaluatedContentParams).then((res) => {
        this.evaluateTable = res.rows;

        this.evaluateTotal = res.total;
      });
    },
    teacherEvaluatedContent2() {
      if (this.evaluateActive2 == 0 && this.evaluateName == 2) {
        //全部学生评价老师
        this.evaluatedContentParams2 = {
          pageNumber: this.newPageNumber2,
          pageSize: this.newPageSize2,
          evaluationSource: 1,
          teacherId: this.teacherId,
        };
      } else if (this.evaluateName == 2) {
        //学生评价老师
        this.evaluatedContentParams2 = {
          pageNumber: this.newPageNumber2,
          pageSize: this.newPageSize2,
          evaluationSource: 1,
          evaluationGrade: this.evaluateActive2,
          teacherId: this.teacherId,
        };
      }
      teacherEvaluatedContent(this.evaluatedContentParams2).then((res) => {
        this.evaluateTable2 = res.rows;
        this.evaluateTotal2 = res.total;
      });
    },
    educationFn(row) {
      var educationstr = "";
      if (row.educationId == 70) {
        educationstr = "专科";
      } else if (row.educationId == 69) {
        educationstr = "本科";
      } else if (row.educationId == 68) {
        educationstr = "硕士";
      } else if (row.educationId == 67) {
        educationstr = "博士";
      } else {
        educationstr = "暂无数据";
      }
      return educationstr;
    },
    teachingAgeFn(row) {
      var teachingAgestr = "";
      if (row.teachingAgeId == 27) {
        teachingAgestr = "5年以下";
      } else if (row.teachingAgeId == 28) {
        teachingAgestr = "5-10";
      } else if (row.teachingAgeId == 29) {
        teachingAgestr = "10-15";
      } else if (row.teachingAgeId == 227) {
        teachingAgestr = "15-20以上";
      }
      return teachingAgestr;
    },
    educationIdFn(row) {
      if (row == 70) {
        return "专科";
      } else if (row == 69) {
        return "本科";
      } else if (row == 68) {
        return "硕士";
      } else if (row == 67) {
        return "博士";
      }
    },
    identityTypeFn(row) {
      var identityTypestr = "";
      if (row.identityType == 1) {
        identityTypestr = "学科类--老师";
      } else if (row.identityType == 2) {
        identityTypestr = "学科类--大学生";
      } else if (row.identityType == 3) {
        identityTypestr = "素质教育类--老师";
      } else if (row.identityType == 4) {
        identityTypestr = "素质教育类--大学生";
      }
      return identityTypestr;
    },
    formatterMoneyType(item) {
      switch (item.moneyType) {
        case 1:
          return "全款";
        case 2:
          return "定金";
        case 3:
          return "尾款";
        case 4:
          return "特殊订单";
        case 5:
          return "余额转充值";
        default:
          return "";
      }
    },
    skilfulFn(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.styleNeedArray[i].macroId == arr[k]) {
            str += this.styleNeedArray[i].name.split("：")[0] + "，";
          }
        }
      }
      return str.substring(str.length - 1, 0);
    },
    closeDialog() {},
    resultList() {
      getSelectList({
        //学生成绩类型
        parentCode: resultCode,
      }).then((res) => {
        if (res.rows) {
          this.studentAchievementList = res.rows;
        }
      });
      getSelectList({
        //教龄
        parentCode: teachAge,
      }).then((res) => {
        if (res.rows) {
          this.teachingAgeIdList = res.rows;
          this.teachingAgeIdList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
      getSelectList({
        //普通话
        parentCode: mandarinCode,
      }).then((res) => {
        if (res.rows) {
          this.mandarinIdList = res.rows;
          this.mandarinIdList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
      getSelectList({
        parentCode: achievement,
      }).then((res) => {
        if (res.code == 0) {
          this.skilfulTeachDate = res.rows;
        }
      });
      getSelectList({
        //风格偏好
        parentCode: teachStyle,
      }).then((res) => {
        if (res.rows) {
          this.styleNeedArray = res.rows;
          this.skilfulDate = res.rows;
        }
      });
    },
    mandarinIdFn(item) {
      for (var i = 0; i < this.mandarinIdList.length; i++) {
        if (this.mandarinIdList[i].macroId == item) {
          return this.mandarinIdList[i].name;
        }
      }
    },
    studentAchievementIdFn(item) {
      for (var i = 0; i < this.studentAchievementList.length; i++) {
        if (this.studentAchievementList[i].macroId == item) {
          return this.studentAchievementList[i].name;
        }
      }
    },
    dealRemove(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentDelete([row.id]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.teacherManagerSave();
            }
          });
        })
        .catch(() => {});
    },
    balanceTurn(row) {
      this.$confirm("确定余额转充值吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          balanceRecharge({ id: row.id }).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.orderListFn();
            } else {
              this.msgWarn(res.rows);
            }
          });
        })
        .catch(() => {});
    },
    editOrderClick(row) {
      this.downOrderStatus = true;
      this.downParams = {
        id: row.studentId,
        type: "edit",
        classId: row.id,
      };
    },
    sourceTypeFn(row) {
      let type;
      for (let i = 0, len = this.sourceTypeList.length; i < len; i++) {
        if (row == this.sourceTypeList[i].value) {
          type = this.sourceTypeList[i].label;
          break;
        }
      }
      return type;
    },

    getFollowTagList() {
      return getSelectList({
        parentCode: followTags,
      }).then((res) => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].select = false;
          }
          this.followTagList = res.rows;
        }
      });
    },
    defaultValue() {
      let d = new Date();
      return (
        `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ` + "06:00:00"
      );
    },
    mouseOver(e, item) {
      if (item.attendClassStatus == 0) {
        e.currentTarget.firstElementChild.className = "td_active";
      }
    },
    mouseLeave(e, item) {
      e.currentTarget.firstElementChild.className = "none";
    },
    submitExamine(formName) {
      if (
        this.saveOrderForm.refundAmount < 0 ||
        this.saveOrderForm.realityRefundAmount < 0
      ) {
        this.msgWarn("订单消耗已超出实际支付金额，不支持退款！");
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          studentRefundSave(this.saveOrderForm).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.refundSync = false;
              this.orderListFn();
            } else {
              this.msgWarn(res.rows);
              return false;
            }
          });
        }
      });
    },
    beginDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.batchPickerValue3) {
            return new Date(self.batchPickerValue3).getTime() < time.getTime();
          }
        },
      };
    },
    processDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.batchPickerValue2) {
            return new Date(self.batchPickerValue2).getTime() > time.getTime();
          }
        },
      };
    },
    setFreeTime() {
      this.freePopup = true;
      this.freePickerValue = "";
      this.freeWeeks = [];
      this.value1 = "";
    },
    cancelFreeTime() {
      if (this.freeId.length == 0) {
        this.msgWarn("设置批量空闲课程不能为空！");
        return false;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchRemoveFreetime(this.freeId).then((res) => {
            if (res.code == 0) {
              this.freeId = [];
              this.msgSuccess("操作成功！");
              this.listStudentCourseFn();
            }
          });
        })
        .catch(() => {});
    },
    startTimeChange() {
      this.studyTimes.searchBeginTime = this.startTime + " 00:00:00";
    },
    endTimeChange() {
      this.studyTimes.searchEndTime = this.endTime + " 23:59:59";
    },
    seeFollow(row) {
      this.followDialog = true;
      this.followId = row.id;
    },
    studentPopupFn(id, status) {
      this.levelParams.levelId = "";
      this.rechargeList = [];
      this.switchValue = true;
      this.idd = "";
      this.getstudentid = "";
      this.tabsActive = "record";
      this.getstudentid = id;
      this.saveOrderForm.studentId = id;
      this.studentDetailPopup = true;
      this.needRuleForm.studentId = id;
      this.xmtStudentNeeds.studentId = id;
      this.getstatus = status;
      this.findListParams.subjectId = "";
      this.findListParams.attendClassStatus = "1";
      this.findListParams.abnormalStatus = "";
      this.timePickerOne = "";
      this.oneToMoreParams.roomName = "";
      this.oneToMoreParams.attendClassStatus = "1";
      this.oneToMoreParams.abnormalStatus = "";
      this.timePickerMore = "";
      this.delParams.delCause = "";
      this.timePickerDel = "";
      studentUpdate({ id: id, otoAllocateIsNew: 0 }).then((res) => {
        if (res.code == 0) {
        }
      });
      this.studentInfo(id);
      this.demandFn(id);
      this.$nextTick(() => {
        this.getFreeWeekFn();
        this.listStudentCourseFn();
      });
      this.listStudentCourseFn();
    },
    async editProvinceChange() {
      await this.getEditCityList();
      this.editRuleForm.cityId = this.cityList[0].areaId;
      this.editCityChange();
    },
    async editCityChange() {
      await this.editgetCountyList();
      this.editRuleForm.countyId = this.countyList[0].areaId;
    },
    studentInfo(id) {
      //获取详情
      studentInfo(id).then((res) => {
        if (res.rows) {
          this.studentListDetail = res.rows;
          this.updateImg.teachManagerPhoto = res.rows.teachManagerPhoto;
          this.updateImg.photoFlag = res.rows.photoFlag;
          this.rechargeParams.surplusMoney = res.rows.accountBalance
            ? res.rows.accountBalance
            : 0;
          this.rechargeParams.classHours = res.rows.remainingClassHours
            ? res.rows.remainingClassHours
            : 0;
        }
      });
    },
    demandFn(id) {
      xmtStudentNeedsList(this.xmtStudentNeeds).then((res) => {
        this.needList = res.rows;
        this.needTotal = res.total;
      });
    },
    demandDetailFn() {
      this.xmtStudentNeeds.studentId = this.idd;
      xmtStudentNeedsList(this.xmtStudentNeeds).then((res) => {
        this.demandList = res.rows;
        this.demandTotal = res.total;
      });
    },
    demandSizeChange(pageSize) {
      this.xmtStudentNeeds.pageSize = pageSize;
      this.demandDetailFn();
    },
    demandCurrentChange(currentPage) {
      this.xmtStudentNeeds.pageNumber = pageSize;
      this.demandDetailFn();
    },
    handleNeedSizeChange(pageSize) {
      this.needRuleForm.pageSize = pageSize;
      this.demandFn();
    },
    handleNeedCurrentChange(currentPage) {
      this.needRuleForm.pageNumber = currentPage;
      this.demandFn();
    },
    addNeed() {
      this.needPopup = true;
      this.skilfulArray = [];
      this.needTitle = {
        type: "add",
        title: "新增需求",
      };
      this.needRuleForm = {
        mandarinId: 0,
        studentAchievementId: "",
        speedNeedId: 0,
        ageNeedId: 0,
        sectionId: "",
        teachTitleId: 0, //讲师职称
        teachingAgeId: 0, //教龄
        sexNeedId: 0, //性别偏好
        priorDateOne: "",
        priorDateTwo: "",
        priorDateThree: "",
        priorTimeOne: "",
        priorTimeTwo: "",
        priorTimeThree: "",
        classTimes: "",
        classForm: 0,
        faceProvinceId: "",
        faceCityId: "",
        faceCountyId: "",
        studentDescribe: "",
        studentDescribe: "",
        studentId: this.getstudentid,
        subjectId: "",
        styleNeedId: "",
        pageNumber: 1,
        pageSize: 10,
      };
    },
    classHourTypeChange() {
      this.$set(this.singleRuleForm, "productId", "");
      this.surplusNum = 0;
    },
    batchHourTypeChange() {
      this.newProductId = "";
      this.batchClassHourType = this.batchTimeForm.classHourType;
      this.surplusNum2 = 0;
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].classHourType = this.batchClassHourType;
      }
    },
    async editStudentInfo() {
      await this.getProvinceList();
      for (var i = 0; i < this.subjectList.length; i++) {
        this.subjectList[i].xmtStudentAcademicRecordList = [];
      }
      await studentInfo(this.getstudentid).then((res) => {
        if (res.code == 0) {
          this.editRuleForm = {
            studentsOtherNeeds: res.rows.studentsOtherNeeds,
            name: res.rows.name,
            contact: res.rows.contact,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            schoolName: res.rows.schoolName,
            sourceType:
              res.rows.sourceType == 0 ? "" : String(res.rows.sourceType),
            areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            referralphone: res.rows.referralphone,
            referralstudent: res.rows.referralstudent,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            qitaxueke: res.rows.qitaxueke,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            xmtStudentSubjectRelationEntityList: [],
            id: res.rows.id,
            schoolReach: res.rows.schoolReach,
            contract: res.rows.contract,
            no: res.rows.no,
          };
          if (this.editRuleForm.provinceId) {
            this.getEditCityList();
          }
          if (this.editRuleForm.cityId) {
            this.getEditCountyList();
          }
          this.initClassTime();
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList[i][j].key
                )
              ) {
                this.firstClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList[i][j].key
                )
              ) {
                this.formalClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            this.subjectList[i].status = false;
            this.subjectList[i].select = false;
            this.subjectList[i].subjectScore = "";
            this.subjectList[i].subjectAllScore = "";
            this.subjectList[i].sexNeedId = "";
            this.subjectList[i].styleNeedId = "";
            this.subjectList[i].speedNeedId = "";
            this.subjectList[i].ageNeedId = "";
            this.subjectList[i].content = "";
            if (
              (this.subjectList[i].name == "其他学科" &&
                res.rows.qitaxueke == 1) ||
              (this.subjectList[i].name == "语文" && res.rows.yuwen == 1) ||
              (this.subjectList[i].name == "数学" && res.rows.shuxue == 1) ||
              (this.subjectList[i].name == "英语" && res.rows.yingyu == 1) ||
              (this.subjectList[i].name == "历史" && res.rows.lishi == 1) ||
              (this.subjectList[i].name == "政治" && res.rows.zhengzhi == 1) ||
              (this.subjectList[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList[i].name == "化学" && res.rows.huaxue == 1) ||
              (this.subjectList[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            for (
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList[i].xmtStudentAcademicRecordList =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].xmtStudentAcademicRecordList;
                this.subjectList[i].status = true;
                this.subjectList[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
                this.subjectList[i].id =
                  res.rows.xmtStudentSubjectRelationEntityList[j].id;
              }
            }
          }
        }
      });
      this.editDialogVisible = true;
    },
    addStudentResult(item, index) {
      this.getSubjectId = item.macroId;
      this.studentResultSync = true;
      this.studentRuleForm = {
        examinationType: "", //考试类型,
        subjectScore: "", //成绩
        subjectAllScore: "", //总分
        examinationTime: "", //考试时间
        remarks: "", //备注说明
      };
      this.testPapers = [];
      this.studentResultDialog = {
        title: "添加试卷信息",
        type: "add",
      };
    },
    info() {},
    orderItemClick(row) {
      this.$refs.orderListTable.toggleRowSelection(row);
    },
    rechargeItemClick(row) {
      this.$refs.rechargeListTable.toggleRowSelection(row);
    },
    studyReportChange() {},
    studyReportClick() {},
    handleOrderChange(val) {
      this.valLength = val.length;
      this.studentIdInfo = val[0].studentId;
      this.getOrderStatus = [];
      this.selectOrderList = [];
      this.orderChangeId = "";
      if (val.length > 0) {
        for (var i = 0; i < val.length; i++) {
          this.selectOrderList.push(val[i].id);
          this.orderChangeId += val[i].id + ",";
          this.getOrderStatus.push(val[i].orderStatus);
        }
        if (this.orderChangeId.length > 0) {
          this.orderChangeId = this.orderChangeId.substr(
            0,
            this.orderChangeId.length - 1
          );
        }
        this.saveOrderForm.orderIds = this.selectOrderList.toString();
      }
    },
    removeSubject(index, id) {
      if (id) {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            removeByStuId({
              studentId: this.studentRuleForm.id,
              subjectId: id,
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.subjectList[index].status = false;
                this.subjectList[index].speedNeedId = "";
                this.subjectList[index].sexNeedId = "";
                this.subjectList[index].ageNeedId = "";
                this.subjectList[index].styleNeedId = "";
                this.subjectList[index].xmtStudentAcademicRecordList = [];
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.msgSuccess("操作成功！");
            this.subjectList[index].status = false;
            this.subjectList[index].speedNeedId = "";
            this.subjectList[index].sexNeedId = "";
            this.subjectList[index].ageNeedId = "";
            this.subjectList[index].styleNeedId = "";
            this.subjectList[index].xmtStudentAcademicRecordList = [];
          })
          .catch(() => {});
      }
    },
    seeNeed() {
      this.needTitle = {
        type: "see",
        title: "查看需求",
      };
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单条数据进行编辑操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一条数据进行编辑操作！");
        return;
      }
      this.needPopup = true;
      this.getDetailByStudentId();
    },
    change_followStatus() {
      this.followDialog = false;
    },
    change_studentStatu() {
      this.studentStatuDialog = false;
      this.alreadyDistribute();
      this.alreadyDistributeCountFn();
    },
    editNeed() {
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单条数据进行编辑操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一条数据进行编辑操作！");
        return;
      }
      this.needTitle = {
        type: "edit",
        title: "修改需求",
      };
      this.needPopup = true;
      this.getDetailByStudentId();
    },
    deleteNeed() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一条数据！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          xmtStudentNeedsRemove(this.selectUserList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.demandFn();
            }
          });
        })
        .catch(() => {});
    },
    saveNeed(formName) {
      this.needRuleForm.styleNeedId = this.skilfulArray.join(); //讲课风格
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.needTitle.type == "add") {
            xmtStudentNeedsSave(this.needRuleForm).then((res) => {
              if (res.code == 0) {
                this.needPopup = false;
                this.msgSuccess("操作成功！");
                this.demandFn();
              }
            });
          } else if (this.needTitle.type == "edit") {
            xmtStudentNeedsUpdate(this.needRuleForm).then((res) => {
              if (res.code == 0) {
                this.needPopup = false;
                this.msgSuccess("操作成功！");
                this.demandFn();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    getFirstTeachTime(firstTeachTime) {
      if (!firstTeachTime) {
        return "";
      }
      let firstTeachTimeArr = firstTeachTime.split(",");
      return firstTeachTimeArr
        .map((value) => {
          return this.wordTimeObj[value];
        })
        .join(",");
    },
    saveLesson(formName, num) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let normalClassHoursListArr = [],
            firstClassTimeListArr = [],
            weekArr = [];

          if (num == 0) {
            this.saveLessonForm.publishStatus = 0;
          } else {
            this.saveLessonForm.publishStatus = 1;
          }
          this.saveLessonForm.sexId = this.getSexId;
          for (
            let i = 0, len = this.normalClassHoursList.length;
            i < len;
            i++
          ) {
            for (
              let j = 0, lenJ = this.normalClassHoursList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.normalClassHoursList[i][j].status) {
                normalClassHoursListArr.push(
                  this.normalClassHoursList[i][j].key
                );
                weekArr.push(
                  this.normalClassHoursList[i][j].week +
                    this.normalClassHoursList[i][j].name
                );
              }
            }
          }
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.firstClassTimeList[i][j].status) {
                firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
              }
            }
          }
          this.saveLessonForm.normalClassHours =
            normalClassHoursListArr.join(",");
          this.saveLessonForm.firstTeachTime = firstClassTimeListArr.join(",");
          this.saveLessonForm.weekArr = weekArr.join(",");
          this.saveLessonForm.styleNeedId = this.styleNeedGroup.join(",");
          this.saveLessonForm.countyId = this.saveCountyId;
          this.saveLessonForm.provinceId = this.saveProvinceId;
          this.saveLessonForm.cityId = this.saveCityId;
          if (this.saveLessonDialog.type == "add") {
            orderPublishSave(this.saveLessonForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveLessonDialogVisible = false;
                this.orderPublishListFn();
              }
            });
          } else if (this.saveLessonDialog.type == "edit") {
            orderPublishUpdate(this.saveLessonForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveLessonDialogVisible = false;
                this.orderPublishListFn();
              }
            });
          }
        }
      });
    },
    getCityLessonList() {
      return systemAreaList({
        parentId: this.saveLessonForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.cityLessonList = res.rows;
        }
      });
    },
    getCountyLessonList() {
      return systemAreaList({
        parentId: this.saveLessonForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.countyLessonList = res.rows;
        }
      });
    },
    async provinceLessonChange() {
      await this.getCityLessonList();
      this.saveLessonForm.cityId = this.cityLessonList[0].areaId;
      for (var i = 0; i < this.provinceLessonList.length; i++) {
        if (
          this.saveLessonForm.provinceId == this.provinceLessonList[i].areaId
        ) {
          this.saveLessonForm.addressNames += this.provinceLessonList[i].name;
        }
      }
      this.saveLessonForm.addressNames += this.cityLessonList[0].name;
      this.cityLessonChange();
    },
    async cityLessonChange() {
      await this.getCountyLessonList();
      this.saveLessonForm.countyId = this.countyLessonList[0].areaId;
      this.saveLessonForm.addressNames += this.countyLessonList[0].name;
    },
    handleNeedChange(val) {
      this.selectUserList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectUserList.push(val[i].id);
        }
      }
    },
    sureRemoveClass() {
      if (this.removeClassRadio == "") {
        this.msgWarn("请选择删除原因！");
        return;
      }
      let data = {
        id: this.getRemoveId,
        delFlag: 0,
        delCause: this.removeClassRadio,
      };
      xmtProductItemUpdate(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.findCourseItemFnn();
          this.removeClassVisible = false;
          this.removeClassRadio = "";
        }
      });
    },
    seeFree(e, item, index) {
      if (item.name) {
        item.flag = !item.flag;
        if (this.freeId.indexOf(item.id) > -1) {
          this.freeId.splice(this.freeId.indexOf(item.id), 1);
        } else {
          this.freeId.push(item.id);
        }
      }
    },
    sureBatchRemoveClass() {
      if (this.removeBatchClassRadio == "") {
        this.msgWarn("请选择删除原因！");
        return;
      }
      for (var i = 0; i < this.type_of_cost.length; i++) {
        this.dataList.push({
          id: this.type_of_cost[i],
          delFlag: 0,
          delCause: this.removeBatchClassRadio,
        });
      }
      batchCancel(this.dataList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.removeBatchClassVisible = false;
          this.cancelCoursePopup = false;
          this.removeBatchClassRadio = "";
          this.findCourseItemFnn();
        }
      });
    },
    needItemClick(row) {
      this.$refs.userListTable.toggleRowSelection(row);
    },
    getDetailByStudentId() {
      getDetailByStudentId(this.selectUserList[0]).then((res) => {
        this.needRuleForm = res.rows;
        this.skilfulArray = res.rows.styleNeedId
          .split(",")
          .map(function (item, index, array) {
            return item - 0;
          });
      });
    },
    tabsHandleClick(tab, event) {
      this.classRecord = "1";
      if (tab.index == 1) {
        this.followUpListFn();
      } else if (tab.index == 2) {
        //学习报告
        this.studentStudyReportFn();
      } else if (tab.index == 3) {
        this.orderListFn();
      } else if (tab.index == 4) {
        this.findListParams.studentId = this.getstudentid;
        this.oneToMoreParams.studentId = this.getstudentid;
        this.delParams.studentId = this.getstudentid;
        this.findProductItemPage();
      }
    },
    followUpListFn() {
      this.followUpParams.studentId = this.getstudentid;
      teacherFollowupRecordList(this.followUpParams).then((res) => {
        for (var i = 0; i < res.rows.length; i++) {
          res.rows[i].imageArray = new Array();
          for (var k = 0; k < res.rows[i].photoList.length; k++) {
            res.rows[i].imageArray.push(res.rows[i].photoList[k].url);
          }
        }
        this.followUpList = res.rows;
        for (var i = 0; i < this.followUpList.length; i++) {
          if (this.followUpList.length) {
            this.followUpList[i].followContent = this.followUpList[
              i
            ].followContent.replace(
              /<img/g,
              "<img style='max-width:200px;height:auto;'"
            );
          }
        }
        this.followUpParamsTotal = res.total;
      });
    },
    studentStudyReportFn() {
      this.studyParams.studentId = this.getstudentid;
      studentStudyReportList(this.studyParams).then((res) => {
        this.studyReportList = res.rows;
        this.studyTotal = res.total;
      });
    },
    createLink(row) {
      //生成二维码
      this.qrClassTime =
        "上课时间：" +
        row.searchBeginTime.substring(0, 11) +
        "~" +
        row.searchEndTime.substring(0, 11);
      const text =
        `${this.$myStore.learnType}` +
        "id=" +
        row.id +
        "&studentId=" +
        row.studentId;
      const qrcodeImgEl = document.getElementById("qrcodeImg");
      qrcodeImgEl.innerHTML = "";
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 236,
        height: 236,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(text);
      // 生成海报
      const vm = this;
      const domObj = document.getElementById("posterHtml");
      const qecodeTime = document.getElementById("qecodeTime");
      qecodeTime.innerHTML =
        "上课时间：" +
        row.searchBeginTime.substring(0, 11) +
        "~" +
        row.searchEndTime.substring(0, 11);
      const scaleBy = window.devicePixelRatio;

      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        scale: scaleBy,
        backgroundColor: null,
        onclone(doc) {
          let e = doc.querySelector("#posterHtml");
          e.style.display = "block";
        },
      }).then((canvas) => {
        // 在微信里,可长按保存或转发
        vm.posterImg = canvas.toDataURL("image/png", 1.0);
        vm.imgDown(vm.posterImg);
        this.msgSuccess("海报已生成！");
      });
    },
    // 保存图片到本地
    imgDown(img) {
      var alink = document.createElement("a");
      alink.href = img;
      alink.download = "学习报告"; // 图片名
      alink.click();
    },
    updateStudyReport(row) {
      this.$confirm("确定要发布吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sendStudyReport({
            id: row.id,
            studentId: row.studentId,
            searchBeginTime: row.searchBeginTime,
            searchEndTime: row.searchEndTime,
          }).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentStudyReportFn();
            }
          });
        })
        .catch(() => {});
    },
    removeStudyReport(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentStudyReportRemove(row.id).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentStudyReportFn();
            }
          });
        })
        .catch(() => {});
    },
    orientSure() {
      let arr = [];
      for (var i = 0; i < this.orientList.length; i++) {
        for (var k = 0; k < this.orientList[i].data.length; k++) {
          if (this.orientList[i].data[k].flag == true) {
            arr.push(this.orientList[i].data[k].id);
          }
        }
      }
      if (arr.length == 0) {
        this.msgWarn("课单不能为空！");
        return;
      }
      this.orientParams.listTeacherId = this.orientIdArray;
      this.orientParams.listTeacherId = arr;
      updateOrientOrderPublish(this.orientParams).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.orientPopup = false;
          this.orderPublishListFn();
        } else {
          this.msgWarn(res.msg);
        }
      });
    },
    orderListFn() {
      if (this.getKey == 3) {
        orderList({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.orderList = res.rows;
          }
        });
        getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.surplusMoney = res.rows;
          }
        });
      } else {
        orderList({ studentId: this.getstudentid, dataAuthority: "user" }).then(
          (res) => {
            if (res.code == 0) {
              this.orderList = res.rows;
            }
          }
        );
        getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 3,
        }).then((res) => {
          if (res.code == 0) {
            this.surplusMoney = res.rows;
          }
        });
      }
    },
    rechargeOrderList() {
      if (this.getKey == 3) {
        orderList({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.orderList = res.rows;
          }
        });
        getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.surplusMoney = res.rows;
          }
        });
      } else {
        orderList({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 5,
        }).then((res) => {
          if (res.code == 0) {
            this.rechargeList = res.rows;
          }
        });
        getOtoPriceByStudentId({
          studentId: this.getstudentid,
          dataAuthority: "user",
          orderType: 5,
        }).then((res) => {
          if (res.code == 0) {
            this.rechargeParams.total = res.rows ? res.rows : 0;
          }
        });
      }
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    levelChange() {
      this.setStudentType();
    },
    setStudentType() {
      var data = {
        levelId: this.levelParams.levelId,
        id: this.getstudentid,
      };
      setStudentlevel(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.studentDetailPopup = false;
          if (this.getKey == 1) {
            this.teacherManagerSave();
            this.showSaveDialog = true;
          } else if (this.getKey == 2) {
            this.alreadyDistribute();
            this.showSaveDialog = false;
          } else if (this.getKey == 3) {
            this.alreadyDistributeMore();
            this.showSaveDialog = false;
          }
        }
      });
    },
    formatterOrderStatus(row) {
      switch (row.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
        case 10:
          return "已转为充值";
      }
    },
    formatterTotalPrice(row) {
      console.log(row.zdyTotalPrice, row.totalPrice, "zdyTotalPrice...");
      return row.zdyTotalPrice ? row.zdyTotalPrice : row.totalPrice;
    },
    formatterZdyActualPrice(row) {
      //9.28 没有实际支付  就显示总价字段
      if (row.zdyActualPrice) {
        return row.zdyActualPrice;
      } else {
        if (row.zdyActualPrice) {
          return row.zdyActualPrice;
        } else {
          return row.totalPrice;
        }
      }
    },
    formatterOrderType(row) {
      switch (row.orderType) {
        case 5:
          return "一对一充值";
        case 2:
        case 5:
          return "一对多";
        case 3:
          return "试听课";
        case 4:
          return "公开课";
      }
    },
    reportBlur() {
      this.$nextTick(() => {
        this.$refs.nav0[0].click();
      });
    },
    reportChangeSubject() {
      this.subjectData = [];
      for (var i = 0; i < this.subjectidList.length; i++) {
        this.subjectidList[i].flag = false;
        for (var j = 0; j < this.reportValue.length; j++) {
          if (this.subjectidList[i].macroId == this.reportValue[j]) {
            this.subjectidList[i].flag = true;
            if ((this.subjectidList[i].flag = true)) {
              this.subjectData.push(this.subjectidList[i]);
            }
          }
        }
      }
    },
    averageClassHoursInput(index) {
      this.drawChart();
    },
    averageClassNumInput(index) {
      //课时数
      this.subjectData[index].stuComparisonClassHours = Number(
        this.subjectData[index].stuComparisonClassHours
      );
    },
    averageClassContInput(index) {
      //计划内容
      this.subjectData[index].plan = this.subjectData[index].plan;
    },
    stuComparisonClick(macroId, index) {
      this.reportNum2 = index;
    },
    sendParent() {
      var data = {
        id: this.studyRuleForm.id,
        studentId: this.getstudentid,
        searchBeginTime: this.startTime,
        searchEndTime: this.endTime,
      };
      sendStudyReport(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("发送成功！");
          this.addStudyPopup = false;
          this.studentStudyReportFn();
          this.startTime = "";
          this.endTime = "";
          this.reportValue = "";
          this.parentProposal = "";
          this.stuProposal = "";
          for (var i = 0; i < this.subjectidList.length; i++) {
            this.subjectidList[i].flag = false;
            this.subjectidList[i].stuComparisonClassHours = "";
            this.subjectidList[i].classHours = "";
            this.subjectidList[i].plan = "";
          }
          for (var i = 0; i < this.subjectData.length; i++) {
            this.subjectData[i].flag = false;
            this.subjectData[i].stuComparisonClassHours = "";
            this.subjectData[i].classHours = "";
            this.subjectData[i].plan = "";
          }
        }
      });
    },
    saveReport() {
      this.studyRuleForm.XmtStudentComparisonClassHoursList = [];
      this.studyRuleForm.XmtStudentFollowUpClassPlanList = [];
      for (var i = 0; i < this.subjectData.length; i++) {
        if (
          this.subjectData[i].stuComparisonClassHours == "" ||
          this.subjectData[i].classHours == ""
        ) {
          this.msgWarn("有必填项未填！");
          return false;
        }
        if (this.subjectData[i].classHours) {
          this.studyRuleForm.XmtStudentComparisonClassHoursList.push({
            subjectId: this.subjectData[i].macroId,
            classHour: this.subjectData[i].classHours,
          });
          this.studyRuleForm.XmtStudentFollowUpClassPlanList.push({
            subjectId: this.subjectData[i].macroId,
            classHour: this.subjectData[i].stuComparisonClassHours,
            plan: this.subjectData[i].plan,
          });
        }
      }
      if (this.addStudyParams.title == "新增学习报告") {
        var data = {
          XmtStudentFollowUpClassPlanList:
            this.studyRuleForm.XmtStudentFollowUpClassPlanList,
          XmtStudentComparisonClassHoursList:
            this.studyRuleForm.XmtStudentComparisonClassHoursList,
          studentId: this.getstudentid,
          searchBeginTime: this.startTime,
          searchEndTime: this.endTime,
          reportStatus: 0, //状态
          parentStatus: 0, //查看
          comparisonClassHours: this.comparisonClassHours ? 1 : 0, //上课时长对比状态
          classroomFeedback: this.classroomFeedback ? 1 : 0, //课堂反馈状态
          wrongQuestionBookCollection: 0, //错题本收录状态
          cancellationOfClass: this.cancellationOfClass ? 1 : 0, //取消课状态
          followUpClassPlan: this.followUpClassPlan ? 1 : 0, //后续上课计划状态
          adviceToParents: this.adviceToParents ? 1 : 0, //给家长建议的状态
          parents: this.parentProposal, //给家长的建议
          suggestionsForParticipants: this.suggestionsForParticipants ? 1 : 0, //给学生的建议的状态
          participants: this.stuProposal, //给学生的建议
          subjectIds: this.reportValue.join(","),
        };
        studentStudyReportSave(data).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.addStudyPopup = false;
            this.studentStudyReportFn();
            this.startTime = "";
            this.endTime = "";
            this.reportValue = "";
            this.parentProposal = "";
            this.stuProposal = "";
            for (var i = 0; i < this.subjectidList.length; i++) {
              this.subjectidList[i].flag = false;
              this.subjectidList[i].stuComparisonClassHours = "";
              this.subjectidList[i].classHours = "";
              this.subjectidList[i].plan = "";
            }
            for (var i = 0; i < this.subjectData.length; i++) {
              this.subjectData[i].flag = false;
              this.subjectData[i].stuComparisonClassHours = "";
              this.subjectData[i].classHours = "";
              this.subjectData[i].plan = "";
            }
          }
        });
      } else {
        var data = {
          id: this.studyRuleForm.id,
          XmtStudentFollowUpClassPlanList:
            this.studyRuleForm.XmtStudentFollowUpClassPlanList,
          XmtStudentComparisonClassHoursList:
            this.studyRuleForm.XmtStudentComparisonClassHoursList,
          studentId: this.getstudentid,
          searchBeginTime: this.startTime,
          searchEndTime: this.endTime,
          reportStatus: 0, //状态
          parentStatus: 0, //查看
          comparisonClassHours: this.comparisonClassHours ? 1 : 0, //上课时长对比状态
          classroomFeedback: this.classroomFeedback ? 1 : 0, //课堂反馈状态
          wrongQuestionBookCollection: 0, //错题本收录状态
          cancellationOfClass: this.cancellationOfClass ? 1 : 0, //取消课状态
          followUpClassPlan: this.followUpClassPlan ? 1 : 0, //后续上课计划状态
          adviceToParents: this.adviceToParents ? 1 : 0, //给家长建议的状态
          parents: this.parentProposal, //给家长的建议
          suggestionsForParticipants: this.suggestionsForParticipants ? 1 : 0, //给学生的建议的状态
          participants: this.stuProposal, //给学生的建议
          subjectIds: this.reportValue.join(","),
        };
        studentStudyReportUpdate(data).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.addStudyPopup = false;
            this.studentStudyReportFn();
            this.startTime = "";
            this.endTime = "";
            this.reportValue = "";
            this.parentProposal = "";
            this.stuProposal = "";
            for (var i = 0; i < this.subjectidList.length; i++) {
              this.subjectidList[i].flag = false;
              this.subjectidList[i].stuComparisonClassHours = "";
              this.subjectidList[i].classHours = "";
              this.subjectidList[i].plan = "";
            }
            for (var i = 0; i < this.subjectData.length; i++) {
              this.subjectData[i].flag = false;
              this.subjectData[i].stuComparisonClassHours = "";
              this.subjectData[i].classHours = "";
              this.subjectData[i].plan = "";
            }
          }
        });
      }
    },
    averageClassChange(val, index) {
      this.$set(this.subjectData[index], "classHours", val);
    },
    async seeStudyReport(row) {
      this.subjectData = [];
      this.reportNum = 0;
      this.reportNum2 = 0;
      this.reportNum3 = 0;
      this.reportNum4 = 0;
      this.seeStudyPopup = true;
      this.startTime = row.searchBeginTime.substring(0, 10);
      this.endTime = row.searchEndTime.substring(0, 10);
      await studentStudyReportInfo(row.id).then((res) => {
        if (res.code == 0) {
          let rowData = res.rows;
          this.reportValue = rowData.subjectIds.split(",").map(Number);
          // this.startTime=rowData.searchBeginTime;
          // this.endTime=rowData.searchEndTime;
          this.parentProposal = rowData.parents;
          this.stuProposal = rowData.participants;
          rowData.comparisonClassHours =
            rowData.comparisonClassHours == 1 ? true : false;
          rowData.classroomFeedback =
            rowData.classroomFeedback == 1 ? true : false;
          rowData.cancellationOfClass =
            rowData.cancellationOfClass == 1 ? true : false;
          rowData.followUpClassPlan =
            rowData.followUpClassPlan == 1 ? true : false;
          rowData.adviceToParents = rowData.adviceToParents == 1 ? true : false;
          rowData.suggestionsForParticipants =
            rowData.suggestionsForParticipants == 1 ? true : false;

          for (var i = 0; i < this.subjectidList.length; i++) {
            for (
              var k = 0;
              k < rowData.xmtStudentComparisonClassHoursList.length;
              k++
            ) {
              if (
                this.subjectidList[i].macroId ==
                rowData.xmtStudentComparisonClassHoursList[k].subjectId
              ) {
                this.subjectidList[i].flag = true;
                this.subjectidList[i].stuComparisonClassHours =
                  rowData.xmtStudentFollowUpClassPlanList[k].classHour;
                this.subjectidList[i].plan =
                  rowData.xmtStudentFollowUpClassPlanList[k].plan;
                this.subjectidList[i].classHours = Number(
                  rowData.xmtStudentComparisonClassHoursList[k].classHour
                );
                if ((this.subjectidList[i].flag = true)) {
                  this.subjectData.push(this.subjectidList[i]);
                }
              }
            }
          }
        }
      });
      this.drawChart4();
    },
    async editStudyReport(row) {
      this.subjectData = [];
      this.reportNum = 0;
      this.reportNum2 = 0;
      this.reportNum3 = 0;
      this.reportNum4 = 0;
      this.addStudyPopup = true;
      this.startTime = row.searchBeginTime.substring(0, 10);
      this.endTime = row.searchEndTime.substring(0, 10);
      this.studyRuleForm.id = row.id;
      this.addStudyParams = {
        type: "edit",
        title: "编辑学习报告",
      };
      await studentStudyReportInfo(row.id).then((res) => {
        if (res.code == 0) {
          let rowData = res.rows;
          this.reportValue = rowData.subjectIds.split(",").map(Number);
          this.parentProposal = rowData.parents;
          this.stuProposal = rowData.participants;
          rowData.comparisonClassHours =
            rowData.comparisonClassHours == 1 ? true : false;
          rowData.classroomFeedback =
            rowData.classroomFeedback == 1 ? true : false;
          rowData.cancellationOfClass =
            rowData.cancellationOfClass == 1 ? true : false;
          rowData.followUpClassPlan =
            rowData.followUpClassPlan == 1 ? true : false;
          rowData.adviceToParents = rowData.adviceToParents == 1 ? true : false;
          rowData.suggestionsForParticipants =
            rowData.suggestionsForParticipants == 1 ? true : false;
          rowData.comparisonClassHours == 1
            ? (this.comparisonClassHours = true)
            : (this.comparisonClassHours = false);
          rowData.classroomFeedback == 1
            ? (this.classroomFeedback = true)
            : (this.classroomFeedback = false);
          rowData.cancellationOfClass == 1
            ? (this.cancellationOfClass = true)
            : (this.cancellationOfClass = false);
          rowData.followUpClassPlan == 1
            ? (this.followUpClassPlan = true)
            : (this.followUpClassPlan = false);
          rowData.adviceToParents == 1
            ? (this.adviceToParents = true)
            : (this.adviceToParents = false);
          rowData.suggestionsForParticipants == 1
            ? (this.suggestionsForParticipants = true)
            : (this.suggestionsForParticipants = false);
          this.subjectData = [];
          for (var i = 0; i < this.subjectidList.length; i++) {
            for (
              var k = 0;
              k < rowData.xmtStudentComparisonClassHoursList.length;
              k++
            ) {
              if (
                this.subjectidList[i].macroId ==
                rowData.xmtStudentComparisonClassHoursList[k].subjectId
              ) {
                this.subjectidList[i].flag = true;
                this.subjectidList[i].stuComparisonClassHours =
                  rowData.xmtStudentFollowUpClassPlanList[k].classHour;
                this.subjectidList[i].plan =
                  rowData.xmtStudentFollowUpClassPlanList[k].plan;
                this.subjectidList[i].classHours = Number(
                  rowData.xmtStudentComparisonClassHoursList[k].classHour
                );
                if ((this.subjectidList[i].flag = true)) {
                  this.subjectData.push(this.subjectidList[i]);
                }
              }
            }
          }
        }
      });
      this.drawChart();
    },
    handlestudySizeChange(pageSize) {
      this.studyParams.pageSize = pageSize;
      this.studentStudyReportFn();
    },
    handlestudyCurrentChange(currentPage) {
      this.studyParams.pageSize = pageSize;
      this.studentStudyReportFn();
    },
    async comparisonClassClick(item, index, items, num) {
      this.reportNum = index;
      this.reportNum2 = index;
      this.reportNum3 = index;
      this.reportNum4 = index;
      this.averageClassHoursId = index;
      let data = {
        studentId: this.getstudentid,
        subjectId: item,
        searchBeginTime: this.startTime + " 00:00:00",
        searchEndTime: this.endTime + " 23:59:59",
      };
      await getChartData(data).then((res) => {
        if (res.code == 0) {
          if (res.rows.teaEarnest.length) {
            this.teaEarnest = [];
            for (var i = 0; i < res.rows.teaEarnest.length; i++) {
              if (
                res.rows.teaEarnest[i].teaEarnestName == "能够跟上老师的节奏"
              ) {
                this.teaEarnest[0] = res.rows.teaEarnest[i].count;
              }
              if (res.rows.teaEarnest[i].teaEarnestName == "正常水平") {
                this.teaEarnest[1] = res.rows.teaEarnest[i].count;
              }
              if (res.rows.teaEarnest[i].teaEarnestName == "走神情况严重") {
                this.teaEarnest[2] = res.rows.teaEarnest[i].count;
              }
              if (
                res.rows.teaEarnest[i].teaEarnestName == "注意力集中时间较短"
              ) {
                this.teaEarnest[3] = res.rows.teaEarnest[i].count;
              }
              if (res.rows.teaEarnest[i].teaEarnestName == "全神贯注") {
                this.teaEarnest[4] = res.rows.teaEarnest[i].count;
              }
            }
          } else {
            this.teaEarnest = [];
          }
          if (res.rows.teaUnderstand.length) {
            this.teaUnderstand = [];
            for (var i = 0; i < res.rows.teaUnderstand.length; i++) {
              if (
                res.rows.teaUnderstand[i].teaUnderstandName ==
                "完全掌握，能够举一反三"
              ) {
                this.teaUnderstand[0] = res.rows.teaUnderstand[i].count;
              }
              if (
                res.rows.teaUnderstand[i].teaUnderstandName ==
                "理解层面，可以熟练做题"
              ) {
                this.teaUnderstand[1] = res.rows.teaUnderstand[i].count;
              }
              if (
                res.rows.teaUnderstand[i].teaUnderstandName ==
                "记忆层面，只记住知识点"
              ) {
                this.teaUnderstand[2] = res.rows.teaUnderstand[i].count;
              }
            }
          } else {
            this.teaUnderstand = [];
          }
          if (res.rows.teaInteraction.length) {
            this.interactionName = [];
            this.interactionData = [];
            for (var i = 0; i < res.rows.teaInteraction.length; i++) {
              this.interactionName.push(
                res.rows.teaInteraction[i].teaInteractionName
              );
              this.interactionData.push(res.rows.teaInteraction[i].count);
            }
          } else {
            this.interactionName = [];
            this.interactionData = [];
          }
          if (res.rows.teaFinish.length) {
            this.teaFinish = [];
            for (var i = 0; i < res.rows.teaFinish.length; i++) {
              this.teaFinish.push({
                value: res.rows.teaFinish[i].count,
                name: res.rows.teaFinish[i].teaFinishName,
              });
            }
          } else {
            this.teaFinish = [];
          }
          this.cancellationOfClassArray = [];
          this.cancellationOfClassArray[0] = res.rows.cancellationOfClass[0]
            ? res.rows.cancellationOfClass[0].planClassHour
              ? res.rows.cancellationOfClass[0].planClassHour
              : 0
            : 0;
          this.cancellationOfClassArray[1] = res.rows.cancellationOfClass[0]
            ? res.rows.cancellationOfClass[0].actualClassHour
              ? res.rows.cancellationOfClass[0].actualClassHour
              : 0
            : 0;
          this.cancellationOfClassArray[2] = res.rows.cancellationOfClass[0]
            ? res.rows.cancellationOfClass[0].cancelClassHour
              ? res.rows.cancellationOfClass[0].cancelClassHour
              : 0
            : 0;
          this.workHome = [];
          this.workHome[0] = res.rows.homeWorkDownload[0]
            ? res.rows.homeWorkDownload[0].downloadCount
              ? res.rows.homeWorkDownload[0].downloadCount
              : 0
            : 0;
          this.workHome[1] = res.rows.homeWorkDownload[0]
            ? res.rows.homeWorkDownload[0].uploadCount
              ? res.rows.homeWorkDownload[0].uploadCount
              : 0
            : 0;
          this.subjectData[index].comparisonOne = res.rows
            .comparisonClassHours[0]
            ? res.rows.comparisonClassHours[0].classHourType
            : 0;
        }
      });
      if (this.seeStudyPopup == true) {
        this.drawChart3();
        this.drawChart4();
      } else {
        this.drawChart();
        this.drawChart2();
      }
    },
    drawChart() {
      let myChart = this.$echarts.init(this.$refs.comparisonClassHoursChart);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["你的平均上课时长", "其他学生的平均上课时长"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.subjectData.length
                  ? this.subjectData[this.reportNum].comparisonOne
                    ? this.subjectData[this.reportNum].comparisonOne
                    : 0
                  : 0,
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.subjectData.length
                  ? this.subjectData[this.reportNum].classHours
                    ? this.subjectData[this.reportNum].classHours
                    : 0
                  : 0,
                itemStyle: {
                  color: "#FF88C2",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart4() {
      let myChart = this.$echarts.init(this.$refs.comparisonClassHoursCharts);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["你的平均上课时长", "其他学生的平均上课时长"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.subjectData[this.reportNum].comparisonOne,
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.subjectData[this.reportNum].classHours,
                itemStyle: {
                  color: "#FF88C2",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart2() {
      let myFeedback = this.$echarts.init(this.$refs.feedbackChart);
      let teaFinishChart = this.$echarts.init(this.$refs.teaFinishChart);
      let teaInteractionChart = this.$echarts.init(
        this.$refs.teaInteractionChart
      );
      let teaUnderstandChart = this.$echarts.init(
        this.$refs.teaUnderstandChart
      );
      let cancellationOfClassChart = this.$echarts.init(
        this.$refs.cancellationOfClassChart
      );
      let homeWorkDownloadChart = this.$echarts.init(
        this.$refs.homeWorkDownloadChart
      );
      let optionFeedback = {
        title: {
          text: "",
        },
        legend: {
          data: [""],
        },
        label: {
          show: true,
          formatter: function (params) {
            return params.value != undefined ? params.value : 0;
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "能够跟上老师的节奏", max: 10 },
            { name: "正常水平", max: 10 },
            { name: "走神状态严重", max: 10 },
            { name: "注意力集中时间较短", max: 10 },
            { name: "全神贯注", max: 10 },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: this.teaEarnest,
                name: "",
                areaStyle: {
                  color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.1)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      let teaFinishOption = {
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: this.teaFinish,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#FF8800",
                    "#0066FF",
                    "#E38EFF",
                    "#FFFF00",
                    "#99FF00",
                    "#993300",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      let teaInteractionOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: this.interactionName,
          axisLabel: {
            formatter: function (val) {
              let str = makeMultiLine(val, 5); // 每 5 个字符一行
              return `{n|${str}}`; // 使用 rich 中的 n 来设置样式
            },
            rich,
            interval: 0, // 显示所有的 x 轴上的文字不隐藏
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.interactionData,
            type: "bar",
            barWidth: 60, //柱图宽度
            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = [
                    "#c23531",
                    "#2f4554",
                    "#61a0a8",
                    "#d48265",
                    "#91c7ae",
                    "#749f83",
                    "#ca8622",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      let teaUnderstandOption = {
        title: {
          text: "",
        },
        legend: {
          data: [""],
        },
        label: {
          show: true,
          formatter: function (params) {
            return params.value != undefined ? params.value : 0;
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "完全掌握，能够举一反三", max: 10 },
            { name: "理解层面，可以熟练做题", max: 10 },
            { name: "记忆层面，只记住知识点", max: 10 },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: this.teaUnderstand,
                name: "",
                areaStyle: {
                  color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.1)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      let cancellationOption = {
        tooltip: { trigger: "axis" },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["计划课时", "实际上课课时", "取消课课时"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.cancellationOfClassArray[0],
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.cancellationOfClassArray[1],
                itemStyle: {
                  color: "#FF88C2",
                },
              },
              {
                value: this.cancellationOfClassArray[2],
                itemStyle: {
                  color: "#c23531",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      let homeWorkOption = {
        tooltip: { trigger: "axis" },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["下载次数", "提交次数"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.workHome[0],
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.workHome[1],
                itemStyle: {
                  color: "#FF88C2",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      myFeedback.setOption(optionFeedback);
      teaFinishChart.setOption(teaFinishOption);
      teaInteractionChart.setOption(teaInteractionOption);
      teaUnderstandChart.setOption(teaUnderstandOption);
      cancellationOfClassChart.setOption(cancellationOption);
      homeWorkDownloadChart.setOption(homeWorkOption);
    },
    drawChart3() {
      let myFeedbacks = this.$echarts.init(this.$refs.feedbackCharts);
      let teaFinishCharts = this.$echarts.init(this.$refs.teaFinishCharts);
      let teaInteractionCharts = this.$echarts.init(
        this.$refs.teaInteractionCharts
      );
      let teaUnderstandCharts = this.$echarts.init(
        this.$refs.teaUnderstandCharts
      );
      let cancellationOfClassCharts = this.$echarts.init(
        this.$refs.cancellationOfClassCharts
      );
      let homeWorkDownloadCharts = this.$echarts.init(
        this.$refs.homeWorkDownloadCharts
      );
      let optionFeedbacks = {
        title: {
          text: "",
        },
        legend: {
          data: ["学生在课堂听课的认真程度"],
        },
        label: {
          show: true,
          formatter: function (params) {
            return params.value != undefined ? params.value : 0;
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "能够跟上老师的节奏", max: 10 },
            { name: "正常水平", max: 10 },
            { name: "走神状态严重", max: 10 },
            { name: "注意力集中时间较短", max: 10 },
            { name: "全神贯注", max: 10 },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: this.teaEarnest,
                name: "",
                areaStyle: {
                  color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.1)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      let teaFinishOptions = {
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: this.teaFinish,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#FF8800",
                    "#0066FF",
                    "#E38EFF",
                    "#FFFF00",
                    "#99FF00",
                    "#993300",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      let teaInteractionOptions = {
        tooltip: { trigger: "axis" },
        xAxis: {
          type: "category",
          data: this.interactionName,
          axisLabel: {
            formatter: function (val) {
              let str = makeMultiLine(val, 5); // 每 5 个字符一行
              return `{n|${str}}`; // 使用 rich 中的 n 来设置样式
            },
            rich,
            interval: 0, // 显示所有的 x 轴上的文字不隐藏
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.interactionData,
            barWidth: 60, //柱图宽度
            type: "bar",
            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = [
                    "#c23531",
                    "#2f4554",
                    "#61a0a8",
                    "#d48265",
                    "#91c7ae",
                    "#749f83",
                    "#ca8622",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      let teaUnderstandOptions = {
        title: {
          text: "",
        },
        legend: {
          data: [""],
        },
        label: {
          show: true,
          formatter: function (params) {
            return params.value != undefined ? params.value : 0;
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "完全掌握，能够举一反三", max: 10 },
            { name: "理解层面，可以熟练做题", max: 10 },
            { name: "记忆层面，只记住知识点", max: 10 },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: this.teaUnderstand,
                name: "",
                areaStyle: {
                  color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.1)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      let cancellationOptions = {
        tooltip: { trigger: "axis" },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["计划课时", "实际上课课时", "取消课课时"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.cancellationOfClassArray[0],
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.cancellationOfClassArray[1],
                itemStyle: {
                  color: "#FF88C2",
                },
              },
              {
                value: this.cancellationOfClassArray[2],
                itemStyle: {
                  color: "#c23531",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      let homeWorkOptions = {
        tooltip: { trigger: "axis" },
        legend: {
          data: [""],
        },
        xAxis: {
          type: "category",
          data: ["下载次数", "提交次数"],
        },
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 60, //柱图宽度
            data: [
              {
                value: this.workHome[0],
                itemStyle: {
                  color: "#00BBFF",
                },
              },
              {
                value: this.workHome[1],
                itemStyle: {
                  color: "#FF88C2",
                },
              },
            ],
            itemStyle: {
              normal: {
                color: "rgba(51,129,255,1)",

                lineStyle: {
                  color: "rgba(51,129,255,.8)",
                  width: 10,
                },
              },
            },
          },
        ],
      };
      myFeedbacks.setOption(optionFeedbacks);
      teaFinishCharts.setOption(teaFinishOptions);
      teaInteractionCharts.setOption(teaInteractionOptions);
      teaUnderstandCharts.setOption(teaUnderstandOptions);
      cancellationOfClassCharts.setOption(cancellationOptions);
      homeWorkDownloadCharts.setOption(homeWorkOptions);
    },
    reportSubject(subjectid) {
      let subjectData = subjectid ? subjectid.split(",") : [];
      let str = "";
      for (var i = 0; i < this.subjectidList.length; i++) {
        for (var k = 0; k < subjectData.length; k++) {
          if (this.subjectidList[i].macroId == subjectData[k]) {
            str += this.subjectidList[i].name + "，";
          }
        }
      }
      return str.substring(0, str.length - 1);
    },
    switchChange() {
      if (this.switchValue == true) {
        this.orderListFn();
      } else {
        this.rechargeOrderList();
      }
    },
    //获取学科
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          for (var i = 0; i < res.rows.length; i++) {
            res.rows[i].flag = false;
          }
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
          this.subjectListEl = res.rows;
          this.stuComparisonList = res.rows;
          this.singSubjectIdList = res.rows;
          this.singSubjectIdList.splice(0, 2);
          this.subjectidList = res.rows;
        }
      });
    },
    //获取学科
    getSubjectList2() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.onesubjectIdList = res.rows;
          this.onesubjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    classFormFn(type) {
      if (type == "1") {
        return "线上";
      } else if (type == "2") {
        return "线下";
      } else if (type == "0") {
        return "均可";
      }
    },
    onLineFlagFn(num) {
      switch (num) {
        case 1:
          return "在线";
        case 2:
          return "刚刚活跃";
        case 3:
          return "一天前活跃";
        case 4:
          return "七天前活跃";
        default:
          return "";
      }
    },
    getSubjectCancel() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.cancelCourseSubjectList = res.rows;
          this.cancelCourseSubjectList.unshift({
            macroId: null,
            name: "全部",
          });
          this.cancelCourseSubjectList.splice(1, 1);
        }
      });
    },
    orderReceivingClick(row) {
      this.$refs.teacherListTable.toggleRowSelection(row);
    },
    getTeachTitle() {
      getSelectList({
        parentCode: teachTitle,
      }).then((res) => {
        if (res.code == 0) {
          this.teachTitleList = res.rows;
          this.teachTitleList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
      getSelectList({
        parentCode: teachStyle,
      }).then((res) => {
        if (res.code == 0) {
          this.teachStyleList = res.rows;
          this.lessonStyleList = res.rows;
          this.lessonStyleList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionIdList = res.rows;
          this.sectionIdList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    getTeachSpeedList() {
      getSelectList({
        parentCode: teachSpeed,
      }).then((res) => {
        if (res.code == 0) {
          this.teachSpeedList = res.rows;
          this.teachSpeedList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    getTeacYearList() {
      getSelectList({
        parentCode: teachYear,
      }).then((res) => {
        if (res.code == 0) {
          this.teachYearList = res.rows;
          this.lessonTeachingAgeId = res.rows;
          this.lessonAgeNeedId = res.rows;
          this.lessonAgeNeedId.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    searchTeacher() {
      if (this.handleKey == 1) {
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    handleSelectionChange(val) {
      this.getval = val;
      if (val.length > 0) {
        this.tableRadio = val[0];
        this.checkedGhCheck = val[0].id;
      }
      if (val.length > 1) {
        this.$refs.teacherListTable.clearSelection();
        this.$refs.teacherListTable.toggleRowSelection(val.pop());
      }
    },
    teacherItemClick(row) {
      this.$refs.teacherListTable.toggleRowSelection(row);
    },
    teacherSizeChange(pageSize) {
      this.teacherListParams.pageSize = pageSize;
      if (this.handleKey == 1) {
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    teacherCurrentChange(currentPage) {
      this.teacherListParams.pageNumber = currentPage;
      if (this.handleKey == 1) {
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    seeMany(row) {
      countConcern({ teacherId: row.id }).then((res) => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
    },
    editBirthdayChange() {
      let birthdayArray = this.editRuleForm.birthday.split("-");
      let month = Number(birthdayArray[1]); //月
      let date = Number(birthdayArray[2]); //日
      if ((month == 1 && date >= 20) || (month == 2 && date <= 18)) {
        this.editRuleForm.constellationId = 256;
      }
      if ((month == 2 && date >= 19) || (month == 3 && date <= 20)) {
        this.editRuleForm.constellationId = 257;
      }
      if ((month == 3 && date >= 21) || (month == 4 && date <= 19)) {
        this.editRuleForm.constellationId = 246;
      }
      if ((month == 4 && date >= 20) || (month == 5 && date <= 20)) {
        this.editRuleForm.constellationId = 247;
      }
      if ((month == 5 && date >= 21) || (month == 6 && date <= 21)) {
        this.editRuleForm.constellationId = 248;
      }
      if ((month == 6 && date >= 22) || (month == 7 && date <= 22)) {
        this.editRuleForm.constellationId = 249;
      }
      if ((month == 7 && date >= 23) || (month == 8 && date <= 22)) {
        this.editRuleForm.constellationId = 250;
      }
      if ((month == 8 && date >= 23) || (month == 9 && date <= 22)) {
        this.editRuleForm.constellationId = 251;
      }
      if ((month == 9 && date >= 23) || (month == 10 && date <= 22)) {
        this.editRuleForm.constellationId = 252;
      }
      if ((month == 10 && date >= 23) || (month == 11 && date <= 21)) {
        this.editRuleForm.constellationId = 253;
      }
      if ((month == 11 && date >= 22) || (month == 12 && date <= 21)) {
        this.editRuleForm.constellationId = 254;
      }
      if ((month == 12 && date >= 22) || (month == 1 && date <= 19)) {
        this.editRuleForm.constellationId = 255;
      }
    },
    // 联系家长
    chatToParent(item) {
      this.chatBoxShowChange({
        show: true,
        chatId: item.parChatId,
        type: "parent",
        studentInfo: {
          subjectInfo: this.mustTest(item),
          allocateTime: item.allocateTime,
          sexName: item.sex == 1 ? "男" : item.sex == 2 ? "女" : "未知性别",
          birthday: item.birthday ? item.birthday : "未知出生日期",
          schoolName: item.schoolName ? item.schoolName : "未知学校",
          studentState: this.getStudentState(item.studentState),
          sectionName: item.sectionName,
          name: item.name + "家长",
        },
      });
    },
    seeMany2(row) {
      this.teacherId = row.id;
      this.evaluateName = "1";
      this.modelName = "first";
      this.teacherDetailPopup = true;
      this.teacherInfo();
    },
    teacherInfo() {
      var data1 = {
        //录入员
        peopleType: 1,
        teacherId: this.teacherId,
      };
      var data2 = {
        //审核员
        peopleType: 2,
        teacherId: this.teacherId,
      };
      teacherFaceMarkDetail(data1).then((res) => {
        if (res.code == 0) {
          this.teacherFaceMark1 = res.rows;
        }
      });
      teacherFaceMarkDetail(data2).then((res) => {
        if (res.code == 0) {
          this.teacherFaceMark2 = res.rows;
        }
      });
      var isConcernParams = {
        teacherId: this.teacherId,
        teachManagerId: this.getTeachManagerId,
      };
      isConcernTeacher(isConcernParams).then((res) => {
        if (res.code == 0) {
          if (res.rows == "无数据") {
          } else {
            this.getConcernId = res.rows.id;
          }
        }
      });
      teacherInfo(this.teacherId).then((res) => {
        if (res.code == 0) {
          this.teacherDetail = res.rows;
        }
      });
      getByTeacherId(this.teacherId).then((res) => {
        if (res.code == 0) {
          this.answerList = res.rows;
        }
      });
      countConcern({ teacherId: this.teacherId }).then((res) => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
    },
    titleFn(row) {
      switch (row) {
        case 59:
          return "初级教师";
        case 60:
          return "中级教师";
        case 61:
          return "高级教师";
        case 62:
          return "特级教师";
        default:
          return "";
      }
    },
    identityFn(row) {
      if (row == 1) {
        return "学科类--老师";
      } else if (row == 2) {
        return "学科类--大学生";
      } else if (row == 3) {
        return "素质教育类--老师";
      } else if (row == 4) {
        return "素质教育类--大学生";
      } else {
        return "";
      }
    },
    CB_orderInfoShow() {
      this.orderInfoStatus = false;
    },
    CB_classRecordShow() {
      this.classRecordStatus = false;
    },
    dealEvaluate() {},
    addCourseList(macroId, name) {
      getConfigureCourseById({ studentId: this.idd, subjectId: macroId }).then(
        (res) => {
          if (res.code == 0) {
            var data = {
              label: 0,
              subjectId: macroId,
              xmtConfigureCourseList: [],
              classSinglePrice: 0,
              courseLevel: 0,
              teacherRealname: '',
              teacherId: '',
              hopeMinFee: '',
              hopeMaxFee: '',
              show: false,
            };
            data.xmtConfigureCourseList = res.rows;
            this.teacherList.unshift(data);
          }
        }
      );
    },
    getStudentState(row) {
      if (row == 0) {
        return "新生";
      } else if (row == 1) {
        return "在读";
      } else if (row == 2) {
        return "暂停课";
      } else if (row == 3) {
        return "退费";
      } else if (row == 4) {
        return "结课";
      } else if (row == 5) {
        return "试听";
      } else if (row == 6) {
        return "试听未报名";
      }
    },
    viewDetails(item) {
      this.teacherListId = item.subjectId;
      this.orderReceivingPopup = true;
      this.orderReceivingParams.orderPublishId = item.id;
      this.getOrderPublishId = item.id;
      this.listTeacherManagerFn();
    },
    listTeacherManagerFn() {
      listTeacherManager(this.orderReceivingParams).then((res) => {
        this.orderReceivingTotal = res.total;
        this.orderReceivingData = res.rows;
      });
    },
    addedOrder() {
      this.downOrderStatus = true;
      this.downParams = {
        id: this.getstudentid,
        type: "add",
      };
    },
    addedOrderlist(row) {
      this.downOrderStatus = true;
      this.downParams = {
        id: row.id,
        type: "add",
      };
    },
    confirmEdit(formName, num) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = [],
            firstClassTimeListArr = [],
            formalClassTimeListArr = [];
          this.editRuleForm.qitaxueke = 0;
          this.editRuleForm.yuwen = 0;
          this.editRuleForm.shuxue = 0;
          this.editRuleForm.yingyu = 0;
          this.editRuleForm.lishi = 0;
          this.editRuleForm.zhengzhi = 0;
          this.editRuleForm.dili = 0;
          this.editRuleForm.wuli = 0;
          this.editRuleForm.huaxue = 0;
          this.editRuleForm.shengwu = 0;
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            if (this.subjectList[i].select) {
              switch (this.subjectList[i].name) {
                case "其他学科":
                  this.editRuleForm.qitaxueke = 1;
                  break;
                case "语文":
                  this.editRuleForm.yuwen = 1;
                  break;
                case "数学":
                  this.editRuleForm.shuxue = 1;
                  break;
                case "英语":
                  this.editRuleForm.yingyu = 1;
                  break;
                case "历史":
                  this.editRuleForm.lishi = 1;
                  break;
                case "政治":
                  this.editRuleForm.zhengzhi = 1;
                  break;
                case "地理":
                  this.editRuleForm.dili = 1;
                  break;
                case "物理":
                  this.editRuleForm.wuli = 1;
                  break;
                case "化学":
                  this.editRuleForm.huaxue = 1;
                  break;
                case "生物":
                  this.editRuleForm.shengwu = 1;
                  break;
              }
            }
            if (this.subjectList[i].status) {
              if (
                this.subjectList[i].id != undefined ||
                this.subjectList[i].id != ""
              ) {
                //传Id
                arr.push({
                  studentId: this.editRuleForm.id,
                  subjectId: this.subjectList[i].macroId,
                  subjectScore: this.subjectList[i].subjectScore,
                  subjectAllScore: this.subjectList[i].subjectAllScore,
                  sexNeedId: this.subjectList[i].sexNeedId,
                  styleNeedId: this.subjectList[i].styleNeedId,
                  speedNeedId: this.subjectList[i].speedNeedId,
                  ageNeedId: this.subjectList[i].ageNeedId,
                  content: this.subjectList[i].content,
                  id: this.subjectList[i].id,
                  xmtStudentAcademicRecordList:
                    this.subjectList[i].xmtStudentAcademicRecordList,
                });
              } else {
                arr.push({
                  studentId: this.editRuleForm.id,
                  subjectId: this.subjectList[i].macroId,
                  subjectScore: this.subjectList[i].subjectScore,
                  subjectAllScore: this.subjectList[i].subjectAllScore,
                  sexNeedId: this.subjectList[i].sexNeedId,
                  styleNeedId: this.subjectList[i].styleNeedId,
                  speedNeedId: this.subjectList[i].speedNeedId,
                  ageNeedId: this.subjectList[i].ageNeedId,
                  content: this.subjectList[i].content,
                  xmtStudentAcademicRecordList:
                    this.subjectList[i].xmtStudentAcademicRecordList,
                });
              }
            }
          }
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.firstClassTimeList[i][j].status) {
                firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.formalClassTimeList[i][j].status) {
                formalClassTimeListArr.push(this.formalClassTimeList[i][j].key);
              }
            }
          }
          this.editRuleForm.firstClassTime = firstClassTimeListArr.join(",");
          this.editRuleForm.formalClassTime = formalClassTimeListArr.join(",");
          this.editRuleForm.xmtStudentSubjectRelationEntityList = arr;

          studentUpdate(this.editRuleForm).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              if (num != 2) {
                this.msgSuccess("操作成功！");
                this.editDialogVisible = false;
                this.daalEdit();
                this.studentInfo(this.getstudentid);
              } else {
                this.daalEdit();
                this.studentInfo(this.getstudentid);
              }
            } else {
              this.msgWarn(res.rows);
              return false;
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    returnStudent() {
      this.$confirm(
        "是否退回该学生到教务主管，退回后将不再是你的学生?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          returnStudentByStudentId(this.getstudentid).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.studentDetailPopup = false;
              if (this.getKey == "1") {
                this.teacherManagerSave();
              } else {
                this.alreadyDistribute();
              }
            } else {
              this.msgWarn(res.msg);
              return;
            }
          });
        })
        .catch(() => {});
    },
    classMoney(countUsedClassHour, classSinglePrice) {
      return Math.ceil(Number(countUsedClassHour)) * Number(classSinglePrice);
    },

    studentRefund() {
      if (this.switchValue == true) {
        if (this.valLength == 0) {
          this.msgWarn("请选择一条数据进行编辑操作！");
          return;
        }
        for (var i = 0; i < this.getOrderStatus.length; i++) {
          if (
            this.getOrderStatus[i] == 1 ||
            this.getOrderStatus[i] == 2 ||
            this.getOrderStatus[i] == 3 ||
            this.getOrderStatus[i] == 6 ||
            this.getOrderStatus[i] == 7 ||
            this.getOrderStatus[i] == 8 ||
            this.getOrderStatus[i] == 9
          ) {
            this.msgWarn("所选订单有不可退费，请重新选择！");
            return;
          }
        }

        this.refundSync = true;
        this.selectOrderList2 = this.selectOrderList.toString();
        studentInfo(this.studentIdInfo).then((res) => {
          this.studentDetailList = res.rows;
        });
        checkRefundOrders({
          orderIds: this.orderChangeId,
          studentId: this.studentIdInfo,
        }).then((res) => {
          if (res.code == 0) {
            if (res.rows == false) {
              //不可以下单
              this.isExamine = false;
            } else if (res.rows == true) {
              this.isExamine = true;
            }
          }
        });
        refundOrderInfos(this.selectOrderList).then((res) => {
          this.orderdDetail = res.rows;
          this.orderMoney = 0;
          this.saveOrderForm.realityUsePrice = "";
          var sum = 0;
          for (let i = 0; i < res.rows.length; i++) {
            if (res.rows[i].totalPrice) {
              this.orderMoney += Math.ceil(Number(res.rows[i].totalPrice));
            } else {
              this.orderMoney += Math.ceil(Number(res.rows[i].zdyActualPrice));
            }
            for (
              let j = 0;
              j < res.rows[i].xmtStudentRefundInfoChildList.length;
              j++
            ) {
              sum +=
                Number(
                  res.rows[i].xmtStudentRefundInfoChildList[j]
                    .countUsedClassHour
                    ? res.rows[i].xmtStudentRefundInfoChildList[j]
                        .countUsedClassHour
                    : 0
                ) *
                Number(
                  res.rows[i].xmtStudentRefundInfoChildList[j].classSinglePrice
                    ? res.rows[i].xmtStudentRefundInfoChildList[j]
                        .classSinglePrice
                    : 0
                );
            }
          }
          this.saveOrderForm.realityUsePrice = sum;
          this.saveOrderForm.refundAmount =
            this.orderMoney - this.saveOrderForm.realityUsePrice;
          this.$set(
            this.saveOrderForm,
            "realityRefundAmount",
            this.orderMoney - this.saveOrderForm.realityUsePrice
          );
        });
      } else {
        if (this.rechargeList.length > 0) {
          checkRefundOneToOne({
            studentId: this.getstudentid,
          }).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.Visible = true;
              this.visibleStudentId = this.rechargeList[0].studentId;
            } else {
              this.msgWarn(res.rows);
            }
          });
        } else {
          this.msgWarn("当前暂无充值订单可退费！");
        }
      }
    },
    freePickerChange() {
      if (!this.freePickerValue) {
        this.freeRuleFormFollowUp.beginTime = null;
        this.freeRuleFormFollowUp.endTime = null;
        return;
      }
      this.freeRuleFormFollowUp.beginTime = this.freePickerValue[0];
      this.freeRuleFormFollowUp.endTime = this.freePickerValue[1];
    },
    seeMany(row) {
      countConcern({ teacherId: row.id }).then((res) => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
    },
    orderSelectionChange(val) {
      this.orderReceivingHandle = val;
      // this.checkedGh = val.id;
      if (val.length > 1) {
        this.$refs.teacherListTable.clearSelection();
        this.$refs.teacherListTable.toggleRowSelection(val.pop());
      }
    },
    //历史配置
    historyFn() {
      this.historyStatus = true;
      this.historyId = this.idd;
    },
    courseLevelFn(item) {
      if (item == 1) {
        return "初级课程";
      } else if (item == 2) {
        return "中级课程";
      } else if (item == 3) {
        return "高级课程";
      } else if (item == 4) {
        return "特级课程";
      } else {
        return "正常课程";
      }
    },
    listTeacherManager(type) {
      let data = {
        selectType: type ? type : "",
        hopeFee: this.teacherListParams.hopeFee,
        name: this.teacherListParams.name,
        sectionId:
          this.teacherListParams.sectionId == "0"
            ? ""
            : this.teacherListParams.sectionId, //年级
        subjectId:
          this.teacherListParams.subjectId == ""
            ? ""
            : this.teacherListParams.subjectId, //学科
        identityType:
          this.teacherListParams.identityType == "0"
            ? null
            : this.teacherListParams.identityType, //身份
        teachTitleId:
          this.teacherListParams.teachTitleId == "0"
            ? null
            : String(this.teacherListParams.teachTitleId), //职称
        pageNumber: this.teacherListParams.pageNumber,
        pageSize: this.teacherListParams.pageSize,
      };
      listTeacherManager(data).then((res) => {
        this.teachertotal = res.total;
        this.currentUserId = res.currentUserId;
        for (var i = 0; i < res.rows.length; i++) {
          if (res.rows[i].xmtConcernTeacherEntityList.length) {
            for (
              var j = 0;
              j < res.rows[i].xmtConcernTeacherEntityList.length;
              j++
            ) {
              if (
                res.rows[i].xmtConcernTeacherEntityList[j].teachManagerId ==
                  this.currentUserId &&
                res.rows[i].xmtConcernTeacherEntityList[j].teacherId ==
                  res.rows[i].id
              ) {
                res.rows[i].newTeachManagerIdd = true;
              } else {
                res.rows[i].newTeachManagerIdd = false;
              }
            }
          } else {
            res.rows[i].newTeachManagerIdd = false;
          }
        }
        this.teacherListData = res.rows;
      });
    },
    dealTags(value) {
      if (value) {
        let arr = value.split(","),
          tags = [];
        for (let i = 0, len = this.followTagList.length; i < len; i++) {
          if (arr.includes(String(this.followTagList[i].macroId))) {
            tags.push(this.followTagList[i].name);
          }
        }
        return tags.join("，") ? tags.join("，") : "暂无";
      }
      return "暂无";
    },
    searchFn() {
      if (!this.showSaveDialog) {
        this.listParams.pageNumber = 1;
        this.teacherManagerSave();
      }
    },
    alreadySearch() {
      this.alreadyParams.pageNumber = 1;
      this.alreadyDistribute();
      this.alreadyDistributeCountFn();
    },
    teachingAgeIdd(row) {
      var str = "";
      if (row.teachingAgeId == 27) {
        str = "5年以下";
      } else if (row.teachingAgeId == 28) {
        str = "5-10";
      } else if (row.teachingAgeId == 29) {
        str = "10-15";
      } else if (row.teachingAgeId == 227) {
        str = "15-20以上";
      } else {
        str = "均可";
      }
      return str;
    },
    disabledDateTime(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    dealSubjectIdd(row) {
      var subjectlist = "";
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    seeConstellationId(constellationId) {
      for (var i = 0; i < this.constellationList.length; i++) {
        if (this.constellationList[i].macroId == constellationId) {
          return this.constellationList[i].name;
        }
      }
    },
    judgeAgeNeed(item) {
      if (item == 49) {
        return "25~35";
      } else if (item == 79) {
        return "35~45";
      } else if (item == 80) {
        return "45以上";
      } else {
        return "均可";
      }
    },
    judgeStyleNeed(item) {
      if (item == 272) {
        return "理智型教学风格";
      } else if (item == 273) {
        return "情感型教学风格";
      } else if (item == 274) {
        return "自然型教学风格";
      } else if (item == 275) {
        return "幽默型教学风格";
      } else if (item == 276) {
        return "技巧型教学风格";
      } else {
        return "均可";
      }
    },
    judgeStyleNeedFn(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.styleNeedArray[i].macroId == arr[k]) {
            str += this.styleNeedArray[i].name.split(":")[0] + "</br>";
          }
        }
      }
      return str;
    },
    judgeStyleNeedFns(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.skilfulTeachDate.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.skilfulTeachDate[i].macroId == arr[k]) {
            str += this.skilfulTeachDate[i].name.split(":")[0] + "，";
          }
        }
      }

      return str.substring(str.length - 1, 0);
    },
    levelTab(type) {
      this.levelNum = type;
      if (type == 1) {
        this.studentStateTotal = [1, 5];
      } else if (type == 2) {
        this.studentStateTotal = [6, 2];
      } else if (type == 3) {
        this.studentStateTotal = [3];
      } else if (type == 4) {
        this.studentStateTotal = [4];
      }
      this.alreadyDistribute();
    },
    //设置状态
    setStatus(row) {
      this.studentStatuDialog = true;
      this.studentStatuId = row.id;
      this.studentStateType = row.studentState;
    },
    classSubjectList() {
      return infoByParentCodeAndValue({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].status = false;
            res.rows[i].select = false;
            res.rows[i].subjectScore = "";
            res.rows[i].subjectAllScore = "";
            res.rows[i].sexNeedId = "";
            res.rows[i].styleNeedId = "";
            res.rows[i].speedNeedId = "";
            res.rows[i].ageNeedId = "";
            res.rows[i].content = "";
            res.rows[i].xmtStudentAcademicRecordList = [];
          }
          this.subjectList = res.rows;
          this.subjectList_info = JSON.parse(JSON.stringify(res.rows));
        }
      });
    },
    getPreviewImgList(index) {
      let tempImgList = [...this.previewImage]; //所有图片地址
      if (index == 0) return tempImgList;
      // 调整图片顺序，把当前图片放在第一位
      let start = tempImgList.splice(index);
      let remain = tempImgList.splice(0, index);
      return start.concat(remain); //将当前图片调整成点击缩略图的那张图片
    },
    handleClick(tab, event) {
      if (tab.index == 0) {
      } else if (tab.index == 1) {
        if (this.orderReceivingHandle.length) {
          //接单老师
        } else {
          this.configteacherPlanFnList();
        }
      } else if (tab.index == 2) {
        this.orderPublishListFn();
      }
    },
    orderPublishListFn() {
      this.orderPublishParams.studentId = this.idd;
      orderPublishList(this.orderPublishParams).then((res) => {
        this.orderPublishData = res.rows;
        for (var i = 0; i < res.rows.length; i++) {
          res.rows[i].formOfClasss = new Array();
          res.rows[i].formOfClasss = res.rows[i].formOfClass
            ? res.rows[i].formOfClass.split(",")
            : [];
        }
      });
    },
    classModeChange() {
      if (this.singleRuleForm.classMode == 1) {
        this.singleRuleForm.classAddress == "";
      }
      this.computeClassExpense()
      this.classAddressData.pageSize = 1000;
      studentClassAddressList(this.classAddressData).then((res) => {
        if (res.rows) {
          this.addressList = res.rows;
          for (var i = 0; i < res.rows.length; i++) {
            if (res.rows[i].isDefault == 1) {
              this.defaultId = res.rows[i].id;
              this.singleRuleForm.classAddress =
                res.rows[i].provinceName +
                res.rows[i].cityName +
                res.rows[i].countyName +
                res.rows[i].detailAddress;
            }
          }
        }
      });
    },
    moreClassMode() {
      this.classAddressData.pageSize = 1000;
      studentClassAddressList(this.classAddressData).then((res) => {
        if (res.rows) {
          for (var i = 0; i < res.rows.length; i++) {
            if (res.rows[i].isDefault == 1) {
              this.defaultId = res.rows[i].id;
              for (var k = 0; k < this.courseDate.length; k++) {
                this.courseDate[k].classAddress =
                  res.rows[i].provinceName +
                  res.rows[i].cityName +
                  res.rows[i].countyName +
                  res.rows[i].detailAddress;
              }
            }
          }
        }
      });
    },
    ageFn(birthday) {
      if (birthday) {
        var birthday_time = birthday.split("-");
        var birthYear = birthday_time[0];
        var d = new Date();
        var nowYear = d.getFullYear();
        var age = nowYear - birthYear;
        return age + "岁";
      } else {
        return "";
      }
    },
    teachingAgeIdFn2(row) {
      if (row.teachingAgeId == "" || row.teachingAgeId == 0) {
        return "均可";
      } else if (row.teachingAgeId == 27) {
        return "5年以下";
      } else if (row.teachingAgeId == 28) {
        return "5-10";
      } else if (row.teachingAgeId == 29) {
        return "10-15";
      } else if (row.teachingAgeId == 227) {
        return "15-20以上";
      }
    },
    CB_historyStatus() {
      this.historyStatus = false;
    },
    speedNeedIdFn(row) {
      if (row == 13) {
        return "正常";
      } else if (row == 12) {
        return "快";
      } else if (row == 14) {
        return "慢";
      } else {
        return "均可";
      }
    },
    styleNeedIdFn(row) {
      if (row == 31) {
        return "严厉型";
      } else if (row == 32) {
        return "幽默风趣型";
      } else if (row == 228) {
        return "亲善知心型";
      } else {
        return "均可";
      }
    },
    ageNeedIdFn(row) {
      if (row == 49) {
        return "25~35";
      } else if (row == 79) {
        return "35~45";
      } else if (row == 80) {
        return "45以上";
      } else {
        return "均可";
      }
    },
    subjectRow(value, index, subjectId, row) {
      this.teacherList[index].show = true;
      this.teacherList[index].classSinglePrice = "";
      this.$set(this.teacherList);
      this.teacherList[index].courseLevel = value;
      getConfigureCourseById({
        studentId: this.idd,
        subjectId: subjectId,
      }).then((res) => {
        if (res.code == 0) {
          for (let i = 0; i < res.rows.length; i++) {
            if (res.rows[i].courseLevel == row.courseLevel) {
              this.teacherList[index].classSinglePrice =
                res.rows[i].classSinglePrice;
            }
          }
        }
      });
    },
    ifFaceToFaceChange() {
      if (this.saveLessonForm.ifFaceToFace == 1) {
        this.saveLessonForm.minimumClassPay = "";
        this.saveLessonForm.maximumClassPay = "";
        this.saveLessonForm.classHour = "";
      }
    },
    mustSubjects(row) {
      if (row.productItemList != undefined) {
        var map = {},
          dest = [];
        for (var i = 0; i < row.productItemList.length; i++) {
          var ai = row.productItemList[i];
          if (!map[ai.subjectName]) {
            dest.push({
              subjectName: ai.subjectName,
              usedClassHour: ai.usedClassHour,
            });
            map[ai.subjectName] = ai;
          } else {
            for (var j = 0; j < dest.length; j++) {
              var dj = dest[j];
              if (dj.subjectName == ai.subjectName) {
                dj.usedClassHour = (
                  parseFloat(dj.usedClassHour) + parseFloat(ai.usedClassHour)
                ).toString();
                break;
              }
            }
          }
        }
        let str = "";
        for (var k = 0; k < dest.length; k++) {
          str +=
            dest[k].subjectName +
            "（" +
            dest[k].usedClassHour +
            "课时）" +
            "、";
        }
        return str.substring(0, str.length - 1);
      }
    },
    setDispose(index) {
      this.teacherList[index].label = 2;
      this.teacherList[index].show = true;
    },
    //选老师
    selectTeacher(index) {
      this.getIndex = index;
      this.teacherPopup = true;
      this.teacherListParams.name = "";
      this.teacherListParams.sectionId = "";
      this.teacherListParams.subjectId = "";
      this.teacherListParams.identityType = "";
      this.teacherListParams.teachTitleId = "";
      this.teacherListParams.hopeFee = "";
      this.teacherIndex = "1";
      this.listTeacherManager();
    },
    confirmSaveTeacher() {
      var newdata = [];
      if (newdata.length < 1) {
        this.msgWarn("请添加计划信息!");
        return;
      }
      saveChooseTeacher(newdata).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("老师配置成功！");
        }
      });
    },
    teacherListSizeChange(pageSize) {
      this.configteacherParams.pageSize = pageSize;
      this.configteacherPlanFnList();
    },
    courseEditRemove(id) {
      this.removeClassRadio = "";
      this.removeClassVisible = true;
      this.getRemoveId = id;
    },
    formatterAttendClassStatus(row) {
      switch (row.attendClassStatus) {
        case 0:
          return "未上课";
        case 1:
          return "已上课";
        case 2:
          return "上课中";
        default:
          return "";
      }
    },
    teacherListCurrentChange(currentPage) {
      this.configteacherParams.pageNumber = currentPage;
      this.configteacherPlanFnList();
    },
    classRecordClick(tab) {
      this.findListParams.studentId = this.getstudentid;
      this.oneToMoreParams.studentId = this.getstudentid;
      this.delParams.studentId = this.getstudentid;
      if (this.classRecord == 1) {
        this.findProductItemPage();
      } else if (this.classRecord == 2) {
        this.findMoreProductItemPage();
      } else if (this.classRecord == 3) {
        this.findDelProductItemPage();
      }
    },
    oneToOneSizeChange(pageSize) {
      this.findListParams.pageSize = pageSize;
      this.findProductItemPage();
    },
    oneToOneCurrentChange(currentPage) {
      this.findListParams.pageNumber = currentPage;
      this.findProductItemPage();
    },
    oneToMoreSizeChange(pageSize) {
      this.findMoreProductItemPage.pageSize = pageSize;
      this.findMoreProductItemPage();
    },
    oneToMoreCurrentChange(currentPage) {
      this.findMoreProductItemPage.pageNumber = currentPage;
      this.findMoreProductItemPage();
    },
    delSizeChange(pageSize) {
      this.delParams.pageSize = pageSize;
      this.findDelProductItemPage();
    },
    delCurrentChange(currentPage) {
      this.delParams.pageNumber = currentPage;
      this.findDelProductItemPage();
    },
    importExcelOne() {
      let jsonData = [],
        str = `上课时间,产品名称,课程标题,课程级别,课时,授课老师,上课状态\n`;
      for (let i = 0, len = this.oneToOneData.length; i < len; i++) {
        jsonData.push({
          beginTime: this.oneToOneData[i].beginTime,
          productName: this.oneToOneData[i].productName,
          title: this.oneToOneData[i].title,
          courseLevel: this.courseLevelFormatter(this.oneToOneData[i]),
          usedClassHour: this.oneToOneData[i].usedClassHour,
          teacherName: this.oneToOneData[i].teacherRealName,
          attendClassStatus: this.formatterAttendClassStatus(
            this.oneToOneData[i]
          ),
        });
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "学生课耗一对一列表.xls";
      link.click();
    },
    importExcelMore() {
      let jsonData = [],
        str = `班级名称,授课老师,课程标题,上课时间,上课状态,课时数,课程状态\n`;
      for (let i = 0, len = this.oneToMoreData.length; i < len; i++) {
        jsonData.push({
          roomName: this.oneToMoreData[i].roomName,
          teacherName: this.oneToMoreData[i].teacherRealName,
          title: this.oneToMoreData[i].title,
          beginTime: this.oneToMoreData[i].beginTime,
          orderSourceName: this.formatterAttendClassStatus(
            this.oneToMoreData[i]
          ),
          usedClassHour: this.oneToMoreData[i].usedClassHour,
        });
      }
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      console.log(str);
      // encodeURIComponent解决中文乱码
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "学生课耗一对多列表.xls";
      link.click();
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
    handlePapersRemove(file, fileList) {
      for (var i = 0; i < this.testPapers.length; i++) {
        if (this.testPapers[i].url === file.url) {
          this.testPapers.splice(i, 1);
        }
      }
    },
    handlePapersSuccess(file, fileList) {
      this.testPapers.push({ url: file[0].url });
    },
    importExcelDel() {
      let jsonData = [],
        str = `上课时间,产品名称,课程标题,课程级别,课时,授课老师,取消原因\n`;
      for (let i = 0, len = this.delData.length; i < len; i++) {
        jsonData.push({
          beginTime: this.delData[i].beginTime,
          productName: this.delData[i].productName,
          title: this.delData[i].title,
          courseLevel: this.courseLevelFormatter(this.delData[i]),
          usedClassHour: this.delData[i].usedClassHour,
          teacherName: this.delData[i].teacherRealName,
          delCause: this.formatterDelCause(this.delData[i]),
        });
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      // encodeURIComponent解决中文乱码
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "学生课耗取消课列表.xls";
      link.click();
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      } else {
      }
    },
    formatterDelCause(row) {
      switch (row.delCause) {
        case 1:
          return "学生";
        case 2:
          return "老师";
        case 3:
          return "其他";
        default:
          return "";
      }
    },
    handleTit(i) {
      let state = this.entitiesState;
      this.$set(state, i, !state[i]);
      for (let a in this.entitiesState) {
        if (a != i) {
          this.$set(state, a, false);
        }
      } //增加判断，改变同级的展开状态
    },
    findProductItemPage() {
      findProductItemPage(this.findListParams).then((res) => {
        this.oneToOneData = res.rows;
        this.countMap = res.countMap;
        this.total1 = res.total;
      });
    },
    findMoreProductItemPage() {
      findMoreProductItemPage(this.oneToMoreParams).then((res) => {
        this.oneToMoreData = res.rows;
        this.total2 = res.total;
      });
    },
    findDelProductItemPage() {
      findDelProductItemPage(this.delParams).then((res) => {
        this.delData = res.rows;
        this.total3 = res.total;
      });
    },
    dealSectionId(row) {
      var str = "";
      var arr2 = new Array();
      if (row) {
        arr2 = row.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三，";
        } else if (arr2[i] == 97) {
          str += "高二，";
        } else if (arr2[i] == 96) {
          str += "高一，";
        } else if (arr2[i] == 20) {
          str += "初三，";
        } else if (arr2[i] == 19) {
          str += "初二，";
        } else if (arr2[i] == 18) {
          str += "初一，";
        } else if (arr2[i] == 95) {
          str += "六年，";
        } else if (arr2[i] == 94) {
          str += "五年级，";
        } else if (arr2[i] == 93) {
          str += "四年级，";
        } else if (arr2[i] == 92) {
          str += "三年级，";
        } else if (arr2[i] == 110) {
          str += "艺术培训，";
        } else if (arr2[i] == 111) {
          str += "小语种，";
        } else {
          str += "暂无数据";
        }
      }
      return str.substring(0, str.length - 1);
    },
    addFollow() {
      this.isSaveFollow = false;
      this.followUpDialogVisible = true;
      this.followUpDialog = {
        title: "新增跟进记录",
        type: "save",
      };
      this.saveRuleFormFollowUp = {
        nextFollowupTime: "",
        followContent: "",
        photo: [],
      };
      this.photoList = [];
    },
    editfollowUp(row) {
      this.isSaveFollow = false;
      this.followUpDialog = {
        title: "修改跟进记录",
        type: "edit",
      };
      this.saveRuleFormFollowUp.id = row.id;
      this.followUpDialogVisible = true;
      this.saveRuleFormFollowUp.followContent = row.followContent;
      this.saveRuleFormFollowUp.nextFollowupTime = row.nextFollowupTime;
      this.photoList = row.photoList.map((item) => {
        return item;
      });
    },
    removefollowUp(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          teacherFollowupRecordRemove(row.id).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("删除成功！");
              this.followUpListFn();
            }
          });
        })
        .catch(() => {});
    },
    followUpSizeChange(pageSize) {
      this.followUpParams.pageSize = pageSize;
      this.followUpListFn();
    },
    followUpCurrentChange(currentPage) {
      this.followUpParams.pageNumber = currentPage;
      this.followUpListFn();
    },
    addLessonList() {
      this.firstClassTimeList = [];
      this.styleNeedGroup = [];
      this.formOfClassModel = [];
      this.lessonTimeValue = "";
      this.saveLessonDialogVisible = true;
      this.saveLessonDialog = {
        title: "新增课单",
        type: "add",
      };
      this.saveLessonForm = {
        studentAchievementId: "",
        weekArr: "",
        studentId: this.idd,
        addressNames: "",
        classListTitle: "",
        sectionId: "",
        ifFaceToFace: "0",
        provinceId: "",
        cityId: "",
        countyId: "",
        teachingAgeId: 0,
        teachTitleId: 0,
        sexNeedId: 0,
        formOfClass: 0,
        styleNeedId: "",
        speedNeedId: 0,
        ageNeedId: 0,
        mandarinId: 0,
        minimumClassPay: '',
        maximumClassPay: '',
        classHour: '',
      }
      this.normalClassHoursList = [
          [
            {
              name: "上午",
              key: "a",
              status: false,
              week: "周一",
            },
            {
              name: "上午",
              key: "b",
              status: false,
              week: "周二",
            },
            {
              name: "上午",
              key: "c",
              status: false,
              week: "周三",
            },
            {
              name: "上午",
              key: "d",
              status: false,
              week: "周四",
            },
            {
              name: "上午",
              key: "e",
              status: false,
              week: "周五",
            },
            {
              name: "上午",
              key: "f",
              status: false,
              week: "周六",
            },
            {
              name: "上午",
              key: "g",
              status: false,
              week: "周日",
            },
          ],
          [
            {
              name: "下午",
              key: "h",
              status: false,
              week: "周一",
            },
            {
              name: "下午",
              key: "i",
              status: false,
              week: "周二",
            },
            {
              name: "下午",
              key: "j",
              status: false,
              week: "周三",
            },
            {
              name: "下午",
              key: "k",
              status: false,
              week: "周四",
            },
            {
              name: "下午",
              key: "l",
              status: false,
              week: "周五",
            },
            {
              name: "下午",
              key: "m",
              status: false,
              week: "周六",
            },
            {
              name: "下午",
              key: "n",
              status: false,
              week: "周日",
            },
          ],
          [
            {
              name: "晚上",
              key: "o",
              status: false,
              week: "周一",
            },
            {
              name: "晚上",
              key: "p",
              status: false,
              week: "周二",
            },
            {
              name: "晚上",
              key: "q",
              status: false,
              week: "周三",
            },
            {
              name: "晚上",
              key: "r",
              status: false,
              week: "周四",
            },
            {
              name: "晚上",
              key: "s",
              status: false,
              week: "周五",
            },
            {
              name: "晚上",
              key: "t",
              status: false,
              week: "周六",
            },
            {
              name: "晚上",
              key: "u",
              status: false,
              week: "周日",
            },
          ],
        ]
      this.firstClassTimeList = [
          [
            {
              name: "上午",
              key: "a",
              status: false,
            },
            {
              name: "上午",
              key: "b",
              status: false,
            },
            {
              name: "上午",
              key: "c",
              status: false,
            },
            {
              name: "上午",
              key: "d",
              status: false,
            },
            {
              name: "上午",
              key: "e",
              status: false,
            },
            {
              name: "上午",
              key: "f",
              status: false,
            },
            {
              name: "上午",
              key: "g",
              status: false,
            },
          ],
          [
            {
              name: "下午",
              key: "h",
              status: false,
            },
            {
              name: "下午",
              key: "i",
              status: false,
            },
            {
              name: "下午",
              key: "j",
              status: false,
            },
            {
              name: "下午",
              key: "k",
              status: false,
            },
            {
              name: "下午",
              key: "l",
              status: false,
            },
            {
              name: "下午",
              key: "m",
              status: false,
            },
            {
              name: "下午",
              key: "n",
              status: false,
            },
          ],
          [
            {
              name: "晚上",
              key: "o",
              status: false,
            },
            {
              name: "晚上",
              key: "p",
              status: false,
            },
            {
              name: "晚上",
              key: "q",
              status: false,
            },
            {
              name: "晚上",
              key: "r",
              status: false,
            },
            {
              name: "晚上",
              key: "s",
              status: false,
            },
            {
              name: "晚上",
              key: "t",
              status: false,
            },
            {
              name: "晚 上",
              key: "u",
              status: false,
            },
          ],
        ];
      this.getProvinceList();
    },
    async editLessonList() {
      this.formOfClassModel = [];
      await this.getProvinceList();
      this.saveLessonDialog = {
        title: "编辑课单",
        type: "edit",
      };
      this.idData = [];
      for (var i = 0; i < this.orderPublishData.length; i++) {
        if (this.orderPublishData[i].checked == true) {
          this.idData.push(this.orderPublishData[i].id);
        }
      }
      if (this.idData.length == 1) {
        await orderPublishInfo({ id: this.idData.toString() }).then((res) => {
          if (res.code == 0) {
            this.saveLessonDialogVisible = true;
            this.saveLessonForm = {
              id: res.rows.id,
              classListTitle: res.rows.classListTitle,
              sectionId: res.rows.sectionId,
              ifFaceToFace: String(res.rows.ifFaceToFace),
              provinceId: res.rows.provinceId,
              cityId: res.rows.cityId,
              countyId: res.rows.countyId,
              teachingAgeId: res.rows.teachingAgeId,
              teachTitleId: res.rows.teachTitleId,
              sexNeedId: res.rows.sexNeedId,
              styleNeedId: res.rows.styleNeedId,
              speedNeedId: res.rows.speedNeedId,
              ageNeedId: res.rows.ageNeedId,
              finishTime: res.rows.finishTime,
              addressDetNames: res.rows.addressDetNames,
              sectionId: res.rows.sectionId,
              minimumClassPay: res.rows.minimumClassPay,
              maximumClassPay: res.rows.maximumClassPay,
              classHour: res.rows.classHour,
              score: res.rows.score,
              totalScore: res.rows.totalScore,
              subjectId: Number(res.rows.subjectId),
              studentDesc: res.rows.studentDesc,
              remarks: res.rows.remarks,
              studentAchievementId: res.rows.studentAchievementId,
              mandarinId: res.rows.mandarinId,
              formOfClass: Number(res.rows.formOfClass),
            };

            this.styleNeedGroup = res.rows.styleNeedId
              .split(",")
              .map(function (item, index, array) {
                return item - 0;
              });

            if (this.saveLessonForm.provinceId) {
              this.getCityLessonList();
            }
            if (this.saveLessonForm.cityId) {
              this.getCountyLessonList();
            }
            this.initClassTime();
            for (
              let i = 0, len = this.normalClassHoursList.length;
              i < len;
              i++
            ) {
              for (
                let j = 0, lenJ = this.normalClassHoursList[i].length;
                j < lenJ;
                j++
              ) {
                if (
                  res.rows.normalClassHours &&
                  res.rows.normalClassHours.includes(
                    this.normalClassHoursList[i][j].key
                  )
                ) {
                  this.normalClassHoursList[i][j].status = true;
                }
              }
            }
            for (
              let i = 0, len = this.firstClassTimeList.length;
              i < len;
              i++
            ) {
              for (
                let j = 0, lenJ = this.firstClassTimeList[i].length;
                j < lenJ;
                j++
              ) {
                if (
                  res.rows.firstTeachTime &&
                  res.rows.firstTeachTime.includes(
                    this.firstClassTimeList[i][j].key
                  )
                ) {
                  this.firstClassTimeList[i][j].status = true;
                }
              }
            }
            this.lessonTimeValue = res.rows.finishTime;
          }
        });
      } else if (this.idData.length > 1) {
        this.msgWarn("只能选择一条课单进行编辑！");
        return false;
      } else {
        this.msgWarn("请先选择课单！");
        return false;
      }
    },
    removeLessonList() {
      this.idData = [];
      for (var i = 0; i < this.orderPublishData.length; i++) {
        if (this.orderPublishData[i].checked == true) {
          this.idData.push(this.orderPublishData[i].id);
        }
      }
      if (this.idData.length) {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            orderPublishRemove(this.idData).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("删除成功！");
                this.orderPublishListFn();
              }
            });
          })
          .catch(() => {});
        //   orderPublishRemove(this.idData).then(res => {
        //     if(res.code==0){
        //        this.msgSuccess("删除成功！");
        //        this.orderPublishListFn();
        //     }
        // });
      } else {
        this.msgWarn("请先选择课单！");
        return false;
      }
    },
    followTeacher(id) {
      var data = {
        teacherId: id,
        teachManagerId: this.getCurrentUserId,
        concernType: 1,
      };
      concernTeacher(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.listTeacherManagerFn();
        }
      });
    },
    huiguanTeacher(id) {
      var data = {
        id: this.getConcernId,
        concernType: 3,
      };
      removeConcern(data).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.listTeacherManagerFn();
        }
      });
    },
    orderSizeChange(pageSize) {
      this.orderReceivingParams.pageSize = pageSize;
      this.listTeacherManagerFn();
    },
    orderCurrentChange(currentPage) {
      this.orderReceivingParams.pageNumber = currentPage;
      this.listTeacherManagerFn();
    },
    confirmSaveFollowUp(formName) {
      this.isSaveFollow = true;
      let photo = [];
      this.photoList.forEach((item) => {
        photo.push({
          url: item.url,
        });
      });
      if (this.followUpDialog.type == "save") {
        teacherFollowupRecordSave({
          studentId: this.getstudentid,
          followContent: this.saveRuleFormFollowUp.followContent,
          nextFollowupTime: this.saveRuleFormFollowUp.nextFollowupTime,
          photo,
        }).then((res) => {
          if (res.code == 0) {
            this.followUpDialogVisible = false;
            this.msgSuccess("新增跟进记录成功！");
            this.isSaveFollow = false;
            this.followUpListFn();
          }
        });
      } else {
        teacherFollowupRecordUpdate({
          studentId: this.getstudentid,
          followContent: this.saveRuleFormFollowUp.followContent,
          nextFollowupTime: this.saveRuleFormFollowUp.nextFollowupTime,
          photo,
          id: this.saveRuleFormFollowUp.id,
        }).then((res) => {
          if (res.code == 0) {
            this.followUpDialogVisible = false;
            this.msgSuccess("修改跟进记录成功！");
            this.followUpListFn();
          }
        });
      }
    },
    onePickerChange() {
      if (!this.timePickerOne) {
        this.findListParams.searchBeginDate = null;
        this.findListParams.searchEndDate = null;
        return;
      }
      this.findListParams.searchBeginDate = this.timePickerOne[0];
      this.findListParams.searchEndDate = this.timePickerOne[1];
    },
    morePickerChange() {
      if (!this.timePickerMore) {
        this.oneToMoreParams.searchBeginDate = null;
        this.oneToMoreParams.searchEndDate = null;
        return;
      }
      this.oneToMoreParams.searchBeginDate = this.timePickerMore[0];
      this.oneToMoreParams.searchEndDate = this.timePickerMore[1];
    },
    delPickerChange() {
      if (!this.timePickerDel) {
        this.delParams.searchBeginDate = null;
        this.delParams.searchEndDate = null;
        return;
      }
      this.delParams.searchBeginDate = this.timePickerDel[0];
      this.delParams.searchEndDate = this.timePickerDel[1];
    },
    subjectFn(row) {
      switch (row) {
        case "185":
          return "全学科";
        case "23":
          return "语文";
        case "24":
          return "数学";
        case "25":
          return "英语";
        case "101":
          return "物理";
        case "102":
          return "化学";
        case "99":
          return "政治";
        case "100":
          return "生物";
        case "87":
          return "地理";
        case "88":
          return "历史";
        case "103":
          return "科学";
        case "112":
          return "国学";
        case "113":
          return "书法";
        case "114":
          return "吉他";
        case "115":
          return "绘画";
        case "116":
          return "德语";
        case "117":
          return "法语";
        case "118":
          return "俄语";
        case "119":
          return "西班牙语";
        case "183":
          return "其他小语种";
        case "184":
          return "其他艺术课";
        default:
          return "暂无数据";
      }
    },
    getSectionIdDetail(row) {
      var str = "";
      var arr2 = new Array();
      var subjectlist = "";
      if (row.sectionId) {
        arr2 = row.sectionId.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三，";
        } else if (arr2[i] == 97) {
          str += "高二，";
        } else if (arr2[i] == 96) {
          str += "高一，";
        } else if (arr2[i] == 20) {
          str += "初三，";
        } else if (arr2[i] == 19) {
          str += "初二，";
        } else if (arr2[i] == 18) {
          str += "初一，";
        } else if (arr2[i] == 95) {
          str += "六年级，";
        } else if (arr2[i] == 94) {
          str += "五年级，";
        } else if (arr2[i] == 93) {
          str += "四年级，";
        } else if (arr2[i] == 92) {
          str += "三年级，";
        } else if (arr2[i] == 110) {
          str += "艺术培训，";
        } else if (arr2[i] == 111) {
          str += "小语种，";
        }
      }
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return str.substring(0, str.length - 1) + " | " + subjectlist;
    },
    dealSubjectId(row) {
      for (var i = 0; i < this.subjectidList.length; i++) {
        if (this.subjectidList[i].macroId == row.subjectId) {
          return this.subjectidList[i].name;
        }
      }
    },
    orientSelectionChange(val) {
      this.orientIdArray = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.orientIdArray.push(val[i].id);
        }
      }
    },
    orientListItemClick(row) {
      this.$refs.orientListListTable.toggleRowSelection(row);
    },
    changeBeginTime() {
      if (this.singleRuleForm.beginTime === null) {
        return;
      }
      this.singleRuleForm.beginTime = dayjs(
        this.singleRuleForm.beginTime
      ).format("YYYY-MM-DD HH:mm");

      let time = this.singleRuleForm.beginTime.substring(0, 10);
      let weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = weekArray[new Date(time).getDay()];
      if (week == "一") {
        this.singleRuleForm.week = 1;
      } else if (week == "二") {
        this.singleRuleForm.week = 2;
      } else if (week == "三") {
        this.singleRuleForm.week = 3;
      } else if (week == "四") {
        this.singleRuleForm.week = 4;
      } else if (week == "五") {
        this.singleRuleForm.week = 5;
      } else if (week == "六") {
        this.singleRuleForm.week = 6;
      } else if (week == "日") {
        this.singleRuleForm.week = 0;
      }
    },
    //学员管理列表
    teacherManagerSave() {
      var data = {
        dataAuthority: this.listParams.dataAuthority,
        keyword: this.listParams.name,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
        sex: this.listParams.sex == "0" ? "" : this.listParams.sex,
        accountBalanceBegin:
          this.listParams.accountBalanceBegin == "0"
            ? null
            : this.listParams.accountBalanceBegin,
        accountBalanceEnd:
          this.listParams.accountBalanceEnd == "0"
            ? null
            : this.listParams.accountBalanceEnd,
        searchBeginDate:
          this.listParams.searchBeginDate == "0"
            ? null
            : this.listParams.searchBeginDate,
        searchEndDate:
          this.listParams.searchEndDate == "0"
            ? null
            : this.listParams.searchEndDate,
        beginTime:
          this.listParams.beginTime == "0" ? null : this.listParams.beginTime,
        endTime:
          this.listParams.endTime == "0" ? null : this.listParams.endTime,
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
      };
      teacherManagerSave(data).then((res) => {
        this.showSaveDialog = false;
        this.studentList = res.rows;
        this.total = res.total;
      });
    },
    //学员管理列表 yiduiduo
    alreadyDistributeMore() {
      this.listMoreParams.pageNumber = 1;
      var data = {
        dataAuthority: this.listMoreParams.dataAuthority,
        keyword: this.listMoreParams.name,
        sectionId:
          this.listMoreParams.sectionId == "0"
            ? ""
            : this.listMoreParams.sectionId, //年级
        sex: this.listMoreParams.sex == "0" ? "" : this.listMoreParams.sex,
        accountBalanceBegin:
          this.listMoreParams.accountBalanceBegin == "0"
            ? null
            : this.listMoreParams.accountBalanceBegin,
        accountBalanceEnd:
          this.listMoreParams.accountBalanceEnd == "0"
            ? null
            : this.listMoreParams.accountBalanceEnd,
        searchBeginDate:
          this.listMoreParams.searchBeginDate == "0"
            ? null
            : this.listMoreParams.searchBeginDate,
        searchEndDate:
          this.listMoreParams.searchEndDate == "0"
            ? null
            : this.listMoreParams.searchEndDate,
        beginTime:
          this.listMoreParams.beginTime == "0"
            ? null
            : this.listMoreParams.beginTime,
        endTime:
          this.listMoreParams.endTime == "0"
            ? null
            : this.listMoreParams.endTime,
        pageNumber: this.listMoreParams.pageNumber,
        pageSize: this.listMoreParams.pageSize,
        maxPrice: this.alreadyParams.maxPrice,
        minPrice: this.alreadyParams.minPrice,
      };
      alreadyDistributeMore(data).then((res) => {
        this.studentMoreList = res.rows;
        this.totalMore = res.total;
      });
    },
    cancelFn() {
      if (this.type_of_cost.length == 0) {
        this.msgWarn("请先查询课程或者选择课程！");
        return false;
      }
      this.removeBatchClassVisible = true;
      // this.$confirm("确定要删除吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     batchCancel(this.dataList).then((res) => {
      //       if (res.code == 0) {
      //         this.msgSuccess("操作成功！");
      //         this.cancelCoursePopup = false;
      //         this.findCourseItemFnn();
      //       }
      //     });
      //   })
      //   .catch(() => {});
    },
    alreadyDistributeCountFn() {
      var data = {
        dataAuthority: this.alreadyParams.dataAuthority,
        keyword: this.alreadyParams.name,
        sectionId:
          this.alreadyParams.sectionId == "0"
            ? ""
            : this.alreadyParams.sectionId, //年级
        sex: this.alreadyParams.sex == "0" ? "" : this.alreadyParams.sex,
        accountBalanceBegin:
          this.alreadyParams.accountBalanceBegin == "0"
            ? null
            : this.alreadyParams.accountBalanceBegin,
        accountBalanceEnd:
          this.alreadyParams.accountBalanceEnd == "0"
            ? null
            : this.alreadyParams.accountBalanceEnd,
        searchBeginDate:
          this.alreadyParams.searchBeginDate == "0"
            ? null
            : this.alreadyParams.searchBeginDate,
        searchEndDate:
          this.alreadyParams.searchEndDate == "0"
            ? null
            : this.alreadyParams.searchEndDate,
        beginTime:
          this.alreadyParams.beginTime == "0"
            ? null
            : this.alreadyParams.beginTime,
        endTime:
          this.alreadyParams.endTime == "0" ? null : this.alreadyParams.endTime,
        pageNumber: this.alreadyParams.pageNumber,
        pageSize: this.alreadyParams.pageSize,
        maxPrice: this.alreadyParams.maxPrice,
        minPrice: this.alreadyParams.minPrice,
        levelId: this.alreadyParams.levelId,
      };
      alreadyDistributeCount(data).then((res) => {
        if (res.code == 0) {
          this.countParams = res.rows;
        }
      });
    },
    alreadyDistribute() {
      var data = {
        dataAuthority: this.alreadyParams.dataAuthority,
        keyword: this.alreadyParams.name,
        sectionId:
          this.alreadyParams.sectionId == "0"
            ? ""
            : this.alreadyParams.sectionId, //年级
        sex: this.alreadyParams.sex == "0" ? "" : this.alreadyParams.sex,
        accountBalanceBegin:
          this.alreadyParams.accountBalanceBegin == "0"
            ? null
            : this.alreadyParams.accountBalanceBegin,
        accountBalanceEnd:
          this.alreadyParams.accountBalanceEnd == "0"
            ? null
            : this.alreadyParams.accountBalanceEnd,
        searchBeginDate:
          this.alreadyParams.searchBeginDate == "0"
            ? null
            : this.alreadyParams.searchBeginDate,
        searchEndDate:
          this.alreadyParams.searchEndDate == "0"
            ? null
            : this.alreadyParams.searchEndDate,
        beginTime:
          this.alreadyParams.beginTime == "0"
            ? null
            : this.alreadyParams.beginTime,
        endTime:
          this.alreadyParams.endTime == "0" ? null : this.alreadyParams.endTime,
        pageNumber: this.alreadyParams.pageNumber,
        pageSize: this.alreadyParams.pageSize,
        maxPrice: this.alreadyParams.maxPrice,
        minPrice: this.alreadyParams.minPrice,
        studentState: this.studentStateTotal,
        levelId: this.alreadyParams.levelId,
      };
      alreadyDistribute(data).then((res) => {
        this.showSaveDialog = true;
        let curStudentList = [];
        this.alreadyList = res.rows.map((item) => {
          item.unreadCount = 0;
          curStudentList.push({
            parChatId: item.parChatId,
          });
          return item;
        });
        console.log("this.alreadyList: ", this.alreadyList);
        this.alreadyTotal = res.total;
        this.updateCurStudentList(curStudentList);
        this.dealUnreadCountChat();
      });
    },
    getTeachTitleId(row) {
      if (row.teachTitleId == "" || row.teachTitleId == 0) {
        return "均可";
      } else if (row.teachTitleId == 59) {
        return "初级讲师";
      } else if (row.teachTitleId == 60) {
        return "中级讲师";
      } else if (row.teachTitleId == 61) {
        return "高级讲师";
      } else if (row.teachTitleId == 62) {
        return "特级讲师";
      }
    },
    getTeachingAgeId(row) {
      if (row.teachingAgeId == "" || row.teachingAgeId == 0) {
        return "均可";
      } else if (row.teachingAgeId == 27) {
        return "5年以下";
      } else if (row.teachingAgeId == 28) {
        return "5-10";
      } else if (row.teachingAgeId == 29) {
        return "10-15";
      } else if (row.teachingAgeId == 227) {
        return "15-20以上";
      }
    },
    getSexNeedId(row) {
      if (row.sexNeedId == "" || row.sexNeedId == 0) {
        return "均可";
      } else if (row.sexNeedId == 1) {
        return "男老师";
      } else if (row.sexNeedId == 2) {
        return "女老师";
      }
    },
    getStyleNeedId(item) {
      let arr = new Array();
      let str = "";
      if (item) {
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if (this.styleNeedArray[i].macroId == arr[k]) {
            str += "<p>" + this.styleNeedArray[i].name + "</p>";
          }
        }
      }
      return str;
    },

    getTeachSpeed(row) {
      if (row.speedNeedId == "" || row.speedNeedId == 0) {
        return "均可";
      } else if (row.speedNeedId == 13) {
        return "正常";
      } else if (row.speedNeedId == 12) {
        return "快";
      } else if (row.speedNeedId == 14) {
        return "慢";
      }
    },
    getAchievement(row) {
      if (row.studentAchievementId == "" || row.studentAchievementId == 0) {
        return "均可";
      } else if (row.studentAchievementId == 268) {
        return "拔高型";
      } else if (row.studentAchievementId == 269) {
        return "中上等成绩";
      } else if (row.studentAchievementId == 270) {
        return "中等类待提高";
      } else if (row.studentAchievementId == 271) {
        return "基础型";
      }
    },
    getMandarinId(row) {
      if (row.mandarinId == "" || row.mandarinId == 0) {
        return "均可";
      } else if (row.mandarinId == 34) {
        return "超标准";
      } else if (row.mandarinId == 35) {
        return "普通话标准";
      } else if (row.mandarinId == 36) {
        return "一般有口音";
      } else if (row.mandarinId == 37) {
        return "口音严重";
      }
    },
    getAgeNeedId(row) {
      if (row.ageNeedId == "" || row.ageNeedId == 0) {
        return "均可";
      } else if (row.ageNeedId == 49) {
        return "25~35";
      } else if (row.ageNeedId == 79) {
        return "35~45";
      } else if (row.ageNeedId == 80) {
        return "45以上";
      }
    },
    getClassTimes(row) {
      if (row.classTimes == "" || row.classTimes == 0) {
        return "每周一次";
      } else if (row.classTimes == 1) {
        return "每周两次";
      } else if (row.classTimes == 2) {
        return "每周三次";
      } else if (row.classTimes == 3) {
        return "每周四次";
      } else if (row.classTimes == 4) {
        return "每周五次";
      } else if (row.classTimes == 5) {
        return "每周六次";
      } else if (row.classTimes == 6) {
        return "每天一次";
      }
    },
    getClassForm(row) {
      if (row.classForm == "" || row.classForm == 0) {
        return "均可";
      } else if (row.classForm == 1) {
        return "线下";
      } else if (row.classForm == 2) {
        return "线上";
      }
    },
    getPriorTimeOne(row) {
      if (row.priorTimeOne == "" || row.priorTimeOne == 0) {
        return "均可";
      } else if (row.priorTimeOne == 1) {
        return "凌晨";
      } else if (row.priorTimeOne == 2) {
        return "上午";
      } else if (row.priorTimeOne == 3) {
        return "下午";
      } else if (row.priorTimeOne == 4) {
        return "晚上";
      }
    },
    getPriorTimeTwo(row) {
      if (row.priorTimeTwo == "" || row.priorTimeTwo == 0) {
        return "均可";
      } else if (row.priorTimeTwo == 1) {
        return "凌晨";
      } else if (row.priorTimeTwo == 2) {
        return "上午";
      } else if (row.priorTimeTwo == 3) {
        return "下午";
      } else if (row.priorTimeTwo == 4) {
        return "晚上";
      }
    },
    getPriorTimeThree(row) {
      if (row.priorTimeThree == "" || row.priorTimeThree == 0) {
        return "均可";
      } else if (row.priorTimeThree == 1) {
        return "凌晨";
      } else if (row.priorTimeThree == 2) {
        return "上午";
      } else if (row.priorTimeThree == 3) {
        return "下午";
      } else if (row.priorTimeThree == 4) {
        return "晚上";
      }
    },
    getPriorDateOne(row) {
      if (row.priorDateOne == 1) {
        return "周一";
      } else if (row.priorDateOne == 2) {
        return "周二";
      } else if (row.priorDateOne == 3) {
        return "周三";
      } else if (row.priorDateOne == 4) {
        return "周四";
      } else if (row.priorDateOne == 5) {
        return "周五";
      } else if (row.priorDateOne == 6) {
        return "周六";
      } else if (row.priorDateOne == 7) {
        return "周日";
      }
    },
    getPriorDateTwo(row) {
      if (row.priorDateTwo == 1) {
        return "周一";
      } else if (row.priorDateTwo == 2) {
        return "周二";
      } else if (row.priorDateTwo == 3) {
        return "周三";
      } else if (row.priorDateTwo == 4) {
        return "周四";
      } else if (row.priorDateTwo == 5) {
        return "周五";
      } else if (row.priorDateTwo == 6) {
        return "周六";
      } else if (row.priorDateTwo == 7) {
        return "周日";
      }
    },
    getPriorDateThree(row) {
      if (row.priorDateThree == 1) {
        return "周一";
      } else if (row.priorDateThree == 2) {
        return "周二";
      } else if (row.priorDateThree == 3) {
        return "周三";
      } else if (row.priorDateThree == 4) {
        return "周四";
      } else if (row.priorDateThree == 5) {
        return "周五";
      } else if (row.priorDateThree == 6) {
        return "周六";
      } else if (row.priorDateThree == 7) {
        return "周日";
      }
    },
    saveTeacherData(num) {
      if (this.getval.length == 0) {
        this.msgWarn("请选择讲师!");
        return;
      }
      if (num == 1) {
        //配置讲师
        this.teacherList[this.getIndex].label = 2;
        var data = {
          studentId: this.idd,
          teacherId: this.checkedGhCheck,
        };
        checkHistoryChooseTeacher(data).then((res) => {
          if (res.code == 0) {
            if (res.rows == true) {
              //配置过
              this.historyDate.studentId = this.idd;
              this.$confirm("该讲师历史配置里配置过，是否继续选择?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.teacherPopup = false;
                  this.teacherIndex = "1";
                })
                .catch(() => {});
            } else {
              this.teacherPopup = false;
              this.teacherIndex = "1";
            }
          }
        });
      } else {
        //预配置讲师
        var data = {
          studentId: this.idd,
          teacherId: this.checkedGhCheck,
        };
        checkHistoryChooseTeacher(data).then((res) => {
          if (res.code == 0) {
            if (res.rows == true) {
              this.$confirm("该讲师历史配置里配置过，是否继续选择?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.teacherPopup = false;
                  this.teacherIndex = "1";
                })
                .catch(() => {});
            } else {
              this.teacherPopup = false;
              this.teacherIndex = "1";
            }
          }
        });
        this.teacherList[this.getIndex].label = 1;
      }
      this.teacherList[this.getIndex].teacherRealname =
        this.tableRadio.realname;
      this.teacherList[this.getIndex].oldTeaChatId =
        this.teacherList[this.getIndex].teaChatId;
      this.teacherList[this.getIndex].newTeaChatId = this.tableRadio.teaChatId;
      this.teacherList[this.getIndex].teacherId = this.tableRadio.id;
      this.teacherList[this.getIndex].hopeMinFee = this.tableRadio.hopeMinFee;
      this.teacherList[this.getIndex].hopeMaxFee = this.tableRadio.hopeMaxFee;
      /* this.$set(
        this.teacherList,
        this.getIndex,
        this.teacherList[this.getIndex]
      ); */
      this.teacherList[this.getIndex].show = true;
    },
    classSingBlur(index, val) {
      this.teacherList[index].show = true;
      this.$set(this.teacherList);
      this.teacherList[index].teachFee = val.replace(/[^\d]/g, "");
    },
    removeTeacherData(scope) {
      if (scope.row.id && scope.row.groupId) {
        //真删除
        this.$confirm("已创建群聊的讲师，删除后会同时解散群聊?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteOneChooseTeacher(scope.row.id).then((res) => {
              if (res.code == 0) {
                this.configteacherPlanFnList();
                this.msgSuccess("删除成功！");
              }
            });
          })
          .catch(() => {});
      } else if (scope.row.id && !scope.row.groupId) {
        this.$confirm("是否删除该讲师?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteOneChooseTeacher(scope.row.id).then((res) => {
              if (res.code == 0) {
                this.configteacherPlanFnList();
                this.msgSuccess("删除成功！");
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("是否删除该讲师?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.teacherList.splice(scope.$index, 1);
          })
          .catch(() => {});
      }
    },
    sureTeacherData(scope, index) {
      let row = scope.row;
      if (row.teacherId == undefined) {
        this.msgWarn("请选择讲师！");
        return false;
      }

      if (
        row.classSinglePrice &&
        (row.classSinglePrice == undefined || row.classSinglePrice == "")
      ) {
        this.msgWarn("请配置讲师单价！");
        return false;
      }
      const { newTeaChatId, oldTeaChatId, groupId } = row;
      this.sureTeacherArray = {
        id: scope.row.id ? scope.row.id : null,
        teacherName: row.teacherRealname,
        // teachFee: row.teachFee,
        classSinglePrice: row.classSinglePrice,
        studentId: this.idd,
        label: row.label,
        subjectId: row.subjectId,
        courseLevel: row.courseLevel,
        classSinglePrice: row.classSinglePrice,
        hopeMinFee: row.hopeMinFee,
        hopeMaxFee: row.hopeMaxFee,
        teacherId: row.teacherId,
        flag: 1,
        orderPublishId: this.getOrderPublishId,
      };
      saveOneChooseTeacher(this.sureTeacherArray).then((res) => {
        if (res.code == 0 && res.msg == "操作成功！") {
          this.msgSuccess("老师配置成功！");
          this.orderReceivingHandle = [];
          //row.id = res.id;
          row.show = false;
          row.label = res.xmtConfigteacherPlan.label;
          row.studentId = res.xmtConfigteacherPlan.studentId;
          row.studentName = res.xmtConfigteacherPlan.studentName;
          row.stuChatId = res.xmtConfigteacherPlan.stuChatId;
          row.teaChatId = res.xmtConfigteacherPlan.teaChatId;
          row.teacherId = res.xmtConfigteacherPlan.teacherId;
          row.id = res.xmtConfigteacherPlan.id;
          row.teachManagerId = res.xmtConfigteacherPlan.teachManagerId;
          row.subjectId = res.xmtConfigteacherPlan.subjectId;
          row.subjectName = res.xmtConfigteacherPlan.subjectName;
          row.teacherName = res.xmtConfigteacherPlan.teacherRealname;
          row.teacherRealname = res.xmtConfigteacherPlan.teacherRealname;
          row.parChatId = res.xmtConfigteacherPlan.parChatId;
          this.$set(this.teacherList, index, row);
          /* 聊天功能先不要 */
          /* if (newTeaChatId && oldTeaChatId && groupId) {
            let groupName = `${row.studentName.substring(0, 4)}-${
              row.subjectName
            }-${
              row.teacherRealname
                ? row.teacherRealname.substring(0, 1) + "老师"
                : "匿" + "老师"
            }`;
            if (getBytesLength(groupName) > 30) {
              for (let i = 1, len = groupName.length; i < len; i++) {
                if (getBytesLength(groupName.substring(0, i)) > 27) {
                  groupName = groupName.substring(0, i - 1);
                  break;
                }
              }
              groupName = groupName + "...";
            }
            tim
              .deleteGroupMember({
                groupID: groupId,
                userIDList: [oldTeaChatId],
                reason: "",
              })
              .then((imResponse) => {
                console.log("删除成功！");
              })
              .catch( (imError) => {
                console.warn("deleteGroupMember error:", imError); // 错误信息
                this.msgError(imError.message);
              });
            tim
              .addGroupMember({
                groupID: groupId,
                userIDList: [newTeaChatId],
              })
              .then((imResponse) => {
                console.log("添加成功！");
              })
              .catch(function (imError) {
                console.warn("addGroupMember error:", imError); // 错误信息
                this.msgError(imError.message);
              });
            tim
              .updateGroupProfile({
                groupID: groupId,
                name: groupName,
              })
              .then((imResponse) => {
                console.log(imResponse.data.group); // 修改成功后的群组详细资料
              })
              .catch(function (imError) {
                console.warn("updateGroupProfile error:", imError); // 修改群组资料失败的相关信息
                this.msgError(imError.message);
              });
            updateGroup({
              groupId,
              groupName,
              configteacherPlanId: row.id,
              teachManagerId: row.teachManagerId,
              teacherId: row.teacherId,
              studentId: row.studentId,
              subjectId: row.subjectId,
            }).then((res) => {});
          } */
        } else {
          this.msgWarn(res.msg);
          return;
        }
      });
    },

    sendLesson(num, id) {
      if (num == 2) {
        this.$confirm("是否立即结束?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            orderPublishUpdate({
              id: id,
              publishStatus: num,
              orientPublishStatus: num,
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.orderPublishListFn();
              }
            });
          })
          .catch(() => {});
      } else {
        orderPublishUpdate({ id: id, publishStatus: num }).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.orderPublishListFn();
          }
        });
      }
    },

    orderSubjectChange() {
      this.dealTeacherTitle();
    },
    orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
            this.saveRuleFormOrder.classHourId &&
          this.saveRuleFormOrder.classHourId != -1
        ) {
          this.saveRuleFormOrder.productPackageId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.classHour =
            this.productDetail.classHourList[i].classHour;
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice =
            this.productDetail.classHourList[i].singlePrice;
          this.saveRuleFormOrder.totalPrice =
            this.productDetail.classHourList[i].totalPrice;
        } else {
          this.saveRuleFormOrder.productPackageId = "";
          this.saveRuleFormOrder.classHour = "";
          this.saveRuleFormOrder.classHourId =
            this.productDetail.classHourList[i].id;
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    certificateSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.certificate = response[0].url;
        this.srcList = [];
        this.srcList.push(response[0].url);
      }
    },
    certificateError(err, file, fileList) {
      this.msgError("付款凭证上传失败！");
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    weekCheckAllChange(val) {
      let checked = cityOptions.map((item) => {
        return item.id;
      });
      this.checkedCities = val ? checked : [];
      this.isIndeterminate = false;
    },
    weeksCheckAllChange(val) {
      let checked = weekOptions.map((item) => {
        return item.id;
      });
      this.checkedWeeks = val ? checked : [];
      this.isWeekIndeterminate = false;
    },
    freeCheckAllChange(val) {
      let checked = weekOptions.map((item) => {
        return item.id;
      });
      this.freeWeeks = val ? checked : [];
      this.isFreeIndeterminate = false;
    },
    sureSaveFree() {
      this.getWeek(
        this.freeRuleFormFollowUp.beginTime,
        this.freeRuleFormFollowUp.endTime,
        this.freeWeeks,
        2
      );
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleCheckedWeeksChange(value) {
      let checkedCount = value.length;
      this.checkWeekAll = checkedCount === this.weeks.length;
      this.isWeekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    freeCheckedWeeksChange(value) {
      let checkedCount = value.length;
      this.freeWeekAll = checkedCount === this.weeks.length;
      this.isFreeIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    selectCheckAllChange(val) {
      let that = this;
      if (val) {
        that.type_of_cost = that.findCourseItemList.map((res) => {
          return res.id;
        });
        console.log(that.type_of_cost);
      } else {
        let that = this;
        that.type_of_cost = [];
      }
    },
    selecteCheckedCitiesChange() {},
    demandInfo(id) {
      this.demandDialog = true;
      getDetailByStudentId(id).then((res) => {
        this.demandParams = res.rows;
      });
    },
    addStudent() {
      this.addStudentStatus = true;
    },
    handleSelect(key, keyPath) {
      this.getKey = key;
      if (key == 1) {
        this.teacherManagerSave();
        this.showSaveDialog = true;
      } else if (key == 2) {
        //我的学员
        this.levelNum = 1;
        this.studentStateTotal = [1, 5];
        this.alreadyDistribute();
        this.alreadyDistributeCountFn();
        this.showSaveDialog = false;
      } else if (key == 3) {
        this.alreadyDistributeMore();
        this.showSaveDialog = false;
      }
    },
    //课程安排
    daalArrange(row) {
      this.weekTitle = "";
      this.idd = "";
      this.getstudentid = "";
      this.activeName = "first";
      this.arrangePopup = true;
      this.idd = row.id;
      this.teacherParams.studentId = row.id;
      this.classAddressData.studentId = row.id;
      this.detNamesData.studentId = row.id;
      this.curWeekFirstDay = dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD");
      this.freeWeekFirstDay = dayjs()
        .add(-(dayjs().day() == 0 ? 7 : dayjs().day()) + 1, "day")
        .format("YYYY-MM-DD");
      // this.saveLessonForm.studentId=row.id;
      this.getSectionName = row.sectionName ? row.sectionName : "";
      studentInfo(row.id).then((res) => {
        this.accountBalance = res.rows.accountBalance;
        this.remainingClassHours = res.rows.remainingClassHours;
        this.studentDetail = res.rows;
        this.getSectionId = res.rows.sectionId;
        this.getSexId = res.rows.sex;
      });
      this.configteacherParams.studentId = row.id;
      this.configteacherPlanFn();
      this.demandDetailFn();
      this.$nextTick(() => {
        this.dataArray2 = [];
        this.dataWeek = [];
        this.getWeekFn();
        this.findCourseItemFnn();
        this.configteacherPlanFnList();
      });
    },
    configteacherPlanFnList() {
      configteacherPlan(this.configteacherParams).then((res) => {
        this.teacherList = res.rows.map( item => {
          item.show = false 
          return item
        });
        this.teacherListTotal = res.total;
      });
    },
    findCourseItemFnn() {
      let year = dayjs(this.curWeekFirstDay).year(),
        month = dayjs(this.curWeekFirstDay).month(),
        date = dayjs(this.curWeekFirstDay).date();
      this.weekTitle =
        year +
        "年" +
        (month + 1) +
        "月第" +
        cNum[getYearWeek(year, month, date)] +
        "周";
      let list = [];
      for (var k = 6; k < 24; k++) {
        list.push([]);
        for (var q = 0; q < 7; q++) {
          // 单元格时间段的开始时间
          let cellTime = dayjs(this.curWeekFirstDay)
            .add(q, "day")
            .add(k, "hour")
            .format("YYYY-MM-DD HH:mm");
          // 时间是否是当前时间之前，是的话不能点击选择， 小时加1按照每格1小时算的
          let isPastTime = dayjs().isAfter(
            dayjs(this.curWeekFirstDay)
              .add(q, "day")
              .add(k + 1, "hour")
          );
          list[k - 6][q] = {
            check: false,
            name: "",
            pastTime: isPastTime,
            canUse: true,
            cellTime,
          };
        }
      }
      this.tableList = list;

      let searchBeginDate =
        dayjs(this.curWeekFirstDay).add(0, "day").format("YYYY-MM-DD") +
        " 00:00:00";
      let searchEndDate =
        dayjs(this.curWeekFirstDay).add(6, "day").format("YYYY-MM-DD") +
        " 23:59:59";
      findCourseItem({
        studentId: this.configteacherParams.studentId,
        searchBeginDate,
        searchEndDate,
      }).then((res) => {
        if (res.code == 0) {
          let listProductItemTeachManager =
            res.rows.listProductItemTeachManager;
          listProductItemTeachManager.forEach((item) => {
            // day取值 0 1 2 3 4 5 6 对应周一至周日
            let day =
              (dayjs(item.beginTime).day() == 0
                ? 7
                : dayjs(item.beginTime).day()) - 1;
            // hour代表y坐标因为小时轴从6开始-6便于计算
            let hour = dayjs(item.beginTime).hour() - 6;
            this.tableList[hour][day].classHourType = item.classHourType;
            this.tableList[hour][day].abnormalStatus = item.abnormalStatus;
            this.tableList[hour][day].canUse = false;
            this.tableList[hour][day].check = true;
            this.tableList[hour][day].name = item.teacherRealName;
            this.tableList[hour][day].id = item.id;
            this.tableList[hour][day].attendClassStatus =
              item.attendClassStatus;
            this.tableList[hour][day].time = `${dayjs(item.beginTime).format(
              "HH:mm"
            )}-${dayjs(item.beginTime)
              .add(item.usedClassHour, "hour")
              .format("HH:mm")}`;
            this.tableList[hour][day].usedClassHour = item.usedClassHour;
            // 如果课时大于1，则设置下边的几个时间段也不能被点击处理，不足1个小时的课程按照一个小时处理
            let len = item.usedClassHour;
            if (dayjs(item.beginTime).minute() != "00") {
              len++;
            }
            for (let i = 1; i < len; i++) {
              try {
                this.tableList[hour + i][day].canUse = false;
              } catch (err) {
                console.log(err);
              }
            }
            this.tableList[hour][day].subjectName = item.subjectName;
            this.tableList[hour][day].abnormalCause = item.abnormalCause;
          });
        }
      });
    },
    listStudentCourseFn() {
      let year = dayjs(this.freeWeekFirstDay).year(),
        month = dayjs(this.freeWeekFirstDay).month(),
        date = dayjs(this.freeWeekFirstDay).date();
      this.weekFreeTitle =
        year +
        "年" +
        (month + 1) +
        "月第" +
        cNum[getYearWeek(year, month, date)] +
        "周";
      let list = [];
      for (var k = 6; k < 24; k++) {
        list.push([]);
        for (var q = 0; q < 7; q++) {
          // 单元格时间段的开始时间
          let cellTime = dayjs(this.freeWeekFirstDay)
            .add(q, "day")
            .add(k, "hour")
            .format("YYYY-MM-DD HH:mm");
          // 时间是否是当前时间之前，是的话不能点击选择， 小时加1按照每格1小时算的
          let isPastTime = dayjs().isAfter(
            dayjs(this.freeWeekFirstDay)
              .add(q, "day")
              .add(k + 1, "hour")
          );
          list[k - 6][q] = {
            check: false,
            name: "",
            pastTime: isPastTime,
            canUse: true,
            cellTime,
            flag: false,
          };
        }
      }
      this.tableFreeList = list;
      let searchBeginDate =
        dayjs(this.freeWeekFirstDay).add(0, "day").format("YYYY-MM-DD") +
        " 00:00:00";
      let searchEndDate =
        dayjs(this.freeWeekFirstDay).add(6, "day").format("YYYY-MM-DD") +
        " 23:59:59";
      listStudentCourse({
        studentId: this.getstudentid,
        searchBeginDate,
        searchEndDate,
      }).then((res) => {
        if (res.code == 0) {
          let xmtStudentFreetime = res.rows.xmtStudentFreetime.concat(
            res.rows.listProductItemTeachManager
          );
          xmtStudentFreetime.forEach((item) => {
            let day =
              (dayjs(item.beginTime).day() == 0
                ? 7
                : dayjs(item.beginTime).day()) - 1;
            // hour代表y坐标因为小时轴从6开始-6便于计算
            let hour = dayjs(item.beginTime).hour() - 6;
            this.tableFreeList[hour][day].canUse = false;
            this.tableFreeList[hour][day].flag = false;
            this.tableFreeList[hour][day].check = true;
            this.tableFreeList[hour][day].name = "可排课";
            this.tableFreeList[hour][day].id = item.id;
            this.tableFreeList[hour][day].teacherRealName =
              item.teacherRealName;
            this.tableFreeList[hour][day].subjectName = item.subjectName;
            this.tableFreeList[hour][day].time = `${dayjs(
              item.beginTime
            ).format("HH:mm")}-${dayjs(item.beginTime)
              .add(item.usedClassHour, "hour")
              .format("HH:mm")}`;
            this.tableFreeList[hour][day].usedClassHour = item.usedClassHour;
            // 如果课时大于1，则设置下边的几个时间段也不能被点击处理，不足1个小时的课程按照一个小时处理
            let len = item.usedClassHour;
            if (dayjs(item.beginTime).minute() != "00") {
              len++;
            }
            for (let i = 1; i < len; i++) {
              try {
                this.tableFreeList[hour + i][day].canUse = false;
              } catch (err) {
                console.log(err);
              }
            }
            this.tableFreeList[hour][day].subjectName = item.subjectName;
          });
        }
      });
      console.log(this.tableFreeList, "tableFreeList..");
    },
    dealTeachTitleName(row) {
      if (row.teachTitleName) {
        return row.teachTitleName;
      } else {
        return "均可";
      }
    },
    usedTitleInput() {
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].title = this.usedTitle;
      }
      //this.$set(this.courseDate);
    },
    async courseEdit(items) {
      console.log(items);
      this.singlePopup = true;
      this.singleParams.title = "编辑课程";
      this.singleParams.type = "edit";
      this.getOrganBalance();
      await getProductByStudentId(this.idd).then((res) => {
        this.buyProductList = res.rows;
      });
      studentInfo(this.idd).then((res) => {
        this.accountBalance = res.rows.accountBalance;
        this.remainingClassHours = res.rows.remainingClassHours;
      });
      xmtProductItemInfo(items.id).then((res) => {
        this.editId = res.rows.id;
        this.getSubjectId = res.rows.subjectId;
        this.getTeacher = res.rows.teacherId;
        this.teacherParams.subjectId = res.rows.subjectId;
        this.editTime = res.rows.beginTime;
        this.editUsedClassHour = res.rows.usedClassHour;
        this.editUsePrice = res.rows.usePrice;
        this.editClassHourType = res.rows.classHourType;
        this.singleRuleForm = {
          // id: res.rows.id,
          subjectId: res.rows.subjectId,
          title: res.rows.title,
          beginTime: res.rows.beginTime,
          usedClassHour: res.rows.usedClassHour,
          isFirstClass: String(res.rows.isFirstClass),
          teacherId: res.rows.teacherId,
          configteacherPlanId: res.rows.configteacherPlanId,
          classMode: String(res.rows.classMode),
          classHourType: this.classHourTypeFn(String(res.rows.classHourType)),
          classAddress: res.rows.classAddress,
          productId: res.rows.productId,
          teachFee: res.rows.teachFee,
          isPrisonClass: String(res.rows.isPrisonClass),
          isPlayback: String(res.rows.isPlayback),
          ifClassRemuneration: String(res.rows.ifClassRemuneration),
          videoMode: res.rows.videoMode,
          expense: "--",
        };
        if (res.rows.classHourType == 1 || res.rows.classHourType == 2) {
          this.classHourStatus = "2";
        } else {
          this.classHourStatus = "1";
        }
        this.newteacherName = res.rows.teacherRealName;
        if (this.classHourStatus == 2) {
          this.surplusNumFn(res.rows.productId);
        }
        for (var i = 0; i < this.buyProductList.length; i++) {
          if (this.buyProductList[i].orderId == res.rows.orderId) {
            this.productSinglePrice = this.buyProductList[i].singlePrice
              ? Number(this.buyProductList[i].singlePrice)
              : Number(this.buyProductList[i].zdySinglePrice);
          }
        }
        this.getSingleClassFee();
      });
    },
    //退回学生
    daalReturnStudent(row) {
      this.$confirm(
        "是否退回该学生到教务主管，退回后将不再是你的学生?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          returnStudentByStudentId(row.id).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.alreadyDistribute();
            } else {
              this.msgWarn(res.msg);
              return;
            }
          });
        })
        .catch(() => {});
    },

    matching(row) {},
    sendOut(row) {
      console.log(row.classForm);
      this.saveLessonDialogVisible = true;
      this.saveLessonForm = {
        studentId: row.studentId,
        classListTitle: "",
        sectionId: "",
        ifFaceToFace: "0",
        minimumClassPay: "",
        maximumClassPay: "",
        classHour: "",
        formOfClass: row.classForm,
        subjectName: row.subjectName ? row.subjectName : "",
        score: row.subjectScore ? row.subjectScore : "",
        totalScore: row.subjectAllScore ? row.subjectAllScore : "",
        subjectId: row.subjectId ? Number(row.subjectId) : "",
        studentAchievementId: row.studentAchievementId
          ? row.studentAchievementId
          : "",
        teachingAgeId: row.teachingAgeId ? row.teachingAgeId : 0,
        sexNeedId: row.sexNeedId ? row.sexNeedId : 0,
        mandarinId: row.mandarinId ? row.mandarinId : 0,
        studentDesc: row.studentDescribe ? row.studentDescribe : "",
        remarks: row.remark ? row.remark : "",
        styleNeedId: row.styleNeedId ? row.styleNeedId : "",
      };
      this.styleNeedGroup = row.styleNeedId
        .split(",")
        .map(function (item, index, array) {
          return item - 0;
        });
      this.formOfClassModel.push(row.classForm);

      // var arr = [];
      // arr = row.formOfClass.split(",");
      // this.formOfClassModel = arr.map(Number);
      // for (var i = 0; i < this.formOfClassModel.length; i++) {
      //   if (this.formOfClassModel[i] == 1) {
      //     this.addressShow = true;
      //   } else {
      //     this.addressShow = false;
      //   }
      // }
    },
    //
    singleTime(items) {
      studentInfo(this.idd).then((res) => {
        this.accountBalance = res.rows.accountBalance;
        this.remainingClassHours = res.rows.remainingClassHours;
      });
      this.classHourStatus = "1";
      let beginTime = "";
      if (items) {
        beginTime = items.cellTime ? items.cellTime : "";
        // 过去的时间段和已经占用的时间段不处理
        if (items.pastTime || !items.canUse) {
          return;
        }
      }
      this.singlePopup = true;
      this.singleParams.title = "新增课程";
      this.getOrganBalance();
      this.getSingleClassFee();
      this.singleParams.type = "add";
      this.singleRuleForm = {
        subjectId: "", //学科
        classMode: "1", //上课模式
        title: "", //课程标题
        classHourType: this.classHourStatus == 2 ? "1" : "3", //收费类型
        usedClassHour: "2", //课时数
        beginTime, //上课时间
        isFirstClass: "0", //首次课设置
        isPrisonClass: "0",
        isPlayback: "0",
        teacherId: "", //讲师
        productId: "",
        classAddress: "",
        totalPrice: "", //总金额
        usePrice: "", //这节课使用的金额
        productId: "",
        sectionId: this.getSectionId,
        configteacherPlanId: "",
        videoMode: 1,
        expense: "--",
      };
      this.newProductId = "";
      this.surplusNum = 0;
      this.newteacherName = "";
      this.teacherParams.subjectId = "";
      this.productListFn();
    },
    //获取该学生购买过的产品
    productListFn() {
      getProductByStudentId(this.idd).then((res) => {
        this.buyProductList = res.rows;
      });
    },
    batchProductListFn() {
      getProductByStudentIdForBatch({ studentId: this.idd }).then((res) => {
        this.batchProductList = res.rows;
      });
    },
    //批量排课
    batchTime() {
      this.courseShow = false;
      this.batchTimePopup = true;
      this.batchPickerValue = "";
      this.checkedWeeks = [];
      this.batchTimeForm.classHourType = "";
      this.newProductId = "";
      this.surplusNum2 = 0;
      this.courseDate = [];
      this.tableSubjectId = null;
      this.pickerData = "";
      this.usedClassHour = "";
      this.usedTitle = "";
      this.selectTeacherName = null;
      this.selectTeacherId = null;
      this.selectConfigteacherPlanId = null;
      this.selectClassAddress = null;
      this.batchTimeForm.videoMode = 1;
      this.batchTimeForm.expense = ''
      this.getOrganBalance();
      this.getSingleClassFee();
      studentInfo(this.idd).then((res) => {
        this.accountBalance = res.rows.accountBalance;
        this.remainingClassHours = res.rows.remainingClassHours;
      });
      // this.productListFn();
      this.batchProductListFn();
    },
    classHourStatusChange() {
      if (this.classHourStatus == 1) {
        this.singleRuleForm.classHourType = "3";
      } else {
        this.singleRuleForm.classHourType = "1";
      }
    },
    //批量取消
    batchCancel() {
      this.cancelCoursePopup = true;
      this.cancelCourseForm.subjectId = "";
      this.batchPickerValue2 = "";
      this.batchPickerValue3 = "";
      this.checkedCities = [];
      this.selectAll = false;
      this.checkAll = false;
      this.findCourseItemList = [];
    },
    mustTest(row) {
      var str = "";
      if (row.qitaxueke == 1) {
        str += "其他学科，";
      }
      if (row.yuwen == 1) {
        str += "语文，";
      }
      if (row.shuxue == 1) {
        str += "数学，";
      }
      if (row.yingyu == 1) {
        str += "英语，";
      }
      if (row.wuli == 1) {
        str += "物理，";
      }
      if (row.lishi == 1) {
        str += "历史，";
      }
      if (row.zhengzhi == 1) {
        str += "政治，";
      }
      if (row.huaxue == 1) {
        str += "化学，";
      }
      if (row.dili == 1) {
        str += "地理，";
      }
      if (row.shengwu == 1) {
        str += "生物，";
      }
      return str.substring(0, str.length - 1);
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.teacherManagerSave();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.teacherManagerSave();
    },
    alreadySizeChange(pageSize) {
      this.alreadyParams.pageSize = pageSize;
      this.alreadyDistribute();
    },
    alreadyCurrentChange(currentPage) {
      this.alreadyParams.pageNumber = currentPage;
      this.alreadyDistribute();
    },
    moreSizeChange(pageSize) {
      this.listMoreParams.pageSize = pageSize;
      this.alreadyDistributeMore();
    },
    moreCurrentChange(currentPage) {
      this.listMoreParams.pageNumber = currentPage;
      this.alreadyDistributeMore();
    },
    overallScoreFn(row) {
      var str = "";
      if (row.overallScore == 1) {
        str = "A+";
      } else if (row.overallScore == 2) {
        str = "A";
      } else if (row.overallScore == 3) {
        str = "B+";
      } else if (row.overallScore == 4) {
        str = "B";
      } else if (row.overallScore == 5) {
        str = "C";
      } else if (row.overallScore == 6) {
        str = "D";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    appearance(row) {
      var str = "";
      if (row.imageTemperament == 1) {
        str = "负分";
      } else if (row.imageTemperament == 2) {
        str = "正常";
      } else if (row.imageTemperament == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    connectCapacity(row) {
      var str = "";
      if (row.communicationExpression == 1) {
        str = "负分";
      } else if (row.communicationExpression == 2) {
        str = "正常";
      } else if (row.communicationExpression == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    lectureSpeed(row) {
      var str = "";
      if (row.substituteLessonsBeijing == 1) {
        str = "5年以下";
      } else if (row.substituteLessonsBeijing == 2) {
        str = "5-10年";
      } else if (row.substituteLessonsBeijing == 3) {
        str = "10年以上";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    specialtySkil(row) {
      var str = "";
      if (row.specialtySkil == 1) {
        str = "极佳";
      } else if (row.specialtySkil == 2) {
        str = "佳";
      } else if (row.specialtySkil == 3) {
        str = "平实";
      } else if (row.specialtySkil == 4) {
        str = "略差";
      } else if (row.specialtySkil == 5) {
        str = "极差";
      }
      return str;
    },

    lectureStyle(row) {
      var str = "";
      if (row.teachingExperience == 1) {
        str = "5年以下";
      } else if (row.teachingExperience == 2) {
        str = "5-10年";
      } else if (row.teachingExperience == 3) {
        str = "10-15年";
      } else if (row.teachingExperience == 4) {
        str = "15-20以上";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    teachingCharacteristics(row) {
      var str = "";
      if (row.teachingCharacteristics == 1) {
        str = "负分";
      } else if (row.teachingCharacteristics == 2) {
        str = "正常";
      } else if (row.teachingCharacteristics == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    timeClass(item) {
      if (item.time) {
        var minuteTop = Number(item.time.substring(3, 5)),
          spacing = 39.9 / 60,
          start = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[0]
          ),
          end = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[1]
          ),
          m = "",
          h = "";
        if (start && end) {
          var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
          var cal = Math.floor(subTime / 60); //分钟
        }
        if (minuteTop != 0) {
          return {
            top: spacing * minuteTop + "px",
            position: "absolute",
            "z-index": "99999",
            background: item.abnormalCause ? "red" : "#1890FF",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        } else {
          return {
            top: "0px",
            position: "absolute",
            "z-index": "99999",
            background: item.abnormalCause ? "red" : "#1890FF",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        }
      }
    },
    timeClassWork(item) {
      if (item.teacherRealName) {
        var minuteTop = Number(item.time.substring(3, 5)),
          spacing = 39.9 / 60,
          start = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[0]
          ),
          end = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[1]
          ),
          m = "",
          h = "";
        if (start && end) {
          var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
          var cal = Math.floor(subTime / 60); //分钟
        }
        if (minuteTop != 0) {
          return {
            top: spacing * minuteTop + "px",
            position: "absolute",
            "z-index": "99999",
            background: item.abnormalCause ? "red" : "#1890FF",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        } else {
          return {
            top: "0px",
            position: "absolute",
            "z-index": "99999",
            background: item.abnormalCause ? "red" : "#1890FF",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        }
      }
    },
    timeClasss(item) {
      if (item.time) {
        var minuteTop = item.time ? Number(item.time.substring(3, 5)) : 0,
          spacing = 39.9 / 60,
          start = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[0]
          ),
          end = new Date(
            item.cellTime.split(" ")[0] + " " + item.time.split("-")[1]
          ),
          m = "",
          h = "";
        if (start && end) {
          var subTime = Math.floor((end.getTime() - start.getTime()) / 1000);
          var cal = Math.floor(subTime / 60); //分钟
        }
        if (minuteTop != 0) {
          return {
            top: spacing * minuteTop + "px",
            position: "absolute",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        } else {
          return {
            top: "0px",
            position: "absolute",
            width: "100%",
            "font-size": "12px",
            color: "#fff",
            height: Number(cal) * spacing + "px",
          };
        }
      }
    },
    jobHuntingAttitude(row) {
      var str = "";
      if (row.jobHuntingAttitude == 1) {
        str = "负分";
      } else if (row.jobHuntingAttitude == 2) {
        str = "正常";
      } else if (row.jobHuntingAttitude == 3) {
        str = "加分";
      } else {
        str = "暂无数据";
      }
      return str;
    },
    async provinceChange() {
      await this.getCityList();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChange();
    },
    async cityChange() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    getCityLists() {
      return systemAreaList({
        parentId: this.saveRuleForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },
    async provinceChangeList() {
      await this.getCityLists();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChangeList();
    },
    async cityChangeList() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    getProvinceList() {
      return systemAreaList({
        parentId: this.parentId,
      }).then((res) => {
        if (res.code == 0) {
          this.provinceList = res.rows;
          this.provinceLessonList = res.rows;
        }
      });
    },
    getCityList() {
      return systemAreaList({
        parentId: this.saveRuleForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },
    getEditCityList() {
      return systemAreaList({
        parentId: this.editRuleForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          console.log(res.rows);
          this.cityList = res.rows;
        }
      });
    },
    getEditCityLists() {
      return systemAreaList({
        parentId: this.saveAddressForm.provinceId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCityList = res.rows;
        }
      });
    },
    getConstellationList() {
      getSelectList({
        parentCode: constellation,
      }).then((res) => {
        if (res.code == 0) {
          this.constellationList = res.rows;
        }
      });
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveRuleForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    editgetCountyList() {
      return systemAreaList({
        parentId: this.editRuleForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    getEditCountyList() {
      return systemAreaList({
        parentId: this.editRuleForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    getEditCountyLists() {
      return systemAreaList({
        parentId: this.saveAddressForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCountyList = res.rows;
        }
      });
    },
    subjectIdChange() {
      for (var i = 0; i < this.subjectList.length; i++) {
        if (this.saveLessonForm.subjectId == this.subjectList[i].macroId) {
          this.saveLessonForm.subjectName = this.subjectList[i].name;
        }
      }
    },
    sectionIdChange() {
      for (var i = 0; i < this.sectionList.length; i++) {
        if (this.saveLessonForm.sectionId == this.sectionList[i].macroId) {
          this.saveLessonForm.sectionName = this.sectionList[i].name;
        }
      }
    },
    //分配时间
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    dateMorePickerChange() {
      if (!this.timeMorePickerValue) {
        this.listMoreParams.searchBeginDate = null;
        this.listMoreParams.searchEndDate = null;
        return;
      }
      this.listMoreParams.searchBeginDate = this.timeMorePickerValue[0];
      this.listMoreParams.searchEndDate = this.timeMorePickerValue[1];
    },
    dateNextPickerChange() {
      if (!this.timeNextPickerValue) {
        this.listMoreParams.beginTime = null;
        this.listMoreParams.endTime = null;
        return;
      }
      this.listMoreParams.beginTime = this.timeNextPickerValue[0];
      this.listMoreParams.endTime = this.timeNextPickerValue[1];
    },
    alreadyPickerChange() {
      if (!this.alreadyPickerValue) {
        this.alreadyParams.searchBeginDate = null;
        this.alreadyParams.searchEndDate = null;
        return;
      }
      this.alreadyParams.searchBeginDate = this.alreadyPickerValue[0];
      this.alreadyParams.searchEndDate = this.alreadyPickerValue[1];
    },
    //下次更进时间
    datePickerChange2() {
      if (!this.timePickerValue2) {
        this.listParams.beginTime = null;
        this.listParams.endTime = null;
        return;
      }
      this.listParams.beginTime = this.timePickerValue2[0];
      this.listParams.endTime = this.timePickerValue2[1];
    },
    alreadyPickerChange2() {
      if (!this.alreadyPickerValue2) {
        this.alreadyParams.beginTime = null;
        this.alreadyParams.endTime = null;
        return;
      }
      this.alreadyParams.beginTime = this.alreadyPickerValue2[0];
      this.alreadyParams.endTime = this.alreadyPickerValue2[1];
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: "0",
            name: "全部",
          });
        }
      });
    },
    getSectionLists() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionLists = res.rows;
        }
      });
    },
    getTeacherId() {
      if (
        this.teacherParams.subjectId == null ||
        this.teacherParams.subjectId == ""
      ) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.selectTeacherPopup = true;
      this.teacherType = 1;
      this.configteacherPlanFn();
    },
    configteacherPlanFn() {
      configteacherPlan(this.teacherParams).then((res) => {
        this.matchingTeacherList = res.rows;
        this.teacherTotalnew = res.total;
      });
    },
    handleTeacherSelectionChange(val) {
      this.teacherSelectionHandle = val;
      this.checkedGh = val.id;
      if (val.length > 1) {
        this.$refs.resTeacherListTable.clearSelection();
        this.$refs.resTeacherListTable.toggleRowSelection(val.pop());
      }
    },
    rowTeacherItemClick(row) {
      this.$refs.resTeacherListTable.toggleRowSelection(row);
    },
    getRowKeys(row) {
      return row.id;
    },
    selectChanged(value) {
      this.teacherParams.subjectId = value;
      if (value != this.getSubjectId) {
        this.newteacherName = "";
        this.singleRuleForm.teacherId = "";
      }
      for (var i = 0; i < this.singSubjectIdList.length; i++) {
        if (this.singSubjectIdList[i].macroId == value) {
          this.singleRuleForm.title =
            this.getSectionName + this.singSubjectIdList[i].name;
        }
      }
    },
    courseLevelFormatter(row) {
      switch (row.courseLevel) {
        case 1:
          return "初级课程";
        case 2:
          return "中级课程";
        case 3:
          return "高级课程";
        case 4:
          return "特级课程";
        case 0:
          return "正常课程";
        default:
          return "";
      }
    },
    dp_item(item) {
      let num = 0;
      item.data.forEach((i) => {
        if (i.flag) {
          num++;
        }
      });
      if (num == 0) {
        item.flag = false;
      } else {
        item.flag = true;
      }
    },
    handleTitAll(pi) {
      pi.data.forEach((item) => {
        item.flag = pi.flag;
      });
    },
    orientIssuing(id, type) {
      if (type == 2) {
        this.msgWarn("该课单已结束，不能再定向发单！");
        return;
      }
      this.orientParams.id = id;
      this.orientPopup = true;
      this.entitiesState = [];
      listTeacherTeble().then((res) => {
        let concernArray = [];
        let concernTeacherArray = [];
        let oftenUsedTeacherArray = [];
        concernTeacherArray.push({
          name: "关注讲师",
          flag: false,
          data: res.rows.concernTeacher,
        });
        oftenUsedTeacherArray.push({
          name: "常用讲师",
          flag: false,
          data: res.rows.oftenUsedTeacher,
        });
        Object.keys(res.rows.concernTeacherGroup).forEach(function (key) {
          concernArray.push({
            name: res.rows.concernTeacherGroup[key].groupName,
            flag: false,
            data: res.rows.concernTeacherGroup[key].groupTeacherList,
          });
        });
        this.orientList = concernArray.concat(
          oftenUsedTeacherArray,
          concernTeacherArray
        );
        let len = this.orientList.length;
        this.listLen = len;
        for (let i = 0; i < len; i++) {
          this.$set(this.childState, i, []);
        }
      });
    },
    sureTeacher() {
      this.selectTeacherPopup = false;
      if (this.teacherType == 1) {
        this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
        this.newteacherName = this.teacherSelectionHandle[0].teacherRealname;
        this.singleRuleForm.teacherId =
          this.teacherSelectionHandle[0].teacherId;
        this.singleRuleForm.configteacherPlanId =
          this.teacherSelectionHandle[0].id;
        this.singleRuleForm.teachFee = this.teacherSelectionHandle[0].teachFee;
      } else {
        //批量选择老师
        this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
        for (var i = 0; i < this.courseDate.length; i++) {
          this.courseDate[i].teacherName =
            this.teacherSelectionHandle[0].teacherRealname;
          this.courseDate[i].teacherId =
            this.teacherSelectionHandle[0].teacherId;
          this.courseDate[i].configteacherPlanId =
            this.teacherSelectionHandle[0].id;
          this.courseDate[i].teachFee = this.teacherSelectionHandle[0].teachFee;
        }
      }
    },
    handleClickItem() {
      this.$nextTick(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
    classMoreChange() {
      if (this.classHourStatusMore == 1) {
        this.batchTimeForm.classHourType = "3";
      } else {
        this.batchTimeForm.classHourType = "1";
      }
    },
    classHourTypeFn(type) {
      if (type == 3 || type == 4) {
        return String(3);
      } else {
        return type;
      }
    },
    handleTeacherNewSizeChange(pageSize) {
      this.teacherParams.pageSize = pageSize;
      this.configteacherPlanFn();
    },
    handleTeacherNewCurrentChange(currentPage) {
      this.teacherParams.pageNumber = currentPage;
      this.configteacherPlanFn();
    },
    getDetailAddress() {
      this.detailAddressPopup = true;
      this.addressType = 1;
      this.studentClassAddressList();
    },
    getAddressDetNames() {
      this.detailDetNamesPopup = true;
      this.detNamesList();
    },
    detNamesList() {
      studentClassAddressList(this.classAddressData).then((res) => {
        this.detNamesListData = res.rows;
        this.detNamesTotal = res.total;
      });
    },
    addDetNames() {
      this.addAddressPopup = true;
      this.saveAddressForm.provinceId = "";
      this.saveAddressForm.cityId = "";
      this.saveAddressForm.countyId = "";
      this.saveAddressForm.detailAddress = "";
      this.saveAddressForm.isDefault = "0";
      this.addAddressParams = {
        title: "添加地址",
        type: "add",
      };
      this.address1();
    },
    async editDetNames() {
      if (this.detNamesId.length > 1) {
        this.msgWarn("请选择单个地址进行编辑！");
        return;
      }
      if (this.detNamesId.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.addAddressParams = {
        title: "编辑地址",
        type: "edit",
      };
      this.addAddressPopup = true;
      await this.address1();
      await studentClassAddressInfo(this.detNamesId[0]).then((res) => {
        if (res.code == 0) {
          this.getAddressId = res.rows.id;
          this.saveAddressForm = {
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            isDefault: String(res.rows.isDefault),
            detailAddress: res.rows.detailAddress,
          };
        }
      });
    },
    removeDetNames() {
      if (this.detNamesId.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentClassAddressRemove(this.detNamesId).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.detNamesList();
            }
          });
        })
        .catch(() => {});
    },
    detNamesListDataChange(val) {
      if (val.length > 1) {
        this.$refs.detNamesListData.clearSelection();
        this.$refs.detNamesListData.toggleRowSelection(val.pop());
      }
      this.detNamesId = [];
      this.saveCountyId = "";
      this.saveProvinceId = "";
      this.saveCityId = "";
      this.detNamesInfo = val[0];
      for (let i = 0, len = val.length; i < len; i++) {
        this.detNamesId.push(val[i].id);
        this.saveCountyId = val[i].countyId;
        this.saveProvinceId = val[i].provinceId;
        this.saveCityId = val[i].cityId;
      }
    },
    //选择地址点确定
    clickDetNamesSure() {
      console.log(this.detNamesInfo);
      if (this.detNamesId.length <= 0) {
        this.msgWarn("请选择上课地址！");
        return;
      }
      this.detailDetNamesPopup = false;
      this.saveLessonForm.addressDetNames =
        this.detNamesInfo.provinceName +
        this.detNamesInfo.cityName +
        this.detNamesInfo.countyName +
        this.detNamesInfo.detailAddress;
    },
    detNamesListDataItemClick(row) {
      this.$refs.detNamesListData.toggleRowSelection(row);
    },
    handleSizeChangeDetNames(pageSize) {
      this.detNamesData.pageSize = pageSize;
      this.detNamesList();
    },
    handleCurrentChangeDetNames(currentPage) {
      this.detNamesData.pageNumber = currentPage;
      this.detNamesList();
    },

    studentClassAddressList() {
      studentClassAddressList(this.classAddressData).then((res) => {
        this.addressListData = res.rows;
        this.totalData = res.total;
      });
    },
    //添加新地址
    addAddress() {
      this.addAddressPopup = true;
      this.addAddressParams = {
        title: "添加地址",
        type: "add",
      };
      this.saveAddressForm.provinceId = "";
      this.saveAddressForm.cityId = "";
      this.saveAddressForm.countyId = "";
      this.saveAddressForm.detailAddress = "";
      this.address1();
    },
    //删除新地址
    removeAddress() {
      if (this.selectData.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          studentClassAddressRemove(this.selectData).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentClassAddressList();
            }
          });
        })
        .catch(() => {});
    },
    //编辑新地址
    async editAddress() {
      if (this.selectData.length > 1) {
        this.msgWarn("请选择单个地址进行编辑！");
        return;
      }
      if (this.selectData.length == 0) {
        this.msgWarn("请至少选择一个地址！");
        return;
      }
      this.addAddressParams = {
        title: "编辑地址",
        type: "edit",
      };
      this.addAddressPopup = true;
      await this.address1();
      await studentClassAddressInfo(this.selectData[0].id).then((res) => {
        if (res.code == 0) {
          this.getAddressId = res.rows.id;
          this.saveAddressForm = {
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            isDefault: String(res.rows.isDefault),
            detailAddress: res.rows.detailAddress,
          };
          if (res.rows.provinceId) {
            this.getEditCityLists();
          }
          if (res.rows.cityId) {
            this.getEditCountyLists();
          }
        }
      });
    },
    address1() {
      return systemAreaList({
        parentId: this.parentId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressProvinceList = res.rows;
        }
      });
    },
    async addressProvinceChange() {
      await this.addressCityListFn();
      this.saveAddressForm.cityId = this.addressCityList[0].areaId;
      this.addressCityChange();
    },
    async addressCityChange() {
      await this.addressCountyListFn();
      this.saveAddressForm.countyId = this.addressCountyList[0].areaId;
    },
    addressCityListFn() {
      return systemAreaList({
        parentId: this.saveAddressForm.provinceId,
      }).then((res) => {
        this.addressCityList = res.rows;
      });
    },
    addressCountyListFn() {
      return systemAreaList({
        parentId: this.saveAddressForm.cityId,
      }).then((res) => {
        if (res.code == 0) {
          this.addressCountyList = res.rows;
        }
      });
    },
    seeInfo(row) {
      this.orderInfoStatus = true;
      this.orderInfoParams = {
        id: row.id,
        studentId: row.studentId,
      };
    },
    confirmAddressSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveAddressForm.studentId = this.idd;
          checkDefault(this.idd).then((res) => {
            if (res.code == 0) {
              if (this.addAddressParams.type == "add") {
                if (res.msg == "无默认") {
                  studentClassAddressSave(this.saveAddressForm).then((res) => {
                    if (res.code == 0) {
                      this.addAddressPopup = false;
                      this.studentClassAddressList();
                      this.detNamesList();
                    }
                  });
                } else {
                  if (this.saveAddressForm.isDefault == 1) {
                    this.msgWarn("已有默认地址！");
                    return false;
                  }
                  studentClassAddressSave(this.saveAddressForm).then((res) => {
                    if (res.code == 0) {
                      this.addAddressPopup = false;
                      this.studentClassAddressList();
                      this.detNamesList();
                    }
                  });
                }
              } else if (this.addAddressParams.type == "edit") {
                this.saveAddressForm.id = this.selectData[0].id;
                if (res.msg == "无默认") {
                  studentClassAddressUpdate(this.saveAddressForm).then(
                    (res) => {
                      if (res.code == 0) {
                        this.addAddressPopup = false;
                        this.studentClassAddressList();
                        this.detNamesList();
                      }
                    }
                  );
                } else {
                  if (this.saveAddressForm.id == this.defaultId) {
                    studentClassAddressUpdate(this.saveAddressForm).then(
                      (res) => {
                        if (res.code == 0) {
                          this.addAddressPopup = false;
                          this.studentClassAddressList();
                          this.detNamesList();
                        }
                      }
                    );
                  } else {
                    if (this.saveAddressForm.isDefault == 1) {
                      this.msgWarn("已有默认地址！");
                      return false;
                    } else {
                      studentClassAddressUpdate(this.saveAddressForm).then(
                        (res) => {
                          if (res.code == 0) {
                            this.addAddressPopup = false;
                            this.studentClassAddressList();
                            this.detNamesList();
                          }
                        }
                      );
                    }
                  }
                }
              }
            }
          });
        }
      });
    },
    addressListDataChange(val) {
      this.selectData = val;
      this.newdetailAddress = val[0];
      if (val.length > 1) {
        this.$refs.addressListData.clearSelection();
        this.$refs.addressListData.toggleRowSelection(val.pop());
      }
    },
    addressListDataItemClick(row) {
      this.$refs.addressListData.toggleRowSelection(row);
    },
    handleSizeChangeAddress(pageSize) {
      this.classAddressData.pageSize = pageSize;
      this.studentClassAddressList();
    },
    handleCurrentChangeAddress(currentPage) {
      this.classAddressData.pageNumber = currentPage;
      this.studentClassAddressList();
    },
    //选择地址点确定
    clickAddressSure() {
      if (this.selectData.length <= 0) {
        this.msgWarn("请选择上课地址！");
        return;
      }
      this.detailAddressPopup = false;
      if (this.addressType == 1) {
        this.singleRuleForm.classAddress =
          this.newdetailAddress.provinceName +
          this.newdetailAddress.cityName +
          this.newdetailAddress.countyName +
          this.newdetailAddress.detailAddress;
      } else {
        this.selectClassAddress =
          this.newdetailAddress.provinceName +
          this.newdetailAddress.cityName +
          this.newdetailAddress.countyName +
          this.newdetailAddress.detailAddress;
        for (var i = 0; i < this.courseDate.length; i++) {
          this.courseDate[i].classAddress =
            this.newdetailAddress.provinceName +
            this.newdetailAddress.cityName +
            this.newdetailAddress.countyName +
            this.newdetailAddress.detailAddress;
        }
      }
    },
    showTag() {
      this.flag = !this.flag;
    },
    /* 视频模式改变 */
    videoModeChanged() {
      this.getSingleClassFee();
    },
    /* 课时数变化 */
    classHourChange() {
      this.computeClassExpense();
    },
    /* 获取当前机构余额 */
    getOrganBalance() {
      this.organTotalBalance = "--";
      getOrganBalance().then((res) => {
        console.log("getOrganBalance: ", res);
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.organTotalBalance = res.rows.accountBalance
          ? res.rows.accountBalance
          : 0;
      });
    },
    /* 获取特定上课模式下单节课的费用 */
    getSingleClassFee() {
      this.oneToOneSingleClassPrice = 0;
      this.singleRuleForm.expense = "--";
      const classMode = 1;
      let videoMode = 1;
      if (this.batchTimePopup) {
        // 批量排课
        videoMode = this.batchTimeForm.videoMode;
      } else if (this.singlePopup) {
        // 单次排课
        videoMode = this.singleRuleForm.videoMode;
      }
      if (videoMode) {
        getFlowFeeByMode({
          classMode,
          videoMode,
        }).then((res) => {
          console.log("getOrganBalance: ", res);
          if (res.flag == "error") {
            return this.$message.error(res.msg);
          }
          this.oneToOneSingleClassPrice = res.rows.flowFee;
          this.computeClassExpense();
        });
      }
    },
    /* 计算本次排课消耗金额 */
    computeClassExpense() {
      let classPirce = -1;
      this.singleRuleForm.expense = "--";
      // 单次排课处理
      if (this.singlePopup) {
        // this.singleRuleForm.classMode  1 线上
        if( this.singleRuleForm.classMode == 2){
          this.singleRuleForm.expense = 0
          return
        }
        const { usedClassHour } = this.singleRuleForm;
        if(this.oneToOneSingleClassPrice != 0){
          classPirce = this.oneToOneSingleClassPrice * usedClassHour;
        }
        if (classPirce == -1) {
          this.singleRuleForm.expense = "--";
        } else {
          this.singleRuleForm.expense = classPirce;
        }
        // 批量排课处理
      } else if (this.batchTimePopup) {
        console.log(111);
        if (this.courseDate.length > 0) {
          // isIncludeOnlineClass 是否包含有线上课，默认为false
          let totalHours = 0,
            isIncludeOnlineClass = false;
          for (let i = 0, len = this.courseDate.length; i < len; i++) {
            const { usedClassHour, classMode } = this.courseDate[i];
            if (!usedClassHour) {
              totalHours = 0;
              break;
            }
            if (!classMode) {
              // classMode true 线下课，流量费为0， false 线上课 有流量费
              isIncludeOnlineClass = true;
              totalHours += usedClassHour;
            }
          }
          // 1，如果总课时不为0说明，每个item 课时数都填写了， 2，如果总课时数为0，并且每个item 都为线下课时， 这两种情况显示消耗流量数
          if (totalHours || (!totalHours && !isIncludeOnlineClass)) {
            classPirce = this.oneToOneSingleClassPrice * totalHours;
          }
          if (classPirce == -1) {
            this.singleRuleForm.expense = "--";
          } else {
            this.singleRuleForm.expense = classPirce;
          }
        }
      }
    },
    sureSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.saveDisabled) {
            return;
          }
          this.saveDisabled = true;
          if (this.singleRuleForm.expense == "--") {
            this.saveDisabled = false;
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            this.saveDisabled = false;
            return this.$message.warning("流量费用不能大于账户余额！");
          }
          if (this.getSinglePrice2) {
            //正常课程除外
            this.singleRuleForm.usePrice =
              Number(this.getSinglePrice2) *
              Number(this.singleRuleForm.usedClassHour);
          } else {
            //正常课程
            this.singleRuleForm.usePrice =
              Number(this.getSinglePrice) *
              Number(this.singleRuleForm.usedClassHour);
          }
          this.singleRuleForm.studentId = this.idd;
          if (this.singleRuleForm.classHourType == 1) {
            //一对一费用判断
            //正常课时
            if (this.singleParams.type == "add") {
              if (this.singleRuleForm.usePrice > this.surplusNum) {
                this.msgWarn("该费用已不足！");
                return this.saveDisabled = false;;
              }
            } else if (this.singleParams.type == "edit") {
              if (this.singleRuleForm.usePrice > this.beforeDate) {
                this.msgWarn("该费用已不足！");
                return this.saveDisabled = false;;
              }
            }
          } else if (this.singleRuleForm.classHourType == 2) {
            //一对一赠送课时判断
            if (this.singleParams.type == "add") {
              if (this.singleRuleForm.usedClassHour > this.surplusNum) {
                this.msgWarn("该赠送课时已不足！");
                return this.saveDisabled = false;;
              } else if (this.singleParams.type == "edit") {
                if (this.singleRuleForm.usedClassHour > this.beforeDate) {
                  this.msgWarn("该赠送课时已不足！");
                  return this.saveDisabled = false;;
                }
              }
            }
          } else {
            //一对一充值判断费用和课时
            // if(this.singleRuleForm.classHourType==3&&this.accountBalance<=0){
            //   this.msgWarn("该费用已不足！");
            //   return false;
            // }
            // if(this.singleRuleForm.classHourType==5&&this.remainingClassHours<=0){
            //   this.msgWarn("该赠送课时已不足！");
            //   return false;
            // }
          }
          if (
            dayjs(this.singleRuleForm.beginTime)
              .add(1, "minute")
              .isBefore(dayjs()) &&
            this.singleRuleForm.classMode == "1"
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return this.saveDisabled = false;;
          }
          this.newSingleRuleForm = JSON.parse(
            JSON.stringify(this.singleRuleForm)
          );
          let now = new Date().valueOf();
          if (this.lastTime == 0 || now - this.lastTime > 2000) {
            //重置上一次点击时间，intervalTime是我自己设置的间隔时间，根据自己的需要传参
            this.lastTime = now;
            if (this.singleParams.type == "add") {
              this.singleRuleForm.id = null;
              checkTeacherClassTime(this.singleRuleForm).then((res) => {
                if (res.msg == "操作成功！") {
                  checkStudentClassTime(this.singleRuleForm).then((res) => {
                    if (res.msg == "操作成功！") {
                      checkTeacherOrganClassTime(this.singleRuleForm).then(
                        (res) => {
                          if (res.msg == "操作成功！") {
                            if (this.classHourStatus == 2) {
                              //一对一保存
                              xmtProductItemSave(this.singleRuleForm).then(
                                (res) => {
                                  if (
                                    res.code == 0 &&
                                    res.msg == "操作成功！"
                                  ) {
                                    this.msgSuccess("添加成功！");
                                    this.singlePopup = false;
                                    this.saveDisabled = false;
                                    this.findCourseItemFnn();
                                  } else {
                                    this.saveDisabled = false;
                                    this.msgWarn(res.rows);
                                    return this.saveDisabled = false;;
                                  }
                                }
                              );
                            } else {
                              //一对一充值保存
                              saveOneToOne(this.singleRuleForm).then((res) => {
                                if (res.code == 0 && res.msg == "操作成功！") {
                                  this.msgSuccess("添加成功！");
                                  this.singlePopup = false;
                                  this.saveDisabled = false;
                                  this.findCourseItemFnn();
                                } else {
                                  this.saveDisabled = false;
                                  this.msgWarn(res.rows);
                                  return this.saveDisabled = false;;
                                }
                              });
                            }
                          } else {
                            this.centerType = "teacher";
                            this.centerStudentName = res.rows.studentName;
                            this.centerTeacherName = res.rows.teacherRealName;
                            this.centerBeginTime = res.rows.beginTime;
                            this.centerTitle = res.rows.title;
                            this.centerUsedClassHour = res.rows.usedClassHour;
                            this.centerOrganName = res.rows.organName;
                            this.centerTrue = true;
                            this.centerDialogVisible = true;
                            this.saveDisabled = false;
                          }
                        }
                      );
                    } else {
                      //学生被占用
                      this.centerType = "student";
                      this.centerStudentName = res.rows.studentName;
                      this.centerTeacherName = res.rows.teacherRealName;
                      this.centerBeginTime = res.rows.beginTime;
                      this.centerTitle = res.rows.title;
                      this.centerUsedClassHour = res.rows.usedClassHour;
                      this.centerTrue = false;
                      this.centerDialogVisible = true;
                      this.saveDisabled = false;
                    }
                  });
                } else {
                  //老师被占用
                  this.centerType = "teacher";
                  this.centerStudentName = res.rows.studentName;
                  this.centerTeacherName = res.rows.teacherRealName;
                  this.centerBeginTime = res.rows.beginTime;
                  this.centerTitle = res.rows.title;
                  this.centerUsedClassHour = res.rows.usedClassHour;
                  this.centerTrue = false;
                  this.centerDialogVisible = true;
                  this.saveDisabled = false;
                }
              });
            } else if (this.singleParams.type == "edit") {
              if (this.singleRuleForm.classHourType == 7) {
                this.singleRuleForm.id = this.editId;
                this.singleRuleForm.supplementItemId = this.editId;
              } else {
                this.singleRuleForm.id = this.editId;
              }
              this.singleRuleForm.sectionId = this.getSectionId;
              if (
                this.editTime == this.singleRuleForm.beginTime &&
                this.editUsedClassHour == this.singleRuleForm.usedClassHour
              ) {
                //没修改时间  只删除保存
                //一对一充值编辑去掉delete
                if (this.classHourStatus == 2) {
                  //一对一
                  xmtProductItemDelete(this.editId).then((res) => {
                    delete this.singleRuleForm.id;
                    xmtProductItemSave(this.singleRuleForm).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("添加成功！");
                        this.singlePopup = false;
                        this.saveDisabled = false;
                        this.findCourseItemFnn();
                      } else {
                        this.saveDisabled = false;
                        this.msgWarn(res.rows);
                        return this.saveDisabled = false;;
                      }
                    });
                  });
                } else if (this.singleRuleForm.classHourType == 7) {
                  deleteMakeUpTimeForClass({ id: this.editId }).then((res) => {
                    delete this.singleRuleForm.id;
                    makeUpTimeForClass(this.singleRuleForm).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("添加成功！");
                        this.singlePopup = false;
                        this.saveDisabled = false;
                        this.findCourseItemFnn();
                      } else {
                        this.saveDisabled = false;
                        this.msgWarn(res.rows);
                        return false;
                      }
                    });
                  });
                } else {
                  xmtProductItemDelete(this.editId).then((res) => {
                    delete this.singleRuleForm.id;
                    saveOneToOne(this.singleRuleForm).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("添加成功！");
                        this.singlePopup = false;
                        this.saveDisabled = false;
                        this.findCourseItemFnn();
                      } else {
                        this.saveDisabled = false;
                        this.msgWarn(res.rows);
                        return false;
                      }
                    });
                  });
                }
              } else {
                checkTeacherClassTime(this.singleRuleForm).then((res) => {
                  if (res.msg == "操作成功！") {
                    checkStudentClassTime(this.singleRuleForm).then((res) => {
                      if (res.msg == "操作成功！") {
                        checkTeacherOrganClassTime(this.singleRuleForm).then(
                          (res) => {
                            if (res.msg == "操作成功！") {
                              if (this.singleRuleForm.classHourType == 7) {
                                deleteMakeUpTimeForClass({
                                  id: this.editId,
                                }).then((res) => {
                                  delete this.singleRuleForm.id;
                                  makeUpTimeForClass(this.singleRuleForm).then(
                                    (res) => {
                                      if (
                                        res.code == 0 &&
                                        res.msg == "操作成功！"
                                      ) {
                                        this.msgSuccess("添加成功！");
                                        this.singlePopup = false;
                                        this.saveDisabled = false;
                                        this.findCourseItemFnn();
                                      } else {
                                        this.saveDisabled = false;
                                        this.msgWarn(res.rows);
                                        return false;
                                      }
                                    }
                                  );
                                });
                              } else {
                                xmtProductItemDelete(this.editId).then(
                                  (res) => {
                                    delete this.singleRuleForm.id;
                                    if (this.classHourStatus == 2) {
                                      xmtProductItemSave(
                                        this.singleRuleForm
                                      ).then((res) => {
                                        if (
                                          res.code == 0 &&
                                          res.msg == "操作成功！"
                                        ) {
                                          this.msgSuccess("添加成功！");
                                          this.singlePopup = false;
                                          this.saveDisabled = false;
                                          this.findCourseItemFnn();
                                        } else {
                                          this.saveDisabled = false;
                                          this.msgWarn(res.rows);
                                          return false;
                                        }
                                      });
                                    } else {
                                      saveOneToOne(this.singleRuleForm).then(
                                        (res) => {
                                          if (
                                            res.code == 0 &&
                                            res.msg == "操作成功！"
                                          ) {
                                            this.msgSuccess("添加成功！");
                                            this.singlePopup = false;
                                            this.saveDisabled = false;
                                            this.findCourseItemFnn();
                                          } else {
                                            if (
                                              res.rows ==
                                                "当前学生剩余金额不足，请核实后重新排课" ||
                                              res.rows ==
                                                "该学生赠送课时不足，请核对后重新排课"
                                            ) {
                                              this.saveDisabled = false;
                                              this.singlePopup = false;
                                              this.msgWarn(res.rows);
                                              this.findCourseItemFnn();
                                              return false;
                                            } else {
                                              this.saveDisabled = false;
                                              this.msgWarn(res.rows);
                                              return false;
                                            }
                                          }
                                        }
                                      );
                                    }
                                  }
                                );
                              }
                            } else {
                              this.centerType = "teacher";
                              this.centerStudentName = res.rows.studentName;
                              this.centerTeacherName = res.rows.teacherRealName;
                              this.centerBeginTime = res.rows.beginTime;
                              this.centerTitle = res.rows.title;
                              this.centerUsedClassHour = res.rows.usedClassHour;
                              this.centerTrue = false;
                              this.centerDialogVisible = true;
                              this.saveDisabled = false;
                            }
                          }
                        );
                      } else {
                        //学生被占用
                        this.centerType = "student";
                        this.centerStudentName = res.rows.studentName;
                        this.centerTeacherName = res.rows.teacherRealName;
                        this.centerBeginTime = res.rows.beginTime;
                        this.centerTitle = res.rows.title;
                        this.centerUsedClassHour = res.rows.usedClassHour;
                        this.centerTrue = false;
                        this.centerDialogVisible = true;
                        this.saveDisabled = false;
                      }
                    });
                  } else {
                    //老师被占用
                    this.centerType = "teacher";
                    this.centerStudentName = res.rows.studentName;
                    this.centerTeacherName = res.rows.teacherRealName;
                    this.centerBeginTime = res.rows.beginTime;
                    this.centerTitle = res.rows.title;
                    this.centerUsedClassHour = res.rows.usedClassHour;
                    this.centerTrue = false;
                    this.centerDialogVisible = true;
                    this.saveDisabled = false;
                  }
                });
              }
            }
            //添加自己要调用的方法
          } else {
            console.log("不触发");
          }
        }
      });
    },
    surplusNumFn(val) {
      var obj = this.buyProductList;
      var hostValue = {};
      obj.forEach((item, index) => {
        const deviceId = item.id;
        if (!hostValue[deviceId]) {
          hostValue[deviceId] = {
            item,
          };
        }
      });
      this.outData = hostValue[val].item;
      this.singleRuleForm.productId = this.outData.id;
      if (this.outData.singlePrice) {
        //订单金额
        this.getSinglePrice = Number(this.outData.singlePrice);
      } else {
        this.getSinglePrice = Number(this.outData.zdySinglePrice);
      }
      getResiduePrice({
        orderId: this.outData.orderId,
        studentId: this.idd,
        classHourType: this.singleRuleForm.classHourType,
      }).then((res) => {
        if (res.code == 0) {
          this.surplusNum = res.rows;
          if (this.editClassHourType == 1) {
            //产品单价*原来的课时+现在的剩余金额
            this.beforeDate =
              Number(this.productSinglePrice) * Number(this.editUsedClassHour) +
              Number(res.rows);
          } else {
            //赠送课时   原来的扣得课时+现在剩的课时
            this.beforeDate = Number(this.editUsedClassHour) + Number(res.rows);
          }
          this.singleRuleForm.totalPrice = res.rows;
        }
      });
    },
    surplusNumFn2(val) {
      this.batchProductId = val.id; //产品Id
      if (val.zdySinglePrice != null) {
        //自定义
        this.getSinglePrice = val.zdySinglePrice;
      } else {
        this.getSinglePrice = val.singlePrice;
      }
      getResiduePrice({
        orderId: val.orderId,
        studentId: this.idd,
        classHourType: this.batchTimeForm.classHourType,
      }).then((res) => {
        if (res.code == 0) {
          this.surplusNum2 = res.rows;
          this.batchTotalPrice = res.rows;
        }
      });
    },
    batchPickerChange() {
      if (!this.batchPickerValue) {
        this.batchTimeForm.beginTime = null;
        this.batchTimeForm.stopTime = null;
        return;
      }
      this.batchTimeForm.beginTime = this.batchPickerValue[0];
      this.batchTimeForm.stopTime = this.batchPickerValue[1];
    },
    nameCheck(row) {},
    refereeTypeFn(type) {
      if (type == 3) {
        return "教务新增";
      } else if (type != 3) {
        return "主管分配";
      }
    },
    //生成课程
    createCourse() {
      if (this.batchPickerValue == "") {
        this.msgWarn("请选择排课日期");
        return;
      } else if (this.checkedWeeks.length == 0) {
        this.msgWarn("请选择星期");
        return;
      } else if (this.batchTimeForm.classHourType == "") {
        this.msgWarn("请选择收费类型");
        return;
      } else if (this.newProductId == "" && this.classHourStatusMore == 2) {
        this.msgWarn("请选择产品名称");
        return;
      }
      this.courseShow = true;
      this.courseDate = [];
      this.getWeek(
        this.batchTimeForm.beginTime,
        this.batchTimeForm.stopTime,
        this.checkedWeeks,
        1
      );
    },
    getWeek(begin, end, weekNum, num) {
      this.dateArr = new Array();
      var stimeArr = begin.split("-"); //=>["2018", "01", "01"]
      var etimeArr = end.split("-"); //=>["2018", "01", "30"]
      console.log(stimeArr, "stimeArr....");
      var stoday = new Date();
      stoday.setUTCFullYear(stimeArr[0], stimeArr[1] - 1, stimeArr[2]);
      var etoday = new Date();
      etoday.setUTCFullYear(etimeArr[0], etimeArr[1] - 1, etimeArr[2]);
      var unixDb = stoday.getTime(); //开始时间的毫秒数
      var unixDe = etoday.getTime(); //结束时间的毫秒数
      for (var k = unixDb; k <= unixDe; ) {
        let needJudgeDate = this.msToDate(parseInt(k)).withoutTime;
        //不加这个if判断直接push的话就是已知时间段内的所有日期
        for (var i = 0; i < weekNum.length; i++) {
          if (new Date(needJudgeDate).getDay() == weekNum[i]) {
            this.dateArr.push(needJudgeDate);
          }
        }
        k = k + 24 * 60 * 60 * 1000;
      }
      if (num == 1) {
        for (var j = 0; j < this.dateArr.length; j++) {
          this.courseDate.push({
            date: this.dateArr[j],
            productId: this.batchProductId,
            totalPrice: this.batchTotalPrice,
            singlePrice: this.getSinglePrice2,
            classHourType: this.batchClassHourType,
            subjectId: this.tableSubjectId,
            time: this.pickerData,
            usedClassHour: this.usedClassHour,
            title: "",
            teacherName: "",
            teacherId: "",
            configteacherPlanId: "",
            classAddress: "",
            isFirstClass: false,
            isPrisonClass: "0",
            isPlayback: "0",
            classMode: false,
          });
        }
      } else {
        const freeDate = [];
        this.dateArr.forEach((item) => {
          this.value1.forEach((child) => {
            freeDate.push({
              userType: "2",
              usedClassHour: "1",
              beginTime: item + " " + child + ":00:00",
              endTime: item + " " + Number(child + Number(1)) + ":00:00",
              teaStuId: this.getstudentid,
            });
          });
        });
        addFreetime(freeDate).then((res) => {
          if (res.code == 0) {
            this.msgSuccess(res.rows);
            this.listStudentCourseFn();
            this.freePopup = false;
          } else {
            this.msgWarn(res.rows);
            return false;
          }
        });
      }
      return this.dateArr;
    },
    msToDate(msec) {
      let datetime = new Date(msec);
      let year = datetime.getFullYear();
      let month = datetime.getMonth();
      let date = datetime.getDate();
      let hour = datetime.getHours();
      let minute = datetime.getMinutes();
      let second = datetime.getSeconds();

      let result1 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 10 ? "0" + date : date) +
        " " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute) +
        ":" +
        (second + 1 < 10 ? "0" + second : second);

      let result2 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 11 ? "0" + date : date);

      let result = {
        hasTime: result1,
        withoutTime: result2,
      };
      return result;
    },
    dealIsDay(row) {
      var day = new Date(row.date).getDay();
      if (day == 1) {
        return "星期一";
      } else if (day == 2) {
        return "星期二";
      } else if (day == 3) {
        return "星期三";
      } else if (day == 4) {
        return "星期四";
      } else if (day == 5) {
        return "星期五";
      } else if (day == 6) {
        return "星期六";
      } else if (day == 0) {
        return "星期日";
      }
    },
    sure() {
      this.batchLoading = true;
      for (var i = 0; i < this.courseDate.length; i++) {
        if (
          dayjs(this.courseDate[i].beginTime).add(1, "minute").isBefore(dayjs())
        ) {
          this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
          this.batchLoading = false;
          return;
        }
      }
      if (this.courseDate.length == 0) {
        this.msgWarn("课程不能为空！");
        this.batchLoading = false;
        return;
      }
      for (var i = 0; i < this.courseDate.length; i++) {
        if (
          this.courseDate[i].subjectId == "" ||
          this.courseDate[i].time == "" ||
          this.courseDate[i].usedClassHour == "" ||
          this.courseDate[i].title == "" ||
          this.courseDate[i].teacherName == ""
        ) {
          this.msgWarn("必填项不能为空！");
          this.batchLoading = false;
          return;
        }
      }
      let courseAll = 0;
      for (var k = 0; k < this.courseDate.length; k++) {
        if (this.getSinglePrice2) {
          //正常课程除外
          this.courseDate[k].usePrice =
            Number(this.getSinglePrice2) *
            Number(this.courseDate[k].usedClassHour);
        } else {
          this.courseDate[k].usePrice =
            Number(this.getSinglePrice) *
            Number(this.courseDate[k].usedClassHour);
        }
        var allUsePrice = 0;
        var allUsedClassHour = 0;
        if (this.batchTimeForm.classHourType == "1") {
          for (var i = 0; i < this.courseDate.length; i++) {
            allUsePrice += this.courseDate[i].usePrice;
          }
          if (allUsePrice > this.surplusNum2) {
            this.msgWarn("该费用已不足！");
            this.batchLoading = false;
            return false;
          }
        } else if (this.batchTimeForm.classHourType == "2") {
          for (var i = 0; i < this.courseDate.length; i++) {
            allUsedClassHour += this.courseDate[i].usedClassHour;
          }
          if (allUsedClassHour > this.surplusNum2) {
            this.msgWarn("该赠送课时已不足！");
            this.batchLoading = false;
            return false;
          }
        }
      }
          if (this.singleRuleForm.expense == "--") {
            this.batchLoading = false;
            return this.$message.warning("流量费用不正确！");
          }
          if (this.organTotalBalance < this.singleRuleForm.expense) {
            this.batchLoading = false;
            return this.$message.warning("流量费用不能大于账户余额！");
          }

      batchCheckTeacherClassTime(this.courseDate).then((res) => {
        if (res.msg == "操作成功！") {
          batchCheckStudentClassTime(this.courseDate).then((res) => {
            if (res.msg == "操作成功！") {
              batchCheckTeacherOrganClassTime(this.courseDate).then((res) => {
                if (res.msg == "操作成功！") {
                  for (var i = 0; i < this.courseDate.length; i++) {
                    this.courseDate[i].studentId = this.idd;
                    this.courseDate[i].sectionId = this.getSectionId;
                    this.courseDate[i].week = new Date(
                      this.courseDate[i].date
                    ).getDay();
                    if (this.courseDate[i].classMode == true) {
                      this.courseDate[i].classMode = "2";
                    } else {
                      this.courseDate[i].classMode = "1";
                    }
                    if (this.courseDate[i].isFirstClass == true) {
                      this.courseDate[i].isFirstClass = "1";
                    } else {
                      this.courseDate[i].isFirstClass = "0";
                    }
                  }
                  if (this.classHourStatusMore == 2) {
                    for (var i = 0; i < this.courseDate.length; i++) {
                      this.courseDate[i].classHourType = 1;
                    }
                    xmtProductItembatchSave(this.courseDate).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.batchTimePopup = false;
                        this.batchLoading = false;
                        this.findCourseItemFnn();
                      } else {
                        this.msgWarn(res.rows);
                        this.batchLoading = false;
                      }
                    });
                  } else {
                    batchSaveOneToOne(this.courseDate).then((res) => {
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.batchTimePopup = false;
                        this.batchLoading = false;
                        this.findCourseItemFnn();
                      } else {
                        this.msgWarn(res.rows);
                        this.batchLoading = false;
                      }
                    });
                  }
                } else {
                  //老师被占用
                  this.centerType = "teacher";
                  this.centerStudentName = res.rows.organStudentName;
                  this.centerTeacherName = res.rows.teacherName;
                  this.centerBeginTime = res.rows.beginTime;
                  this.centerTitle = res.rows.title;
                  this.centerUsedClassHour = res.rows.usedClassHour;
                  this.centerOrganName = res.rows.organName;
                  this.centerTrue = true;
                  this.centerDialogVisible = true;
                  this.batchLoading = false;
                }
              });
            } else {
              //学生被占用
              this.centerType = "student";
              this.centerStudentName = res.rows.studentName;
              this.centerTeacherName = res.rows.teacherName;
              this.centerBeginTime = res.rows.beginTime;
              this.centerTitle = res.rows.title;
              this.centerUsedClassHour = res.rows.usedClassHour;
              this.centerTrue = false;
              this.centerDialogVisible = true;
              this.batchLoading = false;
            }
          });
        } else {
          //老师被占用
          this.centerType = "teacher";
          this.centerStudentName = res.rows.studentName;
          this.centerTeacherName = res.rows.teacherName;
          this.centerBeginTime = res.rows.beginTime;
          this.centerTitle = res.rows.title;
          this.centerUsedClassHour = res.rows.usedClassHour;
          this.centerTrue = false;
          this.centerDialogVisible = true;
          this.batchLoading = false;
        }
      });
    },
    usedClassHourChange() {
      console.log(this.usedClassHour);
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].usedClassHour = this.usedClassHour;
      }
    },
    usedClassHourInput(e) {
      this.usedClassHour = e.data;
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].usedClassHour = this.usedClassHour;
      }
    },
    tableChange() {
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].subjectId = this.tableSubjectId;
      }
      for (var i = 0; i < this.singSubjectIdList.length; i++) {
        if (this.singSubjectIdList[i].macroId == this.tableSubjectId) {
          this.usedTitle = this.getSectionName + this.singSubjectIdList[i].name;
          for (var k = 0; k < this.courseDate.length; k++) {
            this.courseDate[k].title =
              this.getSectionName + this.singSubjectIdList[i].name;
          }
        }
      }
    },
    tableItemChange() {},
    scopeTeacher() {
      this.selectTeacherPopup = true;
      this.teacherType = 2;
      this.teacherParams.subjectId = this.tableSubjectId;
      this.configteacherPlanFn();
    },
    scopeAddress() {
      this.detailAddressPopup = true;
      this.addressType = 2;
      this.studentClassAddressList();
    },

    datetimeChange() {
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].time = this.pickerData;
        this.courseDate[i].beginTime =
          this.courseDate[i].date + " " + this.pickerData;
      }
      //this.$set(this.courseDate);
    },
    datetimeItemChange() {},
    deleteTableRow(row) {
      this.courseDate.splice(row.$index, 1);
    },
    queryCurse() {
      // this.type_of_cost=[];
      // this.selectAll=false;
      if (
        this.cancelCourseForm.subjectId == "" ||
        this.batchPickerValue2 == "" ||
        this.batchPickerValue3 == "" ||
        this.checkedCities.length == 0
      ) {
        this.msgWarn("请选择取消课程的信息！");
        return;
      }
      this.cancelCourseForm.week = this.checkedCities.toString();
      this.cancelCourseForm.studentId = this.idd;
      findCourseItem(this.cancelCourseForm).then((res) => {
        if (res.code == 0) {
          this.findCourseItemList = res.rows.listProductItemTeachManager;
          console.log(this.findCourseItemList, "findCourseItemList....");
        }
      });
    },
    batchPickerChange2() {
      this.cancelCourseForm.searchBeginDate = this.batchPickerValue2;
    },
    batchPickerChange3() {
      this.cancelCourseForm.searchEndDate = this.batchPickerValue3;
    },
    returnWeek(week) {
      console.log(week, "week....");
      var str = "";
      if (week == 1) {
        str = "星期一";
      } else if (week == 2) {
        str = "星期二";
      } else if (week == 3) {
        str = "星期三";
      } else if (week == 4) {
        str = "星期四";
      } else if (week == 5) {
        str = "星期五";
      } else if (week == 6) {
        str = "星期六";
      } else if (week == 0) {
        str = "星期日";
      }
      return str;
    },
    returnTime(items) {
      var endtime =
        Number(items.beginTime.split(" ")[1].split(":")[0]) +
        Number(items.usedClassHour);
      return (
        items.beginTime.split(" ")[1] +
        "-" +
        endtime +
        ":" +
        items.beginTime.split(" ")[1].split(":")[1]
      );
    },
    cancelCourseSave() {
      // console.log(this.checkList);
      //  return;
      batchCancel(this.checkList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.cancelCoursePopup = false;
          this.findCourseItemFnn();
        }
      });
    },

    checkListFn() {
      console.log(this.checkList);
    },

    getWeekFn() {
      this.weekList = [];
      for (let i = 0; i < 7; i++) {
        this.weekList.push({
          value: `${dayjs(this.curWeekFirstDay)
            .add(i, "day")
            .format("MM-DD")}(${this.weekItemArr[i]})`,
        });
      }
      console.log(this.weekLists, "weekLists...");
    },

    lastWeek() {
      this.dataArray2 = [];
      this.dataWeek = [];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    nextWeek() {
      this.dataArray2 = [];
      this.dataWeek = [];
      this.curWeekFirstDay = dayjs(this.curWeekFirstDay)
        .add(7, "day")
        .format("YYYY-MM-DD");
      this.getWeekFn();
      this.findCourseItemFnn();
    },
    getFreeWeekFn() {
      this.weekLists = [];
      for (let i = 0; i < 7; i++) {
        this.weekLists.push({
          value: `${dayjs(this.freeWeekFirstDay)
            .add(i, "day")
            .format("MM-DD")}(${this.weekItemArr[i]})`,
        });
      }
    },
    lastFreeWeek() {
      this.freeWeekFirstDay = dayjs(this.freeWeekFirstDay)
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.getFreeWeekFn();
      this.listStudentCourseFn();
    },
    nextFreeWeek() {
      this.freeWeekFirstDay = dayjs(this.freeWeekFirstDay)
        .add(7, "day")
        .format("YYYY-MM-DD");
      this.getFreeWeekFn();
      this.listStudentCourseFn();
    },
    studentProvinceChange() {},
    studentCityChange() {},
    initClassTime() {
      this.formalClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ];
      this.firstClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false,
          },
          {
            name: "上午",
            key: "b",
            status: false,
          },
          {
            name: "上午",
            key: "c",
            status: false,
          },
          {
            name: "上午",
            key: "d",
            status: false,
          },
          {
            name: "上午",
            key: "e",
            status: false,
          },
          {
            name: "上午",
            key: "f",
            status: false,
          },
          {
            name: "上午",
            key: "g",
            status: false,
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
          },
          {
            name: "下午",
            key: "i",
            status: false,
          },
          {
            name: "下午",
            key: "j",
            status: false,
          },
          {
            name: "下午",
            key: "k",
            status: false,
          },
          {
            name: "下午",
            key: "l",
            status: false,
          },
          {
            name: "下午",
            key: "m",
            status: false,
          },
          {
            name: "下午",
            key: "n",
            status: false,
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
          },
          {
            name: "晚上",
            key: "p",
            status: false,
          },
          {
            name: "晚上",
            key: "q",
            status: false,
          },
          {
            name: "晚上",
            key: "r",
            status: false,
          },
          {
            name: "晚上",
            key: "s",
            status: false,
          },
          {
            name: "晚上",
            key: "t",
            status: false,
          },
          {
            name: "晚上",
            key: "u",
            status: false,
          },
        ],
      ];
      this.normalClassHoursList = [
        [
          {
            name: "上午",
            key: "a",
            status: false,
            week: "周一",
          },
          {
            name: "上午",
            key: "b",
            status: false,
            week: "周二",
          },
          {
            name: "上午",
            key: "c",
            status: false,
            week: "周三",
          },
          {
            name: "上午",
            key: "d",
            status: false,
            week: "周四",
          },
          {
            name: "上午",
            key: "e",
            status: false,
            week: "周五",
          },
          {
            name: "上午",
            key: "f",
            status: false,
            week: "周六",
          },
          {
            name: "上午",
            key: "g",
            status: false,
            week: "周日",
          },
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false,
            week: "周一",
          },
          {
            name: "下午",
            key: "i",
            status: false,
            week: "周二",
          },
          {
            name: "下午",
            key: "j",
            status: false,
            week: "周三",
          },
          {
            name: "下午",
            key: "k",
            status: false,
            week: "周四",
          },
          {
            name: "下午",
            key: "l",
            status: false,
            week: "周五",
          },
          {
            name: "下午",
            key: "m",
            status: false,
            week: "周六",
          },
          {
            name: "下午",
            key: "n",
            status: false,
            week: "周日",
          },
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false,
            week: "周一",
          },
          {
            name: "晚上",
            key: "p",
            status: false,
            week: "周二",
          },
          {
            name: "晚上",
            key: "q",
            status: false,
            week: "周三",
          },
          {
            name: "晚上",
            key: "r",
            status: false,
            week: "周四",
          },
          {
            name: "晚上",
            key: "s",
            status: false,
            week: "周五",
          },
          {
            name: "晚上",
            key: "t",
            status: false,
            week: "周六",
          },
          {
            name: "晚上",
            key: "u",
            status: false,
            week: "周日",
          },
        ],
      ];
    },

    //查看
    async daalEdit() {
      this.saveDialogVisible = true;
      await this.getProvinceList();
      await studentInfo(this.getstudentid).then((res) => {
        if (res.code == 0) {
          this.studentRuleForm = {
            name: res.rows.name,
            contact: res.rows.contact,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            schoolName: res.rows.schoolName,
            sourceType: String(res.rows.sourceType),
            areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            referralphone: res.rows.referralphone,
            referralstudent: res.rows.referralstudent,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            qitaxueke: res.rows.qitaxueke,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            xmtStudentSubjectRelationEntityList: [],
            id: res.rows.id,
            schoolReach: res.rows.schoolReach,
            provinceName: res.rows.provinceName,
            cityName: res.rows.cityName,
            countyName: res.rows.countyName,
            no: res.rows.no,
            sectionName: res.rows.sectionName,
          };
          this.initClassTime();
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList[i][j].key
                )
              ) {
                this.firstClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList[i][j].key
                )
              ) {
                this.formalClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
            this.subjectList_info[i].status = false;
            this.subjectList_info[i].select = false;
            this.subjectList_info[i].subjectScore = "";
            this.subjectList_info[i].subjectAllScore = "";
            this.subjectList_info[i].sexNeedId = "";
            this.subjectList_info[i].styleNeedId = "";
            this.subjectList_info[i].speedNeedId = "";
            this.subjectList_info[i].ageNeedId = "";
            this.subjectList_info[i].content = "";
            if (
              (this.subjectList_info[i].name == "其他学科" &&
                res.rows.qitaxueke == 1) ||
              (this.subjectList_info[i].name == "语文" &&
                res.rows.yuwen == 1) ||
              (this.subjectList_info[i].name == "数学" &&
                res.rows.shuxue == 1) ||
              (this.subjectList_info[i].name == "英语" &&
                res.rows.yingyu == 1) ||
              (this.subjectList_info[i].name == "历史" &&
                res.rows.lishi == 1) ||
              (this.subjectList_info[i].name == "政治" &&
                res.rows.zhengzhi == 1) ||
              (this.subjectList_info[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList_info[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList_info[i].name == "化学" &&
                res.rows.huaxue == 1) ||
              (this.subjectList_info[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList_info[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList_info.length; i < len; i++) {
            for (
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList_info[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList_info[i].xmtStudentAcademicRecordList =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].xmtStudentAcademicRecordList;
                this.subjectList_info[i].status = true;
                this.subjectList_info[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList_info[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList_info[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList_info[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList_info[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList_info[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList_info[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
              }
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.time_table td {
  border-right-style: none !important;
}
#monitor td {
  border-bottom-style: none !important;
}
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .weekDiv {
    margin-top: 20px;
    p {
      font-size: 22px;
      cursor: pointer;
    }
    p:nth-child(2) {
      font-size: 22px;
      padding: 0 10px;
    }
  }
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title2 {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-align: left;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title span:nth-child(1) {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_subject_title span:nth-child(2) {
        font-weight: 500;
        font-size: 16px;
        margin-left: 20px;
        color: #000;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .student_p {
    font-size: 15px;
  }
  .student_p span {
    font-size: 15px;
    margin-right: 5px;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
  .scopeDiv3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .scopeDiv3 .scopeDiv3_btn {
    display: block;
  }
  .scopeDiv {
    margin-left: 20px;
  }
  .content_div1 div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
  }
  .content_div1 h2 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 900;
  }
  .content_div2 h2 {
    margin-top: 20px;
  }
  .content_div2 ul {
    display: flex;
    margin-top: 20px;
  }
  .content_div2 ul li {
    margin-right: 50px;
    font-size: 16px;
  }
  .content_div2 ul li span:nth-child(2) {
    color: red;
    margin-left: 15px;
  }
  .content_div3 {
    margin-top: 20px;
  }
  .content_div4 {
    margin-top: 40px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
  .subjectActive {
    overflow: hidden;
  }
  .student_dl {
    display: flex;
  }
  .student_div h2 {
    margin-top: 30px;
  }
  .student_div span {
    margin-right: 40px;
    margin-top: 20px;
    display: inline-block;
  }
  .info_div1,
  .info_div2 {
    margin-top: 15px;
  }
  .info_div1 > p,
  .info_div2 > p {
    font-size: 14px;
    font-weight: bold;
  }
  .info_div1 ul,
  .info_div2 ul {
    margin-top: 20px;
    display: flex;
  }
  .info_div1 ul li {
    width: 25%;
    p:nth-child(1) {
      color: #909399;
      font-size: 12px;
    }
    p:nth-child(2) {
      color: #000;
      font-size: 14px;
      margin-top: 15px;
    }
  }
  .info_div2 ul li {
    p:nth-child(1) {
      color: #000000;
      font-size: 14px;
    }
  }
  .item_score {
    display: flex;
    /deep/ .el-input {
      width: 40%;
    }
    > span {
      width: 20px;
      text-align: center;
    }
  }
  .curseUl {
    display: flex;
    flex-wrap: wrap;
  }
  .curseUl li {
    width: 50%;
    margin-top: 10px;
  }
  .checkGroup {
    display: inline-block;
  }
  .course_td {
    width: 80px;
    height: 39px;
    line-height: 20px;
    position: relative;
    vertical-align: middle;

    .course_can_use {
      font-size: 25px;
      color: #1890ff;
      margin-top: -8px;
    }
    .free_course_can_use {
      color: #666666;
      margin-top: -8px;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .course_td > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .course_td p {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .td_active {
    position: absolute;
    // top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.6) !important;
  }
  .studentInfo {
    h2 {
      font-size: 16px;
    }
    .studentUl {
      display: flex;
      margin-top: 15px;
      li {
        flex: 1;
      }
    }
    .orderDiv {
      margin-top: 25px;

      ul {
        margin-top: 15px;
        li {
          padding: 5px 0;
        }
      }
    }
  }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .box-card {
    width: 500px;
    margin-top: 20px;
  }
  .radioLesson {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lessonDiv {
    p {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      span {
        flex: 1;
      }
    }
  }
  .cardLesson {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .row_detail {
    padding: 6px 0;
  }
  .row_details {
    padding: 12px 0;
  }
  table,
  table tr td {
    border: 1px solid #dcdfe6;
  }
  table {
    width: 100%;
    min-height: 35px;
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 40px;
  }
}
>>> .td-img .el-icon-circle-close {
  color: white !important;
}
.publishActive {
  visibility: hidden;
}
.studentTitle {
  font-size: 18px;
  font-weight: 700;
  padding: 15px 0;
}
.studentSmallTitle {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.classInfo {
  > p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    p {
      flex: 1;
      padding-left: 20px;
      display: flex;
      text-align: center;
      span {
        flex: 1;
        text-align: center;
      }
    }
  }
  > div:nth-child(3) {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    ul {
      flex: 1;
      padding-left: 20px;
      li {
        display: flex;
        .remarksSpan {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .studentSmallTitle {
    width: 12%;
    text-align: right;
  }
}
.active {
  color: #1890ff;
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
/deep/ .el-checkbox.is-disabled.is-checked .el-checkbox__label {
  color: #1890ff;
}
.classInfo {
  > p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    ul {
      flex: 1;
      padding-left: 20px;
      li {
        display: flex;
        .remarksSpan {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .studentSmallTitle {
    width: 12%;
    text-align: right;
  }
}
.teacherInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  dl {
    display: flex;
    align-items: center;
    dd {
      margin-left: 30px;
      p:nth-child(1) {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
.title_p {
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  margin-top: 55px;
}
.teacher_ul li {
  width: 100%;
  display: flex;
}
.teacher_ul span {
  font-size: 14px;
  margin-top: 20px;
  display: inline-block;
  color: #303133;
  font-weight: 400;
  flex: 1;
}
.answer_ul li {
  margin-top: 30px;
}
.answer_ul p {
  margin-top: 15px;
  font-size: 14px;
  color: #303133;
  font-weight: 400;
}
.teacherFace_ul {
  margin-top: 20px;
}
.teacherFace_ul li {
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  color: #303133;
  font-weight: 400;
}
.teacherFace_ul li p {
  flex: 1;
}
.teacherFace_ul li span {
  margin-left: 5px;
}
.classInfoUl {
  padding-bottom: 40px;
  li {
    margin-top: 25px;
    p:nth-child(1) {
      font-weight: 600;
    }
    p:nth-child(2) {
      margin-top: 10px;
      span {
        margin-right: 15px;
      }
    }
  }
}
.evaluateUl {
  display: flex;
  margin-top: 30px;
  li {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    text-align: center;
    color: orange;
    border: 1px solid orange;
    margin-right: 20px;
    cursor: pointer;
  }
}
.evaluateActive {
  background: orange;
  color: #fff !important;
}
.paperUl {
  padding: 0 20px;
  flex: 1;
}
.paperUl li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}
.detail_type {
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  color: #000;
}
.row_detail {
  padding: 6px 0;
}
.row_detail2 {
  span {
    font-weight: bold;
    color: #ff3f3f;
  }
}
.div >>> p {
  font-size: 16px !important;
}
.imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 40%;
    display: block;
  }
}
.div > * {
  color: #000;
  font-size: 14px;
}
.infoDetails {
  height: 100px;
  padding-top: 8px;
  dl {
    display: flex;
  }
}
.dp_title {
  height: 50px;
  line-height: 50px;
  color: #000;
  input[type="checkbox"] {
    zoom: 1.3;
  }
}
.dp_item {
  margin-left: 10px;
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    zoom: 1.3;
  }
}
.relatedInstance ul {
  padding: 0;
}

.relatedInstance > ul > li {
  margin-bottom: 20px;
}

.relatedInstance > ul > li > h3 {
  background: #f3f2f0;
  color: #6bacdf;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
}
.relatedInstance > ul > li > h5 {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  display: none;
  cursor: pointer;
}

.relatedInstance > ul > li > ul > li {
  padding-left: 28px;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  min-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  margin: 5px 0;
}
.relatedInstance > ul > li > ul > li {
  word-wrap: break-word;
}

.relatedInstance li h3 {
  cursor: pointer;
}
.relatedInstance li h3 span {
  float: right;
  line-height: 26px;
  margin-right: 5px;
}
.relatedInstance li li:hover {
  color: #333;
}

.relatedInstance li ul {
  overflow: hidden;
}
.relatedInstance li h3 {
  position: relative;
}

.relatedInstance li li {
  position: relative;
}
.relatedInstance li h3 b,
.relatedInstance li li b {
  position: absolute;
  top: 1px;
  left: 5px;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  background: url("../../../assets/image/blue.png");
  border: none;
  cursor: pointer;
  background-position: 0 0;
  margin-right: 5px;
}
.relatedInstance li h3 b {
  position: absolute;
  top: 4px;
}
.relatedInstance li h3 b:hover,
.relatedInstance li li b:hover {
  background-position: -20px 0;
}
.relatedInstance li h3 b.checked,
.relatedInstance li li b.checked {
  background-position: -40px 0;
}
.already_create_group {
  color: #ffa900 !important;
}
.img_list {
  width: 100%;
  min-height: 120px;
  overflow: auto;
  .img_item {
    position: relative;
    margin: 10px 10px 10px 0px;
    float: left;
    cursor: pointer;
    height: 100px;
    width: 100px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .img_hover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 6;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #000000, $alpha: 0.7);
      .icon_del {
        margin-left: 10px;
        cursor: pointer;
        color: #ffffff;
      }
      .icon_preview {
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
      }
    }
    &:hover {
      .img_hover {
        display: flex;
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    .icon_new {
      font-size: 40px;
    }
  }
}
.teachContent {
  display: flex;
  flex-direction: column;
}
.infoDetails {
  height: 150px;
  padding-top: 20px;
  display: flex;
}
.infoDetails .left {
  display: flex;
  width: 35%;
  min-width: 500px;
}
.infoDetails .content {
  width: 350px;
}
.infoDetails .content ul {
  margin-top: 30px;
}
.infoDetails .content ul li {
  margin-top: 5px;
}
.infoDetails .content ul span:nth-child(1) {
  font-size: 15px;
  color: #414a60;
}
.infoDetails .content ul span:nth-child(2) {
  font-size: 15px;
  color: #61687c;
}
.infoDetails .right {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.subjectDiv {
  width: 50px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  margin-right: 10px;
}
.infoDiv span {
  padding: 3px 10px;
  border-radius: 3px;
  text-align: center;
  margin-right: 20px;
  color: #1890ff;
  background: #e8f4ff;
  border: 1px solid #1890ff;
}
.reportActive {
  background: #1890ff !important;
  color: #fff !important;
}
.table_info {
  float: right;
  span {
    margin-right: 15px;
  }
}
.countMap_p {
  float: right;
  span {
    padding: 0 8px;
  }
}
.census_div {
  margin-top: 40px;
  > p:nth-child(1) {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}
.student_info {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  dl {
    display: flex;
    align-items: center;
    dd {
      margin-left: 15px;
      p:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
      }
      p:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
#posterHtml {
  position: relative;
}
.qrcode {
  width: 236px;
  height: 236px;
  margin-bottom: 130px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  margin-left: -118px;
}
.levelBtn {
  display: inline-block;
  margin-right: 10px;
  background: #efefef;
  color: #333;
  border: 1px solid #efefef;
}
.levelActive {
  color: #fff;
  background: #3ec279;
  border: 1px solid #3ec279;
}
.infoTitle {
  display: flex;
  p {
    flex: 1;
    span:nth-child(1) {
      color: #909399;
      font-size: 12px;
    }
    span:nth-child(2) {
      color: #000;
      font-size: 14px;
    }
  }
}
.bq {
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 12px;
  color: #ee783d;
  border: 1px solid #ee7031;
  background: #fff4ee;
  margin-left: 6px;
  img {
    vertical-align: middle;
    display: inline-block;
    margin-top: -5px;
  }
  span {
    display: inline-block;
  }
}
.bqlx {
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 12px;
  color: #b2b8c0;
  border: 1px solid #d9dde3;
  background: #f5f7fa;
  margin-left: 6px;
  img {
    vertical-align: middle;
    display: inline-block;
    margin-top: -5px;
  }
  span {
    display: inline-block;
  }
}
/deep/ .add_class .el-dialog__body {
  padding-bottom: 10px;
}
.price_tip {
  margin-top: 10px;
  display: flex;
  line-height: 24px;
  padding-left: 24px;
  .account_fee {
    margin-left: 20px;
  }
}
</style>
