<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
        <div class="select-condition-item">
            <el-menu
            :default-active="activeName"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleClick"
            >
            <el-menu-item index="0">新订单</el-menu-item>
            <el-menu-item index="1">退费订单</el-menu-item>
            </el-menu>
        </div>
    </div>
    <div class="btn-area" v-show="activeName == 0">
        <div class="select-condition-item">
                <el-switch
                  v-model="switchValue"
                  @change="switchChange()"
                  active-color="#1890ff"
                  inactive-color="#1890ff"
                  active-text="订单记录"
                  inactive-text="充值记录">
                </el-switch>
            </div>
    </div>
    <div class="btn-area" v-show="activeName=='0'&&switchValue==true">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品类型：</span>
          <el-select
            v-model="listParams.orderType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">订单状态：</span>
          <el-select
            v-model="listParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- <div class="select-condition-item">
          <span class="item-lable">课程类型：</span>
          <el-select
            v-model="listParams.courseTypeId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in courseTypeIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div> -->
        <div class="select-condition-item">
          <span class="item-lable">下单时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,getOrderList())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area" v-show="activeName=='0'&&switchValue==true">
      <el-table
        size="small"
        :data="orderList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        height="100%"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterProductType"
          label="产品类型"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          :formatter="formatterCourseType"
          label="课程类型"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="classHour"
          label="总课时"
           :formatter="formatterclassHour"
          align="center"
        >
        </el-table-column>
        <el-table-column
         prop="totalPrice"
          label="总价"
           :formatter="formattertotalPrice"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="orderStatus"
          label="订单状态"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
              prop=""
              label="课程状态"
              align="center"
            >
              <template slot-scope="scope">
                  <span v-if="scope.row.abnormalStatus==0">正常</span>
                  <span v-else style="color:red;">异常</span>
              </template>
            </el-table-column> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus==2"
              @click="dealReason(scope.row)"
              >拒绝原因</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >详情</el-button
            >
            <el-button type="text" v-show="scope.row.orderStatus==1" size="small" @click="dealRemove(scope.row)"
              >删除</el-button
            >
            <el-button type="text" size="small" @click="dealEdit(scope.row)"
              v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus!=1&&scope.row.orderStatus!=2"
              @click.stop="seeClassRecord(scope.row)"
              >上课记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="activeName=='0'&&switchValue==true">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <div class="btn-area" v-show="activeName=='0'&&switchValue==false">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="rechargeListParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="rechargeListParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">审核状态：</span>
          <el-select
            v-model="rechargeListParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">充值时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(rechargeListParams.pageNumber=1,rechargeOrderList())"
          >搜索</el-button
        >
      </div>
    </div>
    <p style="padding:8px 0;" v-show="activeName=='0'&&switchValue==false"><span>总计缴费金额：{{totalAmount}}</span><span style="margin-left:15px;">剩余金额：{{remainingAmount}}</span></p>
    <div class="table-area" v-show="activeName=='0'&&switchValue==false">
      <el-table
        size="small"
        :data="rechargeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        height="100%"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="totalPrice"
          label="缴费金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="zdyGiveClassHour"
          label="赠送课时"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="充值时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="orderStatus"
          label="审核状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus==2"
              @click="dealReason(scope.row)"
              >拒绝原因</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >详情</el-button
            >
            <el-button type="text" v-show="scope.row.orderStatus==1" size="small" @click="dealRemove(scope.row)"
              >删除</el-button
            >
            <el-button type="text" size="small" @click="dealEdit(scope.row)"
              v-show="scope.row.orderStatus==1||scope.row.orderStatus==2"
              >修改</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              v-show="scope.row.orderStatus!=1&&scope.row.orderStatus!=2"
              @click.stop="seeClassRecord(scope.row)"
              >上课记录</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="activeName=='0'&&switchValue==false">
      <el-pagination
        @size-change="rechargeSizeChange"
        @current-change="rechargeCurrentChange"
        :current-page="rechargeListParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="rechargeListParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="rechargeTotal"
        background
      ></el-pagination>
    </div>
    <div class="btn-area" v-show="activeName=='1'">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="refundParams.name"
            placeholder="学生姓名"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">审核状态：</span>
          <el-select
            v-model="refundParams.teacherApproval"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in teacherApprovalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">提交时间：</span>
          <el-date-picker
            size="small"
            v-model="refundPickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="refundPickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(refundParams.pageNumber=1,studentRefundListFn())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area" v-show="activeName=='1'">
      <el-table
        size="small"
        :data="refundList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        height="100%"
        border
        tooltip-effect="dark"
      >
      <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="提交时间"
          align="center"
        ></el-table-column>
        <el-table-column
        :formatter="teacherApprovalFor"
          prop="productName"
          label="审核状态"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="productName"
          :formatter="educationalApprovalFormatter"
          label="退款状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.teacherApproval==2"
              @click="refuseReason(scope.row)"
              >拒绝原因</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="seeDetail(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.teacherApproval!=1"
              @click="editDetail(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
     <div class="page-area" v-show="activeName=='1'">
      <el-pagination
        @size-change="refundSizeChange"
        @current-change="refundleCurrentChange"
        :current-page="refundParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="refundParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="refundTotal"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="orderDialog.title"
      :visible.sync="orderDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleFormOrder"
          status-icon
          :rules="saveRuleOrder"
          ref="saveRuleFormOrder"
          label-width="100px"
          size="small"
        >
          <el-form-item label="产品类型" prop="orderType">
            <el-row>
              <el-col :span="7">
                 <el-select
                  v-model="saveRuleFormOrder.orderType"
                  placeholder="请选择"
                  @change="orderClick"
                  size="small"
                  :disabled="
                      orderDialog.type == 'see' || orderDialog.type == 'edit'
                    "
                >
                  <el-option value="5" label="一对一充值"></el-option>
                  <el-option value="2" label="一对多"></el-option>
                  <el-option value="3" label="试听课"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="课程类型"
            prop="courseTypeId"
            v-show="saveRuleFormOrder.orderType == 2"
          >
            <el-radio-group
              v-model="saveRuleFormOrder.courseTypeId"
              :disabled="orderDialog.type == 'see'"
              @change="orderClick"
            >
              <el-radio label="1">大班课</el-radio>
              <el-radio label="2">小班课</el-radio>
              <el-radio label="3">答疑课</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="选择产品"
            prop="productId"
            v-show="saveRuleFormOrder.orderType!=5"
            :disabled="orderDialog.type == 'see'"
          >
            <el-row>
            <el-col :span="7">
              <el-select
              v-model="saveRuleFormOrder.productId"
              placeholder="请选择"
              @change="productChange"
              :disabled="orderDialog.type == 'see'"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              ></el-option>
            </el-select>
            </el-col>
          </el-row>
          </el-form-item>
          <el-form-item
            label="产品规格"
            v-show="saveRuleFormOrder.orderType!=5"
          >
            <el-row type="flex" v-show="saveRuleFormOrder.orderType !=2">
              <el-col :span="5">
                <el-select
                  v-model="saveRuleFormOrder.sectionId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.sectionList"
                    :key="item.sectionId"
                    :label="item.sectionName"
                    :value="item.sectionId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="5" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.orderType == 2">
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.periodId"
                  placeholder="请选择学段"
                  @change="orderSectionChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.periodList"
                    :key="item.periodId"
                    :label="item.periodName"
                    :value="item.periodId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.subjectId"
                  placeholder="请选择学科"
                  @change="orderSubjectChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.subjectList"
                    :key="item.subjectId"
                    :label="item.subjectName"
                    :value="item.subjectId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.orderType == 2"
            >
              <el-col :span="12">
                <el-select
                  v-model="saveRuleFormOrder.teacherTitleId"
                  placeholder="请选择教师头衔"
                  @change="orderTeacherTitleChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.teacherTitleList"
                    :key="item.teacherTitleId"
                    :label="item.teacherTitleName"
                    :value="item.teacherTitleId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-select
                  v-model="saveRuleFormOrder.classHourId"
                  placeholder="请选择课时"
                  @change="orderClassHourChange"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-option
                    v-for="item in productDetail.classHourList"
                    :key="item.id"
                    :label="item.classHourName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT10"
              v-show="saveRuleFormOrder.classHourId != -1"
            >
              <el-col :span="12">
                <div class="course_info">
                  <span>课时单价：</span>
                  <span>{{ saveRuleFormOrder.singlePrice }}元</span>
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <div class="course_info">
                  <span>总价：</span>
                  <span>{{ saveRuleFormOrder.orderType!=2?saveRuleFormOrder.totalPrice:saveRuleFormOrder.zdyActualPrice }}元</span>
                </div>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="mT20"
              v-show="saveRuleFormOrder.classHourId == -1"
            >
              <el-col :span="8">
                <el-form-item label="课时数：" prop="zdyClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdyClassHour"
                    class="self_input"
                    @keyup="filterZdyClassHour"
                    onpaste="return false"
                    @blur="zdyClassHourBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价：" prop="zdySinglePrice">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    v-model="saveRuleFormOrder.zdySinglePrice"
                    class="self_input"
                    onpaste="return false"
                    @keyup="fileterZdySinglePrice"
                    @blur="zdySinglePriceBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="总金额：" prop="zdyTotalPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyTotalPrice"
                    onpaste="return false"
                    @keyup="filterZdyTotalPrice"
                    @blur="zdyTotalPriceBlur"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-show="saveRuleFormOrder.classHourId == -1">
              <el-col :span="8">
                <el-form-item label="赠送课时：" prop="zdyGiveClassHour">
                  <input
                    type="text"
                    :disabled="orderDialog.type == 'see'"
                    onpaste="return false"
                    @keyup="
                      saveRuleFormOrder.zdyGiveClassHour =
                        saveRuleFormOrder.zdyGiveClassHour.replace(/[^\d]/g, '')
                    "
                    v-model="saveRuleFormOrder.zdyGiveClassHour"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="优惠金额：" prop="zdyDiscountAmount">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    v-model="saveRuleFormOrder.zdyDiscountAmount"
                    class="self_input"
                    onpaste="return false"
                    @keyup="filterZdyDiscountAmount"
                    @blur="zdyDiscountAmountBlur()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际支付：" prop="zdyActualPrice">
                  <input
                    :disabled="orderDialog.type == 'see'"
                    type="text"
                    onpaste="return false"
                    @keyup="filterZdyActualPrice"
                    @blur="zdyActualPriceBlur"
                    v-model="saveRuleFormOrder.zdyActualPrice"
                    class="self_input"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="缴费金额" prop="totalPrice" v-show="saveRuleFormOrder.orderType==5">
            <el-input style="width:140px;" maxlength="7" v-model="saveRuleFormOrder.totalPrice" @blur="totalPriceBlur()" placeholder="请输入缴费金额"></el-input>
            <span style="color:#909399;font-size:12px;margin-left:10px;">{{translateTotal}}</span>
          </el-form-item>
          <el-form-item label="赠送课时" prop="zdyGiveClassHour" v-show="saveRuleFormOrder.orderType==5&&saveRuleFormOrder.moneyType!=4">
            <el-input style="width:140px;" type="number" min="0" v-model="saveRuleFormOrder.zdyGiveClassHour" placeholder="请输入赠送课时"></el-input>
          </el-form-item>
          <el-form-item label="优惠金额" prop="payType" v-show="saveRuleFormOrder.orderType==2">
            <el-input style="width:140px;" @blur="morenAmount()" v-model="saveRuleFormOrder.zdyDiscountAmount"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-radio-group
              v-model="saveRuleFormOrder.payType"
              :disabled="orderDialog.type == 'see'"
            >
              <!-- <el-radio label="1">线上支付</el-radio> -->
              <el-radio label="2">线下支付</el-radio>
              <el-radio label="3">余额支付</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="收款方式"
            prop="payMent"
            v-show="
              (saveRuleFormOrder.orderType == 5 ||
                saveRuleFormOrder.orderType == 2||saveRuleFormOrder.orderType == 3) &&
                saveRuleFormOrder.payType == 2
            "
          >
            <el-radio-group
              v-model="saveRuleFormOrder.payMent"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">支付宝</el-radio>
              <el-radio label="2">微信</el-radio>
              <el-radio label="3">对公账户</el-radio>
              <el-radio label="4">POS机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="收款类型" prop="moneyType" v-show="saveRuleFormOrder.orderType!=3">
            <el-radio-group
              v-model="saveRuleFormOrder.moneyType"
              :disabled="orderDialog.type == 'see'"
            >
              <el-radio label="1">全款</el-radio>
              <el-radio label="2">定金</el-radio>
              <el-radio label="3">尾款</el-radio>
              <el-radio label="4">特殊订单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="付款凭证"
            prop="certificate"
            v-show="saveRuleFormOrder.payType == 2"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="certificate_box">
                  <el-image 
                    class="td-img"
                    style="width: 100px; height: 100px"
                    :src="saveRuleFormOrder.certificate" 
                    v-if="saveRuleFormOrder.certificate"
                    :preview-src-list="srcList2"
                  >
                  </el-image>
                </div>
                <div class="certificate_tip">
                  <span v-show="saveRuleFormOrder.certificate"
                    >点击图片查看大图</span
                  >
                  <span v-show="!saveRuleFormOrder.certificate"
                    >暂未上传凭证</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="certificateSuccess"
                  :on-error="certificateError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="辅导协议"
            prop="coachAgreement"
          >
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <div class="coachAgreement_tip">
                  <span v-show="saveRuleFormOrder.coachAgreement">{{
                    saveRuleFormOrder.coachAgreementName
                  }}</span>
                  <span v-show="!saveRuleFormOrder.coachAgreement"
                    >还未上传辅导协议</span
                  >
                </div>
              </el-col>
              <el-col :span="12" class="mL20">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  :action="fileUpload"
                  :on-success="coachAgreementSuccess"
                  :on-error="coachAgreementError"
                  :show-file-list="false"
                  :disabled="orderDialog.type == 'see'"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="orderDialog.type == 'see'"
                    >点击上传</el-button
                  >
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="saveRuleFormOrder.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDialog.type != 'see'"
      >
        <el-button @click="orderDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="isDisable"
          @click="confirmSaveOrder('saveRuleFormOrder')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="查看财务拒绝原因"
      :visible.sync="reasonDialogVisible"
      width="30%"
      center>
      <span>{{reasonTitle}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reasonDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog
          title="退费详情"
          :visible.sync="refundSync"
          width="800px"
          :close-on-click-modal="false"
          center
          custom-class="product-dialog"
        >
          <div class="dialog-content studentInfo">
            <h2>学生概况</h2>
            <ul>
              <li>学生姓名：{{studentDetailList.name}}</li>
              <li>联系方式：{{studentDetailList.no}}</li>
              <li>年级：{{studentDetailList.sectionName}}</li>
            </ul>
            
            <ul v-for="(item, indexs) in orderdDetail" :key="indexs">
               <el-divider></el-divider>
              <li>产品名称：{{item.productName}}</li>
              <li>订单编号：{{item.orderNo}}</li>
              <li>总课时：{{item.classHour?item.classHour:item.zdyClassHour}}课时</li>
              <li>课时单价：{{item.singlePrice}}元</li>
              <li>总费用：{{item.totalPrice}}元</li>
              <li>实收费用：{{item.totalPrice}}元</li>
              <!-- <h2>课消记录</h2> -->
              <div v-for="(items, indexss) in item.xmtStudentRefundInfoChildList" :key="indexss" class="infoRecord">
                <span>课程级别：{{courseLevelFn(items.courseLevel)}}</span>
                <span>课时单价：{{items.classSinglePrice}}元</span>
                <span>已上课时数：{{items.countUsedClassHour}}</span>
                <span>课耗金额：{{classMoney(items.countUsedClassHour,items.classSinglePrice)}}元</span>
              </div>
             
            </ul>
             <h2>退款金额</h2>
             <ul>
                <li>应退款金额：{{getRefundAmount}}元</li>
                <li>实退款金额：{{getRealityRefundAmount}}元</li>
                <li>备注：{{getBackReason}}</li>
                <li>申请时间：{{getCreateTime}}</li>
                <li>申请人：{{getCreateName}}</li>
                <li>打款时间：{{getEducationalApprovalTime}}</li>
                <li>实际消耗金额：{{getRealityUsePrice}}</li>
              </ul>
              <h2>退费账户</h2>
             <ul>
                <li>收款姓名：{{getBankUserName}}元</li>
                <li>收款账号：{{getBankAccount}}元</li>
                <li>开户支行：{{getBankAddress}}</li>
                <li>联系方式：{{getBankUserPhone}}</li>
              </ul>
          </div>
      </el-dialog>
      <el-dialog
      title="退费详情"
      :visible.sync="refundInfoSync"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content studentInfo">
          <!-- <h2>学员信息</h2>
          <ul class="studentUl">
            <li>学生姓名：{{studentDetailList.name}}</li>
            <li>性别：{{studentSex(studentDetailList.sex)}}</li>
            <li>年级：{{studentDetailList.sectionName}}</li>
            <li>出生日期：{{studentDetailList.birthday}}</li>
            <li>星座：{{studentDetailList.constellationName}}</li>
          </ul> -->
          <div class="orderDiv">
            <ul v-for="(item, indexs) in orderInfoDetail" :key="indexs">
              <li style="font-weight:700;font-size:16px;">订单信息{{indexs+1}}</li>
              <li>产品名称：{{item.productName}}</li>
              <li>总课时：{{item.classHour?item.classHour:item.zdyClassHour}}课时</li>
              <li>总金额：{{item.totalPrice?item.totalPrice:item.zdyActualPrice}}元</li>
              <li>下单时间：{{item.createTime}}</li>
              <li>订单编号：{{item.orderNo}}</li>
              <ul v-for="(items, indexss) in item.xmtStudentRefundInfoChildList" :key="indexss">
                <li>课程级别：{{courseLevelFn(items.courseLevel)}}</li>
                <li>已上课时数：{{items.countUsedClassHour}}</li>
                <li>课时单价：{{items.classSinglePrice}}元</li>
                <li>课消金额：{{classMoney(items.countUsedClassHour,items.classSinglePrice)}}元</li>
              </ul>
            </ul>
            <p style="margin-left:150px;">总计交费金额：{{orderInfoMoney}}元</p>
             <el-form
              :model="saveOrderForm"
              status-icon
              :rules="saveOrderRules"
              ref="saveOrderForm"
              label-width="100px"
              size="small"
              style="margin-top:40px;"
            > 
              <el-form-item label="实际消耗金额" prop="realityUsePrice">
              <el-radio-group v-model="saveOrderForm.realityUsePrice">
                <el-input v-model="saveOrderForm.realityUsePrice" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="应退款金额" prop="refundAmount">
              <el-radio-group v-model="saveOrderForm.refundAmount">
                <el-input v-model="saveOrderForm.refundAmount" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="实退款金额" prop="realityRefundAmount">
              <el-radio-group v-model="saveOrderForm.realityRefundAmount">
                <el-input v-model="saveOrderForm.realityRefundAmount" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="备注" prop="backReason">
              <el-radio-group v-model="saveOrderForm.backReason">
                <el-input type="textarea" v-model="saveOrderForm.backReason" placeholder="例如：手续费、设备押金、课时费不正常等"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="收款姓名" prop="bankUserName">
              <el-radio-group v-model="saveOrderForm.bankUserName">
                <el-input v-model="saveOrderForm.bankUserName" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="收款账号" prop="bankAccount">
              <el-radio-group v-model="saveOrderForm.bankAccount">
                <el-input v-model="saveOrderForm.bankAccount" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开户支行" prop="bankAddress">
              <el-radio-group v-model="saveOrderForm.bankAddress">
                <el-input v-model="saveOrderForm.bankAddress" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="联系方式" prop="bankUserPhone">
              <el-radio-group v-model="saveOrderForm.bankUserPhone">
                <el-input v-model="saveOrderForm.bankUserPhone" placeholder="请输入内容"></el-input>
              </el-radio-group>
            </el-form-item>
            </el-form>
          </div>
      </div>
       <span slot="footer" class="dialog-footer">
         <el-button
          type="primary"
          size="small"
           @click="submitExamine('saveOrderForm')"
          >确 定</el-button
        >
        <el-button size="small" @click="refundInfoSync=false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="refuseReasonShow"
      width="30%"
      >
      <span>{{refuseInfo}}</span>
    </el-dialog>
     <el-dialog
      title="上课记录"
      :visible.sync="seeClassRecordPopup"
      :close-on-click-modal="false"
      width="900px"
      center
    >
      <div class="dialog-content">
        <el-table
        size="small"
        :data="recordList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学科"
          :formatter="dealSubjectId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="课程标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teacherName"
          label="讲师"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课程级别"
          align="center"
          :formatter="formatterPlanCourseLevel"
        ></el-table-column>
        <el-table-column
          prop="learnFee"
          label="课时单价"
          align="center"
           :formatter="formatterLearnFee"
        >
        
        </el-table-column>
        <el-table-column
         prop="planTeachFee"
          label="讲师课酬"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="上课状态"
          :formatter="attendClassStatusList"
          align="center"
        ></el-table-column>
        <el-table-column
              prop=""
              label="课程状态"
              align="center"
            >
              <template slot-scope="scope">
                  <span v-if="scope.row.abnormalStatus==0">正常</span>
                  <span v-else style="color:red;">异常</span>
              </template>
            </el-table-column>
      </el-table>
      <p class="table_info">
        <span>已排{{countMap.yipaike}}课时</span>
        <span>已上{{countMap.yishangke}}课时</span>
        <span>上课中{{countMap.shangkezhong}}课时</span>
        <span>未上{{countMap.weishangke}}课时</span>
      </p>
    </div>
    
    <div class="page-area">
      <el-pagination
        @size-change="recordhandleSizeChange"
        @current-change="recordhandleCurrentChange"
        :current-page="recordParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="recordParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="recordTotal"
        background
      ></el-pagination>
    </div>
    </el-dialog>
    <refundDialog ref="refundDialog" :visibleNum="visibleNum" :visibleId="visibleId" :page="visiblePage" @close="Visible=false" :visibleStudentId="visibleStudentId" v-if="Visible" :visible="Visible"></refundDialog>
    <downOrder ref="downOrder" :downOrderStatus="downOrderStatus" :downParams="downParams" @CB_downOrderStatus="CB_downOrderStatus"></downOrder>
    <orderInfo ref="orderInfo" :orderInfoStatus="orderInfoStatus"  :orderInfoParams="orderInfoParams" @CB_orderInfoShow="CB_orderInfoShow"></orderInfo>
  </div>
</template>
<script>
import dayjs from "dayjs";
import orderInfo from '../../finance/dialog/orderInfo.vue';
import { teacherManagerOrder } from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { orderAgree } from "@/api/finance/finance.js";
import downOrder from '../../teachBusiness/expertTeachBusiness/dialog/studentManageFile/downOrder.vue'
import {
  followupSave,
  followUpInfo,
  followUpUpdate,
  followUpListPage,
  followUpList,
  prevAndNext,
  addOrder,
  orderDetail,
  orderList,
  orderRemove,
  checkOneToOneOrder,
  logicalDeletion,
  studentClassRecord
} from "@/api/sales/myCustomer";
import { studentInfo } from "@/api/sales/saleManage.js";
// import {
//   studentRefundList,
//   studentRefundInfo,
//   studentRefundUpdate,
//   studentRefundSave,
//   selectStudentState,
//   refundOrderInfos
// } from "@/api/administration/tubeTeachBusiness/studentManage.js";
import {
  productListNoPage,
  productInfoDetail,
} from "@/api/operates/productManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import {
  studentRefundInfo,
  refundOrderInfos,
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import refundDialog from '../../finance/dialog/refund-dialog.vue'
import { studentRefundList,studentRefundUpdate } from '@/api/finance/finance.js'
export default {
 
  data() {
    let check_zdyClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时数不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdySinglePrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("自定义课时单价不能为空！"));
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("自定义课时单价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyGiveClassHour = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(2).test(value)) {
          callback(new Error("赠送课时数应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyDiscountAmount = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback();
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("优惠金额应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_zdyActualPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.classHourId == -1) {
        if (!value) {
          callback(new Error("课时总价不能为空！"));
        } else if (!reg_Num(4).test(value)) {
          callback(new Error("课时总价应为非零正整数！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_certificate = (rule, value, callback) => {
      if (this.saveRuleFormOrder.payType == 2) {
        if (!value) {
          callback(new Error("线下支付时付款凭证不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_productId = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType != 5) {
        if (!value) {
          callback(new Error("产品不能为空！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let check_totalPrice = (rule, value, callback) => {
      if (this.saveRuleFormOrder.orderType == 5&&this.saveRuleFormOrder.moneyType!=4) {
        var reg = /^\d+$|^\d+[.]?\d+$/;
        if (!value) {
          callback(new Error("缴费金额不能为空！"));
        }else if(!reg.test(value)){
          callback(new Error("请输入正确格式金额！"));
        }else {
          callback();
        }
      }else if(this.saveRuleFormOrder.orderType == 5&&this.saveRuleFormOrder.moneyType==4){
          var regF=/^(^-?\d+$)$/
          if (!value) {
            callback(new Error("缴费金额不能为空！"));
          }else if(!regF.test(value)){
            callback(new Error("请输入正确格式金额！"));
          }else {
            callback();
          }
        }
       else {
        callback();
      }
    };
    return {
      orderInfoStatus:false,
      orderInfoParams:{
        id:"",
        studentId:""
      },
      downOrderStatus:false,
      downParams:{
        id:""
      },
      visiblePage:'',
      visibleStudentId:"",
      Visible:false,
      visibleNum:'',
      visibleId:"",
      studentInfo:{},
      xmtOrderList:[],
      classUsedOverview:[],
      classUsedDetailed:[],
      getApprovalList:[],
      rechargeForm:{},
      xmtOrderMoney:0,
      classUsedViewMoney:0,
      classUsedDetailedMoney:0,
      getApprovalMoney:0,

      dialogStatus:false,
      switchValue:true,
      refundInfoSync:false,
      studentDetailList:{},
      orderInfoDetail:[],
      orderInfoMoney:"",
      editId:"",
      editStudentId:"",
      saveOrderForm:{ 
        teacherApproval:0,
        realityUsePrice:"",
        refundAmount:"",
        realityRefundAmount:"",
        backReason:"",
        bankUserName:"",
        bankAccount:"",
        bankAddress:"",
        bankUserPhone:"",


      },
      remainingAmount:0,
      totalAmount:0,
      saveOrderRules:{},
      editOrderId:"",
      refundSync:false,
      orderdDetail:[],
      getRefundAmount:"",
      getRealityRefundAmount:"",
      getBackReason:"",
      getCreateTime:"",
      getCreateName:"",
      getEducationalApprovalTime:"",
      getRealityUsePrice:"",
      getBankUserName:"",
      getBankAccount:"",
      getBankAddress:"",
      getBankUserPhone:"",
      activeName:'0',
      refundTotal:0,
      refundPickerValue:"",
      refundList:[],
      reasonDialogVisible:false,
      reasonTitle:"",
      fileUpload,
      subjectList: [],
      sectionList: [
       
      ],
      orderList: [], 
      seeClassRecordPopup:false,
      recordParams:{
        pageNumber: 1,
        pageSize: 10,
        studentId:'',
        orderId:""
      },
      recordList:[],
      countMap:{},
      recordTotal:0,
      refundParams:{
        name:"",
        teacherApproval:"",
        searchBeginTime:null,
        searchEndTime:null,
        pageNumber:1,
        pageSize:10,
        dataAuthority:'user',
        ifTeacher:'N'
      },
      teacherApprovalList:[
        {value:'',label:"全部"},
        {value:'0',label:"待审核"},
        {value:1,label:"已通过"},
        {value:2,label:"已拒绝"}
      ],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        dataAuthority: "user",
        name: "",
        orderType: '',
        orderStatus:'',
        searchBeginDate: null,
        searchEndDate: null
      },
      rechargeListParams: {
        pageNumber: 1,
        pageSize: 10,
        dataAuthority: "user",
        name: "",
        orderType: 5,
        sectionId:'',
        orderStatus:'',
        searchBeginDate: null,
        searchEndDate: null
      },
      total: 0,
      rechargeTotal:0,
      rechargeList:[],
      timePickerValue: "",
      editOrderId:"",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      productTypeList: [
        {
          value: '',
          label: "全部"
        },
        {
          value: 1,
          label: "一对一"
        },
        {
          value: 2,
          label: "一对多"
        },
        {
          value: 3,
          label: "试听课"
        }
      ],
      orderStatusList: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "待财务审核"
        },
        {
          value: 2,
          label: "财务审核拒绝"
        },
        {
          value: 3,
          label: "教务主管分配中"
        },
        {
          value: 4,
          label: "待排课"
        },
        {
          value: 5,
          label: "排课中"
        },
        {
          value: 6,
          label: "已退款"
        },
        {
          value: 7,
          label: "订单已完成"
        },
        {
          value: 8,
          label: "订单作废"
        },
        {
          value: 9,
          label: "退款中"
        }
      ],
      orderStatusList2: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "待财务审核"
        },
        {
          value: 2,
          label: "财务审核拒绝"
        },
        {
          value: 4,
          label: "待排课"
        },
        {
          value: 5,
          label: "排课中"
        },
        {
          value: 6,
          label: "已退款"
        },
        {
          value: 7,
          label: "订单已完成"
        },
        {
          value: 8,
          label: "订单作废"
        },
        {
          value: 9,
          label: "退款中"
        }
      ],
      courseTypeIdList: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "大班课"
        },
        {
          value: 2,
          label: "小班课"
        },
        {
          value: 3,
          label: "答疑课"
        }
      ],
      isDisable:false,
      orderDetail: {},
      studentDetail: {},
      detailDialogVisible: false,
      orderDialogVisible: false,
      orderDialog: {
        title: "修改下单",
        type: "edit"
      },
      saveRuleFormOrder: {
        orderType: "1", //1一对一  2一对多  3试听课
        payType: "2", //1线上支付   2线下支付
        courseTypeId: "1", //1大班课  2小班课  3答疑课
        productId: "",
        certificate: "", //付款凭证
        coachAgreement: "", //辅导协议
        sectionId: "", //学段
        subjectId: "",
        teacherTitleId: "",
        classHour: "",
        classHourId: "", //不用传到后台
        singlePrice: "",
        totalPrice: "",
        zdyClassHour: "",
        zdySinglePrice: "",
        zdyGiveClassHour: 0,
        zdyDiscountAmount: "",
        zdyActualPrice: "",
        studentId: this.id,
        productPackageId: "",
        remark: "",
        payMent: "1", //1支付宝  2微信  3对公账户  4pos机
        moneyType: "1" //1全款   2定金  3尾款
      },
      saveRuleOrder: {
        zdyClassHour: [{ validator: check_zdyClassHour, trigger: "blur" }],
        zdySinglePrice: [{ validator: check_zdySinglePrice, trigger: "blur" }],
        zdyGiveClassHour: [
          { validator: check_zdyGiveClassHour, trigger: "blur" }
        ],
        zdyDiscountAmount: [
          { validator: check_zdyDiscountAmount, trigger: "blur" }
        ],
        zdyActualPrice: [{ validator: check_zdyActualPrice, trigger: "blur" }],
        certificate: [{ validator: check_certificate, trigger: "change" }],
        productId: [{ validator: check_productId, trigger: "change" }],
        totalPrice: [{ validator: check_totalPrice, trigger: "blur" }]
      },
      productList: [],
      productDetail: {
        productPackage: [],
        sectionList: [],
        sectionIdList: [],
        subjectList: [],
        subjectIdList: [],
        teacherTitleList: [],
        teacherTitleIdList: [],
        classHourList: [],
        classHourIdList: [],
      },
      id:"",
      srcList:[],
      srcList2:[],
      refuseReasonShow:false,
      refuseInfo:"",
      translateTotal:""
    };
  },
  inject: ["reload"],
  components:{
    refundDialog,
    downOrder,
    orderInfo
  },
  methods: {
    CB_orderInfoShow(){
      this.orderInfoStatus=false;
    },
     switchChange(){
       if(this.switchValue==true){
          this.getOrderList();
       }else{
         this.rechargeOrderList();
       }
      },
    getSectionList() {
        getSelectList({
          parentCode: sectionCode,
        }).then((res) => {
          if (res.code == 0) {
            this.sectionList = res.rows;
            this.sectionList.unshift({
              macroId: '',
              name: "全部",
            });
          }
        });
    },
    seeDetail(row){
        if(row.type==null){
          this.refundSync=true;
          studentInfo(row.studentId).then(res => {
              this.studentDetailList = res.rows;
            });
          studentRefundInfo(row.orderIds).then(res => {
              this.orderdDetail=res.rows.xmtStudentRefundInfoEntity;
              this.getRefundAmount=res.rows.refundAmount;
              this.getRealityRefundAmount=res.rows.realityRefundAmount;
              this.getBackReason=res.rows.backReason;
              this.getCreateName=res.rows.createName;
              this.getCreateTime=res.rows.createTime;
              this.getEducationalApprovalTime=res.rows.educationalApprovalTime;
              this.getRealityUsePrice=res.rows.realityUsePrice;
              this.getBankUserName=res.rows.bankUserName;
              this.getBankAccount=res.rows.bankAccount;
              this.getBankAddress=res.rows.bankAddress;
              this.getBankUserPhone=res.rows.bankUserPhone;
          })
        }else{
          this.Visible=true;
          this.visibleId=row.id;
          this.visibleNum=2;
          this.visiblePage=""
        }
    },
    morenAmount(){
      this.saveRuleFormOrder.zdyActualPrice=Number(this.saveRuleFormOrder.zdyTotalPrice)-Number(this.saveRuleFormOrder.zdyDiscountAmount);
    },
    classMoney(countUsedClassHour,classSinglePrice){
      return Math.ceil(Number(countUsedClassHour))*Number(classSinglePrice)
    },
    refuseReason(row){
        this.refuseReasonShow=true;
        this.refuseInfo=row.teacherApprovalReason;
    },
    editDetail(row){
      if(row.type==null){
        this.refundInfoSync=true;
      this.saveOrderForm.id=row.id;
      this.saveOrderForm.studentId=row.studentId;
      this.saveOrderForm.orderIds=row.orderIds;
      studentRefundInfo(row.orderIds).then(res => {
          this.orderInfoDetail=res.rows.xmtStudentRefundInfoEntity;
          this.orderInfoMoney=0;
          this.saveOrderForm.realityUsePrice="";
          var sum=0;
          this.saveOrderForm.realityUsePrice=res.rows.realityUsePrice;
          this.saveOrderForm.refundAmount=res.rows.refundAmount;
          this.saveOrderForm.realityRefundAmount=res.rows.realityRefundAmount;
          this.saveOrderForm.backReason=res.rows.backReason;
          this.saveOrderForm.bankUserName=res.rows.bankUserName;
          this.saveOrderForm.bankAccount=res.rows.bankAccount;
          this.saveOrderForm.bankAddress=res.rows.bankAddress;
          this.saveOrderForm.bankUserPhone=res.rows.bankUserPhone;
          for(let i=0;i<res.rows.xmtStudentRefundInfoEntity.length;i++){
            if(res.rows.xmtStudentRefundInfoEntity[i].totalPrice){
              this.orderInfoMoney+=Math.ceil(Number(res.rows.xmtStudentRefundInfoEntity[i].totalPrice));
            }else{
              this.orderInfoMoney+=Math.ceil(Number(res.rows.xmtStudentRefundInfoEntity[i].zdyActualPrice));
            }
          }
      })
      }else{
        this.Visible=true;
        this.visibleStudentId=row.studentId;
        this.visibleId=row.id;
        this.visibleNum='';
        this.visiblePage=3;
      }
    },
    dealSubjectId(row) {
      var subjectlist = "";
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    totalPriceBlur(){
      var totalPriceNum=this.saveRuleFormOrder.totalPrice;
      this.numberToString(totalPriceNum)
    },
    attendClassStatusList(row){
      if(row.attendClassStatus==0){
        return '未上课'
      }else if(row.attendClassStatus==1){
        return '已上课'
      }else if(row.attendClassStatus==2){
        return '上课中'
      }
    },
     formatterLearnFee(row){
      return Number(row.usePrice) /Number(row.usedClassHour);
    },
     formatterPlanCourseLevel(row){
      var jibie = "";
      if (row.planCourseLevel == 1) {
        jibie = "初级课程";
      } else if (row.planCourseLevel == 2) {
        jibie = "中级课程";
      } else if (row.planCourseLevel == 3) {
        jibie = "高级课程";
      } else if (row.planCourseLevel == 4) {
        jibie = "特级课程";
      } else {
        jibie = "正常课程";
      }
      return jibie;
    },
    seeClassRecord(row){
      this.recordParams.studentId=row.studentId;
      this.recordParams.orderId=row.id;
      this.seeClassRecordPopup=true;
      this.studentClassRecordFn();
    },
     studentClassRecordFn(){
      studentClassRecord(this.recordParams).then((res) => {
          this.recordList=res.rows;
          this.countMap=res.countMap;
          this.recordTotal=res.total;
      })
    },
     recordhandleSizeChange(pageSize){
      this.recordParams.pageSize = pageSize;
      this.studentClassRecordFn();
    },
    recordhandleCurrentChange(currentPage){
      this.recordParams.pageNumber = currentPage;
      this.studentClassRecordFn();
    },
    dealjibie(row) {
            var jibie = "";
            if (row.courseLevel == 1) {
                jibie = "初级课程";
            } else if (row.courseLevel == 2) {
                jibie = "中级课程";
            } else if (row.courseLevel == 3) {
                jibie = "高级课程";
            } else if (row.courseLevel == 4) {
                jibie = "特级课程";
            } else {
                jibie = "正常课程";
            }
            return jibie;
        },
    formatterMoneyType(row){
            switch (row.moneyType) {
                case 1:
                return "全款";
                case 2:
                return "定金";
                case 3:
                return "尾款";
                case 4:
                return "特殊订单";
                case 5:
                return "余额转充值";
                default:
                return "/";
            } 
        },
    submitExamine(formName){
      if(this.saveOrderForm.refundAmount<0||this.saveOrderForm.realityRefundAmount<0){
        this.msgWarn(
            "订单消耗已超出实际支付金额，不支持退款！"
          );
        return false;
      }
      this.$refs[formName].validate(valid => {
            if (valid) {

                studentRefundUpdate(this.saveOrderForm).then(res => {
                    if(res.code==0){
                      this.msgSuccess("操作成功！");
                      this.refundInfoSync = false;
                    }
                })
            }
         })
    },
    filterZdyTotalPrice() {
      this.saveRuleFormOrder.zdyTotalPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyTotalPrice
      );
    },
    formatterclassHour(item){
      return item.classHour?item.classHour:item.zdyClassHour;
    },
    formattertotalPrice(item){
      return item.totalPrice?item.totalPrice:item.zdyActualPrice;
    },
    courseLevelFn(item){
      if(item==1){
        return '初级课程'
      }else if(item==2){
        return '中级课程'
      }else if(item==3){
        return '高级课程'
      }else if(item==4){
        return '特级课程'
      }else {
        return '正常课程'
      }
    },
     filterZdyActualPrice() {
      this.saveRuleFormOrder.zdyActualPrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyActualPrice
      );
    },
    filterZdyClassHour() {
      // 课时大于等于1的正整数
      this.saveRuleFormOrder.zdyClassHour =
        this.saveRuleFormOrder.zdyClassHour.replace(/[^\d]/g, "");
      if (this.saveRuleFormOrder.zdyClassHour.length == 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/[^1-9]/gi, "");
      } else if (this.saveRuleFormOrder.zdyClassHour.length > 1) {
        this.saveRuleFormOrder.zdyClassHour =
          this.saveRuleFormOrder.zdyClassHour.replace(/^0/, "");
      }
    },
    dealRemove(row){
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logicalDeletion({id:row.id}).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getOrderList();
              this.rechargeOrderList();
            }
          });
        })
        .catch(() => {});
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    fileterZdySinglePrice() {
      this.saveRuleFormOrder.zdySinglePrice = this.dealNumTofix2(
        this.saveRuleFormOrder.zdySinglePrice
      );
    },
    dealNumTofix2(str) {
      if (str != "" && str.substr(0, 1) == ".") {
        str = "";
      }
      str = str.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
      str = str.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (str.indexOf(".") < 0 && str != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (str.substr(0, 1) == "0" && str.length == 2) {
          str = str.substr(1, str.length);
        }
      }
      return str;
    },
     filterZdyDiscountAmount() {
      this.saveRuleFormOrder.zdyDiscountAmount = this.dealNumTofix2(
        this.saveRuleFormOrder.zdyDiscountAmount
      );
    },
    zdyClassHourBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyClassHour) {
        // 处理单价和总价
        if (zdySinglePrice) {
          zdyTotalPrice = zdyClassHour * zdySinglePrice;
        } else if (zdyTotalPrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdySinglePriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdySinglePrice) {
        if (zdySinglePrice === "0") {
          this.msgWarn(
            "单价不合法，必须是必须是大于0的最多保留两位小数的数字！"
          );
          zdySinglePrice = "";
        }
        // 处理课时和总价
        if (zdyClassHour) {
          zdyTotalPrice = zdySinglePrice * zdyClassHour;
        } else if (zdyTotalPrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
        zdyTotalPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyTotalPriceBlur() {
      let {
        zdyClassHour,
        zdySinglePrice,
        zdyDiscountAmount,
        zdyTotalPrice,
        zdyActualPrice,
      } = this.saveRuleFormOrder;
      if (zdyTotalPrice) {
        // 处理课时和单价
        if (zdyClassHour && zdySinglePrice) {
          if (zdyClassHour * zdySinglePrice !== zdyTotalPrice) {
            zdyClassHour = "";
            zdySinglePrice = "";
          }
        } else if (zdyClassHour && !zdySinglePrice) {
          zdySinglePrice =
            Math.round((zdyTotalPrice / zdyClassHour) * 100) / 100;
        } else if (!zdyClassHour && zdySinglePrice) {
          let remainder = zdyTotalPrice % zdySinglePrice;
          zdyClassHour =
            Math.round((zdyTotalPrice / zdySinglePrice) * 100) / 100;
          if (remainder) {
            this.msgWarn(
              `计算出的课时数为${zdyClassHour},不合法，请重新输入！`
            );
            zdyClassHour = "";
          }
        }
        // 处理优惠价
        if (zdyDiscountAmount && zdyTotalPrice) {
          if (zdyDiscountAmount > zdyTotalPrice) {
            zdyDiscountAmount = "";
          }
        }
        // 处理实际价
        if (zdyTotalPrice) {
          zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
        }
      } else {
        zdyActualPrice = "";
      }
      this.saveRuleFormOrder.zdyClassHour = zdyClassHour;
      this.saveRuleFormOrder.zdySinglePrice = zdySinglePrice;
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    zdyDiscountAmountBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyDiscountAmount && zdyTotalPrice) {
        if (zdyDiscountAmount > zdyTotalPrice) {
          this.msgWarn("优惠价格不能大于总价！");
          zdyDiscountAmount = "";
        }
      }
      zdyActualPrice = zdyTotalPrice - Number(zdyDiscountAmount);
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    CB_downOrderStatus(){
      this.downOrderStatus=false;
      this.getOrderList();
      this.rechargeOrderList();
    },
    zdyActualPriceBlur() {
      let { zdyDiscountAmount, zdyTotalPrice, zdyActualPrice } =
        this.saveRuleFormOrder;
      if (zdyActualPrice && zdyTotalPrice) {
        if (zdyActualPrice > zdyTotalPrice) {
          this.msgWarn("实际支付不能大于总价格，请重新输入！");
          zdyActualPrice = zdyTotalPrice;
        }
        zdyDiscountAmount = zdyTotalPrice - zdyActualPrice;
      } else if (zdyActualPrice && zdyDiscountAmount) {
        if (zdyActualPrice < zdyDiscountAmount) {
          zdyDiscountAmount = "";
        }
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      } else {
        zdyTotalPrice = zdyActualPrice - zdyDiscountAmount;
      }
      this.saveRuleFormOrder.zdyDiscountAmount = zdyDiscountAmount;
      this.saveRuleFormOrder.zdyTotalPrice = zdyTotalPrice;
      this.saveRuleFormOrder.zdyActualPrice = zdyActualPrice;
    },
    handleClick(tab){
      console.log(tab)
      this.activeName=tab;
      if(tab==0){
        this.getOrderList();
      }else if(tab==1){
        this.studentRefundListFn();
      }
    },
    educationalApprovalFormatter(row){
      switch (row.educationalApproval) {
        case '0':
          return "未打款";
        case '1':
          return "已打款";
      }
    },
    studentRefundListFn(){
        studentRefundList(this.refundParams).then(res => {
            this.refundList=res.rows;
            this.refundTotal=res.total;
        })
    },
    refundSizeChange(pageSize){
      this.refundParams.pageSize = pageSize;
      this.studentRefundListFn();
    },
    refundleCurrentChange(currentPage){
      this.refundParams.pageNumber = currentPage;
      this.studentRefundListFn();
    },
     teacherApprovalFor(row){
             switch (row.teacherApproval) {
                case '1':
                  return "已通过";
                case '2':
                  return "已拒绝";
                case '0':
                  return "待审核";
              }
          },
    refundPickerChange(){
      if (!this.refundPickerValue) {
          this.refundParams.searchBeginTime = null;
          this.refundParams.searchEndTime = null;
          return;
        }
        this.refundParams.searchBeginTime = this.refundPickerValue[0];
        this.refundParams.searchEndTime = this.refundPickerValue[1];
    },
     confirmSaveOrder(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = {};
          this.isDisable = true;
          if (this.saveRuleFormOrder.orderType !=2) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              studentId: this.id,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
            };
            if (this.saveRuleFormOrder.classHourId == -1) {
              obj.zdyClassHour = this.saveRuleFormOrder.zdyClassHour;
              obj.zdySinglePrice = this.saveRuleFormOrder.zdySinglePrice;
              obj.zdyGiveClassHour = this.saveRuleFormOrder.zdyGiveClassHour;
              obj.zdyDiscountAmount = this.saveRuleFormOrder.zdyDiscountAmount;
              obj.zdyActualPrice = this.saveRuleFormOrder.zdyActualPrice;
              obj.zdyTotalPrice = this.saveRuleFormOrder.zdyTotalPrice;
              obj.classHour = "";
              obj.singlePrice = "";
              obj.totalPrice = "";
            } else {
              obj.zdyClassHour = "";
              obj.zdySinglePrice = "";
              obj.zdyGiveClassHour = 0;
              obj.zdyDiscountAmount = "";
              obj.zdyActualPrice = "";
              obj.zdyTotalPrice = "";
              obj.productPackageId = this.saveRuleFormOrder.productPackageId;
              obj.classHour = this.saveRuleFormOrder.classHour;
              obj.singlePrice = this.saveRuleFormOrder.singlePrice;
              obj.totalPrice = this.saveRuleFormOrder.totalPrice;
            }
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else if (this.saveRuleFormOrder.orderType == 2) {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              courseTypeId: this.saveRuleFormOrder.courseTypeId,
              payType: this.saveRuleFormOrder.payType,
              productId: this.saveRuleFormOrder.productId,
              sectionId: this.saveRuleFormOrder.sectionId,
              studentId: this.id,
              teacherTitleId: this.saveRuleFormOrder.teacherTitleId,
              subjectId: this.saveRuleFormOrder.subjectId,
              coachAgreement: this.saveRuleFormOrder.coachAgreement,
              classHour: this.saveRuleFormOrder.classHour,
              singlePrice: this.saveRuleFormOrder.singlePrice,
              totalPrice: this.saveRuleFormOrder.totalPrice,
              productPackageId: this.saveRuleFormOrder.productPackageId,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              zdyActualPrice:this.saveRuleFormOrder.zdyActualPrice,
              zdyTotalPrice:this.saveRuleFormOrder.totalPrice,
              zdyDiscountAmount:this.saveRuleFormOrder.zdyDiscountAmount
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          } else {
            obj = {
              orderType: this.saveRuleFormOrder.orderType,
              payType: this.saveRuleFormOrder.payType,
              sectionId: this.saveRuleFormOrder.sectionId,
              subjectId: this.saveRuleFormOrder.subjectId,
              studentId: this.id,
              remark: this.saveRuleFormOrder.remark,
              moneyType: this.saveRuleFormOrder.moneyType,
              payMent: this.saveRuleFormOrder.payMent,
              totalPrice:this.saveRuleFormOrder.totalPrice,
              zdyGiveClassHour:this.saveRuleFormOrder.zdyGiveClassHour
            };
            if (this.saveRuleFormOrder.payType == 2) {
              obj.certificate = this.saveRuleFormOrder.certificate;
            }
          }
          if(this.orderDialog.type == 'edit'){
            await orderRemove([this.curEditOrder]).then(res => {
              if(res.code == 0&&res.msg=='操作成功！'){

              }else{
                  this.msgWarn(res.rows);
                  this.isDisable = false;
                  return false;
              }
            })
          }
          addOrder(obj).then((res) => {
            if (res.code == 0&&res.msg=='下单成功') {
              this.orderDialogVisible = false;
              setTimeout(() => {
                this.isDisable = false;
                }, 1000)
              this.msgSuccess("下单成功！");
              this.getOrderList();
              this.rechargeOrderList();
            }else {
              this.msgError(res.rows);
              this.isDisable = false;
            }
          });
        } else {
          this.isDisable = false;
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    coachAgreementSuccess(response, file, fileList) {
      if (response[0].url) {
        this.saveRuleFormOrder.coachAgreement = response[0].url;
        this.saveRuleFormOrder.coachAgreementName = response[0].originalName;
      }
    },
    certificateError(err, file, fileList) {
      this.msgError("付款凭证上传失败！");
    },
     coachAgreementError(err, file, fileList) {
      this.msgError("辅导协议上传失败！");
    },
    certificateSuccess(response, file, fileList) {
      if (response[0].url) {
        this.srcList2=[];
        this.srcList2.push(response[0].url)
        this.saveRuleFormOrder.certificate = response[0].url;
      }
    },
     orderSectionChange() {
      this.dealSubject();
    },
     orderTeacherTitleChange() {
      this.dealClassHour();
    },
     orderSubjectChange() {
      this.dealTeacherTitle();
    },
     orderClassHourChange() {
      for (
        let i = 0, len = this.productDetail.classHourList.length;
        i < len;
        i++
      ) {
        if (
          this.productDetail.classHourList[i].id ==
            this.saveRuleFormOrder.classHourId &&
          this.saveRuleFormOrder.classHourId != -1
        ) {
          this.saveRuleFormOrder.productPackageId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.classHour = this.productDetail.classHourList[
            i
          ].classHour;
          this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.singlePrice = this.productDetail.classHourList[
            i
          ].singlePrice;
          this.saveRuleFormOrder.totalPrice = this.productDetail.classHourList[
            i
          ].totalPrice;
        } else {
          this.saveRuleFormOrder.productPackageId = "";
          this.saveRuleFormOrder.classHour = "";
          this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[
            i
          ].id;
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    formatterProductType(item) {
      switch (item.orderType) {
        case 1:
          return "一对一"
        case 3:
          return "试听课" ;
        case 2:
        case 5:
          return "一对多";
      }
    },
    formatterCourseType(item) {
      switch (item.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    formatterOrderStatus(item) {
      if (item.orderStatus) {
        for (let i = 0, len = this.orderStatusList.length; i < len; i++) {
          if (this.orderStatusList[i].value == item.orderStatus) {
            return this.orderStatusList[i].label;
          }
        }
      }
    },
    orderStatus(row) {
      switch (row.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
      }
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    getOrderList() {
      teacherManagerOrder(this.listParams).then(res => {
        this.orderList = res.rows;
        this.total = res.total;
      });
    },
    rechargeOrderList(){
      teacherManagerOrder(this.rechargeListParams).then(res => {
        this.rechargeList = res.rows;
        this.rechargeTotal = res.total;
        if(res.countMap!=null){
          this.remainingAmount=res.countMap.remainingAmount;
          this.totalAmount=res.countMap.totalAmount;
        }
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrderList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrderList();
    },
    rechargeSizeChange(pageSize){
      this.rechargeListParams.pageSize = pageSize;
      this.rechargeOrderList();
    },
    rechargeCurrentChange(currentPage){
      this.rechargeListParams.pageNumber = currentPage;
      this.rechargeOrderList();
    },
    refreshClick() {
      this.reload();
    },
    dealEdit(row) {
      //修改
      // this.orderDialogVisible = true;
      // this.id=row.studentId;
      // this.getOrderDetail(row.id);
      this.downOrderStatus=true;
      this.downParams={
        id:row.studentId,
        type:"edit",
        classId:row.id,
      }
    },
    handleClickItem(){
		this.$nextTick(()=>{
			// 获取遮罩层dom
			let domImageMask = document.querySelector(".el-image-viewer__mask");
			//  if (!domImageMask) {
		 	//   return;
		 	// }
			domImageMask.addEventListener("click", () => {
			 	  // 点击遮罩层时调用关闭按钮的 click 事件
			  	 document.querySelector(".el-image-viewer__close").click();
			});
		})
	},
    getOrderDetail(id) {
      orderDetail(id).then(res => {
        if (res.code == 0) {
          this.curEditOrder = id;
          if(res.rows.productType==2){
            this.editOrderId=res.rows.productId;
          }else{
            this.editOrderId=res.rows.productPid;
          }
          this.saveRuleFormOrder = {
            orderType: String(res.rows.orderType), //1一对一  2一对多  3试听课
            payType: String(res.rows.payType), //1线上支付   2线下支付
            courseTypeId: res.rows.courseTypeId 
              ? String(res.rows.courseTypeId)
              : null, //1大班课  2小班课  3答疑课
            productId: res.rows.productId,
            certificate: res.rows.certificate, //付款凭证
            coachAgreement: res.rows.coachAgreement, //辅导协议
            coachAgreementName:res.rows.coachAgreementName,
            sectionId: res.rows.sectionId, //学段
            subjectId: res.rows.subjectId,
            teacherTitleId: res.rows.teacherTitleId,
            classHour: res.rows.classHour,
            classHourId: res.rows.classHourId, //不用传到后台
            singlePrice: res.rows.singlePrice,
            totalPrice: res.rows.totalPrice,
            zdyClassHour: res.rows.zdyClassHour,
            zdySinglePrice: res.rows.zdySinglePrice,
            zdyGiveClassHour: res.rows.zdyGiveClassHour,
            zdyDiscountAmount: res.rows.zdyDiscountAmount,
            zdyActualPrice: res.rows.zdyActualPrice,
            zdyTotalPrice:res.rows.zdyTotalPrice,
            studentId: this.id,
            productPackageId: res.rows.productPackageId,
            remark: res.rows.remark,
            payMent: res.rows.payMent ? String(res.rows.payMent) : null,
            moneyType: res.rows.moneyType ? String(res.rows.payMent) : null
          };
          this.srcList2.push(res.rows.certificate);
          this.numberToString(res.rows.totalPrice)
          this.orderClick();
        }
      });
    },
     numberToString(n){
       if (!/^(0|\-*[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
	var fuhao = (n.toString().indexOf("-")==0?"负":"");
    
    var unit = "千百拾亿千百拾万千百拾元角分", str = "";
        n += "00";
     //如果是负数就就截取
   	if(fuhao == "负"){
		n = n.substring(1, n.length);
   	}
    var p = n.indexOf('.');
    if (p >= 0)
        n = n.substring(0, p) + n.substr(p+1, 2);
        unit = unit.substr(unit.length - n.length);
    for (var i=0; i < n.length; i++)
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
    this.translateTotal=fuhao+str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整")
    },
    async orderClick() {
      await this.getProductList();
      this.translateTotal=""
      if(this.saveRuleFormOrder.orderType!=5){//一对一充值除外
        if (this.productList.length > 0) {
          this.saveRuleFormOrder.productId = this.productList[0].id;
          this.getProductDetail();
        } else {
          this.saveRuleFormOrder.sectionId = "";
          this.saveRuleFormOrder.productId = "";
          this.saveRuleFormOrder.periodId = "";
          this.saveRuleFormOrder.subjectId = "";
          this.saveRuleFormOrder.teacherTitleId = "";
          this.saveRuleFormOrder.classHourId = "";
          this.saveRuleFormOrder.singlePrice = "";
          this.saveRuleFormOrder.totalPrice = "";
        }
      }
    },
    getProductList() {
      if(this.saveRuleFormOrder.orderType==3){//试听课
        return productListNoPage({
        isSubscribe:1,
        courseTypeId:
          this.saveRuleFormOrder.orderType == 2
            ? this.saveRuleFormOrder.courseTypeId
            : null
      }).then(res => {
        if (res.code == 0) {
          this.productList = res.rows;
        }
      });
      }
      else if(this.saveRuleFormOrder.orderType==2){//一对多
          return productListNoPage({
            productType: this.saveRuleFormOrder.orderType,
            courseTypeId:
              this.saveRuleFormOrder.orderType == 2
                ? this.saveRuleFormOrder.courseTypeId
                : null
          }).then(res => {
            if (res.code == 0) {
              this.productList = res.rows;
            }
          });
      } 
    },
    getProductDetail() {
      return productInfoDetail(this.saveRuleFormOrder.productId).then(res => {
        if (res.code == 0) {
          this.productDetail.productPackage =
            res.rows.xmtProductPackageEntityList;
          if (this.saveRuleFormOrder.orderType == 2) {
            this.dealPeriod();
          } else {
            this.dealSection();
          }
        }
      });
    },
     dealPeriod() {
      this.productDetail.periodList = [];
      this.productDetail.periodIdList = [];
      this.saveRuleFormOrder.periodId = "";
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.periodIdList.includes(
            this.productDetail.productPackage[i].periodId
          ) &&
          this.productDetail.productPackage[i].periodId
        ) {
          this.productDetail.periodList.push({
            periodName: this.productDetail.productPackage[i].periodName,
            periodId: this.productDetail.productPackage[i].periodId,
          });
          this.productDetail.periodIdList.push(
            this.productDetail.productPackage[i].periodId
          );
        }
      }
      if (this.productDetail.periodList.length > 0) {
        this.saveRuleFormOrder.periodId =
          this.productDetail.periodList[0].periodId;
      }
      this.dealSubject();
    },
    dealSection() {
      this.productDetail.sectionList = [];
      this.productDetail.sectionIdList = [];
      for (
        let i = 0, len = this.productDetail.productPackage.length;
        i < len;
        i++
      ) {
        if (
          !this.productDetail.sectionIdList.includes(
            this.productDetail.productPackage[i].sectionId
          )
        ) {
          this.productDetail.sectionList.push({
            sectionName: this.productDetail.productPackage[i].sectionName,
            sectionId: this.productDetail.productPackage[i].sectionId
          });
          this.productDetail.sectionIdList.push(
            this.productDetail.productPackage[i].sectionId
          );
        }
      }
      this.saveRuleFormOrder.sectionId = this.productDetail.sectionList[0].sectionId;
      this.dealSubject();
    },
    dealSubject() {
      if (this.saveRuleFormOrder.orderType == 2) {
        this.productDetail.subjectList = [];
        this.productDetail.subjectIdList = [];
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            !this.productDetail.subjectIdList.includes(
              this.productDetail.productPackage[i].subjectId
            )
          ) {
            this.productDetail.subjectList.push({
              subjectName: this.productDetail.productPackage[i].subjectName,
              subjectId: this.productDetail.productPackage[i].subjectId
            });
            if (this.productDetail.subjectList.length > 0) {
              this.saveRuleFormOrder.subjectId =
                this.productDetail.subjectList[0].subjectId;
            }
          }
        }
        this.saveRuleFormOrder.subjectId = this.productDetail.subjectList[0].subjectId;
      }
      this.dealTeacherTitle();
    },
    dealTeacherTitle() {
      this.productDetail.teacherTitleList = [];
      this.productDetail.teacherTitleIdList = [];
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName: this.productDetail.productPackage[i]
                .teacherTitleName,
              teacherTitleId: this.productDetail.productPackage[i]
                .teacherTitleId
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        this.saveRuleFormOrder.teacherTitleId = this.productDetail.teacherTitleList[0].teacherTitleId;
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            !this.productDetail.teacherTitleIdList.includes(
              this.productDetail.productPackage[i].teacherTitleId
            )
          ) {
            this.productDetail.teacherTitleList.push({
              teacherTitleName: this.productDetail.productPackage[i]
                .teacherTitleName,
              teacherTitleId: this.productDetail.productPackage[i]
                .teacherTitleId
            });
            this.productDetail.teacherTitleIdList.push(
              this.productDetail.productPackage[i].teacherTitleId
            );
          }
        }
        if (this.productDetail.teacherTitleList.length > 0) {
          this.saveRuleFormOrder.teacherTitleId =
            this.productDetail.teacherTitleList[0].teacherTitleId;
        }
      }
      this.dealClassHour();
    },
    dealClassHour() {
      this.productDetail.classHourList = [];
      this.productDetail.classHourIdList = [];
      if (this.saveRuleFormOrder.orderType == 3) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.sectionId ==
              this.productDetail.productPackage[i].sectionId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
        this.productDetail.classHourList.push({
          classHour: "自定义课时",
          classHourName: "自定义课时",
          id: -1
        });
      } else if (this.saveRuleFormOrder.orderType == 2) {
        for (
          let i = 0, len = this.productDetail.productPackage.length;
          i < len;
          i++
        ) {
          if (
            this.saveRuleFormOrder.periodId ==
              this.productDetail.productPackage[i].periodId &&
            this.saveRuleFormOrder.subjectId ==
              this.productDetail.productPackage[i].subjectId &&
            this.saveRuleFormOrder.teacherTitleId ==
              this.productDetail.productPackage[i].teacherTitleId &&
            !this.productDetail.classHourIdList.includes(
              this.productDetail.productPackage[i].id
            )
          ) {
            this.productDetail.classHourList.push({
              classHour: this.productDetail.productPackage[i].classHour,
              classHourName:
                this.productDetail.productPackage[i].classHour + "课时",
              id: this.productDetail.productPackage[i].id,
              singlePrice: this.productDetail.productPackage[i].singlePrice,
              totalPrice: this.productDetail.productPackage[i].totalPrice
            });
            this.productDetail.classHourIdList.push(
              this.productDetail.productPackage[i].id
            );
          }
        }
      }
      
      if(this.saveRuleFormOrder.zdyClassHour){
        this.saveRuleFormOrder.classHourId = -1;
      }else{
         this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[0].id;
      }
      this.saveRuleFormOrder.productPackageId = this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.classHour = this.productDetail.classHourList[0].classHour;
     // this.saveRuleFormOrder.classHourId = this.productDetail.classHourList[0].id;
      this.saveRuleFormOrder.singlePrice = this.productDetail.classHourList[0].singlePrice;
      this.saveRuleFormOrder.totalPrice = this.productDetail.classHourList[0].totalPrice;
      this.saveRuleFormOrder.zdyTotalPrice =
        this.productDetail.classHourList[0].totalPrice;
        this.saveRuleFormOrder.zdyActualPrice =
        this.productDetail.classHourList[0].totalPrice;
    },
    dealReason(row){
      this.reasonDialogVisible=true;
      this.reasonTitle=row.disagreeCause;
    },
    dealSeeDetail(item) {
      this.orderInfoStatus=true;
      this.orderInfoParams={
        id:item.id,
        studentId:item.studentId
      }
    },
    refuseOrder() {
      this.detailDialogVisible = false;
      this.$prompt("请输入驳回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入驳回原因不能为空！",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          this.detailDialogVisible = false;
          orderUpdate({
            id: this.orderDetail.id,
            disagreeCause: value,
            orderStatus: 2
          }).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getOrderList();
            }
          });
        })
        .catch(() => {
          this.detailDialogVisible = true;
        });
    },
 
     productChange() {
      this.getProductDetail();
    },
    conformOrder() {
      orderAgree({
        id: this.orderDetail.id,
        studentId: this.studentDetail.id,
        xmtStudent: {
          contract: this.studentDetail.contract
        }
      }).then(res => {
        if (res.code == 0) {
          this.detailDialogVisible = false;
          this.msgSuccess("操作成功！");
          this.getOrderList();
        }
      });
    }
  },
  created() {},
  mounted() {
    this.getSectionList();
    this.getOrderList();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
     min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail {
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .self_input {
    padding-bottom: 4px;
    color: #606266;
    text-align: center;
    width: 90%;
    overflow: hidden;
    outline: none;
    border: none;
    border-bottom: 1px solid #c0c4cc;
  }
  .self_input:focus {
    border-bottom: 1px solid #1890ff;
  }
    .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
   .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
  .table_info{
    float:right;
    span{
      margin-right:15px;
      
    }

  }
</style>
