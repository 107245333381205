<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">学员列表</el-menu-item>
          <el-menu-item index="2">班级列表</el-menu-item>
        </el-menu>
      </div>
    </div>
      <div class="btn-area select-condition" v-show="selectKey == 1">
        <div class="left">
          <div class="select-condition-item">
            <span class="item-lable">关键字：</span>
            <el-input
              v-model="listParams.name"
              placeholder="产品名称/学生姓名"
              size="small"
            ></el-input>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">年级：</span>
            <el-select
              v-model="listParams.sectionId"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in sectionList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">下单时间：</span>
            <el-date-picker
              size="small"
              v-model="timePickerValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="datePickerChange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchFn(1)"
            >搜索</el-button
          >
        </div>
      </div>
      <div class="table-area" v-show="selectKey == 1">
        <el-table
          size="small"
          :data="tableList"
          style="width: 100%; margin-bottom: 20px"
          height="100%"
          row-key="userId"
          border
          tooltip-effect="dark"
        >
          <el-table-column
            prop="studentName"
            label="学生姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="studentSectionName"
            label="年级"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称"
            align="center"
          ></el-table-column>
          <el-table-column
            label="课程类型"
            align="center"
            :formatter="courseTypeIdFormatter"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
             :formatter="formatterTotalPrice"
            label="总价"
            align="center"
          ></el-table-column>
          <el-table-column
            label="购课时长"
            :formatter="formatterClassHour"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderSourceName"
            label="所属人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="moneyType"
            label="订单状态"
            align="center"
            :formatter="formatterOrderStatus"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                v-show="scope.row.joinClassFlag=='N'"
                @click.stop="joinClassRoom(scope.row)"
                >加入班级</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.joinClassFlag=='Y'"
                >已加入班级</el-button
              >
              <!-- <el-button
                type="text"
                size="small"
                @click.stop="returnOrder(scope.row)"
                >退回订单</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-area" v-show="selectKey == 1">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listParams.pageNumber"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="listParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        ></el-pagination>
      </div>
      <div class="btn-area select-condition" v-show="selectKey == 2">
        <div class="left">
          <div class="select-condition-item">
            <span class="item-lable">关键字：</span>
            <el-input
              v-model="classListParams.name"
              placeholder="班级名称/产品名称/授课老师/班主任名称"
              size="small"
            ></el-input>
          </div>
          <div class="select-condition-item">
            <span class="item-lable">选择日期：</span>
            <el-date-picker
              size="small"
              v-model="timePickerValue2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="classPickerChange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchFn(2)"
            >搜索</el-button
          >
        </div>
      </div>
      <div class="right" v-show="selectKey == 2">
        <el-button-group>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增班级</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑班级</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除班级</span>
          </el-button>
        </el-button-group>
      </div>
      <div class="table-area" v-show="selectKey == 2">
        <el-table
          size="small"
          :data="tableClassList"
          style="width: 100%; margin-bottom: 20px;margin-top:20px;"
          row-key="userId"
          border
          height="100%"
          ref="tableClassList"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @row-click="studentItemClick"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="roomName"
            label="班级名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalHours"
            label="总课时数"
             width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="usedClassHour"
            label="已排课时"
            align="center"
             width="80"
          ></el-table-column>
          <el-table-column
            label="班级人数"
            align="center"
             width="70"
            prop="peopleNum"
          ></el-table-column>
          <el-table-column
            prop="teacherName"
            label="授课老师"
            align="center"
             width="120"
          ></el-table-column>
          <!-- <el-table-column
            label="开班日期"
            prop="openClassTime"
            align="center"
             width="150"
          ></el-table-column>
          <el-table-column
            prop="moneyType"
            label="班级状态"
            align="center"
             width="120"
          >
          <template slot-scope="scope">
            <span v-show="scope.row.classState==0">未开班</span>
            <span v-show="scope.row.classState==1">开班中</span>
            <span v-show="scope.row.classState==2">已结班</span>
          </template>
          </el-table-column> -->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click.stop="addStudentFn(scope.row)"
                >添加学员</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="rowCourse(scope.row)"
                >去排课</el-button
              >
              <el-button
                type="text"
                size="small"
                @click.stop="clickWork(scope.row)"
                >作业</el-button
              >
              <el-button
                type="text"
                size="small"
                v-show="scope.row.isPush==0"
                  @click.stop="receptionPush(scope.row,1)"
                >设为前台推送</el-button
              >
               <el-button
                type="text"
                size="small"
                 v-show="scope.row.isPush==1"
                  @click.stop="receptionPush(scope.row,0)"
                >取消推送</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-area" v-show="selectKey == 2">
        <el-pagination
          @size-change="roomHandleSizeChange"
          @current-change="roomHandleCurrentChange"
          :current-page="classListParams.pageNumber"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="classListParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="classListParamsTotal"
          background
        ></el-pagination>
      </div>
    <el-dialog
      title="加入班级"
      :visible.sync="classRoomVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    > 
      <div class="dialog-content">
        <div class="item_select">
          <span>班级名称：</span>
          <el-select
            v-model="classroomId"
            placeholder="请选择"
            size="small"
            @change="classroomIdChange()"
          >
            <el-option
              v-for="item in classRoomList"
              :key="item.id"
              :label="item.roomName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classRoomVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="handleClassParams.title"
      :visible.sync="handleClassVisible"
      width="700px"
      :close-on-click-modal="false"
      center
    >
      <div class="save-dialog-content">
        <el-form
          :model="classRuleForm"
          status-icon
          :rules="saveClassRules"
          ref="classRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择课程" prop="productName">
                <el-button type="primary" size="small" @click="choiceCourse()"
                  >选择课程</el-button
                >
                <span>{{ classRuleForm.productName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="班级名称" prop="roomName">
                <el-input
                  v-model="classRuleForm.roomName"
                  autocomplete="off"
                  placeholder="请输入班级名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="班级类型" prop="courseTypeId">
                <el-select
                  v-model="classRuleForm.courseTypeId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in courseTypeIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="满班人数" prop="peopleNum">
                <el-input
                  v-model="classRuleForm.peopleNum"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="回放有效期" prop="playbackValidity">
                <el-input
                  v-model="classRuleForm.playbackValidity"
                  @blur="playbackValidityBlur()"
                ></el-input>
                <el-radio v-model="permanent" label="0" @change="permanentChange">永久</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="开班日期" prop="openClassTime">
                <el-date-picker
                  v-model="batchPickerValue2"
                  type="date"
                  :disabled="handleClassParams.type == 'edit'"
                  :picker-options="expireTimeOptionBefore"
                   @change="batchPickerChange2()"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="结班日期" prop="closeClassTime">
                <el-date-picker
                  v-model="batchPickerValue3"
                  type="date"
                  :disabled="handleClassParams.type == 'edit'"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  placeholder="选择日期"
                  :picker-options="endDatePicker"
                  @change="batchPickerChange3()"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="classRuleForm.remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClassVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="classSave('classRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择课程"
      :visible.sync="choiceCourseVisible"
      width="800px"
      center
    >
      <div class="save-dialog-content">
        <div class="table-area">
          <el-table
            size="small"
            :data="choiceCourseData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            highlight-current-row
            ref="Table"
            tooltip-effect="dark"
            @row-click="rowClick1"
          >
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.row.id"
                  v-model="radioId"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column
              prop="productName"
              label="产品名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="courseTypeId"
              label="课程类型"
              align="center"
              :formatter="dealCourseType"
            ></el-table-column>
            <el-table-column
              prop=""
              label="课时数"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              label="购买人数"
              align="center"
            ></el-table-column>
            <el-table-column
              label="产品状态"
              align="center"
              :formatter="dealProductStatus"
            ></el-table-column>
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeCourseChange"
            @current-change="handleCourseChange"
            :current-page="choiceCourseParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="choiceCourseParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="choiceCourseTotal"
            background
          ></el-pagination>
        </div>
       
      </div>
       <span slot="footer" class="dialog-footer">
          <el-button @click="choiceCourseVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="sureCourse"
            >确 定</el-button
          >
        </span>
    </el-dialog>
    <el-dialog
      title="学员列表"
      :visible.sync="addStudentVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div
          style="display:flex;justify-content: space-between;align-items: center;"
        >
          <div class="left">
            <p>
              当前班级学员<span style="color:#1890ff;font-size:20px;">{{
                studentDataTotal
              }}</span
              >人
            </p>
          </div>
          <div class="right">
            <el-button-group>
              <el-button type="default" size="small" @click="addStudentClick">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                <span>新增学员</span>
              </el-button>
              <el-button type="default" size="small" @click="delStudentClick">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-trash" />
                </svg>
                <span>删除学员</span>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div class="save-dialog-content" style="margin-top:20px;">
          <div class="table-area">
            <el-table
              size="small"
              :data="studentData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
              highlight-current-row
              ref="studentRef"
              tooltip-effect="dark"
              @selection-change="studentHandleSelectionChange"
              @row-click="studentrRowClick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="学员姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sex"
                label="性别"
                align="center"
                :formatter="dealSex"
              ></el-table-column>
              <el-table-column
                prop="contact"
                label="联系方式"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="年级"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="birthday"
                label="出生日期"
                align="center"
                :formatter="dealBirthday"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="studentHandleSizeCourseChange"
              @current-change="studentHandleCourseChange"
              :current-page="studentDataList.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="studentDataList.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studentDataTotal"
              background
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="添加学员"
      :visible.sync="addStudentSync"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="save-dialog-content" style="margin-top:20px;">
          <div class="table-area">
            <el-table
              size="small"
              :data="addStudentData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              border
              highlight-current-row
              ref="studentRef"
              tooltip-effect="dark"
              @selection-change="addStudentSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                :selectable="checkSelectable"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="学员姓名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="年级"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="productName"
                label="产品名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="account"
                label="学生账号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="班级类型"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="classHour"
                label="购课时长"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="orderCreateTime"
                label="下单时间"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStudentSync = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="addFn()"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="排课"
      :visible.sync="rowCourseVisible"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <div>
          <h2>班级信息</h2>
          <ul class="classInfo_ul">
            <li>班级名称:{{ classInfo.roomName }}</li>
            <li>产品名称:{{ classInfo.productName }}</li>
            <li>学段:{{ classInfo.periodName }}</li>
            <li>班级类型:{{ courseTypeJudge(classInfo.courseTypeId) }}</li>
            <!-- <li>满班人数:{{ classInfo.peopleNum }}</li>
            <li>开班日期:{{ classInfo.openClassTime }}</li>
            <li>结班日期:{{ classInfo.closeClassTime }}</li> -->
          </ul>
        </div>
        <div style="margin-top:20px;">
          <el-tabs v-model="classStatus" @tab-click="classStatusClick">
            <el-tab-pane label="配置讲师" name="teacher">
              <ul :class="{subjectActive:flag}" class="subject_ul">
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(23, '语文')">语文</el-button></li>   
                <li  v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(24, '数学')">数学</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(25, '英语')">英语</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(101, '物理')">物理</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(102, '化学')">化学</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(99, '政治')">政治</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(100, '生物')">生物</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(87, '地理')">地理</el-button></li>
                <li v-show="flag==true||flag==false"><el-button size="small" type="primary"  @click="addCourseList(88, '历史')">历史</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(103, '科学')">科学</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(112, '国学')">国学</el-button></li>   
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(113, '书法')">书法</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(114, '吉他')">吉他</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(115, '绘画')">绘画</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(116, '德语')">德语</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(117, '法语')">法语</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(118, '俄语')">俄语</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(119, '西班牙语')">西班牙语</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(183, '其他小语种')">其他小语种</el-button></li>
                <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(184, '其他艺术课')">其他艺术课</el-button></li>
                 <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(299, '其他学科')">其他学科</el-button></li>
                  <li v-show="flag==false"><el-button size="small" type="primary"  @click="addCourseList(300, '汉语')">汉语</el-button></li>
                 <p  @click = "showTag"><span style="cursor:pointer">{{flag?"展开":"收起"}}</span></p>
              </ul>
              <el-table
                size="small"
                :data="teacherList"
                style="width: 100%; margin-top: 20px"
                row-key="userId"
                border
                tooltip-effect="dark"
              >
                <el-table-column
                  prop="label"
                  label="标签"
                  :formatter="dealLabel"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="studentSectionName"
                  label="学科"
                  :formatter="dealSubjectId"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="teacherRealname"
                  label="讲师"
                  align="center"
                ></el-table-column>
                <!-- <el-table-column label="期望薪资" align="center">
                  <template slot-scope="scope">
                    <span
                      >{{ scope.row.hopeMinFee }}~{{
                        scope.row.hopeMaxFee
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="讲师课酬" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.teachFee"
                      @input="classSingBlur(scope.$index, scope.row.teachFee)"
                      placeholder="请输入课酬"
                    ></el-input>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column
                  prop="classSinglePrice"
                  label="课时单价"
                  align="center"
                ></el-table-column> -->
                <el-table-column label="操作" align="center" width="200">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="selectTeacher(scope.$index)"
                      >选老师</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      v-show="scope.row.label == 1"
                      @click="setDispose(scope.$index)"
                      >设为配置讲师</el-button
                    >
                     <el-button
                      type="text"
                      size="small"
                      v-show="scope.row.show == true"
                      @click="sureTeacherData(scope, scope.$index)"
                      >确定</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      @click="removeTeacherData(scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div class="page-area">
                <el-pagination
                  @size-change="teacherListSizeChange"
                  @current-change="teacherListCurrentChange"
                  :current-page="listParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="listParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="teacherListTotal"
                  background
                ></el-pagination>
              </div> -->
              <!-- <div
                style="display:flex;align-items:center;justify-content: center;margin-top:20px;"
              >
                <el-button @click="rowCourseVisible = false" size="small"
                  >取 消</el-button
                >
                <el-button
                  type="primary"
                  @click="confirmSaveTeacher()"
                  size="small"
                  >确 定</el-button
                >
              </div> -->
            </el-tab-pane>
            <el-tab-pane label="课程安排" name="arrange">
              <div style="display:flex;">
                <el-button type="primary" size="small" @click="singleTime"
                  >单次排课</el-button
                >
                <!-- <el-button type="primary" size="small" @click="batchTime"
                  >批量排课</el-button
                > -->
              </div>
              <div class="table-area" style="margin-top:20px;">
                <el-table
                  size="small"
                  :data="onToMoreData"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="userId"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column
                    prop="studentName"
                    label="学科"
                    :formatter="dealSubjectId"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="title"
                    label="课程标题"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="beginTime"
                    label="上课时间"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="usedClassHour"
                    label="课时数"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="讲师"
                    prop="teacherRealName"
                    align="center"
                  ></el-table-column>
                  <!-- <el-table-column
                    prop="classPay"
                    label="讲师课酬"
                    align="center"
                  ></el-table-column> -->
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        v-show="scope.row.attendClassStatus==0"
                        @click.stop="editCourse(scope.row)"
                        >修改</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        v-show="scope.row.attendClassStatus==0"
                        @click.stop="removeCourse(scope.row)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="学员详情" name="studentInfo">
              <div class="table-area" style="margin-top:20px;">
                <el-table
                  size="small"
                  :data="studentList"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="userId"
                  border
                  tooltip-effect="dark"
                >
                  <el-table-column
                    prop="name"
                    label="姓名"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="title"
                    label="性别"
                    :formatter="dealSex"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="contact"
                    label="联系方式"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="sectionName"
                    label="年级"
                    align="center"
                  ></el-table-column>
                  <!-- <el-table-column
                    label="所属人"
                    prop=""
                    align="center"
                  ></el-table-column> -->
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="courseParams.title"
      :visible.sync="singlePopup"
      :close-on-click-modal="false"
      width="400px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="singleRuleForm"
          status-icon
          :rules="singlePopupRules"
          ref="singleRuleForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="singleRuleForm.subjectId"
                placeholder="请选择"
                size="small"
                @change="selectChanged"
              >
                <el-option
                  v-for="item in singSubjectIdList2"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="singleRuleForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课时间" prop="beginTime">
              <el-date-picker
                v-model="singleRuleForm.beginTime"
                type="datetime"
                :picker-options="beforePickerOptions"
                placeholder="选择日期"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
               <el-input-number v-model="singleRuleForm.usedClassHour" type="number" placeholder="请输入内容" :max='Number(4)' :min='Number(1)'></el-input-number>
              <!-- <el-input
                v-model="singleRuleForm.usedClassHour"
                placeholder="请输入内容"
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
                  <el-input @click.native="getTeacherId()" 
                  :disabled="true" 
                  readonly
                  v-model="newteacherName" 
                  placeholder="请输入内容">
            </el-input>
              </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-radio-group v-model="singleRuleForm.isFirstClass">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-radio-group v-model="singleRuleForm.isPrisonClass">
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-radio-group v-model="singleRuleForm.isPlayback">
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="singlePopup = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
           :disabled="isDisable"
          @click="sureSave('singleRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="teacherPopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <div class="btn-area">
          <div class="select-condition-item">
            <el-menu
              :default-active="teacherIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="teacherIndexSelect"
            >
              <el-menu-item index="1">内部讲师</el-menu-item>
              <el-menu-item index="2">关注讲师</el-menu-item>
              <el-menu-item index="3">评价讲师</el-menu-item>
              <el-menu-item index="4">关注我的</el-menu-item>
              <el-menu-item index="5">最新注册</el-menu-item>
              <el-menu-item index="6">系统推荐</el-menu-item>
              <el-menu-item index="7">常用老师</el-menu-item>
            </el-menu>
          </div>
        </div>
        <div class="btn-area select-condition">
          <div class="left">
            <div class="select-condition-item">
              <div class="select-condition-item">
                <span class="item-lable">关键字：</span>
                <el-input
                  v-model="teacherListParams.name"
                  placeholder="姓名/联系方式"
                  size="small"
                ></el-input>
              </div>
              <div class="select-condition-item">
                <span class="item-lable">年级：</span>
                <el-select
                  v-model="teacherListParams.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </div>
              <div class="select-condition-item">
                <span class="item-lable">学科：</span>
                <el-select
                  v-model="teacherListParams.subjectId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in subjectIdList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </div>
              <div class="select-condition-item">
                <span class="item-lable">讲师身份：</span>
                <el-select
                  v-model="teacherListParams.identityType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in identityTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
             
            </div>
            <div class="select-condition-item">
              <el-button type="primary" size="small" icon="el-icon-search" @click="searchTeacher()"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
        <div class="table-areas">
          <el-table
            size="small"
            :data="teacherListData"
            style="width: 100%; margin-bottom: 20px"
            border
            row-key="id"
            tooltip-effect="dark"
            ref="teacherListTable"
            @selection-change="handleSelectionChange2"
            @row-click="teacherItemClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column prop="name" label="">
          <template slot-scope="scope">
            <div class="teachContent">
              <div class="infoDetails">
                <dl class="left">
                  <dt><img :src="scope.row.photo" alt="" style="width:90px;height:90px;margin:0 10px"> </dt>
                  <dd >
                    <el-tooltip
                 
                  effect="dark"
                  :content="aa + getNum + bb"
                  placement="top"
                >
                  <p
                    @mouseenter="seeMany(scope.row)"
                    style="display:inline-block;font-size:18px;cursor:pointer"
                  >
                    {{ scope.row.name }}<span style="font-size:14px;margin-left:5px;">（{{scope.row.realname}}）</span><span style="font-size:14px;margin-left:10px;">{{onLineFlagFn(scope.row.onLineFlag)}}</span>
                  </p>
                </el-tooltip>
                  <p >
                   <el-tag  size='small' v-if="scope.row.sexId == 1">男</el-tag>
                   <el-tag size='small' v-if="scope.row.sexId == 2">女</el-tag>
                   <el-tag size='small'>{{ titleFn(scope.row.teachTitleId) }}</el-tag>
                   <el-tag size='small'>{{ identityFn(scope.row.identityType) }}</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                    <el-tag size='small'>{{ subjectFn(scope.row.subjectId) }}</el-tag>
                    <el-tag size='small'>{{ dealSectionId(scope.row.sectionId) }}</el-tag>
                     
                    <el-tag size='small' type="success" v-if="scope.row.isEquipment==1">有设备</el-tag>
                    <el-tag size='small'  v-else-if="scope.row.isEquipment!=1">无设备</el-tag>
                  </p>
                  <p style="margin-top:10px;">
                  <el-tag size='small' type="warning" v-for="(item,key) in scope.row.arr" :key='key'>{{item.name}}</el-tag>
                  </p>
                  <p style="margin-top:10px;" >
                      分组：
                      <span v-show="scope.row.concernType==0">未关注</span>
                      <span v-show="(scope.row.concernType==1||scope.row.concernType==3)&&scope.row.groupName==null">未分组</span>
                      <span v-show="scope.row.groupName">{{ scope.row.groupName}}</span>
                  </p>
                  </dd>
                </dl>
                <div class="content">
                    <ul>
                      <li><span>授课方式：</span><span>{{classFormFn(scope.row.classForm)}}</span></li>
                      <li><span>擅教学生：</span><span  >{{judgeStyleNeedFns(scope.row.skilfulTeach)}}</span></li>
                      <li><span>讲课风格：</span><span  >{{skilfulFn(scope.row.skilful)}}</span></li>
                    </ul>
                </div>
                <div class="right">
                </div>
              </div>  
            </div>
          </template>
        </el-table-column>
            <!-- <el-table-column prop="name" label="">
              <template slot-scope="scope">
                <div style="display:flex;">
                  <div
                    style="display:inline-block;width:13%;text-align:center;"
                  >
                    <img
                      :src="scope.row.photo"
                      alt=""
                      style="width:100px;height:auto;margin:0 10px"
                    />
                  </div>
                  <div style="display:inline-block;width:65%;" class="scopeDiv">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="aa + getNum + bb"
                      placement="top"
                    >
                      <h2
                        @click="seeMany(scope.row)"
                        @mouseenter="seeMany(scope.row)"
                        style="display:inline-block;"
                      >
                        {{ scope.row.realname }}
                      </h2>
                    </el-tooltip>
                    <div>
                      <p>
                        <span v-if="scope.row.sexId == 1">男</span>
                        <span v-if="scope.row.sexId == 2">女</span>
                        <span>{{ scope.row.account }}</span>
                        <span
                          >期望课酬：{{ scope.row.hopeMinFee }}~{{
                            scope.row.hopeMaxFee
                          }}</span
                        >
                      </p>
                      <p>
                        <span>科目：{{ subjectFn(scope.row.subjectId) }}</span>
                        <span>年级：{{ gradeFn(scope.row.sectionId) }}</span>
                        <span
                          >讲师职称：{{ titleFn(scope.row.teachTitleId) }}</span
                        >
                        <span
                          >讲师身份：{{
                            identityFn(scope.row.identityType)
                          }}</span
                        >
                        <span>总课时：666</span>
                      </p>
                      <p>
                        <span>可面授地点1：666</span>
                        <span>可面授地点1：666</span>
                        <span>所有评价：666</span>
                        <span>好评数：666条</span>
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="teacherSizeChange"
            @current-change="teacherCurrentChange"
            :current-page="teacherListParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="teacherListParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="teachertotal"
            background
          ></el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTeacherData(1)" size="small"
          >添加配置讲师</el-button
        >
        <el-button type="primary" @click="saveTeacherData(2)" size="small"
          >添加预配置讲师</el-button
        >
        <el-button @click="teacherPopup = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择讲师"
      :visible.sync="selectTeacherPopup2"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <div class="table-area mT10">
          <el-table
            size="small"
            :data="matchingTeacherList"
            style="width: 100%;margin-bottom: 20px;"
            border
            height="400px"
            tooltip-effect="dark"
            :row-key="getRowKeys"
            ref="resTeacherListTable"
            @selection-change="handleTeacherSelectionChange"
            @row-click="rowTeacherItemClick"
          >
            <!-- <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              align="center"
            ></el-table-column> -->
             <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              prop="subjectName"
              label="学科"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="teacherRealname"
              label="讲师"
              align="center"
            ></el-table-column>
            <el-table-column prop="createTime" label="期望薪资" align="center">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span
                >
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="teachFee"
              label="讲师课酬"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="classSinglePrice"
              label="课时单价"
              align="center"
            ></el-table-column> -->
          </el-table>
        </div>
        <!-- <div class="page-area">
            <el-pagination
              @size-change="handleTeacherNewSizeChange"
              @current-change="handleTeacherNewCurrentChange"
              :current-page="teacherParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="teacherParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="teacherTotalnew"
              background
            ></el-pagination>
          </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectTeacherPopup2 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新增课程"
      :visible.sync="batchTimePopup"
      :close-on-click-modal="false"
      width="1200px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="batchTimeForm"
          status-icon
          ref="batchTimeForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="排课日期" prop="">
                <el-date-picker
                  size="small"
                  v-model="batchPickerValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  :picker-options="expireTimeOptionBefore"
                  end-placeholder="结束日期"
                  @change="batchPickerChange"
                  value-format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="星期选择" prop="">
                <el-checkbox-group
                  v-model="checkedWeeks"
                  @change="handleCheckedWeeksChange"
                >
                  <el-checkbox
                    v-for="city in weeks"
                    :label="city.id"
                    :key="city.id"
                    >{{ city.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox
                  :indeterminate="isWeekIndeterminate"
                  v-model="checkWeekAll"
                  @change="weeksCheckAllChange"
                  >全选</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button type="primary" @click="createCourse">生成课程</el-button>
        <el-table
        v-show="courseShow==true"
          size="small"
          :data="courseDate"
          style="width: 100%; margin-bottom: 20px;margin-top:20px;"
          row-key="id"
          border
          tooltip-effect="dark"
          highlight-current-row
        >
          <el-table-column
            prop="date"
            label="日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderType"
            label="星期"
            :formatter="dealIsDay"
            align="center"
          >
          </el-table-column>
           <el-table-column prop="productName" label="科目" align="center">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="tableSubjectId"
                placeholder="可选科目"
                size="small"
                @change="tableChange()"
              >
                <el-option
                  v-for="item in singSubjectIdList2"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </template>
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.subjectId"
                placeholder="可选科目"
                size="small"
                @change="tableItemChange()"
              >
                <el-option
                  v-for="item in singSubjectIdList2"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="beginTime" label="上课时间" align="center">
            <template slot="header" slot-scope="scope">
              <el-time-select
                placeholder="起始时间"
                v-model="pickerData"
                :picker-options="{
                  start: '06:00',
                  step: '00:05',
                  end: '23:59'
                }"
                @change="datetimeChange()"
              >
              </el-time-select>
            </template>
            <template slot-scope="scope">
              <el-time-select
                placeholder="起始时间"
                v-model="scope.row.time"
                :picker-options="{
                  start: '06:00',
                  step: '00:05',
                  end: '23:59'
                }"
              >
              </el-time-select>
            </template>
          </el-table-column>
           <el-table-column label="课时" align="center" visEdit="true">
            <template slot="header" slot-scope="scope">
              <el-input-number
                v-model="usedClassHour"
                :max="4"
                :min="0"
                style="width:110px;"
                @change="usedClassHourChange()"
                @input.native="usedClassHourInput($event)"
                clearable
                size="mini"
                placeholder="输入课时"
              />
            </template>
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.usedClassHour"
                :max="4"
                :min="0"
                  style="width:110px;"
                clearable
                size="mini"
                placeholder="输入课时"
              />
            </template>
          </el-table-column>
          <el-table-column label="课程标题" align="center" visEdit="true">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="usedTitle"
                @input="usedTitleInput()"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.title"
                clearable
                size="mini"
                placeholder="输入课程标题"
              />
            </template>
          </el-table-column>
         
          <el-table-column prop="teacherName" label="讲师" align="center">
            <template slot="header" slot-scope="scope">
              <p @click="scopeTeacher">请选择讲师</p>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.teacherName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="200px">
            <template slot-scope="scope">
              <div style="display:flex;">
                <div style="width:60%;">
                  <el-switch
                    v-model="scope.row.isFirstClass"
                    active-text="首次课"
                  >
                  </el-switch>
                  <!-- <el-switch
                    style="display: block"
                    v-model="scope.row.classMode"
                    active-text="线下课"
                  >
                  </el-switch> -->
                </div>
                <div style="width:40%">
                  <i
                    class="el-icon-delete"
                    @click="deleteTableRow(scope)"
                    style="margin-top:10px;font-size:30px;color:red;"
                  ></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchTimePopup = false" size="small">取 消</el-button>
        <el-button type="primary"  size="small" @click="sure()">确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog
      title="选择讲师"
      :visible.sync="selectTeacherPopup"
      :close-on-click-modal="false"
      width="800px"
      center
    >
       <div class="dialog-content">
            <div class="table-area mT10">
            <el-table
              size="small"
              :data="tList"
              style="width: 100%;margin-bottom: 20px;"
              border
              height="400px"
              tooltip-effect="dark"
              :row-key="getRowKeys"
              ref="teacherRef"
              @selection-change="handletChange"
              @row-click="rowClick"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="subjectName"
                label="学科"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="teacherRealname"
                label="讲师"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="期望薪资"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="teachFee"
                label="讲师课酬"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="classSinglePrice"
                label="课时单价"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
           <!-- <div class="page-area">
            <el-pagination
              @size-change="handleTeacherNewSizeChange"
              @current-change="handleTeacherNewCurrentChange"
              :current-page="teacherParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="teacherParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="teacherTotalnew"
              background
            ></el-pagination>
          </div> -->
       </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="selectTeacherPopup = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small"  @click="teacherSure()"
            >确 定</el-button
          >
        </span>
     </el-dialog>
     <el-dialog
      title="上课地址"
      :visible.sync="detailAddressPopup"  
      :close-on-click-modal="false"
      width="800px"
      center
    >
       <div class="dialog-content">
         <!-- <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="addAddress">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editAddress">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="removeAddress">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div> -->
           <div class="table-area">
      <el-table 
        size="small"
        :data="addressListData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        tooltip-effect="dark"
        ref="addressListData"
        @selection-change="addressListDataChange"
        @row-click="addressListDataItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column  
          prop="name"
          label="地址"
          align="center"
        >
          <template slot-scope="scope">
          <span v-show="scope.row.isDefault==1" style="padding:5px 8px;background:red;color:#fff;border-radius:4px;">默认</span>  <span>{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.countyName}}{{scope.row.detailAddress}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="page-area">
      <el-pagination
        @size-change="handleSizeChangeAddress"
        @current-change="handleCurrentChangeAddress"
        :current-page="classAddressData.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="classAddressData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalData"
        background
      ></el-pagination>
    </div> -->
       </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="detailAddressPopup = false" size="small">取 消</el-button>
        <el-button  
          type="primary"
          @click="clickAddressSure()"
          size="small"
          >确 定</el-button
        >
      </span>
      </el-dialog>
      <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <p v-show="centerType=='student'" style="padding:7px 0;">该时间段"{{centerStudentName}}"学生已有课程安排</p>
      <p v-show="centerType=='teacher'" style="padding:7px 0;">该时间段"{{centerTeacherName}}"老师已有课程安排</p>
      <p style="padding:7px 0;">上课时间：{{centerBeginTime}}</p>
      <p style="padding:7px 0;">上课时长：{{centerUsedClassHour}}小时</p>
      <p style="padding:7px 0;">辅导老师：{{centerTeacherName}}老师</p>
      <p style="padding:7px 0;">上课学生：{{centerStudentName}}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <moreWork ref="moreWork"  v-if="Visible" :visible="Visible" @close="Visible=false" ></moreWork>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  achievement
} from "@/api/public";
import {
  distributeMore,
  goBackOrder,
  getNameByProductId,
  classroomStudentSave,
  classroomRemove,
  classroomUpdate,
  classroomInfo,
  classroomSave,
  classroomList,
  classroomStudentList,
  classroomStudentRemove,
  getStudentByProductId,
  classroomStudentBatchSave,
  saveOneToMore,
  batchSaveOneToMore
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import {
  configteacherPlan,
  getConfigureCourseById,
  listTeacherManager,
  saveChooseTeacherMore,
  checkClassTime,
  xmtProductItemSave,
  findCourseItem,
  studentClassAddressList,
  xmtProductItembatchSave,
  xmtProductItemInfo,
  xmtProductItemRemove,
  xmtProductItemUpdate,
   checkTeacherClassTime,//老师是否被占用
  checkStudentClassTime,//学生是否被占用
  saveOneChooseTeacher,
  classroomUpdatepush,
  countConcern,
  batchCheckTeacherClassTime, //批量排课钱判断老师是否被占用
  batchCheckStudentClassTime,
  checkTeacherOrganClassTime,
  deleteOneChooseTeacher
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import dayjs from "dayjs";
import { productList } from "@/api/operates/productManage";
import moreWork from '../publicFile/moreWork.vue';
const weekOptions = [
  { name: "星期一", id: "1" },
  { name: "星期二", id: "2" },
  { name: "星期三", id: "3" },
  { name: "星期四", id: "4" },
  { name: "星期五", id: "5" },
  { name: "星期六", id: "6" },
  { name: "星期日", id: "0" },
];
export default {
  components:{
    moreWork
  },
  data() {
    return {
      Visible:false,
      weeks:weekOptions,
      checkedWeeks:[],
      checkWeekAll:false,
      isWeekIndeterminate:true,
      checkedGh:[],
      centerTeacherName:"",
      centerBeginTime:"",
      centerType:"",
      centerUsedClassHour:"",
      centerStudentName:"",
      getStudentId:"",
      activeIndex: "1",
      handleKey: "1",
      selectKey:'1',
      timePickerValue: "",
      timePickerValue2: "",
      sectionList: [],
      listParams: {
        name: null,
        sectionId: "",
        searchBeginDate: null,
        searchEndDate: null,
        pageNumber: 1,
        pageSize: 10,
        dataAuthority: "user",
        orderStatus: ""
      },
      permanent:'',
      getPeopleNum:0,
      beforePickerOptions:  {
          // 限制时间
          selectableRange: ['06:00:00 -23:59:59'],
        // 限制日期
          disabledDate: this.disabledDateTime
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      getSectionId:"",
      tableList: [],
      total: 0,
      classroomId: "",
      // classParams: {
      //   classroomId: "",
      //   studentId: ""
      // },
      classRoomList: [],
      classRoomVisible: false,
      classListParamsTotal: 0,
      classListParams: {
        name: null,
        openClassTime: null,
        closeClassTime: null,
        pageNumber: 1,
        pageSize: 10
      },
      tableClassList: [],
      handleClassParams: {
        title: "新增班级",
        type: "add"
      },
      handleClassVisible: false,
      saveClassRules: {
         productName: [
          { required: true, message: "课程不能为空", trigger: "blur" }
        ],
        peopleNum: [
          { required: true, message: "人数不能为空", trigger: "blur" }
        ],
        roomName: [
          { required: true, message: "班级名称不能为空", trigger: "blur" }
        ],
        courseTypeId: [
          { required: true, message: "班级类型不能为空", trigger: "blur" }
        ],
        teachManagerId: [
          { required: true, message: "课程不能为空", trigger: "blur" }
        ],
        openClassTime: [
          { required: true, message: "开班日期不能为空", trigger: "blur" }
        ],
        closeClassTime: [
          { required: true, message: "结班日期不能为空", trigger: "blur" }
        ]
      },
      studentList:[],//学生详情数组
      subjectIdListTitle:[],
      classRuleForm: {
        roomName: "",
        peopleNum: "",
        remarks: "",
        productId: "",
        productName: "",
        courseTypeId: "",
        openClassTime: null,
        closeClassTime: null
      },
      courseTypeIdList: [
        {
          value: 1,
          label: "大班课"
        },
        {
          value: 2,
          label: "小班课"
        },
        {
          value: 3,
          label: "答疑课"
        }
      ],
      flag:true,
      selectList:[],
      choiceCourseVisible: false,
      nowStudentId:[],
      choiceCourseData: [],
      choiceCourseParams: {
        pageNumber: 1,
        pageSize: 10,
        productStatus: 2,
        productType:2
      },
      choiceCourseTotal: 0,
      productStatusList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "已保存未发布"
        },
        {
          value: "2",
          label: "已发布"
        },
        {
          value: "3",
          label: "已下架"
        }
      ],
      multipleSelection: {},
      radioId: "",
      selectProductList: [],
      saveClass: {
        classroomId: "",
        studentId: "",
        productId:"",
      },
      isDisable:false,
      addStudentVisible: false,
      studentData: [],
      studentDataList: {
        classroomId: "",
        pageNumber: 1,
        pageSize: 10
      },
      studentDataTotal: 0,
      studentProductList: [],
      addStudentData: [],
      addStudentSync: false,
      getProductId: "",
      selectStudent: [],
      selectStudentParams: [],
      rowCourseVisible: false,
      classInfo: {},
      classStatus: "teacher",
      singlePopup: false,
      getval:[],
      singSubjectIdList2:[],
      singSubjectIdList: [
        {
          macroId: "0",
          name: "全部"
        }
      ],
      singleRuleForm: {
        subjectId: "",
        title: "",
        beginTime: null,
        usedClassHour: "2",
        isFirstClass: "0",
        classHourType:"1"
      },
      newteacherName: "",
      singlePopupRules: {
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        beginTime:[
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId:[
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
         usedClassHour:[
            { required: true, message: "课时数不能为空", trigger: "blur" },
          ],
      },
      teacherParams: {
        status: 1,
        label: 2,
        classroomId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10
      },
      teacherList: [],
      centerDialogVisible:false,
      getClassroomId: "",
      getIndex: "",
      teacherPopup: false,
      teacherListParams: {
        name: "", //关键字
        sectionId: "0", //年级
        subjectId: "0", //科目
        identityType: "0", //讲师身份
        teachTitleId: "0", //讲师职称
        pageNumber: 1,
        pageSize: 10
      },
      teachertotal: 0,
      teacherIndex: "1",
      expireTimeOptionBefore:{
        disabledDate (date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      teacherListData: [],
      tableRadio: "",
      aa: "已有",
      bb: "名教务关注了该讲师",
      getNum: "",
      subjectIdList: [],
      teacherListTotal: 0,
      configteacherParams: {
        status: 1,
        classroomId: "",
        pageNumber: 1,
        pageSize: 10
      },
      selectTeacherPopup2: false,
      matchingTeacherList: [],
      teacherSelectionHandle: [],
      onToMoreData: [],
      courseParams: {
        title: "新增课程",
        type: "add"
      },
      identityTypeList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "自由兼职老师"
        },
        {
          value: "2",
          label: "一线经验老师（含离退）"
        },
        {
          value: "3",
          label: "大学生"
        },
        {
          value: "4",
          label: "素质类老师（含艺术 语言）"
        },
      ],
      batchTimePopup: false,
      batchTimeForm: {},
      batchPickerValue: "",
      week: [],
      courseDate: [],
      tableSubjectId: "",
      singSubjectIdList: [],
      pickerData: "",
      usedClassHour: "2",
      batchTimeForm: {
        beginTime: "",
        stopTime: "",
        classHourType: ""
      },
      classroomObj:{},
      selectTeacherPopup:false,
      teacherParams:{
        status:1,
        label:2,
        classroomId:'',
        subjectId:'',
        pageNumber: 1,
        pageSize: 10
        
      },
      courseShow:false,
      tList:[],
      tTotal:0,
      teacherTable:[],
      tDataList:[],
      addressListData:[],
      totalData:0,
      detailAddressPopup:false,
      rowProductId:"",
      usedTitle:"",
       classAddressData:{
        classroomId:"",
        pageNumber:1,
        pageSize:10
      },
      startDatePicker: null,
      endDatePicker: null,
       batchPickerValue2:"",
      batchPickerValue3:"",
    };
  },
  created() {},
  mounted() {
    this.getSectionList();
    this.getSubjectList();
    
    this.getSubjectListTitle();
    this.distributeMoreFn();
    this.startDatePicker = this.beginDate();
    this.endDatePicker = this.processDate();
  },
   watch: {
    handleClassVisible: function (val,oldVla) {
      if (this.$refs['classRuleForm'] !== undefined) {
        this.$refs["classRuleForm"].resetFields();
      }
    },
    singlePopup: function (val,oldVla) {
      if (this.$refs['singleRuleForm'] !== undefined) {
        this.$refs["singleRuleForm"].resetFields();
      }
    }
  },
  methods: {
    receptionPush(row,num){
      classroomUpdatepush({id:row.id,isPush:num}).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          console.log(this)
          this.classroomListFn();
        }
      });
    },
    batchPickerChange2(){
      this.classRuleForm.openClassTime = this.batchPickerValue2;
    },
    batchPickerChange3(){
      this.classRuleForm.closeClassTime = this.batchPickerValue3+" "+"23:59:59";
    },
    beginDate() {
            const self = this
            return {
                disabledDate(time){
                    if (self.batchPickerValue3) {
                        return new Date(self.batchPickerValue3).getTime() < time.getTime()
                    }
                }
            }
        },
        processDate() {
            const  self = this
            return {
                disabledDate(time) {
                    if (self.batchPickerValue2) {
                        return new Date(self.batchPickerValue2).getTime() > time.getTime()
                    }
                }
            }
        },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: null,
            name: "全部"
          });
        }
      });
      getSelectList({
        parentCode: achievement
      }).then(res => {
        if (res.code == 0) {
          this.skilfulTeachDate=res.rows;
        }
      });
      getSelectList({
        //风格偏好
        parentCode: teachStyle,
      }).then((res) => {
        if (res.rows) {
          this.styleNeedArray = res.rows;
          this.skilfulDate = res.rows;
        }
      });
    },
    disabledDateTime(time) {
        return time.getTime() < Date.now() - 8.64e7  
   },
   teacherIndexSelect(key, keyPath) {
      this.teacherListParams = {
        hopeFee: "",
        name: "", //关键字
        sectionId: "", //年级
        subjectId: "", //科目
        identityType: "", //讲师身份
        teachTitleId: "", //讲师职称
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleKey = key;
      if (key == 1) {
        this.listTeacherManager();
      } else if (key == 2) {
        this.listTeacherManager(2);
      } else if (key == 3) {
        this.listTeacherManager(1);
      } else if (key == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (key == 5) {
        this.listTeacherManager(5);
      } else if (key == 6) {
        this.listTeacherManager(6);
      } else if (key == 7) {
        this.listTeacherManager(7);
      }
    },
    //获取学科
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "0",
            name: "全部"
          });
          this.subjectidList = res.rows;
          this.singSubjectIdList = res.rows;
          this.singSubjectIdList = res.rows;
        }
      });
    },
     getSubjectListTitle() {
      getSelectList({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          this.subjectIdListTitle = res.rows;
          this.singSubjectIdList2=res.rows;
        }
      });
    },
    handleSelect(key, keyPath) {
      // this.handleKey = key;
      this.selectKey=key;
      if(key==1){
        this.distributeMoreFn();
      }
      if (key == 2) {
        this.classroomListFn();
      }
    },
    searchTeacher() {
      if (this.handleKey == 1) {
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    classroomListFn() {
      classroomList(this.classListParams).then(res => {
        this.tableClassList = res.rows;
        this.classListParamsTotal = res.total;
      });
    },
     usedClassHourChange() {
      console.log(this.usedClassHour)
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].usedClassHour = this.usedClassHour;
      }
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    distributeMoreFn() {  
      distributeMore(this.listParams).then(res => {
        this.tableList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.distributeMoreFn();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.distributeMoreFn();
    },
    roomHandleSizeChange(pageSize) {
      this.classListParams.pageSize = pageSize;
      this.classroomListFn();
    },
    roomHandleCurrentChange(currentPage) {
      this.classListParams.pageSize = pageSize;
      this.classroomListFn();
    },
    showTag() {
      this.flag = !this.flag;
    },
    playbackValidityBlur(){
      if(this.permanent!=''){
        this.permanent=''
      }
    },
    permanentChange(){
      if(this.classRuleForm.playbackValidity){
        this.classRuleForm.playbackValidity=''
      }
    },
    //退回订单
    returnOrder(row) {
      this.$confirm("确定要退回订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var data = {
            id: row.id
          };
          goBackOrder(data).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.distributeMoreFn();
            }
          });
        })
        .catch(() => {});
      // this.$prompt("请输入退回订单理由", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputPattern: /\S/,
      //   inputErrorMessage: "退回订单理由不能为空！",
      //   closeOnClickModal: false
      // })
      //   .then(async ({ value }) => {

      //     let flag;
      //     var data = {
      //       id: row.id,
      //       invalidCause: value
      //     };
      //     goBackOrder(data).then(res => {
      //       if (res.code == 0) {
      //         this.msgSuccess("操作成功！");
      //         this.distributeMoreFn();
      //       }
      //     });
      //   })
      //   .catch(() => {});
    },
    joinClassRoom(row) {
      this.classroomId="";
      this.getStudentId=row.studentId;
      this.classRoomVisible = true;
      this.saveClass.studentId = row.studentId;
      this.saveClass.productId = row.productId;
      this.getNameByProductIdFn(row.productId);
    },
    getNameByProductIdFn(productId) {
      getNameByProductId({studentId:this.getStudentId,productId:productId}).then(res => {
        this.classRoomList = res.rows;
      });
    },
    classPickerChange() {
      if (!this.timePickerValue2) {
        this.classListParams.searchBeginDate = null;
        this.classListParams.searchEndDate = null;
        return;
      }
      this.classListParams.searchBeginDate = this.timePickerValue2[0];
      this.classListParams.searchEndDate = this.timePickerValue2[1];
    },
    addClick() {
      this.handleClassVisible = true;
      this.permanent="0"
      this.handleClassParams.title = "新增班级";
      this.handleClassParams.type = "add";
      this.batchPickerValue2='';
      this.batchPickerValue3='';
      this.classRuleForm = {
        roomName: "",
        peopleNum: "",
        remarks: "",
        productId: "",
        productName: "",
        courseTypeId: "",
        openClassTime: null,
        closeClassTime: null
      };
    },
    weeksCheckAllChange(val){
      let checked = weekOptions.map((item) => {
        return item.id;
      });
      this.checkedWeeks = val ? checked : [];
      this.isWeekIndeterminate = false;
    },
    editClick() {
      if (this.selectProductList.length != 1) {
        this.msgWarn("请选择一个班级！");
        return;
      }
      this.handleClassVisible = true;
      this.handleClassParams.title = "编辑班级";
      this.handleClassParams.type = "edit";
      this.classRuleForm = {
        roomName: "",
        peopleNum: "",
        remarks: "",
        productId: "",
        productName: "",
        courseTypeId: "",
        openClassTime: null,
        closeClassTime: null,
        playbackValidity:""
      };
      classroomInfo(this.selectProductList[0]).then(res => {
        if (res.code == 0) {
          this.classRuleForm = {
            roomName: res.rows.roomName,
            peopleNum: res.rows.peopleNum,
            remarks: res.rows.remarks,
            productId: res.rows.productId,
            productName: res.rows.productName,
            courseTypeId: res.rows.courseTypeId,
            openClassTime: res.rows.openClassTime,
            closeClassTime: res.rows.closeClassTime,
            id: res.rows.id,
            playbackValidity:res.rows.playbackValidity!=0?res.rows.playbackValidity:''
          };
          if(res.rows.playbackValidity==0){
            this.permanent='0'
          }
          this.batchPickerValue2=res.rows.openClassTime;
          this.batchPickerValue3=res.rows.closeClassTime;
        }
      });
    },
     classFormFn(type){
      if(type=='1'){
        return '线上'
      }else if(type=='2'){
        return '线下'
      }else if(type=='0'){
        return '均可'
      }
    },
    judgeStyleNeedFns(item) {
      let arr = new Array();
      let str = "";
     if(item){
        arr = item.split(",");
      }
      for (var i = 0; i < this.skilfulTeachDate.length; i++) {
        for (var k = 0; k < arr.length; k++) {
          if(this.skilfulTeachDate[i].macroId==arr[k]){
                str+=this.skilfulTeachDate[i].name.split(":")[0]+"，"
            }
        }
      }
     
      return str.substring(str.length-1,0);
    },
    skilfulFn(item) {
      let arr = new Array();
      let str = "";
      if(item){
        arr = item.split(",");
      }
      for (var i = 0; i < this.styleNeedArray.length; i++) {
          for (var k = 0; k < arr.length; k++) {
            if(this.styleNeedArray[i].macroId==arr[k]){
                str+=this.styleNeedArray[i].name.split("：")[0]+"，"
            }
          }
      }
       return str.substring(str.length-1,0);
    },
    handleSelectionChange2(val) {
      this.getval=val;
       this.tableRadio = val[0];
      this.checkedGhCheck = val[0].id;
      if (val.length > 1) {
        this.$refs.teacherListTable.clearSelection();
        this.$refs.teacherListTable.toggleRowSelection(val.pop());
      }
    },
    delClick(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        if (this.selectProductList.length == 0) {
          this.msgWarn("请至少选择一个班级进行删除！");
          return;
        }
        ids = this.selectProductList;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          classroomRemove(ids).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.classroomListFn();
            }
          });
        })
        .catch(() => {});
    },
    classSave(formName) {
      if(this.permanent!=''){
        this.classRuleForm.playbackValidity=0;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.handleClassParams.type == "add") {
            classroomSave(this.classRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.handleClassVisible = false;
                this.classroomListFn();
              }
            });
          } else if (this.handleClassParams.type == "edit") {
            classroomUpdate(this.classRuleForm).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.handleClassVisible = false;
                this.classroomListFn();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    formatterClassHour(row) {
      if (row.zdyClassHour) {
        return row.zdyClassHour;
      } else {
        return row.classHour;
      }
    },
    confirmApportion() {
      if(this.classroomObj.peopleNum==this.classroomObj.peopleUsedNum){
        this.$message.error('班级人数已上限，请更换班级！');
        return false;
      }
      classroomStudentSave(this.saveClass).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.classRoomVisible = false;
          this.distributeMoreFn();
        }
      });
    },
    choiceCourse() {
      this.choiceCourseVisible = true;
      this.choiceCourseParams.pageNumber=1;
      this.radioId="";
      this.productListFn();
    },
    classroomIdChange() {
     
      let that = this;
      this.classroomObj = this.classRoomList.find(function(item){
        return item.id ==that.classroomId;
      })
      console.log(this.classroomObj)
      this.saveClass.classroomId = this.classroomId;
    },
    productListFn() {
      productList(this.choiceCourseParams).then(res => {
        this.choiceCourseData = res.rows;
        this.choiceCourseTotal = res.total;
      });
    },
    handleSizeCourseChange(pageSize) {
      this.choiceCourseParams.pageSize = pageSize;
      this.productListFn();
    },
    handleCourseChange(currentPage) {
      this.choiceCourseParams.pageNumber = currentPage;
      this.productListFn();
    },
    studentHandleSizeCourseChange(pageSize) {
      this.studentDataList.pageSize = pageSize;
      this.classroomStudentListFn();
    },
    studentHandleCourseChange(currentPage) {
      this.studentDataList.pageNumber = currentPage;
      this.classroomStudentListFn();
    },
    dealCourseType(row) {
      let type;
      switch (row.courseTypeId) {
        case 1:
          type = "大班课";
          break;
        case 2:
          type = "小班课";
          break;
        case 3:
          type = "答疑课";
          break;
        default:
          type = "";
          break;
      }
      return type;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
     handleCheckedWeeksChange(value){
       let checkedCount = value.length;
      this.checkWeekAll = checkedCount === this.weeks.length;
      this.isWeekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    dealProductStatus(row) {
      let status = "";
      for (let i = 0, len = this.productStatusList.length; i < len; i++) {
        if (row.productStatus == this.productStatusList[i].value) {
          status = this.productStatusList[i].label;
        }
      }
      return status;
    },
    sureCourse() {
      if(!this.radioId){
        this.msgWarn("请选择课程！");
        return;
      }
      this.choiceCourseVisible = false;
      console.log(this.radioId)
    },
    rowClick1(row) {
      this.radioId = row.id;
      this.classRuleForm.productId = row.id;
      this.classRuleForm.productName = row.productName;
      if(row.courseTypeId){
        this.classRuleForm.courseTypeId = row.courseTypeId;
      }
    },
    handleSelectionChange(val) {
      this.selectProductList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectProductList.push(val[i].id);
        }
      }
    },
    studentItemClick(row) {
      this.$refs.tableClassList.toggleRowSelection(row);
    },
    studentHandleSelectionChange(val) {
      this.studentProductList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.studentProductList.push(val[i].id);
        }
      }
    },
    studentrRowClick(row) {
      this.$refs.studentRef.toggleRowSelection(row);
    },
    //添加学员
    addStudentFn(row) {
      this.getPeopleNum=row.peopleNum;//获取班级上限人数
      this.addStudentVisible = true;
      this.studentDataList.classroomId = row.id;
      this.getProductId = row.productId;
      this.classroomStudentListFn();
    },
    classroomStudentListFn() {
      classroomStudentList(this.studentDataList).then(res => {
        this.studentData = res.rows;
        this.studentDataTotal = res.total;
        this.selectList=[];
        this.nowStudentId=[];
        if(res.total!=0){
          for(var i=0;i<this.studentData.length;i++){
            this.nowStudentId.push(this.studentData[i].studentId)
            this.selectList.push(this.studentData[i].id);
          }
        }
      });
    },
    sureAddStudent() {},
    dealSex(row) {
      if (row.sex == 1) {
        return "男";
      } else if (row.sex == 2) {
        return "女";
      } else {
        return "";
      }
    },
    dealBirthday(row) {
      return  row.birthday?row.birthday.substring(0, row.birthday.length - 6):"";
    },
    addStudentClick() {
      this.aaa=this.studentData;
      
      // if(this.getPeopleNum<=this.studentDataTotal){
      //   this.msgWarn("班级满班人数已上限！");
      //     return;
      // }
      this.addStudentSync = true;
      getStudentByProductId(this.getProductId).then(res => {
        if (res.code == 0) {
          this.addStudentData = res.rows;
        }
      });
    },
    delStudentClick(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        if (this.studentProductList.length == 0) {
          this.msgWarn("请至少选择一个学员进行删除！");
          return;
        }
        ids = this.studentProductList;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          classroomStudentRemove(ids).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.classroomStudentListFn();
            }
          });
        })
        .catch(() => {});
    },
    addStudentSelectionChange(val) {
      this.selectStudentParams = [];
      for (var i = 0; i < val.length; i++) {
        this.selectStudentParams.push({
          classroomId: this.studentDataList.classroomId,
          name: val[i].name,
          sex: val[i].sex,
          account: val[i].account,
          birthday: val[i].birthday,
          studentId: val[i].id,
          sectionId: val[i].sectionId
        });
      }
    },
    addStudentrRowClick(row) {
       let flag = false;
      for (let i = 0, len = this.studentData.length; i < len; i++) {
        if (this.studentData[i].studentId == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
       this.$refs.studentRef.toggleRowSelection(row);
      }
    },
    checkSelectable(row,index) {
      let flag = false;
      for (let i = 0, len = this.aaa.length; i < len; i++) {
        if (this.aaa[i].studentId == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        return true;
      }
      return false;
    },
    addFn() {
      // if(Number(this.selectStudentParams.length)+Number(this.studentDataTotal)>this.getPeopleNum){
      //   this.msgWarn("班级满班人数已上限！");
      //     return;
      // }
      classroomStudentBatchSave(this.selectStudentParams).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.addStudentSync = false;
          this.classroomStudentListFn();
        }
      });
    },
  async  rowCourse(row) {
      this.classStatus = 'teacher';
      this.rowCourseVisible = true;
      this.getClassroomId = row.id;
      this.teacherParams.classroomId = row.id;
      this.rowProductId=row.productId;
      await classroomInfo(row.id).then(res => {
        if (res.code == 0) {
          this.classInfo = res.rows;
          this.getSectionId = res.rows.periodId;
        }
      });
      this.configteacherParams.classroomId = row.id;
      this.configteacherPlanlist();
    },
    configteacherPlanlist() {
      configteacherPlan(this.configteacherParams).then(res => {
        this.teacherList = res.rows;
        for(var i=0;i<this.teacherList.length;i++){
          this.teacherList[i].flag=2;
        }
      });
    },
    clickWork(row) {
      this.Visible=true;
    },
    classStatusClick(tab, event) {
      if (tab.index == 1) {
        this.findCourseItemFn();
      }else if(tab.index==2){
        this.findStudentListFn();
      }
    },
    findCourseItemFn() {
      var data = {
        classroomId: this.getClassroomId,
        searchBeginDate: null,
        searchEndDate: null
      };
      findCourseItem(data).then(res => {
        if(res.code==0){
          this.onToMoreData=res.rows.listProductItemTeachManager;
        }
      });
    },
    findStudentListFn(){
      var data={
        pageNumber:1,
        pageSize:100,
        classroomId:this.teacherParams.classroomId
      }
      classroomStudentList(data).then(res => {
        this.studentList = res.rows;
      });
    },
    //单次排课
    singleTime() {
      this.singlePopup = true;
      this.courseParams.title="新增课程";
      this.courseParams.type="add";
      this.newteacherName="";
      this.singleRuleForm={
        subjectId: "",
        title: "",
        beginTime: null,
        usedClassHour: "2",
        isFirstClass: "0",
        teacherId:"",
        configteacherPlanId:"",
        isPrisonClass:"1",
        isPlayback:"1"
      }
    },
    selectChanged(value) {
      if(this.courseParams.type=='edit'){
        if (value != this.getSubjectId) {
          this.newteacherName = "";
          this.singleRuleForm.teacherId = "";
        }
      }
      this.teacherParams.subjectId = value;
      if (value == 185) {
        this.singleRuleForm.title = "全学科";
      } else if (value == 23) {
        this.singleRuleForm.title = "语文";
      } else if (value == 24) {
        this.singleRuleForm.title = "数学";
      } else if (value == 25) {
        this.singleRuleForm.title = "英语";
      } else if (value == 101) {
        this.singleRuleForm.title = "物理";
      } else if (value == 102) {
        this.singleRuleForm.title = "化学";
      } else if (value == 99) {
        this.singleRuleForm.title = "政治";
      } else if (value == 100) {
        this.singleRuleForm.title = "生物";
      } else if (value == 87) {
        this.singleRuleForm.title = "地理";
      } else if (value == 88) {
        this.singleRuleForm.title = "历史";
      } else if (value == 103) {
        this.singleRuleForm.title = "科学";
      } else if (value == 112) {
        this.singleRuleForm.title = "国学";
      } else if (value == 113) {
        this.singleRuleForm.title = "书法";
      } else if (value == 114) {
        this.singleRuleForm.title = "吉他";
      } else if (value == 115) {
        this.singleRuleForm.title = "绘画";
      } else if (value == 116) {
        this.singleRuleForm.title = "德语";
      } else if (value == 117) {
        this.singleRuleForm.title = "法语";
      } else if (value == 118) {
        this.singleRuleForm.title = "俄语";
      } else if (value == 119) {
        this.singleRuleForm.title = "西班牙语";
      } else if (value == 183) {
        this.singleRuleForm.title = "其他小语种";
      } else if (value == 184) {
        this.singleRuleForm.title = "其他艺术课";
      }
      return this.singleRuleForm.title;
    },
    onLineFlagFn(num){
        switch(num){
        case 1: 
          return '在线'
        case 2: 
          return '刚刚活跃'
        case 3: 
          return '一天前活跃'
        case 4: 
          return '七天前活跃'
        default:
          return ''
      }
      },
    //批量排课
    batchTime() {
      this.courseShow=false;
      this.batchTimePopup = true;
      this.checkedWeeks=[];
      this.batchPickerValue="";
      this.batchTimeForm.classHourType = "";
      this.newProductId = "";
      this.surplusNum2 = 0;
      this.courseDate = [];
      this.tableSubjectId = null;
      this.pickerData = "";
      this.usedClassHour = "";
      this.usedTitle = "";
      this.selectTeacherName = null
      this.selectTeacherId = null
      this.selectConfigteacherPlanId = null
      this.selectClassAddress = null
    },
    sureSave(formName) {
      let starting = new Date(this.classInfo.openClassTime).getTime();//开班日期
      let ending = new Date(this.classInfo.closeClassTime).getTime();//结班日期
      let nowDate= new Date(this.singleRuleForm.beginTime).getTime();//排课日期
      // if(nowDate<starting){
      //   this.msgError("请在开班日期之后排课！");
      //   return false;
      // }
      // if(nowDate>ending){
      //   this.msgError("请在结班日期之前排课！");
      //   return false;
      // }
      this.singleRuleForm.sectionId=this.getSectionId;
      // this.singleRuleForm.teachFee=Number(this.singleRuleForm.usedClassHour)*Number(this.singleRuleForm.teachFee)
      this.$refs[formName].validate(valid => {
        if (valid) {
              this.isDisable = true;
              checkTeacherClassTime(this.singleRuleForm).then(res => {
              if(res.msg=='操作成功！'){
                checkStudentClassTime(this.singleRuleForm).then(res => {
                        if(res.msg=='操作成功！'){
                          this.singleRuleForm.classroomId = this.getClassroomId;
                          this.singleRuleForm.classHourType ='1';
                            if(this.courseParams.type=='add'){
                               checkTeacherOrganClassTime(this.singleRuleForm).then(res => {
                                 if (res.msg == "操作成功！") {
                                   saveOneToMore(this.singleRuleForm).then(res => {
                                      if(res.code==0&&res.msg=='操作成功！'){
                                        this.msgSuccess("添加成功！");
                                        this.singlePopup = false;
                                        setTimeout(() => {
                                          this.isDisable = false;
                                        }, 1000)
                                        this.findCourseItemFn();
                                      }else{
                                        this.msgWarn(res.msg);
                                        this.isDisable = false;
                                        return false;
                                      }
                                    })
                                 }else{
                                    this.centerType='teacher'
                                    this.centerStudentName=res.rows.studentName;
                                    this.centerTeacherName=res.rows.teacherName;
                                    this.centerBeginTime=res.rows.beginTime;
                                    this.centerTitle=res.rows.title;
                                    this.centerUsedClassHour=res.rows.usedClassHour;
                                    this.centerDialogVisible=true;
                                    this.isDisable = false;
                                 }
                               })
                            }else if(this.courseParams.type=='edit'){
                                 checkTeacherOrganClassTime(this.singleRuleForm).then(
                                  (res) => {
                                    if (res.msg == "操作成功！") {
                                       xmtProductItemUpdate(this.singleRuleForm).then(res => {
                                        if (res.code==0&&res.msg=='操作成功！') {
                                          this.msgSuccess("添加成功！");
                                          this.singlePopup = false;
                                          setTimeout(() => {
                                              this.isDisable = false;
                                            }, 1000)
                                          this.findCourseItemFn();
                                        }else{
                                          this.isDisable = false;
                                          this.msgWarn(res.msg);
                                          return false;
                                        }
                                      });
                                    }else{
                                      this.centerType='teacher'
                                      this.centerStudentName=res.rows.studentName;
                                      this.centerTeacherName=res.rows.teacherName;
                                      this.centerBeginTime=res.rows.beginTime;
                                      this.centerTitle=res.rows.title;
                                      this.centerUsedClassHour=res.rows.usedClassHour;
                                      this.centerDialogVisible=true;
                                      this.isDisable = false;
                                    }
                                  })
                               
                            }
                        }else{
                         //学生被占用
                         this.isDisable = false;
                          this.centerType='student'
                          this.centerStudentName=res.rows.studentName;
                          this.centerTeacherName=res.rows.teacherName;
                          this.centerBeginTime=res.rows.beginTime;
                          this.centerTitle=res.rows.title;
                          this.centerUsedClassHour=res.rows.usedClassHour;
                          this.centerDialogVisible=true;
                        }
                     })
              }else{
                this.isDisable = false;
                  //老师被占用
                  this.centerType='teacher'
                  this.centerStudentName=res.rows.studentName;
                  this.centerTeacherName=res.rows.teacherName;
                  this.centerBeginTime=res.rows.beginTime;
                  this.centerTitle=res.rows.title;
                  this.centerUsedClassHour=res.rows.usedClassHour;
                  this.centerDialogVisible=true;
              }
           }) 
        }
      });
    },
    getTeacherId() {
      // if (
      //   this.teacherParams.subjectId == null ||
      //   this.teacherParams.subjectId == ""
      // ) {
      //   this.msgWarn("请先选择学科！");
      //   return false;
      // }
      if(this.courseParams.type=='edit'){
        this.teacherParams.subjectId=this.singleRuleForm.subjectId;
      }
       if (
       !this.singleRuleForm.subjectId
      ) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.selectTeacherPopup2 = true;
      this.configteacherPlanFn();
    },
    configteacherPlanFn() {
      configteacherPlan(this.teacherParams).then(res => {
        this.matchingTeacherList = res.rows;
        // this.teacherTotalnew=res.total;
      });
    },
    addCourseList(macroId, name) {
      var data = {
        label: 0,
        subjectId: macroId,
        classSinglePrice: 0,
        classroomId: this.getClassroomId,
        flag: 2
      };
      this.teacherList.unshift(data);
    },
    selectTeacher(index) {
      this.getIndex = index;
      this.teacherPopup = true;
      this.teacherListParams.name = "";
      this.teacherListParams.sectionId = "";
      this.teacherListParams.subjectId = "";
      this.teacherListParams.identityType = "";
      this.teacherListParams.teachTitleId = "";
      this.teacherListParams.hopeFee = "";
      this.teacherIndex='1';
      this.listTeacherManager();
    },
    listTeacherManager(type) {
      let data = {
        selectType:type?type:'',
        hopeFee: this.teacherListParams.hopeFee,
        name: this.teacherListParams.name,
        sectionId:
          this.teacherListParams.sectionId == "0"
            ? ""
            : this.teacherListParams.sectionId, //年级
        subjectId:
          this.teacherListParams.subjectId == ""
            ? ""
            : this.teacherListParams.subjectId, //学科
        identityType:
          this.teacherListParams.identityType == "0"
            ? null
            : this.teacherListParams.identityType, //身份
        teachTitleId:
          this.teacherListParams.teachTitleId == "0"
            ? null
            : String(this.teacherListParams.teachTitleId), //职称
        pageNumber: this.teacherListParams.pageNumber,
        pageSize: this.teacherListParams.pageSize,
      };
      listTeacherManager(data).then((res) => {
        this.teachertotal = res.total;
        this.currentUserId = res.currentUserId;
        for (var i = 0; i < res.rows.length; i++) {
          if (res.rows[i].xmtConcernTeacherEntityList.length) {
            for (
              var j = 0;
              j < res.rows[i].xmtConcernTeacherEntityList.length;
              j++
            ) {
              if (
                res.rows[i].xmtConcernTeacherEntityList[j].teachManagerId ==
                  this.currentUserId &&
                res.rows[i].xmtConcernTeacherEntityList[j].teacherId ==
                  res.rows[i].id
              ) {
                res.rows[i].newTeachManagerIdd = true;
              } else {
                res.rows[i].newTeachManagerIdd = false;
              }
            }
          } else {
            res.rows[i].newTeachManagerIdd = false;
          }
        }
        this.teacherListData = res.rows;
      })
    },
    getRowKeys(row) {
      return row.id;
    },
    handleTeacherSelectionChange(val) {
      this.teacherSelectionHandle = val;
       this.checkedGh = val.id;
          if (val.length > 1) {
            this.$refs.resTeacherListTable.clearSelection();
            this.$refs.resTeacherListTable.toggleRowSelection(val.pop());
          }
    },
    rowTeacherItemClick(row) {
      this.$refs.resTeacherListTable.toggleRowSelection(row);
    },
    sureTeacher() {
      this.selectTeacherPopup2 = false;
      this.getSinglePrice2 = this.teacherSelectionHandle[0].classSinglePrice;
      this.newteacherName = this.teacherSelectionHandle[0].teacherRealname;
      this.singleRuleForm.teacherId = this.teacherSelectionHandle[0].teacherId;
      this.singleRuleForm.teachFee = this.teacherSelectionHandle[0].teachFee;
      this.singleRuleForm.configteacherPlanId = this.teacherSelectionHandle[0].id;
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].teacherName =this.teacherSelectionHandle[0].teacherRealname;
        this.courseDate[i].teacherId = this.teacherSelectionHandle[0].teacherId;
        this.courseDate[i].configteacherPlanId = this.teacherSelectionHandle[0].id;
        this.courseDate[i].teachFee = this.teacherSelectionHandle[0].teachFee;
      }
    },
    courseLevelFormatter(row) {
      var str = "";
      if (row.courseLevel == 1) {
        str = "初级课程";
      } else if (row.courseLevel == 2) {
        str = "中级课程";
      } else if (row.courseLevel == 3) {
        str = "高级课程";
      } else if (row.courseLevel == 4) {
        str = "特级课程";
      } else if (row.courseLevel == 0) {
        str = "正常课程";
      }
      return str;
    },
    setDispose(index) {
      this.teacherList[index].label = 2;
    },
    removeTeacherData(scope) {
      if (scope.row.id) {
        //真删除
        this.$confirm("是否删除该讲师?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  deleteOneChooseTeacher(scope.row.id).then((res) => {
                    if (res.code == 0) {
                      this.configteacherPlanlist();
                      this.msgSuccess("删除成功！");
                    }
                  });
                })
                .catch(() => {});
        
      } else {
         this.$confirm("是否删除该讲师?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                this.teacherList.splice(scope.$index, 1);
              })
              .catch(() => {});
        
      }
    },
    classSingBlur(index, val) {
      this.teacherList[index].show = true;
      this.$set(this.teacherList);
      this.teacherList[index].teachFee = val.replace(/[^\d]/g,'')
    },
    teacherSizeChange(pageSize) {
     
        this.teacherListParams.pageSize=pageSize;
      if (this.handleKey == 1) {
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    teacherCurrentChange(currentPage) {
      this.teacherListParams.pageNumber=currentPage;
      if (this.handleKey == 1) {
       
        this.listTeacherManager();
      } else if (this.handleKey == 2) {
        
        this.listTeacherManager(2);
      } else if (this.handleKey == 3) {
       
        this.listTeacherManager(1);
      } else if (this.handleKey == 4) {
        //关注我的
       
        this.listTeacherManager(3);
      } else if (this.handleKey == 5) {
        
        this.listTeacherManager(5);
      } else if (this.handleKey == 6) {
        this.listTeacherManager(6);
      } else if (this.handleKey == 7) {
        this.listTeacherManager(7);
      }
    },
    formatterTotalPrice(row){
      return row.totalPrice?row.totalPrice:row.zdyActualPrice;
    },
   
    teacherItemClick(row) {
         this.$refs.teacherListTable.toggleRowSelection(row);
    },
    dealSectionId(row) {
      var str = "";
      var arr2 = new Array();
      if (row) {
        arr2 = row.split(",");
      }
      for (var i = 0; i < arr2.length; i++) {
        if (arr2[i] == 98) {
          str += "高三，";
        } else if (arr2[i] == 97) {
          str += "高二，";
        } else if (arr2[i] == 96) {
          str += "高一，";
        } else if (arr2[i] == 20) {
          str += "初三，";
        } else if (arr2[i] == 19) {
          str += "初二，";
        } else if (arr2[i] == 18) {
          str += "初一，";
        } else if (arr2[i] == 95) {
          str += "六年，";
        } else if (arr2[i] == 94) {
          str += "五年级，";
        } else if (arr2[i] == 93) {
          str += "四年级，";
        } else if (arr2[i] == 92) {
          str += "三年级，";
        } else if (arr2[i] == 110) {
          str += "艺术培训，";
        } else if (arr2[i] == 111) {
          str += "小语种，";
        }else{
          str+='暂无数据'
        }
      }
      return str.substring(0, str.length - 1);
    },
    subjectFn(row) {
      switch (row) {
        case "185":
          return "全学科";
        case "23":
          return "语文";
        case "24":
          return "数学";
        case "25":
          return "英语";
        case "101":
          return "物理";
        case "102":
          return "化学";
        case "99":
          return "政治";
        case "100":
          return "生物";
        case "87":
          return "地理";
        case "88":
          return "历史";
        case "103":
          return "科学";
        case "112":
          return "国学";
        case "113":
          return "书法";
        case "114":
          return "吉他";
        case "115":
          return "绘画";
        case "116":
          return "德语";
        case "117":
          return "法语";
        case "118":
          return "俄语";
        case "119":
          return "西班牙语";
        case "183":
          return "其他小语种";
        case "184":
          return "其他艺术课";
        default:
          return "暂无数据";
      }
    },
    gradeFn() {},
    titleFn(row) {
      switch (row) {
        case 59:
          return "初级教师";
        case 60:
          return "中级教师";
        case 61:
          return "高级教师";
        case 62:
          return "特级教师";
        default:
          return "";
      }
    },
    identityFn(row) {
       switch (row) {
        case 1:
          return "自由兼职老师";
        case 2:
          return "一线经验老师（含离退）";
        case 3:
          return "大学生";
        case 4:
          return "素质类老师（含艺术 语言）";
        default:
          return "";
      }
    },
    seeMany(row) {
      countConcern({teacherId:row.id}).then(res => {
        if (res.code == 0) {
          this.getNum = res.msg;
        }
      });
    },
    editCourse(row) {
          this.singlePopup=true;
          this.courseParams.title="编辑课程";
          this.courseParams.type="edit";
          xmtProductItemInfo(row.id).then(res => {
             this.getSubjectId = res.rows.subjectId;
            this.singleRuleForm={
              id:res.rows.id,
              subjectId: res.rows.subjectId,
              title: res.rows.title,
              beginTime: res.rows.beginTime,
              usedClassHour: res.rows.usedClassHour,
              isFirstClass:String(res.rows.isFirstClass),
              isPrisonClass:String(res.rows.isPrisonClass),
              isPlayback:String(res.rows.isPlayback),
              teacherId:res.rows.teacherId,
              classroomId:res.rows.classroomId,
              teachFee:res.rows.teachFee,
              configteacherPlanId:res.rows.configteacherPlanId,
            }
            this.newteacherName=res.rows.teacherRealName;
      });
    },
    batchPickerChange() {
      if (!this.batchPickerValue) {
        this.batchTimeForm.beginTime = null;
        this.batchTimeForm.stopTime = null;
        return;
      }
      this.batchTimeForm.beginTime = this.batchPickerValue[0];
      this.batchTimeForm.stopTime = this.batchPickerValue[1];
    },
    removeCourse(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          xmtProductItemRemove([row.id]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.findCourseItemFn();
            }
          });
        })
        .catch(() => {});
    },
    createCourse() {
      if (this.batchPickerValue == "") {
        this.msgWarn("请选择排课日期");
        return;
      } else if (this.checkedWeeks.length == 0) {
        this.msgWarn("请选择星期");
        return;
      }
      this.courseShow=true;
      this.courseDate = [];
      this.getWeek(
        this.batchTimeForm.beginTime,
        this.batchTimeForm.stopTime,
        this.checkedWeeks
      );
    },
    datetimeChange() {
       for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].time = this.pickerData;
        this.courseDate[i].beginTime =
          this.courseDate[i].date + " " + this.pickerData;
      }
    },
    scopeAddress() {
      this.detailAddressPopup=true;
      this.studentClassAddressList()
    },
    studentClassAddressList(){
      this.classAddressData.classroomId=this.getClassroomId;
      studentClassAddressList(this.classAddressData).then(res => {
        this.addressListData=res.rows;
        this.totalData=res.total;
      });
    },
    sure(){
      let starting = new Date(this.classInfo.openClassTime).getTime();//开班日期
      let ending = new Date(this.classInfo.closeClassTime).getTime();//结班日期
      let nowStartDate = new Date(this.batchTimeForm.stopTime).getTime();//批量排课开班班日期
      let nowDate = new Date(this.batchTimeForm.stopTime).getTime();//批量排课结班日期
      if(this.courseDate.length==0){
        this.msgWarn("课程不能为空！");
        return;
      }
      for(var i=0;i<this.courseDate.length;i++){
        if(this.courseDate[i].subjectId==""||this.courseDate[i].time==""||this.courseDate[i].usedClassHour==""||this.courseDate[i].title==""||this.courseDate[i].teacherName==""){
          this.msgWarn("必填项不能为空！");
          return;
        }
      }
      // if(nowStartDate<starting){
      //   this.msgError("请在开班日期之后排课！");
      //   return false;
      // }
      // if(nowDate>ending){
      //   this.msgError("请在结班日期之前排课！");
      //   return false;
      // }
          batchCheckTeacherClassTime(this.courseDate).then((res) => {
        if (res.msg == "操作成功！") {
          batchCheckStudentClassTime(this.courseDate).then((res) => {
            if (res.msg == "操作成功！") {
              for (var i = 0; i < this.courseDate.length; i++) {
                this.courseDate[i].classroomId=this.getClassroomId;
                this.courseDate[i].sectionId=this.getSectionId;
                //  this.courseDate[i].teachFee=Number(this.courseDate[i].teachFee)*Number(this.courseDate[i].usedClassHour)
                this.courseDate[i].classHourType='1';
                this.courseDate[i].week = new Date(
                  this.courseDate[i].date
                ).getDay();
                if (this.courseDate[i].classMode == true) {
                  this.courseDate[i].classMode = "2";
                } else {
                  this.courseDate[i].classMode = "1";
                }
                if (this.courseDate[i].isFirstClass == true) {
                  this.courseDate[i].isFirstClass = "1";
                } else {
                  this.courseDate[i].isFirstClass = "0";
                }
              }
              batchSaveOneToMore(this.courseDate).then((res) => {
                if (res.code==0&&res.msg=='操作成功！') {
                  this.batchTimePopup=false;
                  this.findCourseItemFn();
                }else{
                  this.msgWarn(res.msg);
                  return false;
                }
              });
            } else {
              //学生被占用
              this.centerType = "student";
              this.centerStudentName = res.rows.studentName;
              this.centerTeacherName = res.rows.teacherName;
              this.centerBeginTime = res.rows.beginTime;
              this.centerTitle = res.rows.title;
              this.centerUsedClassHour = res.rows.usedClassHour;
              this.centerDialogVisible = true;
            }
          });
        } else {
          //老师被占用
          this.centerType = "teacher";
          this.centerStudentName = res.rows.studentName;
          this.centerTeacherName = res.rows.teacherName;
          this.centerBeginTime = res.rows.beginTime;
          this.centerTitle = res.rows.title;
          this.centerUsedClassHour = res.rows.usedClassHour;
          this.centerDialogVisible = true;
        }
      });
    },
    clickAddressSure(){

    },
    addressListDataChange(){

    },
    addressListDataItemClick(){

    },
    usedClassHourInput(e) {
      this.usedClassHour = e.data
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].usedClassHour = this.usedClassHour;
      }
    },
    usedTitleInput() {
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].title = this.usedTitle;
      }
    },
    scopeTeacher() {
        this.selectTeacherPopup=true;
        this.teacherParams.subjectId=this.tableSubjectId;
        this.findTeacher();
    },
    findTeacher(){
      this.teacherParams.classroomId=this.getClassroomId;
      configteacherPlan(this.teacherParams).then(res => {
          this.tList=res.rows;
          this.tTotal=res.total;
      });
    },
    handletChange(val){
        this.tDataList=val;
    },
    rowClick(row){
      this.$refs.teacherRef.toggleRowSelection(row);
    },  
    teacherSure(){
      this.selectTeacherPopup=false;
      for(var i=0;i<this.courseDate.length;i++){
          this.courseDate[i].teacherName=this.tDataList[0].teacherRealname;
          this.courseDate[i].teacherId=this.tDataList[0].teacherId;
          this.courseDate[i].teachFee=this.tDataList[0].teachFee;
          this.courseDate[i].configteacherPlanId=this.tDataList[0].id;
        }
    },
    deleteTableRow(row) {
      this.courseDate.splice(row.$index, 1);
    },
    tableChange() {
      for (var i = 0; i < this.courseDate.length; i++) {
        this.courseDate[i].subjectId = this.tableSubjectId;
      }
       for(var i=0;i<this.singSubjectIdList2.length;i++){
        if(this.singSubjectIdList2[i].macroId==this.tableSubjectId){
          this.usedTitle=this.singSubjectIdList2[i].name;
          for (var k = 0; k < this.courseDate.length; k++) {
            this.courseDate[k].title = this.singSubjectIdList2[i].name;
          }
        }
      }

    },
    getWeek(begin, end, weekNum) {
      var dateArr = new Array();
      var stimeArr = begin.split("-"); //=>["2018", "01", "01"]
      var etimeArr = end.split("-"); //=>["2018", "01", "30"]

      var stoday = new Date();
      stoday.setUTCFullYear(stimeArr[0], stimeArr[1] - 1, stimeArr[2]);
      var etoday = new Date();
      etoday.setUTCFullYear(etimeArr[0], etimeArr[1] - 1, etimeArr[2]);

      var unixDb = stoday.getTime(); //开始时间的毫秒数
      var unixDe = etoday.getTime(); //结束时间的毫秒数

      for (var k = unixDb; k <= unixDe; ) {
        let needJudgeDate = this.msToDate(parseInt(k)).withoutTime;
        //不加这个if判断直接push的话就是已知时间段内的所有日期
        for (var i = 0; i < weekNum.length; i++) {
          if (new Date(needJudgeDate).getDay() == weekNum[i]) {
            dateArr.push(needJudgeDate);
          }
        }
        k = k + 24 * 60 * 60 * 1000;
      }
      for (var j = 0; j < dateArr.length; j++) {
        this.courseDate.push({
          date: dateArr[j],
          productId: this.batchProductId,
          totalPrice: this.batchTotalPrice,
          singlePrice: this.getSinglePrice2,
          classHourType: this.batchClassHourType,
          subjectId: this.tableSubjectId,
          time: this.pickerData,
          usedClassHour: this.usedClassHour,
          title: '',
          teacherName: '',
          teacherId: '',
          // configteacherPlanId: '',
          classAddress: '',
          isFirstClass: false,
          classMode: false,
        });
      }
      // for (var j = 0; j < dateArr.length; j++) {
      //   this.courseDate.push({
      //     date: dateArr[j],
      //     productId: this.getproductId,
      //     totalPrice: this.newtotalPrice,
      //     classHourType: this.batchTimeForm.classHourType
      //   });
      // }
      return dateArr;

    },
    msToDate(msec) {
      let datetime = new Date(msec);
      let year = datetime.getFullYear();
      let month = datetime.getMonth();
      let date = datetime.getDate();
      let hour = datetime.getHours();
      let minute = datetime.getMinutes();
      let second = datetime.getSeconds();

      let result1 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 10 ? "0" + date : date) +
        " " +
        (hour + 1 < 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 < 10 ? "0" + minute : minute) +
        ":" +
        (second + 1 < 10 ? "0" + second : second);

      let result2 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 < 11 ? "0" + date : date);

      let result = {
        hasTime: result1,
        withoutTime: result2
      };
      return result;
    },
    dealIsDay(row) {
      var day = new Date(row.date).getDay();
      if (day == 1) {
        return "星期一";
      } else if (day == 2) {
        return "星期二";
      } else if (day == 3) {
        return "星期三";
      } else if (day == 4) {
        return "星期四";
      } else if (day == 5) {
        return "星期五";
      } else if (day == 6) {
        return "星期六";
      } else if (day == 0) {
        return "星期日";
      }
    },
    formatterOrderStatus(row) {
      switch (row.orderStatus) {
        case 1:
          return "待财务审核";
        case 2:
          return "财务审核拒绝";
        case 3:
          return "教务主管分配中";
        case 4:
          return "待排课";
        case 5:
          return "排课中";
        case 6:
          return "已退款";
        case 7:
          return "订单已完成";
        case 8:
          return "订单已作废";
        case 9:
          return "退款中";
      }
    },
    courseTypeIdFormatter(item) {
      switch (item.courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    confirmSaveTeacher() {
      if (this.teacherList.length < 1) {
        this.msgWarn("请添加计划信息!");
        return;
      }
      saveChooseTeacherMore(this.teacherList).then(res => {
        if (res.code == 0) {
          // this.teacherListTotal = res.total;
          // this.rowCourseVisible = false;
          this.msgSuccess("操作成功！");
        }
      });
    },
    sureTeacherData(scope,index){
      let row=scope.row;
      console.log(row)
      if(row.teacherId==undefined){
          this.msgWarn("请选择讲师！");
          return false;
      }
      // if(row.teachFee==undefined||row.teachFee==''){
      //     this.msgWarn("请配置讲师课酬！");
      //     return false;
      // }
      this.sureTeacherArray={
        id:scope.row.id?scope.row.id:null,
        teacherName:row.teacherName,
        // teachFee:row.teachFee,
        classSinglePrice:row.classSinglePrice,
        studentId:this.idd,
        label:row.label,
        subjectId:row.subjectId,
        courseLevel:row.courseLevel,
        hopeMinFee:row.hopeMinFee,
        hopeMaxFee:row.hopeMaxFee,
        teacherId:row.teacherId,
        flag:1,
        classroomId:this.getClassroomId
      }
       this.teacherList[index].show=false;
       this.$set(this.teacherList)
       saveOneChooseTeacher(this.sureTeacherArray).then(res => {
        if (res.code == 0&&res.msg == '操作成功！') {
           this.msgSuccess("老师配置成功！");
            row.show = false;
            row.label = res.xmtConfigteacherPlan.label;
          row.studentId = res.xmtConfigteacherPlan.studentId;
          row.studentName = res.xmtConfigteacherPlan.studentName;
          row.stuChatId = res.xmtConfigteacherPlan.stuChatId;
          row.teaChatId = res.xmtConfigteacherPlan.teaChatId;
          row.teacherId = res.xmtConfigteacherPlan.teacherId;
          row.id = res.xmtConfigteacherPlan.id;
          row.teachManagerId = res.xmtConfigteacherPlan.teachManagerId;
          row.subjectId = res.xmtConfigteacherPlan.subjectId;
          row.subjectName = res.xmtConfigteacherPlan.subjectName
          row.teacherName = res.xmtConfigteacherPlan.teacherName;
          row.teacherRealname = res.xmtConfigteacherPlan.teacherRealname;
          row.parChatId = res.xmtConfigteacherPlan.parChatId;
          this.$set(this.teacherList, index, row);
        }else{
          this.msgWarn(res.msg);
          return;
        }
      });
    },
    teacherListSizeChange() {},
    teacherListCurrentChange() {},

    saveTeacherData(num) {
      if(this.getval.length==0){
       this.msgWarn("请选择讲师!");
        return;
     }
      if (num == 1) {
        //配置讲师
        this.teacherList[this.getIndex].label = 2;
      } else {
        //预配置讲师
        this.teacherList[this.getIndex].label = 1;
      }
      this.teacherPopup = false;
      this.teacherList[this.getIndex].teacherRealname = this.tableRadio.realname;
      this.teacherList[this.getIndex].teacherId = this.tableRadio.id;
      this.teacherList[this.getIndex].hopeMinFee = this.tableRadio.hopeMinFee;
      this.teacherList[this.getIndex].hopeMaxFee = this.tableRadio.hopeMaxFee;
      this.$set(
        this.teacherList,
        this.getIndex,
        this.teacherList[this.getIndex]
      );
       this.teacherList[this.getIndex].show = true;
    },
    courseTypeJudge(courseTypeId) {
      switch (courseTypeId) {
        case 1:
          return "大班课";
        case 2:
          return "小班课";
        case 3:
          return "答疑课";
        default:
          return "";
      }
    },
    dealLabel(row) {
      var labelstr = "";
      if (row.label == 0) {
        labelstr = "无";
      } else if (row.label == 1) {
        labelstr = "预配置讲师";
      } else if (row.label == 2) {
        labelstr = "配置讲师";
      }
      return labelstr;
    },
    dealSubjectId(row) {
      var subjectlist = "";
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    searchFn(num) {
      if (num == 1) {
        this.distributeMoreFn();
      } else if (num == 2) {
        this.classroomListFn();
      }
    }
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    
  }
   .infoDetails{
    height:150px;
    padding-top:20px;
    display: flex;
  }
  .infoDetails .left{
    display: flex;
    width:35%;
    min-width:500px;
  }
  .infoDetails .content{
      width:350px;
  }
  .infoDetails .content ul{
    margin-top:30px;
  }
  .infoDetails .content ul li{
    margin-top:5px;
  }
  .infoDetails .content ul span:nth-child(1){
    font-size:15px;
    color:#414a60;
  }
  .infoDetails .content ul span:nth-child(2){
    font-size:15px;
    color:#61687c;
  }
  .infoDetails .right{
    display: flex;
    width:20%;
    justify-content: center;
    align-items: center;
  }
</style>
