<template>
  <div class="dialog_mask online_chat_window" v-show="chatToUserParams.show">
    <div class="dialog">
      <div class="dialog_header">
        <span
          class="iconfont icon-error icon_close"
          @click="closeDialog"
        ></span>
      </div>
      <div class="dialog_content">
        <div class="user_info" v-if="chatToUserParams.type == 'teacher'">
          <div class="user_info_left">
            <img :src="photo" alt="" />
          </div>
          <div class="user_info_right">
            <div class="row_1">
              <span class="info_label info_name">{{ chatToUserParams.info.name }}</span>
              <span class="online_status">{{
                chatToUserParams.info.onLineFlag
              }}</span>
            </div>
            <div class="row_2">
              <span class="info_label">{{
                chatToUserParams.info.sexName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{ chatToUserParams.info.age }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{
                chatToUserParams.info.teacherTitle
              }}</span>
            </div>
            <div class="row_3">
              <span class="info_label">{{
                chatToUserParams.info.subjectName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{
                chatToUserParams.info.sectionName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{
                chatToUserParams.info.classFlag
              }}</span>
            </div>
          </div>
        </div>
        <div class="user_info" v-if="chatToUserParams.type == 'parent'">
          <div class="user_info_left">
            <img :src="photo" alt="" />
          </div>
          <div class="user_info_right">
            <div class="row_1">
              <span class="sub_title">家长信息</span>
              <span class="info_label">{{chatToUserParams.studentInfo.name}}</span>
            </div>
            <div class="row_2">
              <span class="sub_title">学生信息</span>
              <span class="info_label">{{
                chatToUserParams.studentInfo.sexName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{ chatToUserParams.studentInfo.birthday }}</span>
              <span class="h_line">|</span>
              <span class="info_label">分配时间：{{
                chatToUserParams.studentInfo.allocateTime
              }}</span>
            </div>
            <div class="row_3">
              <span class="info_label">{{
                chatToUserParams.studentInfo.schoolName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{
                chatToUserParams.studentInfo.studentState
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">{{
                chatToUserParams.studentInfo.sectionName
              }}</span>
              <span class="h_line">|</span>
              <span class="info_label">必考科目：{{
                chatToUserParams.studentInfo.subjectInfo
              }}</span>
            </div>
          </div>
        </div>
        <div class="conversation_box">
          <current-conversation :dialogChat="dialogChat"></current-conversation>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import currentConversation from "../conversation/current-conversation";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      dialogChat: true,
      photo: '',
      nick: '',
    };
  },
  inject: ["timChat"],
  watch: {
    "chatToUserParams.show"(show) {
      if (show) {
        this.chatToUser(this.chatToUserParams.chatId);
        this.getChatUserInfo(this.chatToUserParams.chatId)
      }
    },
  },
  components: {
    currentConversation,
  },
  computed: {
    ...mapState("chatInfo", ["currentConversation", "chatToUserParams"]),
  },
  mounted() {},
  methods: {
    ...mapActions("chatInfo", ["checkoutConversation"]),
    ...mapMutations("chatInfo", ["chatBoxShowChange"]),
    getChatUserInfo(chatId) {
      this.timChat.tim.getUserProfile({
        userIDList: [chatId]
      }).then(res => {
        if(res.code == 0){
          const { avatar, nick } = res.data[0]
          this.photo = avatar
          this.nick = nick
        }
        console.log('getUserProfile: ', res)
      }).catch(err => {
        console.error('getUserProfile err: ', err)
      })
    },
    closeDialog() {
      this.timChat.tim.setMessageRead({
        conversationID: this.currentConversation.conversationID,
      });
      this.chatBoxShowChange({
        show: false,
      });
    },
    chatToUser(chatId) {
      const conversationID = `C2C${chatId}`;
      this.checkoutConversation(conversationID);
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog {
  background-color: #ffffff;
  width: 640px;
  height: 660px;
  position: fixed;
  right: 10px;
  top: calc((100vh - 660px) / 2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
  .dialog_header {
    position: relative;
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  .dialog_content {
    flex: 1;
    overflow: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;
    .user_info {
      padding: 20px 20px;
      display: flex;
      font-size: 12px;
      .user_info_left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
        }
      }
      .user_info_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .sub_title {
          font-weight: 700;
          margin-right: 20px
        }
        .info_label {
        }
        .h_line {
          margin: 0 8px;
          color: #DCDFE6;
        }
        .info_name{
          font-weight: 700;
          margin-right: 20px;
          font-size: 14px;
        }
        .online_status{
          border: 1px solid #67C23A;
          padding: 0px 6px;
          color: #67C23A;
          border-radius: 2px;
          font-size: 12px;
        }
      }
    }
    .conversation_box {
      padding: 20px 20px;
      background-color: #F1F2F3;
      flex: 1;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
}
</style>