<template>
  <div class="chat_container my_chat">
    <div class="main_chat" v-show="showWin == 'mainChat'">
      <div class="chat_left">
        <div class="left_top">
          <div class="search_input">
            <el-input
              placeholder="关键字搜索"
              prefix-icon="el-icon-search"
              v-model="searchKey"
              size="small"
              clearable
            >
            </el-input>
          </div>
          <div class="user_btn">
            <span class="icon_c" @click="showWinChange('myRelation')">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-xingmingyonghumingnicheng" />
              </svg>
            </span>
          </div>
        </div>
        <div class="conversation_list" v-show="!searchKey">
          <conversation-item
            :conversation="item"
            v-for="item in conversationList"
            :key="item.conversationID"
          />
        </div>
        <div class="couversation_list_search" v-show="searchKey">
          <conversation-item-search
            :conversation="item"
            v-for="item in searchList"
            :key="item.conversationID"
          />
        </div>
      </div>
      <div class="chat_right">
        <current-conversation></current-conversation>
      </div>
    </div>
    <div class="my_relation" v-show="showWin == 'myRelation'">
      <div class="relation_left">
        <div class="left_top">
          <div class="search_input">
            <el-input
              placeholder="关键字搜索"
              prefix-icon="el-icon-search"
              v-model="relationSearchKey"
              clearable
              size="small"
            >
            </el-input>
          </div>
          <span class="cancel_btn" @click="showWinChange('mainChat')"
            >取消</span
          >
        </div>
        <div class="relation_search_list" v-show="relationSearchKey">
          <div
            class="relation_part"
            v-show="relationSearchObj.colleaguesList.length > 0"
          >
            <div class="relation_title">我的同事</div>
            <div class="r_list">
              <div
                class="r_item"
                v-for="(item, index) in relationSearchObj.colleaguesList"
                :key="index"
                @click="relationSearchItemClick(item)"
              >
                <img
                  class="r_item_avatar"
                  :src="
                    item.avatar
                      ? item.avatar
                      : require('@/assets/image/chat/default_avatar.png')
                  "
                  alt=""
                />
                <span class="r_item_name" :title="item.name">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="relation_part"
            v-show="relationSearchObj.groupList.length > 0"
          >
            <div class="relation_title">我的群聊</div>
            <div class="r_list">
              <div
                class="r_item"
                v-for="(item, index) in relationSearchObj.groupList"
                :key="index"
                @click="relationSearchItemClick(item)"
              >
                <img
                  class="r_item_avatar"
                  :src="
                    item.avatar && item.avatar.includes('http')
                      ? item.avatar
                      : require('@/assets/image/chat/default_avatar.png')
                  "
                  alt=""
                />
                <span class="r_item_name" :title="item.name">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="relation_part"
            v-show="relationSearchObj.studentList.length > 0"
          >
            <div class="relation_title">我的学生/家长</div>
            <div class="r_list">
              <div
                class="r_item"
                v-for="(item, index) in relationSearchObj.studentList"
                :key="index"
                @click="relationSearchItemClick(item)"
              >
                <img
                  class="r_item_avatar"
                  :src="
                    item.avatar
                      ? item.avatar
                      : require('@/assets/image/chat/default_avatar.png')
                  "
                  alt=""
                />
                <span class="r_item_name" :title="item.name">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="relation_part"
            v-show="relationSearchObj.eduList.length > 0"
          >
            <div class="relation_title">我的老师</div>
            <div class="r_list">
              <div
                class="r_item"
                v-for="(item, index) in relationSearchObj.eduList"
                :key="index"
                @click="relationSearchItemClick(item)"
              >
                <img
                  class="r_item_avatar"
                  :src="
                    item.avatar
                      ? item.avatar
                      : require('@/assets/image/chat/default_avatar.png')
                  "
                  alt=""
                />
                <span class="r_item_name" :title="item.name">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="relation_list" v-show="!relationSearchKey">
          <div
            class="relation_item"
            @click="tabClick('group')"
            :class="{ active: activeTab == 'group' }"
          >
            <img
              class="relation_img"
              :src="require('@/assets/image/chat/relation_group.png')"
              alt=""
            />
            <span class="relation_name">交流群</span>
            <span class="iconfont icon-icon13 icon_right"></span>
          </div>
          <div
            class="relation_item"
            @click="tabClick('student')"
            :class="{ active: activeTab == 'student' }"
          >
            <img
              class="relation_img"
              :src="require('@/assets/image/chat/relation_student.png')"
              alt=""
            />
            <span class="relation_name">我的学生/家长</span>
            <span class="iconfont icon-icon13 icon_right"></span>
          </div>
          <div
            class="relation_item"
            @click="tabClick('edu')"
            :class="{ active: activeTab == 'edu' }"
          >
            <img
              class="relation_img"
              :src="require('@/assets/image/chat/relation_edu.png')"
              alt=""
            />
            <span class="relation_name">我的老师</span>
            <span class="iconfont icon-icon13 icon_right"></span>
          </div>
          <div
            class="relation_item"
            @click="tabClick('friend')"
            :class="{ active: activeTab == 'friend' }"
          >
            <img
              class="relation_img"
              :src="require('@/assets/image/chat/relation_teacher_friend.png')"
              alt=""
            />
            <span class="relation_name">我的同事</span>
            <span class="iconfont icon-icon13 icon_right"></span>
          </div>
        </div>
      </div>
      <div class="relation_right">
        <div class="relation_right_header">
          <span class="btn" @click="createGroupClick">创建群聊</span>
        </div>
        <div class="relation_right_inner">
          <div class="find_list friend_list" v-show="activeTab == 'friend'">
            <div
              class="list_item"
              v-for="(item, index) in colleaguesList"
              :key="index"
            >
              <img
                class="item_img"
                :src="
                  item.photo
                    ? item.photo
                    : require('@/assets/image/chat/default_avatar.png')
                "
                alt=""
              />
              <span class="item_nick item_info" :title="item.username">{{
                item.username ? item.username : "暂无名称"
              }}</span>
              <span class="btn" @click="handleFriendClick(item)">进入单聊</span>
            </div>
          </div>
          <div class="find_list group_list" v-show="activeTab == 'group'">
            <div
              class="list_item"
              v-for="(item, index) in groupList"
              :key="index"
            >
              <div class="avatar-c">
                <avatar-group :groupID="item.groupID" />
              </div>
              <span class="item_nick item_info" :title="item.name">{{
                item.name
              }}</span>
              <span class="btn" @click="handleGroupClick(item)">进入群聊</span>
            </div>
          </div>
          <div class="find_list student_list" v-show="activeTab == 'student'">
            <div
              class="list_item"
              v-for="(item, index) in studentList"
              :key="index"
              @click="handleStudentClick(item)"
            >
              <img
                class="item_img"
                :src="
                  item.photo
                    ? item.photo
                    : require('@/assets/image/chat/default_avatar.png')
                "
                alt=""
              />
              <span class="item_nick item_info" :title="item.name">{{
                item.name ? item.name : "暂无名称"
              }}</span>
              <span class="btn white_btn cursor_default">{{
                item.roleType == 1 ? "家长" : "学生"
              }}</span>
              <span class="btn" @click="stuParChatClick(item)">发送消息</span>
            </div>
          </div>
          <div class="edu_tab_list" v-show="activeTab == 'edu'">
            <span
              class="edu_tab"
              :class="{ edu_tab_active: eduTab == 0 }"
              @click="eduTabClick(0)"
              >全部</span
            >
            <span
              class="edu_tab"
              :class="{ edu_tab_active: eduTab == 3 }"
              @click="eduTabClick(3)"
              >互相关注</span
            >
            <span
              class="edu_tab"
              :class="{ edu_tab_active: eduTab == 2 }"
              @click="eduTabClick(2)"
              >关注我的</span
            >
            <span
              class="edu_tab"
              :class="{ edu_tab_active: eduTab == 1 }"
              @click="eduTabClick(1)"
              >我关注的</span
            >
          </div>
          <div class="edu_list grad_list" v-show="activeTab == 'edu'">
            <div
              class="list_item"
              v-for="(item, index) in eduList"
              :key="index"
            >
              <img
                class="item_img"
                :src="
                  item.photo
                    ? item.photo
                    : require('@/assets/image/chat/default_avatar.png')
                "
                alt=""
              />
              <span
                class="item_nick item_info"
                :title="item.realname ? item.realname : '暂无'"
                >真实姓名：{{ item.realname ? item.realname : "暂无" }}</span
              >
              <span class="item_info" :title="item.teacherId"
                >账号：{{ item.teacherId }}</span
              >
              <span
                class="item_info"
                :title="item.subjectName ? item.subjectName : '暂无'"
                >学科：{{ item.subjectName ? item.subjectName : "暂无" }}</span
              >
              <span
                class="item_info"
                :title="item.sectionName ? item.sectionName : '暂无'"
                >学段：{{ item.sectionName ? item.sectionName : "暂无" }}</span
              >
              <span class="btn white_btn" @click="eduSendMessageClick(item)"
                >发消息</span
              >
              <span class="btn">
                <!-- 互相关注 -->
                <span
                  v-show="item.concernType == 3"
                  @click="concernClick(item, 2)"
                  >取消关注</span
                >
                <!-- 关注我的 -->
                <span
                  v-show="item.concernType == 2"
                  @click="concernClick(item, 3)"
                  >关注</span
                >
                <!-- 我的关注 -->
                <span
                  v-show="item.concernType == 1"
                  @click="concernClick(item, 0)"
                  >取消关注</span
                >
              </span>
            </div>
          </div>
          <div
            class="no_data"
            v-show="
              (activeTab == 'edu' && eduList.length == 0) ||
              (activeTab == 'friend' && colleaguesList.length == 0) ||
              (activeTab == 'group' && groupList.length == 0) ||
              (activeTab == 'student' && studentList.length == 0)
            "
          >
            <span class="txt">暂无数据</span>
          </div>
        </div>
        <chat-student-list
          :studentListParams="studentListParams"
          @studentListParamsChange="studentListParamsChange"
        ></chat-student-list>
        <create-group
          :createGroupParams="createGroupParams"
          @closeCreateGroupDialog="closeCreateGroupDialog"
        ></create-group>
      </div>
    </div>
  </div>
</template>
<script>
import avatar from "./avatar";
import avatarGroup from "./avatar-group";
import TIM from "tim-js-sdk";
import { mapActions, mapMutations, mapState } from "vuex";
import conversationItem from "./conversation/conversation-item";
import conversationItemSearch from "./conversation/conversation-item-search";
import currentConversation from "./conversation/current-conversation";
import chatStudentList from "./dialog/chat-student-list";
import createGroup from "./dialog/create-group";

import {
  myStuAndPar,
  concernRelationship,
  updateConcernTeacher,
  myColleagues,
  searchFriend,
  getChatUserDetail,
} from "@/api/chat";
export default {
  data() {
    return {
      msgList: [],
      userList: [],
      msg: "",
      searchKey: "",
      searchList: [],
      relationSearchKey: "",
      relationSearchObj: {
        colleaguesList: [],
        groupList: [],
        studentList: [],
        eduList: [],
        colleaguesList: [],
      },
      groupList: [],
      colleaguesList: [],
      studentList: [],
      eduList: [],
      eduTab: 0, // 0 全部  1 我的关注  2 关注我的  3 互相关注
      activeTab: null, //group friend student edu
      chatUserDetail: {},
      studentListParams: {
        show: false,
        step: 1,
        studentId: null,
        eduId: null,
      },
      createGroupParams: {
        show: false,
      },
    };
  },
  inject: ["timChat"],
  components: {
    conversationItem,
    currentConversation,
    conversationItemSearch,
    chatStudentList,
    avatar,
    avatarGroup,
    createGroup,
  },
  computed: {
    ...mapState("chatInfo", [
      "conversationList",
      "clearConversationSearchKey",
      "showWin",
      "currentConversation",
      'isHidden',
    ]),
    currentUnreadCount() {
      return this.currentConversation.unreadCount;
    },
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.isHiddenChange(true)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.isHiddenChange(false)
      if (!vm.isHidden && vm.currentUnreadCount > 0) {
        vm.timChat.tim.setMessageRead({
          conversationID: vm.currentConversation.conversationID,
        });
      }
    })
  },
  watch: {
    showWin(showWin) {
      if (showWin == "myRelation") {
        this.getGroupList();
      }
    },
    clearConversationSearchKey(clearConversationSearchKey) {
      if (clearConversationSearchKey) {
        this.searchKey = "";
      }
    },
    searchKey(searchKey) {
      if (searchKey) {
        this.searchList = [];
        this.conversationList.forEach((item) => {
          let name = "";
          if (item.type === TIM.TYPES.CONV_C2C) {
            name = item.userProfile.nick || item.userProfile.userID;
            if(item.selfType == 'teacher' || item.selfType == 'parent'){
              name = item.selfNick
            }
          }
          if (item.type === TIM.TYPES.CONV_GROUP) {
            name = item.groupProfile.name || item.groupProfile.groupID;
          }
          if (item.type === TIM.TYPES.CONV_SYSTEM) {
            name = "系统通知";
          }
          if (name.includes(this.searchKey)) {
            this.searchList.push(item);
          }
        });
      }
    },
    relationSearchKey(relationSearchKey) {
      this.relationSearchObj = {
        groupList: [], // 群列表
        studentList: [], // 学生%家长
        eduList: [], // 老师列表
        colleaguesList: [], // 同事列表
      };
      if (relationSearchKey) {
        this.groupList.forEach((item) => {
          if (item.name.includes(relationSearchKey)) {
            this.relationSearchObj.groupList.push({
              type: "group",
              name: item.name,
              groupId: item.groupID,
              avatar: item.avatar,
            });
          }
        });
        this.getSearchList();
      }
    },
    currentUnreadCount(currentUnreadCount) {
      if (!this.isHidden && currentUnreadCount > 0) {
        this.timChat.tim.setMessageRead({
          conversationID: this.currentConversation.conversationID,
        });
      }
    },
    isHidden(isHidden) {
      if (!isHidden && this.currentUnreadCount > 0) {
        this.timChat.tim.setMessageRead({
          conversationID: this.currentConversation.conversationID,
        });
      }
    },
  },
  mounted() {
    if(this.currentConversation.conversationID){
      this.checkoutConversation(this.currentConversation.conversationID)
    }
    this.initData()
  },
  beforeDestroy() {
    console.log('messageCenter beforeDestroy')
  },
  methods: {
    ...mapActions("chatInfo", ["checkoutConversation"]),
    ...mapMutations("chatInfo", ["showWinChange", 'isHiddenChange']),
    initData() {
      document.addEventListener('visibilitychange', () => {
        const isHidden = document.hidden
        let isHiddenFlag = true
        if (!isHidden && this.$route.path == '/teachBusiness/expertTeachBusiness/messageCenter') {
          isHiddenFlag = false
        }
        this.isHiddenChange(isHiddenFlag)
      })
    },
    eduSendMessageClick(item) {
      const conversationID = `C2C${item.teaChatId}`;
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    tabClick(type) {
      this.activeTab = type;
      switch (type) {
        case "group": //我的群聊
          this.getGroupList();
          break;
        case "friend": //我的同事
          this.getFriendList();
          break;
        case "student": //学生&家长
          this.getMyStuAndPar();
          break;
        case "edu": //我的老师
          this.getEduList();
          break;
      }
    },
    getSearchList() {
      searchFriend({ name: this.relationSearchKey }).then((res) => {
        let eduList = res.rows.xmtTeacherList,
          studentList = res.rows.myStuAndPar,
          colleaguesList = res.rows.sysUserList;
        eduList.forEach((item) => {
          this.relationSearchObj.eduList.push({
            type: "edu",
            name: item.name,
            chatId: item.teaChatId,
            avatar: item.photo,
          });
        });
        studentList.forEach((item) => {
          this.relationSearchObj.studentList.push({
            type: "student",
            roleType: 1,
            name: item.parentName ? item.parentName : item.stuName + "（家长）",
            photo: item.stuPhoto,
            chatId: item.parChatId,
          });
          this.relationSearchObj.studentList.push({
            type: "student",
            roleType: 2,
            name: item.stuName,
            photo: item.parentPhoto,
            chatId: item.stuChatId,
          });
        });
        colleaguesList.forEach((item) => {
          this.relationSearchObj.colleaguesList.push({
            type: "colleagues",
            name: item.username,
            avatar: item.photo,
            chatId: item.sysChatId,
          });
        });
      });
    },
    getGroupList() {
      this.timChat.tim
        .getGroupList()
        .then(({ data }) => {
          console.log(data.groupList);
          this.groupList = data.groupList;
        })
        .catch((error) => {
          this.msgError(error.message);
        });
    },
    getFriendList() {
      myColleagues().then((res) => {
        this.colleaguesList = res.rows;
      });
    },
    getMyStuAndPar() {
      myStuAndPar().then((res) => {
        console.log(res);
        let list = [];
        res.rows.forEach((item) => {
          list.push({
            roleType: 1,
            name: item.parentName ? item.parentName : item.stuName + "（家长）",
            photo: item.parentPhoto,
            chatId: item.parChatId,
          });
          list.push({
            roleType: 2,
            name: item.stuName,
            photo: item.stuPhoto,
            chatId: item.stuChatId,
          });
        });
        this.studentList = list;
      });
    },
    getEduList() {
      concernRelationship({
        concernType: this.eduTab ? this.eduTab : null,
      }).then((res) => {
        console.log(res);
        this.eduList = res.rows;
      });
    },
    handleStudentClick(item) {},
    handleGroupClick(item) {
      const conversationID = `GROUP${item.groupID}`;
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    handleFriendClick(item) {
      console.log(item);
      const conversationID = `C2C${item.sysChatId}`;
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    stuParChatClick(item) {
      console.log(item);
      const conversationID = `C2C${item.chatId}`;
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    relationSearchItemClick(item) {
      let conversationID;
      if (item.type == "friend") {
        conversationID = `C2C${item.chatId}`;
      } else if (item.type == "group") {
        conversationID = `GROUP${item.groupId}`;
      } else if (item.type == "edu") {
        conversationID = `C2C${item.chatId}`;
      } else if (item.type == "student") {
        conversationID = `C2C${item.chatId}`;
      }
      this.relationSearchKey = "";
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    seeChatUserDetailClick(item) {
      getChatUserDetail({
        chatId: item.userID,
      }).then((res) => {
        console.log(res);
        this.chatUserDetail = res.msg;
        if (this.chatUserDetail.stuChatId) {
          this.chatUserDetail.chatId = this.chatUserDetail.stuChatId;
        } else if (this.chatUserDetail.teaChatId) {
          this.chatUserDetail.chatId = this.chatUserDetail.teaChatId;
        } else if (this.chatUserDetail.sysChatId) {
          this.chatUserDetail.chatId = this.chatUserDetail.sysChatId;
        } else if (this.chatUserDetail.parChatId) {
          this.chatUserDetail.chatId = this.chatUserDetail.parChatId;
        }
      });
    },
    eduTabClick(eduTab) {
      this.eduTab = eduTab;
      this.getEduList();
    },
    concernClick(item, concernType) {
      updateConcernTeacher({
        concernType,
        id: item.id,
      }).then((res) => {
        this.getEduList();
      });
    },
    studentListParamsChange() {
      this.studentListParams.show = false;
    },
    seeStudentDetailClick(item) {
      console.log(item);
      this.studentListParams = {
        show: true,
        step: 2,
        studentId: item.id,
        eduId: null,
        groupId: item.groupId,
      };
    },
    createGroupClick() {
      this.createGroupParams.show = true;
    },
    closeCreateGroupDialog() {
      this.createGroupParams.show = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.chat_container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .my_relation {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .relation_left {
      width: 300px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      .left_top {
        height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        .search_input {
          padding: 0 10px;
          flex: 1;
          /deep/ {
            .el-input__inner {
              border-radius: 3px;
              background-color: #f2f2f2;
            }
          }
        }
        .cancel_btn {
          margin-left: 10px;
          cursor: pointer;
          color: #024df8;
        }
      }
      .relation_list {
        margin-top: 20px;
        .relation_item {
          display: flex;
          height: 50px;
          align-items: center;
          padding: 0 0 0 20px;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            background-color: #f0f0f0;
          }
          &.active {
            background-color: #f0f0f0;
          }
          .relation_img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
          .relation_name {
            flex: 1;
          }
          .icon_right {
            width: 30px;
          }
        }
      }
      .relation_search_list {
        flex: 1;
        overflow-y: auto;
        .relation_part {
          .relation_title {
            padding: 0 10px;
            color: #666666;
            line-height: 40px;
          }
          .r_list {
            .r_item {
              display: flex;
              cursor: pointer;
              height: 50px;
              align-items: center;
              .r_item_avatar {
                width: 36px;
                height: 36px;
                margin: 0 10px;
              }
              .r_item_name {
                padding-right: 10px;
                flex: 1;
                width: 0;
                @include ellipsis;
              }
              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }
      }
    }
    .relation_right {
      flex: 1;
      background-color: #f6f6f6;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      position: relative;
      .relation_right_header {
        height: 50px;
        background-color: #ffffff;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid #f2f2f2;
        .btn {
          background-color: #024df8;
          color: #ffffff;
          cursor: pointer;
          padding: 8px 16px;
          margin-right: 20px;
          border-radius: 6px;
        }
      }
      .avatar-c {
        width: 50px;
        height: 50px;
      }
      .relation_right_inner {
        flex: 1;
        background-color: #f6f6f6;
        padding: 10px;
        display: flex;
        overflow: hidden;
        flex-direction: column;
      }
      .no_data {
        @include no_data;
      }
      .edu_tab_list {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e3e5e6;
        color: #666666;
        .edu_tab {
          padding: 8px 16px;
          cursor: pointer;
          &:hover {
            color: #024df8;
            position: relative;
            &::after {
              content: "";
              height: 4px;
              position: absolute;
              width: 70%;
              background-color: #024df8;
              border-radius: 2px;
              bottom: -6px;
              left: 15%;
            }
          }
        }
        .edu_tab_active {
          color: #024df8;
          position: relative;
          &::after {
            content: "";
            height: 4px;
            position: absolute;
            width: 70%;
            background-color: #024df8;
            border-radius: 2px;
            bottom: -6px;
            left: 15%;
          }
        }
      }
      .find_list,
      .edu_list {
        padding: 20px;
        overflow-y: auto;
        .list_item {
          width: calc((100% - 60px) / 3);
          margin: 10px;
          float: left;
          padding: 40px 10px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          .item_img {
            width: 60px;
            height: 60px;
            border-radius: 60px;
          }
          .item_info {
            width: 100%;
            @include ellipsis;
            line-height: 30px;
            text-align: center;
            color: #666666;
          }
          .btn {
            margin-top: 14px;
            border-radius: 4px;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            background-color: #024df8;
            color: #ffffff;
          }
          .white_btn {
            border: 1px solid #dddddd;
            background-color: #ffffff;
            color: #666666;
          }
          .teacher_info_item {
            line-height: 24px;
          }
        }
      }
    }
  }
  .main_chat {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .chat_left {
      padding: 0 0 0 6px;
      background-color: #ffffff;
      width: 300px;
      border-right: 1px solid #e3e5e6;
      display: flex;
      flex-direction: column;
      .left_top {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        .search_input {
          padding: 0 10px;
          flex: 1;
          /deep/ {
            .el-input__inner {
              border-radius: 30px;
              background-color: #f2f2f2;
            }
          }
        }
        .user_btn {
          width: 40px;
          display: flex;
          align-items: center;
          text-align: center;
          .icon_c {
            color: #666666;
            font-size: 28px;
            font-weight: bolder;
            cursor: pointer;
          }
        }
      }
      .conversation_list {
        overflow-y: auto;
        flex: 1;
      }
      .couversation_list_search {
        overflow-y: auto;
        flex: 1;
      }
    }
    .chat_right {
      flex: 1;
      background-color: #f6f6f6;
      display: flex;
      flex-direction: column;
      .current_head {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        border-bottom: 1px solid #e3e5e6;
        .title {
          color: #222222;
          font-size: 16px;
        }
        .icon_more {
          font-size: 20px;
          color: #aaaaaa;
          cursor: pointer;
        }
      }
      .message_list {
        flex: 1;
        overflow-y: auto;
      }
      .message_tool {
        height: 80px;
        .course_btn_area {
          height: 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e3e5e6;
          .course_btn {
            padding: 4px 8px;
            border-radius: 20px;
            border: 1px solid #dddddd;
            cursor: pointer;
            margin-left: 20px;
            display: flex;
            align-items: center;
            .iconfont {
              color: #024df8;
              margin-right: 4px;
            }
            .icon_homework {
              font-size: 14px;
            }
          }
        }
        .message_icon_area {
          height: 40px;
          display: flex;
          align-items: center;
          .iconfont {
            cursor: pointer;
            font-size: 20px;
            margin-left: 20px;
          }
        }
      }
      .message_edit {
        height: 200px;
      }
    }
  }
}
</style>